import React, { useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { doc, deleteDoc } from 'firebase/firestore';
import { firestore, auth } from '../firebase'; // Adjust the path if necessary
import { signOut } from 'firebase/auth';
import { useSession } from './SessionContext';

const LoggedOut = () => {
    const navigate = useNavigate();
    const { sessionId } = useSession(); // Retrieve the current session ID

    useEffect(() => {
        const performLogout = async () => {
            try {
                // Delete the session document
                if (sessionId) {
                    const sessionRef = doc(firestore, 'sessions', sessionId);
                    await deleteDoc(sessionRef);
                    console.log("Session deleted successfully");
                }

                // Sign out the user
                await signOut(auth);
                console.log("User signed out successfully");

                // Navigate to a different page if necessary, or handle post-logout logic
            } catch (err) {
                console.error("Error during logout:", err);
            }
        };

        performLogout();
    }, [sessionId, navigate]);

    return (
<section className="flex items-center justify-center min-h-screen bg-gradient-to-br from-primary-50 to-primary-300">
<main className="flex flex-col items-center justify-center w-full max-w-md p-8 bg-white rounded-xl shadow-lg">
<h1 className="mt-6 mb-16 text-4xl font-bold text-primary-400 tracking-tighter sm:text-5xl md:text-6xl text-center">
            DigitalTCO
          </h1>
            <h3 className="text-center">You have successfully logged out.</h3>
            <div className="signup-button-container">
              <Link to="/login" 
className="bg-primary-400 text-white p-2 rounded-xl w-full mx-auto hover:bg-white hover:border hover:border-primary-400 hover:text-primary-400"                          >Log Back In</Link>
            </div>
          </main>
        </section>
    );
    
};

export default LoggedOut;
