import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

const SilenceErrorModal = ({ isVisible, onClose, onEditAudio }) => {
  if (!isVisible) return null;

  return (
    <div className="fixed inset-0 z-50 bg-black bg-opacity-50 flex items-center justify-center p-4">
      <div className="bg-white border-gray-300 border rounded-lg shadow-lg p-8 w-2/5 text-center">
        <h2 className="text-lg font-bold text-red-600 mb-4 text-center">
          <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2" /> Possible Audio Error Detected <FontAwesomeIcon icon={faExclamationTriangle} className="ml-2" />
        </h2>
        {/* <p className="mb-4">
          We noticed some issues with the audio in your recording. It seems there might be long periods of silence or muffled audio.
        </p>
        <p className="mb-4 text-red-500">
          Let’s fix this together! Editing the audio can help you ensure nothing important is missed.
        </p>
        <p className="mb-4 text-center">
          Here’s how you can improve the quality:
        </p>
        <div className="flex justify-center font-semibold">
          <ul className="text-left mb-4 list-decimal list-inside">
            <li>Remove any long silences.</li>
            <li>Boost quiet sections of the recording.</li>
            <li>Edit out any background noise or distortion.</li>
          </ul> */}
          <p className="mb-4">
          We detected some issues with the audio in your recording. It seems there may be repeated sections in the transcript, which could be due to <b>long periods of silence, muffled audio, or microphone issues.</b>
          </p>
          <p className="mb-4 text-red-500">
            We're committed to ensuring the quality of your notes. Please review the transcript, and if you notice any issues, reach out to support. By contacting us, you can help us resolve this issue promptly and contribute to our ongoing improvement efforts.
          </p>
          <p className="mb-4 text-center">
            Please let us know the specific time of the clinical note where the issue occurs, and our team will assist you in addressing it.
          </p>
        
        {/* <button
          className="inline-flex items-center justify-center p-0.5 mb-4 overflow-hidden text-xs font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-primary-500 to-pink-500 group-hover:from-purple-500 group-hover:to-pink-500 hover:text-white focus:ring-4 focus:outline-none focus:ring-purple-200"
          className="cursor-not-allowed inline-flex items-center justify-center p-0.5 mb-4 overflow-hidden text-xs font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-primary-500 to-pink-500 group-hover:from-purple-500 group-hover:to-pink-500 hover:text-white focus:ring-4 focus:outline-none focus:ring-purple-200"
          onClick={onEditAudio}
        >
          <span className="relative px-5 py-1.5 transition-all ease-in duration-75 bg-white rounded-md group-hover:bg-opacity-0">
            Edit Audio
          </span>
        </button> */}
        <div className="flex justify-center">
          <button
            onClick={onClose}
            className="px-4 py-2 text-xs text-gray-700 bg-gray-200 rounded-lg hover:bg-gray-300"
          >
            Close
          </button>
        </div>
      </div>
      </div>

  );
};

export default SilenceErrorModal;
