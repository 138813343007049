// src/components/videos.js

export const videos = [
  { id: 'video1', title: '1. DigitalTCO Setup Guide: Get Started with Ease', url: 'q_9cY94V7EA' },
  { id: 'video2', title: '2. Mastering Super Dentist Cheat Mode', url: 'zyQJCqJXaTk' },
  { id: 'video3', title: '3. Effortless Treatment Notes with DigitalTCO', url: 'm4Mow8qcFCQ' },
  { id: 'video4', title: '4. Revolutionize Your Examinations with Smart Exam', url: '26nwK2fpF6I' },
  { id: 'video5', title: '5. Transform Your Dental Practice with Jazmode', url: '55JLmPskGrQ' },
  { id: 'video6', title: '6. Mastering the DigitalTCO Notes List', url: 'vx3It0mNii4' },
  { id: 'video7', title: '7. Streamlining Dental Documentation with DigitalTCOs Risk Bank', url: 'd3jlbKWmglI' },
  { id: 'video8', title: '8. Cariespondence Tutorial', url: 'u19T_9hPsrk' },
  { id: 'video9', title: '9. Edit Notes With Cheet', url: 'LUlxP-ixDHE' },
  { id: 'video10', title: '10. Molar Mail, a Modular Letter Creator', url: 'X3wtJEPiEKE' }, // New video added
];
