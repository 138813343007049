import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

const SidebarSection = ({ title, icon, children }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <li className="py-1">
      <button
        className="flex items-center justify-between p-2 text-sm font-normal text-gray-400 rounded-lg w-full hover:bg-gray-100 hover:text-gray-600 transition duration-150" // Added hover effect
        onClick={toggleCollapse}
      >
        <span className="flex items-center">
          <FontAwesomeIcon icon={icon} className="mr-2 text-gray-400" /> {/* Display the section icon */}
          {title}
        </span>
        <FontAwesomeIcon icon={isCollapsed ? faChevronUp : faChevronDown} />
      </button>
      {isCollapsed && (
        <ul className="space-y-2 pl-4 mt-2"> {/* Add padding to jog content slightly inward when expanded */}
          {children}
        </ul>
      )}
    </li>
  );
};

export default SidebarSection;
