import React, { useState, useEffect, useRef, useContext } from 'react';
import { collection, doc, getDoc, addDoc, setDoc, updateDoc, deleteDoc, onSnapshot } from 'firebase/firestore';
import { firestore, auth } from '../../firebase';
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMicrophone, faPaperPlane, faStop, faSquarePlus, faPenToSquare, faFloppyDisk, faPlay, faPause } from '@fortawesome/free-solid-svg-icons';
import LoadingPopup from  '../patientnotes/LoadingPopup';
import ActiveAudioAnalyser from '../activeaudioanalyser/ActiveAudioAnalyser';
import OnboardingJoyrideWrapper from '../onboardingsidebar/OnboardingJoyrideWrapper';
import { OnboardingContext } from '../onboardingsidebar/OnboardingContext';

const RiskBank = ({ openSidebar }) => {
    const [generatedNote, setGeneratedNote] = useState('');
    const [isRecording, setIsRecording] = useState(false);
    const [audioData, setAudioData] = useState(null);
    const [mediaRecorder, setMediaRecorder] = useState(null);
    const [transcribedNote, setTranscribedNote] = useState('');
    const [isGenerating, setIsGenerating] = useState(false);
    const [canStopRecording, setCanStopRecording] = useState(false);
    const [showLoadingPopup, setShowLoadingPopup] = useState(false);
    const [downloadUrl, setDownloadUrl] = useState('');
    const globalNoteRef = useRef(null);
    const [showAddPopup, setShowAddPopup] = useState(false);
    const [riskName, setRiskName] = useState('');
    const [trigger, setTrigger] = useState('');
    const [risks, setRisks] = useState([]);
    const [editingRisk, setEditingRisk] = useState(null);
    const [selectedRisk, setSelectedRisk] = useState(null);
    const [mediaStream, setMediaStream] = useState(null);
    const [isPaused, setIsPaused] = useState(false);
    const [languagePreference, setLanguagePreference] = useState('EN');
    const [dentistName, setDentistName] = useState('Dentist');
    const audioDetectedRef = useRef(false);
    const [analyserKey, setAnalyserKey] = useState(0);

    const { onboardingStepKey, setOnboardingStepKey } = useContext(OnboardingContext);
    const [userData, setUserData] = useState(null);
  
    // Define refs
    const onboarding1Ref = useRef(null);
    const onboarding2Ref = useRef(null);
    const onboarding3Ref = useRef(null);
    const onboarding4Ref = useRef(null);
    const onboarding5Ref = useRef(null);
    const onboarding6Ref = useRef(null);
  
    const onboardingRefs = {
      onboarding1Ref,
      onboarding2Ref,
      onboarding3Ref,
      onboarding4Ref,
      onboarding5Ref,
      onboarding6Ref,

    };
  
    // Define stepsDefinition function
    const stepsDefinition = (stepKey, refs) => {
        let steps = [];
        if (stepKey === 'step7') {
            steps = [
                {
                    target: onboarding1Ref.current || '',
                    content: "➕ Step 1: Click the plus button to add the spiel. Give the spiel a clear name so you can recognize it later.",
                    disableBeacon: true,
                },
                {
                    target: onboarding2Ref.current || '',
                    content: "🆔 Step 2: Add a name and trigger phrase. This is the phrase you'll say to activate the spiel. Let's do - perio discussion. Once set, click 'Save.'",
                },
                {
                    target: onboarding3Ref.current || '',
                    content: "🔄 Step 3: Flick the toggle to turn on your spiel. This enables the speech recognition to listen for your trigger.",
                },
                {
                    target: onboarding4Ref.current || '',
                    content: "🎙️ Step 4: Hit record and deliver your standard spiel or explanation. In this example, let’s focus on how you explain periodontal disease to a patient.",
                },
                {
                    target: onboarding5Ref.current || '',
                    content: "🛑 Step 5: Press stop when you’re finished speaking. Take a moment to review your handiwork.",
                },
                {
                    target: onboarding6Ref.current || '',
                    content: "✅ Step 6: Ensure everything looks good. You’re all set!",
                },
            ];
        }
        return steps;
      };


    useEffect(() => {
        const fetchPreferences = async () => {
            const user = auth.currentUser;
            if (user) {
                const userRef = doc(firestore, 'customers', user.uid);
                const userDoc = await getDoc(userRef);
                if (userDoc.exists()) {
                    setLanguagePreference(userDoc.data().languagePreference || 'EN');
                    setDentistName(userDoc.data().name || 'Dentist');
                } else {
                    // Fallback if no data in Firestore
                    setLanguagePreference('EN');
                }
            }
        };
        fetchPreferences();
    }, []);


    const pauseRecording = () => {
        if (mediaRecorder && isRecording) {
            mediaRecorder.pause();
            setIsPaused(true);
        }
    };

    const resumeRecording = () => {
        if (mediaRecorder && isRecording) {
            mediaRecorder.resume();
            setIsPaused(false);
        }
    };

    const handleRowClick = (risk) => {
        setSelectedRisk(selectedRisk && risk.id === selectedRisk.id ? null : risk);
    };
    

    const handleEditClick = (risk) => {
        setRiskName(risk.name);
        setTrigger(risk.trigger);
        setEditingRisk(risk);
        setShowAddPopup(true);
    };

    const handleAddClick = () => {
        setShowAddPopup(true);
      };
      
      const handleRiskNameChange = (e) => {
        setRiskName(e.target.value);
      };
      
      const handleTriggerChange = (e) => {
        setTrigger(e.target.value);
      };
      
      const handleSave = async () => {
        if (auth.currentUser) {
            if (editingRisk) {
                // Ensure status is defined
                const status = editingRisk.status ? editingRisk.status : 'inactive';
    
                // Update the existing document
                const riskRef = doc(firestore, 'customers', auth.currentUser.uid, 'riskBank', editingRisk.id);
                await updateDoc(riskRef, { name: riskName, trigger: trigger, status: status });
                
                // Update selected risk
                setSelectedRisk({ id: editingRisk.id, name: riskName, trigger: trigger, status: status });
            } else {
                // Create a new document
                const riskRef = collection(firestore, 'customers', auth.currentUser.uid, 'riskBank');
                const newRisk = await addDoc(riskRef, { name: riskName, trigger: trigger, status: 'inactive' });
    
                // Set the newly created risk as the selected risk
                setSelectedRisk({ id: newRisk.id, name: riskName, trigger: trigger, status: 'inactive' });
            }
        }
        resetForm();
    };
    
    
    

    const handleDelete = async () => {
        if (editingRisk) {
            const riskRef = doc(firestore, 'customers', auth.currentUser.uid, 'riskBank', editingRisk.id);
            await deleteDoc(riskRef);
        }
        resetForm();
    };

    const resetForm = () => {
        setRiskName("");
        setTrigger("");
        setShowAddPopup(false);
        setEditingRisk(null);
    };
      
      const handleCancel = () => {
        resetForm();
      };

      const updateCurrentlyActive = async (risk, isActive) => {
        const currentlyActiveDocId = "activeRisks";
        const currentlyActiveRef = doc(firestore, 'customers', auth.currentUser.uid, 'currentlyActiveRisks', currentlyActiveDocId);
    
        const docSnap = await getDoc(currentlyActiveRef);
        let currentlyActive = docSnap.exists() && docSnap.data().triggers ? docSnap.data().triggers : [];
    
        if (isActive) {
            // Add risk trigger to the array if not already included
            if (!currentlyActive.includes(risk.trigger)) {
                currentlyActive.push(risk.trigger);
            }
        } else {
            // Remove risk trigger from the array
            currentlyActive = currentlyActive.filter(trigger => trigger !== risk.trigger);
        }
    
        // Update or set the document with the new array
        await setDoc(currentlyActiveRef, { triggers: currentlyActive });
    };
    

    const handleToggle = async (event, riskId, currentStatus) => {
        event.stopPropagation();
        const newStatus = currentStatus === 'active' ? 'inactive' : 'active';
        const riskRef = doc(firestore, 'customers', auth.currentUser.uid, 'riskBank', riskId);
    
        await updateDoc(riskRef, { status: newStatus });
    
        // Find the risk
        const risk = risks.find(r => r.id === riskId);
        if (risk) {
            updateCurrentlyActive(risk, newStatus === 'active');
        }
    };

    const handleSaveChanges = async () => {
        if (selectedRisk) {
            const riskRef = doc(firestore, 'customers', auth.currentUser.uid, 'riskBank', selectedRisk.id);
            await updateDoc(riskRef, {
                // Update it with the current value of generatedNote
                risk: generatedNote 
            });
            alert("Changes saved successfully!");
        }
    };
    
    
    
      useEffect(() => {
        // Listening for real-time updates
        if (auth.currentUser) {
          const unsubscribe = onSnapshot(
            collection(firestore, 'customers', auth.currentUser.uid, 'riskBank'),
            (snapshot) => {
              const risksData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
              setRisks(risksData);
            },
            (error) => {
            //   console.error("Error fetching risks:", error);
            }
          );
    
          return () => unsubscribe(); // Unsubscribe from listener when component unmounts
        }
      }, []);

      useEffect(() => {
        const fetchRiskData = async () => {
            if (selectedRisk) {
                const riskRef = doc(firestore, 'customers', auth.currentUser.uid, 'riskBank', selectedRisk.id);
                const docSnap = await getDoc(riskRef);
                
                if (docSnap.exists()) {
                    // Update the textarea with the fetched risk or transcript
                    const riskData = docSnap.data();
                    setGeneratedNote(riskData.risk || ''); // Assuming 'risk' field contains the risk text
                    setTranscribedNote(riskData.transcript || '');
                }
            } else {
                // Clear the textarea if no risk is selected
                setGeneratedNote('');
                setTranscribedNote('');
            }
        };

        fetchRiskData();
    }, [selectedRisk]);
   
    
    const startRecording = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            setMediaStream(stream);
            const newMediaRecorder = new MediaRecorder(stream);
            // console.log('MediaRecorder started');
            setCanStopRecording(true);
    
            let audioChunks = [];
            newMediaRecorder.ondataavailable = (event) => {
                audioChunks.push(event.data);
            };
    
            newMediaRecorder.onstop = async () => {
                // console.log('Recording stopped, creating audio blob');
                const audioBlob = new Blob(audioChunks, { type: 'audio/webm' });
                // console.log('Audio blob created:', audioBlob.size);
    
                if (audioBlob.size === 0) {
                    alert('Audio recording is blank. If using Safari, please use an alternative browser. If using an iPad, please use an alternative device. If neither, check that your microphone is plugged in and working!');
                    setIsRecording(false);
                    return; // Early return to prevent further execution
                }
    
                if (audioBlob.size > 0) {
                    const uid = auth.currentUser.uid;
                    const filePath = `riskbank/${uid}/riskbank-${Date.now()}.webm`;
                    const storageRef = ref(getStorage(), filePath);
                    await uploadBytes(storageRef, audioBlob);
                    
                    getDownloadURL(storageRef).then((downloadURL) => {
                        // console.log('Download URL:', downloadURL);
                        setAudioData(filePath);
                        setDownloadUrl(downloadURL);
                        sendAudioToCloudFunction(filePath, downloadURL);
                    });
                }
            };
    
            setMediaRecorder(newMediaRecorder);
            newMediaRecorder.start();
            setIsRecording(true);
        } catch (error) {
            // console.error('Error in starting recording:', error);
        }
    };
    
    const stopRecording = async () => {
        if (mediaRecorder) {
            setIsGenerating(true);
            setShowLoadingPopup(true);
            // console.log('Stopping recording');
            mediaRecorder.stop();
            setIsRecording(false);
            mediaRecorder.stream.getTracks().forEach(track => track.stop());
            setCanStopRecording(false); // Disable the stopRecording button after recording stops
            setTranscribedNote("Loading...");
    
            // // Automatically call generate note function after stopping recording
            // if (audioData) {
            //     console.log("sending for transcription");
            //     await sendAudioToCloudFunction(audioData);
            //     console.log("transcription receieved");
            // }
    
            // const prompt = `Audio transcript from dentist: ${transcribedNote}.`;
            // console.log("sending to generate note:", prompt);
            // callGenerateNoteFunction(prompt);
        }
    };
    
    
    // useEffect(() => {
    //     if (audioData) {
    //         console.log('Audio Blob is set, now sending to cloud function');
    //         sendAudioToCloudFunction(audioData);
    //     }
    // }, [audioData]);

    const sendAudioToCloudFunction = async (filePath, downloadURL) => {
        if (!selectedRisk) return; // Ensure there's a selected risk
    
        try {
            // Get the current authenticated user
            const user = auth.currentUser;
            if (!user) {
                throw new Error("User not authenticated");
            }
    
            // Get the Firebase ID token of the current user
            const idToken = await user.getIdToken();
    
            // Prepare the payload
            const payload = {
                filePath: filePath,
                language: languagePreference // Send the user's language preference
            };
    
            // Make the POST request with Authorization header
            const response = await fetch('https://us-central1-digitaltco-c40e9.cloudfunctions.net/multilanguage', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${idToken}`, // Include the ID token in the Authorization header
                },
                body: JSON.stringify(payload),
            });
    
            if (!response.ok) {
                // Handle HTTP errors
                const errorText = await response.text();
                throw new Error(`Server Error: ${errorText}`);
            }
            
            const result = await response.json();
            // console.log('Received response:', result);
            setTranscribedNote(result.text);
            // setIsRecording(false);
    
            // Update the selected risk document with transcript
            const riskRef = doc(firestore, 'customers', auth.currentUser.uid, 'riskBank', selectedRisk.id);
            await updateDoc(riskRef, {
                transcript: result.text,
                audio_recording: downloadURL
                // other fields you might want to update...
            });
            const prompt = `Audio transcript from dentist: ${result.text}.`;
            // console.log("sending to generate note:", prompt);
            callGenerateNoteFunction(prompt);
        } catch (error) {
            // console.error('Error sending audio URL:', error);
        }
    };  
    
    const callGenerateNoteFunction = async (prompt) => {
        // if (!selectedRisk) return; // Ensure there's a selected risk
    
        setIsGenerating(true);
        setShowLoadingPopup(true);
    
        const transcript = selectedRisk.transcript || ''; // Fallback if no transcript
    
        try {
            const response = await fetch('https://us-central1-digitaltco-c40e9.cloudfunctions.net/riskBankWriter', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ prompt }),
            });
    
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const responseData = await response.json();
            setGeneratedNote(responseData.text);
    
            // Update the selected risk document with the generated note
            const riskRef = doc(firestore, 'customers', auth.currentUser.uid, 'riskBank', selectedRisk.id);
            await updateDoc(riskRef, {
                risk: responseData.text,
                // other fields you might want to update...
            });
        } catch (error) {
            // console.error('Error generating note:', error);
            alert("Sorry, the artificial intelligence seemed to be on its own coffee break. Please try to generate your note again.");
        } finally {
            setIsGenerating(false);
            setShowLoadingPopup(false);
        }
    };  
    
        const removeDoubleAsterisks = (text) => {
            let formattedText = text.replace(/\*\*(.*?)\*\*/g, '$1'); // Remove **text**
            formattedText = formattedText.replace(/##/g, ''); // Remove ##
            formattedText = formattedText.replace(/###/g, ''); // Remove ###
            formattedText = formattedText.replace(/【\d+†.*?】/g, '');
            return formattedText;
        };
    

        return (
            <main className="flex flex-col items-center w-4/5 mx-auto">
                {showLoadingPopup && <LoadingPopup />}
        
                {showAddPopup && (
                    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
                        <div className="bg-white p-6 rounded-lg shadow-lg">
                            <div className="flex flex-col">
                                <label className="relative block mb-4">
                                    <input
                                        type="text"
                                        value={riskName}
                                        onChange={(e) => setRiskName(e.target.value)}
                                        className="w-full p-2 border rounded focus:outline-none"
                                        placeholder=" "
                                    />
                                    <span className="absolute left-2 -top-2 text-primary-400 bg-white px-1 text-xs">Risk Name</span>
                                </label>
                                <label className="relative block mb-4">
                                    <input
                                        type="text"
                                        value={trigger}
                                        onChange={(e) => setTrigger(e.target.value)}
                                        className="w-full p-2 border rounded focus:outline-none"
                                        placeholder=" "
                                    />
                                    <span className="absolute left-2 -top-2 text-primary-400 bg-white px-1 text-xs">Trigger</span>
                                </label>
        
                                <div className="flex justify-around mt-4">
                                    <button
                                        onClick={handleSave}
                                        className={`${
                                            editingRisk
                                                ? 'px-4 py-2 text-sm text-primary-400 bg-white border border-gray-200 rounded-l-lg hover:bg-primary-400 hover:text-white'
                                                : 'px-4 py-2 text-sm text-primary-400 bg-white border border-gray-200 rounded-lg hover:bg-primary-400 hover:text-white'
                                        }`}
                                    >
                                        {editingRisk ? 'Update' : 'Save'}
                                    </button>
                                    {editingRisk && (
                                        <button
                                            onClick={handleDelete}
                                            className="px-4 py-2 text-sm text-red-600 bg-white border border-gray-200 hover:bg-red-600 hover:text-white"
                                        >
                                            Delete
                                        </button>
                                    )}
                                    <button
                                        onClick={handleCancel}
                                        className={`px-4 py-2 text-sm text-gray-500 bg-gray-300 border border-gray-200 hover:bg-gray-400 ${
                                            editingRisk ? 'rounded-r-lg' : 'rounded-lg'
                                        }`}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
        
                <div className="flex w-full gap-4 mt-6" ref={onboarding2Ref}>
                    <button
                        onClick={() => setShowAddPopup(true)}
                        className="bg-gradient-to-b from-primary-400 to-teal-200 text-white p-2 rounded hover:bg-gradient-to-t from-primary-400 to-teal-200 hover:shadow-lg"
                        ref={onboarding1Ref}
                    >
                        <FontAwesomeIcon icon={faSquarePlus} />
                    </button>
        
                    {/* TABLE */}
                    <div className="flex-1 overflow-y-auto max-h-[40rem] border rounded">
                        <table className="w-full border-collapse bg-white text-left text-sm">
                            <thead>
                                <tr className="border-b">
                                    <th className="px-4 py-2">Risk Name</th>
                                    <th className="px-4 py-2">Trigger</th>
                                    <th className="px-4 py-2">Status</th>
                                    <th className="px-4 py-2">Edit</th>
                                </tr>
                            </thead>
                            <tbody>
                                {risks.map((risk) => (
                                    <tr
                                        key={risk.id}
                                        className={`cursor-pointer hover:bg-gray-100 ${selectedRisk && risk.id === selectedRisk.id ? 'bg-gray-200' : ''}`}
                                        onClick={() => handleRowClick(risk)}
                                    >
                                        <td className="px-4 py-2 truncate">{risk.name}</td>
                                        <td className="px-4 py-2 truncate">{risk.trigger}</td>
                                        <td className="px-4 py-2 text-center" ref={onboarding3Ref}>
                                            <label className="relative inline-block w-12 h-6 cursor-pointer">
                                                <input
                                                    type="checkbox"
                                                    checked={risk.status === 'active'}
                                                    onChange={(event) => handleToggle(event, risk.id, risk.status)}
                                                    className="sr-only"
                                                />
                                                <span
                                                    className={`block h-6 w-full rounded-full transition-colors ${
                                                        risk.status === 'active' ? 'bg-malachite-500' : 'bg-gray-300'
                                                    }`}
                                                ></span>
                                                <span
                                                    className={`absolute left-1 top-1 h-4 w-4 bg-white rounded-full transform transition-transform ${
                                                        risk.status === 'active' ? 'translate-x-6' : ''
                                                    }`}
                                                ></span>
                                            </label>
                                        </td>
                                        <td className="px-4 py-2 text-center">
                                            <button onClick={() => handleEditClick(risk)}>
                                                <FontAwesomeIcon icon={faPenToSquare} />
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
        
                    {/* INPUT AREA */}
                    <div className="flex-1 flex flex-col items-center">
                        <textarea
                            className="w-full p-2 border rounded bg-gray-50"
                            placeholder="What's your spiel? Spill the beans, Doc!"
                            value={generatedNote}
                            onChange={(e) => setGeneratedNote(e.target.value)}
                            rows="12"
                            ref={onboarding6Ref}
                        ></textarea>
        
                        {mediaStream && (
                            <div className="active-audio-analyser-container">
                                <ActiveAudioAnalyser
                                    key={analyserKey}
                                    audioStream={mediaStream}
                                    isPaused={isPaused}
                                    onAudioDetected={() => {
                                        audioDetectedRef.current = true;
                                    }}
                                />
                            </div>
                        )}
        
                        {/* BUTTONS */}
                        <div className="mx-auto mt-2">
                            <div className="inline-flex rounded-md shadow-sm" role="group" ref={onboarding4Ref}>
                                <button 
                                    onClick={startRecording} 
                                    disabled={isRecording || isPaused || !selectedRisk} // Disable if recording, paused, or no selected risk
                                    className={`${isRecording || isPaused || !selectedRisk ? 'px-4 py-2 text-xl font-medium text-gray-500 bg-gray-300 border border-gray-200 rounded-s-lg cursor-not-allowed' : 'px-4 py-2 text-xl font-medium text-primary-400 bg-white border border-gray-200 rounded-s-lg hover:bg-primary-400 hover:text-white'}`}
                                >
                                    <FontAwesomeIcon icon={faMicrophone} />
                                </button>
        
                                {isRecording && (
                                    <button 
                                        onClick={isPaused ? resumeRecording : pauseRecording} // Switch between pause and resume
                                        className={`${isPaused ? 'px-4 py-2 text-xl font-medium text-primary-400 bg-white border-t border-b border-gray-200 hover:bg-primary-400 hover:text-white' : 'px-4 py-2 text-xl font-medium text-primary-400 bg-white border-t border-b border-gray-200 hover:bg-primary-400 hover:text-white'}`}
                                    >
                                        {isPaused ? <FontAwesomeIcon icon={faPlay} /> : <FontAwesomeIcon icon={faPause} />}
                                    </button>
                                )}
        
                                <button
                                    onClick={stopRecording} 
                                    disabled={!isRecording || isPaused} // Disable if not recording or paused
                                    ref={onboarding5Ref}
                                    className={`${!isRecording || isPaused ? 'px-4 py-2 text-xl font-medium text-gray-500 bg-gray-300 border-t border-b border-gray-200 cursor-not-allowed' : 'px-4 py-2 text-xl font-medium text-primary-400 bg-white border-t border-b border-gray-200 hover:bg-primary-400 hover:text-white'}`}
                                >
                                    <FontAwesomeIcon icon={faStop} />
                                </button>
        
                                {selectedRisk && (
                                    <button 
                                        className="px-4 py-2 text-xl font-medium text-primary-400 bg-white border rounded-e-lg border-gray-200 hover:bg-primary-400 hover:text-white"
                                        onClick={handleSaveChanges}
                                    >
                                        <FontAwesomeIcon icon={faFloppyDisk} />
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
        
                {/* Onboarding Joyride Wrapper */}
                <OnboardingJoyrideWrapper
                    stepKey="step7"
                    stepsDefinition={stepsDefinition}
                    refs={onboardingRefs}
                    onboardingStepKey={onboardingStepKey}
                    setOnboardingStepKey={setOnboardingStepKey}
                    userData={userData}
                    openSidebar={openSidebar}
                />
            </main>
        );
                };
        
        export default RiskBank;