import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { firestore, auth } from '../../firebase';
import './PatientProfile.css';
import { generateDentalDiagnosis, generatePeriodontalDiagnosis, generateDentalPathologyDiagnosis, updatePatientDiagnoses } from './DiagnosisService';
import GenerateFullNoteButton from './GenerateFullNoteButton';
import AudioRecorder from './AudioRecorder';
import PatientProfileNav from './PatientProfileNav';
import LoadingPopup from './LoadingPopup';

function PatientProfile() {
  const { patientId } = useParams();
  const [patient, setPatient] = useState(null);
  const [dentalDiagnosis, setDentalDiagnosis] = useState('');
  const [pathologyDiagnosis, setPathologyDiagnosis] = useState('');
  const [periodontalDiagnosis, setPeriodontalDiagnosis] = useState('');
  const [userId, setUserId] = useState(null);
  const navigate = useNavigate();
  const [showLoadingPopup, setShowLoadingPopup] = useState(false);

  useEffect(() => {
    const fetchPatientAndDiagnoses = async () => {
      try {
        if (auth.currentUser) {
          const uid = auth.currentUser.uid;
          setUserId(uid); // Set the userId state
          const patientRef = doc(firestore, 'customers', uid, 'patients', patientId);
          const patientDoc = await getDoc(patientRef);
          if (patientDoc.exists) {
            setPatient(patientDoc.data());
            // Fetch and set the diagnoses
            const dentalDiag = await generateDentalDiagnosis(patientId, uid);
            const pathologyDiag = await generateDentalPathologyDiagnosis(patientId, uid);
            const periodontalDiag = await generatePeriodontalDiagnosis(patientId, uid);
            
            // Update the state with the new diagnoses
            setDentalDiagnosis(dentalDiag);
            setPathologyDiagnosis(pathologyDiag);
            setPeriodontalDiagnosis(periodontalDiag);
    
            // Update the diagnoses in Firestore
            await updatePatientDiagnoses(patientId, uid, dentalDiag, pathologyDiag, periodontalDiag);
          } else {
            console.error("Patient not found");
          }
        }
      } catch (error) {
        console.error("An error occurred while fetching patient data:", error);
      }
    };
  
    fetchPatientAndDiagnoses();
  }, [patientId]);
  
    const renderObjectAsList = (obj) => {
      return Object.entries(obj).map(([key, value], index, array) => {
        // Capitalize the first letter of each word in the key
        const formattedKey = key.replace(/([A-Z])/g, ' $1')
                                .replace(/^./, (str) => str.toUpperCase())
                                .trim();
        // Use the default value if the value is empty
        const formattedValue = value || 'No abnormality detected';
        // Determine if a comma should be added
        const needsComma = index < array.length - 1 ? ', ' : '';
    
        // Return JSX with the key in bold
        return (
          <React.Fragment key={key}>
            <strong>{formattedKey}</strong> - {formattedValue}{needsComma}
          </React.Fragment>
        );
      });
    };
    
    const renderBPEGrid = (bpeValues) => {
      // Define the order of the BPE values for the grid
      const gridOrder = [
        'topRight', 'topMiddle', 'topLeft',
        'bottomRight', 'bottomMiddle', 'bottomLeft'
      ];
      return (
        <div className="bpe-grid">
          {gridOrder.map((position) => (
            <div key={position} className="bpe-cell">
              {bpeValues[position]}
            </div>
          ))}
        </div>
      );
    };

    const updatePatientNotes = async (newNotes) => {
      setPatient(prevState => {
        return { ...prevState, notes: { ...prevState.notes, ...newNotes }};
      });
    };

    const handleNavigateToFullNote = () => {
      navigate(`/mvp/patient-profile/${patientId}/full-note`);
    };  

    if (!patient) return <div>Loading...</div>;

    const fullNoteExists = patient.notes && patient.notes.fullNote;
  
    return (
      <>
      {showLoadingPopup && <LoadingPopup />}
      <div className="patient-complaints-container">
      <PatientProfileNav patientId={patientId} />
      </div>
      <div className="patient-profile-container">
        <h1>Note Review</h1>
        <div className="patient-profile-header-container">
            {userId && !fullNoteExists && <GenerateFullNoteButton 
              patientId={patientId} 
              userId={userId}
              onGenerateStart={() => setShowLoadingPopup(true)}
              onGenerateEnd={() => setShowLoadingPopup(false)}
               />}
            {userId && fullNoteExists && <button onClick={handleNavigateToFullNote} className="profile-back-button">View Full Note</button>}
        </div>
        <table className="profile-table">
          <tbody>
            <tr>
              <th>Name</th>
              <td>{patient.name}</td>
            </tr>
            {patient.notes && patient.notes.complaints && (
              <tr>
                <th>Complaints</th>
                <td>{patient.notes.complaints}</td>
              </tr>
            )}
            {patient.notes && patient.notes.pastDentalHistory && (
              <tr>
                <th>PDH</th>
                <td>{patient.notes.pastDentalHistory}</td>
              </tr>
            )}
            {patient.notes && patient.notes.socialHistory && (
              <tr>
                <th>SH</th>
                <td>{patient.notes.socialHistory}</td>
              </tr>
            )}
            {patient.notes && patient.notes.motivators && (
              <tr>
                <th>Motivators</th>
                <td>{patient.notes.motivators}</td>
              </tr>
            )}
            {patient.notes && patient.notes.roadblocks && (
              <tr>
                <th>Roadblocks</th>
                <td>{patient.notes.roadblocks}</td>
              </tr>
            )}
            {patient.notes && patient.notes.extraoralExam && (
              <tr>
                <th>E/O</th>
                <td>{renderObjectAsList(patient.notes.extraoralExam)}</td>
              </tr>
            )}
            {patient.notes && patient.notes.intraoralExam && (
              <tr>
                <th>I/O</th>
                <td>{renderObjectAsList(patient.notes.intraoralExam)}</td>
              </tr>
            )}
            {patient.notes && patient.notes.clinicalCaries && (
              <tr>
                <th>Clinical Caries</th>
                <td>{patient.notes.clinicalCaries}</td>
              </tr>
            )}
            {patient.notes && patient.notes.toothWear && (
              <tr>
                <th>Tooth Wear</th>
                <td>{patient.notes.toothWear}</td>
              </tr>
            )}
            {patient.notes && patient.notes.bpe && (
              <tr>
                <th>BPE</th>
                <td>{renderBPEGrid(patient.notes.bpe)}</td>
              </tr>
            )}
            {patient.notes && patient.notes.bpeType && (
              <tr>
                <th>BOP</th>
                <td>{patient.notes.bpeType}</td>
              </tr>
            )}
            {patient.notes && patient.notes.oralHygiene && (
              <tr>
                <th>OH</th>
                <td>{patient.notes.oralHygiene}</td>
              </tr>
            )}
            {patient.notes && patient.notes.cariesRisk && (
              <tr>
                <th>Caries Risk</th>
                <td>{patient.notes.cariesRisk}</td>
              </tr>
            )}
            {patient.notes && patient.notes.specialTests && (
              <tr>
                <th>Special Tests</th>
                <td>{patient.notes.specialTests}</td>
              </tr>
            )}
            {patient.notes && patient.notes.radiographAssessment && (
              <tr>
                <th>X-rays</th>
                <td>{renderObjectAsList(patient.notes.radiographAssessment)}</td>
              </tr>
            )}
            {dentalDiagnosis && patient.notes && patient.notes.clinicalCaries && patient.notes.radiographAssessment.cariesAssessment && (
              <tr>
                <th>Dental Diagnosis</th>
                <td>{dentalDiagnosis}</td>
              </tr>
            )}
            {pathologyDiagnosis && patient.notes && patient.notes.radiographAssessment.pathologyAssessment && (
              <tr>
                <th>Pathology Diagnosis</th>
                <td>{pathologyDiagnosis}</td>
              </tr>
            )}
            {periodontalDiagnosis && patient.notes && patient.notes.bpe && patient.notes.radiographAssessment.maxBoneLossPercentage && (
              <tr>
                <th>Periodontal Diagnosis</th>
                <td>{periodontalDiagnosis}</td>
              </tr>
            )}
            {patient.notes && patient.notes.discussion && (
              <tr>
                <th>Discussion</th>
                <td>{patient.notes.discussion}</td>
              </tr>
            )}
            {patient.notes && patient.notes.treatmentPlan && (
              <tr>
                <th>Treatment Plan</th>
                <td>
                  {patient.notes.treatmentPlan + (patient.notes.nonToothSpecificTreatments ? ('; ' + patient.notes.nonToothSpecificTreatments) : '')}
                </td>
              </tr>
            )}
            {patient.notes && patient.notes.recording && (
              <tr>
                <th>Additionals</th>
                <td>{patient.notes.recording}</td>
              </tr>
            )}              
          </tbody>
        </table>
        <AudioRecorder updatePatientNotes={updatePatientNotes} />
      </div>
      </>
    );
}

export default PatientProfile;
