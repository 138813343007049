import React, { useState, useEffect } from 'react';
import { collection, getDocs, query, doc, addDoc, onSnapshot, deleteDoc, where } from 'firebase/firestore';
import { firestore, auth } from '../firebase';
import { loadStripe } from '@stripe/stripe-js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelopeOpenText, faClock, faPenNib, faMicrophone, faShieldAlt, faUnlockAlt } from '@fortawesome/free-solid-svg-icons';
import './PaymentPage.css';
import './Upgrade.css'

function Unauthorized() {
  const [isLoading, setIsLoading] = useState(false);
  const [products, setProducts] = useState({});
  const performanceProductId = 'prod_PyLT5sVvc4Lp1f';

  useEffect(() => {
    const fetchProducts = async () => {
      const productsCollection = collection(firestore, 'products');
      const q = query(productsCollection, where('active', '==', true));
      const snapshot = await getDocs(q);
      let fetchedProducts = {};
      for (const doc of snapshot.docs) {
        const productId = doc.id;
        const pricesCollection = collection(doc.ref, 'prices');
        const priceSnapshot = await getDocs(pricesCollection);
        fetchedProducts[productId] = {
          ...doc.data(),
          prices: priceSnapshot.docs.map(priceDoc => ({
            priceId: priceDoc.id,
            ...priceDoc.data()
          }))
        };
      }
      setProducts(fetchedProducts);
    };
    fetchProducts().catch(err => {
      console.error("Error fetching products:", err);
    });
  }, []);
  
  const checkOut = async (productId) => {
    setIsLoading(true);
    const user = auth.currentUser;
    const product = products[productId];
    if (user && product && product.prices.length > 0) {
      const matchingPriceId = product.prices[0].priceId;

      const checkoutSessionCollection = collection(doc(firestore, 'customers', user.uid), 'checkout_sessions');
      // Delete existing checkout sessions
      const sessionsSnapshot = await getDocs(checkoutSessionCollection);
      for (const doc of sessionsSnapshot.docs) {
        await deleteDoc(doc.ref);
      }

 // Additionally, handle canceled subscriptions if necessary
       const subscriptionsCollection = collection(firestore, 'customers', user.uid, 'subscriptions');
       const subscriptionsSnapshot = await getDocs(subscriptionsCollection);
       for (const subDoc of subscriptionsSnapshot.docs) {
         const subscriptionData = subDoc.data();
         if (subscriptionData.status === 'canceled') {
           await deleteDoc(subDoc.ref); // Consider only deleting if the cancelation is final
         }
       }

      const payload = {
        mode: 'subscription',
        payment_method_types: ['card'],
        line_items: [{ price: matchingPriceId, quantity: 1 }],
        success_url: `${window.location.origin}/payment-success`,
        cancel_url: window.location.origin,
      };

      try {
        const docRef = await addDoc(checkoutSessionCollection, payload);
        const checkoutSessionDocRef = doc(firestore, 'customers', user.uid, 'checkout_sessions', docRef.id);
        onSnapshot(checkoutSessionDocRef, async (snap) => {
          const { error, sessionId } = snap.data();
          if (error) {
            alert(`Checkout error: ${error.message}`);
            setIsLoading(false);
          }
          if (sessionId) {
            const stripe = await loadStripe("pk_live_51NwhHfDGxaME3o6c1NlU5EkPLihJW5vzOw7ogz1Ayx9gdKKadXopC9LAfMBERgCO6F9m3PZ2NeW2uoQ0TLfPX6FB00bqeviOsh");
            stripe.redirectToCheckout({ sessionId });
            setIsLoading(false);
          }
        });
      } catch (error) {
        console.error("Error creating checkout session:", error);
        setIsLoading(false);
      }
    } else {
      console.error("Product or price information is missing.");
      setIsLoading(false);
    }
  };

  return (
    <section className="upgrade-header">
      <div className="upgrade-content">
      <div className="payment-plan">
          <div className="upgrade-section">
              <h2 className="upgrade-heading">Unleash the Full Powerhouse of DigitalTCO</h2>
              <p className="upgrade-paragraph">Upgrade to our Performance Plan and experience the true potential of DigitalTCO. With a suite of advanced features, including Letters, Jazmode, Cariespondence, Cheet, and Risk Bank, you'll streamline your workflow and revolutionize your practice.</p>
              <div className="plan-price">
                <span className="price-amount">£54.95</span>
                <span className="price-period">/month</span>
              </div>
              {isLoading ? (
              <div className="payment-loading">
                  <div className="payment-loading-bar">
                  <p>Loading...</p>
                  </div>
              </div>
              ) : (
              <a onClick={() => checkOut(performanceProductId)} className="plan-cta-2">Upgrade today</a>
              )}
              <a onClick={() => checkOut(performanceProductId)} className="plan-cta" style={{marginLeft: '1rem', background: 'white', border: '4px solid #cd66ef', color: '#cd66ef'}}>Find out more</a>
          </div>
      </div>    
          <div className="upgrade-grid">
              <div className="upgrade-item">
                  <div className="upgrade-icon">
                    <FontAwesomeIcon icon={faEnvelopeOpenText} />
                  </div>
                  <h3 className="upgrade-subheading">Letters at a click</h3>
                  <p className="upgrade-paragraph">Wave goodbye to the days of laborious letter writing. Our Letters feature revolutionizes the process, allowing you to effortlessly generate polished, professional correspondence with just a single click.</p>
                  <p className="upgrade-paragraph">No more wrestling with templates or wasting precious time - streamline your workflow and elevate your practice with ease.</p>
              </div>
              <div className="upgrade-item">
                  <div className="upgrade-icon">
                    <FontAwesomeIcon icon={faClock} />
                  </div>
                  <h3 className="upgrade-subheading">Jazmode</h3>
                  <p className="upgrade-paragraph">Experience the future of note-taking with Jazmode. Transform entire appointments into meticulous notes in a fraction of the time.</p>
                  <p className="upgrade-paragraph">Whether you prefer AI-generated summaries or customizable templates, Jazmode empowers you to create comprehensive records effortlessly. Say hello to Cheat Mode on steroids and bid farewell to tedious documentation tasks forever.</p>
              </div>
              <div className="upgrade-item">
                  <div className="upgrade-icon">
                    <FontAwesomeIcon icon={faPenNib} />
                  </div>
                  <h3 className="upgrade-subheading">Cariespondence </h3>
                  <p className="upgrade-paragraph">Unlock the power of personalized communication with Cariespondence. Craft tailor-made letters for each patient with lightning speed. Dictate your message, choose your delivery method, and let our intuitive AI handle the rest.</p>
                  <p className="upgrade-paragraph">From fixed dictation to customizable templates, Cariespondence simplifies correspondence like never before, ensuring every interaction leaves a lasting impression.</p>
              </div>
              <div className="upgrade-item">
                  <div className="upgrade-icon">
                    <FontAwesomeIcon icon={faMicrophone} />
                  </div>
                  <h3 className="upgrade-subheading">Cheet </h3>
                  <p className="upgrade-paragraph">That wee crazy face in the corner is no support bot. Meet your new sidekick in efficient note editing - Cheet.</p>
                  <p className="upgrade-paragraph">This dynamic tool allows you to edit notes on-the-fly using nothing but your voice. Say goodbye to manual typing and hello to unparalleled accuracy and efficiency.</p>
                  <p className="upgrade-paragraph">Plus, with integrated access to the Risk Bank and Super Dentist Cheat Mode, Cheet puts the power of streamlined documentation at your fingertips whenever you need it most.</p>
              </div>
              <div className="upgrade-item">
                  <div className="upgrade-icon">
                  <FontAwesomeIcon icon={faShieldAlt} />
                  </div>
                  <h3 className="upgrade-subheading">Risk Bank </h3>
                  <p className="upgrade-paragraph">Supercharge your notes with the Risk Bank feature. Seamlessly integrate your go-to phrases and spiels into any document with ease.</p>
                  <p className="upgrade-paragraph">Simply utter the trigger phrase, and watch as your favorite content effortlessly populates your notes. With the Risk Bank, enhance your workflow and elevate the quality of your documentation effortlessly.</p>
              </div>
              <div className="upgrade-item">
                  <div className="upgrade-icon">
                    <FontAwesomeIcon icon={faUnlockAlt} />
                  </div>
                  <h3 className="upgrade-subheading">Ease Your Life Even More</h3>
                  <p className="upgrade-paragraph"> Instantly generate polished letters with a single click, effortlessly transform appointments into comprehensive notes, and personalize patient communication with unparalleled ease.</p>
                  <p className="upgrade-paragraph">Seamlessly edit notes using voice commands and integrate favorite phrases effortlessly, streamlining your workflow and elevating documentation quality.</p>
                  <p className="upgrade-paragraph">With DigitalTCO, empower your practice to thrive with less effort and greater impact, maximizing productivity and success every step of the way.</p>
              </div>
          </div>
      </div>
    </section>
    );
  }
  

export default Unauthorized;