import React, { useEffect, useState } from 'react';
import { auth, firestore, functions } from '../firebase';
import { httpsCallable } from 'firebase/functions';
import { useNavigate } from 'react-router-dom';
import { collection, doc, getDocs, writeBatch, addDoc, getDoc, setDoc } from 'firebase/firestore';
import stockTxNoteTemplates from './txnotes/stocktxnotetemplates';
import molarMailTemplate from './molarMailTemplate'; 
import './PaymentSuccess.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faBolt, faVideo, faBookOpen, faBrain, faPause, faRedoAlt, faRocket } from '@fortawesome/free-solid-svg-icons'; // Added new icons
import ConfettiExplosion from './animations/ConfettiExplosion';

const cardData = [
  {
    icon: <FontAwesomeIcon icon={faBolt} className="w-5 h-5" />, // Icon representing speed and efficiency for cheat mode
    title: 'Super Dentist Cheat Mode',
    text: 'Start here. Dive into this feature to boost your efficiency instantly. Simply present the case, and boom - notes.',
  },
  {
    icon: <FontAwesomeIcon icon={faVideo} className="w-5 h-5" />, // Icon for video tutorials
    title: 'Short Training Videos',
    text: 'Keep an eye on your email inbox. You’ll receive quick, 2-3 minute videos to guide you through key features and maximize your experience.',
  },
  {
    icon: <FontAwesomeIcon icon={faBookOpen} className="w-5 h-5" />, // Icon representing the knowledge vault
    title: 'Knowledge Vault',
    text: 'Access the Knowledge Vault any time for a refresher or to dive deeper into the tools that will transform your practice.',
  },
  {
    icon: <FontAwesomeIcon icon={faBrain} className="w-5 h-5" />, // Icon representing strategy and learning
    title: 'Understand the Exam Template',
    text: 'Don’t overwrite the basic exam template just yet. Understand the how and why of it first. There is a method to this madness.',
  },
  {
    icon: <FontAwesomeIcon icon={faPause} className="w-5 h-5" />, // Icon representing pause or patience
    title: 'Pause the AI',
    text: 'The AI doesn’t like long silences, so pause it if you’re faffing around. It’s here to keep you moving smoothly.',
  },
  {
    icon: <FontAwesomeIcon icon={faRedoAlt} className="w-5 h-5" />, // Icon representing retry or correction
    title: 'Retry When Needed',
    text: 'If the note comes back weird or not great, don’t worry. Resend the transcript. It’s rare, but it can happen.',
  },
];

const PaymentSuccess = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);

  const essentialsProductId = ['prod_PyLRbdsIRPKXyU', 'prod_PyfT1MUKQ3igOP'];
  const performanceProductId = ['prod_PjjN7P8dG7mvw9', 'prod_PyLT5sVvc4Lp1f', 'prod_PyfUlioIbDQekP'];

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        setUser(user);
        const subscriptionsCollection = collection(firestore, 'customers', user.uid, 'subscriptions');
        const subscriptionDocs = await getDocs(subscriptionsCollection);

        let isPaid = false;
        let productType = null;
        subscriptionDocs.forEach(docSnapshot => {
          const subscriptionData = docSnapshot.data();
          if (subscriptionData.status === 'active' || subscriptionData.status === 'trialing') {
            isPaid = true;
          }
          // Assuming the product ID is stored directly without a full path
          const productId = subscriptionData.product.id;  // Get the ID directly from the document
          console.log("Product ID:", productId);
          if (essentialsProductId.includes(productId)) {
            console.log("Product type detected: Essentials");
            productType = 'Essentials';
          } else if (performanceProductId.includes(productId)) {
            console.log("Product type detected: Performance");
            productType = 'Performance';
          }
        });

        if (isPaid && productType) {
          console.log("User is paid, setting custom claims...");
          await setCustomClaims(user.uid, productType);
          await checkAndHandleTemplates(user);
          await pushMolarMailTemplateToUser(user.uid, molarMailTemplate); // Push the specific Molar Mail template
          await createSession(user);
          await addCustomerData(user, 'paid');  // Add the customer data with the 'paid' tag
        } 

        if (user.email) {
          addToEmailList(user.email);
        }
        else {
          console.log("User has not paid or product type is undefined.");
        }
      } else {
        console.log("No user detected.");
      }
    });

    return () => unsubscribe();
  }, []);

  // Function to push the Molar Mail template to the user
  const pushMolarMailTemplateToUser = async (uid, template) => {
    if (!uid || !template) {
      console.error("UID and template must be provided.");
      return;
    }

    try {
      const molarMailerRef = collection(firestore, 'customers', uid, 'molarmailer');
      const templateRef = doc(molarMailerRef, template.id); // Use template.id as the document ID

      // Check if the template already exists to prevent duplicates
      const docSnapshot = await getDoc(templateRef);
      if (docSnapshot.exists()) {
        console.log(`Template "${template.name}" already exists for UID: ${uid}. Skipping.`);
        return;
      }

      // Add the template
      await setDoc(templateRef, template);
      console.log(`Template "${template.name}" pushed successfully to UID: ${uid}.`);
    } catch (error) {
      console.error(`Error pushing template to UID: ${uid}`, error);
    }
  };

  const setCustomClaims = async (uid, productType) => {
    console.log("Setting custom claims...");
    const setClaimsFunc = httpsCallable(functions, 'setCustomClaims');
    try {
      const result = await setClaimsFunc({ uid, productType });
      // console.log("Custom claims set successfully: ", result.data.message);
    } catch (error) {
      console.error('Error setting custom claims:', error);
    }
  };

  const checkAndHandleTemplates = async (user) => {
    const templatesCollection = collection(firestore, 'customers', user.uid, 'templates');
    const templateDocs = await getDocs(templatesCollection);

    if (templateDocs.empty) {
      const batch = writeBatch(firestore);
      for (const [key, value] of Object.entries(stockTxNoteTemplates)) {
        const templateDocRef = doc(templatesCollection, key);
        batch.set(templateDocRef, { text: value });
      }

      await batch.commit();
      console.log("Templates created and populated successfully");
    } else {
      console.log("User already has templates, skipping creation and population");
    }
  };

  const createSession = async (user) => {
    const sessionId = generateSessionId();
    const sessionsRef = collection(firestore, 'sessions');
    
    try {
      const sessionDocRef = await addDoc(sessionsRef, {
        userId: user.uid,
        sessionId: sessionId,
        valid: true,
        timestamp: new Date(),
        deviceInfo: {
          userAgent: navigator.userAgent,
          browser: navigator.appName,
          os: navigator.platform
        }
      });

    } catch (error) {
      console.error("Error creating new session:", error);
    }
  };

  const generateSessionId = () => {
    const randomPart = Math.random().toString(36).substring(2, 15);
    const timePart = new Date().getTime().toString(36);
    return `${randomPart}${timePart}`;
  };

  const addToEmailList = async (email) => {
    const createEmailOctopusContact = httpsCallable(functions, 'createEmailOctopusContact');
    try {
      const result = await createEmailOctopusContact({
        email: email,
        // Add any other details or default values you need to send
      });
      // console.log("Email added to list successfully:", result.data);
    } catch (error) {
      console.error('Error adding email to list:', error);
    }
  };

  const addCustomerData = async (user, tag) => {
    const addCustomerData = {
      email: user.email,
      firstName: '', // Add the necessary fields
      lastName: '',
      dentalPractice: '',
      timestamp: new Date().toISOString(),
      tag: tag
    };

    try {
      await fetch('https://us-central1-digitaltcolandingpage.cloudfunctions.net/addOrUpdateCustomer', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(addCustomerData)
      });
      console.log("Customer data added successfully");
    } catch (error) {
      console.error("Error adding customer data:", error);
    }
  };

  const handleGoToMVP = () => {
    navigate('/MVP/profile');
  };

  return (
    <>
      <ConfettiExplosion />
      <section className="bg-gradient-to-br from-primary-50 to-primary-100 text-black">
        <div className="max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8 lg:py-16 mx-auto">
          <div className="max-w-xl">
            <h2 className="text-5xl font-bold sm:text-6xl">Welcome to DigitalTCO <FontAwesomeIcon icon={faRocket} /></h2>
            <p className="mt-4 text-gray-800">
              You've made a smart move joining DigitalTCO. Read through this page to get the most out of your new tools, watch the quick intro video, and then click the button below to dive into the platform. Let’s make every second count.
            </p>
          </div>

          <div className="mt-8 grid grid-cols-1 gap-8 md:mt-16 md:grid-cols-2 md:gap-12 lg:grid-cols-3">
            {cardData.map((card, index) => (
              <div key={index} className="flex items-start gap-4">
                <span className="shrink-0 rounded-lg bg-primary-200 p-4">{card.icon}</span>
                <div>
                  <h2 className="text-lg font-bold">{card.title}</h2>
                  <p className="mt-1 text-sm text-gray-800">{card.text}</p>
                </div>
              </div>
            ))}
          </div>

          <div className="mt-12">
            <iframe
              className="w-full h-[315px] md:h-[560px] lg:h-[480px] mx-auto"
              src="https://www.youtube.com/embed/znNopl7Zhzc?si=qPyPRqEpOQgc55bo"
              title="Introductory Video"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>

          <button
            className="mx-auto text-center bg-black text-white text-lg md:text-xl font-medium py-3 px-6 rounded-full w-full md:w-1/2 flex items-center justify-center mt-8 hover:bg-primary-400 transition-all"
            onClick={handleGoToMVP}
          >
            Click here to access your new tools
            <FontAwesomeIcon className="ml-2" icon={faArrowRight} />
          </button>

          {/* Removed the user feedback notification */}
        </div>
      </section>
    </>
  );
};

export default PaymentSuccess;
