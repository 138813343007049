import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { firestore, auth } from '../../firebase';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { DragDropContext } from 'react-beautiful-dnd';
import DraggableList from './DraggableList';
import CustomTextForm from './CustomTextForm';
import ImageForm from './ImageForm';
import CustomForms from './CustomForms';
import ToothSpecificDetails from './ToothSpecificDetails';
import AutoPatientLetter from './AutoPatientLetter';
import IdealVsActualTeeth from './IdealVsActualTeeth';
import ChartSummary from './ChartSummary';
import SixteenPointAssessment from './SixteenPointAssessment';
import PastExamModal from './PastExamModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTextHeight, faImage, faFileAlt, faTooth, faEnvelope, faSmile, faChartPie, faCheckSquare } from '@fortawesome/free-solid-svg-icons';
import EXIF from 'exif-js';

const ModularLetters = () => {
    const { id } = useParams();
    const [letterTitle, setLetterTitle] = useState('');
    const [leftItems, setLeftItems] = useState([
        { id: 'item-1', content: 'Custom Text', icon: faTextHeight },
        { id: 'item-2', content: 'Image', icon: faImage },
        { id: 'item-3', content: 'Custom Forms', icon: faFileAlt },
        // { id: 'item-4', content: 'Tooth Specific Details', icon: faTooth },
        { id: 'item-5', content: 'Auto Patient Letter', icon: faEnvelope },
        { id: 'item-6', content: 'Current Teeth', icon: faSmile },
        { id: 'item-7', content: 'Chart Summary', icon: faChartPie },
        { id: 'item-8', content: '16 Point Assessment', icon: faCheckSquare }
    ]);
    const [rightItems, setRightItems] = useState([]);
    const [isDraggingFromRight, setIsDraggingFromRight] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [exampleNote, setExampleNote] = useState('');

    useEffect(() => {
        const fetchLetter = async () => {
            console.log('Fetching letter with ID:', id);
            const uid = auth.currentUser.uid;
            const letterRef = doc(firestore, 'customers', uid, 'molarmailer', id);
            const letterDoc = await getDoc(letterRef);
            if (letterDoc.exists()) {
                const data = letterDoc.data();
                console.log('Letter data fetched:', data);
                setLetterTitle(data.name || '');
                setRightItems(data.rightItems ? data.rightItems.map(deserializeItem) : []);
                setExampleNote(data.exampleNote || ''); // Set example note if it exists
                console.log('Letter title set to:', data.name);
                console.log('Right items set to:', data.rightItems ? data.rightItems.map(deserializeItem) : []);
            } else {
                console.error('No such document!');
            }
        };

        if (id) {
            fetchLetter();
        }
    }, [id]);

    useEffect(() => {
        console.log('Letter title updated:', letterTitle);
    }, [letterTitle]);

    useEffect(() => {
        console.log('Right items updated:', rightItems);
    }, [rightItems]);

    useEffect(() => {
        console.log('Example note updated:', exampleNote);
    }, [exampleNote]);

    useEffect(() => {
        const saveRightItems = async () => {
            const uid = auth.currentUser.uid;
            const letterRef = doc(firestore, 'customers', uid, 'molarmailer', id);
            try {
                await setDoc(letterRef, { 
                    rightItems: rightItems.map(serializeItem),
                    exampleNote: exampleNote, // Save example note
                }, { merge: true });
                console.log('Right items and example note saved successfully.');
            } catch (error) {
                console.error('Error saving right items and example note:', error);
            }
        };

        if (rightItems.length > 0 || exampleNote) {
            saveRightItems();
        }
    }, [rightItems, exampleNote, id]);

    const serializeItem = (item) => {
        return {
            ...item,
            content: item.type === 'CustomText' ? item.content : JSON.stringify(item.content),
        };
    };

    const deserializeItem = (item) => {
        try {
            return {
                ...item,
                content: item.type === 'CustomText' ? item.content : JSON.parse(item.content),
            };
        } catch (error) {
            console.error('Error parsing item content:', error);
            return item;
        }
    };

    const onTitleChange = (e) => {
        setLetterTitle(e.target.value);
    };

    const onTitleBlur = async () => {
        const uid = auth.currentUser.uid;
        const letterRef = doc(firestore, 'customers', uid, 'molarmailer', id);
        try {
            await setDoc(letterRef, { name: letterTitle }, { merge: true });
            console.log('Letter title saved successfully.');
        } catch (error) {
            console.error('Error updating letter title:', error);
        }
    };

    const onDragStart = (start) => {
        if (start.source.droppableId === 'right') {
            setIsDraggingFromRight(true);
        }
    };

    const onDragEnd = (result) => {
        setIsDraggingFromRight(false);
        const { source, destination } = result;

        if (!destination) return;

        if (source.droppableId === 'right' && destination.droppableId === 'left') {
            const sourceClone = Array.from(rightItems);
            sourceClone.splice(source.index, 1);
            setRightItems(sourceClone);
            return;
        }

        if (source.droppableId === destination.droppableId) {
            const items = reorder(
                getList(source.droppableId),
                source.index,
                destination.index
            );
            if (source.droppableId === 'left') {
                setLeftItems(items);
            } else {
                setRightItems(items);
            }
        } else {
            if (source.droppableId === 'left' && destination.droppableId === 'right') {
                const sourceClone = Array.from(leftItems);
                const destClone = Array.from(rightItems);
                const removed = sourceClone[source.index];

                let newItem;
                if (removed.content === 'Custom Text') {
                    newItem = { id: `right-${removed.id}-${Date.now()}`, type: 'CustomText', content: '' };
                } else if (removed.content === 'Image') {
                    newItem = { id: `right-${removed.id}-${Date.now()}`, type: 'Image', content: '' };
                } else if (removed.content === 'Custom Forms') {
                    newItem = { id: `right-${removed.id}-${Date.now()}`, type: 'CustomForms', content: '' };
                } else if (removed.content === 'Tooth Specific Details') {
                    newItem = { id: `right-${removed.id}-${Date.now()}`, type: 'ToothSpecificDetails', content: '' };
                } else if (removed.content === 'Auto Patient Letter') {
                    newItem = { id: `right-${removed.id}-${Date.now()}`, type: 'AutoPatientLetter', content: '' };
                } else if (removed.content === 'Current Teeth') {
                    newItem = { id: `right-${removed.id}-${Date.now()}`, type: 'IdealVsActualTeeth', content: 'This section will show the patient\'s teeth with annotations.' };
                } else if (removed.content === 'Chart Summary') {
                    newItem = { id: `right-${removed.id}-${Date.now()}`, type: 'ChartSummary', content: 'The AI will take your charting and treatment planning information and write it in patient friendly language.' };
                } else if (removed.content === '16 Point Assessment') {
                    newItem = { id: `right-${removed.id}-${Date.now()}`, type: '16PointAssessment', content: 'Create a 16-point checklist based on the patient\'s note, overall health, and treatment plan.' };
                } else {
                    newItem = { ...removed, id: `right-${removed.id}-${Date.now()}` };
                }

                destClone.splice(destination.index, 0, newItem);

                setLeftItems(sourceClone);
                setRightItems(destClone);
            }
        }
    };

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    const getList = (id) => (id === 'left' ? leftItems : rightItems);

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);
    const handleUsePastExam = (note) => setExampleNote(note);

    const handleCustomTextChange = (id, text, isDynamic) => {
        setRightItems((prevRightItems) =>
            prevRightItems.map((item) =>
                item.id === id ? { ...item, content: text, isDynamic } : item
            )
        );
    };

    const handleImageChange = async (id, file, imageName, isDynamic, changeType) => {
        if (changeType === 'upload') {
            const uid = auth.currentUser.uid;
            const storage = getStorage();
            const imageRef = ref(storage, `molarmailer/${uid}/${id}-${Date.now()}`);
            await uploadBytes(imageRef, file);
            const imageUrl = await getDownloadURL(imageRef);

            setRightItems((prevRightItems) =>
                prevRightItems.map((item) =>
                    item.id === id ? { ...item, content: imageUrl, imageName, isDynamic } : item
                )
            );
        } else {
            setRightItems((prevRightItems) =>
                prevRightItems.map((item) =>
                    item.id === id ? { ...item, content: file, imageName, isDynamic } : item
                )
            );
        }
    };

    const renderComponent = (item) => {
        switch (item.type) {
            case 'CustomText':
                return (
                    <CustomTextForm
                        key={item.id}
                        id={item.id}
                        content={item.content}
                        isDynamic={item.isDynamic}
                        onChange={handleCustomTextChange}
                    />
                );
            case 'Image':
                return (
                    <ImageForm
                        key={item.id}
                        id={item.id}
                        content={item.content}
                        imageName={item.imageName}
                        isDynamic={item.isDynamic}
                        onChange={handleImageChange}
                    />
                );
            case 'CustomForms':
                return <CustomForms key={item.id} />;
            case 'ToothSpecificDetails':
                return <ToothSpecificDetails key={item.id} />;
            case 'AutoPatientLetter':
                return <AutoPatientLetter key={item.id} />;
            case 'IdealVsActualTeeth':
                return <IdealVsActualTeeth key={item.id} id={item.id} />;
            case 'ChartSummary':
                return <ChartSummary key={item.id} />;
            case '16PointAssessment':
                return <SixteenPointAssessment key={item.id} />;
            default:
                return null;
        }
    };

    return (
        <main className="flex flex-col items-center tracking-tight w-4/5 mx-auto">
            <div className="flex items-center w-[65%] mx-auto justify-center">
                <div>
                    <input
                        type="text"
                        placeholder="Letter title"
                        className="block w-full p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 text-xs focus:ring-blue-500 focus:border-blue-500"
                        value={letterTitle || ''}
                        onChange={onTitleChange}
                        onBlur={onTitleBlur}
                    />
                </div>
            </div>
            <div className="mt-4 flex w-full">
                <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
                    <div className={`flex flex-col w-1/5 ${isDraggingFromRight ? '' : ''}`}>
                        <div className="w-auto mx-auto mb-6">
                            <button
                                onClick={openModal}
                                className="ml-8 inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-xs font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-primary-500 to-pink-500 group-hover:from-purple-500 group-hover:to-pink-500 hover:text-white focus:ring-4 focus:outline-none focus:ring-purple-200"
                            >
                                <span className="relative px-5 py-1.5 transition-all ease-in duration-75 bg-white rounded-md group-hover:bg-opacity-0">
                                    {exampleNote ? 'Edit example note' : 'Add example note'}
                                </span>
                            </button>
                        </div>
                        <DraggableList droppableId="left" items={leftItems} isBin={isDraggingFromRight} />
                    </div>
                    <div className="flex flex-col w-4/5">
                        <DraggableList droppableId="right" items={rightItems} renderComponent={renderComponent} />
                    </div>
                </DragDropContext>
            </div>
            <PastExamModal isOpen={isModalOpen} onClose={closeModal} onUsePastExam={handleUsePastExam} initialNote={exampleNote} />
        </main>
    );
};

export default ModularLetters;
