// src/components/onboardingsidebar/OnboardingJoyrideWrapper.jsx

import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import OnboardingJoyride from './OnboardingJoyride';
import { auth, firestore } from '../../firebase';
import { doc, getDoc, updateDoc } from 'firebase/firestore';

const OnboardingJoyrideWrapper = ({
  stepKey,
  onComplete,
  stepsDefinition,
  refs,
  onboardingStepKey,
  setOnboardingStepKey,
  openSidebar,
  currentIndex, // The current index for this Joyride instance (optional)
  setCurrentIndex, // Function to update the index for this Joyride instance (optional)
}) => {
  const [joyrideRun, setJoyrideRun] = useState(false);
  const [joyrideSteps, setJoyrideSteps] = useState([]);
  const [joyrideInitialized, setJoyrideInitialized] = useState(false);
  const [userData, setUserData] = useState(null); // State for storing user data
  const [localIndex, setLocalIndex] = useState(0); // Local state for index when not provided as a prop

  const location = useLocation();
  const navigate = useNavigate();

  // Fetch user data when component mounts
  useEffect(() => {
    const fetchUserData = async () => {
      const user = auth.currentUser;
      if (user) {
        const userRef = doc(firestore, 'customers', user.uid);
        const userDoc = await getDoc(userRef);
        if (userDoc.exists()) {
          setUserData(userDoc.data());
        } else {
          console.error("User data not found in Firestore.");
        }
      }
    };

    fetchUserData();
  }, []);

  useEffect(() => {
    if (
      userData &&
      !joyrideInitialized &&
      ((location.state && location.state.fromOnboarding && location.state.stepKey) || onboardingStepKey)
    ) {
      const stepKeyLocal = location.state?.stepKey || onboardingStepKey;

      console.log('Initiating Joyride with stepKey:', stepKeyLocal);

      // Wait until all refs are assigned
      const interval = setInterval(() => {
        // Check that all refs are assigned
        const allRefsAssigned = Object.values(refs).every((ref) => ref.current);

        if (allRefsAssigned) {
          clearInterval(interval);

          // Get the steps based on the stepKey and refs
          const steps = stepsDefinition(stepKeyLocal, refs, userData);

          // Log steps for debugging
          console.log('Joyride steps:', steps);

          setJoyrideSteps(steps);
          setJoyrideRun(true);
          setJoyrideInitialized(true);

          // Clear the location state after using it
          navigate(location.pathname, { replace: true, state: {} });
        }
      }, 100);

      // Optional: Clear the interval after a timeout to prevent memory leaks
      const timeout = setTimeout(() => {
        clearInterval(interval);
        console.error('Failed to assign all onboarding refs within timeout.');
      }, 5000); // 5 seconds timeout

      return () => clearTimeout(timeout);
    }
  }, [userData, joyrideInitialized, location, navigate, onboardingStepKey, refs]);

  // Handler functions
  const handleJoyrideComplete = () => {
    console.log('handleJoyrideComplete called');
    setJoyrideRun(false);
    // Invoke onComplete callback to open the sidebar
    if (onComplete) {
      onComplete();
    }
  };

  const handleDoNotShowAgain = async () => {
    setJoyrideRun(false);
    setOnboardingStepKey(null); // Reset the onboardingStepKey
    const user = auth.currentUser;
    if (user) {
      const onboardingRef = doc(firestore, 'customers', user.uid, 'onboarding', 'progress');
      await updateDoc(onboardingRef, { [`show${stepKey}Joyride`]: false });
    }
  };

  const handleJoyrideCallback = (data) => {
    const { action, index } = data;

    // Determine whether to use the prop index or fallback to the local index
    const updateIndex = setCurrentIndex || setLocalIndex;
    const currentIndexValue = currentIndex ?? localIndex;

    if (action === 'next' || action === 'prev') {
      // Update the index using either the prop function or local state
      updateIndex(currentIndexValue + (action === 'next' ? 1 : -1));
    }

    // Handle the end of the Joyride (if the user completes it)
    if (data.status === 'finished' || data.status === 'skipped') {
      handleJoyrideComplete();
    }
  };

  return (
    <>
      {joyrideSteps.length > 0 && (
        <OnboardingJoyride
          run={joyrideRun}
          steps={joyrideSteps}
          stepIndex={currentIndex ?? localIndex} // Use the prop index if provided, else use local state
          callback={handleJoyrideCallback} // Update the callback function
          onComplete={() => {
            handleJoyrideComplete();
            openSidebar(); // Reopen the sidebar after completing the Joyride
          }}
          onDoNotShowAgain={handleDoNotShowAgain}
        />
      )}
    </>
  );
};

export default OnboardingJoyrideWrapper;
