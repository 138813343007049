// UserProgressAdmin.js
import React, { useState } from 'react';
import { doc, getDoc, collection, getDocs } from 'firebase/firestore';
import { firestore } from '../../firebase';
import { steps } from '../onboardingsidebar/OnboardingSidebar'; // Import the steps array
import { videos } from '../knowledgevault/videos'; // Import the videos array

const UserProgressAdmin = () => {
  const [uidInput, setUidInput] = useState('');
  const [userData, setUserData] = useState(null);
  const [onboardingProgress, setOnboardingProgress] = useState(null);
  const [knowledgeVaultProgress, setKnowledgeVaultProgress] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchUserProgress = async () => {
    if (!uidInput) {
      alert('Please enter a valid UID.');
      return;
    }

    setLoading(true);
    try {
      // Fetch user data
      const userDocRef = doc(firestore, 'customers', uidInput);
      const userDoc = await getDoc(userDocRef);

      if (userDoc.exists()) {
        setUserData(userDoc.data());
      } else {
        alert('User not found.');
        setLoading(false);
        return;
      }

      // Fetch onboarding progress
      const onboardingDocRef = doc(firestore, 'customers', uidInput, 'onboarding', 'progress');
      const onboardingDoc = await getDoc(onboardingDocRef);

      if (onboardingDoc.exists()) {
        setOnboardingProgress(onboardingDoc.data());
      } else {
        alert('No onboarding progress found for this user.');
        setOnboardingProgress(null);
      }

      // Fetch Knowledge Vault progress
      const knowledgeVaultCollection = collection(firestore, 'customers', uidInput, 'knowledgevault');
      const knowledgeVaultSnapshot = await getDocs(knowledgeVaultCollection);

      const knowledgeProgress = {};
      knowledgeVaultSnapshot.forEach(doc => {
        knowledgeProgress[doc.id] = doc.data().watched;
      });
      setKnowledgeVaultProgress(knowledgeProgress);
    } catch (error) {
      console.error('Error fetching user progress:', error);
      alert('An error occurred while fetching user progress. Please check the console for details.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="mb-6 p-6 border rounded shadow-md bg-white">
      <h3 className="text-2xl font-bold mb-6 text-center">User Progress Admin</h3>
      <div className="flex justify-center mb-6">
        <input
          type="text"
          value={uidInput}
          onChange={(e) => setUidInput(e.target.value)}
          placeholder="Enter User UID"
          className="border p-2 rounded w-64 mr-4"
        />
        <button
          onClick={fetchUserProgress}
          disabled={loading}
          className={`px-6 py-3 rounded text-white font-semibold ${loading ? 'bg-gray-500 cursor-not-allowed' : 'bg-blue-500 hover:bg-blue-600'}`}
        >
          {loading ? 'Loading...' : 'Fetch User Progress'}
        </button>
      </div>

      {userData && (
        <div className="mb-6">
          <h4 className="text-xl font-semibold mb-4">User Information</h4>
          <p><strong>Email:</strong> {userData.email}</p>
          {/* Add more user information if needed */}
        </div>
      )}

      {onboardingProgress && (
        <div className="mb-6">
          <h4 className="text-xl font-semibold mb-4">Onboarding Progress</h4>
          <table className="min-w-full bg-white">
            <thead>
              <tr>
                <th className="py-2 px-4 border-b border-gray-200">Step</th>
                <th className="py-2 px-4 border-b border-gray-200">Title</th>
                <th className="py-2 px-4 border-b border-gray-200">Completed</th>
                <th className="py-2 px-4 border-b border-gray-200">Skipped</th>
                <th className="py-2 px-4 border-b border-gray-200">Unlocked</th>
              </tr>
            </thead>
            <tbody>
              {steps.map((step, index) => (
                <tr key={step.key}>
                  <td className="py-2 px-4 border-b border-gray-200">{index + 1}</td>
                  <td className="py-2 px-4 border-b border-gray-200">{step.title}</td>
                  <td className="py-2 px-4 border-b border-gray-200">
                    {onboardingProgress[step.key]?.completed ? '✔️' : '❌'}
                  </td>
                  <td className="py-2 px-4 border-b border-gray-200">
                    {onboardingProgress[step.key]?.skipped ? '✔️' : '❌'}
                  </td>
                  <td className="py-2 px-4 border-b border-gray-200">
                    {onboardingProgress[step.key]?.unlocked ? '✔️' : '❌'}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {knowledgeVaultProgress && (
        <div className="mb-6">
          <h4 className="text-xl font-semibold mb-4">Knowledge Vault Progress</h4>
          <table className="min-w-full bg-white">
            <thead>
              <tr>
                <th className="py-2 px-4 border-b border-gray-200">Video ID</th>
                <th className="py-2 px-4 border-b border-gray-200">Title</th>
                <th className="py-2 px-4 border-b border-gray-200">Watched</th>
              </tr>
            </thead>
            <tbody>
              {videos.map(video => (
                <tr key={video.id}>
                  <td className="py-2 px-4 border-b border-gray-200">{video.id}</td>
                  <td className="py-2 px-4 border-b border-gray-200">{video.title}</td>
                  <td className="py-2 px-4 border-b border-gray-200">
                    {knowledgeVaultProgress[video.id] ? '✔️' : '❌'}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {(!onboardingProgress && !knowledgeVaultProgress && !loading) && (
        <p className="text-center text-gray-500">No progress data available for this user.</p>
      )}
    </div>
  );
};

export default UserProgressAdmin;
