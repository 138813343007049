// PatientReccosModal.js
import React from 'react';
import CopyButton from '../CopyButton'; // Adjust the import path as necessary
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const PatientReccosModal = ({ isOpen, onClose, responseText }) => {
    if (!isOpen) return null;

    const removeDoubleAsterisks = (text) => {
        if (!text) return ''; // Handle undefined or null text
        let formattedText = text.replace(/\*\*(.*?)\*\*/g, '$1');
        formattedText = formattedText.replace(/###/g, ''); // Remove ### first
        formattedText = formattedText.replace(/##/g, '');  // Then remove ##
        formattedText = formattedText.replace(/\*\*/g, '');
        formattedText = formattedText.replace(/【\d+†.*?】/g, '');
        return formattedText;
    };

    return (
        <div className="fixed inset-0 z-50 overflow-auto bg-black bg-opacity-50 flex">
            <div className="relative p-8 bg-white w-full max-w-3xl m-auto flex-col flex rounded-lg">
                <button
                    className="absolute top-0 right-0 mt-4 mr-4 text-gray-500 hover:text-gray-700"
                    onClick={onClose}
                >
                    <FontAwesomeIcon icon={faTimes} size="lg" />
                </button>
                <h2 className="text-xl mb-4">Patient Recommendations</h2>
                <textarea
                    value={removeDoubleAsterisks(responseText)}
                    readOnly
                    className="block p-2.5 h-[40rem] w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300"
                />
                <div className="flex justify-end mt-4">
                    <CopyButton textToCopy={removeDoubleAsterisks(responseText)} />
                    <button
                        onClick={onClose}
                        className="ml-2 px-4 py-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-400"
                    >
                        Close
                    </button>
                </div>
            </div>
        </div>
    );
};

export default PatientReccosModal;
