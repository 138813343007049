import React, { useState, useEffect, useRef } from 'react';
import { doc, getDoc, setDoc, writeBatch, collection, getDocs, deleteDoc } from 'firebase/firestore';
import { firestore, auth } from '../../firebase';
import './settings.css';

const CariespondenceTemplates = () => {
  const [selectedTemplate, setSelectedTemplate] = useState('');
  const [templates, setTemplates] = useState({});
  const [selectedTreatment, setSelectedTreatment] = useState('');
  const [searchInput, setSearchInput] = useState('');
  let treatments = Object.keys(templates); // Now dynamic, based on the templates object
  const [isTemplatesVisible, setIsTemplatesVisible] = useState(false);
  const textareaRef = useRef(null);

  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [newTemplateName, setNewTemplateName] = useState('');
  const [newTemplateContent, setNewTemplateContent] = useState('');

  const togglePopup = () => {
    setIsPopupVisible(!isPopupVisible);
  };

  const handleEditTemplate = () => {
    if (!selectedTemplate) {
      alert("Please select a template to edit.");
      return;
    }
    setNewTemplateName(selectedTemplate);
    setNewTemplateContent(templates[selectedTemplate]);
    setIsPopupVisible(true);
  };

  const saveNewTemplate = async () => {
    if (!newTemplateName.trim() || !newTemplateContent.trim()) {
      alert("Please enter a valid template name and content.");
      return;
    }
  
    console.log("Saving new template:", newTemplateName); // Log new template name
  
    const uid = auth.currentUser.uid;
    try {
      // Prepare references
      const newTemplateRef = doc(firestore, 'customers', uid, 'CariespondenceTemplates', newTemplateName);
  
      console.log("Created new template reference for:", newTemplateName); // Log template reference
  
      if (newTemplateName !== selectedTemplate) {
        console.log("Template name has changed, creating new and deleting old"); // Log change in template name
  
        await setDoc(newTemplateRef, { text: newTemplateContent }); // Save new template
  
        if (selectedTemplate) {
          const oldTemplateRef = doc(firestore, 'customers', uid, 'CariespondenceTemplates', selectedTemplate);
          await deleteDoc(oldTemplateRef); // Delete old template
        }
  
        // Update local state
        const updatedTemplates = { ...templates };
        if (selectedTemplate) {
          delete updatedTemplates[selectedTemplate]; // Remove old template
        }
        updatedTemplates[newTemplateName] = newTemplateContent; // Add new template
        setTemplates(updatedTemplates);
      } else {
        console.log("Updating existing template:", newTemplateName); // Log updating existing template
  
        await setDoc(newTemplateRef, { text: newTemplateContent }); // Update template
  
        // Update local state
        const updatedTemplates = { ...templates, [newTemplateName]: newTemplateContent };
        setTemplates(updatedTemplates);
      }
  
      console.log("Template saved successfully"); // Log successful save
      alert('Template saved successfully');
      togglePopup();
    } catch (error) {
      console.error('Error saving template:', error);
      alert('Error saving template. Please try again.');
    }
  };
  
  
  

  const toggleTemplatesVisibility = () => {
    setIsTemplatesVisible(!isTemplatesVisible);
  };

  useEffect(() => {
    const loadTemplates = async () => {
      const uid = auth.currentUser.uid;
      const loadedTemplates = {};
  
      // Fetch all template documents from Firestore
      const templatesRef = collection(firestore, 'customers', uid, 'CariespondenceTemplates');
      const querySnapshot = await getDocs(templatesRef);
  
      querySnapshot.forEach((doc) => {
        // Add each template to the loadedTemplates object
        loadedTemplates[doc.id] = doc.data().text;
      });
  
      setTemplates(loadedTemplates);
    };
  
    loadTemplates();
  }, []);
  

  const handleTemplateSelection = (treatment) => {
    setSelectedTreatment(treatment);
    setSelectedTemplate(treatment); // Set the selectedTemplate to the treatment selected
  };
  
const updateSelectedTemplate = (newTemplate) => {
    setSelectedTemplate(newTemplate);
    // Ensure the textarea is updated with the new template
    if (textareaRef.current) {
        textareaRef.current.focus();
    }
};

const handleSaveTemplate = async () => {
  if (!selectedTemplate) return;

  const uid = auth.currentUser.uid;
  const templateRef = doc(firestore, 'customers', uid, 'CariespondenceTemplates', selectedTemplate);
  try {
    await setDoc(templateRef, { text: templates[selectedTemplate] });
    alert('Template saved successfully');
  } catch (error) {
    console.error('Error saving template:', error);
  }
};

const handleSaveAllTemplates = async () => {
  const uid = auth.currentUser.uid;
  try {
    const batch = writeBatch(firestore);

    for (const [key, value] of Object.entries(templates)) {
      const templateRef = doc(firestore, 'customers', uid, 'CariespondenceTemplates', key);
      batch.set(templateRef, { text: value });
    }

    await batch.commit();
    alert('All templates saved successfully');
  } catch (error) {
    console.error('Error saving all templates:', error);
  }
};

  const handleTextChange = (e) => {
    setTemplates({ ...templates, [selectedTemplate]: e.target.value });
  };

const filteredTreatments = treatments.filter(treatment =>
    treatment.toLowerCase().includes(searchInput.toLowerCase())
);

const deleteTemplate = async () => {
  if (!selectedTemplate) {
    alert("Please select a template to delete.");
    return;
  }

  // Ask for confirmation before deleting
  const isConfirmed = window.confirm("Are you sure you want to delete this template?");

  if (isConfirmed) {
    const uid = auth.currentUser.uid;
    const templateRef = doc(firestore, 'customers', uid, 'CariespondenceTemplates', selectedTemplate);

    try {
      await deleteDoc(templateRef); // Correctly delete the template document

      // Update the local state to remove the deleted template
      const updatedTemplates = { ...templates };
      delete updatedTemplates[selectedTemplate];
      setTemplates(updatedTemplates);

      // Reset the selected template
      setSelectedTemplate('');
      setSelectedTreatment('');

      alert('Template deleted successfully');
      togglePopup(); // Close the popup
    } catch (error) {
      console.error('Error deleting template:', error);
    }
  }
};

return (
  <main className="w-full p-6">
    {/* Top Bar: Search and Create New Exam Template */}
    <div className="flex items-center justify-between mb-6">
      <input
        type="text"
        placeholder="Search Cariespondence Template..."
        autoFocus
        value={searchInput}
        onChange={(e) => setSearchInput(e.target.value)}
        className="w-2/5 p-3 border border-gray-300 rounded-lg bg-gray-50 text-sm focus:outline-none focus:ring-2 focus:ring-primary-600"
        autoFocus
      />
      <button
        onClick={togglePopup}
        className="px-5 py-3 bg-gradient-to-br from-primary-400 to-teal-200 text-white font-medium rounded-lg hover:bg-primary-700 transition-colors"
      >
        Create New Cariespondence Template
      </button>
    </div>

    {/* Template List and Editor */}
    <div className="flex flex-col md:flex-row">
      {/* Template List */}
      <div className="md:w-1/3 md:pr-4 mb-6 md:mb-0">
        <div className="bg-white rounded-lg shadow p-4">
          <h3 className="text-lg font-semibold mb-4">Cariespondence Templates</h3>
          <ul className="space-y-2 max-h-[448px] overflow-y-auto">
            {filteredTreatments.length > 0 ? (
              filteredTreatments.map((treatment) => (
                <li key={treatment}>
                  <button
                    onClick={() => handleTemplateSelection(treatment)}
                    className={`w-full text-left px-3 py-2 rounded-md ${
                      selectedTreatment === treatment
                        ? 'bg-primary-100 text-primary-700'
                        : 'hover:bg-gray-100'
                    }`}
                  >
                    {treatment}
                  </button>
                </li>
              ))
            ) : (
              <p className="text-sm text-gray-500">No templates found.</p>
            )}
          </ul>
        </div>
      </div>

      {/* Template Editor */}
      <div className="md:w-2/3">
        {selectedTemplate ? (
          <div className="bg-white rounded-lg shadow p-4">
            <h3 className="text-lg font-semibold mb-4">Edit Template</h3>
            <textarea
              className="w-full h-96 p-2 border border-gray-300 rounded-lg bg-gray-50 text-sm focus:outline-none focus:ring-2 focus:ring-primary-600"
              rows="20"
              value={templates[selectedTemplate] || ''}
              onChange={handleTextChange}
              placeholder="Type the template here..."
              ref={textareaRef}
            ></textarea>
            <div className="flex justify-end space-x-3 mt-4">
              <button
                onClick={handleSaveTemplate}
                className="px-4 py-2 bg-gradient-to-br from-primary-400 to-teal-100 text-white rounded-md hover:bg-primary-700"
              >
                Save
              </button>
              <button
                onClick={handleSaveAllTemplates}
                className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md hover:bg-gray-400"
              >
                Save All
              </button>
              <button
                onClick={deleteTemplate}
                className="px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700"
              >
                Delete
              </button>
            </div>
          </div>
        ) : (
          <div className="bg-white rounded-lg shadow p-4">
            <p className="text-gray-500">Select a template to edit or create a new one.</p>
          </div>
        )}
      </div>
    </div>
  </main>
);

};

export default CariespondenceTemplates;