import React from 'react';

const AutoPatientLetter = () => {
    return (
        <div className="bg-gray-100 p-4 rounded-lg">
            <p className="text-sm text-gray-900">
                This will create a letter similar to the patient letter you can create in the notes list. It will be based on the patient's note and will be displayed here.
            </p>
        </div>
    );
};

export default AutoPatientLetter;
