// UpdatePopup.jsx

import React, { useState, useEffect, useRef } from 'react';
import { doc, setDoc, getDoc, collection } from 'firebase/firestore';
import { firestore, auth } from '../../firebase';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import SuccessToast from '../toast/SuccessToast'; // Make sure to import the SuccessToast component
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMicrophone, faStop, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import ActiveAudioAnalyser from '../activeaudioanalyser/ActiveAudioAnalyser';


const UpdatePopup = ({ onOk, onDoNotShowAgain }) => {
  const [isRecording, setIsRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [transcribedNote, setTranscribedNote] = useState('');
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [mediaStream, setMediaStream] = useState(null);
  const [languagePreference, setLanguagePreference] = useState('EN');
  const [showLoading, setShowLoading] = useState(false);
  const audioDetectedRef = useRef(false);
  const [analyserKey, setAnalyserKey] = useState(0);

  const audioChunksRef = useRef([]);

  useEffect(() => {
    // Fetch user's language preference
    const fetchPreferences = async () => {
      const user = auth.currentUser;
      if (user) {
        const userRef = doc(firestore, 'customers', user.uid);
        const userDoc = await getDoc(userRef);
        if (userDoc.exists()) {
          setLanguagePreference(userDoc.data().languagePreference || 'EN');
        } else {
          setLanguagePreference('EN');
        }
      }
    };
    fetchPreferences();
  }, []);

  const handleBackgroundClick = (e) => {
    if (e.target === e.currentTarget) {
      onDoNotShowAgain();
    }
  };

  const handleContentClick = (e) => {
    e.stopPropagation();
  };

  // Start Recording
  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      setMediaStream(stream);
      const newMediaRecorder = new MediaRecorder(stream);
      audioChunksRef.current = [];

      newMediaRecorder.ondataavailable = (event) => {
        audioChunksRef.current.push(event.data);
      };

      newMediaRecorder.onstop = async () => {
        const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/webm' });

        if (audioBlob.size === 0) {
          alert('Audio recording is blank. Please check your microphone and try again.');
          setIsRecording(false);
          return;
        }

        // Upload audio and get transcription
        await sendAudioToCloudFunction(audioBlob);
      };

      setMediaRecorder(newMediaRecorder);
      newMediaRecorder.start();
      setIsRecording(true);
    } catch (error) {
      console.error('Error in starting recording:', error);
      alert('Could not start recording. Please check your microphone permissions.');
    }
  };

  // Stop Recording
  const stopRecording = () => {
    if (mediaRecorder) {
      mediaRecorder.stop();
      setIsRecording(false);
      mediaRecorder.stream.getTracks().forEach((track) => track.stop());
      setTranscribedNote('Loading...');
      setShowLoading(true);
    }
  };

  // Send audio to cloud function for transcription
  const sendAudioToCloudFunction = async (audioBlob) => {
    try {
      const user = auth.currentUser;
      if (!user) {
        throw new Error('User not authenticated');
      }

      const idToken = await user.getIdToken();

      // Upload audio to Firebase Storage
      const userUID = user.uid;
      const filePath = `sdcm/${userUID}/${Date.now()}.webm`;
      const storageRef = ref(getStorage(), filePath);
      await uploadBytes(storageRef, audioBlob);
      const downloadURL = await getDownloadURL(storageRef);

      // Prepare the payload
      const payload = {
        filePath: filePath,
        language: languagePreference,
      };

      // Call the cloud function for transcription
      const response = await fetch('https://us-central1-digitaltco-c40e9.cloudfunctions.net/multilanguage', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`,
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Server Error: ${errorText}`);
      }

      const result = await response.json();
      setTranscribedNote(result.text);

      const feedbackRef = doc(collection(firestore, 'feedback'), user.uid);
      await setDoc(feedbackRef, {
        uid: user.uid,
        stopped: result.text,
        timestamp: new Date(),
      }, { merge: true });

      setShowLoading(false);
    } catch (error) {
      console.error('Error:', error);
      setShowLoading(false);
      alert('Sorry, there was an error processing your request. Please try again.');
    }
  };

// Submit feedback to Firestore as "sent"
const submitFeedback = async () => {
  try {
    const user = auth.currentUser;
    if (!user) {
      throw new Error('User not authenticated');
    }

    // Update the same Firestore document with "sent" data
    const feedbackRef = doc(collection(firestore, 'feedback'), user.uid);
    await setDoc(feedbackRef, {
      uid: user.uid,
      sent: transcribedNote,
      timestamp: new Date(),
    }, { merge: true });

    setShowSuccessToast(true);
    setTranscribedNote('');
  } catch (error) {
    console.error('Error submitting feedback:', error);
    alert('An error occurred while submitting your feedback. Please try again.');
  }
};

  return (
    <>
      {showSuccessToast && (
        <SuccessToast message="Feedback submitted successfully!" onClose={() => setShowSuccessToast(false)} />
      )}

      <div
        className="fixed inset-0 flex items-center justify-center z-[100] bg-black bg-opacity-50"
        onClick={handleBackgroundClick}
      >
        <div
          className="relative p-8 w-full max-w-2xl bg-white rounded-lg shadow max-h-[90vh] overflow-auto tracking-tight text-sm"
          onClick={handleContentClick}
        >
          <h1 className="text-xl font-bold mb-6 text-center">Thank You for Your Feedback!</h1>

          <div className="mb-6">
            <p className="mb-4">
              Thanks to your survey responses, our Net Promoter Score (NPS) for October is <strong>79.27</strong>. This surpasses
              the industry averages for B2B (25-33), SaaS (30), and even healthcare (27). While we’re proud of this,
              it's not enough—we’re driven to do even better.
            </p>

            <p className="mb-4">
              If you're facing any challenges or have suggestions, we’d love to hear them. Your feedback helps us
              improve and deliver more value.
            </p>

            <p className="mb-4">Share your thoughts. We’re listening and ready to act.</p>
          </div>

          {/* Audio Recording Section */}
          <div className="mb-6">
            <label className="block mb-2 text-xs text-primary-400">Your Feedback</label>
            <textarea
              value={transcribedNote}
              onChange={(e) => setTranscribedNote(e.target.value)}
              placeholder="Record your feedback or type here..."
              className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300"
              rows="6"
            ></textarea>

            {mediaStream && (
                    <div className="active-audio-analyser-container">
                        <ActiveAudioAnalyser
                            key={analyserKey}
                            audioStream={mediaStream}
                            onAudioDetected={() => {
                                // console.log('Audio detected callback triggered in SuperDentistCheatMode'); // Debugging
                                audioDetectedRef.current = true;
                            }}
                        />
                    </div>
                )}

            {/* Recording Buttons */}
            <div className="flex items-center mx-auto justify-center mt-2 pt-2">
              <button
                onClick={startRecording}
                disabled={isRecording}
                className={`${
                  isRecording
                    ? 'px-4 py-2 text-xl font-medium text-gray-500 bg-gray-300 border border-gray-200 rounded-s-lg cursor-not-allowed'
                    : 'px-4 py-2 text-xl font-medium text-primary-400 bg-white border border-gray-200 rounded-s-lg hover:bg-primary-400 hover:text-white'
                }`}
              >
                <FontAwesomeIcon icon={faMicrophone} />
              </button>

              <button
                onClick={stopRecording}
                disabled={!isRecording}
                className={`${
                  !isRecording
                    ? 'px-4 py-2 text-xl font-medium text-gray-500 bg-gray-300 border border-gray-200 cursor-not-allowed'
                    : 'px-4 py-2 text-xl font-medium text-primary-400 bg-white border border-gray-200 hover:bg-primary-400 hover:text-white'
                }`}
              >
                <FontAwesomeIcon icon={faStop} />
              </button>

              <button
                onClick={submitFeedback}
                disabled={!transcribedNote.trim()}
                className={`${
                  !transcribedNote.trim()
                    ? 'px-4 py-2 text-xl font-medium text-gray-500 bg-gray-300 border border-gray-200 rounded-e-lg cursor-not-allowed'
                    : 'px-4 py-2 text-xl font-medium text-primary-400 bg-white border border-gray-200 rounded-e-lg hover:bg-primary-400 hover:text-white'
                }`}
              >
                <FontAwesomeIcon icon={faPaperPlane} />
              </button>
            </div>
          </div>

          {/* Calls to Action */}
          <div className="flex justify-center mt-4 space-x-2">
            <button
              onClick={onOk}
              className="px-4 py-2 text-xs text-gray-400 bg-white border border-gray-200 rounded-lg hover:bg-primary-400 hover:text-white"
            >
              Close
            </button>
            <button
              onClick={onDoNotShowAgain}
              className="px-4 py-2 text-xs text-gray-400 rounded-lg border border-gray-200 hover:bg-primary-400 hover:text-white"
            >
              Do Not Show Again
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdatePopup;
