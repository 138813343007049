// RiskBankChecker.js
import { doc, getDoc } from 'firebase/firestore';
import { firestore, auth } from '../../firebase';

const RiskBankChecker = async (transcript) => {
    const currentlyActiveDocId = "activeRisks";
    const currentlyActiveRef = doc(firestore, 'customers', auth.currentUser.uid, 'currentlyActiveRisks', currentlyActiveDocId);

    try {
        const docSnap = await getDoc(currentlyActiveRef);
        if (docSnap.exists()) {
            const { triggers } = docSnap.data();
            const lowerCaseTranscript = transcript.toLowerCase();
            return triggers.filter(trigger => lowerCaseTranscript.includes(trigger.toLowerCase()));
        }
        return [];
    } catch (error) {
        console.error("Error fetching triggers:", error);
        return [];
    }
};

export default RiskBankChecker;
