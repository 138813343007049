import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { firestore, auth } from '../../firebase';
import PatientProfileNav from './PatientProfileNav';
import './PatientNotesInput.css'; 

const SocialHistory = () => {
  const [socialHistory, setSocialHistory] = useState('');
  const [selectedMainOption, setSelectedMainOption] = useState(null);
  const [smokingOptions, setSmokingOptions] = useState({
    cigs: false, vapes: false, 
    '5': false, '10': false, '15': false, '20': false, 
    interested: false, 'not interested': false, 'cessation advice given': false
  });
  const [alcoholOptions, setAlcoholOptions] = useState({
    socially: false, 
    '0-5': false, '5-10': false, '10-15': false, '15-20': false, 
    '20-25': false, '>25': false
  });
  const navigate = useNavigate();
  const { patientId } = useParams();

  useEffect(() => {
    // Define the Firestore document reference
    const patientRef = doc(firestore, 'customers', auth.currentUser.uid, 'patients', patientId);

    // Fetch the current social history data from Firestore
    const fetchSocialHistory = async () => {
      const patientDoc = await getDoc(patientRef);
      if (patientDoc.exists() && patientDoc.data().notes && patientDoc.data().notes.socialHistory) {
        setSocialHistory(patientDoc.data().notes.socialHistory); // Set the social history data to the local state
      }
    };

    fetchSocialHistory();
  }, [patientId]); // Dependency array ensures this effect runs when the component mounts or patientId changes

  const handleNext = async () => {
    const patientRef = doc(firestore, 'customers', auth.currentUser.uid, 'patients', patientId);
  
    // Create strings from selected options for both smoking and alcohol
    const selectedSmokingOptionsString = Object.entries(smokingOptions)
      .filter(([key, value]) => value)
      .map(([key]) => key.charAt(0).toUpperCase() + key.slice(1))
      .join(', ');
  
    const selectedAlcoholOptionsString = Object.entries(alcoholOptions)
      .filter(([key, value]) => value)
      .map(([key]) => key.charAt(0).toUpperCase() + key.slice(1))
      .join(', ');
  
    // Combine the strings with the text area input
    let historyString = '';
    if (selectedSmokingOptionsString) {
      historyString += `Smoking: ${selectedSmokingOptionsString}; `;
    }
    if (selectedAlcoholOptionsString) {
      historyString += `Alcohol: ${selectedAlcoholOptionsString}; `;
    }
    if (socialHistory) {
      historyString += `${socialHistory}`;
    }
  
    try {
      await updateDoc(patientRef, {
        'notes.socialHistory': historyString.trim()
      });
      navigate(`/mvp/patient-profile/${patientId}/motivators`);
    } catch (error) {
      console.error("Error updating document:", error);
    }
  };  

  const toggleOption = (category, option) => {
    if (category === 'smoking') {
      setSmokingOptions(prev => ({ ...prev, [option]: !prev[option] }));
    } else if (category === 'alcohol') {
      setAlcoholOptions(prev => ({ ...prev, [option]: !prev[option] }));
    }
  };

  const selectMainOption = (option) => {
    setSelectedMainOption(option);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleNext();
    }
  };

  return (
    <div className="patient-complaints-container">
      <PatientProfileNav patientId={patientId} />
      <h2 className="patient-complaints-h2">Social History</h2>

      <div className="main-options">
        <button onClick={() => selectMainOption('smoking')} className={`treatment-button ${selectedMainOption === 'smoking' ? 'selected' : ''}`}>Smoking</button>
        <button onClick={() => selectMainOption('alcohol')} className={`treatment-button ${selectedMainOption === 'alcohol' ? 'selected' : ''}`}>Alcohol</button>
      </div>

      {selectedMainOption === 'smoking' && (
        <>
        <div className="sub-options">
          {['cigs', 'vape'].map(option => (
            <button key={option} onClick={() => toggleOption('smoking', option)} className={`treatment-button ${smokingOptions[option] ? 'selected' : ''}`}>
              {option.charAt(0).toUpperCase() + option.slice(1)}
            </button>
          ))}
        </div>
        <div className="sub-options">
          {['5', '10', '15', '20'].map(option => (
            <button key={option} onClick={() => toggleOption('smoking', option)} className={`treatment-button ${smokingOptions[option] ? 'selected' : ''}`}>
              {option.charAt(0).toUpperCase() + option.slice(1)}
            </button>
          ))}
        </div>
        <div className="sub-options">
          {['interested', 'not interested'].map(option => (
            <button key={option} onClick={() => toggleOption('smoking', option)} className={`treatment-button ${smokingOptions[option] ? 'selected' : ''}`}>
              {option.charAt(0).toUpperCase() + option.slice(1)}
            </button>
          ))}
        </div>
        <div className="sub-options">
          {['cessation adivce given'].map(option => (
            <button key={option} onClick={() => toggleOption('smoking', option)} className={`treatment-button ${smokingOptions[option] ? 'selected' : ''}`}>
              {option.charAt(0).toUpperCase() + option.slice(1)}
            </button>
          ))}
        </div>
        </>
      )}

      {selectedMainOption === 'alcohol' && (
        <div className="sub-options">
          {Object.keys(alcoholOptions).map(option => (
            <button key={option} onClick={() => toggleOption('alcohol', option)} className={`treatment-button ${alcoholOptions[option] ? 'selected' : ''}`}>
              {option.charAt(0).toUpperCase() + option.slice(1)}
            </button>
          ))}
        </div>
      )}

      <textarea
        className="patient-complaints-textarea"
        placeholder="Enter social history here"
        value={socialHistory}
        onChange={(e) => setSocialHistory(e.target.value)}
        onKeyPress={handleKeyPress}
        autoFocus
      />
      <button className="patient-complaints-button" onClick={handleNext}>Next</button>
    </div>
  );
};

export default SocialHistory;
