export const fetchMailchimpData = async () => {
    try {
      // Placeholder API call or mock data
      const data = {
        campaigns: [
          {
            id: '1',
            name: 'New Product Launch',
            sentDate: '2023-01-10',
            openRate: 45,
            clickRate: 12,
          },
        ],
      };
      
      return data;
    } catch (error) {
      console.error('Error fetching Mailchimp data:', error);
      throw error;
    }
  };
  