import React from 'react';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

const DraggableList = ({ droppableId, items, isBin, renderComponent }) => (
    <Droppable droppableId={droppableId}>
        {(provided, snapshot) => (
            <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                className={`flex flex-col p-2 ml-4 bg-gradient-to-br from-primary-100 to-primary-50 rounded-xl shadow-sm min-h-[200px] ${isBin ? 'justify-center items-center p-8' : 'bg-gray-500'}`}
            >
                {isBin ? (
                    <FontAwesomeIcon icon={faTrash} className="text-red-500 text-3xl" />
                ) : (
                    items.map((item, index) => (
                        <Draggable key={item.id} draggableId={item.id} index={index}>
                            {(provided, snapshot) => (
                                <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    className="flex items-center p-2 mb-2 bg-white rounded-md shadow-md w-full"
                                    style={{
                                        userSelect: 'none',
                                        ...provided.draggableProps.style,
                                    }}
                                >
                                    {item.icon ? (
                                        <FontAwesomeIcon icon={item.icon} className="mr-2 text-gray-500" />
                                    ) : null}
                                    <div className="flex-grow">
                                        {renderComponent ? renderComponent(item) : item.content}
                                    </div>
                                </div>
                            )}
                        </Draggable>
                    ))
                )}
                {provided.placeholder}
            </div>
        )}
    </Droppable>
);

export default DraggableList;
