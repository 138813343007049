import React, { useState, useEffect } from 'react';
import { collection, getDocs, query, doc, addDoc, onSnapshot, deleteDoc, where } from 'firebase/firestore';
import { firestore, auth } from '../firebase';
import { loadStripe } from '@stripe/stripe-js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import './PaymentPage.css';

const PaymentPage = () => {
  const [products, setProducts] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const essentialsProductId = 'prod_PyLRbdsIRPKXyU';
  const performanceProductId = 'prod_PyLT5sVvc4Lp1f';

  useEffect(() => {
    const fetchProducts = async () => {
      const productsCollection = collection(firestore, 'products');
      const q = query(productsCollection, where('active', '==', true));
      const snapshot = await getDocs(q);
      let fetchedProducts = {};
      for (const doc of snapshot.docs) {
        const productId = doc.id;
        const pricesCollection = collection(doc.ref, 'prices');
        const priceSnapshot = await getDocs(pricesCollection);
        fetchedProducts[productId] = {
          ...doc.data(),
          prices: priceSnapshot.docs.map(priceDoc => ({
            priceId: priceDoc.id,
            ...priceDoc.data()
          }))
        };
      }
      setProducts(fetchedProducts);
    };
    fetchProducts().catch(err => {
      console.error("Error fetching products:", err);
    });
  }, []);
  
  const checkOut = async (productId) => {
    setIsLoading(true);
    const user = auth.currentUser;
    const product = products[productId];
    if (user && product && product.prices.length > 0) {
      const matchingPriceId = product.prices[0].priceId;

      const checkoutSessionCollection = collection(doc(firestore, 'customers', user.uid), 'checkout_sessions');
      // Delete existing checkout sessions
      const sessionsSnapshot = await getDocs(checkoutSessionCollection);
      for (const doc of sessionsSnapshot.docs) {
        await deleteDoc(doc.ref);
      }

      // Handle canceled subscriptions
      const subscriptionsCollection = collection(firestore, 'customers', user.uid, 'subscriptions');
      const subscriptionsSnapshot = await getDocs(subscriptionsCollection);
      for (const subDoc of subscriptionsSnapshot.docs) {
        const subscriptionData = subDoc.data();
        if (subscriptionData.status === 'canceled') {
          await deleteDoc(subDoc.ref);
        }
      }

      const payload = {
        mode: 'subscription',
        payment_method_types: ['card'],
        line_items: [{ price: matchingPriceId, quantity: 1 }],
        allow_promotion_codes: true,
        success_url: `${window.location.origin}/payment-success`,
        cancel_url: window.location.origin,
      };

      try {
        const docRef = await addDoc(checkoutSessionCollection, payload);
        const checkoutSessionDocRef = doc(firestore, 'customers', user.uid, 'checkout_sessions', docRef.id);
        onSnapshot(checkoutSessionDocRef, async (snap) => {
          const { error, sessionId } = snap.data();
          if (error) {
            alert(`Checkout error: ${error.message}`);
            setIsLoading(false);
          }
          if (sessionId) {
            const stripe = await loadStripe("pk_live_51NwhHfDGxaME3o6c1NlU5EkPLihJW5vzOw7ogz1Ayx9gdKKadXopC9LAfMBERgCO6F9m3PZ2NeW2uoQ0TLfPX6FB00bqeviOsh");
            stripe.redirectToCheckout({ sessionId });
            setIsLoading(false);
          }
        });
      } catch (error) {
        console.error("Error creating checkout session:", error);
        setIsLoading(false);
      }
    } else {
      console.error("Product or price information is missing.");
      setIsLoading(false);
    }
  };

  return (
    <section className="py-12 px-4 mx-auto max-w-screen-lg">
      {/* Title */}
      <h2 className="text-2xl md:text-4xl text-center mb-12">Enjoy 28 Days Free with DigitalTCO – Experience the Future of Dental Documentation</h2>

      {/* Pricing Options */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <div className="border rounded-lg p-8 flex flex-col h-full border-gray-200 text-gray-500 order-last">
          {/* Pricing Title */}
          <h3 className="text-xl mb-8 text-gray-700">After-Hours Documentation</h3>

          {/* Features */}
          <ul className="mb-8 flex-grow">
            <li className="mb-2 py-2 flex items-center">
              <FontAwesomeIcon icon={faCheck} className="mr-2 text-gray-500" />
              Handle notes after the patient has left
            </li>
            <li className="mb-2 py-2 flex items-center">
              <FontAwesomeIcon icon={faCheck} className="mr-2 text-gray-500" />
              AI-assisted note-taking
            </li>
            <li className="mb-2 py-2 flex items-center">
              <FontAwesomeIcon icon={faCheck} className="mr-2 text-gray-500" />
              Voice input for quicker documentation
            </li>
            <li className="mb-2 py-2 flex items-center">
              <FontAwesomeIcon icon={faTimes} className="mr-2 text-red-500" />
              No live appointment recording
            </li>
          </ul>

          {/* Price */}
          <div className="mb-4 text-gray-700">£34.95 / month</div>

          {/* Button */}
          {isLoading ? (
            <div className="payment-loading">
              <div className="payment-loading-bar">
                <p>Loading...</p>
              </div>
            </div>
          ) : (
            <button
              onClick={() => checkOut(essentialsProductId)}
              className="w-6/12 md:w-4/12 text-white focus:outline-none focus:ring-4 font-medium rounded-full text-md px-5 py-2.5 mb-2 bg-gray-400 hover:bg-gray-500 focus:ring-gray-300"
            >
              Start now <FontAwesomeIcon className="ml-1" icon={faArrowRight} />
            </button>
          )}
        </div>

        <div className="border rounded-lg p-8 flex flex-col h-full bg-white border-gray-300">
          {/* Pricing Title */}
          <h3 className="text-xl mb-8 text-black">Most Popular: Real-Time AI-Powered Note Taking</h3>

          {/* Features */}
          <ul className="mb-8 flex-grow">
            <li className="mb-2 py-2 flex items-center">
              <FontAwesomeIcon icon={faCheck} className="mr-2 text-black-500" />
              Live note-taking during appointments
            </li>
            <li className="mb-2 py-2 flex items-center">
              <FontAwesomeIcon icon={faCheck} className="mr-2 text-black-500" />
              Automated patient letters and summaries
            </li>
            <li className="mb-2 py-2 flex items-center">
              <FontAwesomeIcon icon={faCheck} className="mr-2 text-black-500" />
              Comprehensive treatment planning tools
            </li>
            <li className="mb-2 py-2 flex items-center">
              <FontAwesomeIcon icon={faCheck} className="mr-2 text-black-500" />
              Enhanced patient communication
            </li>
            <li className="mb-2 py-2 flex items-center">
              <FontAwesomeIcon icon={faCheck} className="mr-2 text-black-500" />
              Work-life balance optimized with real-time AI
            </li>
          </ul>

          {/* Price */}
          <div className="mb-4 text-black">£54.95 / month</div>

          {/* Button */}
          {isLoading ? (
            <div className="payment-loading">
              <div className="payment-loading-bar">
                <p>Loading...</p>
              </div>
            </div>
          ) : (
            <button
              onClick={() => checkOut(performanceProductId)}
              className="w-6/12 md:w-4/12 text-white focus:outline-none focus:ring-4 font-medium rounded-full text-md px-5 py-2.5 mb-2 bg-black hover:bg-primary-400 focus:ring-primary-300"
            >
              Start now <FontAwesomeIcon className="ml-1" icon={faArrowRight} />
            </button>
          )}
        </div>
      </div>
    </section>
  );
};

export default PaymentPage;
