const molarMailTemplate = {
    id: "1720723778824",
    name: "Treatment Plan",
    exampleNote: `Exam Note: - Summary of last exam: N/A 
    - Complaints: Patient is complaining of a broken tooth on the upper right quadrant, which is sensitive to cold. 
    - Medical History: Checked and updated, nil changes noted. 
    - Dental Old patient is a regular attender. 
    - Social History: Patient is a retired nurse. 
    - Smoking: Patient smokes 21 cigarettes a day. 
    - Alcohol: Patient drinks 21 units of alcohol a week. 
    Extra Oral Exam: 
      - TMJ: Clicking on the left-hand side TMJ. 
      - Face: NAD 
      - Neck: NAD 
      - Lips: NAD 
      - Skin: NAD 
      - Lymph Nodes: NAD 
      - Salivary glands: NAD 
      - Swelling: NAD 
      - Deviation: NAD 
    Intra Oral Exam: 
      - Soft tissues: Geographic tongue noted. 
      - Buccal mucosa: NAD 
      - Floor of mouth: NAD 
      - Tongue: Geographic tongue 
      - Hard Palatal: NAD 
      - Soft Palate: NAD 
      - Fauces / Pharynx: NAD 
    Teeth: Buccal cusp fracture on UR6. Caries noted on UR6 periapical radiograph. 
    - BPE: 333/333 
    - Periodontal Condition: Not healthy. 
    - Periodontal diagnosis: Periodontitis, Stage III, Grade B, Generalised. 
    - Radiographs: Periapical. Grade A. 
    - Radiograph report: 
      Radiograph Type Taken: Periapical, 
      Caries: UR6, 
      Pathology: None, 
      Bone Loss: 50%, 
      Justification: Diagnosis and treatment of broken and carious UR6. 
    - Comments on periodontal condition: Patient shows evidence of significant periodontal disease. 
    - OHI: Poor based on current smoking habits and periodontal BPE score. 
    - Periodontal diagnosis based on highest BPE code of 3, generalised due to more than 30% involvement and patient’s age and maximum bone loss percentage fitting Stage III, Grade B. 
    - Risk of complaining: High due to periodontal and carious issues. 
    - Wear facets: Nil. 
    - Basic Erosive Wear Examination (BEWE): 000/000 
    - Occlusion: no issues in static and dynamic. 
    - Crossbites: NAD 
    - Anterior open bite: NAD 
    - Overbite: Normal. 
    - Overjet: Normal. 
    - Caries Risk: High, in light of current findings. 
    - Periodontal Risk: High, considering BPE scores and smoking habit. 
    - Oral Cancer Risk: Low. 
    - Tooth Fracture Risk: Increased due to fracture of UR6. 
    - Observations: Significant wear and damage to UR6. 
    - Diagnoses: Fracture and caries on UR6, periodontitis, Stage III, Grade B, smoker's status. 
    - Recommended treatment: Composite restoration for UR6. 
    - Alternatives discussed: Amalgam restoration, no treatment. 
    - Patients preferred treatment plan: Composite restoration for UR6. 
    - Risks discussed: Post-operative sensitivity and potential shorter lifespan of restoration due to heavily restored dentition. 
    - Benefits: Restoration of functionality and aesthetics, temporary solution before possible need for indirect restoration. 
    - Treatment cost discussed: 225 pounds. 
    - Discussion of fees: Advised cost for composite restoration is 225 pounds. 
    - Treatment today: Composite restoration initiated for UR6. 
    - Preventative plan: Advice to cut down or quit smoking, regular hygiene visits, and monitoring of periodontal condition. 
    - Next Visit: Patient scheduled to return for evaluation of restoration and periodontal condition.`,
    rightItems: [
      {
        id: "right-item-5-1721908210150",
        type: "AutoPatientLetter",
        content: `""`
      },
      {
        id: "right-item-1-1720724171020",
        type: "CustomText",
        content: "<p>I have attached a photo of your teeth, with an explanation of what is going on, and what we plan to do:</p>"
      },
      {
        id: "right-item-6-1721838693941",
        type: "IdealVsActualTeeth",
        content: `""This section will show a comparison between ideal teeth and the patient's teeth with annotations.""`
      },
      {
        id: "right-item-7-1721678887833",
        type: "ChartSummary",
        content: `""The AI will take your charting and treatment planning information and write it in patient friendly language.""`
      },
      {
        id: "right-item-1-1720724274131",
        type: "CustomText",
        content: "<p>Here is some information regarding the proposed treatment:</p>"
      },
      {
        id: "right-item-3-1721838763192",
        type: "CustomForms",
        content: `""`
      },
      {
        id: "right-item-1-1721908237831",
        type: "CustomText",
        content: "<p>Remember, we are here to help. Keep in touch, and we look forward to seeing you soon. Kind regards Dr. Kevin Shannon BDS</p>"
      }
    ]
  };
  
  export default molarMailTemplate;
  