export const defaultTeethDataUniversal = [
    { number: '1', description: '', segments: { occlusal: '', mesial: '', distal: '', buccal: '', lingual: '' }},
    { number: '2', description: '', segments: { occlusal: '', mesial: '', distal: '', buccal: '', lingual: '' }},
    { number: '3', description: '', segments: { occlusal: '', mesial: '', distal: '', buccal: '', lingual: '' }},
    { number: '4', description: '', segments: { occlusal: '', mesial: '', distal: '', buccal: '', lingual: '' }},
    { number: '5', description: '', segments: { occlusal: '', mesial: '', distal: '', buccal: '', lingual: '' }},
    { number: '6', description: '', segments: { occlusal: '', mesial: '', distal: '', buccal: '', lingual: '' }},
    { number: '7', description: '', segments: { occlusal: '', mesial: '', distal: '', buccal: '', lingual: '' }},
    { number: '8', description: '', segments: { occlusal: '', mesial: '', distal: '', buccal: '', lingual: '' }},
    { number: '9', description: '', segments: { occlusal: '', mesial: '', distal: '', buccal: '', lingual: '' }},
    { number: '10', description: '', segments: { occlusal: '', mesial: '', distal: '', buccal: '', lingual: '' }},
    { number: '11', description: '', segments: { occlusal: '', mesial: '', distal: '', buccal: '', lingual: '' }},
    { number: '12', description: '', segments: { occlusal: '', mesial: '', distal: '', buccal: '', lingual: '' }},
    { number: '13', description: '', segments: { occlusal: '', mesial: '', distal: '', buccal: '', lingual: '' }},
    { number: '14', description: '', segments: { occlusal: '', mesial: '', distal: '', buccal: '', lingual: '' }},
    { number: '15', description: '', segments: { occlusal: '', mesial: '', distal: '', buccal: '', lingual: '' }},
    { number: '16', description: '', segments: { occlusal: '', mesial: '', distal: '', buccal: '', lingual: '' }},
    { number: '17', description: '', segments: { occlusal: '', mesial: '', distal: '', buccal: '', lingual: '' }},
    { number: '18', description: '', segments: { occlusal: '', mesial: '', distal: '', buccal: '', lingual: '' }},
    { number: '19', description: '', segments: { occlusal: '', mesial: '', distal: '', buccal: '', lingual: '' }},
    { number: '20', description: '', segments: { occlusal: '', mesial: '', distal: '', buccal: '', lingual: '' }},
    { number: '21', description: '', segments: { occlusal: '', mesial: '', distal: '', buccal: '', lingual: '' }},
    { number: '22', description: '', segments: { occlusal: '', mesial: '', distal: '', buccal: '', lingual: '' }},
    { number: '23', description: '', segments: { occlusal: '', mesial: '', distal: '', buccal: '', lingual: '' }},
    { number: '24', description: '', segments: { occlusal: '', mesial: '', distal: '', buccal: '', lingual: '' }},
    { number: '25', description: '', segments: { occlusal: '', mesial: '', distal: '', buccal: '', lingual: '' }},
    { number: '26', description: '', segments: { occlusal: '', mesial: '', distal: '', buccal: '', lingual: '' }},
    { number: '27', description: '', segments: { occlusal: '', mesial: '', distal: '', buccal: '', lingual: '' }},
    { number: '28', description: '', segments: { occlusal: '', mesial: '', distal: '', buccal: '', lingual: '' }},
    { number: '29', description: '', segments: { occlusal: '', mesial: '', distal: '', buccal: '', lingual: '' }},
    { number: '30', description: '', segments: { occlusal: '', mesial: '', distal: '', buccal: '', lingual: '' }},
    { number: '31', description: '', segments: { occlusal: '', mesial: '', distal: '', buccal: '', lingual: '' }},
    { number: '32', description: '', segments: { occlusal: '', mesial: '', distal: '', buccal: '', lingual: '' }}
  ];
