import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { firestore, auth } from '../../firebase';
import PatientProfileNav from './PatientProfileNav';
import './PatientNotesInput.css'; // Assuming this CSS contains necessary styles

const PatientComplaints = () => {
  const [complaints, setComplaints] = useState('');
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [painOptions, setPainOptions] = useState('');
  const [appearanceOptions, setAppearanceOptions] = useState('');
  const navigate = useNavigate();
  const { patientId } = useParams();

  useEffect(() => {
    const patientRef = doc(firestore, 'customers', auth.currentUser.uid, 'patients', patientId);
    const fetchComplaints = async () => {
      const patientDoc = await getDoc(patientRef);
      if (patientDoc.exists()) {
        const data = patientDoc.data().notes;
        if (data) {
          setComplaints(data.complaints || '');
          setPainOptions(data.painOptions || { 
            UR: false, UL: false, LL: false, LR: false, 
            hot: false, cold: false, biting: false,
            dull: false, sharp: false, throbbing: false,
            constant: false, intermittent: false,
            localised: false, spreads: false,
            swelling: false,
            'Sleep disturbance': false,
            'painkillers Help': false, 'Painkillers Dont Help': false,
            'mobile tooth': false,
            days: false, weeks: false, months: false,
          });
          setAppearanceOptions(data.appearanceOptions || { 
            color: false, alignment: false, shape: false,
            crooked: false, 'Stick out': false, fangs: false, gaps: false,
            days: false, weeks: false, months: false, years: false,
            'getting worse': false,
            'hides smile': false,         
          });
        }
      }
    };
    fetchComplaints();
  }, [patientId]);

  const handleNext = async () => {
    const patientRef = doc(firestore, 'customers', auth.currentUser.uid, 'patients', patientId);
  
    // Create strings from selected options
    const selectedPainOptionsString = Object.entries(painOptions)
      .filter(([key, value]) => value)
      .map(([key]) => key.charAt(0).toUpperCase() + key.slice(1))
      .join(', ');
  
    const selectedAppearanceOptionsString = Object.entries(appearanceOptions)
      .filter(([key, value]) => value)
      .map(([key]) => key.charAt(0).toUpperCase() + key.slice(1))
      .join(', ');
  
    // Combine the strings with the text area input
    let complaintsString = '';
    if (selectedPainOptionsString) {
      complaintsString += `Pain: ${selectedPainOptionsString},`;
    }
    if (selectedAppearanceOptionsString) {
      complaintsString += ` Appearance: ${selectedAppearanceOptionsString},`;
    }
    if (complaints) {
      complaintsString += ` ${complaints}`;
    }
  
    try {
      await updateDoc(patientRef, {
        'notes.complaints': complaintsString.trim()
      });
      navigate(`/mvp/patient-profile/${patientId}/past-dental-history`);
    } catch (error) {
      console.error("Error updating document:", error);
    }
  };  

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleNext();
    }
  };

  const toggleOption = (category, option) => {
    if (category === 'pain') {
      setPainOptions(prev => ({ ...prev, [option]: !prev[option] }));
    } else if (category === 'appearance') {
      setAppearanceOptions(prev => ({ ...prev, [option]: !prev[option] }));
    }
  };

  const selectCategory = (category) => {
    setSelectedCategory(category);
  };

  return (
    <div className="patient-complaints-container">
      <PatientProfileNav patientId={patientId} />
      <h2 className="patient-complaints-h2">Patient Complaints</h2>

      <div className="main-options">
        <button onClick={() => selectCategory('pain')} className={`treatment-button ${selectedCategory === 'pain' ? 'selected' : ''}`}>Pain</button>
        <button onClick={() => selectCategory('appearance')} className={`treatment-button ${selectedCategory === 'appearance' ? 'selected' : ''}`}>Appearance</button>
      </div>

      {selectedCategory === 'pain' && (
        <>
          <div className="sub-options">
            {['UR', 'UL', 'LL', 'LR'].map(option => (
              <button
                key={option}
                onClick={() => toggleOption('pain', option)}
                className={`treatment-button ${painOptions[option] ? 'selected' : ''}`}
              >
                {option}
              </button>
            ))}
          </div>
          <div className="sub-options">
            {['hot', 'cold', 'biting'].map(option => (
              <button
                key={option}
                onClick={() => toggleOption('pain', option)}
                className={`treatment-button ${painOptions[option] ? 'selected' : ''}`}
              >
                {option.charAt(0).toUpperCase() + option.slice(1)}
              </button>
            ))}
          </div>
          <div className="sub-options">
            {['dull', 'sharp', 'throbbing'].map(option => (
              <button
                key={option}
                onClick={() => toggleOption('pain', option)}
                className={`treatment-button ${painOptions[option] ? 'selected' : ''}`}
              >
                {option.charAt(0).toUpperCase() + option.slice(1)}
              </button>
            ))}
          </div>
          <div className="sub-options">
            {['constant', 'intermittent'].map(option => (
              <button
                key={option}
                onClick={() => toggleOption('pain', option)}
                className={`treatment-button ${painOptions[option] ? 'selected' : ''}`}
              >
                {option.charAt(0).toUpperCase() + option.slice(1)}
              </button>
            ))}
          </div>
          <div className="sub-options">
            {['localised', 'spreads'].map(option => (
              <button
                key={option}
                onClick={() => toggleOption('pain', option)}
                className={`treatment-button ${painOptions[option] ? 'selected' : ''}`}
              >
                {option.charAt(0).toUpperCase() + option.slice(1)}
              </button>
            ))}
          </div>
          <div className="sub-options">
            {['swelling', 'Sleep disturbance'].map(option => (
              <button
                key={option}
                onClick={() => toggleOption('pain', option)}
                className={`treatment-button ${painOptions[option] ? 'selected' : ''}`}
              >
                {option.charAt(0).toUpperCase() + option.slice(1)}
              </button>
            ))}
          </div>
          <div className="sub-options">
            {['painkillers Help', 'Painkillers Dont Help'].map(option => (
              <button
                key={option}
                onClick={() => toggleOption('pain', option)}
                className={`treatment-button ${painOptions[option] ? 'selected' : ''}`}
              >
                {option.charAt(0).toUpperCase() + option.slice(1)}
              </button>
            ))}
          </div>
          <div className="sub-options">
            {['mobile tooth'].map(option => (
              <button
                key={option}
                onClick={() => toggleOption('pain', option)}
                className={`treatment-button ${painOptions[option] ? 'selected' : ''}`}
              >
                {option.charAt(0).toUpperCase() + option.slice(1)}
              </button>
            ))}
          </div>
          <div className="sub-options">
            {['days', 'weeks', 'months'].map(option => (
              <button
                key={option}
                onClick={() => toggleOption('pain', option)}
                className={`treatment-button ${painOptions[option] ? 'selected' : ''}`}
              >
                {option.charAt(0).toUpperCase() + option.slice(1)}
              </button>
            ))}
          </div>
        </>
      )}

      {selectedCategory === 'appearance' && (
        <>
        <div className="sub-options">
          {['color', 'alignment', 'shape'].map(option => (
            <button
              key={option}
              onClick={() => toggleOption('appearance', option)}
              className={`treatment-button ${appearanceOptions[option] ? 'selected' : ''}`}
            >
              {option.charAt(0).toUpperCase() + option.slice(1)}
            </button>
          ))}
        </div>
        <div className="sub-options">
          {['crooked', 'Stick out', 'fangs', 'gaps'].map(option => (
            <button
              key={option}
              onClick={() => toggleOption('appearance', option)}
              className={`treatment-button ${appearanceOptions[option] ? 'selected' : ''}`}
            >
              {option.charAt(0).toUpperCase() + option.slice(1)}
            </button>
          ))}
        </div>
        <div className="sub-options">
            {['days', 'weeks', 'months', 'years'].map(option => (
              <button
                key={option}
                onClick={() => toggleOption('appearance', option)}
                className={`treatment-button ${appearanceOptions[option] ? 'selected' : ''}`}
              >
                {option.charAt(0).toUpperCase() + option.slice(1)}
              </button>
            ))}
        </div>
        <div className="sub-options">
          {['getting worse'].map(option => (
            <button
              key={option}
              onClick={() => toggleOption('appearance', option)}
              className={`treatment-button ${appearanceOptions[option] ? 'selected' : ''}`}
            >
              {option.charAt(0).toUpperCase() + option.slice(1)}
            </button>
          ))}
        </div>
        <div className="sub-options">
          {['hides smile'].map(option => (
            <button
              key={option}
              onClick={() => toggleOption('appearance', option)}
              className={`treatment-button ${appearanceOptions[option] ? 'selected' : ''}`}
            >
              {option.charAt(0).toUpperCase() + option.slice(1)}
            </button>
          ))}
        </div>
      </>
      )}

      <textarea
        className="patient-complaints-textarea"
        placeholder="Enter patient complaints here"
        value={complaints}
        onChange={(e) => setComplaints(e.target.value)}
        onKeyPress={handleKeyPress}
        autoFocus
      />

      <button className="treatment-plan-button" onClick={handleNext}>Next</button>
    </div>
  );
};

export default PatientComplaints;