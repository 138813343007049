import React, { useState, useEffect } from 'react';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { doc, getDoc, setDoc, getFirestore, collection } from 'firebase/firestore';
import { auth, firestore } from '../../firebase';
import LoadingPopup from '../patientnotes/LoadingPopup';
import axios from 'axios';

const ReferralLetters = ({ noteContent }) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [showLoadingPopup, setShowLoadingPopup] = useState(false);
  const [letterheadURL, setLetterheadURL] = useState('');
  const [languagePreference, setLanguagePreference] = useState('EN');
  const [dentistName, setDentistName] = useState('Dentist');
  const [userProfile, setUserProfile] = useState(null);

  const uid = auth.currentUser ? auth.currentUser.uid : null;

  const removeDoubleAsterisks = (text) => {
    let formattedText = text.replace(/\*\*(.*?)\*\*/g, '$1');
    formattedText = formattedText.replace(/##/g, ''); // Remove ##
    formattedText = formattedText.replace(/###/g, ''); // Remove ###
    formattedText = formattedText.replace(/【\d+†.*?】/g, ''); // Remove citations
    return formattedText;
  };

  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed in JavaScript
    const year = String(date.getFullYear()).substr(-2); // Get last two digits of the year

    return `${day}/${month}/${year}`;
  };

  const fetchSettings = async () => {
    if (!uid) return;
    const settingsRef = doc(firestore, 'customers', uid, 'molarmailer', 'settings');
    const settingsDoc = await getDoc(settingsRef);
    if (settingsDoc.exists()) {
      return settingsDoc.data();
    }
    return {
      font: 'Calibri',
      fontSize: '11',
      footerContent: 'Change this in Communication Hub > Molar Mail',
    };
  };

  useEffect(() => {
    const fetchUserData = async () => {
      const user = auth.currentUser;
      if (user) {
        const userRef = doc(getFirestore(), 'customers', user.uid);
        const userDoc = await getDoc(userRef);
        if (userDoc.exists()) {
            setUserProfile(userDoc.data());
            const data = userDoc.data();
            if (data.letterheadURL) {
                setLetterheadURL(data.letterheadURL);
            }
        } else {
          console.log('No user data found');
        }
      } else {
        console.log('No user logged in');
      }
    };

    fetchUserData();
  }, []); // No 'user' dependency needed

  useEffect(() => {
    const fetchPreferences = async () => {
      const user = auth.currentUser;
      if (user) {
        const userRef = doc(getFirestore(), 'customers', user.uid);
        const userDoc = await getDoc(userRef);
        if (userDoc.exists()) {
          setLanguagePreference(userDoc.data().languagePreference || 'EN');
          setDentistName(userDoc.data().name || 'Dentist');
        } else {
          // Fallback if no data in Firestore
          setLanguagePreference('EN');
        }
      }
    };

    fetchPreferences();
  }, []);

  const removeLeadingWhitespace = (htmlString) => {
    return htmlString.replace(/^\s+</gm, '<').replace(/<\/?(ol|ul|strong|i|u|b)>/gm, '');
  };

  const handleButtonClick = async () => {
    setIsProcessing(true);
    setShowLoadingPopup(true);
    console.log('Sending content to cloud function:', noteContent);
    try {
      const user = auth.currentUser;
      if (!user) {
        throw new Error('User not authenticated');
      }

      const idToken = await user.getIdToken();

      const prompt = `YOU MUST REPLY IN ${languagePreference} LANGUAGE.
                ${noteContent}`;

      // Step 1: Send content to ReferralLetters to format it
      const letterResponse = await fetch(
        'https://us-central1-digitaltco-c40e9.cloudfunctions.net/refsbroke',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${idToken}`,
          },
          body: JSON.stringify({ prompt, dentistName }),
        }
      );

      const letterData = await letterResponse.json();
      console.log('Formatted letter content:', letterData.text);

      // Clean up the letter content using removeDoubleAsterisks function
      const cleanedLetterText = removeDoubleAsterisks(letterData.text);
      console.log('Cleaned letter content:', cleanedLetterText);

      const finalLetter = `
      ${removeLeadingWhitespace(`<right>Dr. ${userProfile?.name}</right>`)}
      ${removeLeadingWhitespace(`<right>${userProfile?.practiceName}</right>`)}
      ${removeLeadingWhitespace(`<right>${userProfile?.practiceAddress}</right>`)}
      ${removeLeadingWhitespace(`<right>${userProfile?.practicePostcode}</right>\n`)}
      ${removeLeadingWhitespace(`<right>${userProfile?.practiceEmail}</right>`)}
      ${removeLeadingWhitespace(`<right>${userProfile?.practicePhoneNumber}</right>\n`)}
      ${removeLeadingWhitespace(`<right>${formatDate(new Date())}</right>\n\n`)}<br>
      ${removeLeadingWhitespace(cleanedLetterText)}
    `;
    console.log('Generated Letter:', finalLetter);
    
      const settings = await fetchSettings();

      // Step 2: Send cleaned content to DocxCreator to create a .docx file
      const docxResponse = await axios.post(
        'https://us-central1-digitaltco-c40e9.cloudfunctions.net/DocxCreatorMMModular',
        {
          textContent: finalLetter,
          letterheadURL,
          settings,
          uid,
        },
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      );

      if (docxResponse.status !== 200) {
        throw new Error('Network response was not ok in DocxCreator');
      }

      const docxData = docxResponse.data;

      // Download the file using the URL provided
      const { url } = docxData;
      const a = document.createElement('a');
      a.href = url;
      a.download = 'Referral-Letter.docx';
      document.body.appendChild(a);
      a.click();
      a.remove();

      // If you need to upload and save the letter, continue with your code
      const storage = getStorage();
      const storageRef = ref(storage, `letters/${uid}/referral-letter-${Date.now()}.docx`);

      // Download the file as a blob
      const response = await fetch(url);
      const blob = await response.blob();

      // Upload the blob to Firebase Storage
      const snapshot = await uploadBytes(storageRef, blob);
      const downloadURL = await getDownloadURL(snapshot.ref);

      // Save the download URL to Firestore
      const noteRef = doc(collection(firestore, 'customers', uid, 'notes'));
      await setDoc(noteRef, {
        title: 'Referral Letter',
        content: downloadURL,
        timestamp: new Date(),
      });

      setIsProcessing(false);
      setShowLoadingPopup(false);
    } catch (error) {
      console.error('Error:', error);
      setIsProcessing(false);
      setShowLoadingPopup(false);
      alert('Sorry, there was an error with the AI. Please try again.');
    }
  };

  return (
    <>
      <button
        onClick={handleButtonClick}
        disabled={isProcessing}
        className="px-4 py-2 text-xs text-primary-400 bg-white border-t border-b border-e border-gray-200 hover:bg-primary-400 hover:text-white"
      >
        {isProcessing ? 'Writing Letter...' : 'Referral Letter'}
      </button>

      {showLoadingPopup && <LoadingPopup />}
    </>
  );
};

export default ReferralLetters;
