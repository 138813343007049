// SessionContext.js
import React, { createContext, useState, useEffect, useContext } from 'react';
import { onSnapshot, doc } from 'firebase/firestore';
import { firestore } from '../firebase'; // adjust path if needed

const SessionContext = createContext();

export const useSession = () => {
    return useContext(SessionContext);
};

export const SessionProvider = ({ children }) => {
    const [sessionId, setSessionId] = useState(null);
    const [isSessionValid, setIsSessionValid] = useState(true);

    useEffect(() => {
        if (sessionId) {
            const sessionRef = doc(firestore, 'sessions', sessionId);

            const unsubscribe = onSnapshot(sessionRef, 
              (docSnapshot) => {
                console.log('Session document status:', docSnapshot.exists());
                if (docSnapshot.exists()) {
                    setIsSessionValid(docSnapshot.data().valid);
                } else {
                    setIsSessionValid(false);
                }
                console.log('isSessionValid state:', isSessionValid);
              },
              (error) => {
                console.error("Error with session snapshot listener:", error);
                setIsSessionValid(false); // Set session as invalid on any error
              }
              
            
            );

            return () => {
                console.log('Unsubscribing from session document');
                unsubscribe();
            };
        }
    }, [sessionId]);

    return (
        <SessionContext.Provider value={{ sessionId, setSessionId, isSessionValid }}>
            {children}
        </SessionContext.Provider>
    );
};