import React, { useEffect, useState } from 'react';
import { auth } from '../firebase';
import { useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { getFunctions, httpsCallable } from 'firebase/functions';

const withAdminAccess = (WrappedComponent) => {
  return (props) => {
    const [hasAccess, setHasAccess] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
      const checkAccess = async () => {
        const user = auth.currentUser;

        if (user) {
          const idTokenResult = await user.getIdTokenResult();
          const isAdmin = idTokenResult.claims.roles && idTokenResult.claims.roles.includes('admin');
          
          if (isAdmin) {
            setHasAccess(true);
          } else {
            navigate('/mvp/treatmentplanner');
          }
        } else {
          navigate('/mvp/treatmentplanner');
        }
      };

      checkAccess();
    }, [navigate]);

    return hasAccess ? <WrappedComponent {...props} /> : null;
  };
};

export default withAdminAccess;
