// src/data/initialKanban.js

const initialKanban = {
    columns: {
      'column-1': {
        id: 'column-1',
        name: 'To Do',
        cards: [
          { id: 'card-1', content: 'Sample Task 1' },
          { id: 'card-2', content: 'Sample Task 2' },
        ],
      },
      'column-2': {
        id: 'column-2',
        name: 'In Progress',
        cards: [
          { id: 'card-3', content: 'Sample Task 3' },
        ],
      },
      'column-3': {
        id: 'column-3',
        name: 'Done',
        cards: [],
      },
    },
    columnOrder: ['column-1', 'column-2', 'column-3'],
  };
  
  export default initialKanban;
  