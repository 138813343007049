import React, { useState, useEffect } from 'react';
import { doc, setDoc, getDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { firestore, auth } from '../../firebase';
import SuccessToast from '../toast/SuccessToast'; // Import the Toast component

const ShareTemplateButton = ({ templateText, selectedTemplate }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [templateName, setTemplateName] = useState('');
  const [dentistName, setDentistName] = useState('');
  const [loading, setLoading] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState(''); // State to hold the toast message
  const [showInfo, setShowInfo] = useState(true); // State to toggle the explanation

  // Fetch the dentist's name and template name when the modal is opened
  useEffect(() => {
    const fetchInitialData = async () => {
      setLoading(true);
      const uid = auth.currentUser?.uid;

      if (!uid) {
        console.error('User not authenticated. Unable to get UID.');
        alert('User not authenticated. Please log in and try again.');
        setLoading(false);
        return;
      }

      try {
        // Fetch dentist's name from Firestore
        const userRef = doc(firestore, 'customers', uid);
        const userDoc = await getDoc(userRef);
        if (userDoc.exists()) {
          setDentistName(userDoc.data().name || 'Dentist');
        } else {
          setDentistName('Dentist');
        }

        // Fetch selected template details
        if (selectedTemplate) {
          setTemplateName(selectedTemplate.label || 'Untitled Template');
        } else {
          setTemplateName('Untitled Template');
        }
      } catch (error) {
        console.error('Error fetching data from Firestore:', error);
      } finally {
        setLoading(false);
      }
    };

    if (isModalOpen) {
      fetchInitialData();
    }
  }, [isModalOpen, selectedTemplate]);

  const handleShareTemplate = async () => {
    if (!templateText) {
      alert('No template text available to share.');
      return;
    }

    const uid = auth.currentUser?.uid;

    if (!uid) {
      console.error('User not authenticated. Unable to get UID.');
      alert('User not authenticated. Please log in and try again.');
      return;
    }

    try {
      // Check if the template with the same name and author already exists
      const sharedTemplatesRef = collection(firestore, 'sharedtemplates');
      const existingTemplateQuery = query(
        sharedTemplatesRef,
        where('name', '==', templateName),
        where('author', '==', dentistName)
      );
      const existingTemplateSnapshot = await getDocs(existingTemplateQuery);

      if (!existingTemplateSnapshot.empty) {
        setToastMessage(`Template "${templateName}" by ${dentistName} already exists.`);
        setIsModalOpen(false); // Close modal after successful submission
        setShowToast(true);
        return;
      }

      // If no existing template found, share the new template
      const sharedTemplateRef = doc(sharedTemplatesRef, `${Date.now()}`); // Creating a unique ID based on user and timestamp

      await setDoc(sharedTemplateRef, {
        content: templateText,
        name: templateName,
        author: dentistName,
        timestamp: new Date(),
        steals: 0, // Set steals to 0 when adding a new template
      });

      setToastMessage(`Template "${templateName}" shared successfully.`);
      setShowToast(true);
      setIsModalOpen(false); // Close modal after successful submission
    } catch (error) {
      console.error('Error sharing template:', error);
      setToastMessage('Failed to share the template. Please try again.');
      setShowToast(true);
    }
  };

  return (
    <>
      <button
        className="mt-2 px-4 py-2 text-xs text-primary-400 bg-white border border-gray-200 rounded-lg hover:bg-primary-400 hover:text-white"
        onClick={() => setIsModalOpen(true)}
        disabled={!templateText}
      >
        Share Template
      </button>

      {/* Modal for Inputting Template Details */}
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 text-sm">
          <div className="bg-white p-6 rounded shadow-lg w-1/3">
            {showInfo && (
              <div className="mb-4 p-4 bg-primary-100 text-primary-700 rounded-lg">
                <p>
                  By sharing your template, you're helping other dentists save time and improve their workflows.
                  DigitalTCO is all about dentists helping dentists. Your contribution adds value to the community
                  and reinforces the spirit of collaboration!
                </p>
                <div className="flex justify-end">
                  <button
                    className="mt-2 text-xs text-primary-400 hover:underline"
                    onClick={() => setShowInfo(false)}
                  >
                    Got it, hide this.
                  </button>
                </div>
              </div>
            )}
            {loading ? (
              <p>Loading...</p>
            ) : (
              <>
                <input
                  type="text"
                  placeholder="Template Name"
                  className="block w-full p-2 mb-4 border border-gray-300 rounded"
                  value={templateName}
                  onChange={(e) => setTemplateName(e.target.value)}
                />
                <input
                  type="text"
                  placeholder="Dentist Name"
                  className="block w-full p-2 mb-4 border border-gray-300 rounded"
                  value={dentistName}
                  onChange={(e) => setDentistName(e.target.value)}
                />
                <div className="flex justify-end mt-2">
                  <div className="inline-flex rounded-md shadow-sm" role="group">
                    <button
                      onClick={() => setIsModalOpen(false)}
                      className="px-4 py-2 text-xs text-primary-400 bg-white border border-gray-200 rounded-s-lg hover:bg-primary-400 hover:text-white"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={handleShareTemplate}
                      className={`${
                        !templateName || !dentistName
                          ? 'px-4 py-2 text-xs text-gray-500 bg-gray-300 rounded-e-lg border border-gray-200 cursor-not-allowed'
                          : 'px-4 py-2 text-xs text-primary-400 bg-white border border-gray-200 rounded-e-lg hover:bg-primary-400 hover:text-white'
                      }`}
                      disabled={!templateName || !dentistName}
                    >
                      Share
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      )}

      {/* Display Toast when showToast is true */}
      {showToast && <SuccessToast message={toastMessage} onClose={() => setShowToast(false)} />}
    </>
  );
};

export default ShareTemplateButton;
