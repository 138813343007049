import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { doc, updateDoc, getDoc } from 'firebase/firestore';
import { firestore, auth } from '../../firebase';
import PatientProfileNav from './PatientProfileNav';
import './PatientNotesInput.css'; 

const OralHygiene = () => {
  const navigate = useNavigate();
  const { patientId } = useParams();
  const [oralHygiene, setOralHygiene] = useState('');

  useEffect(() => {
    const patientRef = doc(firestore, 'customers', auth.currentUser.uid, 'patients', patientId);

    const fetchOralHygiene = async () => {
      const patientDoc = await getDoc(patientRef);
      if (patientDoc.exists() && patientDoc.data().notes.oralHygiene) {
        setOralHygiene(patientDoc.data().notes.oralHygiene); // Set the oral hygiene data to the local state
      }
    };

    fetchOralHygiene();
  }, [patientId]);

  const handleOralHygieneClick = async (value) => {
    const patientRef = doc(firestore, 'customers', auth.currentUser.uid, 'patients', patientId);

    await updateDoc(patientRef, {
      'notes.oralHygiene': value,
    });

    console.log("Updated Patient Data:", { oralHygiene: value });

    navigate(`/mvp/patient-profile/${patientId}/caries-risk`);
  };

  return (
    <div className="patient-complaints-container">
      <PatientProfileNav patientId={patientId} />
      <h2 className="patient-complaints-h2">Oral Hygiene</h2>
      <div className="patient-complaints-button-group">
        <button onClick={() => handleOralHygieneClick('Good')} className={`patient-complaints-button ${oralHygiene === 'Good' ? 'selected' : ''}`}>Good</button>
        <button onClick={() => handleOralHygieneClick('Adequate')} className={`patient-complaints-button ${oralHygiene === 'Adequate' ? 'selected' : ''}`}>Adequate</button>
        <button onClick={() => handleOralHygieneClick('Poor')} className={`patient-complaints-button ${oralHygiene === 'Poor' ? 'selected' : ''}`}>Poor</button>
      </div>
    </div>
  );
};

export default OralHygiene;
