import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { firestore, auth } from '../../firebase';
import PatientProfileNav from './PatientProfileNav';
import './PatientNotesInput.css'; 

const PastDentalHistory = () => {
  const [pastDentalHistory, setPastDentalHistory] = useState('');
  const [dentalOptions, setDentalOptions] = useState({
    regular: false, irregular: false,
    manual: false, electric: false,
    'twice daily': false, 'once daily': false,
    'fluoride TP': false, mouthwash: false, 'ID cleaning': false,
    'high sugar': false, 'mod sugar': false, 'low sugar': false,
    anxious: false,
  });
  const navigate = useNavigate();
  const { patientId } = useParams();

  useEffect(() => {
    // Define the Firestore document reference
    const patientRef = doc(firestore, 'customers', auth.currentUser.uid, 'patients', patientId);

    // Fetch the current past dental history data from Firestore
    const fetchPastDentalHistory = async () => {
      const patientDoc = await getDoc(patientRef);
      if (patientDoc.exists() && patientDoc.data().notes && patientDoc.data().notes.pastDentalHistory) {
        setPastDentalHistory(patientDoc.data().notes.pastDentalHistory); 
      }
    };

    fetchPastDentalHistory();
  }, [patientId]);

  const toggleDentalOption = (option) => {
    setDentalOptions(prev => ({ ...prev, [option]: !prev[option] }));
  };

  const handleNext = async () => {
    const patientRef = doc(firestore, 'customers', auth.currentUser.uid, 'patients', patientId);

    const selectedDentalOptionsString = Object.entries(dentalOptions)
      .filter(([key, value]) => value)
      .map(([key]) => key.charAt(0).toUpperCase() + key.slice(1))
      .join(', ');

    const updateString = `${selectedDentalOptionsString} - ${pastDentalHistory}`;

    await updateDoc(patientRef, {
      'notes.pastDentalHistory': updateString.trim()
    });

    navigate(`/mvp/patient-profile/${patientId}/social-history`);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleNext();
    }
  };

  return (
    <div className="patient-complaints-container">
      <PatientProfileNav patientId={patientId} />
      <h2 className="patient-complaints-h2">Past Dental History</h2>

      <div className="sub-options">
        {['regular', 'irregular'].map(option => (
          <button
            key={option}
            onClick={() => toggleDentalOption(option)}
            className={`treatment-button ${dentalOptions[option] ? 'selected' : ''}`}
          >
            {option.charAt(0).toUpperCase() + option.slice(1)}
          </button>
        ))}
      </div>
      <div className="sub-options">
        {['manual', 'electric'].map(option => (
          <button
            key={option}
            onClick={() => toggleDentalOption(option)}
            className={`treatment-button ${dentalOptions[option] ? 'selected' : ''}`}
          >
            {option.charAt(0).toUpperCase() + option.slice(1)}
          </button>
        ))}
      </div>
      <div className="sub-options">
        {['twice daily', 'once daily'].map(option => (
          <button
            key={option}
            onClick={() => toggleDentalOption(option)}
            className={`treatment-button ${dentalOptions[option] ? 'selected' : ''}`}
          >
            {option.charAt(0).toUpperCase() + option.slice(1)}
          </button>
        ))}
      </div>
      <div className="sub-options">
        {['fluoride TP', 'mouthwash', 'ID cleaning'].map(option => (
          <button
            key={option}
            onClick={() => toggleDentalOption(option)}
            className={`treatment-button ${dentalOptions[option] ? 'selected' : ''}`}
          >
            {option.charAt(0).toUpperCase() + option.slice(1)}
          </button>
        ))}
      </div>
      <div className="sub-options">
        {['high sugar', 'mod sugar', 'low sugar'].map(option => (
          <button
            key={option}
            onClick={() => toggleDentalOption(option)}
            className={`treatment-button ${dentalOptions[option] ? 'selected' : ''}`}
          >
            {option.charAt(0).toUpperCase() + option.slice(1)}
          </button>
        ))}
      </div>
      <div className="sub-options">
        {['anxious'].map(option => (
          <button
            key={option}
            onClick={() => toggleDentalOption(option)}
            className={`treatment-button ${dentalOptions[option] ? 'selected' : ''}`}
          >
            {option.charAt(0).toUpperCase() + option.slice(1)}
          </button>
        ))}
      </div>

      <textarea
        className="patient-complaints-textarea"
        placeholder="Enter past dental history here"
        value={pastDentalHistory}
        onChange={(e) => setPastDentalHistory(e.target.value)}
        onKeyPress={handleKeyPress}
        autoFocus
      />
      <button className="patient-complaints-button" onClick={handleNext}>Next</button>
    </div>
  );
};

export default PastDentalHistory;
