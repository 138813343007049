import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { collection, doc, getDoc, setDoc, updateDoc, query, where, getDocs } from 'firebase/firestore';
import { firestore, auth } from '../../firebase';
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMicrophone, faPlay, faPause, faPaperPlane, faStop } from '@fortawesome/free-solid-svg-icons';
import LoadingPopup from  '../patientnotes/LoadingPopup';
import CopyButton from '../CopyButton';
import PatientLetters from '../letters/PatientLetters';
import ReferralLetters from '../letters/ReferralLetters';
import ReferralResponseLetter from '../letters/ReferralResponseLetter';
import PastExamModal from './PastExamModal';
import RiskBankChecker from '../riskbank/RiskBankChecker';
import ActiveAudioAnalyser from '../activeaudioanalyser/ActiveAudioAnalyser';


  
const BasicExam = () => {
    const [noteTemplate, setNoteTemplate] = useState('');
    const [generatedNote, setGeneratedNote] = useState('');
    const { treatmentId } = useParams();
    const [isRecording, setIsRecording] = useState(false);
    const [audioData, setAudioData] = useState(null);
    const [mediaRecorder, setMediaRecorder] = useState(null);
    const [audioChunks, setAudioChunks] = useState([]);
    const [transcribedNote, setTranscribedNote] = useState('');
    const [isGenerating, setIsGenerating] = useState(false);
    const [canStopRecording, setCanStopRecording] = useState(false);
    const [templates, setTemplates] = useState({});
    const [showLoadingPopup, setShowLoadingPopup] = useState(false);
    const templateRef = useRef(null);
    const [nurseInput, setNurseInput] = useState('');
    const [patientId, setPatientId] = useState('');
    const [downloadUrl, setDownloadUrl] = useState('');
    const globalNoteRef = useRef(null);
    const [isPastExamModalOpen, setIsPastExamModalOpen] = useState(false);
    const [mediaStream, setMediaStream] = useState(null);
    const [isPaused, setIsPaused] = useState(false);
    const [chartingMethod, setChartingMethod] = useState('FDI');
    const [languagePreference, setLanguagePreference] = useState('EN');
    const [dentistName, setDentistName] = useState('Dentist');

    useEffect(() => {
        const fetchChartingMethod = async () => {
            const user = auth.currentUser;
            if (user) {
                const userRef = doc(firestore, 'customers', user.uid);
                const userDoc = await getDoc(userRef);
                if (userDoc.exists()) {
                    setChartingMethod(userDoc.data().chartingMethod || 'FDI'); // Load charting method from Firestore
                }
            }
        };
        fetchChartingMethod();
    }, []);

    useEffect(() => {
        const fetchPreferences = async () => {
            const user = auth.currentUser;
            if (user) {
                const userRef = doc(firestore, 'customers', user.uid);
                const userDoc = await getDoc(userRef);
                if (userDoc.exists()) {
                    setLanguagePreference(userDoc.data().languagePreference || 'EN');
                    setDentistName(userDoc.data().name || 'Dentist');
                } else {
                    // Fallback if no data in Firestore
                    setLanguagePreference('EN');
                }
            }
        };
        fetchPreferences();
    }, []);

    const handleOpenPastExamModal = () => {
    setIsPastExamModalOpen(true);
    };

    const handleClosePastExamModal = () => {
    setIsPastExamModalOpen(false);
    };

    const handleUsePastExam = (pastExamText) => {
    setNoteTemplate(pastExamText);
    };

    const handlePatientIdChange = (e) => {
        setPatientId(e.target.value);
    };

    useEffect(() => {
        if (templateRef.current) {
            templateRef.current.style.height = 'auto'; // Reset height to compute the new height
            templateRef.current.style.height = templateRef.current.scrollHeight + 'px';
        }
    }, [noteTemplate]); // Depend on noteTemplate to update height when it changes  

    useEffect(() => {
        const loadTemplate = async () => {
            // Ensure the user is logged in and auth.currentUser is not null
            if (auth.currentUser) {
                const uid = auth.currentUser.uid; // Get the user ID from Firebase Auth
                const templateRef = doc(firestore, 'customers', uid, 'templates', 'Basic Exam');
                const templateSnap = await getDoc(templateRef);
    
                if (templateSnap.exists()) {
                    setNoteTemplate(templateSnap.data().text);
                } else {
                    // console.log('No template found for Basic Exam');
                    alert("You need to add your Basic Exam template in settings.");
                }
            } else {
                // console.log('User not logged in');
            }
        };
    
        loadTemplate();
    }, []);
    
    
    const sendAudioToCloudFunction = async (filePath, downloadURL) => {
        try {
            // Get the current authenticated user
            const user = auth.currentUser;
            if (!user) {
                throw new Error("User not authenticated");
            }
    
            // Get the Firebase ID token of the current user
            const idToken = await user.getIdToken();
    
            // Prepare the payload
            const payload = {
                filePath: filePath,
                language: languagePreference // Send the user's language preference
            };
    
            // Make the POST request with Authorization header
            const response = await fetch('https://us-central1-digitaltco-c40e9.cloudfunctions.net/multilanguage', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${idToken}`, // Include the ID token in the Authorization header
                },
                body: JSON.stringify(payload),
            });
    
            if (!response.ok) {
                // Handle HTTP errors
                const errorText = await response.text();
                throw new Error(`Server Error: ${errorText}`);
            }

            const result = await response.json();
            let appendedTranscript = result.text;

        // RiskBank - check for triggers and append risks if found
        const triggersInTranscript = await RiskBankChecker(result.text);
        // // console.log("Triggers found in transcript:", triggersInTranscript);

        if (triggersInTranscript.length > 0) {
            for (const trigger of triggersInTranscript) {
                const risksQuery = query(collection(firestore, 'customers', auth.currentUser.uid, 'riskBank'), where("trigger", "==", trigger));
                const querySnapshot = await getDocs(risksQuery);
                querySnapshot.forEach(doc => {
                    const riskData = doc.data();
                    appendedTranscript += `\n\nRisk for ${riskData.name}: ${doc.data().risk}`;
                });
            }
        }

        setTranscribedNote(appendedTranscript);

        // Store the note
        const noteRef = doc(collection(firestore, 'customers', auth.currentUser.uid, 'notes'));
        await setDoc(noteRef, {
            title: "Basic Exam",
            content: "", 
            transcript: appendedTranscript,
            audio_recording: downloadURL,
            patientId: patientId,
            timestamp: new Date()
        });
        globalNoteRef.current = noteRef;
    } catch (error) {
        console.error('Error:', error);
    }
};

    const startRecording = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            setMediaStream(stream);
            const newMediaRecorder = new MediaRecorder(stream);
            // console.log('MediaRecorder started');
            setCanStopRecording(true);

            let audioChunks = [];
            newMediaRecorder.ondataavailable = (event) => {
                audioChunks.push(event.data);
            };

            newMediaRecorder.onstop = async () => {
                // console.log('Recording stopped, creating audio blob');
                const audioBlob = new Blob(audioChunks, { type: 'audio/webm' });
                // console.log('Audio blob created:', audioBlob.size);

                if (audioBlob.size === 0) {
                    alert('Audio recording is blank. If using Safari, please use an alternative browser. If using an iPad, please use an alternative device. If neither, check that your microphone is plugged in and working!');
                    setIsRecording(false);
                    return; // Early return to prevent further execution
                }

                if (audioBlob.size > 0) {
                    const uid = auth.currentUser.uid;
                    const filePath = `smartexam/${uid}/${treatmentId}-${Date.now()}.webm`;
                    const storageRef = ref(getStorage(), filePath);
                    await uploadBytes(storageRef, audioBlob);
                    
                    getDownloadURL(storageRef).then((downloadURL) => {
                        // console.log('Download URL:', downloadURL);
                        setAudioData(filePath);
                        setDownloadUrl(downloadURL);
                        sendAudioToCloudFunction(filePath, downloadURL);
                    });
                }
            };

            setMediaRecorder(newMediaRecorder);
            newMediaRecorder.start();
            setIsRecording(true);
        } catch (error) {
            console.error('Error in starting recording:', error);
        }
    };

    const stopRecording = () => {
        if (mediaRecorder) {
            // console.log('Stopping recording');
            mediaRecorder.stop();
            setIsRecording(false);
            mediaRecorder.stream.getTracks().forEach(track => track.stop());
            setCanStopRecording(false); // Disable the stopRecording button after recording stops
            setTranscribedNote("Loading...");
        }
    };

    // useEffect(() => {
    //     if (audioData) {
    //         // console.log('Audio Blob is set, now sending to cloud function');
    //         sendAudioToCloudFunction(audioData);
    //     }
    // }, [audioData]);

    // const saveNoteToFirestore = async (noteTitle, noteContent) => {
    //     const uid = auth.currentUser?.uid;
    //     if (!uid) {
    //         console.error('User is not authenticated');
    //         return;
    //     }
    
    //     const noteRef = doc(collection(firestore, 'customers', uid, 'notes'));
    //     try {
    //         await setDoc(noteRef, {
    //             title: noteTitle, // This will be "Basic Exam"
    //             content: noteContent, // This is the generated text
    //             timestamp: new Date(),
    //             patientId: patientId,
    //         });
    //         // console.log('Note successfully saved to Firestore');
    //     } catch (error) {
    //         console.error('Error saving note to Firestore:', error);
    //     }
    // };
    

    const callGenerateNoteFunction = async (prompt) => {
        try {
            const idToken = await auth.currentUser.getIdToken();
            const response = await fetch('https://us-central1-digitaltco-c40e9.cloudfunctions.net/basicExam', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${idToken}`,
                },
                body: JSON.stringify({ prompt }),
            });
      
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const responseData = await response.json();
            setGeneratedNote(responseData.text);
    
            // console.log("global note ref:", globalNoteRef);
              
            if (!globalNoteRef.current) {
                // Create a new document if globalNoteRef is not set
                const newNoteRef = doc(collection(firestore, 'customers', auth.currentUser.uid, 'notes'));
                await setDoc(newNoteRef, {
                    title: "Basic Exam",
                    content: responseData.text,
                    patientId: patientId,
                    timestamp: new Date()
                });
                globalNoteRef.current = newNoteRef;
                // console.log("New document created because there was no global note ref.");
            } else {
                // Update the existing document
                try {
                    await updateDoc(globalNoteRef.current, {
                        content: responseData.text
                    });
                    // console.log("Document updated successfully");
                } catch (error) {
                    console.error("Error updating document: ", error);
                }
            }
        } catch (error) {
            console.error('Error generating note:', error);
            setShowLoadingPopup(false);
            setIsGenerating(false);
            alert("Sorry, the artificial intelligence seemed to be on its own coffee for too long. Please try to generate your note again.");
        } finally {
            setIsGenerating(false);
            setShowLoadingPopup(false);
        }
    };
        

    const handleGenerateNote = () => {
        setIsGenerating(true);
        setShowLoadingPopup(true);

        const prompt = `YOU MUST REPLY IN ${languagePreference} LANGUAGE.
                        Dentist's Name: ${dentistName}.
                        Exam Note Template: ${noteTemplate}.
                        Teeth Notation Used: ${chartingMethod}.
                        Nurse's Input: ${nurseInput}.
                        Dentist's Notes: ${transcribedNote}.`;

        callGenerateNoteFunction(prompt);
    };    

    const removeDoubleAsterisks = (text) => {
        let formattedText = text.replace(/\*\*(.*?)\*\*/g, '$1'); // Remove **text**
        formattedText = formattedText.replace(/##/g, ''); // Remove ##
        formattedText = formattedText.replace(/###/g, ''); // Remove ###
        formattedText = formattedText.replace(/【\d+†.*?】/g, '');
        return formattedText;
    };

    const containsReferral = /referr(al|er)/i.test(generatedNote);

    const pauseRecording = () => {
        if (mediaRecorder && isRecording) {
            mediaRecorder.pause();
            setIsPaused(true);
        }
    };

    const resumeRecording = () => {
        if (mediaRecorder && isRecording) {
            mediaRecorder.resume();
            setIsPaused(false);
        }
    };
          

    return (
        <main className="flex flex-col items-center tracking-tight w-4/5 mx-auto">
            {showLoadingPopup && <LoadingPopup />}
            <PastExamModal
            isOpen={isPastExamModalOpen}
            onClose={handleClosePastExamModal}
            onUsePastExam={handleUsePastExam}
            />
            <div className="bg-primary-50 border border-primary-500 text-gray-700 px-4 py-3 rounded-lg relative tracking-tighter text-sm mb-8 w-3/5">
            <strong className="font-bold">Important: </strong>
            <span className="block sm:inline">
                Our Smart Exam feature is revolutionary, working differently from anything you've used before. Yes, DigitalTCO excels with AI and voice capabilities, but the real innovation lies in how we utilize templates.
                <p className="mt-2">
                If you're not seeing consistent results, it might be because you haven't fully grasped the concept yet. The process is simple, but it requires a specific approach. To help you master it, we strongly recommend watching video #4 in the Knowledge Vault. This video provides a clear and concise explanation.
                </p>
                <p className="mt-2">
                Embrace the simplicity and specificity of our Smart Exam feature, and you'll soon see why it's a game-changer in dental documentation.
                </p>
            </span>
            </div>



            {/* TOP BAR */}
            <div className="flex items-center w-[65%] mx-auto justify-center">
                <div className="pt-1.5">
                    <button onClick={handleOpenPastExamModal} className="ml-8 inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-xs font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-primary-500 to-pink-500 group-hover:from-purple-500 group-hover:to-pink-500 hover:text-white focus:ring-4 focus:outline-none focus:ring-purple-200">
                        <span className="relative px-5 py-1.5 transition-all ease-in duration-75 bg-white rounded-md group-hover:bg-opacity-0">
                        Import Previous Exam
                        </span>
                    </button>
                </div>   

                <div>
                    <input
                        type="text"
                        id="patientId"
                        value={patientId}
                        onChange={handlePatientIdChange}
                        placeholder="Patient ID"
                        className="block w-full p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 text-xs focus:ring-blue-500 focus:border-blue-500"
                    />
                </div>
            </div>
    
            {/* MAIN SECTION */}
            <div className="mt-2 grid grid-cols-1 gap-6 sm:grid-cols-2 w-full">
            {/* LEFT INPUT COLUMN */}
            <div className="flex flex-col">
            <label className="block mb-2 text-xs text-primary-400">Template</label>
                        <textarea
                            ref={templateRef}
                            readOnly
                            value={noteTemplate}
                            placeholder="Exam Note Template will be displayed here..."
                            className="block h-5rows overflow-y-auto p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300"
                                 
                        ></textarea>

                <label className="block mt-2 mb-2 text-xs text-primary-400">Nurse's notes</label>
                         <textarea
                            placeholder="Nurse's input..."
                            value={nurseInput}
                            onChange={(e) => setNurseInput(e.target.value)}
                            className="block overflow-y-auto p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300"
                            rows="5"
                        ></textarea>
                        
                <label className="mt-4 block mb-2 text-xs text-primary-400">Dentist's observations and notes</label>
                        <textarea
                            placeholder="Enter your notes here or use the recording option..."
                            value={transcribedNote}
                            onChange={(e) => setTranscribedNote(e.target.value)}
                            readOnly={isRecording || transcribedNote === "Loading..."}
                            className="block overflow-y-auto p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300"
                            rows="5"
                        ></textarea>

                        {mediaStream && (
                            <div className="active-audio-analyser-container">
                                <ActiveAudioAnalyser audioStream={mediaStream} isPaused={isPaused} />
                            </div>
                        )}

                        {/* BUTTONS */}
                        <div className="mx-auto mt-2">
                            <div className="inline-flex rounded-md shadow-sm" role="group">
                                <button 
                                    onClick={startRecording} 
                                    disabled={isRecording || isPaused}
                                    className={`${isRecording || isPaused ? 'px-4 py-2 text-xl font-medium text-gray-500 bg-gray-300 border border-gray-200 rounded-s-lg cursor-not-allowed' : 'px-4 py-2 text-xl font-medium text-primary-400 bg-white border border-gray-200 rounded-s-lg hover:bg-primary-400 hover:text-white'}`}
                                >
                                    <FontAwesomeIcon icon={faMicrophone} />
                                </button>

                                {isRecording && (
                                    <button 
                                        onClick={isPaused ? resumeRecording : pauseRecording}
                                        className={`${isPaused ? 'px-4 py-2 text-xl font-medium text-primary-400 bg-white border-t border-b border-gray-200 hover:bg-primary-400 hover:text-white' : 'px-4 py-2 text-xl font-medium text-primary-400 bg-white border-t border-b border-gray-200 hover:bg-primary-400 hover:text-white'}`}
                                    >
                                        {isPaused ? <FontAwesomeIcon icon={faPlay} /> : <FontAwesomeIcon icon={faPause} />}
                                    </button>
                                )}

                                <button 
                                    onClick={stopRecording} 
                                    disabled={!isRecording || isPaused}
                                    className={`${!isRecording || isPaused ? 'px-4 py-2 text-xl font-medium text-gray-500 bg-gray-300 border-t border-b border-gray-200 cursor-not-allowed' : 'px-4 py-2 text-xl font-medium text-primary-400 bg-white border-t border-b border-gray-200 hover:bg-primary-400 hover:text-white'}`}
                                >
                                    <FontAwesomeIcon icon={faStop} />
                                </button>
                    
                                <button 
                                    onClick={handleGenerateNote} 
                                    disabled={isGenerating || isRecording || isPaused}
                                    className={`${isGenerating || isRecording || isPaused ? 'px-4 py-2 text-xl font-medium text-gray-500 bg-gray-300 border border-gray-200 rounded-e-lg cursor-not-allowed' : 'px-4 py-2 text-xl font-medium text-primary-400 bg-white border border-gray-200 rounded-e-lg hover:bg-primary-400 hover:text-white'}`}
                                >
                                    <FontAwesomeIcon icon={faPaperPlane} />
                                </button>
                            </div>
                        </div>
            </div>
    
                {/* RIGHT OUTPUT COLUMN */}
            <div className="flex flex-col">
            
            <label className="block mb-2 text-xs text-primary-400">AI Output</label>
                    <textarea  
                        value={removeDoubleAsterisks(generatedNote)}
                        onChange={(e) => setGeneratedNote(e.target.value)}
                        placeholder="Notes will show here..."
                        className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300"
                        rows="37"
    
                    ></textarea>

                    <div className="flex flex-col items-center justify-center mx-auto mt-2">
                        <CopyButton textToCopy={removeDoubleAsterisks(generatedNote)} />
                        <div className="mx-auto mt-2">
                            {generatedNote && (
                                <PatientLetters noteContent={removeDoubleAsterisks(generatedNote)} />
                            )}
                            {containsReferral && (
                                <ReferralLetters noteContent={removeDoubleAsterisks(generatedNote)} />
                            )}
                            {generatedNote && (
                                <ReferralResponseLetter noteContent={removeDoubleAsterisks(generatedNote)} />
                            )}
                        </div>
                    </div>
                </div>
            
            </div>
        </main>
    );  
};

export default BasicExam;