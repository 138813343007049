import React, { useState } from 'react';
import { signInWithEmailAndPassword, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { useNavigate, Link } from 'react-router-dom';
import { auth, firestore } from '../firebase';  // adjust path if needed
import { doc, setDoc, getDoc, collection, getDocs, query, where, writeBatch, addDoc } from 'firebase/firestore';
import './SignUp.css';
import { useSession } from './SessionContext';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { FcGoogle } from 'react-icons/fc';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const { setSessionId } = useSession();
  const navigate = useNavigate();

  const handleGoogleLogin = async () => {
    const googleProvider = new GoogleAuthProvider();
    try {
      const userCredential = await signInWithPopup(auth, googleProvider);
      const user = userCredential.user;

      // Check for forceReAuth claim and remove it if present
      user.getIdTokenResult().then(async (idTokenResult) => {
        if (idTokenResult.claims.forceReAuth) {
          await removeForceReAuthClaim(user.uid)
            .then(() => {})
            .catch(error => {});
        }
      });

      const userRef = doc(firestore, 'customers', user.uid);
      const userSnapshot = await getDoc(userRef);

      // If the user doesn't exist in Firestore, then create the user document
      if (!userSnapshot.exists()) {
        await setDoc(userRef, {
          email: user.email,
        });
        navigate('/payment');  // If they're a new user, navigate them to the payment page.
        return;
      }
 
      await invalidatePreviousSessions(user.uid);
      await createNewSession(user.uid);
      navigate('/mvp');
    } catch (err) {
      setError('Failed to log in with Google. Please try again.');
      console.error("Google login error:", err);
    }
  };

  const invalidatePreviousSessions = async (userId) => {
    try {
      const sessionsRef = collection(firestore, 'sessions');
      const q = query(sessionsRef, where('userId', '==', userId));
      const activeSessions = await getDocs(q);
  
      const batch = writeBatch(firestore);
      activeSessions.forEach((docSnapshot) => {
        console.log(`Deleting session: ${docSnapshot.id}`);
        batch.delete(docSnapshot.ref);
      });
      await batch.commit();
      console.log("All previous sessions invalidated successfully.");
    } catch (err) {
      console.error("Error invalidating sessions:", err);
      throw err; // Rethrow the error for higher-level handling
    }
  };

  const generateSessionId = () => {
    const randomPart = Math.random().toString(36).substring(2, 15);
    const timePart = new Date().getTime().toString(36);
    return `${randomPart}${timePart}`;
  };

  const createNewSession = async (userId) => {
    try {
      const sessionId = generateSessionId();
      const userAgent = navigator.userAgent;
      const browser = navigator.appName;
      const os = navigator.platform;
      const sessionsRef = collection(firestore, 'sessions');
  
      const sessionDocRef = await addDoc(sessionsRef, {
        userId: userId,
        sessionId: sessionId,
        valid: true,
        timestamp: new Date(),
        deviceInfo: { userAgent, browser, os }
      });
  
      setSessionId(sessionDocRef.id);
    } catch (err) {
      console.error("Error creating new session:", err);
      throw err; // Rethrow the error for higher-level handling
    }
  };

  const removeForceReAuthClaim = async (userId) => {
    const functions = getFunctions();
    const removeClaim = httpsCallable(functions, 'removeForceReAuthClaim');
    return removeClaim({ userId });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await signInWithEmailAndPassword(auth, email, password);
      const user = auth.currentUser;

      user.getIdTokenResult().then(async (idTokenResult) => {
        if (idTokenResult.claims.forceReAuth) {
          await removeForceReAuthClaim(user.uid);
        }
      });

      await invalidatePreviousSessions(user.uid);
      await createNewSession(user.uid);
        
      user.getIdTokenResult().then(idTokenResult => {
        if (idTokenResult.claims.role === 'reception') {
          navigate('/reception');
        } else {
          navigate('/mvp');
        }
      });
    } catch (err) {
      setError('Failed to log in. Please check your email and password.');
      console.error("Login error:", err);
    }
  };

  return (
<section className="flex items-center justify-center min-h-screen overflow-auto bg-gradient-to-br from-primary-50 to-primary-300">
<main className="flex flex-col items-center justify-center w-full max-w-md p-8 bg-white rounded-xl shadow-lg">
      
          <h1 className="mt-6 mb-16 text-4xl font-bold text-primary-400 tracking-tighter sm:text-5xl md:text-6xl text-center">
            DigitalTCO
          </h1>
        <form className="mx-auto w-full" onSubmit={handleSubmit}>
                {/* Sign Up With Google Button */}
                <div class="flex items-center justify-center mx-auto pb-8 border-b border-primary-300">
                  <button type="button"
                          class="flex items-center justify-center mx-auto p-2 bg-white text-primary-400 shadow-sm border-primary-400 border rounded-xl w-full hover:bg-gradient-to-r hover:from-primary-200 hover:to-primary-300 hover:text-white"
                          onClick={handleGoogleLogin}>
                    <FcGoogle className="text-xl mr-2"/>
                    Log in with Google
                  </button>
                </div>

         
            <input
              type="email"
              id="email"
              className="mb-2 mt-8 bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
              placeholder="Enter your email"                    
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              onBlur={(e) => setEmail(e.target.value)}
            />
       
            <input
              type="password"
              id="password"
              className="mb-8 mt-2 bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
              placeholder="Enter your password"                    
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              onBlur={(e) => setPassword(e.target.value)}
            />

          <button className="bg-primary-400 text-white p-2 rounded-xl w-full mx-auto hover:bg-white hover:border hover:border-primary-400 hover:text-primary-400" type="submit">Log In</button>
        </form>
        <div className="signup-button-container">
          {error && <p>{error}</p>}
          <Link to="/signup" className="signup-link">Not registered? Sign Up!</Link>
          <Link to="/forgot-password" className="signup-link">Forgotten Password?</Link>
        </div>
      
      </main>
    </section>
  );
};

export default Login;
