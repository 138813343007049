import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import './CopyButton.css';

const CopyButton = ({ textToCopy }) => {
  const [isExactCopied, setIsExactCopied] = useState(false);
  const [isNormalCopied, setIsNormalCopied] = useState(false);

  // Function to format text for exact copy
  const formatTextForExactCopy = (text) => {
    return text.replace(/(?<!\d)\.\s+/g, '.\n\n'); // Example formatting
  };

  // Function to handle exact copy
  const handleExactCopy = () => {
    const formattedText = formatTextForExactCopy(textToCopy);
    navigator.clipboard.writeText(formattedText);
    setIsExactCopied(true);
    setTimeout(() => setIsExactCopied(false), 1000); // Reset after 1 second
  };

  // Function to handle normal copy
  const handleCopy = () => {
    navigator.clipboard.writeText(textToCopy);
    setIsNormalCopied(true);
    setTimeout(() => setIsNormalCopied(false), 1000); // Reset after 1 second
  };

  const particleVariants = {
    initial: { opacity: 0, y: 0, scale: 0.5 },
    animate: { opacity: 1, y: -20, scale: 1 },
    exit: { opacity: 0, y: -30, scale: 0 }
  };

  return (
    <div className="inline-flex rounded-md shadow-sm" role="group">
      <button onClick={handleCopy} className={`${isNormalCopied ? 'px-4 py-2 text-xs text-white bg-malachite-400 border border-gray-200 rounded-s-lg hover:bg-malachite-400 hover:text-white' : 'px-4 py-2 text-xs text-primary-400 bg-white border border-gray-200 rounded-s-lg hover:bg-primary-400 hover:text-white'}`}>
        {isNormalCopied ? 'Copied!' : 'Copy'}
        <AnimatePresence>
          {isNormalCopied && (
            <motion.span
              className="sparkle"
              variants={particleVariants}
              initial="initial"
              animate="animate"
              exit="exit"
              transition={{ duration: 0.5 }}
            >
              ✨
            </motion.span>
          )}
        </AnimatePresence>
      </button>

      <button onClick={handleExactCopy} className={`${isExactCopied ? 'px-4 py-2 text-xs text-white bg-malachite-400 border border-gray-200 rounded-e-lg hover:bg-malachite-400 hover:text-white' : 'px-4 py-2 text-xs text-primary-400 bg-white border border-gray-200 rounded-e-lg hover:bg-primary-400 hover:text-white'}`}>
        {isExactCopied ? 'Copied Exact!' : 'EXACT Copy'}
        <AnimatePresence>
          {isExactCopied && (
            <motion.span
              className="sparkle"
              variants={particleVariants}
              initial="initial"
              animate="animate"
              exit="exit"
              transition={{ duration: 0.5 }}
            >
              ✨
            </motion.span>
          )}
        </AnimatePresence>
      </button>
    </div>
  );
};

export default CopyButton;
