import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { firestore, auth } from '../../firebase';
import { collection, getDocs, doc, getDoc } from 'firebase/firestore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import ImageUploadModal from './ImageUploadModal';
import MarkUpImage from './MarkUpImage';
import {
  introduction,
  generalDentalRisks,
  dentalcareDetails,
  compositeDetails,
  rootCanalDetails,
  crownOrOnlayDetails,
  dentureDetails,
  hygieneDetails,
  bridgeDetails,
  extractionDetails,
  treatmentAgreement,
  whiteningDetails,
  dentalimplantDetails,
  patientBehaviour,
  amalgamDetails,
  fissuresealantDetails,
  stainlesssteelcrownDetails,
  compositebondingDetails,
  invisalignDetails,
  fixedorthoDetails,
  veneerDetails,
  sedationDetails,
  fullarchimplantsDetails,
  immediatedentureDetails,
  enlightenDetails,
} from '../TreatmentDetails';
import axios from 'axios';
import { getStorage, ref, uploadString, uploadBytes, getDownloadURL } from 'firebase/storage';

const LoadingModal = ({ onClose }) => {
  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  return (
    <div
      className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-75"
      onClick={handleOverlayClick}
    >
      <div
        className="bg-white p-4 rounded-lg shadow-lg w-1/5"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex flex-col items-center justify-center">
          <p className="text-center text-gray-700">
            Letter is generating, it will take a few minutes. Please continue to use the app but do
            not close or refresh the browser.
          </p>
          <button
            onClick={onClose}
            className="px-4 py-2 text-xs mt-2 text-primary-400 bg-white border border-gray-200 rounded-lg hover:bg-primary-400 hover:text-white"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

const CreateMolarMailButton = ({ clinicalRecordText, teethData, treatmentPlanData }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [letters, setLetters] = useState([]);
  const [selectedLetter, setSelectedLetter] = useState(null);
  const [showImageUploadModal, setShowImageUploadModal] = useState(false);
  const [showMarkUpModal, setShowMarkUpModal] = useState(false);
  const [imageUploadCallback, setImageUploadCallback] = useState(null);
  const [imageUploadItems, setImageUploadItems] = useState([]);
  const [imageMarkupCallback, setImageMarkupCallback] = useState(null);
  const [consentForms, setConsentForms] = useState({});
  const [userProfile, setUserProfile] = useState(null);
  const [letterheadURL, setLetterheadURL] = useState('');
  const [markupImageUrl, setMarkupImageUrl] = useState('');
  const [isGenerating, setIsGenerating] = useState(false);
  const [modalClose, setModalClose] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchLetters = async () => {
      const uid = auth.currentUser.uid;
      const lettersRef = collection(firestore, 'customers', uid, 'molarmailer');
      const lettersSnapshot = await getDocs(lettersRef);
      const lettersList = lettersSnapshot.docs
        .filter((doc) => doc.id !== 'settings')
        .map((doc) => ({ id: doc.id, ...doc.data() }));
      setLetters(lettersList);
    };

    fetchLetters();
  }, []);

  useEffect(() => {
    const fetchUserProfile = async () => {
      const user = auth.currentUser;
      if (user) {
        const userRef = doc(firestore, 'customers', user.uid);
        const userDoc = await getDoc(userRef);
        if (userDoc.exists()) {
          setUserProfile(userDoc.data());
          if (userDoc.data().letterheadURL) {
            setLetterheadURL(userDoc.data().letterheadURL);
          }
        }
      }
    };

    const fetchConsentForms = async () => {
      const uid = auth.currentUser.uid;
      const consentFormsRef = doc(firestore, 'customers', uid, 'templates', 'consentforms');

      try {
        const consentFormsSnap = await getDoc(consentFormsRef);
        let customConsentForms = {};

        if (consentFormsSnap.exists()) {
          customConsentForms = consentFormsSnap.data();
        } else {
          console.log('No custom consent form data found. Using default forms.');
        }

        setConsentForms({
          introduction,
          generalDentalRisks,
          dentalcareDetails,
          compositeDetails,
          rootCanalDetails,
          crownOrOnlayDetails,
          dentureDetails,
          hygieneDetails,
          bridgeDetails,
          extractionDetails,
          treatmentAgreement,
          whiteningDetails,
          dentalimplantDetails,
          patientBehaviour,
          amalgamDetails,
          fissuresealantDetails,
          stainlesssteelcrownDetails,
          compositebondingDetails,
          invisalignDetails,
          fixedorthoDetails,
          veneerDetails,
          sedationDetails,
          fullarchimplantsDetails,
          immediatedentureDetails,
          enlightenDetails,
          ...customConsentForms,
        });
      } catch (error) {
        console.error('Error fetching custom consent form data:', error);
      }
    };

    fetchUserProfile();
    fetchConsentForms();
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleLetterSelect = async (letter) => {
    setSelectedLetter(letter);
    setIsOpen(false); // Close the dropdown when a letter is selected
  
    const uid = auth.currentUser.uid;
    const letterRef = doc(firestore, 'customers', uid, 'molarmailer', letter.id);
    const letterDoc = await getDoc(letterRef);
  
    if (letterDoc.exists()) {
      const letterData = letterDoc.data();
      console.log('Selected letter data:', letterData);
  
      await processImageUploadsSequentially(letterData);
  
      await generateLetter(clinicalRecordText, teethData, treatmentPlanData, letterData);
    }
  };
  
  const processImageUploadsSequentially = async (letterData) => {
    const dynamicImageItems = letterData.rightItems
      .filter((item) => item.type === 'Image' && item.isDynamic)
      .map((item, index) => ({
        label: item.imageName || `Dynamic Image ${index + 1}`,
        id: `dynamic-image-${index}`,
        type: 'DynamicImage',
        item: item,
      }));
  
    const idealVsActualTeethItems = letterData.rightItems
      .filter((item) => item.type === 'IdealVsActualTeeth')
      .map((item, index) => ({
        label: `Ideal vs Actual Teeth ${index + 1}`,
        id: `ideal-vs-actual-teeth-${index}`,
        type: 'IdealVsActualTeeth',
        item: item,
      }));
  
    const imageUploadTasks = [...dynamicImageItems, ...idealVsActualTeethItems];
  
    for (const uploadTask of imageUploadTasks) {
      await new Promise((resolve) => {
        // Prepare the image upload modal for the current task
        setImageUploadItems([uploadTask]);
        setShowImageUploadModal(true);
  
        setImageUploadCallback(() => async (uploadedImages) => {
          if (uploadedImages[0]) {
            if (uploadTask.type === 'DynamicImage') {
              // Assign image URL to dynamic image item
              uploadTask.item.content = uploadedImages[0].url;
              console.log(`Assigned dynamic image URL ${uploadedImages[0].url} to item`);
              setShowImageUploadModal(false);
              resolve();
            } else if (uploadTask.type === 'IdealVsActualTeeth') {
              // Assign image URL to IdealVsActualTeeth item
              uploadTask.item.content = uploadedImages[0].url;
              uploadTask.item.isIdealVsActualTeeth = true;
              uploadTask.item.uploadedImageLabel = uploadedImages[0].label;
              console.log(`Assigned image ${uploadedImages[0].url} to IdealVsActualTeeth item`);
  
              setShowImageUploadModal(false);
  
              // Now handle markup for this image
              setMarkupImageUrl(uploadedImages[0].url);
              setShowMarkUpModal(true);
  
              setImageMarkupCallback(() => async (combinedImageUrl) => {
                console.log(`Combined image URL:`, combinedImageUrl);
  
                // Save combined image to Firebase storage
                const imageRef = ref(
                  getStorage(),
                  `molarmailer/${auth.currentUser.uid}/${Date.now()}.png`
                );
                const downloadUrl = await saveImageToStorage(combinedImageUrl, imageRef);
                console.log('Combined image saved to:', downloadUrl);
  
                // Save the combined image URL to the current item
                uploadTask.item.combinedImageUrl = downloadUrl;
  
                setShowMarkUpModal(false);
                resolve();
              });
            } else {
              resolve();
            }
          } else {
            resolve();
          }
        });
      });
    }
  };
    

  const handleDynamicImageUpload = async (letterData) => {
    // Prepare dynamic image items for upload based on existing rightItems
    const dynamicImageItems = letterData.rightItems
      .filter((item) => item.type === 'Image' && item.isDynamic)
      .map((item, index) => ({
        label: item.imageName || `Dynamic Image ${index + 1}`,
        id: `dynamic-image-${index}`,
      }));

    console.log('Images to be uploaded:', dynamicImageItems);
    setImageUploadItems(dynamicImageItems);
    setShowImageUploadModal(true);

    return new Promise((resolve) => {
      setImageUploadCallback(() => async (uploadedImages) => {
        console.log('Images uploaded from modal:', uploadedImages);

        // Directly assigning URLs to dynamic items based on the order of upload
        let uploadIndex = 0;
        letterData.rightItems.forEach((item, index) => {
          if (item.type === 'Image' && item.isDynamic) {
            if (uploadIndex < uploadedImages.length) {
              item.content = uploadedImages[uploadIndex].url;
              console.log(`Assigning URL ${uploadedImages[uploadIndex].url} to item`);
              uploadIndex++;
            } else {
              console.log('No more uploaded images available for remaining dynamic items.');
            }
          }
        });

        console.log('Updated letterData with dynamic image URLs:', letterData);
        setShowImageUploadModal(false);
        await generateLetter(clinicalRecordText, teethData, treatmentPlanData, letterData);
        resolve();
      });
    });
  };

  const handleIdealVsActualTeethUpload = async (letterData) => {
    const idealVsActualTeethItems = letterData.rightItems
      .filter((item) => item.type === 'IdealVsActualTeeth')
      .map((item, index) => ({
        label: `Ideal vs Actual Teeth ${index + 1}`,
        id: `ideal-vs-actual-teeth-${index}`,
      }));
  
    console.log('IdealVsActualTeeth items:', idealVsActualTeethItems);
    setImageUploadItems(idealVsActualTeethItems);
    setShowImageUploadModal(true);
  
    return new Promise((resolve) => {
      setImageUploadCallback(() => async (uploadedImages) => {
        console.log('Images uploaded from modal:', uploadedImages);
  
        // Assign uploaded images to items based on index
        const idealItems = letterData.rightItems.filter(
          (item) => item.type === 'IdealVsActualTeeth'
        );
  
        idealItems.forEach((item, index) => {
          const uploadedImage = uploadedImages[index];
          if (uploadedImage) {
            item.content = uploadedImage.url;
            item.isIdealVsActualTeeth = true;
            item.uploadedImageLabel = uploadedImage.label;
            console.log(`Assigned image ${uploadedImage.url} to item at index ${index}`);
          } else {
            console.log(`No uploaded image found for item at index ${index}`);
          }
        });
  
        console.log('Updated letterData after image upload:', JSON.stringify(letterData, null, 2));
  
        setShowImageUploadModal(false);
  
        // Process markups for each image
        const idealItemsToProcess = letterData.rightItems.filter(
          (item) => item.type === 'IdealVsActualTeeth'
        );
        let currentImageIndex = 0;
  
        const processNextImage = async () => {
          if (currentImageIndex < idealItemsToProcess.length) {
            const currentItem = idealItemsToProcess[currentImageIndex];
            setMarkupImageUrl(currentItem.content);
            setShowMarkUpModal(true);
  
            const handleMarkupSave = async (combinedImageUrl) => {
              console.log(`Combined image URL for image ${currentImageIndex}:`, combinedImageUrl);
  
              // Save combined image to Firebase storage
              const imageRef = ref(
                getStorage(),
                `molarmailer/${auth.currentUser.uid}/${Date.now()}.png`
              );
              const downloadUrl = await saveImageToStorage(combinedImageUrl, imageRef);
              console.log('Combined image saved to:', downloadUrl);
  
              // Save the combined image URL to the current item
              currentItem.combinedImageUrl = downloadUrl;
  
              currentImageIndex++;
              setShowMarkUpModal(false);
              processNextImage(); // Process the next image
            };
  
            setImageMarkupCallback(() => handleMarkupSave);
          } else {
            // All images processed
            setShowMarkUpModal(false);
            await generateLetter(clinicalRecordText, teethData, treatmentPlanData, letterData);
            resolve(); // Resolve the promise here
          }
        };
  
        processNextImage();
      });
    });
  };

  const fetchSettings = async () => {
    const uid = auth.currentUser.uid;
    const settingsRef = doc(firestore, 'customers', uid, 'molarmailer', 'settings');
    const settingsDoc = await getDoc(settingsRef);
    if (settingsDoc.exists()) {
      return settingsDoc.data();
    }
    return {
      font: 'Calibri',
      fontSize: '11',
      footerContent: 'Default footer content',
    };
  };

  const generateLetter = async (clinicalRecordText, teethData, treatmentPlanData, letterData) => {
    console.log('Starting generateLetter function...');
    setImageUploadItems([]); // Clear previous image items
    setIsGenerating(true); // Show generating modal

    const settings = await fetchSettings();

    const processedSections = await Promise.all(
      letterData.rightItems.map(async (item, index) => {
        console.log(`Processing item at index ${index}:`, JSON.stringify(item, null, 2));
        console.log(`Processing item: ${item.type}`);
        switch (item.type) {
          case 'CustomText':
            const customTextResult = await processCustomText(
              clinicalRecordText,
              item.content,
              letterData.exampleNote,
              item.isDynamic
            );
            console.log(`CustomText result: ${customTextResult}`);
            return customTextResult;
          case 'Image':
            console.log(
              `Processing Image item: isDynamic=${item.isDynamic}, content=${item.content}`
            );
            if (item.isDynamic) {
              console.log(`Dynamic Image content: ${item.content}`);
              return `<p><img src="${item.content}" alt="Dynamic Image" /></p>`;
            } else {
              console.log(`Static Image content: ${item.content}`);
              return `<p><img src=${item.content} alt="Static Image" /></p>`;
            }
          case 'IdealVsActualTeeth':
            console.log(`Processing IdealVsActualTeeth item at index ${index}`);
            console.log(`Item content URL: ${item.content}`);
            console.log(`Item combinedImageUrl: ${item.combinedImageUrl}`);
            return `
              <p><img src="${item.content}" alt="Ideal vs Actual Teeth Image" /></p>
              <p><img src="${item.combinedImageUrl}" alt="Annotated Ideal vs Actual Teeth Image" /></p>
            `;
          case 'CustomForms':
            const customFormsResult = await processCustomForms(clinicalRecordText);
            console.log(`CustomForms result: ${customFormsResult}`);
            return customFormsResult;
          case 'AutoPatientLetter':
            const autoPatientLetterResult = await processAutoPatientLetter(clinicalRecordText);
            console.log(`AutoPatientLetter result: ${autoPatientLetterResult}`);
            return autoPatientLetterResult;
          case 'ChartSummary':
            const chartSummaryResult = await processChartSummary(teethData, treatmentPlanData);
            console.log(`ChartSummary result: ${chartSummaryResult}`);
            return chartSummaryResult;
          case '16PointAssessment':
            const sixteenPointAssessmentResult = await process16PointAssessment(
              teethData,
              treatmentPlanData,
              clinicalRecordText
            );
            console.log(`16PointAssessment result: ${sixteenPointAssessmentResult}`);
            return sixteenPointAssessmentResult;
          default:
            console.log(`Unknown item type: ${item.type}`);
            return '';
        }
      })
    );

    console.log('Processed sections:', processedSections);
    const finalLetter = `
${removeLeadingWhitespace(`<right>Dr. ${userProfile?.name}</right>`)}
${removeLeadingWhitespace(`<right>${userProfile?.practiceName}</right>`)}
${removeLeadingWhitespace(`<right>${userProfile?.practiceAddress}</right>`)}
${removeLeadingWhitespace(`<right>${userProfile?.practicePostcode}</right>\n`)}
${removeLeadingWhitespace(`<right>${userProfile?.practiceEmail}</right>`)}
${removeLeadingWhitespace(`<right>${userProfile?.practicePhoneNumber}</right>\n`)}
${removeLeadingWhitespace(`<right>${formatDate(new Date())}</right>\n\n`)}<br>
${removeLeadingWhitespace(processedSections.join('\n\n'))}
    `;
    console.log('Generated Letter:', finalLetter);

    try {
      const user = auth.currentUser;
      const uid = user.uid;
      const idToken = await user.getIdToken();
      const response = await axios.post(
        'https://us-central1-digitaltco-c40e9.cloudfunctions.net/DocxCreatorMMModular',
        {
          textContent: finalLetter,
          letterheadURL,
          settings,
          uid,
        },
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      );
    
      if (response.status === 200) {
        const { url } = response.data;
    
        // Create a temporary link to download the file
        const a = document.createElement('a');
        a.href = url;
        a.download = 'MolarMailLetter.docx';
        document.body.appendChild(a);
        a.click();
        a.remove();
    
        console.log('DOCX file created and downloaded successfully');
      } else {
        console.error('Failed to create DOCX file', response);
      }
    } catch (error) {
      console.error('Error creating DOCX file', error);
    } finally {
      setIsGenerating(false);
    }     
  };

  const removeEmptyParagraphs = (htmlText) => {
    // This regex finds empty paragraph tags that may contain spaces between them but no other content
    return htmlText.replace(/<p>\s*<\/p>/g, '');
  };

  const processCustomText = async (clinicalRecordText, content, exampleNote, isDynamic) => {
    if (isDynamic) {
      try {
        const prompt = `
          Example Clinical Record Text: ${exampleNote}.
          Corresponding modular letter section: ${content}.
          New clinical record: ${clinicalRecordText}.
        `;
        const response = await fetch(
          `https://us-central1-digitaltco-c40e9.cloudfunctions.net/dynamicCustomTextForm`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ prompt }),
          }
        );
        const result = await response.json();
        console.log('Generated custom text:', result.text);

        // Apply formatting functions to the result text
        let formattedHTML = formatHTMLText(result.text);
        formattedHTML = removeEmptyParagraphs(formattedHTML); // Remove empty <p></p>
        const cleanText = removeDoubleAsterisks(formattedHTML);
        return cleanText;
      } catch (error) {
        console.error('Failed to generate custom text:', error);
        return 'Error generating custom text.';
      }
    } else {
      // Apply formatting to static content as well
      let formattedHTML = formatHTMLText(content);
      formattedHTML = removeEmptyParagraphs(formattedHTML); // Remove empty <p></p>
      return removeDoubleAsterisks(formattedHTML);
    }
  };

  const processCustomForms = async (clinicalRecordText) => {
    try {
      const user = auth.currentUser;
      if (!user) {
        throw new Error('User not authenticated');
      }
      
      const idToken = await user.getIdToken();
      console.log('User authenticated, ID Token:', idToken);
  
      const consentFormKeys = Object.keys(consentForms);
      console.log('Consent form keys:', consentFormKeys);
  
      const treatArrayResponse = await fetch(
        'https://us-central1-digitaltco-c40e9.cloudfunctions.net/molarmailIdArray',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${idToken}`,
          },
          body: JSON.stringify({ prompt: clinicalRecordText, keys: consentFormKeys }),
        }
      );
  
      console.log('Treat array response status:', treatArrayResponse.status);
      if (!treatArrayResponse.ok) {
        const errorText = await treatArrayResponse.text();
        console.error('Error response from molarmailIdArray:', errorText);
        throw new Error('Network response was not ok in processing custom forms');
      }
  
      const treatmentArrayResponse = await treatArrayResponse.json();
      console.log('Treatment array response:', treatmentArrayResponse);
  
      let treatmentArrayText = treatmentArrayResponse.text || '';
      const treatmentArrayCode = treatmentArrayText.replace(/```/g, '').replace(/jsx/g, '').trim();
  
      let selectedForms = '';
      for (let key in consentForms) {
        if (treatmentArrayCode.includes(key)) {
          let formContent = consentForms[key];
          formContent = removeLeadingWhitespace(formContent);
          formContent = formatHTMLText(formContent);
          selectedForms += `${formContent}\n\n`;
        }
      }
  
      console.log('Selected forms content:', selectedForms);
      return selectedForms;
  
    } catch (error) {
      console.error('Error processing custom forms:', error);
      return 'Error processing custom forms.';
    }
  };
  
  const processAutoPatientLetter = async (clinicalRecordText) => {
    try {
      const response = await fetch(
        'https://us-central1-digitaltco-c40e9.cloudfunctions.net/lettersbroke',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ prompt: clinicalRecordText }),
        }
      );

      if (!response.ok) {
        throw new Error('Network response was not ok in processing auto patient letter');
      }

      const result = await response.json();
      console.log('Generated auto patient letter:', result.text);
      return removeDoubleAsterisks(result.text);
    } catch (error) {
      console.error('Failed to generate auto patient letter:', error);
      return 'Error generating auto patient letter.';
    }
  };

  const processChartSummary = async (teethData, treatmentPlanData) => {
    console.log(teethData, treatmentPlanData);
    try {
      const chartingMethod = userProfile?.chartingMethod || 'default';
      const prompt = `Teeth Data: ${JSON.stringify(teethData)}
                          Treatment Plan Data: ${JSON.stringify(treatmentPlanData)}
                          Charting Method: ${chartingMethod}`;
      const response = await fetch(
        'https://us-central1-digitaltco-c40e9.cloudfunctions.net/chartSummary',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ prompt }),
        }
      );

      if (!response.ok) {
        throw new Error('Network response was not ok in processing chart summary');
      }

      const result = await response.json();
      console.log('Generated chart summary:', result.text);
      const formattedResult = removeDoubleAsterisks(result.text);
      return formattedResult;
    } catch (error) {
      console.error('Failed to generate ChartSummary:', error);
      return 'Error generating ChartSummary.';
    }
  };

  const process16PointAssessment = async (teethData, treatmentPlanData, clinicalRecordText) => {
    try {
      const prompt = `    
      Teeth charting: ${JSON.stringify(teethData)}
      
      Treatment plan charting: ${JSON.stringify(treatmentPlanData)}
  
      Clinical Record: ${clinicalRecordText}
      `;

      const response = await fetch(
        `https://us-central1-digitaltco-c40e9.cloudfunctions.net/sixteenPointChecklist`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ prompt }),
        }
      );

      if (!response.ok) {
        throw new Error('Network response was not ok in processing 16PointAssessment');
      }

      const result = await response.json();
      const cleanedResult = removeDoubleAsterisks(result.text); // Process the result to remove formatting artifacts
      console.log('Generated 16PointAssessment:', cleanedResult);
      return cleanedResult;
    } catch (error) {
      console.error('Failed to generate 16PointAssessment:', error);
      return 'Error generating 16PointAssessment.';
    }
  };

  const removeLeadingWhitespace = (htmlString) => {
    return htmlString.replace(/^\s+</gm, '<').replace(/<\/?(ol|ul|strong|i|u|b)>/gm, '');
  };

  const formatHTMLText = (htmlText) => {
    let formattedText = htmlText
      .replace(/(<(p|h1|h2|h3|li|div)[^>]*>)/g, '\n$1')
      .replace(/<\/?(ul|ol|h4|h5|h6|strong|br)>/g, '');

    return formattedText;
  };

  const removeDoubleAsterisks = (text) => {
    let formattedText = text.replace(/\*\*(.*?)\*\*/g, '$1'); // Remove **text**
    formattedText = formattedText.replace(/###/g, '');
    formattedText = formattedText.replace(/##/g, ''); // Remove ##
    formattedText = formattedText.replace(/\*\*/g, '');
    formattedText = formattedText.replace(/【\d+†.*?】/g, '');
    return formattedText;
  };

  const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  };

  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed in JavaScript
    const year = String(date.getFullYear()).substr(-2); // Get last two digits of the year

    return `${day}/${month}/${year}`;
  };

  const saveImageToStorage = async (combinedImageUrl, imageRef) => {
    const storage = getStorage();
    const base64Image = combinedImageUrl.split(',')[1];
    await uploadString(imageRef, base64Image, 'base64', { contentType: 'image/png' });
    return await getDownloadURL(imageRef);
  };

  return (
    <div className="relative">
      <button
        onClick={toggleDropdown}
        className="p-2 text-xs text-primary-400 bg-white border border-gray-200 rounded-lg hover:bg-primary-400 hover:text-white"
      >
        Create Molar Mail
        <FontAwesomeIcon icon={isOpen ? faChevronUp : faChevronDown} className="ml-2" />
      </button>
      {isOpen && (
        <div className="absolute bottom-full mb-2 w-full bg-white border border-gray-200 rounded-lg shadow-lg z-10 max-h-96 overflow-y-auto">
          {letters.map((letter) => (
            <div
              key={letter.id}
              className="px-4 py-2 text-gray-700 cursor-pointer hover:bg-gray-100"
              onClick={() => handleLetterSelect(letter)}
            >
              {letter.name}
            </div>
          ))}
        </div>
      )}
      {showImageUploadModal && (
        <ImageUploadModal
          images={imageUploadItems}
          onUpload={(uploadedImages) => {
            console.log('Images uploaded from modal:', uploadedImages);
            imageUploadCallback(uploadedImages);
          }}
          onClose={() => setShowImageUploadModal(false)}
        />
      )}
      {showMarkUpModal && (
        <MarkUpImage
          imageUrl={markupImageUrl}
          onSave={async (combinedImageUrl) => {
            if (imageMarkupCallback) {
              await imageMarkupCallback(combinedImageUrl);
            }
          }}
        />
      )}
      {isGenerating && <LoadingModal onClose={() => setIsGenerating(false)} />}
    </div>
  );
};

export default CreateMolarMailButton;
