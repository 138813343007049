import React, { useState, useEffect } from 'react';
import { collection, getDocs, doc, setDoc, updateDoc, increment, query, orderBy, limit, startAfter, where } from 'firebase/firestore';
import { firestore, auth } from '../../firebase';

const SharedTemplates = () => {
  const [templates, setTemplates] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [filteredTemplates, setFilteredTemplates] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [newTemplateName, setNewTemplateName] = useState('');
  const [lastVisible, setLastVisible] = useState(null); // For pagination
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  // Fetch templates from Firestore with ordering and pagination
  const loadTemplates = async (loadMore = false) => {
    setIsLoading(true);
    const sharedTemplatesRef = collection(firestore, 'sharedtemplates');
    const templatesQuery = query(
      sharedTemplatesRef,
      orderBy('steals', 'desc'), // Order by steals descending
      limit(15), // Show 15 templates per page
      ...(loadMore && lastVisible ? [startAfter(lastVisible)] : []) // Pagination
    );

    const querySnapshot = await getDocs(templatesQuery);
    const loadedTemplates = [];
    querySnapshot.forEach((doc) => {
      loadedTemplates.push({ id: doc.id, ...doc.data() });
    });

    setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]); // Set last document for next pagination
    setTemplates(loadMore ? [...templates, ...loadedTemplates] : loadedTemplates); // Append or set
    setIsLoading(false);
    setTotalPages(loadedTemplates.length < 15 ? currentPage : currentPage + 1); // Set total pages based on data
  };

  useEffect(() => {
    loadTemplates();
  }, []);

  // Function to handle search
const handleSearch = async (e) => {
  const value = e.target.value.toLowerCase();
  setSearchInput(value);

  if (!value) {
    setFilteredTemplates([]); // Clear the search results when input is empty
    return;
  }

  try {
    const sharedTemplatesRef = collection(firestore, 'sharedtemplates');

    // Fetch broader results for names and authors to include all potential matches
    const searchQuery = query(sharedTemplatesRef, orderBy('name'), limit(50)); // Adjust limit based on expected volume

    // Execute the search query
    const searchSnapshot = await getDocs(searchQuery);

    // Filter results client-side for case insensitivity
    const searchedTemplates = searchSnapshot.docs
      .map((doc) => ({ id: doc.id, ...doc.data() }))
      .filter(
        (template) =>
          template.name.toLowerCase().includes(value) ||
          template.author?.toLowerCase().includes(value)
      );

    setFilteredTemplates(searchedTemplates);
  } catch (error) {
    console.error('Error searching templates:', error);
  }
};



  // Handle page change
  const handlePageChange = (newPage) => {
    if (newPage < 1 || newPage > totalPages) return;
    setCurrentPage(newPage);
    loadTemplates(newPage > currentPage); // Load more if going forward
  };

  // Function to handle row click and open modal
  const handleRowClick = (template) => {
    setSelectedTemplate(template);
    setNewTemplateName(template.name);
    setIsModalOpen(true);
  };

  // Function to close the modal
  const handleCloseModal = () => {
    setSelectedTemplate(null);
    setIsModalOpen(false);
  };

  // Function to add the selected shared template to the user's exam_templates
  const handleUseTemplate = async () => {
    const user = auth.currentUser;
    if (!user) {
      alert('You need to be logged in to use this template.');
      return;
    }

    const uid = user.uid;
    const userTemplateRef = doc(firestore, 'customers', uid, 'exam_templates', newTemplateName);
    const sharedTemplateRef = doc(firestore, 'sharedtemplates', selectedTemplate.id);

    try {
      // Save the template into the user's exam_templates with the new name
      await setDoc(userTemplateRef, {
        text: selectedTemplate.content,
        label: newTemplateName,
        category: selectedTemplate.category || 'General',
      });

      // Increment the usage count in the shared template
      await updateDoc(sharedTemplateRef, {
        steals: increment(1),
      });

      alert(`Template "${newTemplateName}" has been added to your exam templates.`);
      handleCloseModal();
    } catch (error) {
      console.error('Error adding template to exam templates:', error);
      alert('Failed to add the template. Please try again.');
    }
  };

  const removeDoubleAsterisks = (text) => {
    let formattedText = text.replace(/\*\*(.*?)\*\*/g, '$1'); // Remove **text**
    formattedText = formattedText.replace(/##/g, ''); // Remove ##
    formattedText = formattedText.replace(/###/g, ''); // Remove ###
    formattedText = formattedText.replace(/【\d+†.*?】/g, '');
    return formattedText;
};

  const renderPageNumbers = () => {
    return Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
      <li key={page}>
        <button
          onClick={() => handlePageChange(page)}
          className={`flex items-center justify-center px-3 h-8 leading-tight ${
            page === currentPage ? 'text-white bg-primary-400' : 'text-gray-500 bg-white'
          } border border-gray-300 hover:bg-gray-100 hover:text-gray-700`}
        >
          {page}
        </button>
      </li>
    ));
  };

  return (
    <main className="flex flex-col items-center tracking-tight w-4/5 mx-auto">
      <div className="flex flex-col sm:flex-row flex-wrap space-y-4 sm:space-y-0 items-center justify-between pb-4">
        <label htmlFor="table-search" className="sr-only">
          Search
        </label>
        <div className="relative">
          <input
            type="text"
            id="table-search"
            className="block p-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500"
            placeholder="Search for templates or dentist names"
            value={searchInput}
            onChange={handleSearch}
            autoFocus
            autoComplete="off"
          />
        </div>
      </div>

      <table className="w-3/5 text-sm text-left text-gray-500 bg-white rounded-lg">
        <tbody>
          {searchInput && filteredTemplates.length > 0 ? (
            filteredTemplates.map((template, index) => (
              <tr
                key={template.id}
                className={`${
                  index % 2 === 0 ? 'bg-white' : 'bg-primary-50'
                } hover:bg-primary-100 cursor-pointer`}
                onClick={() => handleRowClick(template)}
              >
                <th
                  scope="row"
                  className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                >
                  {template.name}
                </th>
                <td className="px-6 py-4">{template.author || 'Unknown'}</td>
                <td className="px-6 py-4">{template.steals || 0} steals</td>
              </tr>
            ))
          ) : templates.length > 0 ? (
            templates.map((template, index) => (
              <tr
                key={template.id}
                className={`${
                  index % 2 === 0 ? 'bg-white' : 'bg-primary-50'
                } hover:bg-primary-100 cursor-pointer`}
                onClick={() => handleRowClick(template)}
              >
                <th
                  scope="row"
                  className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                >
                  {template.name}
                </th>
                <td className="px-6 py-4">{template.author || 'Unknown'}</td>
                <td className="px-6 py-4">{template.steals || 0} steals</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="3" className="px-6 py-4 text-center">
                No templates found.
              </td>
            </tr>
          )}
        </tbody>
      </table>

      {isLoading && <p>Loading more templates...</p>}

      {/* Pagination Navigation */}
      <nav aria-label="Page navigation example" className="mt-4">
        <ul className="flex items-center -space-x-px h-8 text-sm">
          <li>
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className="flex items-center justify-center px-3 h-8 ms-0 leading-tight text-gray-500 bg-white border border-e-0 border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700"
            >
              <span className="sr-only">Previous</span>
              <svg
                className="w-2.5 h-2.5 rtl:rotate-180"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 6 10"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M5 1 1 5l4 4"
                />
              </svg>
            </button>
          </li>
          {renderPageNumbers()}
          <li>
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700"
            >
              <span className="sr-only">Next</span>
              <svg
                className="w-2.5 h-2.5 rtl:rotate-180"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 6 10"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 9 4-4-4-4"
                />
              </svg>
            </button>
          </li>
        </ul>
      </nav>

      {/* Modal to display template content */}
      {isModalOpen && selectedTemplate && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-1/2 text-sm">
            <textarea
              readOnly
              value={removeDoubleAsterisks(selectedTemplate.content)}
              className="block w-full p-2 mb-4 border border-gray-300 rounded-lg"
              rows="20"
            />
            <label className="block mb-2 text-xs text-primary-400">Rename Template</label>
            <input
              type="text"
              value={newTemplateName}
              onChange={(e) => setNewTemplateName(e.target.value)}
              placeholder="Enter new template name"
              className="block w-full p-2 mb-4 border border-gray-300 rounded"
            />
            <div className="flex justify-end">
              <div className="inline-flex rounded-md shadow-sm" role="group">
                <button
                  onClick={handleCloseModal}
                  className="px-4 py-2 text-xs text-primary-400 bg-white border border-gray-200 rounded-s-lg hover:bg-primary-400 hover:text-white"
                >
                  Close
                </button>
                <button
                  onClick={handleUseTemplate}
                  className={`${
                    !newTemplateName
                      ? 'px-4 py-2 text-xs text-gray-500 bg-gray-300 rounded-e-lg border border-gray-200 cursor-not-allowed'
                      : 'px-4 py-2 text-xs text-primary-400 bg-white border border-gray-200 rounded-e-lg hover:bg-primary-400 hover:text-white'
                  }`}
                  disabled={!newTemplateName}
                >
                  Use Template
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </main>
  );
};

export default SharedTemplates;
