// noteGeneration.js
import { collection, doc, getDoc, setDoc, updateDoc, getDocs, query, where } from 'firebase/firestore';
import { firestore, auth } from '../../firebase';


export const handleButtonClickMega = async (params) => {
    const {
        transcribedNote,
        setIsFetching,
        setShowLoadingPopup,
        fetchAssistantResponse,
        selectedExamTemplate,
        setAssistantResponse,
        setGeneratedNote,
        generateDiscussion,
        generateTxplan,
        generateRisks,
        generateNote,
        combineAllParts,
        nursesInput,
        callGenerateNoteFunction,
        globalNoteRef,
        patientId,
        assistantResponse
    } = params;

    if (transcribedNote.trim() !== '') {
        setIsFetching(true);
        setShowLoadingPopup(true);

        let combinedNote = "";

        if (!selectedExamTemplate) {
            console.log("No template selected, processing general note components");
            console.log("sending for discussion")
            const discussion = await generateDiscussion(transcribedNote);
            console.log("sending for tx plan")
            const txplan = await generateTxplan(transcribedNote);
            console.log("sending for risks")
            const risks = await generateRisks(transcribedNote);
            console.log("sending for clinical")
            const clinicalFindings = await generateClinicalFindings(transcribedNote);
            console.log("sending for combine")
            combinedNote = combineAllPartsNoTemplate(discussion, txplan, risks, clinicalFindings);
            console.log("Combined note =", combinedNote)
            if (typeof combinedNote !== 'string') {
                console.error('Error: combinedNote is not a string', combinedNote);
            }
            setGeneratedNote(combinedNote);
            setAssistantResponse(combinedNote);
            console.log("Assistant response has been set to:", assistantResponse);
        } else {
            console.log("Template selected:", selectedExamTemplate);
            console.log("sending for discussion")
            const discussion = await generateDiscussion(transcribedNote);
            console.log("sending for tx plan")
            const txplan = await generateTxplan(transcribedNote);
            console.log("sending for risks")
            const risks = await generateRisks(transcribedNote);
            console.log("sending for clinical")
            const clinicalFindings = await generateClinicalFindings(transcribedNote);
            console.log("sending for combine")
            combinedNote = combineAllPartsNoTemplate(discussion, txplan, risks, clinicalFindings);
            console.log("Combined note =", combinedNote)

            console.log("sending for note template combo")
            const note = await generateNote({
                combinedNote,
                selectedExamTemplate,
                nursesInput,
                callGenerateNoteFunction,
            });

            console.log("Generated note received:", combinedNote);

            setGeneratedNote(combinedNote);
            setAssistantResponse(combinedNote);
        }

        console.log("Firestore starting");
        if (globalNoteRef.current) {
            console.log("Updating existing document in Firestore");
            await updateDoc(globalNoteRef.current, {
                content: combinedNote,
            });
            console.log("Document updated successfully.");
        } else {
            console.log("Creating new document in Firestore");
            const noteRef = doc(collection(firestore, 'customers', auth.currentUser.uid, 'notes'));
            await setDoc(noteRef, {
                title: "Jazmode",
                content: combinedNote,
                transcript: transcribedNote,
                patientId: patientId,
                timestamp: new Date()
            });
            globalNoteRef.current = noteRef;
            console.log("Document created successfully.");
        }

        setIsFetching(false);
        setShowLoadingPopup(false);
        console.log("Processing complete.");
    } else {
        console.log("No transcribed note to process.");
    }
};

export const generateClinicalFindings = async (transcribedNote) => {
    const prompt = `Generate discussion based on the following input: ${transcribedNote}`;
    try {
        const idToken = await auth.currentUser.getIdToken();
        const response = await fetch(`https://us-central1-digitaltco-c40e9.cloudfunctions.net/parseClinicalFindings`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${idToken}`,
            },
            body: JSON.stringify({ prompt })
        });
        
        const result = await response.json();
        return result.text;
    } catch (error) {
        console.error("Failed to generate clinical findings:", error);
        return "";  // Return a default or error-specific response if necessary
    }
};

export const generateDiscussion = async (transcribedNote) => {
    const prompt = `Generate discussion based on the following input: ${transcribedNote}`;
    try {
        const idToken = await auth.currentUser.getIdToken();
        const response = await fetch(`https://us-central1-digitaltco-c40e9.cloudfunctions.net/parseDiscussion`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${idToken}`,
            },
            body: JSON.stringify({ prompt })
        });
        
        const result = await response.json();
        return result.text;
    } catch (error) {
        console.error("Failed to generate discussion:", error);
        return "";  // Return a default or error-specific response if necessary
    }
};

export const generateTxplan = async (transcribedNote) => {
    const prompt = `Generate Txplan based on the following input: ${transcribedNote}`;
    try {
        const idToken = await auth.currentUser.getIdToken();
        const response = await fetch(`https://us-central1-digitaltco-c40e9.cloudfunctions.net/parseTxplan`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${idToken}`,
            },
            body: JSON.stringify({ prompt })
        });
        
        const result = await response.json();
        return result.text;
    } catch (error) {
        console.error("Failed to generate tx plan:", error);
        return "";  // Return a default or error-specific response if necessary
    }
};

export const generateRisks = async (transcribedNote) => {
    const prompt = `Generate risks based on the following input: ${transcribedNote}`;
    try {
        const idToken = await auth.currentUser.getIdToken();
        const response = await fetch(`https://us-central1-digitaltco-c40e9.cloudfunctions.net/parseRisks`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${idToken}`,
            },
            body: JSON.stringify({ prompt })
        });
        
        const result = await response.json();
        return result.text;
    } catch (error) {
        console.error("Failed to generate risks:", error);
        return "";  // Return a default or error-specific response if necessary
    }
};

export const generateNote = async ({ combinedNote, selectedExamTemplate, nursesInput, callGenerateNoteFunction }) => {
    const prompt = `Exam Note Template: ${selectedExamTemplate.text}
                    Nurse's Input: ${nursesInput}.
                    Dentist's Notes: ${combinedNote}.
                    (For your reference AI - For the items I have not mentioned an item in the template, leave them as they are, as the patient is healthy in these areas.)`;
    console.log("started callgeneratenotefunction");
    return await callGenerateNoteFunction(prompt);
};


export const combineAllPartsNoTemplate = (discussion, txplan, risks, clinicalFindings) => {
    const finalNote = `${clinicalFindings}\n
    ${discussion}\n
    ${txplan}\n
    ${risks}\n`;

    return finalNote; // Removed 'await' here because it's unnecessary
};
