import React from 'react';

const ToothSpecificDetails = () => {
    return (
        <div className="p-4 border border-gray-300 rounded-lg bg-white dark:bg-gray-800">
            <p className="text-sm text-gray-900 dark:text-gray-100">
                If you have used voice charting, you can incorporate a tooth-specific breakdown of their current condition and treatment plan within the letter. The details will be shown here.
            </p>
        </div>
    );
};

export default ToothSpecificDetails;
