export const fetchFirestoreData = async () => {
    try {
      // Placeholder API call or mock data
      const data = [
        { id: '1', name: 'Document 1', createdAt: new Date() },
        { id: '2', name: 'Document 2', createdAt: new Date() },
      ];
      
      return data;
    } catch (error) {
      console.error('Error fetching Firestore data:', error);
      throw error;
    }
  };
  