import React from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import './PatientNotesInput.css'; 

const navItems = [
  { name: 'Complaints', route: 'patient-complaints' },
  { name: 'PDH', route: 'past-dental-history' },
  { name: 'SH', route: 'social-history' },
  { name: 'Motivators', route: 'motivators' },
  { name: 'Roadblocks', route: 'roadblocks' },
  { name: 'E/O', route: 'extraoral-exam' },
  { name: 'I/O', route: 'intraoral-exam' },
  { name: 'Clinical Caries', route: 'clinical-caries' },
  { name: 'Tooth Wear', route: 'tooth-wear' },
  { name: 'BPE', route: 'BPE' },
  { name: 'OH', route: 'oral-hygiene' },
  { name: 'Caries Risk', route: 'caries-risk' },
  { name: 'Special Tests', route: 'special-tests' },
  { name: 'X-rays', route: 'radiograph-assessment' },
  { name: 'Discussion', route: 'discussion' },
  { name: 'Tx Plan', route: 'treatment-plan' },
  { name: 'Review', route: 'review'},
  { name: 'Full Note', route: 'full-note'}
];

const PatientProfileNav = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { patientId } = useParams();

  const handleNavClick = (route) => {
    if (route === 'review') {
      navigate(`/mvp/patient-profile/${patientId}`);
    } else {
      navigate(`/mvp/patient-profile/${patientId}/${route}`);
    }
  };

  const isActive = (route) => {
  if (route === 'review') {
    return location.pathname === `/mvp/patient-profile/${patientId}`;
  }
  return location.pathname.includes(`${route}`);
};

  return (
    <div className="patient-profile-nav">
      {navItems.map((item) => (
        <button
            key={item.route}
            onClick={() => handleNavClick(item.route)}
            className={`nav-item ${isActive(item.route) ? 'active' : ''}`}
        >
            {item.name}
        </button>
      ))}
    </div>
  );
};

export default PatientProfileNav;
