// src/components/AdminDashboard/MailchimpWidget.jsx

import React from 'react';
import { Card, CardContent, Typography, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';

const MailchimpWidget = ({ data }) => {
  return (
    <Card>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Mailchimp Campaigns
        </Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Campaign Name</TableCell>
              <TableCell>Sent Date</TableCell>
              <TableCell>Open Rate</TableCell>
              <TableCell>Click Rate</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.campaigns.map((campaign) => (
              <TableRow key={campaign.id}>
                <TableCell>{campaign.name}</TableCell>
                <TableCell>{new Date(campaign.sentDate).toLocaleDateString()}</TableCell>
                <TableCell>{campaign.openRate}%</TableCell>
                <TableCell>{campaign.clickRate}%</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

export default MailchimpWidget;
