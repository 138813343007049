import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChrome, faWindows, faApple } from '@fortawesome/free-brands-svg-icons';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

const AudioErrorModal = ({ isVisible, onClose }) => {
  const [defaultMic, setDefaultMic] = useState(null);
  const [isMicChecked, setIsMicChecked] = useState(false);

  const checkDefaultMicrophone = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const devices = await navigator.mediaDevices.enumerateDevices();
      const audioDevices = devices.filter(device => device.kind === 'audioinput');
      const track = stream.getTracks()[0];
      const defaultMicId = track.getSettings().deviceId;
      const defaultMic = audioDevices.find(device => device.deviceId === defaultMicId);
      setDefaultMic(defaultMic ? defaultMic.label : 'Unknown microphone');
      track.stop();
      setIsMicChecked(true);
    } catch (error) {
      console.error('Error accessing microphone:', error);
    }
  };

  if (!isVisible) return null;

  return (
    <div className="fixed inset-0 z-50 bg-black bg-opacity-50 flex items-center justify-center p-4">
      <div className="bg-white border-gray-300 border rounded-lg shadow-lg p-8 w-2/5 text-center">
        <h2 className="text-lg font-bold text-red-600 mb-4 text-center">
          <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2" /> No Audio Detected <FontAwesomeIcon icon={faExclamationTriangle} className="ml-2" />
        </h2>
        <p className="mb-4">
          It looks like we couldn't detect any audio during your recording. Let's make sure everything is working properly.
        </p>
        <p className="mb-4 text-red-500">
          Mac users: Please avoid using Safari, as it may not fully support all our features. We recommend using Chrome for the best experience.
        </p>
        <p className="mb-4 text-center">
          Please follow these steps:
        </p>
        <div className="flex justify-center font-semibold">
          <ul className="text-left mb-4 list-decimal list-inside">
            <li>Check your microphone connection.</li>
            <li>Ensure your microphone is not muted.</li>
            <li>Verify the settings below.</li>
          </ul>
        </div>
        {!isMicChecked && (
          <button
            className="inline-flex items-center justify-center p-0.5 mb-4 overflow-hidden text-xs font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-primary-500 to-pink-500 group-hover:from-purple-500 group-hover:to-pink-500 hover:text-white focus:ring-4 focus:outline-none focus:ring-purple-200"
            onClick={checkDefaultMicrophone}
          >
            <span className="relative px-5 py-1.5 transition-all ease-in duration-75 bg-white rounded-md group-hover:bg-opacity-0">
              Check Default Microphone
            </span>
          </button>
        )}
        {defaultMic && (
          <div className="mb-4 text-center">
            <p><b>Default Microphone: {defaultMic}</b></p>
            <p>If this microphone isn't what you expected, you may need to update your settings directly in your operating system or browser:</p>
            <ul className="mic-instructions">
              <li><a href="https://support.google.com/chrome/answer/2693767?hl=en-GB&co=GENIE.Platform%3DDesktop" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faChrome} /> Chrome Settings</a></li>
              <li><a href="https://www.youtube.com/watch?v=_wYUQlC3RdE" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faWindows} /> Windows Settings</a></li>
              <li><a href="https://support.apple.com/en-gb/guide/mac-help/mchlp2567/mac" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faApple} /> Mac Settings</a></li>
            </ul>
          </div>
        )}
        <div className="flex justify-center">
          <button
            onClick={onClose}
            className="px-4 py-2 text-xs text-gray-700 bg-gray-200 rounded-lg hover:bg-gray-300"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default AudioErrorModal;
