// PDFConsentGen.js

import React, { useState } from 'react';
import axios from 'axios';
import { doc, getDoc } from 'firebase/firestore';
import { auth, firestore } from '../../firebase';
import {
    introduction,
    generalDentalRisks,
    dentalcareDetails,
    compositeDetails,
    rootCanalDetails,
    crownOrOnlayDetails,
    dentureDetails,
    hygieneDetails,
    bridgeDetails,
    extractionDetails,
    treatmentAgreement,
    whiteningDetails,
    dentalimplantDetails,
    patientBehaviour,
    amalgamDetails,
    fissuresealantDetails,
    stainlesssteelcrownDetails,
    compositebondingDetails,
    invisalignDetails,
    fixedorthoDetails,
    veneerDetails,
    sedationDetails,
    fullarchimplantsDetails,
    immediatedentureDetails,
    enlightenDetails
} from '../TreatmentDetails';

export const generatePatientPDF = async (patientId) => {
    // Fetch user profile
    const user = auth.currentUser;
    let fetchedUserProfile;
    if (user) {
      const userRef = doc(firestore, 'customers', user.uid);
      const userDoc = await getDoc(userRef);
      if (userDoc.exists()) {
        fetchedUserProfile = userDoc.data();
      } else {
        console.error("User profile document does not exist");
        return;
      }
    }
  
    // Fetch patient data
    const patientRef = doc(firestore, 'customers', user.uid, 'patients', patientId);
    const patientDoc = await getDoc(patientRef);
    if (!patientDoc.exists()) {
      console.error("Patient document does not exist");
      return;
    }
    const patientName = patientDoc.data().name;
    const nonToothSpecificTreatmentsString = patientDoc.data().notes.nonToothSpecificTreatments || '';
    const treatmentPlanString = patientDoc.data().notes.treatmentPlan || '';
    const selectedTreatments = parseTreatmentPlan(treatmentPlanString, nonToothSpecificTreatmentsString);
  
    // Fetch custom consent form details
    const consentFormsRef = doc(firestore, 'customers', user.uid, 'templates', 'consentforms');
    const consentFormsSnap = await getDoc(consentFormsRef);
    let consentForms;
    
    if (consentFormsSnap.exists()) {
      consentForms = consentFormsSnap.data();
    } else {
      consentForms = {
        introduction,
        generalDentalRisks,
        dentalcareDetails,
        compositeDetails,
        rootCanalDetails,
        crownOrOnlayDetails,
        dentureDetails,
        hygieneDetails,
        bridgeDetails,
        extractionDetails,
        treatmentAgreement,
        whiteningDetails,
        dentalimplantDetails,
        patientBehaviour,
        amalgamDetails,
        fissuresealantDetails,
        stainlesssteelcrownDetails,
        compositebondingDetails,
        invisalignDetails,
        fixedorthoDetails,
        veneerDetails,
        sedationDetails,
        fullarchimplantsDetails,
        immediatedentureDetails,
        enlightenDetails
      };
    }
  
    // Generate PDF
    await generatePDF(fetchedUserProfile, patientName, selectedTreatments, consentForms);
  };
  
  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed in JavaScript
    const year = String(date.getFullYear()).substr(-2); // Get last two digits of the year

    return `${day}/${month}/${year}`;
}

    const todayDate = formatDate(new Date());

const parseTreatmentPlan = (treatmentPlanString, nonToothSpecificTreatmentsString) => {
    const treatments = new Set();
    const treatmentMapping = {
        'RCT': "root canal",
        'Crown': "crown",
        'Amalgam': "amalgam",
        'Composite': "composite filling",
        'Fissure Sealant': "fissure sealant",
        'Extraction': "extraction",
        'Denture': "denture",
        'Hygiene': "hygiene",
        'Bridge': "bridge",
        'Whitening': "whitening",
        'Implant': "implant",
        'Veneer': "veneer",
        'SSC': "ssc",
        'Composite Bonding': "composite bonding",
        'Invisalign': "invisalign",
        'Fixed Ortho': "fixed ortho",
        'Sedation': "sedation",
        // Add other mappings as needed
    };

    console.log("Original Treatment Plan String:", treatmentPlanString);

    // Splitting the string by '; '
    const entries = treatmentPlanString.split('; ');

    entries.forEach(entry => {
        console.log("Processing Entry:", entry);

        // Extract the treatment part
        const treatmentParts = entry.split(' - ')[1];
        if (treatmentParts) {
            treatmentParts.split(', ').forEach(treatmentPart => {
                console.log("Treatment Part:", treatmentPart);
                const treatment = treatmentMapping[treatmentPart];
                if (treatment) {
                    console.log("Mapped Treatment:", treatment);
                    treatments.add(treatment);
                }
            });
        }
        nonToothSpecificTreatmentsString.split('; ').forEach(treatment => {
            if (treatmentMapping[treatment]) {
                treatments.add(treatmentMapping[treatment]);
            }
        });
    });

    console.log("Final Set of Treatments:", Array.from(treatments));
    return Array.from(treatments); // Convert Set to Array to eliminate duplicates
};

const generatePDF = async (fetchedUserProfile, patientName, selectedTreatments, consentForms) => {
    if (!fetchedUserProfile || !fetchedUserProfile.name) {
        console.error("User profile is undefined or missing necessary data");
        alert("Please add your details to your user profile before generating the PDF.");
        return;
    }
        
        const userProfileDetails = `
                <div style="text-align: center; margin-bottom: 70px" >
                    <h1 style="margin: 0;">Dr. ${fetchedUserProfile.name}</h1>
                    <h3 style="margin: 0;">GDC Number: ${fetchedUserProfile.gdcNumber}</h3>
                    <h3 style="margin: 0;">${fetchedUserProfile.practiceName}</h3>
                    <h3 style="margin: 0;">${fetchedUserProfile.practiceAddress}</h3>
                    <h3 style="margin: 0;">${fetchedUserProfile.practicePostcode}</h3>
                    <h3 style="margin: 0;">${fetchedUserProfile.practiceEmail}</h3>
                    <h3 style="margin: 0;">${fetchedUserProfile.practicePhoneNumber}</h3>
                </div>
            `;

            let detailsToUse = `
                <div style="margin-bottom: 70px;">${userProfileDetails}</div>
                <div style=""><p>${todayDate}</p></div>
                <div style=""><p>Dear ${patientName},</p></div>
                <div style="margin-bottom: 70px;">${consentForms.introduction}</div>
                <div style="margin-bottom: 70px;">${consentForms.generalDentalRisks}</div>
                `;

                if (selectedTreatments.includes("composite filling")) {
                    detailsToUse += `<div style="margin-bottom: 70px;">${consentForms.compositeDetails}</div>`;
                }

                if (selectedTreatments.includes("amalgam")) {
                    detailsToUse += `<div style="margin-bottom: 70px;">${consentForms.amalgamDetails}</div>`;
                }

                if (selectedTreatments.includes("fissure sealant")) {
                    detailsToUse += `<div style="margin-bottom: 70px;">${consentForms.fissuresealantDetails}</div>`;
                }

                if (selectedTreatments.includes("ssc")) {
                    detailsToUse += `<div style="margin-bottom: 70px;">${consentForms.stainlesssteelcrownDetails}</div>`;
                }

                if (selectedTreatments.includes("composite bonding")) {
                    detailsToUse += `<div style="margin-bottom: 70px;">${consentForms.compositebondingDetails}</div>`;
                }

                if (selectedTreatments.includes("invisalign")) {
                    detailsToUse += `<div style="margin-bottom: 70px;">${consentForms.invisalignDetails}</div>`;
                }

                if (selectedTreatments.includes("fixed ortho")) {
                    detailsToUse += `<div style="margin-bottom: 70px;">${consentForms.fixedorthoDetails}</div>`;
                }
                
                if (selectedTreatments.includes("root canal")) {
                    detailsToUse += `<div style="margin-bottom: 70px;">${consentForms.rootCanalDetails}</div>`;
                }
            
                if (selectedTreatments.includes("crown")) {
                    detailsToUse += `<div style="margin-bottom: 70px;">${consentForms.crownOrOnlayDetails}</div>`;
                }

                if (selectedTreatments.includes("veneer")) {
                    detailsToUse += `<div style="margin-bottom: 70px;">${consentForms.veneerDetails}</div>`;
                }
            
                if (selectedTreatments.includes("denture")) {
                    detailsToUse += `<div style="margin-bottom: 70px;">${consentForms.dentureDetails}</div>`;
                }
            
                if (selectedTreatments.includes("hygiene")) {
                    detailsToUse += `<div style="margin-bottom: 70px;">${consentForms.hygieneDetails}</div>`;
                }
            
                if (selectedTreatments.includes("bridge")) {
                    detailsToUse += `<div style="margin-bottom: 70px;">${consentForms.bridgeDetails}</div>`;
                }
            
                if (selectedTreatments.includes("extraction")) {
                    detailsToUse += `<div style="margin-bottom: 70px;">${consentForms.extractionDetails}</div>`;
                }
            
                if (selectedTreatments.includes("whitening")) {
                    detailsToUse += `<div style="margin-bottom: 70px;">${consentForms.whiteningDetails}</div>`;
                }
            
                if (selectedTreatments.includes("implant")) {
                    detailsToUse += `<div style="margin-bottom: 70px;">${consentForms.dentalimplantDetails}</div>`;
                }

                if (selectedTreatments.includes("sedation")) {
                    detailsToUse += `<div style="margin-bottom: 70px;">${consentForms.sedationDetails}</div>`;
                }

                detailsToUse += `<div style="margin-bottom: 70px;">${consentForms.dentalcareDetails}</div>`;

                detailsToUse += `<div style="margin-bottom: 70px;">${consentForms.patientBehaviour}</div>`;
            
                detailsToUse += `<div style="margin-bottom: 70px;">${consentForms.treatmentAgreement}</div>`;

            

            if (!detailsToUse) return;
                        
            try {
                const response = await axios.post('https://us-central1-digitaltco-c40e9.cloudfunctions.net/generatePdf', {
                    htmlContent: detailsToUse
                }, {
                    responseType: 'blob'
                });

                const blob = new Blob([response.data], { type: 'application/pdf' });
  
                    console.log("Creating download link for the PDF");
                    const link = document.createElement('a');
                    link.href = URL.createObjectURL(blob);
                    link.download = `${patientName} - Dr. ${fetchedUserProfile.name}.pdf`;
                
                    console.log("Initiating download...");
                    link.click();
                
                    console.log("Download initiated. Cleaning up object URLs");
                    URL.revokeObjectURL(link.href);
                    console.log("PDF generation and download process completed");
                
                } catch (error) {
                    if (error.response) {
                        console.error("Error Status:", error.response.status);
                        console.error("Error Data:", error.response.data);
                        console.error("Error Headers:", error.response.headers);
                
                        // Specific check for auth error
                        if (error.response.status === 401) {
                            console.error("Authentication error. The provided token may be invalid or expired.");
                        }
                    } else if (error.request) {
                        console.error("No response was received:", error.request);
                    } else {
                        console.error("Error setting up the request:", error.message);
                    }
                
                    console.error("Detailed error:", error.config);
                }
                // generatePDF();
            };

            const PDFConsentGen = () => {
              return null; // Since this component does not render anything
            };
            
            export default PDFConsentGen;