import React, { useState } from 'react';
import { Card, CardContent, Typography, Button, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { fetchStripeData, fetchMetricsFromFirestore } from '../../api/AdminDashboard/stripeApi';

const StripeWidget = () => {
  const [loading, setLoading] = useState(false);
  const [fetchSuccess, setFetchSuccess] = useState(false);
  const [calculateSuccess, setCalculateSuccess] = useState(false);
  const [error, setError] = useState(null);
  const [metrics, setMetrics] = useState(null);

  

  const calculateMetrics = (subscriptions) => {
    if (subscriptions.length === 0) {
      return {
        lifetime: {},
        threeMonths: {},
        oneMonth: {}
      };
    }

    const now = Math.floor(Date.now() / 1000); // Current time in Unix timestamp
    const threeMonthsAgo = now - 3 * 30 * 24 * 60 * 60; // 3 months ago
    const oneMonthAgo = now - 30 * 24 * 60 * 60; // 30 days ago

    const calculateForPeriod = (subs) => {
      if (subs.length === 0) {
        return {
          totalRevenue: 0,
          averageRevenuePerUser: 0,
          churnRate: 0,
          retentionRate: 0,
          customerLifetimeValue: 0,
          revenueGrowthRate: 0,
          activeSubscriptionsCount: 0,
          trialingSubscriptionsCount: 0,
        };
      }

      const totalRevenue = subs.reduce((sum, sub) => sum + (sub.plan.amount / 100), 0);
      const averageRevenuePerUser = totalRevenue / subs.length;
      const churned = subs.filter(sub => sub.status === 'canceled').length;
      const active = subs.filter(sub => sub.status === 'active').length;
      const trialing = subs.filter(sub => sub.status === 'trialing').length;
      const churnRate = churned / subs.length;
      const retentionRate = 1 - churnRate;
      const customerLifetimeValue = averageRevenuePerUser / (churnRate || 1); // Avoid division by zero
      const revenueGrowthRate = (totalRevenue - (totalRevenue * retentionRate)) / totalRevenue;

      return {
        totalRevenue,
        averageRevenuePerUser,
        churnRate,
        retentionRate,
        customerLifetimeValue,
        revenueGrowthRate,
        activeSubscriptionsCount: active,
        trialingSubscriptionsCount: trialing,
      };
    };

    const lifetimeMetrics = calculateForPeriod(subscriptions);
    const threeMonthsMetrics = calculateForPeriod(subscriptions.filter(sub => sub.created >= threeMonthsAgo));
    const oneMonthMetrics = calculateForPeriod(subscriptions.filter(sub => sub.created >= oneMonthAgo));

    return {
      lifetime: lifetimeMetrics,
      threeMonths: threeMonthsMetrics,
      oneMonth: oneMonthMetrics,
    };
  };

  const handleFetchLatestData = async () => {
    setLoading(true);
    setError(null);
    setFetchSuccess(false);
    try {
      await fetchStripeData(); // Fetch the data from Stripe and save it to Firestore
      setFetchSuccess(true);
    } catch (err) {
      setError('Failed to fetch the latest data.');
      console.error('Error fetching latest data:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleCalculateMetrics = async () => {
    setLoading(true);
    setError(null);
    setCalculateSuccess(false);
    
    try {
        console.log("Starting to calculate metrics...");
        
        // Fetch the saved data from Firestore
        const response = await fetchMetricsFromFirestore();
        console.log("Data fetched from Firestore:", response);

        // Extract subscriptions
        const subscriptions = response.subscriptions || [];
        console.log("Total subscriptions fetched:", subscriptions.length);

        // Calculate metrics
        const calculatedMetrics = calculateMetrics(subscriptions);
        console.log("Calculated metrics:", calculatedMetrics);

        // Set the calculated metrics in the state
        setMetrics(calculatedMetrics);

        setCalculateSuccess(true);
        console.log("Metrics calculation successful.");
    } catch (err) {
        setError('Failed to calculate metrics.');
        console.error('Error calculating metrics:', err);
    } finally {
        setLoading(false);
    }
  };

  return (
    <Card>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Stripe Data Fetcher & Metrics Calculator
        </Typography>
        <Button 
          variant="contained" 
          color="primary" 
          onClick={handleFetchLatestData}
          disabled={loading}
          style={{ marginTop: '20px', marginRight: '10px' }}
        >
          {loading ? 'Fetching...' : 'Fetch Latest Data'}
        </Button>
        <Button 
          variant="contained" 
          color="secondary" 
          onClick={handleCalculateMetrics}
          style={{ marginTop: '20px' }}
        >
          {loading ? 'Calculating...' : 'Calculate Metrics'}
        </Button>
        {fetchSuccess && (
          <Typography variant="body1" color="primary" style={{ marginTop: '20px' }}>
            Data has been successfully fetched and saved!
          </Typography>
        )}
        {calculateSuccess && metrics && (
          <div style={{ marginTop: '20px' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Metric</TableCell>
                  <TableCell>Lifetime</TableCell>
                  <TableCell>Past 3 Months</TableCell>
                  <TableCell>Past 30 Days</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Total Revenue</TableCell>
                  <TableCell>£{metrics.lifetime?.totalRevenue?.toFixed(2)}</TableCell>
                  <TableCell>£{metrics.threeMonths?.totalRevenue?.toFixed(2)}</TableCell>
                  <TableCell>£{metrics.oneMonth?.totalRevenue?.toFixed(2)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Average Revenue Per User (ARPU)</TableCell>
                  <TableCell>£{metrics.lifetime?.averageRevenuePerUser?.toFixed(2)}</TableCell>
                  <TableCell>£{metrics.threeMonths?.averageRevenuePerUser?.toFixed(2)}</TableCell>
                  <TableCell>£{metrics.oneMonth?.averageRevenuePerUser?.toFixed(2)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Churn Rate</TableCell>
                  <TableCell>{(metrics.lifetime?.churnRate * 100)?.toFixed(2)}%</TableCell>
                  <TableCell>{(metrics.threeMonths?.churnRate * 100)?.toFixed(2)}%</TableCell>
                  <TableCell>{(metrics.oneMonth?.churnRate * 100)?.toFixed(2)}%</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Retention Rate</TableCell>
                  <TableCell>{(metrics.lifetime?.retentionRate * 100)?.toFixed(2)}%</TableCell>
                  <TableCell>{(metrics.threeMonths?.retentionRate * 100)?.toFixed(2)}%</TableCell>
                  <TableCell>{(metrics.oneMonth?.retentionRate * 100)?.toFixed(2)}%</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Revenue Growth Rate</TableCell>
                  <TableCell>{(metrics.lifetime?.revenueGrowthRate * 100)?.toFixed(2)}%</TableCell>
                  <TableCell>{(metrics.threeMonths?.revenueGrowthRate * 100)?.toFixed(2)}%</TableCell>
                  <TableCell>{(metrics.oneMonth?.revenueGrowthRate * 100)?.toFixed(2)}%</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Customer Lifetime Value (LTV)</TableCell>
                  <TableCell>£{metrics.lifetime?.customerLifetimeValue?.toFixed(2)}</TableCell>
                  <TableCell>£{metrics.threeMonths?.customerLifetimeValue?.toFixed(2)}</TableCell>
                  <TableCell>£{metrics.oneMonth?.customerLifetimeValue?.toFixed(2)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Total Active Subscriptions</TableCell>
                  <TableCell>{metrics.lifetime?.activeSubscriptionsCount}</TableCell>
                  <TableCell>{metrics.threeMonths?.activeSubscriptionsCount}</TableCell>
                  <TableCell>{metrics.oneMonth?.activeSubscriptionsCount}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Total Trialing Subscriptions</TableCell>
                  <TableCell>{metrics.lifetime?.trialingSubscriptionsCount}</TableCell>
                  <TableCell>{metrics.threeMonths?.trialingSubscriptionsCount}</TableCell>
                  <TableCell>{metrics.oneMonth?.trialingSubscriptionsCount}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
        )}
        {error && (
          <Typography variant="body2" color="error" style={{ marginTop: '10px' }}>
            {error}
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

export default StripeWidget;
