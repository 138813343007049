import React, { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { firestore } from '../../firebase';
import * as XLSX from 'xlsx';
import { getAuth } from 'firebase/auth';

const FeedbackOverview = () => {
    const [feedbackData, setFeedbackData] = useState([]);
    const [loading, setLoading] = useState(false);

    const auth = getAuth();

    const fetchFeedbackData = async () => {
        setLoading(true);
        try {
            console.log('Fetching feedback data...');
            const feedbackRef = collection(firestore, 'feedback');
            const feedbackSnapshot = await getDocs(feedbackRef);
            console.log(`Total feedback entries fetched: ${feedbackSnapshot.size}`);

            const feedbackEntries = feedbackSnapshot.docs.map(doc => {
                const data = doc.data();
                const timestamp = data.timestamp.toDate();
                return {
                    id: doc.id,
                    ...data,
                    timestamp
                };
            });

            const uniqueUids = [...new Set(feedbackEntries.map(entry => entry.uid))];
            console.log(`Unique UIDs to fetch emails for: ${uniqueUids.length}`);

            // Get the current user's ID token for authorization
            const idToken = await auth.currentUser.getIdToken();

            // Fetch emails from the backend
            const response = await fetch('https://us-central1-digitaltco-c40e9.cloudfunctions.net/getUserEmails', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${idToken}`,
                },
                body: JSON.stringify({ uids: uniqueUids }),
            });

            if (!response.ok) {
                if (response.status === 403) {
                    throw new Error('Forbidden: You do not have the required permissions.');
                } else if (response.status === 401) {
                    throw new Error('Unauthorized: Invalid or expired token.');
                } else {
                    throw new Error(`Backend responded with status ${response.status}`);
                }
            }

            const userEmails = await response.json();
            console.log(`Fetched emails: ${JSON.stringify(userEmails)}`);

            const uidToEmailMap = userEmails.reduce((map, user) => {
                map[user.uid] = user.email;
                return map;
            }, {});

            const feedbackDataWithEmails = feedbackEntries.map(entry => ({
                ...entry,
                email: uidToEmailMap[entry.uid] || 'Email not found',
            }));

            setFeedbackData(feedbackDataWithEmails);
            console.log('Feedback data fetching completed.');
        } catch (error) {
            console.error('Error fetching feedback data or user emails:', error);
            alert(error.message || 'An error occurred while fetching data. Please check the console for details.');
        } finally {
            setLoading(false);
        }
    };

    const downloadAsExcel = () => {
        const data = feedbackData.map(entry => ({
            Time: entry.timestamp.toLocaleString(),
            Email: entry.email,
            Stopped: entry.stopped,
            Sent: entry.sent,
        }));

        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Feedback Data');

        XLSX.writeFile(workbook, `Feedback_Data.xlsx`);
        console.log('Excel file downloaded.');
    };

    return (
        <div className="mb-6 p-6 border rounded shadow-md bg-white">
            <h3 className="text-2xl font-bold mb-6 text-center">Feedback Overview</h3>
            <div className="flex justify-center mb-6">
                <button
                    onClick={fetchFeedbackData}
                    disabled={loading}
                    className={`px-6 py-3 rounded text-white font-semibold ${loading ? 'bg-gray-500 cursor-not-allowed' : 'bg-blue-500 hover:bg-blue-600'}`}
                >
                    {loading ? 'Loading...' : 'Fetch Feedback Data'}
                </button>
                <button
                    onClick={downloadAsExcel}
                    disabled={feedbackData.length === 0}
                    className={`ml-4 px-6 py-3 rounded text-white font-semibold ${feedbackData.length === 0 ? 'bg-gray-400 cursor-not-allowed' : 'bg-green-500 hover:bg-green-600'}`}
                >
                    Download as Excel
                </button>
            </div>
            {feedbackData.length > 0 && (
                <div className="overflow-x-auto">
                    <table className="min-w-full bg-white">
                        <thead>
                            <tr>
                                <th className="py-2 px-4 border-b border-gray-200">Time</th>
                                <th className="py-2 px-4 border-b border-gray-200">Email</th>
                                <th className="py-2 px-4 border-b border-gray-200">Stopped</th>
                                <th className="py-2 px-4 border-b border-gray-200">Sent</th>
                            </tr>
                        </thead>
                        <tbody>
                            {feedbackData.map(entry => (
                                <tr key={entry.id}>
                                    <td className="py-2 px-4 border-b border-gray-200">{entry.timestamp.toLocaleString()}</td>
                                    <td className="py-2 px-4 border-b border-gray-200">{entry.email}</td>
                                    <td className="py-2 px-4 border-b border-gray-200">{entry.stopped}</td>
                                    <td className="py-2 px-4 border-b border-gray-200">{entry.sent}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
};

export default FeedbackOverview;
