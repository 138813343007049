export const fetchAnalyticsData = async () => {
    try {
      // Placeholder API call or mock data
      const data = {
        users: 2000,
        sessions: 3500,
        pageViews: [
          { date: '2023-01-01', views: 150 },
          { date: '2023-01-02', views: 200 },
        ],
      };
      
      return data;
    } catch (error) {
      console.error('Error fetching Analytics data:', error);
      throw error;
    }
  };
  