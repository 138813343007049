// AudioRecordingsList.jsx

import React, { useState, useEffect } from 'react';
import { firestore, auth } from '../../firebase';
import {
  collection,
  getDocs,
  query,
  orderBy,
  limit,
  where,
  startAfter,
  getCountFromServer,
  doc,
  setDoc,
  getFirestore,
  getDoc
} from 'firebase/firestore';
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from "firebase/storage";
import { useNavigate } from 'react-router-dom';
import LoadingPopup from '../patientnotes/LoadingPopup';
import SuccessToast from '../toast/SuccessToast';

const AudioRecordingsList = () => {
  const [audioNotes, setAudioNotes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [lastDoc, setLastDoc] = useState(null);
  const [firstDoc, setFirstDoc] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const notesPerPage = 20;
  const navigate = useNavigate();

  // New state to track processing audio
  const [processingAudioId, setProcessingAudioId] = useState(null); // ID of the audio being processed
  const [showLoadingPopup, setShowLoadingPopup] = useState(false);
  const [letterheadURL, setLetterheadURL] = useState('');
    const [languagePreference, setLanguagePreference] = useState('EN');
    const [dentistName, setDentistName] = useState('Dentist');
    const [showSuccessToast, setShowSuccessToast] = useState(false); // State to control toast visibility
  const [successMessage, setSuccessMessage] = useState('');
  const [searchInput, setSearchInput] = useState('');
const [searchResult, setSearchResult] = useState(null);


  // Utility function to extract filePath from downloadURL
  const extractFilePathFromURL = (url) => {
    try {
      const urlObj = new URL(url);
      const path = urlObj.pathname; // e.g., /v0/b/<bucket>/o/sdcm%2F<uid>%2F<timestamp>.webm
      const encodedFilePath = path.split('/o/')[1].split('?')[0]; // 'sdcm%2F<uid>%2F<timestamp>.webm'
      const filePath = decodeURIComponent(encodedFilePath); // 'sdcm/<uid>/<timestamp>.webm'
      return filePath;
    } catch (error) {
      console.error('Error extracting filePath from URL:', error);
      return null;
    }
  };

  const fetchAudioNotes = async (page = 1) => {
    setIsLoading(true);
    const uid = auth.currentUser.uid;

    // Base query without search filters
    let audioNotesQuery = query(
      collection(firestore, 'customers', uid, 'notes'),
      where('title', '>=', 'Audio Recording'),
      where('title', '<=', 'Audio Recording\uf8ff'),
      orderBy('title'),
      orderBy('timestamp', 'desc'),
      limit(notesPerPage)
    );

    // Handle pagination
    if (page > 1 && lastDoc) {
      audioNotesQuery = query(audioNotesQuery, startAfter(lastDoc));
    }

    try {
      const notesSnapshot = await getDocs(audioNotesQuery);
      const audioNotesData = notesSnapshot.docs.map((docSnap) => ({
        id: docSnap.id,
        ...docSnap.data(),
        timestamp: docSnap.data().timestamp.toDate(),
        audioLink: docSnap.data().audio_recording || docSnap.data().content,
      }));

      setLastDoc(notesSnapshot.docs[notesSnapshot.docs.length - 1]);
      setFirstDoc(notesSnapshot.docs[0]);
      setAudioNotes(audioNotesData);

      // Fetch total count for pagination
      const countQuery = query(
        collection(firestore, 'customers', uid, 'notes'),
        where('title', '>=', 'Audio Recording'),
        where('title', '<=', 'Audio Recording\uf8ff')
      );
      const snapshot = await getCountFromServer(countQuery);
      setTotalPages(Math.ceil(snapshot.data().count / notesPerPage));

      setCurrentPage(page);
    } catch (error) {
      console.error('Error fetching audio notes:', error);
      alert('There was an error fetching the audio recordings. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAudioNotes(currentPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  useEffect(() => {
    const fetchUserData = async () => {
        const user = auth.currentUser;
        if (user) {
            const userRef = doc(getFirestore(), 'customers', user.uid);
            const userDoc = await getDoc(userRef);
            if (userDoc.exists()) {
                const data = userDoc.data();
                if (data.letterheadURL) {
                    setLetterheadURL(data.letterheadURL);
                }
                setLanguagePreference(userDoc.data().languagePreference || 'EN');
                setDentistName(userDoc.data().name || 'Dentist');
            } else {
                console.log("No user data found");
            }
        } else {
            console.log("No user logged in");
        }
    };
    fetchUserData();
}, []);

  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      fetchAudioNotes(page);
    }
  };

  const formatTime = (date) => {
    return date.toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: '2-digit',
      hour12: true,
    });
  };

  const formatDate = (date) => {
    return date.toLocaleDateString('en-GB', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    });
  };

  const handleDownloadAudio = async (note) => {
    const downloadURL = note.audioLink; // Use note to get the audio link
    if (downloadURL) {
      try {
        const response = await fetch(downloadURL);
        if (!response.ok) throw new Error('Network response was not ok.');
        const blob = await response.blob();
        const blobUrl = window.URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = blobUrl;
        // Use note.patientId and a timestamp to name the file
        a.download = `${note.patientId || 'dental_appt'}-${new Date().toISOString()}.webm`;
        document.body.appendChild(a);
        a.click();

        window.URL.revokeObjectURL(blobUrl);
        document.body.removeChild(a);
      } catch (error) {
        console.error('Error downloading audio:', error);
        alert('There was an error downloading the audio file. Please try again.');
      }
    }
  };

  // New handler to process transcript and generate letter
  const handleProcessTranscript = async (note) => {
    const downloadURL = note.audioLink; // Audio file URL
    if (!downloadURL) {
      console.error('No audio link found for this note.');
      alert('No audio file available to process.');
      return;
    }

    // Extract filePath from downloadURL
    const filePath = extractFilePathFromURL(downloadURL);
    console.log('Extracted filePath:', filePath); // Debugging line
    if (!filePath) {
      console.error('Could not extract filePath from downloadURL.');
      alert('Invalid audio file URL.');
      return;
    }

    try {
      setProcessingAudioId(note.id); // Set the processing state
      setShowLoadingPopup(true); // Show loading indicator

      // Step 1: Send filePath to multilanguage function to get transcript
      const payloadMultilanguage = {
        filePath: filePath,
      };

      // Get the user's ID token for authentication
      const user = auth.currentUser;
      if (!user) {
        throw new Error('User not authenticated');
      }
      const idToken = await user.getIdToken();

      // Make the POST request to the multilanguage cloud function
      const responseMultilanguage = await fetch('https://us-central1-digitaltco-c40e9.cloudfunctions.net/multilanguage', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${idToken}`,
        },
        body: JSON.stringify(payloadMultilanguage),
      });

      if (!responseMultilanguage.ok) {
        const errorText = await responseMultilanguage.text();
        throw new Error(`Server Error (multilanguage): ${errorText}`);
      }

      const resultMultilanguage = await responseMultilanguage.json();
      console.log('Transcript:', resultMultilanguage.text); // Log transcript

       // Prepare the transcriptPayload with user preferences
       const transcriptPayload = `YOU MUST REPLY IN ${languagePreference} LANGUAGE.
       Dentist's Name: ${dentistName}.
       ${resultMultilanguage.text}`;
       
             // Step 2: Send transcript to lettersbroke (PatientLetters) function to format letter
             const verbatimatorPayload = {
               prompt: transcriptPayload, // Correct field name
             };
       
             const responseVerbatimator = await fetch('https://us-central1-digitaltco-c40e9.cloudfunctions.net/verbatimator', {
               method: 'POST',
               headers: {
                 'Content-Type': 'application/json',
                 'Authorization': `Bearer ${idToken}`,
               },
               body: JSON.stringify(verbatimatorPayload), // Corrected payload
             });

      if (!responseVerbatimator.ok) {
        const errorText = await responseVerbatimator.text();
        throw new Error(`Server Error (lettersbroke): ${errorText}`);
      }

      const resultPatientLetters = await responseVerbatimator.json();
      console.log('Formatted Letter:', resultPatientLetters.text); // Log formatted letter

      // Step 3: Send formatted letter to DocxCreator to generate .docx file
      const payloadDocxCreator = {
        textContent: resultPatientLetters.text,
      };

      const responseDocxCreator = await fetch('https://us-central1-digitaltco-c40e9.cloudfunctions.net/DocxCreator', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${idToken}`,
        },
        body: JSON.stringify(payloadDocxCreator),
      });

      if (!responseDocxCreator.ok) {
        const errorText = await responseDocxCreator.text();
        throw new Error(`Server Error (DocxCreator): ${errorText}`);
      }

      const resultDocxCreator = await responseDocxCreator.json();
      console.log('Docx Creator Response:', resultDocxCreator); // Log DocxCreator response

      // Convert base64 string to a Blob
      const base64String = resultDocxCreator.base64; // Assuming response has 'base64' field
      const byteCharacters = atob(base64String);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });

      // Create download link and trigger download
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `transcript_${note.id}.docx`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
      console.log('Word document downloaded.');

      // Step 4: Upload the .docx file to Firebase Storage
      const storage = getStorage();
      const storagePath = `letters/${user.uid}/patient-letter-${Date.now()}.docx`;
      const storageReference = storageRef(storage, storagePath);

      await uploadBytes(storageReference, blob);
      const downloadURLDocx = await getDownloadURL(storageReference);
      console.log('Uploaded .docx to Firebase Storage:', downloadURLDocx);

      // Step 5: Save the download URL to Firestore
      const firestoreInstance = firestore; // Already imported
      const noteRef = doc(collection(firestoreInstance, 'customers', user.uid, 'notes'));
      await setDoc(noteRef, {
        title: 'Verbatimator',
        content: downloadURLDocx,
        timestamp: new Date(),
      });
      console.log('Saved download URL to Firestore.');

        // Set success message for toast and show it
        setSuccessMessage('Letter has been generated and downloaded successfully.');
        setShowSuccessToast(true); // Show the success toast
    } catch (error) {
      console.error('Error processing transcript:', error);
      alert('There was an error processing the transcript. Please try again.');
    } finally {
      setProcessingAudioId(null); // Reset the processing state
      setShowLoadingPopup(false); // Hide loading indicator
    }
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    let startPage = Math.max(1, currentPage - 2);
    let endPage = Math.min(totalPages, currentPage + 2);

    if (currentPage === 1) {
      endPage = Math.min(5, totalPages);
    } else if (currentPage === totalPages) {
      startPage = Math.max(1, totalPages - 4);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <li key={i}>
          <button
            onClick={() => handlePageChange(i)}
            className={`flex items-center justify-center px-3 h-8 leading-tight ${
              currentPage === i
                ? 'text-primary-600 border border-primary-300 bg-primary-50 hover:bg-primary-100 hover:text-primary-700'
                : 'text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700'
            }`}
          >
            {i}
          </button>
        </li>
      );
    }
    return pageNumbers;
  };

  const handleSearchById = async () => {
    if (!searchInput.trim()) {
      alert('Please enter a Note ID to search.');
      return;
    }
  
    setIsLoading(true);
  
    try {
      const uid = auth.currentUser.uid;
      const noteRef = doc(firestore, 'customers', uid, 'notes', searchInput.trim());
      const noteDoc = await getDoc(noteRef);
  
      if (noteDoc.exists()) {
        const noteData = noteDoc.data();
        
        // Check if the title contains "Audio Recording"
        if (noteData.title && noteData.title.toLowerCase().includes("audio recording")) {
          setSearchResult({
            id: noteDoc.id,
            ...noteData,
            timestamp: noteData.timestamp.toDate(),
            audioLink: noteData.audio_recording || noteData.content,
          });
          setAudioNotes([]); // Clear the audio notes list
        } else {
          alert('The note does not contain an audio recording.');
          setSearchResult(null); // Reset search result
        }
      } else {
        alert('No note found with the given ID.');
        setSearchResult(null);
      }
    } catch (error) {
      console.error('Error fetching note by ID:', error);
      alert('There was an error fetching the note. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  

  return (
    <main className="flex flex-col items-center tracking-tight w-4/5 mx-auto">
        {showSuccessToast && (
        <SuccessToast
            message={successMessage}
            onClose={() => setShowSuccessToast(false)} // Hide the toast after close
        />
        )}

      {/* Disabled Search Bar */}
      <div className="flex items-center w-1/5 mx-auto justify-center mb-4">
        <input
            type="text"
            placeholder="Enter Record ID"
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
            className="block w-full p-2 text-gray-900 border border-gray-300 rounded-s-lg bg-gray-50 text-xs"
        />
        <button onClick={handleSearchById} className="block p-2 text-gray-900 border-e border-t border-b border-gray-300 rounded-e-lg bg-gray-200 text-xs hover:text-white hover:bg-primary-400">
            Search
        </button>
        {searchInput.trim() && (
            <button onClick={() => { setSearchInput(''); setSearchResult(null); }} className="ml-2 px-2 py-1 text-xs text-gray-300 hover:text-primary-400">
            Clear
            </button>
        )}
        </div>


{/* Audio Recordings Table */}
      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <div className="overflow-x-auto w-full text-sm text-left text-gray-500">
          {searchResult ? (
            <table className="w-full">
              <thead>
                <tr>
                  <th className="px-4 py-2">Date</th>
                  <th className="px-4 py-2">Time</th>
                  <th className="px-4 py-2">Patient ID</th>
                  <th className="px-4 py-2">Title</th>
                  <th className="px-4 py-2">Download</th>
                  <th className="px-4 py-2">Verbatimator</th>
                  <th className="px-4 py-2">Record ID</th>
                </tr>
              </thead>
              <tbody>
                <tr key={searchResult.id} className="border-b hover:bg-primary-200">
                  <td className="px-4 py-2">{formatDate(searchResult.timestamp)}</td>
                  <td className="px-4 py-2">{formatTime(searchResult.timestamp)}</td>
                  <td className="px-4 py-2">{searchResult.patientId || 'N/A'}</td>
                  <td className="px-4 py-2">{searchResult.title}</td>
                  <td className="px-4 py-2">
                    <button
                      className="text-primary-400 hover:animate-pulse"
                      onClick={() => handleDownloadAudio(searchResult)}
                    >
                      Download Audio
                    </button>
                  </td>
                  <td className="px-4 py-2">
                    <button
                      className={`text-black p-2 bg-gradient-to-br from-primary-200 to-teal-200 rounded-lg hover:animate-pulse ${
                        processingAudioId === searchResult.id ? 'opacity-50 cursor-not-allowed' : ''
                      }`}
                      onClick={() => handleProcessTranscript(searchResult)}
                      disabled={processingAudioId === searchResult.id}
                    >
                      {processingAudioId === searchResult.id ? 'Processing...' : 'Verbatimator'}
                    </button>
                  </td>
                  <td className="px-4 py-2">{searchResult.id}</td>
                </tr>
              </tbody>
            </table>
          ) : (
            audioNotes.length > 0 ? (
              <table className="w-full">
                <thead>
                  <tr>
                    <th className="px-4 py-2">Date</th>
                    <th className="px-4 py-2">Time</th>
                    <th className="px-4 py-2">Patient ID</th>
                    <th className="px-4 py-2">Title</th>
                    <th className="px-4 py-2">Download</th>
                    <th className="px-4 py-2">Verbatimator</th>
                    <th className="px-4 py-2">Record ID</th>
                  </tr>
                </thead>
                <tbody>
                  {audioNotes.map((note) => (
                    <tr key={note.id} className="border-b hover:bg-primary-200">
                      <td className="px-4 py-2">{formatDate(note.timestamp)}</td>
                      <td className="px-4 py-2">{formatTime(note.timestamp)}</td>
                      <td className="px-4 py-2">{note.patientId || 'N/A'}</td>
                      <td className="px-4 py-2">{note.title}</td>
                      <td className="px-4 py-2">
                        <button
                          className="text-primary-400 hover:animate-pulse"
                          onClick={() => handleDownloadAudio(note)}
                        >
                          Download Audio
                        </button>
                      </td>
                      <td className="px-4 py-2">
                        <button
                          className={`text-black p-2 bg-gradient-to-br from-primary-200 to-teal-200 rounded-lg hover:animate-pulse ${
                            processingAudioId === note.id ? 'opacity-50 cursor-not-allowed' : ''
                          }`}
                          onClick={() => handleProcessTranscript(note)}
                          disabled={processingAudioId === note.id}
                        >
                          {processingAudioId === note.id ? 'Processing...' : 'Verbatimator'}
                        </button>
                      </td>
                      <td className="px-4 py-2">{note.id}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <p>No audio recordings found.</p>
            )
          )}
        </div>
      )}

      {/* Pagination */}
      <nav aria-label="Page navigation example" className="mt-4">
        <ul className="flex items-center -space-x-px h-8 text-sm">
          <li>
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className="flex items-center justify-center px-3 h-8 ms-0 leading-tight text-gray-500 bg-white border border-e-0 border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700"
            >
              <span className="sr-only">Previous</span>
              <svg
                className="w-2.5 h-2.5 rtl:rotate-180"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 6 10"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M5 1 1 5l4 4"
                />
              </svg>
            </button>
          </li>
          {renderPageNumbers()}
          <li>
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700"
            >
              <span className="sr-only">Next</span>
              <svg
                className="w-2.5 h-2.5 rtl:rotate-180"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 6 10"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 9 4-4-4-4"
                />
              </svg>
            </button>
          </li>
        </ul>
      </nav>

      {/* Loading Popup */}
      {showLoadingPopup && <LoadingPopup />}
    </main>
  );
};

export default AudioRecordingsList;
