import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation, faTimes, faMicrophone, faPaperPlane, faStop, faPause, faPlay, faRocket } from '@fortawesome/free-solid-svg-icons';
import { collection, doc, getDoc, setDoc, updateDoc, getDocs, query, where } from 'firebase/firestore';
import { firestore, auth } from '../../firebase';
import LoadingPopup from  '../patientnotes/LoadingPopup';
import LoadingPopup2 from  '../popup/LoadingPopup2';
import CopyButton from '../CopyButton';
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import PatientLetters from '../letters/PatientLetters';
import ReferralLetters from '../letters/ReferralLetters';
import ReferralResponseLetter from '../letters/ReferralResponseLetter';
import PastExamModal from '../basicexam/PastExamModal';
import ActiveAudioAnalyser from '../activeaudioanalyser/ActiveAudioAnalyser';
import RiskBankChecker from '../riskbank/RiskBankChecker';
import { handleButtonClickMega, generateDiscussion, generateTxplan, generateRisks, generateNote, combineAllPartsNoTemplate } from './noteGeneration';

const JazModev3 = () => {
    const [assistantResponse, setAssistantResponse] = useState('');
    const [isFetching, setIsFetching] = useState(false);
    const [copySuccess, setCopySuccess] = useState('');
    const [isInfoVisible, setIsInfoVisible] = useState(true); 
    const [showLoadingPopup, setShowLoadingPopup] = useState(false);
    const [isRecording, setIsRecording] = useState(false);
    const [audioData, setAudioData] = useState(null);
    const [mediaRecorder, setMediaRecorder] = useState(null);
    const [audioChunks, setAudioChunks] = useState([]);
    const [transcribedNote, setTranscribedNote] = useState('');
    const [canStopRecording, setCanStopRecording] = useState(false);
    const [patientId, setPatientId] = useState('');
    const [downloadUrl, setDownloadUrl] = useState('');
    const [showLoadingPopup2, setShowLoadingPopup2] = useState(false);
    const [nursesInput, setNursesInput] = useState('');
    const [examTemplates, setExamTemplates] = useState('');
    const [searchInput, setSearchInput] = useState('');
    const [filteredTemplates, setFilteredTemplates] = useState([]);
    const [selectedExamTemplate, setSelectedExamTemplate] = useState(null)
    const searchInputRef = useRef(null);
    const [filteredTreatments, setFilteredTreatments] = useState([]);
    const [focusedItemIndex, setFocusedItemIndex] = useState(-1);
    const [generatedNote, setGeneratedNote] = useState('');
    const [isPastExamModalOpen, setIsPastExamModalOpen] = useState(false);
    const [mediaStream, setMediaStream] = useState(null);
    const [isPaused, setIsPaused] = useState(false);
    const [useMegaFunction, setUseMegaFunction] = useState(false);
    const [chartingMethod, setChartingMethod] = useState('Palmer');
    const [languagePreference, setLanguagePreference] = useState('EN');
    const [dentistName, setDentistName] = useState('Dentist');

    useEffect(() => {
        const fetchChartingMethod = async () => {
            const user = auth.currentUser;
            if (user) {
                const userRef = doc(firestore, 'customers', user.uid);
                const userDoc = await getDoc(userRef);
                if (userDoc.exists()) {
                    setChartingMethod(userDoc.data().chartingMethod || 'Palmer'); // Load charting method from Firestore
                }
            }
        };
        fetchChartingMethod();
    }, []);

    useEffect(() => {
        const fetchPreferences = async () => {
            const user = auth.currentUser;
            if (user) {
                const userRef = doc(firestore, 'customers', user.uid);
                const userDoc = await getDoc(userRef);
                if (userDoc.exists()) {
                    setLanguagePreference(userDoc.data().languagePreference || 'EN');
                    setDentistName(userDoc.data().name || 'Dentist');
                } else {
                    // Fallback if no data in Firestore
                    setLanguagePreference('EN');
                }
            }
        };
        fetchPreferences();
    }, []);

    const toggleFunction = () => {
        setUseMegaFunction(prev => !prev);
    };

    const handleOpenPastExamModal = () => {
    setIsPastExamModalOpen(true);
    };

    const handleClosePastExamModal = () => {
    setIsPastExamModalOpen(false);
    };

    const handleUsePastExam = (pastExamText) => {
        const newTemplate = {
            text: pastExamText, // Here you can add more properties as needed
            // Other properties can go here if needed
        };
    
        setSelectedExamTemplate(newTemplate);
    };
    

    const handleSearchInput = (value) => {
        setSearchInput(value);
        if (value.trim() === '') {
            setFilteredTemplates([]);
            setSelectedExamTemplate(null);
        } else {
            const filtered = Object.keys(examTemplates).filter(templateKey =>
                templateKey.toLowerCase().includes(value.toLowerCase())
            );
            setFilteredTemplates(filtered);
        }
    };
    
    const handleKeyDown = (e) => {
        if (e.key === "ArrowDown") {
            e.preventDefault();
            setFocusedItemIndex(prev => Math.min(prev + 1, filteredTemplates.length - 1));
        } else if (e.key === "ArrowUp") {
            e.preventDefault();
            setFocusedItemIndex(prev => Math.max(prev - 1, 0));
        } else if (e.key === "Enter" && focusedItemIndex >= 0) {
            e.preventDefault();
            selectTemplate(filteredTemplates[focusedItemIndex]);
        }
    };
    
    const selectTemplate = (template) => {
        setSelectedExamTemplate(examTemplates[template]);
        // console.log("SET:", selectedExamTemplate);
        setSearchInput(examTemplates[template].label); // Set the input to the label of the selected template
        setFilteredTemplates([]); // Clear filtered list after selection
        setFocusedItemIndex(-1); // Reset focused item index
    };
    
    

    const handlePatientIdChange = (e) => {
        setPatientId(e.target.value);
    };

    const handleNursesInputChange = (e) => {
        setNursesInput(e.target.value);
    };

   
    useEffect(() => {
        const fetchTemplates = async () => {
            const uid = auth.currentUser.uid;
    
            // Fetch basicExamTemplate
            const basicExamRef = doc(firestore, 'customers', uid, 'templates', 'Basic Exam');
            const basicExamSnap = await getDoc(basicExamRef);
            let basicExamTemplate = {};
            if (basicExamSnap.exists()) {
                basicExamTemplate = { 'Basic Exam': { label: 'Basic Exam', icon: null, ...basicExamSnap.data() }};
            }
    
            // Fetch additional examTemplates
            const templatesRef = collection(firestore, 'customers', uid, 'exam_templates');
            const snapshot = await getDocs(templatesRef);
            const fetchedTemplates = {};
            snapshot.forEach(doc => {
                // Assume no specific icon for additional treatments
                fetchedTemplates[doc.id] = { label: doc.id, icon: null, ...doc.data() };
            });
    
            // Combine basicExamTemplate with fetchedTemplates
            const allTreatments = { ...basicExamTemplate, ...fetchedTemplates };
            setExamTemplates(allTreatments);
            // // console.log(examTemplates);
        };
    
        fetchTemplates();
    }, []);

    const handleSelectTemplate = (templateKey) => {
        const template = examTemplates[templateKey];
        if (template) {
            setSelectedExamTemplate(template);
            setSearchInput(template.label); // Assuming each template has a 'label' property
            setFilteredTemplates([]); // Clear filtered list after selection
            setFocusedItemIndex(-1); // Reset focused item index
        }
    };
   
    useEffect(() => {
        // // console.log("Updated template:", selectedExamTemplate);
    }, [selectedExamTemplate]);
    
    
    const globalNoteRef = useRef(null);

    const handleAssistantResponseChange = (e) => {
        setAssistantResponse(e.target.value);
    };

// useEffect(() => {
//     if (audioData) {
//         // // console.log('Audio Blob is set, now sending to cloud function');
//         sendAudioToCloudFunction(audioData);
//     }
// }, [audioData]);

const startRecording = async () => {
    try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        setMediaStream(stream);
        const newMediaRecorder = new MediaRecorder(stream);
        // // console.log('MediaRecorder started');
        setCanStopRecording(true);

        let audioChunks = [];
        newMediaRecorder.ondataavailable = (event) => {
            audioChunks.push(event.data);
        };

        newMediaRecorder.onstop = async () => {
            // // console.log('Recording stopped, creating audio blob');
            const audioBlob = new Blob(audioChunks, { type: 'audio/webm' });
            // // console.log('Audio blob created:', audioBlob.size);

            if (audioBlob.size === 0) {
                alert('Audio recording is blank. If using Safari, please use an alternative browser. If using an iPad, please use an alternative device. If neither, check that your microphone is plugged in and working!');
                setIsRecording(false);
                return; // Early return to prevent further execution
            }

            if (audioBlob.size > 0) {
                const userUID = auth.currentUser ? auth.currentUser.uid : 'anonymous';
                const filePath = `jazmodeaudios/${userUID}/${Date.now()}.webm`;
                const storageRef = ref(getStorage(), filePath);
                await uploadBytes(storageRef, audioBlob);
                
                getDownloadURL(storageRef).then(async (downloadURL) => {
                    // // console.log('Download URL:', downloadURL);
                    setDownloadUrl(downloadURL);

                    const htmlLink = `<a href="${downloadURL}" target="_blank" rel="noopener noreferrer">Right click and save</a>`;
                    const noteRef = doc(collection(firestore, 'customers', userUID, 'notes'));
                    await setDoc(noteRef, {
                        title: "Audio Recording - Jazmode",
                        content: downloadURL,
                        patientId: patientId,
                        timestamp: new Date(),
                       
                    });

                    let finalFilePath = filePath;
                    if (audioBlob.size > 23000000) { // 23MB
                        // console.log("File size exceeds 23MB, compressing...");
                        setShowLoadingPopup2(true);
                        finalFilePath = await compressAudio(filePath, userUID);
                    }

                    sendAudioToCloudFunction(finalFilePath, downloadURL);
                });
            }
        };

        setMediaRecorder(newMediaRecorder);
        newMediaRecorder.start();
        setIsRecording(true);
    } catch (error) {
        console.error('Error in starting recording:', error);
    }
};


const stopRecording = () => {
    if (mediaRecorder) {
        // // console.log('Stopping recording');
        mediaRecorder.stop();
        setIsRecording(false);
        mediaRecorder.stream.getTracks().forEach(track => track.stop());
        setTranscribedNote("Loading...");
        setShowLoadingPopup(true);
    }
};

const sendAudioToCloudFunction = async (filePath, downloadURL) => {
    // console.log("Starting audio2cloud function...");
    try {
        // Get the current authenticated user
        const user = auth.currentUser;
        if (!user) {
            throw new Error("User not authenticated");
        }

        // Get the Firebase ID token of the current user
        const idToken = await user.getIdToken();

        // Prepare the payload
        const payload = {
            filePath: filePath,
            language: languagePreference // Send the user's language preference
        };

        // Make the POST request with Authorization header
        const response = await fetch('https://us-central1-digitaltco-c40e9.cloudfunctions.net/multilanguage', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${idToken}`, // Include the ID token in the Authorization header
            },
            body: JSON.stringify(payload),
        });

        if (!response.ok) {
            // Handle HTTP errors
            const errorText = await response.text();
            throw new Error(`Server Error: ${errorText}`);
        }

        const result = await response.json();
        setShowLoadingPopup(false);
        setShowLoadingPopup2(false);
        setIsRecording(false);

        let appendedTranscript = result.text;

        // Check for specific sequence indicating dead microphone and replace it
        const deadMicrophoneSequence = "Upper Right, Lower Left, Oral Hygiene, Bitewing";
        const deadMicrophoneMessage = "The microphone might be dead or not plugged in. Please check your microphone connection.";
        appendedTranscript = appendedTranscript.replace(deadMicrophoneSequence, deadMicrophoneMessage);

        // RiskBank - check for triggers and append risks if found
        const triggersInTranscript = await RiskBankChecker(appendedTranscript);

        if (triggersInTranscript.length > 0) {
            for (const trigger of triggersInTranscript) {
                const risksQuery = query(collection(firestore, 'customers', auth.currentUser.uid, 'riskBank'), where("trigger", "==", trigger));
                const querySnapshot = await getDocs(risksQuery);
                querySnapshot.forEach(doc => {
                    const riskData = doc.data();
                    appendedTranscript += `\n\nRisk for ${riskData.name}: ${doc.data().risk}`;
                });
            }
        }

        setTranscribedNote(appendedTranscript);

        const noteRef = doc(collection(firestore, 'customers', auth.currentUser.uid, 'notes'));
        await setDoc(noteRef, {
            title: "Jazmode",
            content: '', // This will be updated later
            transcript: appendedTranscript,
            audio_recording: downloadURL,
            patientId: patientId,
            timestamp: new Date()
        });
        globalNoteRef.current = noteRef;
    } catch (error) {
        console.error('Error sending audio URL:', error);
        alert('There was an error processing the audio. Please retrieve the audio from the notes list, and re-upload it using the button below.');
        setShowLoadingPopup(false);
        setShowLoadingPopup2(false);
        setIsRecording(false);
    }
};


const compressAudio = async (filePath) => {
    const user = auth.currentUser;
    if (!user) {
        throw new Error("User not authenticated");
    }

    // Get the Firebase ID token of the current user
    const idToken = await user.getIdToken();

    // Prepare the payload
    const payload = {
        filePath: filePath,
        uid: user.uid // Ensure uid matches the authenticated user
    };

    // Make the POST request with Authorization header
    const response = await fetch('https://us-central1-digitaltco-c40e9.cloudfunctions.net/compressAudio', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${idToken}`, // Include the ID token in the Authorization header
        },
        body: JSON.stringify(payload),
    });
  
    if (!response.ok) {
      throw new Error('Network response was not ok during compression');
    }
  
    const data = await response.json();
    // // // console.log('Compression response:', data);
    return data.outputPath; // Return the path of the compressed file
};

    

const fetchAssistantResponse = async () => {
  
    try {
        const idToken = await auth.currentUser.getIdToken();
        const response = await fetch('https://us-central1-digitaltco-c40e9.cloudfunctions.net/jazmodev3', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${idToken}`,
            },
            body: JSON.stringify({ prompt: transcribedNote }),
        });
        
        const result = await response.json();
        
        // console.log("global note ref:", globalNoteRef);
        
        if (globalNoteRef.current) {
            // Update the existing Firestore document
            await updateDoc(globalNoteRef.current, {
                content: result.text,
            });
        } else {
            // Create a new Firestore document
            const noteRef = doc(collection(firestore, 'customers', auth.currentUser.uid, 'notes'));
            await setDoc(noteRef, {
                title: "Jazmode",
                content: result.text,
                transcript: transcribedNote,
                patientId: patientId,
                timestamp: new Date()
            });
            globalNoteRef.current = noteRef;
        }
        
        // console.log("Document created/updated successfully");
        return result.text;
        // console.log("")
    } catch (error) {
        console.error("Error updating document: ", error);
        setAssistantResponse('Error fetching response.');
        alert("Sorry, the artificial intelligence seemed to be on its own coffee break. Please try to generate your note again.");
        console.error('Error fetching response:', error);
    } 
};

const callGenerateNoteFunction = async (prompt) => {
    try {
      const response = await fetch('https://us-central1-digitaltco-c40e9.cloudfunctions.net/jazmodeV4', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ prompt }),
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const responseData = await response.json();
      

    //   // console.log("global note ref:", globalNoteRef);
        
    if (globalNoteRef.current) {
        // Update the existing Firestore document
        await updateDoc(globalNoteRef.current, {
            content: responseData.text,
        });
    } else {
        // Create a new Firestore document
        const noteRef = doc(collection(firestore, 'customers', auth.currentUser.uid, 'notes'));
        await setDoc(noteRef, {
            title: "Jazmode",
            content: responseData.text,
            transcript: transcribedNote,
            patientId: patientId,
            timestamp: new Date()
        });
        globalNoteRef.current = noteRef;
    }
        return responseData.text;
    } catch (error) {
    //   console.error('Error generating note:', error);
      alert("Sorry, the artificial intelligence seemed to be on its own coffee break. Please try to generate your note again.");
    } 
    };  

    const handleCloseInfo = () => {
        setIsInfoVisible(false);
    };

    const copyToClipboard = () => {
        navigator.clipboard.writeText(assistantResponse).then(() => {
            setCopySuccess('Copied!');
            setTimeout(() => setCopySuccess(''), 1000); // Reset message after 2 seconds
        }, () => {
            setCopySuccess('Failed to copy');
        });
    };

    const handleInputChange = (e) => {
        setTranscribedNote(e.target.value);
        
    };

    const removeDoubleAsterisks = (text) => {
        let formattedText = text.replace(/\*\*(.*?)\*\*/g, '$1'); // Remove **text**
        formattedText = formattedText.replace(/##/g, ''); // Remove ##
        formattedText = formattedText.replace(/###/g, '');
        formattedText = formattedText.replace(/\*\*/g, ''); 
        formattedText = formattedText.replace(/【\d+†.*?】/g, '');
        return formattedText;
    };
    
    const containsReferral = /referr(al|er)/i.test(assistantResponse);

    const handleCheckAudioQuality = () => {
        if (downloadUrl) {
            window.open(downloadUrl, '_blank');
        }
    };


    // todelete

    const handleFileChange = async (event) => {
        // console.log("File input changed");
        const file = event.target.files[0];
        if (file) {
            // console.log('File selected:', file.name);
            
            const userUID = auth.currentUser ? auth.currentUser.uid : 'anonymous';
            const filePath = `jazmodeaudios/${userUID}/${Date.now()}.webm`;
            const storageRef = ref(getStorage(), filePath);
    
            try {
                // Upload the file to Firebase Storage
                // console.log(`Uploading file to Firebase Storage at path: ${filePath}`);
                await uploadBytes(storageRef, file);
    
                // Get the download URL
                const downloadURL = await getDownloadURL(storageRef);
                // console.log(`File uploaded successfully. Download URL: ${downloadURL}`);
    
                let finalFilePath = filePath;
                if (file.size > 23000000) { // 23MB
                    // console.log("File size exceeds 23MB, compressing...");
                    setShowLoadingPopup2(true);
                    finalFilePath = await compressAudio(filePath, userUID); // Include uid here
                    // console.log("Audio Compressed");
                }
    
                // console.log(`Final file path for transcription: ${finalFilePath}`);
                // console.log("Sending audio for transcription");
                sendAudioToCloudFunction(finalFilePath, downloadURL);
            } catch (error) {
                console.error('Error uploading file:', error);
            }
        }
    };
    
        
    const pauseRecording = () => {
        if (mediaRecorder && isRecording) {
            mediaRecorder.pause();
            setIsPaused(true);
        }
    };

    const resumeRecording = () => {
        if (mediaRecorder && isRecording) {
            mediaRecorder.resume();
            setIsPaused(false);
        }
    };

    const handleButtonClick = async () => {

        if (transcribedNote.trim() === '') {
            // console.log("No transcribed note to process");
            return; // Early exit if no note to process
        }

        if (useMegaFunction) {
            handleButtonClickMega({
                transcribedNote,
                setIsFetching,
                setShowLoadingPopup,
                fetchAssistantResponse,
                selectedExamTemplate,
                setAssistantResponse,
                setGeneratedNote,
                generateDiscussion,
                generateTxplan,
                generateRisks,
                generateNote,
                nursesInput,
                callGenerateNoteFunction,
                globalNoteRef,
                patientId,
                assistantResponse
            });
            return; // Exit after handling mega function
        }
    
            setIsFetching(true);
            setShowLoadingPopup(true);
            try {
            
    
            if (!selectedExamTemplate) {
                // Construct the prompt to include the language and dentist's name
                const prompt = `YOU MUST REPLY IN ${languagePreference} LANGUAGE.
                                Dentist's Name: ${dentistName}.
                                Dentist's Notes: ${transcribedNote}.
                                Nurse's Input: ${nursesInput}.
                                The tooth notation system used is ${chartingMethod})`;
            
                // Fetch the assistant response using the constructed prompt
                const assistantResp = await fetchAssistantResponse(prompt);
            
                // Set the assistant response
                setAssistantResponse(assistantResp);
            } else {
                // // console.log("Template selected:", selectedExamTemplate);
                const prompt = `YOU MUST REPLY IN ${languagePreference} LANGUAGE.
                                Dentist's Name: ${dentistName}.
                                Exam Note Template: ${selectedExamTemplate.text}
                                Nurse's Input: ${nursesInput}.
                                Dentist's Notes: ${transcribedNote}.
                                (For your reference AI - For the items I have not mentioned an item in the template, leave them as they are, as the patient is healthy in these areas.
                                The tooth notation system used is ${chartingMethod})`;
                // // console.log("Generated note prompt:", prompt);
    
                // // console.log("Generating note based on template...");
                const generatedResp = await callGenerateNoteFunction(prompt);
                // // console.log("Generated note received:", generatedResp);
    
                setGeneratedNote(generatedResp);
                setAssistantResponse(generatedResp);
            }
        } catch (error) {
            // console.error("Error during assistant response handling:", error);
        } finally {
            // Always turn off fetching indicators
            setIsFetching(false);
            setShowLoadingPopup(false);
        }
    };
    

    const fileInputRef = useRef(null);

    const handleFileInputClick = () => {
        if (fileInputRef.current) {
            // console.log("File input clicked");
            fileInputRef.current.click();
        }
    };
    
    

    return (
        <main className="flex flex-col items-center tracking-tight w-4/5 mx-auto">
            {showLoadingPopup && <LoadingPopup />}
            {showLoadingPopup2 && <LoadingPopup2 />}
            <PastExamModal
            isOpen={isPastExamModalOpen}
            onClose={handleClosePastExamModal}
            onUsePastExam={handleUsePastExam}
            />
         

            {/* <div className="info-box">
                <h2>Important Guidelines: Please Read Before Using</h2>
                <ul>
                    <li>
                        <p>
                            Jazmode is uniquely designed for recording entire appointments. The other features are not built for this. Please use a high-quality lapel microphone to ensure optimal audio quality.
                        </p>
                    </li>
                    <li>
                        <p>
                            <strong>Recommended for Examinations, Not Treatments:</strong> For treatment documentation, utilize our Treatment Notes and templates for a faster, more efficient experience. Refer to the "How To Use" section for guidance.
                        </p>
                    </li>
                    <li>
                        <p>
                            Maximum recording duration is approximately 90 minutes. Press the microphone button and start speaking promptly—no need for prolonged silences at the beginning. Get ready and start!
                        </p>
                    </li>
                    <li>
                        <p>
                            As Jazmode is currently in beta testing, it may not be flawless. Your feedback is crucial for us to enhance this feature. You may encounter some unexpected elements, but that's part of the innovation journey! For stable performance, our other features are at your service.
                        </p>
                    </li>
                    <li>
                        <p>
                            <strong>Editing Notes:</strong> You can edit the notes using Cheet (refer to "How To Use"), or simply press the paper airplane icon to give it another go!
                        </p>
                    </li>
                    <li>
                        <p>
                            For best practices and consistent results, watch the instructional videos provided in the "How To Use" section.
                        </p>
                    </li>
                    <li>
                        <p>
                        By the way, if I haven't already made it abundantly clear, "How to Use", "How to Use", "How to Use"....🤪
                        </p>
                    </li>
                </ul>
            </div> */}

            {/* TOP BAR */}
            <div className="bg-primary-50 border border-primary-500 text-gray-700 px-4 py-3 rounded-lg relative tracking-tighter text-sm mb-8 w-3/5">
                <strong className="font-bold">Important: </strong>
                <span className="block sm:inline">
                    For best results, start talking immediately. If you need to be quiet for a while, pause the recording and resume when ready. Alternatively, keep the conversation going by explaining your actions to the patient—they usually appreciate it.
                    <p className="mt-2">
                    Repetitive words and errors in the transcript often occur due to <b>long silences</b> (usually a few minutes of silence), starting the recording without speaking, or handling the microphone roughly. 
                    </p>
                    <p className="mt-2">
                    If you encounter these issues, click the "Check Audio Quality" button to understand why. If the audio quality is perfect but issues persist, please get in touch.
                    </p>
                </span>
            </div>

            <div className="flex items-center w-[65%] mx-auto justify-center">
                <div>
                    <input
                        type="text"
                        id="patientId"
                        value={patientId}
                        onChange={handlePatientIdChange}
                        placeholder="Patient ID"
                        className="block w-full p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 text-xs focus:ring-blue-500 focus:border-blue-500"
                    />
                </div>

                <div>
                    <input
                        ref={searchInputRef}
                        onKeyDown={handleKeyDown}
                        type="text"
                        id="templatesearch"
                        placeholder="Conform to template..."
                        value={searchInput}
                        autoComplete="off"
                        onChange={(e) => handleSearchInput(e.target.value)}
                        className="realtive block ml-4 w-full p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 text-xs focus:ring-blue-500 focus:border-blue-500"
                    />
                    {filteredTemplates.length > 0 && (
                    <div className="absolute ml-4 z-10 bg-white divide-y divide-gray-100 rounded-lg shadow w-44">
                        <ul className="py-2 text-sm text-gray-700 ">
                            {
                                filteredTemplates.map((templateKey, index) => (
                                    <li
                                        key={templateKey}
                                        onClick={() => handleSelectTemplate(templateKey)}
                                        className={focusedItemIndex === index ? "block px-4 py-2 bg-gray-50" : "block px-4 py-2 hover:cursor-pointer hover:bg-gray-50"}
                                        // onMouseEnter={() => setFocusedItemIndex(index)}
                                        // onMouseLeave={() => setFocusedItemIndex(-1)}
                                    >
                                        {examTemplates[templateKey].label} {/* Display the label */}
                                    </li>
                                ))
                            }

                        </ul>
                    </div>
                        )} 
                </div>
                <div className="pt-1.5">
                <button onClick={handleOpenPastExamModal} className="ml-8 inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-xs font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-primary-500 to-pink-500 group-hover:from-purple-500 group-hover:to-pink-500 hover:text-white focus:ring-4 focus:outline-none focus:ring-purple-200">
                    <span className="relative px-5 py-1.5 transition-all ease-in duration-75 bg-white rounded-md group-hover:bg-opacity-0">
                    Import Previous Exam
                    </span>
                </button>
                </div>                
            </div>

            {/* MAIN SECTION */}
            <div className="mt-8 grid grid-cols-1 gap-6 sm:grid-cols-2 w-full">
            {/* LEFT INPUT COLUMN */}
            <div className="flex flex-col">
                <label className="block mb-2 text-xs text-primary-400">Nurse's notes</label>
                <textarea
                    placeholder="Enter nurse's observations and notes here..."
                    value={nursesInput}
                    onChange={handleNursesInputChange}
                    className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300"
                    rows="5"
                ></textarea>

                <label className="mt-4 block mb-2 text-xs text-primary-400">Dentist's observations and notes</label>
                    <textarea
                        placeholder="Connect your lapel mic and record the entire appointment..."
                        value={transcribedNote}
                        onChange={(e) => setTranscribedNote(e.target.value)}
                        readOnly={isRecording || transcribedNote === "Loading..."}
                        className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300"
                        rows="15"
                    />
                    {mediaStream && (
                        <div className="active-audio-analyser-container">
                            <ActiveAudioAnalyser audioStream={mediaStream} isPaused={isPaused} />
                        </div>
                    )}
              
                {/* BUTTONS */}
                <div className="mx-auto mt-2">
                    <div className="flex">
                        <div className="inline-flex rounded-md shadow-sm" role="group">
                            <button 
                                onClick={startRecording} 
                                disabled={isRecording || isPaused}
                                className={`${isRecording || isPaused ? 'px-4 py-2 text-xl font-medium text-gray-500 bg-gray-300 border border-gray-200 rounded-s-lg cursor-not-allowed' : 'px-4 py-2 text-xl font-medium text-primary-400 bg-white border border-gray-200 rounded-s-lg hover:bg-primary-400 hover:text-white'}`}
                            >
                                <FontAwesomeIcon icon={faMicrophone} />
                            </button>

                            {isRecording && (
                                <button 
                                    onClick={isPaused ? resumeRecording : pauseRecording}
                                    className={`${isPaused ? 'px-4 py-2 text-xl font-medium text-primary-400 bg-white border-t border-b border-gray-200 hover:bg-primary-400 hover:text-white' : 'px-4 py-2 text-xl font-medium text-primary-400 bg-white border-t border-b border-gray-200 hover:bg-primary-400 hover:text-white'}`}
                                >
                                    {isPaused ? <FontAwesomeIcon icon={faPlay} /> : <FontAwesomeIcon icon={faPause} />}
                                </button>
                            )}

                            <button 
                                onClick={stopRecording} 
                                disabled={!isRecording || isPaused}
                                className={`${!isRecording || isPaused ? 'px-4 py-2 text-xl font-medium text-gray-500 bg-gray-300 border-t border-b border-gray-200 cursor-not-allowed' : 'px-4 py-2 text-xl font-medium text-primary-400 bg-white border-t border-b border-gray-200 hover:bg-primary-400 hover:text-white'}`}
                            >
                                <FontAwesomeIcon icon={faStop} />
                            </button>
                            
                            <button
                                onClick={handleButtonClick}
                                disabled={isFetching || isRecording || isPaused}
                                className={`${isFetching || isRecording || isPaused ? 'px-4 py-2 text-xl font-medium text-gray-500 bg-gray-300 border border-gray-200 rounded-e-lg cursor-not-allowed' : 'px-4 py-2 text-xl font-medium text-primary-400 bg-white border border-gray-200 rounded-e-lg hover:bg-primary-400 hover:text-white'}`}
                            >
                                <FontAwesomeIcon icon={faPaperPlane} />
                            </button>
                        </div>
                        <button
                            onClick={toggleFunction}
                            className={`${useMegaFunction ? 'ml-2 px-4 py-2 text-xl font-medium text-white bg-malachite-400 border border-gray-200 rounded-lg' : 'ml-2 px-4 py-2 text-xl font-medium text-primary-400 bg-white border border-gray-200 rounded-lg hover:bg-malachite-400 hover:text-white hover:animate-pulse'}`}
                        >
                            <FontAwesomeIcon icon={faRocket} /><sup className="text-xs ml-1">BETA</sup>
                        </button>
                    </div>
                </div>

                {/* OTHER BUTTONS */}
                <div className="mx-auto mt-2">
    <div className="inline-flex rounded-md shadow-sm" role="group">
        {/* <button 
            type="button" 
            onClick={handleFileInputClick}
            className="px-4 py-2 text-xs text-primary-400 bg-white border border-gray-200 rounded-s-lg hover:bg-primary-400 hover:text-white"
        >
            Upload Audio (webm)
        </button>
        <input
            type="file"
            id="fileInput"
            ref={fileInputRef}
            onChange={handleFileChange}
            accept="audio/*"
            style={{ display: 'none' }}
        /> */}
        <button 
            disabled={!downloadUrl}
            onClick={handleCheckAudioQuality}
            className={`${!downloadUrl ? 'px-4 py-2 text-xs text-gray-500 bg-gray-300 rounded-lg border border-gray-200 cursor-not-allowed' : 'px-4 py-2 text-xs text-primary-400 bg-white border border-gray-200 rounded-e-lg hover:bg-primary-400 hover:text-white'}`}
        >
            Check Audio Quality
        </button>
    </div> 
</div>
            </div>

            {/* RIGHT OUTPUT COLUMN */}
            <div className="flex flex-col">
            
                <label className="block mb-2 text-xs text-primary-400">AI Output</label>
                <textarea
                    value={removeDoubleAsterisks(assistantResponse)}
                    placeholder="Notes will show here..."
                    onChange={handleAssistantResponseChange}
                    className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300"
                    rows="25"
                />
           
                <div className="flex flex-col items-center justify-center mx-auto mt-2">
                    <CopyButton textToCopy={removeDoubleAsterisks(assistantResponse)} />
                    <div className="mx-auto mt-2">
                        {assistantResponse && (
                            <PatientLetters noteContent={removeDoubleAsterisks(assistantResponse)} />
                        )}
                        {containsReferral && (
                        <ReferralLetters noteContent={removeDoubleAsterisks(assistantResponse)} />
                        )}
                        {assistantResponse && (
                            <ReferralResponseLetter noteContent={removeDoubleAsterisks(assistantResponse)} />
                        )}
                    </div>
                </div>
            </div>

            </div>
        </main>
    );
};

export default JazModev3;