import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { firestore, auth } from '../../firebase';
import PatientProfileNav from './PatientProfileNav';
import './PatientNotesInput.css'; 

const ToothWear = () => {
  const [toothWear, setToothWear] = useState('');
  const [toothWearOptions, setToothWearOptions] = useState({
    localised: false, generalised: false, posterior: false, anterior: false,
    attrition: false, erosion: false, abrasion: false, abfraction: false,
    'lost canine guidance': false, 'group function': false, 'NWS interference': false,
    'restricted envelope of function': false, 'reduced OVD': false, 'dentoalveolar compensation': false,
    AOB: false, 'deep overbite': false
  });
  const navigate = useNavigate();
  const { patientId } = useParams();

  useEffect(() => {
    const patientRef = doc(firestore, 'customers', auth.currentUser.uid, 'patients', patientId);

    const fetchToothWear = async () => {
      const patientDoc = await getDoc(patientRef);
      if (patientDoc.exists() && patientDoc.data().notes && patientDoc.data().notes.toothWear) {
        setToothWear(patientDoc.data().notes.toothWear); // Set the tooth wear data to the local state
      }
    };

    fetchToothWear();
  }, [patientId]);

  const toggleToothWearOption = (option) => {
    setToothWearOptions(prev => ({ ...prev, [option]: !prev[option] }));
  };

  const handleNext = async () => {
    const patientRef = doc(firestore, 'customers', auth.currentUser.uid, 'patients', patientId);

    const selectedToothWearOptionsString = Object.entries(toothWearOptions)
      .filter(([key, value]) => value)
      .map(([key]) => key.charAt(0).toUpperCase() + key.slice(1))
      .join(', ');

    const updateString = `${selectedToothWearOptionsString} - ${toothWear}`;

    await updateDoc(patientRef, {
      'notes.toothWear': updateString.trim()
    });

    navigate(`/mvp/patient-profile/${patientId}/BPE`);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleNext();
    }
  };

  return (
    <div className="patient-complaints-container">
      <PatientProfileNav patientId={patientId} />
      <h2 className="patient-complaints-h2">Tooth Wear</h2>

      <>
      <div className="sub-options">
        {['erosion', 'abrasion', 'attrition', 'abfraction'].map(option => (
          <button
            key={option}
            onClick={() => toggleToothWearOption(option)}
            className={`treatment-button ${toothWearOptions[option] ? 'selected' : ''}`}
          >
            {option.charAt(0).toUpperCase() + option.slice(1)}
          </button>
        ))}
      </div>
      <div className="sub-options">
        {['anterior', 'posterior'].map(option => (
          <button
            key={option}
            onClick={() => toggleToothWearOption(option)}
            className={`treatment-button ${toothWearOptions[option] ? 'selected' : ''}`}
          >
            {option.charAt(0).toUpperCase() + option.slice(1)}
          </button>
        ))}
      </div>
      <div className="sub-options">
        {['localised', 'generalised'].map(option => (
          <button
            key={option}
            onClick={() => toggleToothWearOption(option)}
            className={`treatment-button ${toothWearOptions[option] ? 'selected' : ''}`}
          >
            {option.charAt(0).toUpperCase() + option.slice(1)}
          </button>
        ))}
      </div>
      <div className="sub-options">
        {['lost canine guidance', 'group function'].map(option => (
          <button
            key={option}
            onClick={() => toggleToothWearOption(option)}
            className={`treatment-button ${toothWearOptions[option] ? 'selected' : ''}`}
          >
            {option.charAt(0).toUpperCase() + option.slice(1)}
          </button>
        ))}
      </div>
      <div className="sub-options">
        {['NWS interference'].map(option => (
          <button
            key={option}
            onClick={() => toggleToothWearOption(option)}
            className={`treatment-button ${toothWearOptions[option] ? 'selected' : ''}`}
          >
            {option.charAt(0).toUpperCase() + option.slice(1)}
          </button>
        ))}
      </div>
      <div className="sub-options">
        {['restricted envelope of function'].map(option => (
          <button
            key={option}
            onClick={() => toggleToothWearOption(option)}
            className={`treatment-button ${toothWearOptions[option] ? 'selected' : ''}`}
          >
            {option.charAt(0).toUpperCase() + option.slice(1)}
          </button>
        ))}
      </div>
      <div className="sub-options">
        {['AOB', 'deep overbite'].map(option => (
          <button
            key={option}
            onClick={() => toggleToothWearOption(option)}
            className={`treatment-button ${toothWearOptions[option] ? 'selected' : ''}`}
          >
            {option.charAt(0).toUpperCase() + option.slice(1)}
          </button>
        ))}
      </div>
      </>

      <textarea
        className="patient-complaints-textarea"
        placeholder="Enter additional tooth wear information here"
        value={toothWear}
        onChange={(e) => setToothWear(e.target.value)}
        onKeyPress={handleKeyPress}
        autoFocus
      />

      <button className="patient-complaints-button" onClick={handleNext}>Next</button>
    </div>
  );
};

export default ToothWear;
