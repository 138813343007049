const jazTemplates = {
  "RC Exam": `Reason for Attendance: Exam

Patient Complains of: No present complaint
Medical History: Checked
Consent Gained Verbally for Examination Today: Yes

Alcohol Intake: No alcohol
Smoking: Non-smoker
Cancer Risk: Low

Past Dental History: Regular attender

Extra Oral Exam -

Asymmetry: NAD
Lymph Nodes: NAD
Lips: NAD
Mouth Opening: NAD
Temporo-Mandibular Joint: NAD

Soft Tissues -

Tongue: NAD
Palate: NAD
Floor of Mouth: NAD
Buccal Mucosa: NAD
Tonsils: NAD
Gingivae: NAD
Dental Hygiene:

Oral Hygiene: Good
Brushing: Twice a day with an electric toothbrush
Interdental Aids: Interdental brushes

Clinical Findings -

Caries: Nil
Fractures: Nil
Wear: Nil

Occlusion -
Incisal Relationship: Class I

Investigations/Radiographs -

Radiographs: None
Radiograph Report: Not applicable
Maximum Bone Loss: 0%
Periodontal Diagnosis: Nil
Special Tests: N/A

Diagnosis -

Gingivae: Clinical Health
Caries Risk: Low
Perio Risk: Low
Tooth Surface Loss Risk: Low

Discussions/Treatment Options -

Discussion of Periodontal Disease: N/A
Discussion of Teeth: N/A
Discussion of Wear: N/A
Discussion of Cosmetic Concerns: N/A
Risks: N/A
Benefits: N/A

Further Notes: N/A

Treatment Plan: Nil

Treatment Provided Today: Nil

Oral Health Advice Given: Yes
Treatment Plan Signed/Emailed to Patient: Yes

Next Visit: 6/12

Recall: 6 months exam according to NICE guidelines
  `,


  };
  
  export default jazTemplates;
  




