import { 
    useState, useEffect, useRef, useParams, useContext, useNavigate, FontAwesomeIcon, 
    faTriangleExclamation, faTimes, faMicrophone, faPaperPlane, faStop, faPause, faPlay, faRocket, faPlus,
    collection, doc, getDoc, setDoc, updateDoc, getDocs, query, where, onSnapshot, firestore, auth,
    LoadingPopup, LoadingPopup2, CopyButton, getStorage, ref, uploadBytes, getDownloadURL,
    PatientLetters, ReferralLetters, ReferralResponseLetter, PastExamModal, ActiveAudioAnalyser,
    RiskBankChecker, DentalChart, handleButtonClickMega, generateDiscussion, generateTxplan, generateRisks, generateNote, combineAllPartsNoTemplate, 
    fetchTemplates, handleSelectTemplate, useFetchTemplatesEffect, deleteObject
} from './utils/imports';
import { useTreatmentPlannerState } from './utils/imports';
import { defaultTeethData } from './defaultTeethData';
import CreateMolarMailButton from '../modularletters/CreateMolarMailButton';
import {
  faEye,
  faEyeSlash,
  faFire, 
  faFeather,
} from '@fortawesome/free-solid-svg-icons';
import ShareTemplateButton from '../sharedtemplates/ShareTemplateButton';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import AudioErrorModal from '../elements/AudioErrorModal';
import SilenceErrorModal from '../elements/SilenceErrorModal';
import AudioEditor from '../audioeditor/AudioEditor';
import ResetButton from '../elements/ResetButton';
import debounce from 'lodash.debounce';
import OnboardingJoyrideWrapper from '../onboardingsidebar/OnboardingJoyrideWrapper';
import { OnboardingContext } from '../onboardingsidebar/OnboardingContext';
import PatientReccosModal from './PatientReccosModal';



const TreatmentPlanner = ({ openSidebar }) => {
    const { 
        assistantResponse, setAssistantResponse,
        isFetching, setIsFetching,
        copySuccess, setCopySuccess,
        isInfoVisible, setIsInfoVisible,
        showLoadingPopup, setShowLoadingPopup,
        isRecording, setIsRecording,
        audioData, setAudioData,
        mediaRecorder, setMediaRecorder,
        audioChunks, setAudioChunks,
        transcribedNote, setTranscribedNote,
        canStopRecording, setCanStopRecording,
        patientId, setPatientId,
        downloadUrl, setDownloadUrl,
        showLoadingPopup2, setShowLoadingPopup2,
        nursesInput, setNursesInput,
        examTemplates, setExamTemplates,
        searchInput, setSearchInput,
        filteredTemplates, setFilteredTemplates,
        selectedExamTemplate, setSelectedExamTemplate,
        searchInputRef,
        filteredTreatments, setFilteredTreatments,
        focusedItemIndex, setFocusedItemIndex,
        generatedNote, setGeneratedNote,
        isPastExamModalOpen, setIsPastExamModalOpen,
        mediaStream, setMediaStream,
        isPaused, setIsPaused,
        useMegaFunction, setUseMegaFunction,
        teethData, setTeethData,
        treatmentplanData, setTreatmentplanData,
        viewTreatmentPlan, setViewTreatmentPlan,
        noteId, navigate
    } = useTreatmentPlannerState();

    const [originalTranscribedNote, setOriginalTranscribedNote] = useState('');
    const [isAppending, setIsAppending] = useState(false);
    const [languagePreference, setLanguagePreference] = useState('EN');
    const [dentistName, setDentistName] = useState('Dentist');
    const [showChart, setShowChart] = useState(false);
    const [isMeatyDiscussion, setIsMeatyDiscussion] = useState(null);
    const [isAudioErrorModalVisible, setIsAudioErrorModalVisible] = useState(false);
    const audioDetectedRef = useRef(false);
    const [analyserKey, setAnalyserKey] = useState(0);
    const [isSilenceErrorModalVisible, setIsSilenceErrorModalVisible] = useState(false);
    const [isAudioEditorVisible, setIsAudioEditorVisible] = useState(false);
    const [showEditAudioButton, setShowEditAudioButton] = useState(false);
    const [saveAudioRecordings, setSaveAudioRecordings] = useState(true);
    const [currentAudioRecordId, setCurrentAudioRecordId] = useState(null);
    const [chartingMethod, setChartingMethod] = useState('FDI');
    const [isPatientReccosModalOpen, setIsPatientReccosModalOpen] = useState(false);
    const [patientReccosResponse, setPatientReccosResponse] = useState('');
    const [isUpdateModalVisible, setIsUpdateModalVisible] = useState(false);
    const isUpdatingFromFirestoreRef = useRef(false);
const assistantResponseRef = useRef(assistantResponse);

    const { onboardingStepKey, setOnboardingStepKey } = useContext(OnboardingContext);
    const [userData, setUserData] = useState(null);
  
    // Define refs
    const onboarding1Ref = useRef(null);
    const onboarding2Ref = useRef(null);
    const onboarding3Ref = useRef(null);
    const onboarding4Ref = useRef(null);
    const onboarding5Ref = useRef(null);
    const onboarding6Ref = useRef(null);
    const onboarding7Ref = useRef(null);
    const onboarding8Ref = useRef(null);
    const onboarding9Ref = useRef(null);
    const onboarding10Ref = useRef(null);
    const onboarding11Ref = useRef(null);
  
    const onboardingRefs = {
      onboarding1Ref,
      onboarding2Ref,
      onboarding3Ref,
      onboarding4Ref,
      onboarding5Ref,
      onboarding6Ref,
      onboarding7Ref,
      onboarding8Ref,
      onboarding9Ref,
      onboarding10Ref,
      onboarding11Ref,
    };

  
    // Define stepsDefinition function
    const stepsDefinition = (stepKey, refs) => {
        let steps = [];
        if (stepKey === 'step6') {
          steps = [
            {
              target: refs.onboarding1Ref.current,
              content: "🆔 Step 1: Enter the Patient ID — Add the ID if you want, or skip it for now. Easy start!",
              disableBeacon: true,
            },
            {
              target: refs.onboarding2Ref.current,
              content: "📄 Step 2: Choose a Template — It's set to Basic Exam. Keep it simple and leave it as is for this tutorial.",
            },
            {
              target: refs.onboarding3Ref.current,
              content: "✍️ Step 3: Nurse's Input — If doing post-appointment notes, the nurse types everything they hear here. No stress about order or spelling! As an example, write a couple of things in here now.",
            },
            {
              target: refs.onboarding4Ref.current,
              content: "🔥 Step 4: Discussion Style — Choose 'Flame' for detailed notes or try 'Feather' for a softer touch. See what fits best! Leave it on Flame for now.",
            },
            {
              target: refs.onboarding5Ref.current,
              content: "🎙️ Step 5: Present the Case — Click the mic and present the case/record live. Only tell DigitalTCO the patients problems. Watch Video #4 in the Knowledge Vault to master this.",
            },
            {
              target: refs.onboarding6Ref.current,
              content: "⌛ Step 6: See the Transcript — Watch as your words are automatically transcribed here. Like magic!",
            },
            {
              target: refs.onboarding7Ref.current,
              content: "📨 Step 7: Send to AI — Click the button to let the AI transform your words into a polished note.",
            },
            {
              target: refs.onboarding8Ref.current,
              content: "👀 Step 8: Review the Note — Your note appears here! Remember, practice makes perfect—watch video #4 in the Knowledge Vault!!",
            },
            {
              target: refs.onboarding9Ref.current,
              content: "📋 Step 9: Copy the Note — Happy with it? Hit 'Copy' and drop it into your patient management system.",
            },
            {
              target: refs.onboarding10Ref.current,
              content: "🔄 Step 10: Refresh & Start Again — When you're ready, refresh to create a new note.",
            },
            {
              target: refs.onboarding11Ref.current,
              content: "🚀 Step 11: The 'Import Previous Exam' and 'Chart' buttons are more advanced features. We’ll dive into these later.",
            },
          ];
        }
        return steps;
      };

      useEffect(() => {
        assistantResponseRef.current = assistantResponse;
      }, [assistantResponse]);      
      

      useEffect(() => {
        const fetchSaveAudioSetting = async () => {
          const user = auth.currentUser;
          if (user) {
            const userRef = doc(firestore, 'customers', user.uid);
            const userDoc = await getDoc(userRef);
            if (userDoc.exists()) {
              setSaveAudioRecordings(userDoc.data().saveAudioRecordings !== false); // Default to true if not set
              setChartingMethod(userDoc.data().chartingMethod || 'Palmer');
            }
          }
        };
        fetchSaveAudioSetting();
      }, []);
      
      // Log whenever chartingMethod changes
      useEffect(() => {
        // console.log("chartingMethod =", chartingMethod);
      }, [chartingMethod]);

      const handleSaveAudioRecordingsToggle = () => {
        setSaveAudioRecordings(prev => !prev);
      };
      

    const toggleChartVisibility = () => {
        setShowChart((prev) => !prev); // Toggle the chart visibility
    };

    useEffect(() => {
        const fetchPreferences = async () => {
          const user = auth.currentUser;
          if (user) {
            const userRef = doc(firestore, 'customers', user.uid);
            const userDoc = await getDoc(userRef);
            if (userDoc.exists()) {
              setIsUpdateModalVisible(!userDoc.data().dismissedUpdateModal);
              console.log("Fetched isMeatyDiscussion from Firestore:", userDoc.data().isMeatyDiscussion); // Log the fetched value
              setLanguagePreference(userDoc.data().languagePreference || 'EN');
              setDentistName(userDoc.data().name || 'Dentist');
              setIsMeatyDiscussion(userDoc.data().isMeatyDiscussion !== undefined ? userDoc.data().isMeatyDiscussion : true); // Update the logic here
            } else {
              // Fallback if no data in Firestore
              setLanguagePreference('EN');
              setIsMeatyDiscussion(true);
              setIsUpdateModalVisible(true);
            }
          }
        };
        fetchPreferences();
      }, []);

      const handleDoNotShowAgain = async () => {
        const user = auth.currentUser;
        if (user) {
          const userRef = doc(firestore, 'customers', user.uid);
          await updateDoc(userRef, { dismissedUpdateModal: true });
        }
        setIsUpdateModalVisible(false);
      };
      
      const handleUpdateModalOk = () => {
        setIsUpdateModalVisible(false);
      };
      

      useEffect(() => {
        if (isMeatyDiscussion !== null) {
          const saveDiscussionStyle = async () => {
            const user = auth.currentUser;
            if (user) {
              const userRef = doc(firestore, 'customers', user.uid);
              try {
                await updateDoc(userRef, { isMeatyDiscussion });
                console.log(`isMeatyDiscussion saved as ${isMeatyDiscussion}`);
              } catch (error) {
                console.error("Error updating isMeatyDiscussion:", error);
              }
            }
          };
      
          saveDiscussionStyle();
        }
      }, [isMeatyDiscussion]);
      
      
    
    

    useEffect(() => {
        const fetchLastUsedTemplate = async () => {
            if (Object.keys(examTemplates).length === 0) return; // Wait until templates are loaded
    
            const user = auth.currentUser;
            if (user) {
                const userRef = doc(firestore, 'customers', user.uid);
                const userDoc = await getDoc(userRef);
                if (userDoc.exists()) {
                    // Set default template to "Basic Exam" if no last used template is found
                    const lastUsedTemplateLabel = userDoc.data().lastUsedTemplate || 'Basic Exam';
                    setSearchInput(lastUsedTemplateLabel);
    
                    // Find the matching template
                    const matchingTemplateKey = Object.keys(examTemplates).find(key => examTemplates[key].label === lastUsedTemplateLabel);
                    if (matchingTemplateKey) {
                        const template = examTemplates[matchingTemplateKey];
                        setSelectedExamTemplate(template);
                    }
                }
            }
        };
        fetchLastUsedTemplate();
    }, [examTemplates]);
    

    // 1. **Save Nurse's Input with Debouncing and Note Creation**
  useEffect(() => {
    // Check if nursesInput is empty or only whitespace
    if (!nursesInput.trim()) {
      // If empty, do not proceed
      return;
    }

    // Function to save nurse's input to Firestore
    const saveNursesInput = async () => {
      const user = auth.currentUser;
      if (!user) {
        console.error('User not authenticated');
        return;
      }

      // If no note reference exists, create a new note
      if (!globalNoteRef.current) {
        try {
          const userUID = user.uid;
          const newNoteRef = doc(collection(firestore, 'customers', userUID, 'notes'));
          await setDoc(newNoteRef, {
            title: "Exam Notes",
            nursesInput: nursesInput,
            patientId: patientId || '', // Ensure patientId is included if available
            timestamp: new Date(),
            // Add other necessary default fields here
          });
          globalNoteRef.current = newNoteRef; // Correctly set the DocumentReference
        //   // console.log('New note created and nurse\'s input saved');
        } catch (error) {
          console.error('Error creating new note:', error);
          return;
        }
      } else {
        // Update the existing note with nurse's input
        try {
          await updateDoc(globalNoteRef.current, {
            nursesInput: nursesInput,
          });
        //   // console.log('Nurse\'s input updated in Firestore');
        } catch (error) {
          console.error('Error updating nurse\'s input:', error);
        }
      }
    };

    // Debounce the save function to prevent excessive writes
    const debouncedSave = debounce(saveNursesInput, 500);

    // Invoke the debounced save function
    debouncedSave();

    // Cleanup function to cancel debounce on unmount or input change
    return () => {
      debouncedSave.cancel();
    };
  }, [nursesInput, patientId]); // Depend on nursesInput and patientId

  // 2. **Retrieve Nurse's Input from Firestore on Component Mount or Note ID Change**
  useEffect(() => {
    const fetchNursesInput = async () => {
      // console.log('fetchNursesInput called');
      
      const user = auth.currentUser;
      if (!user) {
        console.error('User not authenticated');
        return;
      }

      // console.log(`User authenticated: ${user.uid}`);
      let noteRef = null;

      if (noteId) {
        // If a specific noteId is provided, use it
        noteRef = doc(firestore, 'customers', user.uid, 'notes', noteId);
        // console.log(`Using provided noteId: ${noteId}`);
      } else if (globalNoteRef.current) {
        // If globalNoteRef is already set, use it
        noteRef = globalNoteRef.current;
        // console.log(`Using globalNoteRef: ${globalNoteRef.current.id}`);
      } else {
        // No noteId and no existing note reference
        console.warn('No noteId or existing note reference found. Nurse\'s input will be saved to a new note upon typing.');
        return;
      }

      try {
        const noteDoc = await getDoc(noteRef);
        // console.log(`Attempting to fetch document with reference: ${noteRef.id}`);

        if (noteDoc.exists()) {
          const noteData = noteDoc.data();
          // console.log('Note document exists. Data:', noteData);

          setNursesInput(noteData.nursesInput || '');
          // console.log(`Nurse's input set to: ${noteData.nursesInput || ''}`);

          globalNoteRef.current = noteRef; // Ensure globalNoteRef.current is a DocumentReference
          // console.log(`globalNoteRef.current set to document reference with ID: ${noteRef.id}`);
        } else {
          console.warn('Note document does not exist');
        }
      } catch (error) {
        console.error('Error fetching nurse\'s input:', error);
      }
    };

    fetchNursesInput();
  }, [noteId]); // Depend on noteId


    const toggleChartView = () => {
        setViewTreatmentPlan(prev => !prev);
    };

    const toggleFunction = () => {
        setUseMegaFunction(prev => !prev);
    };

    const handleOpenPastExamModal = () => {
        setIsPastExamModalOpen(true);
    };

    const handleClosePastExamModal = () => {
        setIsPastExamModalOpen(false);
    };

    const handleUsePastExam = (pastExamText) => {
        const newTemplate = {
            text: pastExamText,
        };
        setSelectedExamTemplate(newTemplate);
    };

    const handleKeyDown = (e) => {
        if (e.key === "ArrowDown") {
            e.preventDefault();
            setFocusedItemIndex(prev => Math.min(prev + 1, filteredTemplates.length - 1));
        } else if (e.key === "ArrowUp") {
            e.preventDefault();
            setFocusedItemIndex(prev => Math.max(prev - 1, 0));
        } else if (e.key === "Enter" && focusedItemIndex >= 0) {
            e.preventDefault();
            const selectedTemplateKey = filteredTemplates[focusedItemIndex];
            localHandleSelectTemplate(selectedTemplateKey); // Use localHandleSelectTemplate
        }
    };
    
    
    // Update the usage to use the renamed local function
    const handleSearchInput = (value) => {
        setSearchInput(value);
        if (value.trim() === '') {
            setFilteredTemplates([]);
            setSelectedExamTemplate(null);
        } else {
            const filtered = Object.keys(examTemplates).filter(templateKey =>
                templateKey.toLowerCase().includes(value.toLowerCase())
            );
            setFilteredTemplates(filtered);
        }
    };

    const handlePatientIdChange = (e) => {
        setPatientId(e.target.value);
    };

    const handleNursesInputChange = (e) => {
        setNursesInput(e.target.value);
    };

    useFetchTemplatesEffect(setExamTemplates, setSelectedExamTemplate, setSearchInput);

    const localHandleSelectTemplate = async (templateKey) => {
        try {
            handleSelectTemplate(
                templateKey,
                examTemplates,
                setSelectedExamTemplate,
                setSearchInput,
                setFilteredTemplates,
                setFocusedItemIndex
            );
    
            // Update Firestore with the selected template
            const user = auth.currentUser;
            if (user) {
                const userRef = doc(firestore, 'customers', user.uid);
                const templateLabel = examTemplates[templateKey]?.label;
    
                if (!templateLabel) {
                    // console.error(`Template label not found for key: ${templateKey}`);
                    return;
                }
    
                await updateDoc(userRef, { lastUsedTemplate: templateLabel });
                // // console.log(`Successfully updated lastUsedTemplate to: ${templateLabel}`);
            }
        } catch (error) {
            // console.error("Error updating Firestore with selected template:", error);
            alert("Failed to update the selected template. Please try again.");
        }
    };
    
    
    

    const globalNoteRef = useRef(null);

    const debouncedUpdateFirestore = debounce(async (updatedResponse, globalNoteRef) => {
        try {
          const user = auth.currentUser;
          if (user && globalNoteRef) {
            isUpdatingFromFirestoreRef.current = true; // Set before updating Firestore
            await updateDoc(globalNoteRef, {
              content: updatedResponse,
            });
          }
        } catch (error) {
          console.error('Error updating Firestore document:', error);
        }
      }, 500);
      
    
    const handleAssistantResponseChange = (e) => {
        const updatedResponse = e.target.value;
        setAssistantResponse(updatedResponse);
      
        // Call the debounced function
        debouncedUpdateFirestore(updatedResponse, globalNoteRef.current);
      };
      
    
    // Cleanup function to cancel the debounce when the component unmounts or updates
    useEffect(() => {
        return () => {
            debouncedUpdateFirestore.cancel();
        };
    }, []);

    const startRecording = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            setMediaStream(stream);
            const newMediaRecorder = new MediaRecorder(stream);
            setCanStopRecording(true);
            audioDetectedRef.current = false;
            setAnalyserKey(prevKey => prevKey + 1);

            let audioChunks = [];
            newMediaRecorder.ondataavailable = (event) => {
                audioChunks.push(event.data);
            };
            newMediaRecorder.onstop = async () => {
                const audioBlob = new Blob(audioChunks, { type: 'audio/webm' });
                if (audioBlob.size === 0) {
                    alert('Audio recording is blank. If using Safari, please use an alternative browser. If using an iPad, please use an alternative device. If neither, check that your microphone is plugged in and working!');
                    setIsRecording(false);
                    return;
                }
                if (audioBlob.size > 0) {
                    const userUID = auth.currentUser ? auth.currentUser.uid : 'anonymous';
                    const filePath = `jazmodeaudios/${userUID}/${Date.now()}.webm`;
                    const storageRef = ref(getStorage(), filePath);
                    await uploadBytes(storageRef, audioBlob);
                    getDownloadURL(storageRef).then(async (downloadURL) => {
                    setDownloadUrl(downloadURL);

                    // Conditionally create Firestore record based on saveAudioRecordings
                    if (saveAudioRecordings) {
                        const noteRef = doc(collection(firestore, 'customers', userUID, 'notes'));
                        await setDoc(noteRef, {
                        title: "Audio Recording - Exam Notes",
                        content: downloadURL,
                        patientId: patientId,
                        timestamp: new Date(),
                        });

                        setCurrentAudioRecordId(noteRef.id);
                    }
            
                    let finalFilePath = filePath;
                    if (audioBlob.size > 23000000) {
                        setShowLoadingPopup2(true);
                        finalFilePath = await compressAudio(filePath, userUID);
                    }
                                            
                    sendAudioToCloudFunction(finalFilePath, downloadURL);
                        
                    });
                }
            };
            setMediaRecorder(newMediaRecorder);
            newMediaRecorder.start();
            setIsRecording(true);
        } catch (error) {
            // console.error('Error in starting recording:', error);
        }
    };

    const startRecordingAppendage = async () => {
        // // console.log("Starting append recording...");
        // // console.log("TranscribedNote=", transcribedNote);
        setIsAppending(true); // Indicate that we are in appending mode
        
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            setMediaStream(stream);
            const newMediaRecorder = new MediaRecorder(stream);
            setCanStopRecording(true);

            audioDetectedRef.current = false;
            setAnalyserKey(prevKey => prevKey + 1);

            let audioChunks = [];
            newMediaRecorder.ondataavailable = (event) => {
                audioChunks.push(event.data);
            };
            newMediaRecorder.onstop = async () => {
                const audioBlob = new Blob(audioChunks, { type: 'audio/webm' });
                if (audioBlob.size === 0) {
                    alert('Audio recording is blank. Please check your microphone settings.');
                    setIsRecording(false);
                    return;
                }
                const userUID = auth.currentUser ? auth.currentUser.uid : 'anonymous';
                const filePath = `jazmodeaudios/${userUID}/${Date.now()}.webm`;
                const storageRef = ref(getStorage(), filePath);
                await uploadBytes(storageRef, audioBlob);
                const downloadURL = await getDownloadURL(storageRef);
                // // console.log("Download URL:", downloadURL);
                // Handle appending to transcript logic here
                appendTranscripts(filePath, downloadURL, transcribedNote); // Pass transcribedNote directly
            };
            setMediaRecorder(newMediaRecorder);
            newMediaRecorder.start();
            setIsRecording(true);
        } catch (error) {
            // console.error('Error starting recording:', error);
        }
    };
    

    const appendTranscripts = async (filePath, downloadURL, transcribedNote) => {
        try {
            // Get the current authenticated user
            const user = auth.currentUser;
            if (!user) {
                throw new Error("User not authenticated");
            }
    
            // Get the Firebase ID token of the current user
            const idToken = await user.getIdToken();
    
            // Prepare the payload
            const payload = {
                filePath: filePath,
                language: languagePreference // Send the user's language preference
            };
    
            // Make the POST request with Authorization header
            const response = await fetch('https://us-central1-digitaltco-c40e9.cloudfunctions.net/multilanguage', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${idToken}`, // Include the ID token in the Authorization header
                },
                body: JSON.stringify(payload),
            });
    
            if (!response.ok) {
                // Handle HTTP errors
                const errorText = await response.text();
                throw new Error(`Server Error: ${errorText}`);
            }

            const result = await response.json();
            setShowLoadingPopup(false);
            setShowLoadingPopup2(false);
            setIsRecording(false);

            // // console.log("originalTranscribedNote:", transcribedNote);
    
            // Combine the original and new transcribed notes safely
            const newTranscribedNote = `${transcribedNote}\n${result.text}`;
    
            // Check for triggers in the new transcript
            const triggersInTranscript = await RiskBankChecker(result.text);
            if (triggersInTranscript.length > 0) {
                for (const trigger of triggersInTranscript) {
                    const risksQuery = query(collection(firestore, 'customers', auth.currentUser.uid, 'riskBank'), where("trigger", "==", trigger));
                    const querySnapshot = await getDocs(risksQuery);
                    querySnapshot.forEach(doc => {
                        const riskData = doc.data();
                        newTranscribedNote += `\n\nRisk for ${riskData.name}: ${doc.data().risk}`;
                    });
                }
            }
    
            setTranscribedNote(newTranscribedNote);

            if (hasRepetitiveWords(newTranscribedNote)) {
                setIsSilenceErrorModalVisible(true);
                setShowEditAudioButton(true);
            }
    
            const noteRef = doc(collection(firestore, 'customers', auth.currentUser.uid, 'notes'));
            const noteData = {
                title: "Exam Notes",
                content: '',
                transcript: newTranscribedNote,
                patientId: patientId,
                teethData: teethData,
                treatmentPlanData: treatmentplanData,
                timestamp: new Date()
            };
    
            // Conditionally include audio_recording
            if (saveAudioRecordings) {
                noteData.audio_recording = downloadURL;
            } else {
                // If not saving, delete the uploaded audio file to free up space
                const storageRef = ref(getStorage(), filePath);
                await deleteObject(storageRef);
            }
    
            await setDoc(noteRef, noteData);
            globalNoteRef.current = noteRef;
        } catch (error) {
            // console.error('Error sending audio URL:', error);
            setShowLoadingPopup2(false);
        }
    };
    

    const stopRecording = () => {
        if (mediaRecorder) {
            mediaRecorder.stop();
            setIsRecording(false);
            mediaRecorder.stream.getTracks().forEach(track => track.stop());
            setTranscribedNote("Loading...");
            setShowLoadingPopup(true);
    
            // Check if any audio was detected during the recording
            if (!audioDetectedRef.current) {
                setIsAudioErrorModalVisible(true); // Show the error modal
                setShowLoadingPopup(false);
                setTranscribedNote('');
                setIsAppending(false);
                return;
            }
        }
    };
    

    const sendAudioToCloudFunction = async (filePath, downloadURL, mergedTeethData, mergedTreatmentPlanData, isUploadedFile = false) => {
        try {
            // Get the current authenticated user
            const user = auth.currentUser;
            if (!user) {
                throw new Error("User not authenticated");
            }
    
            // Get the Firebase ID token of the current user
            const idToken = await user.getIdToken();
    
            // Prepare the payload
            const payload = {
                filePath: filePath,
                language: languagePreference // Send the user's language preference
            };
    
            // Make the POST request with Authorization header
            const response = await fetch('https://us-central1-digitaltco-c40e9.cloudfunctions.net/multilanguage', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${idToken}`, // Include the ID token in the Authorization header
                },
                body: JSON.stringify(payload),
            });
    
            if (!response.ok) {
                // Handle HTTP errors
                const errorText = await response.text();
                throw new Error(`Server Error: ${errorText}`);
            }

            const result = await response.json();
            setShowLoadingPopup(false);
            setShowLoadingPopup2(false);
            setIsRecording(false);
            let appendedTranscript = result.text;
            const triggersInTranscript = await RiskBankChecker(result.text);
            if (triggersInTranscript.length > 0) {
                for (const trigger of triggersInTranscript) {
                    const risksQuery = query(collection(firestore, 'customers', auth.currentUser.uid, 'riskBank'), where("trigger", "==", trigger));
                    const querySnapshot = await getDocs(risksQuery);
                    querySnapshot.forEach(doc => {
                        const riskData = doc.data();
                        appendedTranscript += `\n\nRisk for ${riskData.name}: ${doc.data().risk}`;
                    });
                }
            }
            setTranscribedNote(appendedTranscript);

            if (!isUploadedFile && !audioDetectedRef.current) {
                setTranscribedNote('');
                return;
            }

            if (hasRepetitiveWords(appendedTranscript)) {
                setIsSilenceErrorModalVisible(true);
                setShowEditAudioButton(true);
            }

            if (!mergedTeethData) {
                mergedTeethData = [];
            }
            if (!mergedTreatmentPlanData) {
                mergedTreatmentPlanData = [];
            }

            const noteRef = doc(collection(firestore, 'customers', auth.currentUser.uid, 'notes'));
            const noteData = {
                title: "Exam Notes",
                content: "",
                transcript: appendedTranscript,
                patientId: patientId,
                teethData: mergedTeethData,
                treatmentPlanData: mergedTreatmentPlanData,
                timestamp: new Date()
            };
    
            // Conditionally include audio_recording
            if (saveAudioRecordings) {
                noteData.audio_recording = downloadURL;
            } else {
                // If not saving, delete the uploaded audio file to free up space
                const storageRef = ref(getStorage(), filePath);
                await deleteObject(storageRef);
            }
    
            await setDoc(noteRef, noteData);
            // // console.log('Note successfully saved to Firestore with ID:', noteRef.id);

            globalNoteRef.current = noteRef;
        } catch (error) {
            // console.error('Error in sendAudioToCloudFunction:', error);
            setShowLoadingPopup2(false);
        }
    };

    const compressAudio = async (filePath) => {
        const user = auth.currentUser;
        if (!user) {
            throw new Error("User not authenticated");
        }
    
        // Get the Firebase ID token of the current user
        const idToken = await user.getIdToken();
    
        // Prepare the payload
        const payload = {
            filePath: filePath,
            uid: user.uid // Ensure uid matches the authenticated user
        };
    
        // Make the POST request with Authorization header
        const response = await fetch('https://us-central1-digitaltco-c40e9.cloudfunctions.net/compressAudio', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${idToken}`, // Include the ID token in the Authorization header
            },
            body: JSON.stringify(payload),
        });
      
        if (!response.ok) {
          throw new Error('Network response was not ok during compression');
        }
      
        const data = await response.json();
        // // // console.log('Compression response:', data);
        return data.outputPath; // Return the path of the compressed file
    };
    

    const handleCloseInfo = () => {
        setIsInfoVisible(false);
    };

    const copyToClipboard = () => {
        navigator.clipboard.writeText(assistantResponse).then(() => {
            setCopySuccess('Copied!');
            setTimeout(() => setCopySuccess(''), 1000);
        }, () => {
            setCopySuccess('Failed to copy');
        });
    };

    const handleInputChange = (e) => {
        setTranscribedNote(e.target.value);
    };

    const removeDoubleAsterisks = (text) => {
        if (!text) return ''; // Handle undefined or null text
        let formattedText = text.replace(/\*\*(.*?)\*\*/g, '$1');
        formattedText = formattedText.replace(/###/g, ''); // Remove ### first
        formattedText = formattedText.replace(/##/g, '');  // Then remove ##
        formattedText = formattedText.replace(/\*\*/g, '');
        formattedText = formattedText.replace(/【\d+†.*?】/g, '');
        return formattedText;
    };
    
    const containsReferral = /referr(al|er)/i.test(assistantResponse);

    const handleCheckAudioQuality = () => {
        if (downloadUrl) {
            window.open(downloadUrl, '_blank');
        }
    };

    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
            // Validate file extension
            const fileExtension = file.name.split('.').pop().toLowerCase();
            if (fileExtension !== 'webm') {
                alert('Only .webm audio files are allowed.');
                return;
            }
    
            // Optionally, validate MIME type
            if (file.type !== 'audio/webm' && file.type !== 'video/webm') {
                alert('Only .webm audio files are allowed.');
                return;
            }

            const userUID = auth.currentUser ? auth.currentUser.uid : 'anonymous';
            const filePath = `jazmodeaudios/${userUID}/${Date.now()}.webm`;
            const storageRef = ref(getStorage(), filePath);
            const metadata = {
                contentType: 'audio/webm',
              };
            try {
                setIsFetching(true);
                setShowLoadingPopup(true);
                await uploadBytes(storageRef, file, metadata);
                const downloadURL = await getDownloadURL(storageRef);
                let finalFilePath = filePath;
                if (file.size > 23000000) {
                    setShowLoadingPopup2(true);
                    finalFilePath = await compressAudio(filePath);
                }
                
                sendAudioToCloudFunction(finalFilePath, downloadURL, null, null, true);

            } catch (error) {
                console.error('Error uploading file:', error);
                setIsFetching(false);
                setShowLoadingPopup(false);
            }
        }
    };

    const pauseRecording = () => {
        if (mediaRecorder && isRecording) {
            mediaRecorder.pause();
            setIsPaused(true);
        }
    };

    const resumeRecording = () => {
        if (mediaRecorder && isRecording) {
            mediaRecorder.resume();
            setIsPaused(false);
        }
    };

    const extractDentalChartingSection = (transcribedNote) => {
        const startPhrase = "start dental chart";
        const stopPhrase = "stop dental chart";
    
        const lowerCasedNote = transcribedNote.toLowerCase();
        const startIndex = lowerCasedNote.indexOf(startPhrase);
        const stopIndex = lowerCasedNote.indexOf(stopPhrase);
    
        if (startIndex !== -1 && stopIndex !== -1 && stopIndex > startIndex) {
            return transcribedNote.substring(startIndex + startPhrase.length, stopIndex).trim();
        }
    
        return ""; // Return empty string if the section is not found
    };
    
    const extractTreatmentPlanSection = (transcribedNote) => {
        const startPhrase = "start treatment chart";
        const stopPhrase = "stop treatment chart";
    
        const lowerCasedNote = transcribedNote.toLowerCase();
        const startIndex = lowerCasedNote.indexOf(startPhrase);
        const stopIndex = lowerCasedNote.indexOf(stopPhrase);
    
        if (startIndex !== -1 && stopIndex !== -1 && stopIndex > startIndex) {
            return transcribedNote.substring(startIndex + startPhrase.length, stopIndex).trim();
        }
    
        return ""; // Return empty string if the section is not found
    };

    const mergeTeethDataWithDefaults = (teethData) => {
        const defaultData = defaultTeethData.map(tooth => ({
            ...tooth,
            segments: { ...tooth.segments, ...teethData.find(tdTooth => tdTooth.number === tooth.number)?.segments }
        }));
        return defaultData;
    };
    
    const mergeTreatmentPlanDataWithDefaults = (treatmentPlanData) => {
        const defaultData = defaultTeethData.map(tooth => ({
            ...tooth,
            segments: { ...tooth.segments, ...treatmentPlanData.find(tpTooth => tpTooth.number === tooth.number)?.segments }
        }));
        return defaultData;
    };
    
    const parseDentalCharting = async (dentalChartingSection) => {
        const prompt = dentalChartingSection;
        // // console.log(prompt);
        // // console.log("sending to get the json");
        try {
            const idToken = await auth.currentUser.getIdToken();

            const response = await fetch(`https://us-central1-digitaltco-c40e9.cloudfunctions.net/parseDentalCharting`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${idToken}`,
                },
                body: JSON.stringify({ prompt })
            });
            
            const result = await response.json();
            let cleanedResponse = result.text;
    
            // Remove any backticks and "json" occurrences
            cleanedResponse = cleanedResponse.replace(/```json/g, '').replace(/```/g, '');
    
            // Parse the cleaned response
            const teethData = JSON.parse(cleanedResponse);
            return teethData;
        } catch (error) {
            // console.error("Failed to generate charting:", error);
            return "";  // Return a default or error-specific response if necessary
        }
    };

    const removeSectionsFromTranscript = (transcribedNote) => {
        const dentalStartPhrase = "start dental chart";
        const dentalStopPhrase = "stop dental chart";
        const treatmentStartPhrase = "start treatment chart";
        const treatmentStopPhrase = "stop treatment chart";
    
        let lowerCasedNote = transcribedNote.toLowerCase();
    
        const removeSection = (startPhrase, stopPhrase, note) => {
            const startIndex = note.indexOf(startPhrase);
            const stopIndex = note.indexOf(stopPhrase);
    
            if (startIndex !== -1 && stopIndex !== -1 && stopIndex > startIndex) {
                return note.substring(0, startIndex) + note.substring(stopIndex + stopPhrase.length);
            }
    
            return note;
        };
    
        lowerCasedNote = removeSection(dentalStartPhrase, dentalStopPhrase, lowerCasedNote);
        lowerCasedNote = removeSection(treatmentStartPhrase, treatmentStopPhrase, lowerCasedNote);
    
        return String(lowerCasedNote);  // Ensure it's a string
    };

    const splitTranscript = (cleanedTranscript) => {
        const endOfClinicalExamPhrase = "end of clinical exam";
        const lowerCasedTranscript = cleanedTranscript.toLowerCase();
        const splitIndex = lowerCasedTranscript.indexOf(endOfClinicalExamPhrase);
    
        let clinicalExamTranscriptText = "";
        let discussionTranscriptText = "";
    
        if (splitIndex !== -1) {
            clinicalExamTranscriptText = cleanedTranscript.substring(0, splitIndex).trim();
            discussionTranscriptText = cleanedTranscript.substring(splitIndex + endOfClinicalExamPhrase.length).trim();
        } else {
            clinicalExamTranscriptText = cleanedTranscript;  // If the phrase is not found, use the entire transcript
        }
    
        return {
            clinicalExamTranscriptText,
            discussionTranscriptText
        };
    };
    
    const fetchAssistantResponse = async (cleanedTranscript, mergedTeethData, mergedTreatmentPlanData) => {
        try {
            const transcriptString = String(cleanedTranscript);  // Ensure it's a string
            // // console.log("Sending cleaned transcript to assistant:", transcriptString);
            const idToken = await auth.currentUser.getIdToken();
    
            const response = await fetch('https://us-central1-digitaltco-c40e9.cloudfunctions.net/jazmodev3', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${idToken}`,
                },
                body: JSON.stringify({ prompt: transcriptString }),
            });
    
            // // console.log("Response status:", response.status);
            // // console.log("Response status text:", response.statusText);
            
            if (!response.ok) {
                // console.error("HTTP error! status:", response.status, response.statusText);
                const errorText = await response.text();  // Read the error response text
                // console.error("Error response text:", errorText);
                throw new Error(`HTTP error! status: ${response.status} ${response.statusText}`);
            }
    
            const result = await response.json();
            // // console.log("Assistant response received:", result);
    
            if (globalNoteRef.current) {
                // // console.log("Updating existing note:", globalNoteRef.current.id);
                // // console.log("Teeth Data being saved:", mergedTeethData);
                // // console.log("Treatment Plan Data being saved:", mergedTreatmentPlanData);
                await updateDoc(globalNoteRef.current, {
                    content: result.text,
                    teethData: mergedTeethData,
                    treatmentPlanData: mergedTreatmentPlanData,
                });
            } else {
                const noteRef = doc(collection(firestore, 'customers', auth.currentUser.uid, 'notes'));
                const newNoteData = {
                    title: "Exam Notes",
                    content: result.text,
                    transcript: transcribedNote,
                    patientId: patientId,
                    teethData: mergedTeethData,
                    treatmentPlanData: mergedTreatmentPlanData,
                    timestamp: new Date()
                };
                // // console.log("Creating new note with data:", newNoteData);
                await setDoc(noteRef, newNoteData);
                globalNoteRef.current = noteRef;
                // // console.log("Created new note with ID:", noteRef.id);
            }
            return result.text;
        } catch (error) {
            // console.error("Error in fetchAssistantResponse:", error);
            setAssistantResponse('Error fetching response.');
            alert("Sorry, the artificial intelligence seemed to be on its own coffee break. Please try to generate your note again.");
            throw error;
        }
    };
    
    const callGenerateNoteFunction = async (prompt, mergedTeethData, mergedTreatmentPlanData) => {
        try {
            const idToken = await auth.currentUser.getIdToken();

            const response = await fetch('https://us-central1-digitaltco-c40e9.cloudfunctions.net/thePlatypus', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${idToken}`,
                },
                body: JSON.stringify({ prompt }),
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const responseData = await response.json();
    
            if (globalNoteRef.current) {
                try {
                    await updateDoc(globalNoteRef.current, {
                        jsonContent: responseData.text,
                        teethData: mergedTeethData,
                        treatmentPlanData: mergedTreatmentPlanData,
                    });
                } catch (error) {
                    // console.error("Error updating document: ", error);
                }
            } else {
                const noteRef = doc(collection(firestore, 'customers', auth.currentUser.uid, 'notes'));
                const newNoteData = {
                    title: "Exam Notes",
                    jsonContent: responseData.text,
                    transcript: transcribedNote,
                    patientId: patientId,
                    teethData: mergedTeethData,
                    treatmentPlanData: mergedTreatmentPlanData,
                    timestamp: new Date()
                };
                // // console.log("Creating new note with data:", newNoteData);
                await setDoc(noteRef, newNoteData);
                globalNoteRef.current = noteRef;
                // // console.log("Created new note with ID:", noteRef.id);
            }
    
            return responseData.text;
        } catch (error) {
            // console.error('Error generating note:', error);
            alert("Sorry, the artificial intelligence seemed to be on its own coffee break. Please try to generate your note again.");
        }
    };

    const generateDiscussion = async (cleanedTranscript) => {
        const prompt = `Generate discussion based on the following input: ${cleanedTranscript}. YOU MUST REPLY IN ${languagePreference} LANGUAGE. The tooth notation system used is ${chartingMethod}.`;
        try {
            const idToken = await auth.currentUser.getIdToken();

            const response = await fetch(`https://us-central1-digitaltco-c40e9.cloudfunctions.net/thePlatypusParseDiscussion`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${idToken}`,
                },
                body: JSON.stringify({ prompt })
            });
            
            const result = await response.json();
            return result.text;
        } catch (error) {
            // console.error("Failed to generate discussion:", error);
            return "";  // Return a default or error-specific response if necessary
        }
    };

    const generateTreatmentPlan = async (cleanedTranscript) => {
        const prompt = `Generate a detailed treatment plan based on the following input: ${cleanedTranscript}. YOU MUST REPLY IN ${languagePreference} LANGUAGE. The tooth notation system used is ${chartingMethod}.`;
        try {
            const idToken = await auth.currentUser.getIdToken();
    
            const response = await fetch(`https://us-central1-digitaltco-c40e9.cloudfunctions.net/parseTxplan`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${idToken}`,
                },
                body: JSON.stringify({ prompt })
            });
            
            const result = await response.json();
            return result.text;
        } catch (error) {
            console.error("Failed to generate treatment plan:", error);
            return "";  // Return a default or error-specific response if necessary
        }
    };
    
        
    const handleButtonClick = async () => {
        if (transcribedNote.trim() === '') {
            return;
        }
    
        setIsFetching(true);
        setShowLoadingPopup(true);
    
        try {
            const dentalChartingSection = extractDentalChartingSection(transcribedNote);
            const treatmentPlanSection = extractTreatmentPlanSection(transcribedNote);
    
            let teethData = [];
            let mergedTeethData = [];
            let treatmentPlanData = [];
            let mergedTreatmentPlanData = [];
    
            if (dentalChartingSection) {
                teethData = await parseDentalCharting(dentalChartingSection);
                mergedTeethData = mergeTeethDataWithDefaults(teethData);
                setTeethData(mergedTeethData);
            }
    
            if (treatmentPlanSection) {
                treatmentPlanData = await parseDentalCharting(treatmentPlanSection);
                mergedTreatmentPlanData = mergeTreatmentPlanDataWithDefaults(treatmentPlanData);
                setTreatmentplanData(mergedTreatmentPlanData);
            }
    
            let cleanedTranscript;
            if (dentalChartingSection || treatmentPlanSection) {
                cleanedTranscript = removeSectionsFromTranscript(transcribedNote);
            } else {
                cleanedTranscript = transcribedNote;
            }
    
            let plainTextResponse = "";
    
            if (!selectedExamTemplate) {
                // Construct the prompt to include the language and dentist's name
                const prompt = `YOU MUST REPLY IN ${languagePreference} LANGUAGE.
                                Dentist's Name: ${dentistName}.
                                Dentist's Notes: ${cleanedTranscript}.
                                Nurse's Input: ${nursesInput}.
                                (The tooth notation system used is ${chartingMethod})`;
                
                // Fetch the assistant response using the constructed prompt
                const assistantResp = await fetchAssistantResponse(prompt, mergedTeethData, mergedTreatmentPlanData);
                
                // Set the response as plain text
                plainTextResponse = assistantResp;
                setAssistantResponse(plainTextResponse);
            } else {
                const prompt = `YOU MUST REPLY IN ${languagePreference} LANGUAGE.
                                Dentist's Name: ${dentistName}.
                                Exam Note Template: ${selectedExamTemplate.text}
                                Nurse's Input: ${nursesInput}.
                                Dentist's Notes: ${cleanedTranscript}.
                                (The tooth notation system used is ${chartingMethod})
                                (For your reference AI - For the items I have not mentioned an item in the template, leave them as they are, as the patient is healthy in these areas.)`;
    
                const generatedResp = await callGenerateNoteFunction(prompt, mergedTeethData, mergedTreatmentPlanData);
    
                let jsonObject;
                try {
                    jsonObject = JSON.parse(generatedResp.text || generatedResp);
                } catch (parseError) {
                    throw parseError;
                }
    
                if (isMeatyDiscussion) {
                    try {
                        // Initiate both async operations concurrently
                        const [discussionText, treatmentPlanText] = await Promise.all([
                            generateDiscussion(cleanedTranscript),
                            generateTreatmentPlan(cleanedTranscript)
                        ]);
                
                        // Update the discussion section in the JSON object recursively
                        const discussionUpdated = updateDiscussionInJson(jsonObject, discussionText);
                
                        // If no discussion section was found and updated, append it at the end
                        if (!discussionUpdated) {
                            jsonObject.Discussion = discussionText;
                        }
                
                        // Update the treatment plan section in the JSON object recursively
                        const treatmentPlanUpdated = updateSectionInJson(jsonObject, 'Treatment Plan', treatmentPlanText);
                
                        // If no treatment plan section was found and updated, append it at the end
                        if (!treatmentPlanUpdated) {
                            jsonObject['Treatment Plan'] = treatmentPlanText;
                        }
                
                    } catch (error) {
                        console.error("Error generating discussion or treatment plan:", error);
                        // Optionally, handle the error (e.g., show a notification to the user)
                    }
                }
                
    
                // Convert updated JSON object to plain text
                plainTextResponse = jsonToPlainText(jsonObject);

                // console.log("audio id: ", currentAudioRecordId);
                const audioRecordIdText = currentAudioRecordId 
                ? `\n\nDigitalTCO Audio Recording ID: ${currentAudioRecordId}` 
                : '';

                const finalNote = (plainTextResponse + audioRecordIdText);

                // Update Firestore with the plain text response
                if (globalNoteRef.current) {
                    await updateDoc(globalNoteRef.current, {
                        content: finalNote,
                    });
                }
    
                
                
                // Append the formatted response
                setGeneratedNote(finalNote);
                setAssistantResponse(finalNote);
            }
        } catch (error) {
            console.error("Error during assistant response handling:", error);
        } finally {
            setIsFetching(false);
            setShowLoadingPopup(false);
        }
    };
    
    
    
    const updateDiscussionInJson = (obj, newDiscussion) => {
        let discussionUpdated = false;
    
        for (const key in obj) {
            if (typeof obj[key] === 'object' && obj[key] !== null) {
                // Recursively check nested objects
                discussionUpdated = updateDiscussionInJson(obj[key], newDiscussion) || discussionUpdated;
            } else if (key.toLowerCase() === 'discussion' && typeof obj[key] === 'string') {
                obj[key] = newDiscussion;
                discussionUpdated = true;
            }
        }
    
        return discussionUpdated;
    };

    const updateSectionInJson = (obj, sectionName, newText) => {
        let sectionUpdated = false;
    
        for (const key in obj) {
            if (typeof obj[key] === 'object' && obj[key] !== null) {
                // Recursively check nested objects
                sectionUpdated = updateSectionInJson(obj[key], sectionName, newText) || sectionUpdated;
            } else if (key.toLowerCase() === sectionName.toLowerCase() && typeof obj[key] === 'string') {
                obj[key] = newText;
                sectionUpdated = true;
            }
        }
    
        return sectionUpdated;
    };
    
    
    
    const jsonToPlainText = (jsonResponse) => {
        let plainText = '';
    
        const processObject = (obj) => {
            for (const [key, value] of Object.entries(obj)) {
                if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
                    // Add a line break before sections if not already present
                    if (plainText.length > 0 && plainText.slice(-1) !== '\n') {
                        plainText += '\n';
                    }
    
                    // Add key and recursively process nested object
                    plainText += `${key}:\n`;
                    processObject(value);
                    plainText += '\n'; // Add a line break after processing each object
                } else if (Array.isArray(value)) {
                    // Handle arrays specifically
                    if (plainText.length > 0 && plainText.slice(-1) !== '\n') {
                        plainText += '\n';
                    }
    
                    plainText += `${key}:\n`;
                    value.forEach((item, index) => {
                        plainText += `  ${index}: ${item}\n`;
                    });
                    plainText += '\n'; // Add a line break after arrays
                } else {
                    // Add key-value pair
                    plainText += `${key}: ${value}\n`;
                }
            }
        };
    
        processObject(jsonResponse);
        return plainText.trim(); // Trim any trailing newlines
    };
               
    

    const fileInputRef = useRef(null);

    const handleFileInputClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

     // 3. **Set globalNoteRef.current to the DocumentReference in the useEffect that listens to the note**
  useEffect(() => {
    if (noteId) {
      const uid = auth.currentUser.uid;
      const noteRef = doc(firestore, 'customers', uid, 'notes', noteId);
      const unsubscribe = onSnapshot(noteRef, (doc) => {
        if (doc.exists()) {
          const noteData = doc.data();
          setTranscribedNote(noteData.transcript || '');
          if (isUpdatingFromFirestoreRef.current) {
            isUpdatingFromFirestoreRef.current = false; // Reset the flag
          } else {
            // Only update assistantResponse if the content has changed
            if (noteData.content !== assistantResponseRef.current) {
              setAssistantResponse(noteData.content || '');
            }
          }
          setPatientId(noteData.patientId || '');
          setTeethData(noteData.teethData || []);
          setTreatmentplanData(noteData.treatmentPlanData || []);
          globalNoteRef.current = noteRef; // Set to the actual DocumentReference
        }
      });
      return () => unsubscribe();
    }
  }, [noteId]);

    const hasRepetitiveWords = (transcript) => {
        const words = transcript.split(/\s+/);
        let repeatCount = 1;
        for (let i = 1; i < words.length; i++) {
            if (words[i] === words[i - 1]) {
                repeatCount++;
                if (repeatCount >= 4) { // Adjust the threshold as needed
                    return true;
                }
            } else {
                repeatCount = 1;
            }
        }
        return false;
    };

    const handleAudioEdited = async (editedAudioBlob, getDownloadURL) => {
        const userUID = auth.currentUser ? auth.currentUser.uid : 'anonymous';
        const filePath = `sdcm/${userUID}/${Date.now()}_edited.webm`;
        const storageRef = ref(getStorage(), filePath);
        await uploadBytes(storageRef, editedAudioBlob);
    
        const downloadURL = await getDownloadURL(storageRef);
    
        // Reprocess the audio
        sendAudioToCloudFunction(filePath, downloadURL);
    
        // Hide the audio editor
        setIsAudioEditorVisible(false);
    };  

    const resetComponent = () => {
        // console.log('Reset function called');
      
        // Reset all state variables except selectedExamTemplate and isMeatyDiscussion
        setAssistantResponse('');
        setIsFetching(false);
        setCopySuccess('');
        setIsInfoVisible(true);
        setShowLoadingPopup(false);
        setIsRecording(false);
        setAudioData(null);
        setMediaRecorder(null);
        setAudioChunks([]);
        setTranscribedNote('');
        setCanStopRecording(false);
        setPatientId('');
        setDownloadUrl('');
        setShowLoadingPopup2(false);
        setNursesInput('');
        // setSearchInput('');
        setFilteredTemplates([]);
        // setSelectedExamTemplate(null); // Do not reset the template
        setFocusedItemIndex(-1);
        setGeneratedNote('');
        setIsPastExamModalOpen(false);
        setMediaStream(null);
        setIsPaused(false);
        setUseMegaFunction(false);
        setTeethData([]); // Or defaultTeethData if that's your initial state
        setTreatmentplanData([]);
        setViewTreatmentPlan(false);
        setOriginalTranscribedNote('');
        setIsAppending(false);
        setShowChart(false);
        // setIsMeatyDiscussion(true); // Remove this line to preserve the state
        setIsAudioErrorModalVisible(false);
        setAnalyserKey(0);
        setIsSilenceErrorModalVisible(false);
        setIsAudioEditorVisible(false);
        setShowEditAudioButton(false);
        setCurrentAudioRecordId(null);
      
        // Reset refs
        globalNoteRef.current = null;
        audioDetectedRef.current = false;
      
        // console.log('State reset initiated');
    };
          
      const handlePatientReccosClick = async () => {
        if (!transcribedNote.trim()) {
            alert('Transcript is empty. Please record or enter a transcript first.');
            return;
        }
    
        try {
            setIsFetching(true);
            setShowLoadingPopup(true);
            const idToken = await auth.currentUser.getIdToken();
            const response = await fetch('https://us-central1-digitaltco-c40e9.cloudfunctions.net/patientreccos', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${idToken}`,
                },
                body: JSON.stringify({ prompt: transcribedNote }),
            });
    
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
    
            const result = await response.json();
            setPatientReccosResponse(result.text);
            setIsPatientReccosModalOpen(true);
        } catch (error) {
            console.error('Error fetching patient recommendations:', error);
            alert('Failed to fetch patient recommendations. Please try again.');
        } finally {
            setIsFetching(false);
            setShowLoadingPopup(false); 
        }
    };
    
      

    return (
        <main className="flex flex-col items-center tracking-tight w-4/5 mx-auto">
            <AudioErrorModal 
            isVisible={isAudioErrorModalVisible} 
            onClose={() => setIsAudioErrorModalVisible(false)} 
            />
            <SilenceErrorModal 
                isVisible={isSilenceErrorModalVisible} 
                onClose={() => setIsSilenceErrorModalVisible(false)} 
                onEditAudio={() => {
                    setIsAudioEditorVisible(true);
                    setIsAudioErrorModalVisible(false);
                }}
            />

            {isAudioEditorVisible && (
                <AudioEditor
                    audioUrl={downloadUrl}
                    onSave={handleAudioEdited}
                    onCancel={() => setIsAudioEditorVisible(false)}
                />
            )}

            <Tooltip
                id="discussionTip"
                place="top"
                effect="solid"
                content="Select the type of discussion you want to generate."
                style={{
                    backgroundColor: '#cd66ef', // Use your primary color with opacity
                    color: '#fff',
                }}
            />
            <Tooltip 
                id="featherTip" 
                place="top" 
                effect="solid" 
                content="Generates a concise, straightforward discussion." 
                style={{
                    backgroundColor: '#cd66ef', // Use your primary color with opacity
                    color: '#fff',
                }} 
            />
            <Tooltip 
                id="meatyTip" 
                place="top" 
                effect="solid" 
                content="Generates a detailed, comprehensive discussion." 
                style={{
                    backgroundColor: '#cd66ef', // Use your primary color with opacity
                    color: '#fff',
                }} 
            />
            <Tooltip 
                id="templateTip" 
                place="top" 
                effect="solid" 
                content="The template must be filled in, as if the patient is in perfect health. No 'fill in the blanks' or 'multiple choice'. If adjustments are needed, use the Template Fixer in the ‘Customise’ section. For a deeper understanding, watch video #4 in the Knowledge Vault—it covers the key concept behind DigitalTCO." 
                style={{
                    backgroundColor: '#cd66ef', // Use your primary color with opacity
                    color: '#fff',
                    maxWidth: '40%',
                    zIndex: 9999
                }} 
            />

            {showLoadingPopup && <LoadingPopup />}
            {showLoadingPopup2 && <LoadingPopup2 />}
            <PastExamModal
                isOpen={isPastExamModalOpen}
                onClose={handleClosePastExamModal}
                onUsePastExam={handleUsePastExam}
            />



            <div className="flex items-center w-[65%] mx-auto justify-between"> {/* Adjusted justify-between for better spacing */}
                <div ref={onboarding10Ref}>
                    <ResetButton onReset={resetComponent} />
                </div>
                <div className="flex-1"> {/* Added flex-1 to ensure inputs take up the available space */}
                    <input
                        ref={onboarding1Ref}
                        type="text"
                        id="patientId"
                        value={patientId}
                        onChange={handlePatientIdChange}
                        placeholder="Patient ID"
                        className="block w-full p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 text-xs focus:ring-blue-500 focus:border-blue-500"
                    />
                </div>
                <div ref={onboarding2Ref} className="flex-1 ml-4 relative"> {/* Adjusted flex-1 and margin for balanced spacing */}
                    <input
                        ref={searchInputRef}
                        onKeyDown={handleKeyDown}
                        type="text"
                        id="templatesearch"
                        placeholder="Pick Your Exam Template..."
                        value={searchInput}
                        onChange={(e) => handleSearchInput(e.target.value)}
                        onClick={() => {
                            if (searchInput) {
                                setSearchInput('');
                                setSelectedExamTemplate(null);
                            }
                        }}
                        autoComplete="off"
                        className={`block w-full p-2 text-gray-900 border border-gray-300 rounded-lg text-xs focus:ring-blue-500 focus:border-blue-500 ${
                            selectedExamTemplate
                                ? 'bg-gray-200 cursor-pointer hover:bg-gray-300'
                                : 'bg-gray-50'
                        } transition-colors duration-200`}
                    />
                    {filteredTemplates.length > 0 && (
                        <div className="absolute z-50 bg-white divide-y divide-gray-100 rounded-lg shadow w-44">
                            <ul className="py-2 text-sm text-gray-700">
                                {filteredTemplates.map((templateKey, index) => (
                                    <li
                                        key={templateKey}
                                        onClick={() => localHandleSelectTemplate(templateKey)}
                                        className={
                                            focusedItemIndex === index
                                                ? "block px-4 py-2 bg-gray-50"
                                                : "block px-4 py-2 hover:cursor-pointer hover:bg-gray-50"
                                        }
                                    >
                                        {examTemplates[templateKey].label}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                </div>

                <div ref={onboarding11Ref} className="flex items-center gap-4 pt-1.5 ml-4"> {/* Added gap and margin to create spacing */}
                    <button
                        onClick={handleOpenPastExamModal}
                        className="inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-xs font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-primary-500 to-pink-500 group-hover:from-purple-500 group-hover:to-pink-500 hover:text-white focus:ring-4 focus:outline-none focus:ring-purple-200"
                    >
                        <span className="relative px-5 py-1.5 transition-all ease-in duration-75 bg-white rounded-md group-hover:bg-opacity-0">
                            Import Previous Exam
                        </span>
                    </button>
                    <button
                        onClick={toggleChartVisibility}
                        className="text-white bg-gradient-to-r from-purple-500 to-pink-500 hover:bg-gradient-to-l focus:ring-4 focus:outline-none focus:ring-purple-200 font-medium rounded-lg text-sm px-5 py-1.5 text-center me-2 mb-2"
                    >
                        <FontAwesomeIcon icon={showChart ? faEyeSlash : faEye} className="mr-2" />
                        {showChart ? 'Chart' : 'Chart'}
                    </button>
                    
                    <div className="flex items-center mb-2">
                    <input
                        type="checkbox"
                        id="saveAudioRecordings"
                        checked={saveAudioRecordings}
                        onChange={handleSaveAudioRecordingsToggle}
                        className="h-4 w-4 text-primary-600 accent-primary-400 focus:ring-primary-500 border-gray-300 rounded"
                    />
                    <label htmlFor="saveAudioRecordings" className="ml-2 block text-xs text-gray-900">
                        Save Audio
                    </label>
                    </div>
                
                </div>
                </div>


            {/* Conditional Rendering of Dental Chart with Assistant Response Check */}
            {showChart && (
                <>
                <div className="flex items-center w-full mx-auto justify-center relative mt-4">
                    <div className="relative p-2">
                        <DentalChart
                        data={viewTreatmentPlan ? treatmentplanData : teethData}
                        noteId={globalNoteRef.current ? globalNoteRef.current.id : null}
                        isTreatmentPlan={viewTreatmentPlan}
                        setTeethData={setTeethData}
                        setTreatmentplanData={setTreatmentplanData}
                        />
                        {!assistantResponse && (
                        <div className="absolute inset-0 bg-gray-100 bg-opacity-70 flex justify-center items-center z-10 rounded-lg cursor-not-allowed">
                            <p className="text-xl text-gray-500"></p>
                        </div>
                        )}
                    </div>
                </div>
                <div className="flex justify-center mt-4">
                    <label className="inline-flex items-center me-5 cursor-pointer">
                            <input 
                                type="checkbox" 
                                className="sr-only peer" 
                                checked={viewTreatmentPlan} 
                                onChange={toggleChartView}
                            />
                            <div className={`relative w-11 h-6 rounded-full peer peer-focus:ring-4 peer-focus:ring-primary-200 after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all ${viewTreatmentPlan ? 'bg-malachite-400 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white peer-checked:bg-primary-400' : 'bg-primary-400 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white peer-checked:bg-malachite-400'}`}></div>
                            <span className="ms-3 text-xs font-medium tracking-tighter text-gray-900">{viewTreatmentPlan ? "Treatment Plan" : "Chart"}</span>
                    </label>
                </div>
            </>
            )}

            <div className="mt-8 grid grid-cols-1 gap-6 sm:grid-cols-2 w-full">
                <div className="flex flex-col">
                    {selectedExamTemplate && (
                        <div className="mb-4">
                            <label className="block mb-2 text-xs text-primary-400">Selected Template</label>
                            <textarea
                                value={selectedExamTemplate.text}
                                readOnly
                                className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300"
                                rows="20"
                                data-tooltip-id="templateTip"
                            />
                        </div>
                    )}
                    {showChart && (
                    <>
                    <label className="block mb-2 text-xs text-white"> .</label>
                    <div className="block p-2.5 w-full text-sm text-primary-900 bg-white rounded-lg border border-gray-300 tracking-tighter text-sm flex flex-col justify-center items-center">
                        <div className="grid grid-cols-2 gap-4">
                            <div>
                            <p>To record charting:</p>
                            <ul className="list-disc list-inside ml-4">
                                <li>Say "start dental chart"</li>
                                <li>Chart each tooth</li>
                                <li>Say "stop dental chart"</li>
                            </ul>
                            </div>
                            <div>
                            <p>To record treatment plan:</p>
                            <ul className="list-disc list-inside ml-4">
                                <li>Say "start treatment chart"</li>
                                <li>Chart each tooth</li>
                                <li>Say "stop treatment chart"</li>
                            </ul>
                            </div>
                        </div>
                        <p className="mt-4">
                            Keep it efficient. Chart tooth by tooth, minimal fluff. Explanations can be given before or after charting.
                        </p>
                    </div>
                    </>
                    )}

                    <label className="mt-4 block mb-2 text-xs text-primary-400">Nurse's notes</label>
                    <textarea
                        ref={onboarding3Ref}
                        placeholder="Enter nurse's observations and notes here..."
                        value={nursesInput}
                        onChange={handleNursesInputChange}
                        className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300"
                        rows="5"
                    ></textarea>
                    <label className="mt-4 block mb-2 text-xs text-primary-400">Dentist's observations and notes</label>
                    <textarea
                        ref={onboarding6Ref}
                        placeholder="Connect your lapel mic and record the entire appointment..."
                        value={transcribedNote}
                        onChange={(e) => setTranscribedNote(e.target.value)}
                        readOnly={isRecording || transcribedNote === "Loading..."}
                        className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300"
                        rows="15"
                    />
                    
                    {mediaStream && (
                        <div className="active-audio-analyser-container">
                            <ActiveAudioAnalyser
                                key={analyserKey}
                                audioStream={mediaStream}
                                isPaused={isPaused}
                                onAudioDetected={() => {
                                    audioDetectedRef.current = true;
                                }}
                            />
                        </div>
                    )}


                    <div className="mx-auto mt-2">
                        <div className="flex items-center">
                            {/* First Button Group: Recording Controls */}
                            <div ref={onboarding5Ref} className="inline-flex rounded-md shadow-sm" role="group">
                                <button 
                                    onClick={startRecording} 
                                    disabled={isRecording || isPaused || transcribedNote.trim().length > 0}
                                    className={`${isRecording || isPaused || transcribedNote.trim().length > 0 ? 'px-4 py-2 text-xl font-medium text-gray-500 bg-gray-300 border border-gray-200 rounded-l-lg cursor-not-allowed' : 'px-4 py-2 text-xl font-medium text-primary-400 bg-white border border-gray-200 rounded-l-lg hover:bg-primary-400 hover:text-white'}`}
                                >
                                    <FontAwesomeIcon icon={faMicrophone} />
                                </button>
                                {isRecording && (
                                    <button 
                                        onClick={isPaused ? resumeRecording : pauseRecording}
                                        className="px-4 py-2 text-xl font-medium text-primary-400 bg-white border-t border-b border-gray-200 hover:bg-primary-400 hover:text-white"
                                    >
                                        {isPaused ? <FontAwesomeIcon icon={faPlay} /> : <FontAwesomeIcon icon={faPause} />}
                                    </button>
                                )}
                                <button 
                                    onClick={stopRecording} 
                                    disabled={!isRecording || isPaused}
                                    className={`${!isRecording || isPaused ? 'px-4 py-2 text-xl font-medium text-gray-500 bg-gray-300 border-t border-b border-gray-200 cursor-not-allowed' : 'px-4 py-2 text-xl font-medium text-primary-400 bg-white border-t border-b border-gray-200 hover:bg-primary-400 hover:text-white'}`}
                                >
                                    <FontAwesomeIcon icon={faStop} />
                                </button>
                                <button
                                    onClick={startRecordingAppendage}
                                    disabled={isRecording || isPaused || !transcribedNote.trim()}
                                    className={`${isRecording || isPaused || !transcribedNote.trim() ? 'px-4 py-2 text-xl font-medium text-gray-500 bg-gray-300 border border-gray-200 rounded-r-lg cursor-not-allowed' : 'px-4 py-2 text-xl font-medium text-primary-400 bg-white border border-gray-200 rounded-r-lg hover:bg-primary-400 hover:text-white cursor-pointer'}`}
                                >
                                    <FontAwesomeIcon icon={faPlus} />
                                </button>
                            </div>

                            {/* Small Margin Between Groups */}
                            <div className="mx-2"></div>

                            {/* Second Button Group: Discussion Selection and Generate Note */}
                            <div ref={onboarding4Ref} className="inline-flex rounded-md shadow-sm" role="group">
                                {/* Label for Discussion Type */}
                                <span 
                                data-tooltip-id="discussionTip"                             
                                className="flex items-center px-4 py-2 bg-white border border-gray-200 text-xs rounded-l-lg font-medium text-gray-400">
                                    Discussion
                                </span>

                                {/* Segmented Control for Simple and Meaty */}
                                <button
                                    onClick={() => setIsMeatyDiscussion(false)}
                                    data-tooltip-id="featherTip"         
                                    className={`${!isMeatyDiscussion ? 'bg-malachite-400 text-white' : 'bg-white text-primary-400 hover:bg-gray-100'} text-xl font-medium px-4 py-2 border border-gray-200`}
                                >
                                    <FontAwesomeIcon icon={faFeather} />
                                </button>
                                {/* Meaty Button with Fire Animation */}
                                <div className="relative">
                                    <button
                                        onClick={() => setIsMeatyDiscussion(true)}
                                        data-tooltip-id="meatyTip"
                                        className={`${isMeatyDiscussion ? 'bg-gradient-to-b from-yellow-400 to-red-500 text-white' : 'bg-white text-primary-400 hover:bg-gray-100 rounded-r-lg'} text-xl font-medium px-4 py-2 border border-gray-200 rounded-r-lg relative z-10`}
                                    >
                                        <FontAwesomeIcon icon={faFire} />
                                    </button>
                                    {/* Fire Animation SVG */}
                                    {isMeatyDiscussion && (
                                        <div className="absolute -top-[11rem] left-[28%] transform -translate-x-1/2 z-0">
                                            <svg
                                                viewBox="0 0 200 200"
                                                width="180"
                                                height="180"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <defs>
                                                    <filter id="turb" x="-100%" y="-100%" width="300%" height="300%">
                                                        <feTurbulence
                                                            type="turbulence"
                                                            baseFrequency="0.06"
                                                            numOctaves="2.5"
                                                            result="turbulence"
                                                            seed="69"
                                                        />
                                                        <feDisplacementMap
                                                            in2="turbulence"
                                                            in="SourceGraphic"
                                                            scale="35"
                                                        />
                                                    </filter>
                                                    <radialGradient id="grad" cx="50%" cy="100%">
                                                        <stop offset="0%" stopColor="blue" />
                                                        <stop offset="20%" stopColor="gold" />
                                                        <stop offset="60%" stopColor="gold" />
                                                        <stop offset="100%" stopColor="red" />
                                                    </radialGradient>
                                                </defs>
                                                <g>
                                                    {/* Opacity Animation to fade out after 10s */}
                                                    <animate
                                                        attributeName="opacity"
                                                        from="1"
                                                        to="0"
                                                        begin="2s"
                                                        dur="1s"
                                                        fill="freeze"
                                                    />
                                                    <path
                                                        d="M70 200 h60 l-30 -90z"
                                                        filter="url(#turb)"
                                                        fill="url(#grad)"
                                                    >
                                                        <animate
                                                            attributeName="d"
                                                            values="M70 200 h60 l-30 -90z; M70 7200 h60 l-30 -90z"
                                                            dur="100s"
                                                            begin="0s"
                                                            repeatCount="indefinite"
                                                        />
                                                    </path>
                                                    <animateTransform
                                                        attributeName="transform"
                                                        attributeType="XML"
                                                        type="translate"
                                                        values="0 0; 0 -7000"
                                                        dur="100s"
                                                        begin="0s"
                                                        repeatCount="indefinite"
                                                    />
                                                </g>
                                            </svg>
                                        </div>
                                    )}
                                </div>

                            </div>
                            <div className="inline-flex rounded-md shadow-sm ml-2" role="group">

                                {/* Generate Note Button */}
                                <button
                                    ref={onboarding7Ref}
                                    onClick={handleButtonClick}
                                    disabled={isFetching || isRecording || isPaused}
                                    className={`${isFetching || isRecording || isPaused ? 'px-4 py-2 text-xl font-medium text-gray-500 bg-gray-300 border border-gray-200 rounded-lg cursor-not-allowed' : 'px-4 py-2 text-xl font-medium text-primary-400 bg-white border border-gray-200 rounded-lg hover:bg-malachite-500 hover:text-white'}`}
                                >
                                    <FontAwesomeIcon icon={faPaperPlane} />
                                </button>
                            </div>
                        </div>
                    </div>


                    <div className="mx-auto mt-2">
                        <div className="inline-flex rounded-md shadow-sm" role="group">
                            <button
                                onClick={handlePatientReccosClick}
                                disabled={!transcribedNote.trim() || isFetching}
                                className={`mr-2 px-4 py-2 text-xs text-primary-400 bg-white border border-gray-200 rounded-lg hover:bg-primary-400 hover:text-white ${
                                    (!transcribedNote.trim() || isFetching) && 'opacity-50 cursor-not-allowed'
                                }`}
                            >
                                Get Patient Recommendations
                            </button>

                            <button 
                                type="button" 
                                onClick={handleFileInputClick}
                                className="px-4 py-2 text-xs text-primary-400 bg-white border border-gray-200 rounded-s-lg hover:bg-primary-400 hover:text-white"
                            >
                                Upload Audio (webm)
                            </button>
                            <input
                                type="file"
                                id="fileInput"
                                ref={fileInputRef}
                                onChange={handleFileChange}
                                accept=".webm"
                                style={{ display: 'none' }}
                            />

                            <button 
                                disabled={!downloadUrl}
                                onClick={handleCheckAudioQuality}
                                className={`${!downloadUrl ? 'px-4 py-2 text-xs text-gray-500 bg-gray-300 rounded-e-lg border border-gray-200 cursor-not-allowed' : 'px-4 py-2 text-xs text-primary-400 bg-white border border-gray-200 rounded-e-lg hover:bg-primary-400 hover:text-white'}`}
                            >
                                Check Audio Quality
                            </button>
                        </div> 
                    </div>
                </div>
                <div className="flex flex-col">
                    <label className="block mb-2 text-xs text-primary-400">AI Output</label>
                    <textarea
                        ref={onboarding8Ref}
                        value={removeDoubleAsterisks(assistantResponse)}
                        placeholder="Notes will show here..."
                        onChange={handleAssistantResponseChange}
                        className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300"
                        rows="25"
                    />
                    <div ref={onboarding9Ref} className="flex flex-col items-center justify-center mx-auto mt-2">
                        <CopyButton textToCopy={removeDoubleAsterisks(assistantResponse)} />
                        {selectedExamTemplate?.text && (
                        <ShareTemplateButton templateText={selectedExamTemplate?.text} selectedTemplate={selectedExamTemplate} />
                        )}
                        <div className="mx-auto mt-2 flex">
                            {assistantResponse && (
                                <PatientLetters noteContent={removeDoubleAsterisks(assistantResponse)} />
                            )}
                            {containsReferral && (
                                <ReferralLetters noteContent={removeDoubleAsterisks(assistantResponse)} />
                            )}
                            {assistantResponse && (
                                <ReferralResponseLetter noteContent={removeDoubleAsterisks(assistantResponse)} />
                            )}
                            <div className="ml-2">
                            {assistantResponse && (
                                <CreateMolarMailButton
                                    className="px-4 py-2 text-xs text-primary-400 bg-white border border-gray-200 rounded-lg hover:bg-primary-400 hover:text-white"
                                    clinicalRecordText={removeDoubleAsterisks(assistantResponse)}
                                    teethData={teethData}
                                    treatmentPlanData={treatmentplanData}
                                />
                            
                            )}
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>

            {isUpdateModalVisible && (
            <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
                <div className="bg-white rounded-lg p-6 w-4/5 max-w-lg">
                <h2 className="text-xl font-bold mb-4">Critical Update</h2>
                <p className="mb-4">Ensure these essential details are in your template for optimal effectiveness:</p>
                <p className="mb-2 font-mono bg-gray-100 p-2 rounded">Discussion: Nil</p>
                <p className="mb-2 font-mono bg-gray-100 p-2 rounded">Treatment Plan: Nil</p>
                <p className="mb-4">
                    Including these elements ensures your discussion and treatment plan are comprehensive and seamless.</p>
                    <p className="mb-4">
                    Adding them is simple: navigate to <strong>Customise &gt; Your Templates &gt; Exams</strong> and select your exam template.
                </p>
                <p className="mb-4">
                    These two lines, combined with the flame discussion type, will elevate your real-time note-taking efficiency and professionalism.
                </p>
                <div className="flex justify-end">
                    <button
                    onClick={handleUpdateModalOk}
                    className="bg-primary-500 text-white px-4 py-2 rounded mr-2"
                    >
                    OK
                    </button>
                    <button
                    onClick={handleDoNotShowAgain}
                    className="bg-gray-500 text-white px-4 py-2 rounded"
                    >
                    Do not show again
                    </button>
                </div>
                </div>
            </div>
            )}


            <PatientReccosModal
                isOpen={isPatientReccosModalOpen}
                onClose={() => setIsPatientReccosModalOpen(false)}
                responseText={patientReccosResponse}
            />

            <OnboardingJoyrideWrapper
            stepKey="step6"
            stepsDefinition={stepsDefinition}
            refs={onboardingRefs}
            onboardingStepKey={onboardingStepKey}
            setOnboardingStepKey={setOnboardingStepKey}
            userData={userData}
            openSidebar={openSidebar}
            />        

        </main>
    );
};

export default TreatmentPlanner;