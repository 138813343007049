// ExamsAndConsultTemplates.js

import React, { useState, useEffect, useRef } from 'react';
import {
  doc,
  getDoc,
  setDoc,
  writeBatch,
  collection,
  getDocs,
  deleteDoc
} from 'firebase/firestore';
import { firestore, auth } from '../../firebase';

const ExamsAndConsultTemplates = () => {
  const [selectedTemplate, setSelectedTemplate] = useState('');
  const [templates, setTemplates] = useState({});
  const [searchInput, setSearchInput] = useState('');
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [newTemplateName, setNewTemplateName] = useState('');
  const [newTemplateContent, setNewTemplateContent] = useState('');
  const [isConfirmationVisible, setIsConfirmationVisible] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isFixing, setIsFixing] = useState(false);
  const textareaRef = useRef(null);

  useEffect(() => {
    const loadTemplates = async () => {
      const uid = auth.currentUser.uid;
      const loadedTemplates = {};
  
      // Fetch all template documents from Firestore (for exams)
      const examTemplatesRef = collection(firestore, 'customers', uid, 'exam_templates');
      const querySnapshot = await getDocs(examTemplatesRef);
  
      querySnapshot.forEach((doc) => {
        loadedTemplates[doc.id] = doc.data().text;
      });
  
      // Fetch the "Basic Exam" template from the 'templates' collection
      const basicExamRef = doc(firestore, 'customers', uid, 'templates', 'Basic Exam');
      const basicExamSnap = await getDoc(basicExamRef);
      if (basicExamSnap.exists()) {
        loadedTemplates['Basic Exam'] = basicExamSnap.data().text;
      }
  
      // Sort templates alphabetically by key
      const sortedTemplates = Object.keys(loadedTemplates)
        .sort((a, b) => a.localeCompare(b))
        .reduce((acc, key) => {
          acc[key] = loadedTemplates[key];
          return acc;
        }, {});
  
      setTemplates(sortedTemplates);
    };
  
    loadTemplates();
  }, []);
    

  const treatments = Object.keys(templates);

  const handleTemplateSelection = (treatment) => {
    setSelectedTemplate(treatment);
    setNewTemplateName(treatment);
    setNewTemplateContent(templates[treatment]);
  };

  const handleTextChange = (e) => {
    setNewTemplateContent(e.target.value);
  };

  const togglePopup = () => {
    setIsPopupVisible(!isPopupVisible);
    if (!isPopupVisible) {
      setNewTemplateName('');
      setNewTemplateContent('');
    }
  };

  const saveNewTemplate = async () => {
    const trimmedTemplateName = newTemplateName.trim();
    const trimmedTemplateContent = newTemplateContent.trim();

    if (!trimmedTemplateName || !trimmedTemplateContent) {
      alert('Please enter a valid template name and content.');
      return;
    }

    if (containsPlaceholders(trimmedTemplateContent)) {
      setIsConfirmationVisible(true);
    } else {
      await confirmSaveTemplate(
        trimmedTemplateName,
        trimmedTemplateContent
      );
    }
  };

  const confirmSaveTemplate = async (
    templateName,
    templateContent
  ) => {
    setIsSaving(true);
    const uid = auth.currentUser.uid;

    try {
      const newTemplateRef = doc(
        firestore,
        'customers',
        uid,
        'exam_templates',
        templateName
      );

      await setDoc(newTemplateRef, { text: templateContent });

      setTemplates((prevTemplates) => ({
        ...prevTemplates,
        [templateName]: templateContent
      }));

      alert('Template saved successfully');
      setIsPopupVisible(false);
    } catch (error) {
      console.error('Error saving template:', error);
      alert('Error saving template. Please try again.');
    } finally {
      setIsSaving(false);
    }
  };

  const deleteTemplate = async () => {
    if (!selectedTemplate) {
      alert('Please select a template to delete.');
      return;
    }

    const isConfirmed = window.confirm(
      'Are you sure you want to delete this template?'
    );

    if (isConfirmed) {
      const uid = auth.currentUser.uid;
      const templateRef = doc(
        firestore,
        'customers',
        uid,
        'exam_templates',
        selectedTemplate
      );

      try {
        await deleteDoc(templateRef);

        setTemplates((prevTemplates) => {
          const updatedTemplates = { ...prevTemplates };
          delete updatedTemplates[selectedTemplate];
          return updatedTemplates;
        });

        setSelectedTemplate('');
        alert('Template deleted successfully');
        setIsPopupVisible(false);
      } catch (error) {
        console.error('Error deleting template:', error);
      }
    }
  };

  const containsPlaceholders = (text) => {
    const placeholderPatterns = [
      /\[.*?\]/,
      /_{2,}/,
      /___+/,
      /\.\.\.+/,
      /\([a-zA-Z]\)/,
      /\/\s*/,
      /{{.*?}}/,
      /<<.*?>>/
    ];

    return placeholderPatterns.some((pattern) => pattern.test(text));
  };

  const fixTemplateAutomatically = async () => {
    setIsFixing(true);
    try {
      const response = await fetch(
        'https://us-central1-digitaltco-c40e9.cloudfunctions.net/templateFixer',
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ prompt: newTemplateContent })
        }
      );

      const result = await response.json();

      if (!result.text) {
        throw new Error('No fixed template returned from API');
      }

      const cleanedResult = removeDoubleAsterisks(result.text);
      setNewTemplateContent(cleanedResult);

      await confirmSaveTemplate(
        newTemplateName.trim(),
        cleanedResult.trim()
      );

      setIsConfirmationVisible(false);
    } catch (error) {
      console.error('Error fixing template:', error);
      alert('Error fixing template. Please try again.');
    } finally {
      setIsFixing(false);
    }
  };

  const removeDoubleAsterisks = (text) => {
    let formattedText = text.replace(/\*\*(.*?)\*\*/g, '$1');
    formattedText = formattedText.replace(/##/g, '');
    formattedText = formattedText.replace(/###/g, '');
    formattedText = formattedText.replace(/【\d+†.*?】/g, '');
    return formattedText;
  };

  const filteredTreatments = treatments.filter((treatment) =>
    treatment.toLowerCase().includes(searchInput.toLowerCase())
  );

  return (
    <div className="w-full p-6">
      {/* Confirmation Modal */}
      {isConfirmationVisible && (
        <div className="fixed inset-0 z-[100] bg-black bg-opacity-50 flex items-center justify-center p-4">
          <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-xl">
            <h2 className="text-lg font-bold text-red-600 mb-4">
              Warning
            </h2>
            <p className="mb-4">
              This template includes placeholders or multiple-choice
              options that might not function as expected.
            </p>
            <p className="mb-4 underline">
              <a
                href="https://youtu.be/26nwK2fpF6I"
                target="_blank"
                rel="noopener noreferrer"
              >
                Click here to learn the key concept underpinning
                DigitalTCO
              </a>
            </p>
            <p className="mb-6">
              Would you like us to fix it for you?
            </p>
            <div className="flex justify-end space-x-3">
              <button
                onClick={fixTemplateAutomatically}
                className="px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700"
                disabled={isFixing}
              >
                {isFixing ? 'Fixing...' : 'Fix Template (RECOMMENDED)'}
              </button>
              <button
                onClick={() => {
                  confirmSaveTemplate(
                    newTemplateName.trim(),
                    newTemplateContent.trim()
                  );
                  setIsConfirmationVisible(false);
                }}
                className="px-4 py-2 bg-gray-600 text-white rounded-md hover:bg-gray-700"
                disabled={isSaving}
              >
                {isSaving ? 'Saving...' : 'Save Anyway'}
              </button>
              <button
                onClick={() => setIsConfirmationVisible(false)}
                className="px-4 py-2 bg-gray-200 text-gray-700 rounded-md hover:bg-gray-300"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Search and Add Template */}
      <div className="flex items-center justify-between mb-6">
        <input
          type="text"
          autoFocus
          placeholder="Search..."
          value={searchInput}
          onChange={(e) => setSearchInput(e.target.value)}
          className="w-1/2 p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary-600"
        />
        <button
          onClick={togglePopup}
          className="px-4 py-2 bg-gradient-to-br from-primary-400 to-teal-200 text-white rounded-lg hover:bg-primary-700"
        >
          Create New Exam Template
        </button>
      </div>

      {/* Template Selection and Editor */}
      <div className="flex flex-col md:flex-row">
        {/* Template List */}
        <div className="md:w-1/3 md:pr-4 mb-6 md:mb-0">
        <div className="bg-white rounded-lg shadow p-4">
          <h3 className="text-lg font-semibold mb-4">Templates</h3>
          <ul className="space-y-2 max-h-[448px] overflow-y-auto"> {/* Adjust height as needed */}
            {filteredTreatments.map((treatment) => (
              <li key={treatment}>
                <button
                  onClick={() => handleTemplateSelection(treatment)}
                  className={`w-full text-left px-3 py-2 rounded-md ${
                    selectedTemplate === treatment
                      ? 'bg-primary-100 text-primary-700'
                      : 'hover:bg-gray-100'
                  }`}
                >
                  {treatment}
                </button>
              </li>
            ))}
          </ul>
        </div>

        </div>

        {/* Template Editor */}
        <div className="md:w-2/3">
          {selectedTemplate ? (
            <div className="bg-white rounded-lg shadow p-4">
              <h3 className="text-lg font-semibold mb-4">
                Edit Template
              </h3>
              <textarea
                className="w-full h-96 p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary-600"
                value={newTemplateContent}
                onChange={handleTextChange}
                ref={textareaRef}
              ></textarea>
              <div className="flex justify-end space-x-3 mt-4">
                <button
                  onClick={saveNewTemplate}
                  className="cursor-pointer px-4 py-2 bg-gradient-to-br from-primary-400 to-teal-100 text-white rounded-md hover:bg-primary-700"
                  disabled={!newTemplateName.trim() || !newTemplateContent.trim()}
                >
                  Save
                </button>
                <button
                  onClick={deleteTemplate}
                  className="px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700"
                >
                  Delete
                </button>
              </div>
            </div>
          ) : (
            <div className="bg-white rounded-lg shadow p-4">
              <p className="text-gray-500">
                Select a template to edit or create a new one.
              </p>
            </div>
          )}
        </div>
      </div>

      {/* Popup for New Template */}
      {isPopupVisible && (
        <div className="fixed inset-0 z-50 bg-black bg-opacity-50 flex items-center justify-center p-4">
          <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-2xl max-h-[90vh] overflow-y-auto">
            <h3 className="text-lg font-semibold mb-4">
              {selectedTemplate ? 'Edit Template' : 'New Template'}
            </h3>
            <input
              type="text"
              value={newTemplateName}
              onChange={(e) => setNewTemplateName(e.target.value)}
              placeholder="Template Name"
              className="w-full p-2 mb-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary-600"
            />
            <textarea
              value={newTemplateContent}
              onChange={(e) => setNewTemplateContent(e.target.value)}
              placeholder="Template Content"
              className="w-full h-40 p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary-600"
            ></textarea>
            <div className="flex justify-end space-x-3 mt-4">
              <button
                onClick={saveNewTemplate}
                className="px-4 py-2 bg-gradient-to-br from-primary-400 to-teal-100 text-white rounded-md hover:bg-primary-700"
                disabled={!newTemplateName.trim() || !newTemplateContent.trim()}
              >
                Save
              </button>
              <button
                onClick={togglePopup}
                className="px-4 py-2 bg-gray-200 text-gray-700 rounded-md hover:bg-gray-300"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ExamsAndConsultTemplates;
