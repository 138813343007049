// src/components/common/Error.jsx

import React from 'react';
import { Box, Typography } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';

const Error = ({ message }) => (
  <Box sx={{ textAlign: 'center', padding: 4 }}>
    <ErrorIcon color="error" sx={{ fontSize: 60 }} />
    <Typography variant="h6" color="error" gutterBottom>
      {message}
    </Typography>
  </Box>
);

export default Error;
