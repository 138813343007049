// OnboardingSidebar.js
import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import { doc, updateDoc, getDoc, setDoc } from 'firebase/firestore';
import { auth, firestore } from '../../firebase'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronRight,
  faChevronLeft,
  faChevronUp,
  faChevronDown,
  faCheckCircle,
  faCircle,
  faLock, 
} from '@fortawesome/free-solid-svg-icons';
import { OnboardingContext } from './OnboardingContext';
import ConfettiExplosion from '../animations/ConfettiExplosion';

export const steps = [
  {
    title: 'Set Up Your Profile 📝',
    description:
      'Complete your personal and practice information. This is your first step to getting fully integrated.',
    key: 'step1',
    navigateTo: '/mvp/profile',
  },
  {
    title: 'Super Dentist Cheat Mode 💡',
    description:
      'Start speaking, and let the AI take care of the rest. You’ll see how easy it is to generate detailed, professional notes in real-time.',
    key: 'step2',
    navigateTo: '/mvp/super-dentist-cheat-mode',
  },
  {
    title: 'Set Up a Treatment Template 📋',
    description:
      'Import a ready-to-go template and make it yours.',
    key: 'step3',
    navigateTo: '/mvp/settings',
  },
  {
    title: 'Make a Treatment Note 🦷',
    description:
      'Use the treatment template you just imported to create a complete, flawless treatment note.',
    key: 'step4',
    navigateTo: '/mvp/tx-notes/Composite',
  },
  {
    title: 'Learn the key concept 🔧',
    description:
      'Master the foundational idea behind DigitalTCO and why it works. Once you "get" this, everything else will click into place.',
    key: 'step5',
    navigateTo: '/mvp/knowledgevault/video/video4',
  },
  {
    title: 'Make an Exam Note 🩺',
    description:
      'Speak freely while the system organizes your words into structured, compliant notes.',
    key: 'step6',
    navigateTo: '/mvp/treatmentplanner',
  },
  {
    title: 'Add a Spiel 💬',
    description:
      'Save a common phrase or explanation you often use to avoid repetition.',
    key: 'step7',
    navigateTo: '/mvp/risk-bank',
  },
  {
    title: 'Use a Spiel 🗣️',
    description:
      'Now, plug that spiel into a live session and watch how much time you save.',
    key: 'step8',
    navigateTo: '/mvp/super-dentist-cheat-mode',
  },
  {
    title: 'Make a Letter 📨',
    description:
      'Use our seamless letter function to transform your notes into patient-friendly letters.',
    key: 'step10',
    navigateTo: '/mvp/notes-list',
  },
  {
    title: 'Advanced Features ⚙️',
    description:
      'Once you’ve mastered the basics, head over to the Knowledge Vault in the sidebar to learn how voice charting and Molar Mail work. It’ll blow your socks off!',
    key: 'step11',
  },
];

const OnboardingSidebar = ({ isSidebarOpen, handleToggleSidebar }) => { // Accept props
  const navigate = useNavigate();
  const [expandedSteps, setExpandedSteps] = useState({});
  const [userData, setUserData] = useState({});
  const [onboardingProgress, setOnboardingProgress] = useState({});
  const uid = auth.currentUser?.uid;
  const { setOnboardingStepKey } = useContext(OnboardingContext);
  const location = useLocation();
  const [showConfetti, setShowConfetti] = useState(false);
  const [loading, setLoading] = useState(true); // Loading state
  const [isShowAll, setIsShowAll] = useState(false); // New state
  const [isInitialized, setIsInitialized] = useState(false); // New state

  useEffect(() => {
    if (!uid) return; // Ensure user is authenticated

    const fetchUserData = async () => {
      try {
        // Fetch user data
        const userDocRef = doc(firestore, 'customers', uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          setUserData(userDoc.data());
        } else {
          console.error('User document does not exist');
        }

        // Fetch onboarding progress
        const onboardingDocRef = doc(firestore, 'customers', uid, 'onboarding', 'progress');
        const onboardingDoc = await getDoc(onboardingDocRef);
        if (onboardingDoc.exists()) {
          setOnboardingProgress(onboardingDoc.data());
        } else {
          // Initialize onboarding progress if it doesn't exist
          const initialProgress = {};
          steps.forEach((step, index) => {
            initialProgress[step.key] = {
              completed: false,
              skipped: false,
              unlocked: index === 0, // Only first step unlocked initially
            };
          });
          await setDoc(onboardingDocRef, initialProgress);
          setOnboardingProgress(initialProgress);
        }

        // Determine if all steps are completed
        const allCompleted = steps.every(
          (step) =>
            onboardingDoc.exists() &&
            (onboardingDoc.data()[step.key]?.completed ||
              onboardingDoc.data()[step.key]?.skipped)
        );

        if (!isInitialized) {
          // Set sidebar open state based on completion
          handleToggleSidebar(!allCompleted); // Use the prop function
          setIsInitialized(true); // Prevent future resets
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      } finally {
        setLoading(false); // Data fetching complete
      }
    };

    fetchUserData();
  }, [uid, isInitialized, handleToggleSidebar]);

  // Helper function to check if a step is unlocked
  const isStepUnlocked = (index) => {
    if (index === 0) return true; // First step is always unlocked
    const previousStepKey = steps[index - 1].key;
    const prevStep = onboardingProgress[previousStepKey];
    return prevStep && (prevStep.completed || prevStep.skipped);
  };

  // Check if all steps are completed
  const allStepsCompleted = steps.every(
    (step) => onboardingProgress[step.key]?.completed === true
  );

  // Handler for skipping a step
  const handleSkipStep = async (key, index) => {
    if (!uid) return; // Ensure user is authenticated
    try {
      // Update Firestore to mark the step as skipped
      const onboardingRef = doc(firestore, 'customers', uid, 'onboarding', 'progress');
      await updateDoc(onboardingRef, {
        [key]: { completed: false, skipped: true },
      });

      // Update local state
      setOnboardingProgress((prev) => ({
        ...prev,
        [key]: { completed: false, skipped: true },
      }));

      // Unlock the next step
      const nextStep = steps[index + 1];
      if (nextStep) {
        await updateDoc(onboardingRef, {
          [nextStep.key]: { ...onboardingProgress[nextStep.key], unlocked: true },
        });
        setOnboardingProgress((prev) => ({
          ...prev,
          [nextStep.key]: { ...prev[nextStep.key], unlocked: true },
        }));

        // Collapse all steps and expand only the next step
        const newExpandedSteps = {};
        newExpandedSteps[nextStep.key] = true;
        setExpandedSteps(newExpandedSteps);
      }
    } catch (error) {
      console.error('Error skipping step:', error);
    }
  };

  // Handler for beginning a step
  const handleBeginStep = async (step, index) => {
    if (!uid) return; // Ensure user is authenticated
    try {
      // Update Firestore to mark the step as completed
      const onboardingRef = doc(firestore, 'customers', uid, 'onboarding', 'progress');
      await updateDoc(onboardingRef, {
        [step.key]: { completed: true, skipped: false },
      });

      // Update local state
      setOnboardingProgress((prev) => ({
        ...prev,
        [step.key]: { completed: true, skipped: false },
      }));

      // Navigate to the target page
      if (step.navigateTo) {
        navigate(step.navigateTo, { state: { fromOnboarding: true, stepKey: step.key } });
        handleToggleSidebar(false); // Close the sidebar
      }

      if (step.key === 'step11') {
        setShowConfetti(true);
        setTimeout(() => {
          setShowConfetti(false);
        }, 3000); // Show confetti for 3 seconds
      }

      // Unlock the next step
      const nextStep = steps[index + 1];
      if (nextStep) {
        await updateDoc(onboardingRef, {
          [nextStep.key]: { ...onboardingProgress[nextStep.key], unlocked: true },
        });
        setOnboardingProgress((prev) => ({
          ...prev,
          [nextStep.key]: { ...prev[nextStep.key], unlocked: true },
        }));

        // Collapse all steps and expand only the next step
        const newExpandedSteps = {};
        newExpandedSteps[nextStep.key] = true;
        setExpandedSteps(newExpandedSteps);
      }

      // If all steps are now completed, close the sidebar
      const updatedAllCompleted = steps.every(
        (s) => onboardingProgress[s.key]?.completed || onboardingProgress[s.key]?.skipped || (s.key === step.key && true)
      );
      if (updatedAllCompleted) {
        handleToggleSidebar(false); // Close the sidebar
      }
    } catch (error) {
      console.error('Error completing step:', error);
    }
  };

  // Handler to toggle step expansion
  const handleToggleStep = (key) => {
    setExpandedSteps((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  const handleShowAllOrComplete = async () => {
    if (!uid) return; // Ensure user is authenticated

    const updatedProgress = {};
    steps.forEach((step) => {
      updatedProgress[step.key] = {
        completed: isShowAll, // If already showing all, mark as complete; else mark as skipped
        skipped: !isShowAll, // If not showing all, mark as skipped
        unlocked: true,
      };
    });

    try {
      const onboardingRef = doc(firestore, 'customers', uid, 'onboarding', 'progress');
      await updateDoc(onboardingRef, updatedProgress);

      setOnboardingProgress(updatedProgress);
      setIsShowAll(!isShowAll); // Toggle state to switch between "Show All" and "Mark as Complete"
    } catch (error) {
      console.error('Error updating all steps:', error);
    }
  };

  if (loading) {
    return null; // Or a loader/spinner if desired
  }

  return (
    <>
      {/* Toggle Arrow */}
      <button
        onClick={() => handleToggleSidebar(!isSidebarOpen)} // Toggle based on current state
        className={`fixed top-1/2 transform -translate-y-1/2 z-[100] p-2 ${
          allStepsCompleted
            ? 'bg-gradient-to-r from-primary-400 to-primary-500'
            : 'bg-gradient-to-r from-primary-400 to-teal-400 animate-pulse'
        } text-white rounded-l-lg hover:bg-gradient-to-r hover:from-primary-500 hover:to-teal-500 transition focus:outline-none transition-transform duration-300 ease-in-out ${
          isSidebarOpen ? 'right-[28%]' : 'right-0'
        }`}
        aria-label={isSidebarOpen ? 'Close Onboarding Sidebar' : 'Open Onboarding Sidebar'}
      >
        <FontAwesomeIcon icon={isSidebarOpen ? faChevronRight : faChevronLeft} />
      </button>

      {/* Backdrop */}
      {isSidebarOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-25 z-[99] transition-opacity duration-300 ease-in-out"
          onClick={() => handleToggleSidebar(false)} // Ensure clicking backdrop closes the sidebar
          aria-hidden="true"
        ></div>
      )}

      {/* Sidebar */}
      <div
        className={`fixed top-0 right-0 h-full w-[28%] ${
          allStepsCompleted
            ? 'bg-gradient-to-b from-primary-50 to-primary-100'
            : 'bg-gradient-to-b from-teal-50 to-teal-100'
        } border-l shadow-lg transform ${
          isSidebarOpen ? 'translate-x-0' : 'translate-x-full'
        } transition-transform duration-300 ease-in-out z-[100]`}
      >
        <div className="flex flex-col h-full relative">
          {showConfetti && <ConfettiExplosion />}

          {/* Header */}
          <div
            className={`px-6 py-8 border-b ${
              allStepsCompleted
                ? 'bg-gradient-to-br from-primary-100 to-primary-200 text-primary-800'
                : 'bg-gradient-to-br from-teal-100 to-primary-100 text-gray-700'
            }`}
          >
            {allStepsCompleted ? (
              <div className="text-sm flex flex-col space-y-3">
                <h2 className="text-xl font-bold">🎉 Congratulations!</h2>
                <p>You have successfully completed all onboarding steps.</p>
                <p>You can revisit any step below at any time.</p>
              </div>
            ) : (
              <div className="text-sm flex flex-col space-y-3">
                <p>
                  <strong>DigitalTCO Onboarding 🚀</strong>
                </p>
                <p>
                  DigitalTCO is simple. It is simple, but specific.
                </p>
                <p>
                  There is a unique method. It will work if you try to use it your own way, but it will not be consistent. And consistency is king.
                </p>
                <p>
                <b>This checklist breaks down the simple approach to mastering dental notes in just 20 minutes, saving you time and effort. Ignore it at your peril!</b>
                </p>
                <p>
                  <strong>New Users:</strong> Fast-track your setup.
                  <br />
                  <strong>Existing Users:</strong> Learn how to make it better.
                </p>
                <p>
                  Maximize efficiency, reclaim your time. Let’s go! 💡
                </p>
              </div>
            )}
          </div>

          {/* Progress Bar */}
          {!allStepsCompleted && (
            <div className="px-6 py-4">
              <div className="flex justify-between mb-2">
                <span className="text-sm font-medium text-teal-700">Progress</span>
                <span className="text-sm font-medium text-teal-700">
                  {`${Object.values(onboardingProgress).filter(step => step.completed).length} / ${steps.length}`}
                </span>
              </div>
              <div className="w-full bg-teal-200 rounded-full h-2.5">
                <div
                  className="bg-gradient-to-r from-primary-400 to-teal-400 h-2.5 rounded-full transition-all duration-300"
                  style={{
                    width: `${(Object.values(onboardingProgress).filter(step => step.completed).length / steps.length) * 100}%`,
                  }}
                ></div>
              </div>
            </div>
          )}

          {/* Steps List */}
          <div className="flex-1 overflow-y-auto px-6 py-4">
            <ul className="space-y-4">
              {steps.map((step, index) => {
                const stepStatus = onboardingProgress[step.key] || { completed: false, skipped: false, unlocked: false };
                const unlocked = isStepUnlocked(index);
                const isCompleted = stepStatus.completed;
                const isSkipped = stepStatus.skipped;

                // Determine if this is the current active step
                const isCurrentStep = unlocked && !isCompleted && !isSkipped;

                return (
                  <li key={step.key} className="bg-white rounded-lg shadow-sm">
                    <div className="flex flex-col">
                      <button
                        onClick={() => unlocked && handleToggleStep(step.key)}
                        className={`flex items-center justify-between p-4 text-sm font-medium rounded-t-lg rounded-b-lg w-full 
                          ${
                            unlocked
                              ? isCompleted
                                ? 'bg-gradient-to-r from-primary-50 to-primary-100 text-primary-800 hover:from-primary-100 hover:to-primary-200'
                                : 'bg-gradient-to-r from-teal-100 to-teal-200 text-teal-800 hover:from-teal-200 hover:to-teal-300'
                              : 'bg-teal-50 cursor-not-allowed blur-sm'
                          } 
                          ${isCurrentStep && !expandedSteps[step.key] ? 'animate-pulse rounded-b-lg' : ''}`}
                        disabled={!unlocked}
                        aria-label={unlocked ? `Open ${step.title}` : `${step.title} is locked`}
                      >
                        <div className="flex items-center">
                          <FontAwesomeIcon
                            icon={isCompleted ? faCheckCircle : unlocked ? faCircle : faLock}
                            className={`text-lg mr-3 ${
                              isCompleted
                                ? 'text-primary-500'
                                : unlocked
                                  ? 'text-teal-400'
                                  : 'text-gray-400'
                            }`}
                          />
                          <span className="text-teal-800">{step.title}</span>
                        </div>
                        {unlocked && (
                          <FontAwesomeIcon
                            icon={expandedSteps[step.key] ? faChevronUp : faChevronDown}
                            className="text-teal-500"
                          />
                        )}
                      </button>
                      {unlocked && expandedSteps[step.key] && (
                        <div className="px-6 py-4 bg-teal-50 rounded-b-lg">
                          <p className="text-teal-700 mb-3">{step.description}</p>
                          {/* Add video link if available */}
                          {step.video && (
                            <a href="#" className="text-teal-500 underline mb-4 inline-block">
                              {step.video}
                            </a>
                          )}
                          <div className="flex space-x-4">
                            <button
                              onClick={() => handleBeginStep(step, index)}
                              className={`px-4 py-2 rounded-lg font-semibold transition ${
                                isCurrentStep
                                  ? 'bg-primary-400 text-white animate-bounce hover:bg-primary-500'
                                  : isCompleted
                                    ? 'bg-primary-300 text-white hover:bg-primary-400'
                                    : 'bg-teal-400 text-white hover:bg-teal-500'
                              } focus:outline-none`}
                            >
                              {step.key === 'step11' ? 'Finish' : isCompleted ? 'Review' : 'Begin'}
                            </button>
                            {!isCompleted && step.key !== 'step11' && (
                              <button
                                onClick={() => handleSkipStep(step.key, index)}
                                className="px-4 py-2 bg-teal-200 text-teal-700 rounded-lg hover:bg-teal-300 transition focus:outline-none"
                              >
                                Skip
                              </button>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </li>
                );
              })}
            </ul>

            {/* Show All / Mark as Complete Button */}
            {!allStepsCompleted && (
              <div className="mt-6 text-center">
                <button
                  onClick={handleShowAllOrComplete}
                  className="text-xs text-teal-400 hover:underline"
                >
                  {isShowAll ? "Mark as Complete" : "Show All"}
                </button>
              </div>
            )}

          </div>
        </div>
      </div>

      {/* Tooltip */}
      <Tooltip id="tooltip" />
    </>
  );
};

export default OnboardingSidebar;