import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faTooth, 
    faStethoscope, 
    faChild, 
    faShieldAlt, 
    faCapsules, 
    faHeartbeat, 
    faSmileBeam, 
    faSmile, 
    faAlignJustify, 
    faNetworkWired, 
    faCrown, 
    faArchway, 
    faMask, 
    faScrewdriver, 
    faTeeth, 
    faBroom, 
    faBrush, 
    faProcedures, 
    faXRay,
    faShower, 
    faWrench, 
    faTeethOpen, 
    faSeedling,
    faSun,
    faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { collection, getDocs } from 'firebase/firestore';
import { firestore, auth } from '../../firebase';

const ExamsAndConsultsList = () => {
    const [selectedTreatments, setSelectedTreatments] = useState({});
    const [searchInput, setSearchInput] = useState('');
    const [additionalTreatments, setAdditionalTreatments] = useState({});
    const [isInfoVisible, setIsInfoVisible] = useState(true); 

    const handleCloseInfo = () => {
        setIsInfoVisible(false);
    };

    const initialTreatments = {
        'Basic Exam': { label: 'Basic Exam', icon: faStethoscope },
    };

    useEffect(() => {
        const fetchTreatments = async () => {
            const uid = auth.currentUser.uid;
            const treatmentsRef = collection(firestore, 'customers', uid, 'exam_templates');
            const snapshot = await getDocs(treatmentsRef);
            const fetchedTreatments = {};
            snapshot.forEach(doc => {
                fetchedTreatments[doc.id] = { label: doc.id, icon: null };
            });
            setAdditionalTreatments(fetchedTreatments);
        };

        fetchTreatments();
    }, []);

    const allTreatments = { ...initialTreatments, ...additionalTreatments };

    const filteredTreatments = Object.entries(allTreatments).filter(([key, value]) =>
        value.label.toLowerCase().includes(searchInput.toLowerCase())
    );

    return (
        <main className="flex flex-col items-center tracking-tight w-4/5 mx-auto">
            <input
                type="text"
                placeholder="Search examination type..."
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
                className="block p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 text-xs focus:ring-blue-500 focus:border-blue-500"
                autoFocus
            />
            <div className="grid grid-cols-6 gap-8 mt-8">
                {filteredTreatments.map(([key, value]) => (
                    <div key={key}>
                        <input 
                            id={key} 
                            type="checkbox" 
                            className="hidden" 
                        />
                        <label htmlFor={key} className="ml-8 w-full h-full text-center inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-lg text-gray-900 rounded-lg group bg-gradient-to-br from-primary-500 to-pink-500 group-hover:from-purple-500 group-hover:to-pink-500 hover:text-white focus:ring-4 focus:outline-none focus:ring-purple-200 shadow-lg">
                            <Link to={`/mvp/examsandconsults/${key}`} className="w-full h-full flex items-center justify-center px-5 py-1.5 transition-all ease-in duration-75 bg-white rounded-md group-hover:bg-opacity-0">
                                {value.label}
                            </Link>
                        </label>
                    </div>
                ))}
            </div>
        </main>
    );
};

export default ExamsAndConsultsList;
