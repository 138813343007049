// TreatmentDetails.js
export const introduction = `<p>Thank you for choosing our dental practice for your oral healthcare needs. We are genuinely grateful for the trust you've placed in us. At our clinic, we are committed to your well-being and strive to provide you with the best possible dental care experience.</p>
<p>Dentistry offers numerous benefits beyond just enhancing your smile's appearance. It plays a vital role in maintaining your overall health and ensuring your comfort. Our dedicated team is here to support you every step of the way, from routine check-ups to addressing specific dental concerns.</p>
<p>During your dental checkup, we performed a thorough examination in three steps.</p>
<p>First, we carefully checked your teeth to look for any signs of cavities or issues with existing dental work like fillings or crowns.</p>
<p>Next, we examined your gums and the bone that supports your teeth and keeps them in place. We checked for problems like gum disease or inflammation that might affect the health of your teeth in the future.</p>
<p>Finally, we took a close look at the way your teeth come together when you bite down. This helps us ensure that your jaw joints and muscles are healthy and that your bite isn't causing any damage to your teeth.</p>
<p>After completing the examination, we sat down to discuss the findings and the options available to you. For each issue we identified, I explained that you have a choice. You can choose to do nothing, but it's important to understand that this may lead to more dental problems down the road.</p>
<p>To help you make informed decisions, I've provided detailed information about the available treatments for each specific issue as we discussed.</p>
<br>
<h2>Your Dental Care Journey</h2>
<p>At our practice, you'll meet various members of our team, each playing a specific role in your treatment. If you ever have questions about who is taking care of you, please don't hesitate to ask any team member for more information.</p>
<p>This document has been carefully crafted to give you all the details about your upcoming dental treatment. It covers potential issues and what we can do to help. We don't want to scare you, but rather, we want to provide you with the knowledge you need to make decisions about your treatment. This way, you can decide what's best for you, considering both the possible risks and the benefits.</p>
<p>It's important to know that every medical procedure, including dental care, has some level of risk, and sometimes things don't go exactly as planned. Even though we're committed to providing the best care, healthcare can be unpredictable due to the unique way each person's body responds.</p>
<p>In rare cases, complications or challenges may arise despite our best efforts. Some of these we can prepare for, but others may be unexpected because they are so unlikely or hard to foresee.</p>
<p>If complications do happen, we're here to help. We'll do our best to fix any issues and get your treatment back on track. In cases where we can't fully resolve a problem, we'll support you and, if necessary, help you find additional care outside our practice.</p>
<p>If you ever face difficulties, concerns, or questions during your treatment journey, please reach out to any team member. Your comfort and well-being are our priorities. And if, at any point, you feel that your treatment hasn't met your expectations—remember, complications can happen in healthcare—please talk to our Practice Manager and ask for our complaints proecdure.</p>
<p>It's important to understand that no healthcare procedure, even in private healthcare, can guarantee a perfect outcome. If you have reservations about the possibility of complications, it might be a good idea to delay your treatment until you feel more comfortable or to have more discussions with our team before giving your final consent.</p>
<p>We hope your visit here will be everything you hope for and more.</p>
`;

export const generalDentalRisks = `<h2>General Dental Treatment Risks</h2>
<p>Dental treatments are essential for maintaining your oral health and addressing various dental issues. However, it's important to be aware of the potential risks and complications associated with dental procedures. Your understanding of these risks is crucial for making informed decisions regarding your dental care.</p>
<h3>Common Risks and Considerations:</h3>
<ul>
<li>Numbness in treated areas, which typically resolves within a few hours.</li>
<li>Soreness, bruising, and localized swelling following certain procedures.</li>
<li>Temporary sensitivity or discomfort in teeth or gums after treatments.</li>
<li>Prolonged post-operative pain or infection due to poor healing.</li>
<li>Changes in the appearance of dental work, such as dental restorations or crowns.</li>
</ul>
<h3>Potential Complications:</h3>
<ul>
<li>Trauma to other parts of the mouth, including adjacent teeth, gums, cheeks, or tongue.</li>
<li>In rare cases, allergic reactions to materials used during dental procedures.</li>
<li>Fracture of teeth during certain treatments, requiring additional procedures or repairs.</li>
<li>Discomfort associated with dental impressions or X-rays, which are often necessary for treatment planning.</li>
<li>Root canal treatments may lead to changes in tooth color over time.</li>
<li>Subsequent need for a root canal treatment after a filling/crown if the tooth does not heal due to a variety of factors.</li>
</ul>
<h3>Rare Complications:</h3>
<ul>
<li>Lasting Numbness: While temporary numbness is a common side effect of many dental procedures, prolonged or permanent numbness, also known as paresthesia, is a rare but possible complication. This can result from nerve trauma during a procedure. The affected area may feel "tingly," "asleep," or entirely devoid of sensation. Though this is uncommon, it's essential to communicate any prolonged numbness to your dentist promptly.</li>
<li>Unforeseen bone resorption after procedures, potentially affecting the stability of dental implants or prostheses.</li>
<li>Post-operative bleeding that does not subside or becomes excessive.</li></ul>
<h3>Long-term Considerations:</h3>
<ul>
<li>Dental treatments will require ongoing maintenance and follow-up appointments to ensure their longevity and effectiveness.</li>
<li>Treatments do not last forever. Your restorations go through a lot of use - at 3 meals a day, thats 1095 uses per year! Sadly our materials are not as good as natural teeth, so you will likely require replacement or repair after a certain period.</li>
<li>Changes in your dental health or lifestyle may impact the success of certain procedures over time. The better you look after your dental work, the longer it will last.</li>
<li>Missing teeth can lead to bite changes, shifting of adjacent teeth, and possible future dental issues.</li>
</ul>
<p>It's important to maintain open communication with your dentist and discuss any concerns or questions you may have about your dental treatment. Dentists prioritize patient safety and strive to minimize risks associated with dental procedures.</p>
<p>Remember that maintaining good oral hygiene practices at home, attending regular dental check-ups, and following your dentist's recommendations can help reduce the likelihood of complications and ensure the long-term success of your dental treatments.</p>
`;

export const dentalcareDetails = `<h2>Choosing Your Dental Care</h2>
<p>Visiting our practice means you're seeking guidance and expertise for your dental concerns. We'll offer insights based on examinations or x-ray results and suggest various treatments. However, always remember that you hold the power to decide your treatment path.</p>
<h3>Empowering Your Decisions:</h3>
<p>Your dental health is personal. While we offer recommendations, it's ultimately your choice to select which treatments you undergo. When making a choice, you're also recognizing and being open to the possible risks associated with it.</p>
<h3>Understanding Risks:</h3>
<p>Every dental procedure, like all medical treatments, comes with its own set of uncertainties. Our commitment is to guide and support you, but it's essential to understand that not all treatments have guaranteed outcomes.</p>
<h3>Possible Scenarios:</h3>
<ul>
<li>There might be unexpected challenges during the procedure.</li>
<li>Results might differ from initial expectations.</li>
<li>Further treatments might become necessary.</li>
</ul>
<h3>Your Comfort Matters:</h3>
<p>If any risks or potential challenges make you uneasy, it's always your choice to decide against the treatment. While we aim to achieve the best for our patients, the nature of dental care means that results can sometimes be uncertain. We assure you of our unwavering support, whatever you decide.</p>
<h3>Stay Connected:</h3>
<p>Should you have further questions or need clarification, don't hesitate to contact us.</p>
<p><strong>Please note:</strong> This is a general overview and not a complete list of all risks and scenarios. It provides a summary of the common situations patients may face. If you have any questions about something not covered here, please ensure to ask your dentist or a team member at the practice.</p>
`;

export const compositeDetails = `<h2>Composite Restoration</h2>
<p>Placing a filling is a dental procedure during which decay is removed from a tooth and the cavity is restored with a composite filling material. The intended benefits of the procedure include removal or prevention of pain and returning the tooth to its correct shape.</p>
<h3>Duration of the Procedure:</h3>
<p>The procedure can take anywhere from 20 minutes to multiple hours, depending on the number of teeth being treated.</p>
<h3>Materials Used:</h3>
<p>For this procedure, a composite (white) filling material will be used.</p>
<p>As with all medical procedures, there are risks and potential complications which you must be aware of before you can give your consent to proceed.</p>
<h3>Expected complications:</h3>
<ul>
<li>Numbness lasting a few hours due to the use of local anesthesia.</li>
<li>Soreness of the gums lasting a few days.</li>
<li>Some sensitivity or discomfort from the tooth for a period of weeks after treatment</li>
<li>Changes in how the tooth feels to your tongue</li>
</ul>
<h3>Common risks and complications:</h3>
<ul>
<li>Trauma to other parts of the mouth including adjacent teeth, gums, cheeks, tongue, etc.</li>
<li>Inability to return the tooth to a perfect shape which could result in it being more difficult to look after.</li>
<li>The filling will be a close colour match but will not be perfect.</li>
</ul>
<h3>Rare risks and complications:</h3>
<ul>
<li>Weakening of the tooth being treated meaning it might require further work in the future to restore it to decent strength.</li>
<li>Trauma to tissues underneath the tooth including bone, sinus, nerves supplying other teeth, etc.</li>
<li>Allergic reaction to the composite material or something used during the procedure.</li>
</ul>
<p>Fillings are not successful 100% of the time even if all parts of the procedure go as planned. The decay may have already irritated the nerve which could result in toothache in the future. Therefore, some teeth that have undergone this procedure will require a root canal treatment or might require extraction.</p>
<h3>Alternative options:</h3>
<ul>
<li>Referral to a specialist in this field who may be able to treat the tooth better via the use of a microscope and specialist equipment, etc.</li>
<li>Treating the tooth in a different way such as extraction or crowning the tooth.</li>
<li>Refusing treatment but this will result in a high risk of further pain and infection from this tooth.</li>
</ul>
<h3>Post-Procedure Care:</h3>
<p>After the procedure, it's recommended to avoid eating on the treated side for at least 24 hours and refrain from consuming very hot or cold foods.</p>
<h3>Anesthesia:</h3>
<p>Local anesthesia usually containing adrenaline will be used for this procedure.</p>
`;

export const rootCanalDetails = `<h2>Root Canal Treatment (RCT)</h2>
<p>Root canal treatment (RCT) is a dental procedure during which the nerve is removed from a tooth, typically because the nerve is dying or has died. The intended benefits of the procedure include the removal of pain and/or infection at the base of the tooth.</p>
<h3>Duration of the Procedure:</h3>
<p>The procedure can take anywhere from 30 minutes to several hours, depending on the complexity of the tooth's root canal system and the number of roots involved.</p>
<h3>Materials Used:</h3>
<p>During this procedure, various materials and solutions, including gutta-percha, are used to clean and seal the root canal.</p>
<p>As with all medical procedures, there are risks and potential complications which you must be aware of before you can give your consent to proceed.</p>
<h3>Expected complications:</h3>
<ul>
<li>Numbness lasting a few hours due to the use of local anesthesia.</li>
<li>Soreness of the gums lasting a few days.</li>
</ul>
<h3>Common risks and complications:</h3>
<ul>
<li>Trauma to other parts of the mouth including adjacent teeth, gums, cheeks, tongue, etc.</li>
<li>Weakening of the tooth being treated, meaning it might require further work in the future to restore it to decent strength.</li>
<li>Darkening of the tooth.</li>
<li>Inability to locate, clean, and seal all parts of the root canal, resulting in failure to remove all pain and/or infection.</li>
</ul>
<h3>Rare risks and complications:</h3>
<ul>
<li>Fracture of the tooth resulting in extraction being required.</li>
<li>Separation (fracture) of an instrument within the tooth.</li>
<li>Perforation of the floor or root of the tooth by an instrument.</li>
<li>Trauma to tissues underneath the tooth, including bone, sinus, nerves supplying other teeth, etc.</li>
<li>Allergic reaction to materials or solutions used during the procedure.</li>
</ul>
<p>Root canal treatment is not successful 100% of the time, even if all parts of the procedure go as planned. Therefore, some teeth that have undergone this procedure will require the RCT to be re-done or might require extraction.</p>
<h3>Alternative options:</h3>
<ul>
<li>Referral to a specialist in this field who may be able to treat the tooth better via the use of a microscope and specialist equipment, etc.</li>
<li>Extraction leading to a gap or further work to replace the tooth, such as a bridge, implant, or denture.</li>
<li>Refusing treatment, but this will result in a high risk of further pain and infection from this tooth.</li>
</ul>
<h3>Post-Procedure Care:</h3>
<p>After the procedure, it's recommended to avoid eating on the treated side for a certain period and refrain from consuming very hot or cold foods.</p>
<h3>Anesthesia:</h3>
<p>Local anesthesia, usually containing adrenaline, will be used for this procedure.</p>
`;

export const crownOrOnlayDetails = `<h2>Crown or Onlay (Partial Crown)</h2>
<p>Placing a crown or onlay is a dental procedure during which the tooth is shaped, and a lab-made restoration is custom-fitted to the tooth. The intended benefits of the procedure include returning the tooth to its correct shape and restoring its strength. If an onlay is provided, it maintains healthy tooth structure, which will be visible. The best shade match will be provided, but it may not be perfect. However, as these are usually placed on back teeth, they are typically not visible to others.</p>
<h3>Duration of the Procedure:</h3>
<p>The procedure can vary in duration, typically requiring two appointments: one for tooth preparation and impression-taking or 3D scanning, and a second for fitting the crown or onlay.</p>
<p>In some instances we can complete the whole procedure in one day, we will advise you if this is possible.</p>
<h3>Materials Used:</h3>
<p>Various materials can be used for crowns and onlays, including porcelain, ceramic, gold, and other metals.</p>
<p>As with all medical procedures, there are risks and potential complications which you must be aware of before you can give your consent to proceed.</p>
<h3>Expected complications:</h3>
<ul>
<li>Numbness lasting a few hours due to the use of local anesthesia.</li>
<li>Soreness of the gums lasting a few days.</li>
<li>Some sensitivity and discomfort following the treatment for a period of time until the tooth heals.</li>
</ul>
<h3>Common risks and complications:</h3>
<ul>
<li>Trauma to other parts of the mouth including adjacent teeth, gums, cheeks, tongue, etc.</li>
<li>Some teeth will remain sensitive for some time after the procedure.</li>
<li>Darkening of the root of the tooth, depending on the material used.</li>
<li>Inability to return the tooth to a perfect shape, which could make it more challenging to maintain.</li>
</ul>
<h3>Rare risks and complications:</h3>
<ul>
<li>Removing tooth material to make space for the crown can irritate the nerve inside the tooth, sometimes leading to the nerve dying and causing toothache.</li>
<li>Trauma to tissues underneath the tooth, including bone, sinus, nerves supplying other teeth, etc.</li>
<li>Allergic reaction to materials or solutions used during the procedure.</li>
</ul>
<p>Crowns and onlays are not successful 100% of the time, even if all parts of the procedure go as planned. The tooth may not have enough strength left prior to this procedure for a long-term result. Decay and previous work may have already irritated the nerve, which could result in toothache in the future. Therefore, some teeth that have undergone this procedure will require a root canal treatment or might require extraction.</p>
<h3>Alternative options:</h3>
<ul>
<li>Referral to a specialist in this field who may be able to treat the tooth better via the use of a microscope and specialist equipment, etc.</li>
<li>Treating the tooth in a different way, such as a large direct restoration (a filling), but this is not as strong and won't last as long.</li>
<li>Refusing treatment, but this will result in a high risk of fracture or pain and infection from this tooth.</li>
</ul>
<h3>Post-Procedure Care:</h3>
<p>After the procedure, it's recommended to avoid eating on the treated side for a certain period and refrain from consuming very hot or cold foods.</p>
<h3>Anesthesia:</h3>
<p>Local anesthesia, usually with adrenaline, will be used for this procedure.</p>
`;

export const dentureDetails = `<h2>Dentures</h2>
<p>Getting dentures involves creating a custom-made, removable prosthetic for your mouth to replace one or more missing teeth. The primary objectives are to enhance the appearance of your smile and restore your ability to speak and eat comfortably.</p>
<p>It's important to understand that the process of obtaining dentures can vary depending on the method chosen and the approach taken by the dental team.</p>
<h3>Classic Denture Method:</h3>
<p>The classic method of crafting dentures typically involves multiple appointments over several weeks. This approach requires a coordinated effort between different members of the dental team:</p>
<ul>
<li>Your dentist initiates the process, taking necessary measurements and impressions of your mouth during the first appointment.</li>
<li>These records are then passed on to a dental technician who plays a crucial role in crafting the denture.</li>
<li>The technician meticulously designs and creates the denture based on the provided specifications.</li>
<li>Upon completion, the denture is sent back to your dentist for fitting and adjustments.</li>
</ul>
<p>This traditional method allows for precise customization but may entail a longer time frame.</p>
<h3>Modern Denture Methods:</h3>
<p>Modern advancements in dental technology have introduced shorter time frames for denture creation, often utilizing 3D scanning and printing techniques. These methods can expedite the process while maintaining a high level of precision.</p>
<h3>What to Expect:</h3>
<ul>
<li>Dentures are removable and may take some time to adjust to. They won't feel exactly like your natural teeth.</li>
<li>Regular cleaning and maintenance are essential for maintaining oral health and prolonging the lifespan of your dentures.</li>
<li>It's not uncommon for dentures to experience minor movement or discomfort initially.</li>
<li>Over time, changes in your mouth's shape may necessitate adjustments, relining, or even remaking of the dentures to ensure a proper fit.</li>
</ul>
<h3>Common Issues:</h3>
<ul>
<li>New dentures usually cause sore spots due to friction against your gums. For most people, new dentures need to be adjusted after a few weeks of wear to make them comfortable. This is normal.</li>
<li>If you have existing teeth, fillings, or crowns, the dentures may exert additional pressure on them, potentially requiring future dental work.</li>
<li>Dentures can be fragile and may break or become lost. In such cases, repairs or replacements may be necessary, which may involve some waiting time.</li>
</ul>
<h3>Less Common Issues:</h3>
<ul>
<li>Allergic reactions to denture materials are rare but possible.</li>
<li>Even with well-crafted dentures, some individuals may find them challenging to adapt to. Some people like them, some people don't. If this occurs, alternative tooth replacement options may be explored.</li>
</ul>
<h3>Other Options:</h3>
<ul>
<li>Consultation with a specialist who may employ different techniques or technologies to enhance denture fit and comfort.</li>
<li>Exploration of alternative treatments, such as dental bridges or implants, for replacing missing teeth.</li>
<li>If you decide not to proceed with dentures, please be aware that your dental condition will remain unchanged.</li>
</ul>
`;

export const hygieneDetails = `<h2>Periodontal (Gum Disease) Treatment</h2>
<h3>What is Periodontal Disease</h3>
<p>Periodontal disease, commonly known as gum disease, is a condition that affects the tissues supporting your teeth (like the foundations of your house - the stuff that keeps your teeth in your head!). It usually starts with inflammation of the gums caused by the accumulation of plaque, a sticky film of bacteria.</p>
<p>If left untreated, it can progress to more severe stages, potentially leading to gum recession, pockets forming between the teeth and gums, and even tooth loss. Symptoms may include red, swollen gums, bleeding while brushing or flossing, and bad breath.</p>
<p>Think back to when you last bought an off-the-rack suit. Remember how the pockets were sewn shut? That's what we want your gums to be like. Gum disease is like when you have removed the thread - now there is a deep pocket, and you can't get down to the bottom to clean out all the fluff and dust (plaque and debris). This then festers, and progressively gets worse.</p>
<p>Gum disease cannot be cured. If you have it, you have it. What that means is you need to take extra special care when cleaning your teeth, so that it doesn't get worse. We will show you what to do.</p>
<p><strong>Procedure Description:</strong> Periodontal treatment is a dental procedure during which we thoroughly clean your teeth, both above and below the gumline. The primary goal of this treatment is to manage and control your gum disease with the aim of preserving the health and stability of your teeth.</p>
<h3>Expected Outcomes:</h3>
<ul>
<li>Temporary numbness in the treated area, if local anaesthetic has been used, which typically resolves within a few hours.</li>
<li>Mild soreness or tenderness in the gums for a few days following the treatment.</li>
<li>The need for future maintenance cleanings and review appointments to sustain gum health and prevent the progression of gum disease.</li>
</ul>
<h3>Common Risks and Considerations:</h3>
<ul>
<li>The procedure may lead to some temporary discomfort in other parts of your mouth, such as the tongue, cheeks, or teeth.</li>
<li>You may experience increased sensitivity in some of your teeth following the cleaning.</li>
<li>Gum disease is a chronic condition that cannot be completely cured. To manage it effectively, you will need to maintain excellent oral hygiene at home, adhere to the advice provided by the dental hygienist, and schedule regular maintenance cleanings. Failure to do so may increase the risk of tooth loss over time.</li>
<li>It's possible that your gums may exhibit slight changes in appearance, including alterations in color or shape. If your gums are inflammed (puffed up), they will shrink back when they have healed, and you can have visible gaps in between your teeth. This can be corrected with restorative techniques.</li>
</ul>
<h3>Smoking and Periodontal Health:</h3>
<p>Smoking is a significant risk factor for the development and progression of periodontal (gum) disease. It has been shown that smokers are more likely to experience severe gum disease compared to non-smokers.</p>
<p>The harmful chemicals in tobacco products can weaken the immune system's response to bacterial infection, making it harder for the body to fight off gum infections. Smoking also restricts blood flow to the gums, reducing their ability to heal and regenerate.</p>
<p>Additionally, smokers often exhibit less obvious signs of gum disease, such as reduced bleeding, which can lead to delayed diagnosis and treatment. To maintain healthy gums, quitting smoking is crucial.</p>
<h3>Diabetes and Periodontal Health:</h3>
<p>Diabetes, particularly uncontrolled diabetes, is closely linked to an increased risk of periodontal disease. Elevated blood sugar levels can affect the body's ability to fight off infections, including gum infections.</p>
<p>Furthermore, individuals with diabetes are more prone to inflammation, which can exacerbate the severity of gum disease. On the other hand, severe gum disease can lead to difficulties in controlling blood sugar levels, creating a challenging cycle between diabetes and periodontal health.</p>
<p>Effective management of blood sugar through proper diabetes care and consistent dental hygiene is vital to reduce the risk of gum disease in diabetic individuals. Regular dental check-ups and professional cleanings are essential for early detection and intervention in cases of periodontal disease.</p>
<p><strong>Important Note:</strong> Gum disease is a persistent condition that requires ongoing care and attention. Even with a thorough cleaning, gum disease will still persist. We cannot cure it. Proper management involves not only professional cleanings but also your active participation in maintaining oral hygiene at home and following the recommendations of the dental hygienist. Neglecting this care may elevate the risk of tooth loss. We will do our part, you need to your part too!</p>
<h3>Alternative Options:</h3>
<ul>
<li>Referral to a periodontal specialist who may employ advanced techniques and equipment to address your specific needs.</li>
<li>Exploring alternative tooth replacement options, such as dental bridges or implants.</li>
<li>Electing not to proceed with the treatment. However, it's crucial to be aware that untreated gum disease can lead to further gum deterioration and tooth loss.</li>
</ul>
`;

export const bridgeDetails = `<h2>Dental Bridge</h2>
<p>Placing a bridge is a dental procedure during which a tooth/teeth are shaped and a lab-made restoration is custom made and fitted. The intended benefits of the procedure include providing a fixed false tooth supported by the adjacent tooth/teeth.</p>
<p>As with all medical procedures, there are risks and potential complications which you must be aware of before you can give your consent to proceed.</p>
<h3>Expected Complications:</h3>
<ul>
<li>Numbness lasting a few hours.</li>
<li>Soreness of the gums lasting a few days.</li>
</ul>
<h3>Common Risks and Complications:</h3>
<ul>
<li>Trauma to other parts of the mouth including adjacent teeth, gums, cheeks, tongue, etc.</li>
<li>Some teeth will still be sensitive for some time after the procedure.</li>
<li>Darkening of the root of the tooth depending on the material used.</li>
<li>Inability to return the tooth to a perfect shape which could result in it being more difficult to look after.</li>
</ul>
<h3>Rare Risks and Complications:</h3>
<ul>
<li>Removing tooth material to make space for the crown can irritate the nerve inside the tooth sometimes leading to the nerve dying and causing toothache.</li>
<li>Trauma to tissues underneath the tooth including bone, sinus, nerves supplying other teeth, etc.</li>
<li>Allergic reaction to something used during the procedure.</li>
</ul>
<p><strong>Additional Risk:</strong> A missing tooth is a one-tooth problem. Providing a bridge makes it a 2 or 3 tooth problem, so further down the line, there may be more teeth that require treatment, as nothing lasts forever!</p>
<p>Bridges are not successful 100% of the time even if all parts of the procedure go as planned. The tooth may not have enough strength left prior to this procedure for a long-term result. Decay and previous work may have already irritated the nerve which could result in toothache in the future. Therefore, some teeth that have undergone this procedure will require a root canal treatment or might require extraction.</p>
<h3>Alternative Options:</h3>
<ul>
<li>Referral to a specialist in this field who may be able to treat the tooth better via the use of a microscope and specialist equipment, etc.</li>
<li>Treating the tooth in a different way such as extraction.</li>
<li>Refusing treatment, but this will result in a high risk of fracture or pain and infection from this tooth.</li>
</ul>
`;

export const extractionDetails = `<h2>Extractions</h2>
<p>Extraction is a dental procedure during which a tooth is removed, typically because it has a significant problem and is not expected to have a good long-term prognosis. The intended benefits of the procedure include the removal of pain and/or infection, or the aim to make the mouth less prone to future problems.</p>
<p>After the tooth extraction, it typically takes about 3 weeks for the gum tissue to heal and close over. In the following 3-6 months, your underlying bone will undergo a full healing process, which is particularly crucial if you're getting a tooth removed and a temporary denture placed. Please be aware that the healing process may impact the fit of the denture, and it might require replacement in 3-6 months. The cost of replacing the denture will be your responsibility.</p>
<p>As with all medical procedures, there are risks and potential complications that you must be aware of before you can give your consent to proceed. Your understanding of these factors is essential for making an informed decision regarding your dental care.</p>
<h3>Expected Complications:</h3>
<ul>
<li>Numbness in the treated area, which typically resolves within a few hours.</li>
<li>Soreness, bruising, and localized swelling that may last up to a week.</li>
</ul>
<h3>Common Risks and Complications:</h3>
<ul>
<li>Trauma to other parts of the mouth, including adjacent teeth, gums, cheeks, tongue, etc.</li>
<li>Prolonged post-operative pain due to poor healing or infection.</li>
<li>Fracture of the tooth during the procedure, which can lead to a minor surgical procedure being required (including stiches) or a piece of the tooth being left because it cannot be removed.</li>
</ul>
<h3>Rare Risks and Complications:</h3>
<ul>
<li>Trauma to tissues underneath the tooth, including bone, sinus, nerves supplying other teeth, etc.</li>
<li>A communication (hole) between the mouth and sinus.</li>
<li>Displacement of part of the tooth into the sinus.</li>
<li>Allergic reaction to substances used during the procedure, although this is extremely rare.</li>
</ul>
<p><strong>Additional Considerations:</strong> Extraction is a significant procedure, and it's important to understand that removing a tooth is a permanent decision. The loss of a tooth may impact your oral function and aesthetics. It's essential to discuss tooth replacement options with your dentist if necessary, such as dental implants, bridges, or partial dentures.</p>
<h3>Alternative Options:</h3>
<ul>
<li>Referral to a specialist in this field who may be able to treat the tooth better using specialized equipment, techniques, or advanced procedures.</li>
<li>Consideration of alternative treatments, such as a root canal treatment, to potentially save the tooth. Your dentist can discuss the suitability of this option based on your specific situation.</li>
<li>Refusing treatment, but it's crucial to be aware that this decision carries a high risk of further pain and infection from the affected tooth.</li>
</ul>
`;

export const treatmentAgreement = `<h2>Adjustments to Treatment Plan</h2>
<p>It's essential for patients to recognize that while most treatments proceed as initially outlined, unforeseen circumstances might necessitate alterations or additions. Such changes often arise from conditions encountered during the procedure that weren't evident during the initial examination.</p>
<h3>Common Situations</h3>
<p>A common example is the need for root canal therapy after standard restorative work. Should such a situation arise, we will promptly inform you, discuss the available options, and adjust the treatment plan accordingly.</p>
<h3>Our Commitment</h3>
<p>We always prioritize your best interests and ensure transparency regarding costs and schedules. If you decide against a suggested procedure, we respect your decision.</p>
<h3>Scheduling & Delays</h3>
<p>Please note that treatment timelines might be influenced by factors like your availability and our scheduling constraints. We often segment treatments into phases, scheduling appointments progressively. Sometimes, delays are intentional, allowing for optimal healing. If you have specific timing preferences, please communicate them to us, and we'll do our utmost to accommodate.</p>
<br>
<h2>Our Promise on Treatment Results</h2>
<p>Our top priority is to ensure your treatment gives you the best possible results, taking your unique situation into account. Occasionally, the end results might not match your expectations due to the limitations we've discussed. In these cases, we can't offer refunds.</p>
<h3>Adapting and Improving</h3>
<p>We are committed to providing high-quality treatment and ensuring that our patients are fully informed. If, after the initial treatment or design confirmation, follow-up assessments suggest that a different approach or changes in the procedure could yield better results, we are open to discussing it. However, please be aware that significant changes to the treatment plan after confirmation may result in additional costs, such as materials and lab fees. Our primary goal is to ensure your satisfaction while being transparent about the associated expenses.</p>
<h3>Your Freedom to Choose</h3>
<p>You're in control throughout the process. Before we finalize any treatment, we can set up a review session. This allows you to carefully consider and approve the treatment plan. If you're unsure, feel free to document your concerns and ask for another review, maybe with a friend. If you decide to make changes after approving the plan, you'll be responsible for any associated costs, like materials and lab fees.</p>
<h3>Honesty</h3>
<p>In the world of medical and dental treatments, things can get complex. Even the most skilled professionals can't guarantee specific results. When you start your treatment, there are no promises—explicit or implied. We encourage open communication, so please ask all your questions and get answers that leave you satisfied before starting treatment with us.</p>
<h3>Costs</h3>
<p>The breakdown of costs associated with your treatment has been provided in a separate document. We encourage you to review this detailed cost outline to fully understand the financial aspects of your procedure. If you have any questions regarding the fees, please don't hesitate to reach out to us.</p>
<h3>Contact Us</h3>
<p>If you have any problems or issues during your treatment, we have an emergency appointment protocol. Please contact us by calling the telephone number at the start of this document at our opening time and we will see you within 24 hours.</p>
<p>We're eager to provide you with the best care. Should you have any questions or require further clarification on any aspect, please reach out to us.</p>
`;

export const whiteningDetails = `<h2>Teeth Whitening</h2>
<p>We're set to enhance the brightness of your smile using a specialized whitening gel. It's important to note that teeth naturally vary in shade, especially closer to the gums. After the procedure, there might still be slight variations in color across a tooth.</p>
<h3>Composition of the Whitening Gel:</h3>
<p>The primary ingredient in the gel is carbamide peroxide, combined with glycerine. If you have a known allergy or reaction to any of these components, please inform your dentist and reconsider this treatment.</p>
<h3>Whitening Expectations:</h3>
<p>Results can differ based on individual factors. However, many patients see a significant change within 2-3 weeks. You can technically eat and drink what you like, however we would recommend avoiding products that cause staining as they will continue to stain your teeth - red wine, coffee, etc.</p>
<h3>Application Process:</h3>
<p>Our team will guide you on applying the whitening gel. For the best results, ensure your teeth are clean. A single syringe of gel typically lasts for 4-5 applications, depending on tooth size. Place tiny amounts of gel on the inner side of the whitening trays and fit them onto your teeth. Any extra gel can be wiped away with a wet finger or brush.</p>
<h3>Sensitivity Concerns:</h3>
<p>Most people experience some sensitivity during the treatment. By this, we mean you will feel cold air on your teeth. This is temporary & passes with time after treatment. If it becomes bothersome, you can pause the treatment and consider using a sensitive toothpaste inside the whitening tray. Some people experience extreme sensitivity when you are actively wearing the whitening gel. If this happens, let us know and we will advise you what to do.</p>
<h3>Tray Maintenance:</h3>
<p>Clean the trays with COLD water after every use and store them in the provided case. If you grind your teeth while sleeping, the trays might wear out faster and might need replacement.</p>
<h3>Risks & Precautions:</h3>
<p>Whitening, like all treatments, carries certain risks. Prolonged use might cause sensitivity. However, decades of research and application have shown the treatment to be generally safe. If you're pregnant, please avoid this treatment. Remember, only natural teeth will whiten. Existing fillings, crowns, or veneers won't change color, so you may wish to have these replaced to match.</p>
<h3>Aftercare and Future Maintenance:</h3>
<p>To maintain the brightness, consider regular check-ups every 6 months. Over time, teeth might regain some discoloration, but you can always get a top-up treatment if needed. Ensure your last dental check-up wasn't over 6 months ago if you wish to get additional gel.</p>
<h3>Outcomes & Considerations:</h3>
<p>It's common for individuals to experience sensitivity during the whitening process. As teeth naturally age, they might darken, leading some to seek touch-ups in the future. It's crucial to understand that results can vary, and existing dental work might appear different in color after the procedure.</p>
<p>Some conditions can make whitening a bit trickier, such as tetracycline staining. This does not mean they cannot be whitened, but it may mean it takes longer, or the result may not be perfect. Again, if you have any concerns please discuss this with your dentist.</p>
<p>We have discussed various whitening methods, and we're committed to delivering your chosen treatment. Ensure you understand the instructions and follow them meticulously. If there are any concerns or unsatisfactory results, we're here to help and discuss advanced whitening solutions.</p>
<p><strong>Disclaimer:</strong> This overview isn't exhaustive, but it highlights common experiences and considerations tied to the teeth whitening process.</p>
`;

export const dentalimplantDetails = `<h2>Dental Implants</h2>
<p>Dental implants are a popular and effective way to replace missing teeth. While they offer numerous benefits, like all medical procedures, they come with certain risks.</p>
<h3>Purpose of Dental Implants:</h3>
<ul>
<li>To replace one or more missing teeth without affecting adjacent teeth.</li>
<li>To support a bridge and eliminate the need for a removable partial denture.</li>
<li>To provide support for a denture, making it more secure and comfortable.</li>
</ul>
<h3>Treatment Process:</h3>
<ol>
<li>Consultation and Planning: Initial consultation, dental examination, and planning for the implant procedure.</li>
<li>Implant Placement: The dental implant, made of titanium, is placed into the jawbone.</li>
<li>Healing Process: Over the next several months, the implant integrates with the bone in a process called osseointegration.</li>
<li>Placement of the Prosthesis: After healing, the dental prosthesis (either a crown, bridge, or denture) is attached to the implant.</li>
</ol>
<h3>What to Expect:</h3>
<ul>
<li>Dental implants look and feel like your own teeth.</li>
<li>With proper care, dental implants can last a lifetime - but you will need to look after them!</li>
<li>Implants require the same care as real teeth, including brushing, flossing, and regular dental check-ups.</li>
</ul>
<h3>Risks and Complications:</h3>
<ul>
<li>Infection: There's a risk of infection at the implant site.</li>
<li>Implant Failure: Not all dental implants integrate with the jawbone successfully. This can lead to implant mobility or complete implant loss.</li>
<li>Nerve Damage: Incorrect placement can lead to nerve damage, causing pain, numbness, or tingling in your natural teeth, gums, lips, or chin.</li>
<li>Sinus Problems: Implants placed in the upper jaw can protrude into the sinus cavities, leading to sinusitis or other complications.</li>
<li>Injury or Damage: Surrounding structures, such as other teeth or blood vessels, might be injured during the procedure.</li>
<li>Bone Loss: If the implant doesn't integrate well or if there's chronic infection, it can lead to bone loss around the implant.</li>
<li>Overloading: Immediate loading or early loading of implants can increase the risk of implant failure.</li>
<li>Biocompatibility Issues: Rarely, a patient might be allergic to the materials used, leading to rejection.</li>
<li>Improper Positioning: If the implant is not placed correctly, it might not support the prosthesis well, leading to complications.</li>
</ul>
<h3>Other Options:</h3>
<ul>
<li>Dental bridges or dentures as alternatives to implants.</li>
<li>Not proceeding with any form of treatment, but this may lead to other dental issues in the future.</li>
</ul>
`;

export const patientBehaviour = `<h2>Our Expectations for a Positive Experience</h2>
<p>At our dental practice, we want to ensure that your visit is as pleasant and comfortable as possible. We're here to help and care about your well-being. To create a warm and friendly atmosphere, we kindly ask for your cooperation and understanding with the following expectations:</p>
<h3>Your Attitude Matters</h3>
<p>We genuinely appreciate your good behavior, patience, and understanding during your time with us. Dental procedures can be challenging, but maintaining a positive and cooperative attitude can make the experience smoother and more enjoyable for everyone.</p>
<h3>Our Commitment to Respect</h3>
<p>We're committed to providing you with the highest level of care and service. In return, we expect respect and consideration from our patients. Please remember:</p>
<ul>
<li><strong>Respect for Our Team:</strong> Treating our staff with kindness and respect is essential. Their well-being is our priority.</li>
<li><strong>Kindness Matters:</strong> Showing kindness and consideration, whether to our staff or fellow patients, contributes to a positive dental experience.</li>
<li><strong>Reasonable Requests:</strong> We're here to address your needs and concerns. Please help us by making reasonable requests related to your dental treatment.</li>
</ul>
<h3>Working Together</h3>
<p>We hope that every visit to our practice is enjoyable. However, if any behavior becomes a concern, we will take appropriate steps:</p>
<ul>
<li><strong>Open Dialogue:</strong> In minor cases, we may have a conversation with you to address concerns and remind you of our expectations.</li>
<li><strong>Alternative Options:</strong> In very rare cases where behavior becomes a significant concern, we may assist you in finding alternative dental care elsewhere. Your dental health remains a priority, and we'll work together to ensure you receive the care you need.</li>
</ul>
<p>We value the relationship we build with our patients. Your comfort and satisfaction are our priorities, and we hope that we can always provide a positive and pleasant experience during your visits.</p>
<h3>Respecting Appointment Times</h3>
<p>We greatly value your time and the time of our other patients. To provide the best possible care for everyone, it's essential that we maintain our schedule as closely as possible.</p>
<p>We kindly request your assistance in being mindful of your appointment time. While we aim to address all your questions and concerns thoroughly, in some cases, we may need to schedule additional time for a more detailed discussion.</p>
<p>Your understanding in helping us stay on schedule is greatly appreciated. It ensures that all our patients receive the attention they deserve and allows us to provide a seamless and efficient dental experience.</p>
<p>If you have extensive questions or require additional time, we'll be happy to schedule a follow-up appointment to ensure all your needs are met comprehensively.</p>
<p>Your cooperation and understanding in creating a respectful and friendly environment are truly appreciated.</p>
`;

export const summaryIntroduction = `<p>Thank you for coming back to us for your dental care. We truly appreciate your trust and promise to always do our best for you.</p>
<p>During your last visit, we took a good look at your teeth, gums, and bite. We checked everything from fillings to how your teeth fit together when you chew.</p>
<p>After that, we chatted about what we found and what we think might help. Remember, it's always your choice on what to do next. We've given you some info on the treatments we talked about, so you can decide what feels right for you.</p>
<h2>Your Dental Journey:</h2>
<p>Our team is like a family, and we're here to help you every step of the way. If you're ever curious about something or have questions, just ask!</p>
<p>This document gives you a quick rundown of what we discussed and what might come next. We always aim for smooth sailing, but sometimes, things can be unpredictable. If there's ever a hiccup, we've got your back and will sort it out.</p>
<p>If something's on your mind or you're feeling unsure, please let us know. We want you to feel comfortable and in the loop. And if something doesn't feel right, our door is always open for a chat. Please get in touch with us.</p>
<p>Lastly, while we always hope for the best, sometimes things don't go as planned. We will always do our best for you, however complications do occur. We have explained some common and uncommon complications here. We are not trying to scare you, but to keep you informed about your healthcare.</p> 
<p>If you're feeling unsure at this stage, it's okay to take a step back and think things over or chat with us some more.</p>
<p>We're here for you and can't wait to see you smile!</p>
`;

export const summaryConclusion = `<h2>Your Dental Care Choices</h2>
<p>We're here to guide you, but remember, you're in the driver's seat. We'll suggest treatments based on our checks, but the final decision is always yours.</p>
<h2>Understanding the Process</h2>
<p>Every dental treatment has its pros and cons. We'll always be transparent about these, but sometimes things can be unpredictable. If you ever feel unsure, it's okay to pause and think or chat with us.</p>
<h2>Our Team's Promise</h2>
<p>We're all about making your visits comfortable. If you have questions, need more info, or just want to chat about your treatment, our door is always open.</p>
<h2>Respecting Time</h2>
<p>We value your time and aim to stick to our schedule. If you have many questions, we might set up another chat to ensure we cover everything.</p>
<h2>Changes Along the Way</h2>
<p>Sometimes, we might need to tweak the plan based on what we find during treatment. We'll always keep you in the loop and discuss any changes with you.</p>
<h2>Results & Costs</h2>
<p>We aim for the best results, but sometimes things might not go as expected. We'll be transparent about costs and any changes. If you have concerns about the treatment or costs, let's chat before moving forward.</p>
<h2>Got Questions?</h2>
<p>If anything comes up or you're unsure about something, just give us a call. We're here to help and ensure you're comfortable every step of the way.</p>
`;

export const amalgamDetails = `<h2>Amalgam Filling</h2>
<p>An amalgam filling is a common dental procedure where a cavity in a tooth is filled with an amalgam material, a mixture of metals including silver, mercury, tin, and copper. The primary purpose of this procedure is to restore the function and integrity of the tooth affected by decay or structural damage.</p>
<h3>Duration of the Procedure:</h3>
<p>The procedure typically takes one appointment and varies in duration depending on the size and location of the cavity. The process involves removing decay, preparing the tooth, and placing the amalgam filling material.</p>
<h3>Materials Used:</h3>
<p>The filling material is a durable, time-tested amalgam blend known for its strength and longevity. However, it is noticeable due to its silver color and is generally used for back teeth where aesthetics are less of a concern.</p>
<p>As with all medical procedures, there are risks and potential complications, which you must be aware of before giving your consent.</p>
<h3>Expected complications:</h3>
<ul>
<li>Temporary sensitivity to hot and cold temperatures.</li>
<li>Some discomfort or soreness in the treated area.</li>
<li>Numbness lasting a few hours due to local anesthesia.</li>
</ul>
<h3>Common risks and complications:</h3>
<ul>
<li>Slight damage to adjacent teeth or soft tissues (gums, cheeks, tongue).</li>
<li>Mercury sensitivity, though extremely rare, is a potential risk for some patients.</li>
<li>Discoloration of the surrounding tooth structure over time.</li>
</ul>
<h3>Rare risks and complications:</h3>
<ul>
<li>Cracking of the tooth due to amalgam expansion.</li>
<li>Allergic reactions to the metal components in the amalgam.</li>
<li>Persistent pain or infection, requiring further dental treatment.</li>
</ul>
<p>While amalgam fillings are effective for restoring decayed teeth, they are not always a permanent solution. Over time, they may wear down or break, necessitating replacement or alternative treatments.</p>
<h3>Alternative options:</h3>
<ul>
<li>Composite fillings, which are tooth-colored and more aesthetically pleasing but may not be as durable for large cavities.</li>
<li>Inlays, onlays, or crowns, which are more extensive restorations for significantly damaged teeth.</li>
<li>Leaving the tooth untreated, which could lead to further decay, pain, and potential tooth loss.</li>
</ul>
<h3>Post-Procedure Care:</h3>
<p>It's advisable to avoid chewing on the treated side until the numbness wears off. Patients should maintain good oral hygiene and schedule regular dental check-ups to monitor the condition of the filling and surrounding teeth.</p>
<h3>Anesthesia:</h3>
<p>Local anesthesia, often with adrenaline, will be used to numb the area during the procedure.</p>
`;

export const fissuresealantDetails = `<h2>Fissure Sealant</h2>
<p>Fissure sealant is a preventative dental treatment where a protective plastic coating is applied to the biting surfaces of the back teeth (molars and premolars). The sealant acts as a barrier, protecting enamel from plaque and acids and preventing decay in the grooves and fissures of the teeth.</p>
<h3>Duration of the Procedure:</h3>
<p>The procedure is typically quick, often completed in a single visit. It involves cleaning the tooth, preparing it with a special solution, and then applying the sealant, which is set with a light.</p>
<h3>Materials Used:</h3>
<p>The sealant material is a clear or tooth-colored resin that is applied directly to the tooth surface. It is non-invasive and pain-free.</p>
<p>As with any medical procedure, there are potential risks and complications, though they are minimal in this case.</p>
<h3>Expected complications:</h3>
<ul>
<li>None expected; the procedure is painless and non-invasive.</li>
</ul>
<h3>Common risks and complications:</h3>
<ul>
<li>Dislodgment or wearing off of the sealant over time, requiring reapplication.</li>
<li>Possible allergic reaction to the sealant material, though this is extremely rare.</li>
</ul>
<h3>Rare risks and complications:</h3>
<ul>
<li>Accidental ingestion of the sealant material, though it is non-toxic and generally harmless.</li>
</ul>
<p>Sealants are a highly effective way to prevent tooth decay, especially for children and teenagers. However, they are not a substitute for regular brushing and flossing.</p>
<h3>Alternative options:</h3>
<ul>
<li>No treatment, but this leaves the tooth at a higher risk for decay.</li>
<li>Regular fluoride treatments to strengthen enamel and prevent decay.</li>
</ul>
<h3>Post-Procedure Care:</h3>
<p>No special care is required following a sealant application. Normal eating and oral hygiene practices can be continued immediately after the procedure.</p>
<h3>Anesthesia:</h3>
<p>No anesthesia is required for this procedure as it is painless and non-invasive.</p>
`;

export const stainlesssteelcrownDetails = `<h2>Stainless Steel Crown for Children</h2>

<p>A stainless steel crown is a type of dental restoration used to cover and protect a primary (baby) tooth that has been damaged by decay or injury. These crowns are typically used when the tooth is too damaged to support a regular filling. They are most commonly used on molars.</p>

<h3>Duration of the Procedure:</h3>
<p>The procedure usually requires one visit to complete. It involves removing the decay, shaping the tooth, and fitting the crown over the tooth to restore its shape, size, and function.</p>

<h3>Materials Used:</h3>
<p>Stainless steel crowns are made from a durable metal alloy that can withstand the biting forces in children's mouths. They are designed to last until the primary tooth naturally falls out and is replaced by a permanent tooth.</p>

<p>While the procedure is generally safe, it's important to be aware of potential risks and complications.</p>

<h3>Expected complications:</h3>
<ul>
<li>Temporary discomfort or sensitivity in the treated area.</li>
<li>Numbness from the local anesthesia, lasting a few hours post-procedure.</li>
</ul>

<h3>Common risks and complications:</h3>
<ul>
<li>Discomfort or irritation to the surrounding gums and soft tissues.</li>
<li>Loosening or dislodging of the crown, which may require re-cementing or replacement.</li>
<li>Metal allergies, though rare, should be considered.</li>
</ul>

<h3>Rare risks and complications:</h3>
<ul>
<li>Damage to the underlying permanent tooth, if still developing.</li>
<li>Infection or abscess formation around the treated tooth.</li>
<li>Swallowing or inhaling the crown if it becomes loose or dislodged.</li>
</ul>

<p>The use of a stainless steel crown aims to preserve the primary tooth until it naturally exfoliates, maintaining the space and guiding the eruption of the permanent tooth.</p>

<h3>Alternative options:</h3>
<ul>
<li>Composite or amalgam fillings for less extensive decay, but these might not provide the same level of protection or durability.</li>
<li>Extraction of the tooth, which may be considered if the tooth is severely damaged, followed by a space maintainer to prevent space loss.</li>
<li>Refusing treatment, though this may lead to further decay, pain, infection, and potential problems with the development of permanent teeth.</li>
</ul>

<h3>Post-Procedure Care:</h3>
<p>After the crown is placed, normal eating can resume once the numbness wears off. Good oral hygiene practices should be maintained, including regular brushing and flossing, to care for the crown and surrounding teeth.</p>

<h3>Anesthesia:</h3>
<p>Local anesthesia will be used to numb the area and ensure the child is comfortable during the procedure.</p>
`;

export const compositebondingDetails = `<h2>Composite Bonding</h2>

<p>Composite bonding is a cosmetic dental procedure where a tooth-colored resin material is applied to the teeth to improve their appearance. This procedure is often used to fix discolored, chipped, or misshapen teeth, and it can also be used to close gaps between teeth or to make teeth look longer.</p>

<h3>Duration of the Procedure:</h3>
<p>Composite bonding typically requires one visit to complete and can take anywhere from 30 minutes to an hour per tooth, depending on the complexity of the case.</p>

<h3>Materials Used:</h3>
<p>The procedure involves the application of a composite resin material, which is sculpted to the desired shape, hardened with a special light, and then polished. The color of the resin is matched to the natural color of your teeth for a seamless appearance.</p>

<p>It's important to understand the risks and potential complications associated with composite bonding.</p>

<h3>Expected complications:</h3>
<ul>
<li>Temporary sensitivity to hot or cold temperatures.</li>
<li>Mild discomfort in the treated area.</li>
</ul>

<h3>Common risks and complications:</h3>
<ul>
<li>Accidental damage to surrounding teeth or gums during the procedure.</li>
<li>The composite material may stain over time and may need periodic polishing or replacement.</li>
<li>The bonding material may chip or break off and require repair or replacement.</li>
</ul>

<h3>Rare risks and complications:</h3>
<ul>
<li>Allergic reaction to the composite resin material, although this is extremely rare.</li>
<li>Damage to the nerve of the tooth, potentially requiring further dental treatment like a root canal.</li>
</ul>

<p>Composite bonding is a less invasive option compared to veneers or crowns but may not be as long-lasting. Proper care and regular dental check-ups are essential to maintain the results.</p>

<h3>Alternative options:</h3>
<ul>
<li>Veneers or crowns, which are more durable but require more tooth reduction.</li>
<li>Orthodontic treatment for misalignment or gaps.</li>
<li>No treatment, but this may not address aesthetic or functional concerns with your teeth.</li>
</ul>

<h3>Post-Procedure Care:</h3>
<p>After composite bonding, it's important to avoid biting on hard objects and consuming foods or beverages that can stain the teeth. Regular brushing, flossing, and dental check-ups are essential to maintain the health and appearance of bonded teeth.</p>

<h3>Anesthesia:</h3>
<p>Local anesthesia is not typically required for composite bonding unless decay removal or shape alteration is necessary.</p>
`;

export const invisalignDetails = `<h2>Invisalign Treatment</h2>

<p>Invisalign treatment involves using a series of clear, removable aligners to straighten teeth and correct bite issues. Unlike traditional braces, these aligners are virtually invisible and can be removed for eating, brushing, and flossing.</p>
<h3>Duration of the Procedure:</h3>
<p>The length of Invisalign treatment varies depending on the complexity of the case. On average, it ranges from 6 to 18 months. Patients are typically given a new set of aligners every one to two weeks.</p>
<h3>Materials Used:</h3>
<p>Invisalign aligners are made from a thermoplastic material uniquely developed for the Invisalign treatment plan.</p>

<p>It is important to be aware of the risks and potential complications associated with Invisalign treatment.</p>

<h3>Expected complications:</h3>
<ul>
<li>Temporary discomfort or a feeling of pressure for a few days with each new set of aligners.</li>
<li>Minor speech alterations in the initial stages of treatment.</li>
</ul>

<h3>Common risks and complications:</h3>
<ul>
<li>Slight irritation to the gums, cheeks, or lips until the mouth adjusts to the aligners.</li>
<li>Teeth may not move as expected, necessitating additional treatment, such as refinement aligners or, in some cases, fixed braces.</li>
<li>Failure to wear aligners for the recommended 20-22 hours per day can extend treatment time and affect the outcome.</li>
</ul>

<h3>Rare risks and complications:</h3>
<ul>
<li>Allergic reaction to the aligner material, although very uncommon.</li>
<li>Relapse of tooth positions after treatment, especially if retainers are not worn as instructed.</li>
<li>Root resorption (shortening of the tooth roots) or loss of tooth vitality.</li>
</ul>

<p>Regular dental check-ups are essential during Invisalign treatment to monitor progress and make necessary adjustments.</p>

<h3>Alternative options:</h3>
<ul>
<li>Conventional braces, which may be more effective for complex orthodontic cases.</li>
<li>Lingual braces, which are attached to the backside of teeth.</li>
<li>No treatment, though this may leave existing dental issues unaddressed.</li>
</ul>

<h3>Post-Procedure Care:</h3>
<p>Maintaining good oral hygiene is crucial during Invisalign treatment. This includes regular brushing and flossing and cleaning the aligners as instructed. Retainers should be worn after treatment as directed to prevent teeth from shifting back.</p>

<h3>Anesthesia:</h3>
<p>Anesthesia is not required for Invisalign treatment.</p>
`;

export const fixedorthoDetails = `<h2>Fixed Orthodontic Treatment</h2>

<p>Fixed orthodontic treatment, commonly known as braces, involves the use of brackets and wires attached to the teeth to correct misalignment, improve bite issues, and achieve aesthetic dental goals. The treatment is effective in straightening teeth and correcting bite problems.</p>

<h3>Duration of the Procedure:</h3>
<p>The duration of fixed orthodontic treatment varies widely depending on the complexity of the case. Typically, treatment can last between 1 to 3 years. Patients are required to attend regular adjustment appointments approximately every 4 to 8 weeks.</p>

<h3>Materials Used:</h3>
<p>The braces consist of brackets that are bonded to the teeth and wires that are adjusted to move the teeth into the desired position. Materials used for brackets include metal, ceramic, or composite, and the wires are usually made of stainless steel or nickel-titanium alloys.</p>

<p>Being aware of the risks and potential complications of fixed orthodontic treatment is essential for informed consent.</p>

<h3>Expected complications:</h3>
<ul>
<li>Temporary discomfort or soreness after adjustments.</li>
<li>Minor speech alterations at the beginning of the treatment.</li>
<li>Possible increase in plaque accumulation around brackets and wires, necessitating meticulous oral hygiene.</li>
</ul>

<h3>Common risks and complications:</h3>
<ul>
<li>Gum irritation or inflammation due to the brackets and wires.</li>
<li>Minor tooth movement or relapse after treatment if retainers are not worn as instructed.</li>
<li>Risk of decalcification or white spot lesions on the teeth if oral hygiene is not maintained.</li>
</ul>

<h3>Rare risks and complications:</h3>
<ul>
<li>Root resorption (shortening of tooth roots).</li>
<li>Damage to the enamel during bracket removal.</li>
<li>Allergic reactions to the materials used.</li>
</ul>

<p>Regular dental check-ups and proper oral hygiene practices are crucial during fixed orthodontic treatment. It is important to follow all care instructions provided by the orthodontist.</p>

<h3>Alternative options:</h3>
<ul>
<li>Removable orthodontic appliances like clear aligners (e.g., Invisalign), although they may not be suitable for all cases.</li>
<li>Lingual braces, attached to the backside of teeth, offering a more aesthetic option.</li>
<li>Opting not to undergo orthodontic treatment, which may leave existing dental issues unresolved.</li>
</ul>

<h3>Post-Procedure Care:</h3>
<p>After completion of the treatment, wearing retainers is crucial to maintain the results. Patients should follow the orthodontist's instructions regarding retainer wear and care.</p>

<h3>Anesthesia:</h3>
<p>Anesthesia is not typically required for fixed orthodontic treatments.</p>
`;

export const veneerDetails = `<h2>Dental Veneers</h2>

<p>Dental veneers are thin, custom-made shells designed to cover the front surface of teeth to improve their appearance. These shells are bonded to the front of the teeth, changing their color, shape, size, or length. Veneers can be made from porcelain or from resin composite materials.</p>

<h3>Duration of the Procedure:</h3>
<p>The veneer process typically requires two to three visits over a few weeks – consultation and treatment planning, preparation of teeth, and bonding of the veneers.</p>

<h3>Materials Used:</h3>
<p>Veneers are most commonly made from porcelain or resin composite materials. Porcelain veneers resist stains better than resin veneers and better mimic the light-reflecting properties of natural teeth.</p>

<p>As with any dental procedure, there are risks and potential complications of which patients should be aware before proceeding.</p>

<h3>Expected complications:</h3>
<ul>
<li>Temporary sensitivity to hot or cold foods and beverages.</li>
<li>Possible discomfort during the tooth preparation phase.</li>
</ul>

<h3>Common risks and complications:</h3>
<ul>
<li>Mismatched coloration compared to surrounding teeth, especially if teeth are whitened in the future.</li>
<li>Potential chipping or cracking of the veneer material.</li>
<li>Unintended alteration in bite or tooth alignment.</li>
</ul>

<h3>Rare risks and complications:</h3>
<ul>
<li>Increased sensitivity if enamel removal is extensive.</li>
<li>Possibility of veneer detachment or complete loss, requiring replacement.</li>
<li>Irreversible damage to the natural tooth structure during preparation.</li>
</ul>

<p>While veneers are a durable solution for enhancing a smile, they may eventually need to be replaced. They are not a reversible treatment, as a portion of the tooth enamel is removed for their placement.</p>

<h3>Alternative options:</h3>
<ul>
<li>Composite bonding, a less invasive and reversible option.</li>
<li>Crowns, which cover the entire tooth and are used when a tooth is more severely damaged or decayed.</li>
<li>No treatment, although this does not address aesthetic concerns.</li>
</ul>

<h3>Post-Procedure Care:</h3>
<p>After veneers are placed, patients should maintain good oral hygiene practices, avoid biting hard objects, and wear a mouthguard if they grind their teeth.</p>

<h3>Anesthesia:</h3>
<p>Local anesthesia may be used during the tooth preparation phase to minimize discomfort.</p>
`;

export const sedationDetails = `<h2>Dental Sedation</h2>

<p>Dental sedation involves the use of medication to help patients relax during dental procedures. It can range from minimal sedation, where the patient is relaxed but awake, to general anesthesia, where the patient is completely unconscious. The level of sedation is tailored to the patient's needs and the nature of the procedure.</p>

<h3>Types of Sedation:</h3>
<ul>
<li><strong>Minimal Sedation:</strong> Nitrous oxide (laughing gas) or oral sedatives to relax the patient while remaining awake.</li>
<li><strong>Moderate Sedation:</strong> Oral or IV sedatives leading to a deeper relaxation state. The patient may not remember much of the procedure.</li>
<li><strong>Deep Sedation:</strong> Medications that make the patient either almost or completely unconscious during the procedure.</li>
<li><strong>General Anesthesia:</strong> Medications that render the patient completely unconscious with no memory of the procedure.</li>
</ul>

<h3>Pre-Procedure Requirements:</h3>
<ul>
<li>Fasting for a certain period before sedation.</li>
<li>Arranging for someone to drive the patient home post-procedure.</li>
</ul>

<h3>Expected effects:</h3>
<ul>
<li>Relaxation and reduced anxiety during the dental procedure.</li>
<li>Temporary amnesia regarding the procedure.</li>
</ul>

<h3>Common risks and complications:</h3>
<ul>
<li>Nausea and vomiting.</li>
<li>Headache, dizziness, or grogginess after the procedure.</li>
<li>Minor allergic reactions.</li>
</ul>

<h3>Rare risks and complications:</h3>
<ul>
<li>More severe allergic reactions.</li>
<li>Respiratory or cardiovascular complications, especially in patients with underlying health issues.</li>
</ul>

<p>Patients with certain medical conditions or those taking specific medications may have increased risks associated with sedation. A thorough medical history and consultation with the patient's physician may be required.</p>

<h3>Alternative options:</h3>
<ul>
<li>Using local anesthesia without sedation.</li>
<li>Behavioral techniques for anxiety management.</li>
</ul>

<h3>Post-Procedure Care:</h3>
<p>Post-sedation, patients are monitored until they are ready to leave. They should not drive, operate heavy machinery, or make important decisions for the rest of the day. It's advisable to have a responsible adult accompany them for a few hours post-procedure.</p>

<h3>Anesthesia:</h3>
<p>The type of sedation used will depend on the patient's needs, the procedure being performed, and the patient's medical history. The dental team will discuss the most appropriate options with the patient.</p>
`;

export const fullarchimplantsDetails = `<h2>Full Arch Dental Implant and Bridge Restoration</h2>

<p><strong>Treatment Overview:</strong></p>
<p>Full arch dental implant and bridge restoration is a comprehensive dental procedure designed to replace an entire arch of missing teeth. The treatment involves the surgical placement of dental implants, followed by the attachment of a custom bridge to restore functionality and aesthetics.</p>

<h3>Advantages:</h3>
<ul>
<li><strong>Improved Chewing Functionality:</strong> Regain the ability to chew a variety of foods with ease.</li>
<li><strong>Enhanced Aesthetics:</strong> Achieve a natural-looking and attractive smile.</li>
<li><strong>Stability and Durability:</strong> Dental implants provide a stable foundation for the bridge, offering long-term durability.</li>
<li><strong>Preservation of Jawbone:</strong> Implants help prevent bone loss in the jaw, maintaining facial structure.</li>
</ul>

<h3>Risks and Complications:</h3>
<p>While full arch dental implant and bridge restoration is a well-established procedure, there are potential risks and complications, including:</p>
<ul>
<li><strong>Infection:</strong> Infection at the surgical site is a possibility.</li>
<li><strong>Bleeding and Bruising:</strong> Temporary bleeding and bruising may occur after surgery.</li>
<li><strong>Nerve Damage:</strong> Risk of temporary or permanent numbness due to nerve involvement.</li>
<li><strong>Implant Integration Issues:</strong> Implants may not integrate successfully with the jawbone.</li>
</ul>

<h3>Disadvantages:</h3>
<ul>
<li><strong>Cost:</strong> The procedure can be more expensive compared to alternative options.</li>
<li><strong>Surgery and Recovery:</strong> Surgical intervention is required, and a recovery period is necessary.</li>
<li><strong>Potential Complications:</strong> Although rare, unforeseen complications may arise during or after treatment.</li>
</ul>

<h3>Alternative Treatment Options:</h3>
<ul>
<li><strong>Removable Dentures:</strong> Traditional removable dentures provide a non-invasive option for tooth replacement.</li>
<li><strong>Partial Dentures:</strong> Partial dentures are suitable for replacing a section of missing teeth.</li>
<li><strong>Implant-Supported Dentures:</strong> An alternative implant-supported denture option may be considered.</li>
<li><strong>No Treatment:</strong> Some individuals may choose not to pursue active treatment.</li>
</ul>

<h3>Considerations:</h3>
<ul>
<li>The decision to undergo full arch dental implant and bridge restoration should be based on individual oral health needs and preferences.</li>
<li>Consultation with the dental team is essential to discuss specific concerns, expectations, and treatment planning.</li>
</ul>

<h3>Patient's Role:</h3>
<ul>
<li>Understanding and following pre and post-operative instructions is crucial for successful outcomes.</li>
<li>Regular follow-up appointments should be attended to monitor healing and address any concerns.</li>
</ul>

<h3>Financial Considerations:</h3>
<p>Patients are responsible for the associated costs of the procedure. Detailed financial arrangements will be provided during the consultation.</p>

<h3>Conclusion:</h3>
<p>Full arch dental implant and bridge restoration is an advanced and effective solution for comprehensive tooth replacement. While it comes with advantages, risks, and costs, careful consideration and collaboration with the dental team will ensure a well-informed decision.</p>

<p>For further inquiries or to discuss your specific case, please feel free to contact our dental professionals.</p>
`;

export const immediatedentureDetails = `<h2>Immediate Denture Information</h2>

<p>Immediate denture treatment involves the use of a denture constructed before tooth extraction, providing various advantages and considerations during the healing phase. Here's what you need to know:</p>

<h3>Advantages:</h3>
<ul>
<li><strong>Immediate Restoration of Aesthetics:</strong> Patients leave with a denture immediately after tooth extraction.</li>
<li><strong>Minimal Time Without Teeth:</strong> Maintains the appearance of having teeth during the initial healing phase.</li>
<li><strong>Efficient Process:</strong> Constructed while teeth are present, allowing for a quicker transition.</li>
</ul>

<h3>Disadvantages:</h3>
<ul>
<li><strong>Guesswork in Fit:</strong> Denture made before extraction, leading to uncertainty in post-extraction fit.</li>
<li><strong>Changes During Healing:</strong> Gums heal and shrink, potentially affecting denture fit over the initial 3-6 months.</li>
<li><strong>Adaptation Period:</strong> Adjustments and relines may be necessary for optimal fit.</li>
</ul>

<h3>Risks:</h3>
<ul>
<li><strong>Discomfort and Soreness:</strong> Common during the initial healing period.</li>
<li><strong>Speech and Chewing Changes:</strong> Temporary adjustments may be needed.</li>
<li><strong>Additional Adjustments:</strong> Gums may require further adjustments during the healing process.</li>
</ul>

<h3>Alternatives:</h3>
<ul>
  <li><strong>Delayed Denture:</strong> Waiting until after the healing period to create a denture.</li>
  <li><strong>Overdenture:</strong> Utilizing implants for enhanced stability.</li>
</ul>

<h3>Nature of Immediate Denture as a "Healing" Denture:</h3>
<ul>
  <li>Constructed with existing teeth, anticipating changes during extraction and healing.</li>
  <li>Expected to worsen in fit as gums heal and shrink over the initial 3-6 months.</li>
  <li>Definitive denture recommended after 3 months for an accurate fit.</li>
</ul>

<h3>Responsibilities and Costs:</h3>
<ul>
  <li><strong>Patient bears responsibility for the cost of the immediate denture.</strong></li>
  <li><strong>Patient is responsible for the cost of adjustments/relines to the immediate denture.</strong></li>
  <li><strong>Patient is responsible for the cost of the definitive denture after 3 months.</strong></li>
</ul>

<p>By choosing immediate denture treatment, patients acknowledge the advantages, potential challenges, and financial responsibilities associated with the process.</p>
`;

export const enlightenDetails = `<h2>Enlighten Teeth Whitening Patient Information</h2>
<p>Thank you for choosing Enlighten for your teeth whitening journey. Below is important information to guide you through the process. Please read this sheet carefully and feel free to ask any questions during your consultation.</p>
<h3>Enlighten Teeth Whitening Process:</h3>
<h3>Home Whitening Kit (3 Weeks):</h3>
<ul>
<li>Receive a home whitening kit with custom trays.</li>
<li>Use the provided Enlighten whitening gel daily for three weeks as instructed.</li>
<li>Follow the detailed guidelines provided for optimal results.</li>
</ul>
<h3>In-Office Whitening Session (if necessary):</h3>
<ul>
<li>After the at-home phase, you may have an in-office whitening session at the clinic if necessary.</li>
<li>The decision will be made based on the progress during the at-home phase.</li>
</ul>
<h3>Guaranteed Shade B1:</h3>
<ul>
<li>Enlighten guarantees a shade B1 result.</li>
<li>In some cases with difficulties, achieving this shade might take longer.</li>
</ul>

<h3>Important Points:</h3>
<ul>
<li><strong>Duration:</strong> The entire process typically takes around 3 weeks.</li>
<li><strong>Compliance:</strong> Follow the instructions diligently for the best outcome.</li>
<li><strong>Difficulty:</strong> Some cases may face challenges in reaching shade B1, but adjustments can be made.</li>
<li><strong>Additional Gels:</strong> If B1 isn't achieved, more gels will be provided at no extra cost after reassessment.</li>
</ul>

<h3>Advantages:</h3>
<ul>
<li><strong>Effective Whitening:</strong> Enlighten guarantees a bright shade B1 result.</li>
<li><strong>Convenient At-Home Process:</strong> The majority of the whitening process is done at home on your schedule.</li>
<li><strong>Professional In-Office Session:</strong> An in-office session is available if required for optimal results.</li>
</ul>

<h3>Disadvantages:</h3>
<ul>
<li><strong>Potential Sensitivity:</strong> Some patients may experience temporary tooth sensitivity during or after the whitening process.</li>
<li><strong>Time Commitment:</strong> The at-home process takes three weeks; additional time may be needed for some cases.</li>
</ul>

<h3>Risks:</h3>
<ul>
<li><strong>Tooth Sensitivity:</strong> Some individuals may experience temporary sensitivity during or after treatment.</li>
<li><strong>Color Unevenness:</strong> In rare cases, there may be variations in whitening results between teeth.</li>
</ul>

<h3>Alternatives:</h3>

<ul>
<li><strong>Other Whitening Products:</strong> Consider alternative over-the-counter whitening products.</li>
<li><strong>Professional In-Office Options:</strong> Explore other in-office whitening procedures for immediate results.</li>
</ul>

<h3>Contact Information:</h3>

<p>If you have questions or concerns during the process, contact our clinic immediately.</p>
`;
