import React, { useState, useEffect } from 'react';
import { collectionGroup, getDocs } from 'firebase/firestore';
import { firestore } from '../../firebase';
import * as XLSX from 'xlsx';
import { getAuth } from 'firebase/auth';

const NpsOverview = () => {
    const [totalSubmissions, setTotalSubmissions] = useState(0);
    const [npsScore, setNpsScore] = useState(0);
    const [detractors, setDetractors] = useState([]);
    const [passives, setPassives] = useState([]);
    const [promoters, setPromoters] = useState([]);
    const [detractorsPercentage, setDetractorsPercentage] = useState(0);
    const [passivesPercentage, setPassivesPercentage] = useState(0);
    const [promotersPercentage, setPromotersPercentage] = useState(0);
    const [loading, setLoading] = useState(false);
    const [selectedMonth, setSelectedMonth] = useState('');
    const [selectedYear, setSelectedYear] = useState('');

    const auth = getAuth();

    const fetchSubmissionsByMonth = async () => {
        if (!selectedMonth || !selectedYear) {
            alert('Please select a month and year.');
            return;
        }

        setLoading(true);
        try {
            console.log(`Fetching submissions for month: ${selectedMonth}, year: ${selectedYear}`);
            const submissionsRef = collectionGroup(firestore, 'submissions');
            const submissionsSnapshot = await getDocs(submissionsRef);
            console.log(`Total submissions fetched: ${submissionsSnapshot.size}`);

            const submissionsData = submissionsSnapshot.docs.map(doc => {
                const data = doc.data();
                const timestamp = data.timestamp.toDate();
                return {
                    id: doc.id,
                    ...data,
                    timestamp
                };
            });

            const filteredSubmissions = submissionsData.filter(submission => {
                return (
                    submission.timestamp.getMonth() + 1 === parseInt(selectedMonth) &&
                    submission.timestamp.getFullYear() === parseInt(selectedYear)
                );
            });

            setTotalSubmissions(filteredSubmissions.length);
            console.log(`Total filtered submissions for ${selectedMonth}/${selectedYear}: ${filteredSubmissions.length}`);

            const uniqueUids = [...new Set(filteredSubmissions.map(submission => submission.uid))];
            console.log(`Unique UIDs to fetch emails for: ${uniqueUids.length}`);

            // Get the current user's ID token for authorization
            const idToken = await auth.currentUser.getIdToken();

            // Fetch emails from the backend
            const response = await fetch('https://us-central1-digitaltco-c40e9.cloudfunctions.net/getUserEmails', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${idToken}`,
                },
                body: JSON.stringify({ uids: uniqueUids }),
            });

            if (!response.ok) {
                if (response.status === 403) {
                    throw new Error('Forbidden: You do not have the required permissions.');
                } else if (response.status === 401) {
                    throw new Error('Unauthorized: Invalid or expired token.');
                } else {
                    throw new Error(`Backend responded with status ${response.status}`);
                }
            }

            const userEmails = await response.json();
            console.log(`Fetched emails: ${JSON.stringify(userEmails)}`);

            const uidToEmailMap = userEmails.reduce((map, user) => {
                map[user.uid] = user.email;
                return map;
            }, {});

            let promotersCount = 0;
            let passivesCount = 0;
            let detractorsCount = 0;

            const uniqueDetractors = new Map();
            const uniquePassives = new Map();
            const uniquePromoters = new Map();

            filteredSubmissions.forEach(submission => {
                const score = submission.score;
                const uid = submission.uid;
                const email = uidToEmailMap[uid] || 'Email not found';
                const submissionWithEmail = { ...submission, email };

                if (score >= 9) {
                    promotersCount += 1;
                    if (!uniquePromoters.has(uid)) {
                        uniquePromoters.set(uid, submissionWithEmail);
                    }
                } else if (score >= 7) {
                    passivesCount += 1;
                    if (!uniquePassives.has(uid)) {
                        uniquePassives.set(uid, submissionWithEmail);
                    }
                } else {
                    detractorsCount += 1;
                    if (!uniqueDetractors.has(uid)) {
                        uniqueDetractors.set(uid, submissionWithEmail);
                    }
                }
            });

            // Set unique arrays for display
            setPromoters([...uniquePromoters.values()]);
            setPassives([...uniquePassives.values()]);
            setDetractors([...uniqueDetractors.values()]);

            // Calculate the percentages for NPS calculation
            const totalResponses = filteredSubmissions.length;
            const promotersPercentage = (promotersCount / totalResponses) * 100;
            const passivesPercentage = (passivesCount / totalResponses) * 100;
            const detractorsPercentage = (detractorsCount / totalResponses) * 100;

            setPromotersPercentage(promotersPercentage.toFixed(2));
            setPassivesPercentage(passivesPercentage.toFixed(2));
            setDetractorsPercentage(detractorsPercentage.toFixed(2));

            // Calculate NPS Score
            const nps = promotersPercentage - detractorsPercentage;
            setNpsScore(nps.toFixed(2));

            console.log(`Promoters: ${promotersCount}, Passives: ${passivesCount}, Detractors: ${detractorsCount}`);
            console.log('NPS calculation completed.');
        } catch (error) {
            console.error('Error fetching submissions or user emails:', error);
            alert(error.message || 'An error occurred while fetching data. Please check the console for details.');
        } finally {
            setLoading(false);
        }
    };

    const downloadAsExcel = () => {
        const data = [];
    
        // Create sets to track unique UIDs in each category
        const detractorsUIDs = new Set(detractors.map(submission => submission.uid));
        const passivesUIDs = new Set(passives.map(submission => submission.uid));
        const promotersUIDs = new Set(promoters.map(submission => submission.uid));
    
        // Remove UIDs from passives and promoters that also exist in detractors
        passivesUIDs.forEach(uid => {
            if (detractorsUIDs.has(uid)) {
                passivesUIDs.delete(uid);
            }
        });
    
        promotersUIDs.forEach(uid => {
            if (detractorsUIDs.has(uid) || passivesUIDs.has(uid)) {
                promotersUIDs.delete(uid);
            }
        });
    
        // Prepare the data array with only unique users in each category
        detractors.forEach(submission => {
            if (detractorsUIDs.has(submission.uid)) {
                data.push({
                    Email: submission.email,
                    Score: submission.score,
                    Category: 'Detractor'
                });
            }
        });
    
        passives.forEach(submission => {
            if (passivesUIDs.has(submission.uid)) {
                data.push({
                    Email: submission.email,
                    Score: submission.score,
                    Category: 'Passive'
                });
            }
        });
    
        promoters.forEach(submission => {
            if (promotersUIDs.has(submission.uid)) {
                data.push({
                    Email: submission.email,
                    Score: submission.score,
                    Category: 'Promoter'
                });
            }
        });
    
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'NPS Data');
    
        XLSX.writeFile(workbook, `NPS_Data_${selectedMonth}_${selectedYear}.xlsx`);
        console.log('Excel file downloaded.');
    };
    
    return (
        <div className="mb-6 p-6 border rounded shadow-md bg-white">
            <h3 className="text-2xl font-bold mb-6 text-center">NPS Submissions Overview</h3>
            <div className="mb-6 flex flex-col md:flex-row items-center justify-center gap-4">
                <div className="flex flex-col items-start">
                    <label className="text-lg font-medium mb-2">Select Month:</label>
                    <select
                        value={selectedMonth}
                        onChange={(e) => setSelectedMonth(e.target.value)}
                        className="border p-2 rounded w-40"
                    >
                        <option value="">--Select Month--</option>
                        <option value="1">January</option>
                        <option value="2">February</option>
                        <option value="3">March</option>
                        <option value="4">April</option>
                        <option value="5">May</option>
                        <option value="6">June</option>
                        <option value="7">July</option>
                        <option value="8">August</option>
                        <option value="9">September</option>
                        <option value="10">October</option>
                        <option value="11">November</option>
                        <option value="12">December</option>
                    </select>
                </div>
                <div className="flex flex-col items-start">
                    <label className="text-lg font-medium mb-2">Select Year:</label>
                    <input
                        type="number"
                        value={selectedYear}
                        onChange={(e) => setSelectedYear(e.target.value)}
                        placeholder="e.g., 2024"
                        className="border p-2 rounded w-28"
                    />
                </div>
            </div>
            <div className="flex justify-center mb-6">
                <button
                    onClick={fetchSubmissionsByMonth}
                    disabled={loading}
                    className={`px-6 py-3 rounded text-white font-semibold ${loading ? 'bg-gray-500 cursor-not-allowed' : 'bg-blue-500 hover:bg-blue-600'}`}
                >
                    {loading ? 'Loading...' : 'Fetch Submissions by Month'}
                </button>
                <button
                    onClick={downloadAsExcel}
                    disabled={totalSubmissions === 0}
                    className={`ml-4 px-6 py-3 rounded text-white font-semibold ${totalSubmissions === 0 ? 'bg-gray-400 cursor-not-allowed' : 'bg-green-500 hover:bg-green-600'}`}
                >
                    Download as Excel
                </button>
            </div>
            <div className="text-center mb-6">
                <p className="text-lg"><strong>Total Submissions:</strong> {totalSubmissions}</p>
                <p className="text-lg"><strong>Net Promoter Score:</strong> {npsScore}</p>
                <p className="text-lg"><strong>Detractors Percentage:</strong> {detractorsPercentage}%</p>
                <p className="text-lg"><strong>Passives Percentage:</strong> {passivesPercentage}%</p>
                <p className="text-lg"><strong>Promoters Percentage:</strong> {promotersPercentage}%</p>
            </div>

            {/* Display sections for Detractors, Passives, and Promoters */}
            {detractors.length > 0 && (
                <div className="mt-6">
                    <h4 className="text-xl font-semibold mb-4 text-red-700">Detractors (Score 6 or Below):</h4>
                    <ul className="space-y-2">
                        {detractors.map(submission => (
                            <li key={submission.id} className="p-4 border rounded bg-red-50 flex justify-between items-center">
                                <span>{submission.email}</span>
                                <span className="font-medium">Score: {submission.score}</span>
                            </li>
                        ))}
                    </ul>
                </div>
            )}

            {passives.length > 0 && (
                <div className="mt-6">
                    <h4 className="text-xl font-semibold mb-4 text-yellow-700">Passives (Score 7-8):</h4>
                    <ul className="space-y-2">
                        {passives.map(submission => (
                            <li key={submission.id} className="p-4 border rounded bg-yellow-50 flex justify-between items-center">
                                <span>{submission.email}</span>
                                <span className="font-medium">Score: {submission.score}</span>
                            </li>
                        ))}
                    </ul>
                </div>
            )}

            {promoters.length > 0 && (
                <div className="mt-6">
                    <h4 className="text-xl font-semibold mb-4 text-green-700">Promoters (Score 9-10):</h4>
                    <ul className="space-y-2">
                        {promoters.map(submission => (
                            <li key={submission.id} className="p-4 border rounded bg-green-50 flex justify-between items-center">
                                <span>{submission.email}</span>
                                <span className="font-medium">Score: {submission.score}</span>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default NpsOverview;
