// templateUtils.jsx
import { useEffect } from 'react';
import { doc, collection, getDoc, getDocs } from 'firebase/firestore';
import { firestore, auth } from '../../../firebase';

export const fetchTemplates = async (setExamTemplates) => {
    const uid = auth.currentUser.uid;
    const basicExamRef = doc(firestore, 'customers', uid, 'templates', 'Basic Exam');
    const basicExamSnap = await getDoc(basicExamRef);
    let basicExamTemplate = {};
    if (basicExamSnap.exists()) {
        basicExamTemplate = { 'Basic Exam': { label: 'Basic Exam', icon: null, ...basicExamSnap.data() }};
    }
    const templatesRef = collection(firestore, 'customers', uid, 'exam_templates');
    const snapshot = await getDocs(templatesRef);
    const fetchedTemplates = {};
    snapshot.forEach(doc => {
        fetchedTemplates[doc.id] = { label: doc.id, icon: null, ...doc.data() };
    });
    const allTreatments = { ...basicExamTemplate, ...fetchedTemplates };
    setExamTemplates(allTreatments);
};

export const handleSelectTemplate = (templateKey, examTemplates, setSelectedExamTemplate, setSearchInput, setFilteredTemplates, setFocusedItemIndex) => {
    const template = examTemplates[templateKey];
    if (template) {
        setSelectedExamTemplate(template);
        setSearchInput(template.label);
        setFilteredTemplates([]);
        setFocusedItemIndex(-1);
    }
};

export const selectTemplate = (templateKey, examTemplates, setSelectedExamTemplate, setSearchInput, setFilteredTemplates, setFocusedItemIndex) => {
    const template = examTemplates[templateKey];
    if (template) {
        setSelectedExamTemplate(template);
        setSearchInput(template.label);
        setFilteredTemplates([]);
        setFocusedItemIndex(-1);
    }
};

export const useFetchTemplatesEffect = (setExamTemplates) => {
    useEffect(() => {
        fetchTemplates(setExamTemplates);
    }, []);
};

// export const useFetchTemplatesEffect = (setExamTemplates, setSelectedExamTemplate, setSearchInput) => {
//     useEffect(() => {
//         const fetchAndSetDefaultTemplate = async () => {
//             await fetchTemplates((fetchedTemplates) => {
//                 setExamTemplates(fetchedTemplates);

//                 // Check if "Basic Exam" exists and set it as the default template
//                 if (fetchedTemplates["Basic Exam"]) {
//                     setSelectedExamTemplate(fetchedTemplates["Basic Exam"]);
//                     setSearchInput("Basic Exam"); // Show "Basic Exam" in the input field
//                 }
//             });
//         };

//         fetchAndSetDefaultTemplate();
//     }, [setExamTemplates, setSelectedExamTemplate, setSearchInput]);
// };