import React, { useState, useEffect } from 'react';
import { doc, getDoc, setDoc, deleteDoc } from 'firebase/firestore';
import { firestore, auth } from '../../firebase';
import './settings.css';

const defaultAnaesthetics = {
  Lidocaine: { batchNo: '', expiry: '', volume: '', percentage: '', concentration: '' },
  Articaine: { batchNo: '', expiry: '', volume: '', percentage: '', concentration: '' },
  Citanest: { batchNo: '', expiry: '', volume: '', percentage: '', concentration: '' },
  Mepivacaine: { batchNo: '', expiry: '', volume: '', percentage: '' },
  Bupivacaine: { batchNo: '', expiry: '', volume: '', percentage: '', concentration: '' },
  Prilocaine: { batchNo: '', expiry: '', volume: '', percentage: '', concentration: '' },
};

const LocalAnaesthetic = () => {
  const [selectedAnaesthetic, setSelectedAnaesthetic] = useState('');
  const [anaesthetics, setAnaesthetics] = useState(defaultAnaesthetics);
  const [isAnaestheticsVisible, setIsAnaestheticsVisible] = useState(false);
  const [newAnaestheticName, setNewAnaestheticName] = useState('');

  const toggleAnaestheticsVisibility = () => {
    setIsAnaestheticsVisible(prev => !prev); 
  };

  useEffect(() => {
    const loadAnaesthetics = async () => {
      const uid = auth.currentUser.uid;
      const anaestheticRef = doc(firestore, 'customers', uid, 'templates', 'anaesthetics');
      const anaestheticSnap = await getDoc(anaestheticRef);
      if (anaestheticSnap.exists()) {
        setAnaesthetics(anaestheticSnap.data());
      }
    };
    loadAnaesthetics();
  }, []);
  

  const handleAnaestheticSelection = (type) => {
    setSelectedAnaesthetic(type);
  };

  const handleInputChange = (type, field, value) => {
    setAnaesthetics(prev => ({
      ...prev,
      [type]: { ...prev[type], [field]: value }
    }));
  };

  const handleSaveAnaesthetic = async () => {
    const uid = auth.currentUser.uid;
    const anaestheticRef = doc(firestore, 'customers', uid, 'templates', 'anaesthetics');
    try {
      await setDoc(anaestheticRef, anaesthetics);
      console.log('Anaesthetic saved successfully');
      alert('LA saved successfully');
    } catch (error) {
      console.error('Error saving anaesthetic:', error);
    }
  };

  const handleDeleteAnaesthetics = async () => {
    const confirmed = window.confirm("Are you sure you want to clear all batch numbers and expiries?");
    if (!confirmed) {
      return; // Early return if the user clicks Cancel
    }
  
    const uid = auth.currentUser.uid;
    const anaestheticRef = doc(firestore, 'customers', uid, 'templates', 'anaesthetics');
    try {
      await deleteDoc(anaestheticRef);
      setAnaesthetics({});
      console.log('Anaesthetic record deleted successfully');
      alert('Batch numbers and expiries cleared');
    } catch (error) {
      console.error('Error deleting LA record:', error);
    }
  };

  const handleAddAnaesthetic = async () => {
    const trimmedAnaestheticName = newAnaestheticName.trim();
    if (trimmedAnaestheticName && !anaesthetics[trimmedAnaestheticName]) {
      const updatedAnaesthetics = {
        ...anaesthetics,
        [trimmedAnaestheticName]: { batchNo: '', expiry: '', volume: '', percentage: '', concentration: '' },
      };
      setAnaesthetics(updatedAnaesthetics);
      setNewAnaestheticName('');

      const uid = auth.currentUser.uid;
      const anaestheticRef = doc(firestore, 'customers', uid, 'templates', 'anaesthetics');
      try {
        await setDoc(anaestheticRef, updatedAnaesthetics);
        console.log('Anaesthetic added successfully');
      } catch (error) {
        console.error('Error adding anaesthetic:', error);
      }
    } else {
      alert('Invalid or duplicate anaesthetic name');
    }
  };

  const handleDeleteSingleAnaesthetic = async () => {
    const confirmed = window.confirm(`Are you sure you want to delete the anesthetic "${selectedAnaesthetic}"? This action cannot be undone.`);
    if (!confirmed) {
      return; // Early return if the user clicks Cancel
    }

    const updatedAnaesthetics = { ...anaesthetics };
    delete updatedAnaesthetics[selectedAnaesthetic];

    const uid = auth.currentUser.uid;
    const anaestheticRef = doc(firestore, 'customers', uid, 'templates', 'anaesthetics');
    try {
      await setDoc(anaestheticRef, updatedAnaesthetics);
      console.log('Anaesthetic deleted successfully');
    } catch (error) {
      console.error('Error deleting anaesthetic:', error);
    }

    const remainingAnaesthetics = Object.keys(updatedAnaesthetics);
    setSelectedAnaesthetic(remainingAnaesthetics.length > 0 ? remainingAnaesthetics[0] : '');
    setAnaesthetics(updatedAnaesthetics);
  };

  const handleResetToDefault = async () => {
    const confirmed = window.confirm("Are you sure you want to reset to default? This action cannot be undone.");
    if (!confirmed) {
      return; // Early return if the user clicks Cancel
    }

    const uid = auth.currentUser.uid;
    const anaestheticRef = doc(firestore, 'customers', uid, 'templates', 'anaesthetics');
    try {
      await deleteDoc(anaestheticRef);
      setAnaesthetics(defaultAnaesthetics);
      setSelectedAnaesthetic('');
      console.log('Reset to default successfully');
      alert('Reset to default successfully');
    } catch (error) {
      console.error('Error resetting to default:', error);
    }
  };

  return (
    <main className="w-full p-6">
      {/* Top Bar: Add New Anaesthetic */}
      <div className="flex items-center justify-between mb-8">
        <input
          type="text"
          placeholder="New Anaesthetic Name"
          value={newAnaestheticName}
          onChange={(e) => setNewAnaestheticName(e.target.value)}
          className="flex-grow p-3 border border-gray-300 rounded-lg bg-gray-50 text-sm focus:outline-none focus:ring-2 focus:ring-primary-600"
        />
        <button
          onClick={handleAddAnaesthetic}
          className="ml-4 px-5 py-3 bg-gradient-to-br from-primary-400 to-teal-200 text-white font-medium rounded-lg hover:bg-primary-700 transition-colors"
        >
          Add Anaesthetic
        </button>
      </div>

  
      {/* Anaesthetic List and Editor */}
      <div className="flex flex-col md:flex-row">
        {/* Anaesthetic List */}
        <div className="md:w-1/3 md:pr-4 mb-6 md:mb-0">
          <div className="bg-white rounded-lg shadow p-4">
            <h3 className="text-lg font-semibold mb-4">Anaesthetics</h3>
            <ul className="space-y-2 max-h-[448px] overflow-y-auto">
              {Object.keys(anaesthetics).length > 0 ? (
                Object.keys(anaesthetics)
                  .sort() // Sort the keys alphabetically
                  .map((anaestheticName) => (
                    <li key={anaestheticName}>
                      <button
                        onClick={() => handleAnaestheticSelection(anaestheticName)}
                        className={`w-full text-left px-3 py-2 rounded-md ${
                          selectedAnaesthetic === anaestheticName
                            ? 'bg-primary-100 text-primary-700'
                            : 'hover:bg-gray-100'
                        }`}
                      >
                        {anaestheticName}
                      </button>
                    </li>
                  ))
              ) : (
                <p className="text-sm text-gray-500">No anaesthetics found.</p>
              )}
            </ul>
          </div>
        </div>
  
        {/* Anaesthetic Editor */}
        <div className="md:w-2/3">
          {selectedAnaesthetic ? (
            <div className="bg-white rounded-lg shadow p-4">
              <h3 className="text-lg font-semibold mb-4">Edit {selectedAnaesthetic}</h3>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <label className="block mb-1 text-sm font-medium text-gray-700">Batch No.</label>
                  <input
                    type="text"
                    placeholder="Batch No."
                    value={anaesthetics[selectedAnaesthetic]?.batchNo || ''}
                    onChange={(e) => handleInputChange(selectedAnaesthetic, 'batchNo', e.target.value)}
                    className="w-full p-2 border border-gray-300 rounded-lg bg-white text-sm focus:outline-none focus:ring-2 focus:ring-primary-600"
                  />
                </div>
                <div>
                  <label className="block mb-1 text-sm font-medium text-gray-700">Expiry Date</label>
                  <input
                    type="month"
                    placeholder="Expiry Date"
                    value={anaesthetics[selectedAnaesthetic]?.expiry || ''}
                    onChange={(e) => handleInputChange(selectedAnaesthetic, 'expiry', e.target.value)}
                    className="w-full p-2 border border-gray-300 rounded-lg bg-white text-sm focus:outline-none focus:ring-2 focus:ring-primary-600"
                  />
                </div>
                <div>
                  <label className="block mb-1 text-sm font-medium text-gray-700">Volume</label>
                  <input
                    type="text"
                    placeholder="Enter Volume"
                    value={anaesthetics[selectedAnaesthetic]?.volume || ''}
                    onChange={(e) => handleInputChange(selectedAnaesthetic, 'volume', e.target.value)}
                    className="w-full p-2 border border-gray-300 rounded-lg bg-white text-sm focus:outline-none focus:ring-2 focus:ring-primary-600"
                  />
                </div>
                <div>
                  <label className="block mb-1 text-sm font-medium text-gray-700">Percentage</label>
                  <input
                    type="number"
                    placeholder="Percentage"
                    value={anaesthetics[selectedAnaesthetic]?.percentage || ''}
                    onChange={(e) => handleInputChange(selectedAnaesthetic, 'percentage', e.target.value)}
                    className="w-full p-2 border border-gray-300 rounded-lg bg-white text-sm focus:outline-none focus:ring-2 focus:ring-primary-600"
                  />
                </div>
                {selectedAnaesthetic !== 'Mepivacaine' && (
                  <div className="md:col-span-2">
                    <label className="block mb-1 text-sm font-medium text-gray-700">Concentration</label>
                    <input
                      type="text"
                      placeholder="Concentration"
                      value={anaesthetics[selectedAnaesthetic]?.concentration || ''}
                      onChange={(e) => handleInputChange(selectedAnaesthetic, 'concentration', e.target.value)}
                      className="w-full p-2 border border-gray-300 rounded-lg bg-white text-sm focus:outline-none focus:ring-2 focus:ring-primary-600"
                    />
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className="bg-white rounded-lg shadow p-4">
              <p className="text-gray-500">Select an anaesthetic to edit or create a new one.</p>
            </div>
          )}
        </div>
      </div>
  
      {/* Action Buttons */}
      <div className="flex flex-wrap justify-center gap-4 mt-4">
        <button
          onClick={handleSaveAnaesthetic}
          className="px-6 py-3 bg-gradient-to-br from-primary-400 to-teal-200 text-white font-medium rounded-lg hover:bg-primary-700"
        >
          Save
        </button>
        <button
          onClick={handleDeleteAnaesthetics}
          className="px-6 py-3 bg-gray-400 text-white font-medium rounded-lg hover:bg-red-600"
        >
          Clear All Batches & Expiries
        </button>
        <button
          onClick={handleResetToDefault}
          className="px-6 py-3 bg-gray-300 text-gray-700 font-medium rounded-lg hover:bg-gray-400"
        >
          Reset to Default
        </button>
        {selectedAnaesthetic && (
          <button
            onClick={handleDeleteSingleAnaesthetic}
            className="px-6 py-3 bg-red-400 text-white font-medium rounded-lg hover:bg-red-500"
          >
            Delete
          </button>
        )}
      </div>
    </main>
  );  
};

export default LocalAnaesthetic;