import React, { useState, useEffect } from 'react';
import { getStorage, ref, uploadBytes } from 'firebase/storage';
import './AudioRecorder.css';
import { doc, onSnapshot, collection, query, where, getDocs, updateDoc } from 'firebase/firestore';
import { firestore, auth } from '../../firebase';
import { useParams } from 'react-router-dom';

const AudioRecorder = ({ updatePatientNotes }) => {
  const [isRecording, setIsRecording] = useState(false);
  const [loading, setLoading] = useState(false);
  const [transcribedNote, setTranscribedNote] = useState('');
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [audioChunks, setAudioChunks] = useState([]);
  const [canStopRecording, setCanStopRecording] = useState(false);
  const [audioData, setAudioData] = useState(null);
  const { patientId } = useParams();

  const sendAudioToCloudFunction = async (filePath) => {
    try {
        setLoading(true); // Set loading to true when operation starts
        const response = await fetch('https://us-central1-digitaltco-c40e9.cloudfunctions.net/whisper', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ filePath }),
        });
        const result = await response.json();
        console.log('Received response:', result);
        setTranscribedNote(result.text);
        setIsRecording(false);
        updatePatientNotes({ recording: result.text });
    } catch (error) {
        console.error('Error sending audio URL:', error);
    } finally {
        setLoading(false); // Reset loading to false when operation ends
    }
  };

  const startRecording = async () => {
    try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        const newMediaRecorder = new MediaRecorder(stream);
        console.log('MediaRecorder started');
        setCanStopRecording(true);

        newMediaRecorder.ondataavailable = (event) => {
            audioChunks.push(event.data);
        };

        newMediaRecorder.onstop = async () => {
            console.log('Recording stopped, creating audio blob');
            const audioBlob = new Blob(audioChunks, { type: 'audio/webm' });
            console.log('Audio blob created:', audioBlob.size);

            if (audioBlob.size > 0) {
                const uid = auth.currentUser.uid;
                const filePath = `fullnote/${uid}/${Date.now()}.webm`; // Removed treatmentId
                const storageRef = ref(getStorage(), filePath);
                await uploadBytes(storageRef, audioBlob);
                setAudioData(filePath); // Set the path for useEffect dependency
            }
        };

        setMediaRecorder(newMediaRecorder);
        newMediaRecorder.start();
        setIsRecording(true);
    } catch (error) {
        console.error('Error in starting recording:', error);
    }
  };

  const stopRecording = () => {
    if (mediaRecorder) {
        console.log('Stopping recording');
        mediaRecorder.stop();
        setIsRecording(false);
        mediaRecorder.stream.getTracks().forEach(track => track.stop());
        setCanStopRecording(false); 
    }
  };

  useEffect(() => {
    if (audioData) {
        console.log('Audio Blob is set, now sending to cloud function');
        sendAudioToCloudFunction(audioData);
    }
  }, [audioData]);

  const saveTranscriptionToFirestore = async () => {
    setLoading(true);
    const patientRef = doc(firestore, 'customers', auth.currentUser.uid, 'patients', patientId);
     // Start loading while saving

    try {
      console.log("Transcribed Note:", transcribedNote);
      await updateDoc(patientRef, {
        'notes.recording': transcribedNote, // Assuming 'notes.recording' is the correct path
      });

      updatePatientNotes({ recording: transcribedNote });

      console.log("Transcription saved successfully");
    } catch (error) {
      console.error("Error saving transcription:", error);
    } finally {
      setLoading(false); // Stop loading whether saving is successful or not
      setTranscribedNote('');
    }
  };

  return (
    <div className="audio-recorder-container">
      <textarea
      className="audio-recorder-textarea"
      placeholder="Enter your notes here..."
      value={loading ? "Loading..." : transcribedNote} // Display Loading... when loading
      onChange={(e) => setTranscribedNote(e.target.value)}
      readOnly={isRecording || loading}
    ></textarea>
     
      <div className="button-container">
        <button
          onClick={startRecording}
          disabled={isRecording}
          className={`audio-recorder-button ${isRecording ? "recording" : ""}`}
        >
          {isRecording ? "Recording..." : "Start Rec"}
        </button>
        <button
          className="audio-recorder-button"
          onClick={stopRecording}
          disabled={!canStopRecording}
        >
          Stop Rec
        </button>
        <button 
          className="audio-recorder-button"
          onClick={saveTranscriptionToFirestore}
          disabled={loading}
        >
          Save
        </button>
      </div>
    </div>
  );     
};

export default AudioRecorder;
