import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Stylesheet for ReactQuill
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

const CustomTextForm = ({ id, content, isDynamic, onChange }) => {
    const [text, setText] = useState(content || '');
    const [dynamic, setDynamic] = useState(isDynamic || false);
    const [isOpen, setIsOpen] = useState(true);

    useEffect(() => {
        setText(content || '');
        setDynamic(isDynamic || false);
    }, [content, isDynamic]);

    const toggleOpen = () => {
        setIsOpen(!isOpen);
    };

    const handleTextChange = (value) => {
        setText(value);
        onChange(id, value, dynamic);
    };

    const handleDynamicChange = () => {
        const newDynamic = !dynamic;
        setDynamic(newDynamic);
        onChange(id, text, newDynamic);
    };

    return (
        <div className="p-4">
            <div className="flex items-center justify-between w-full p-2 font-medium text-gray-500">
                <span>Custom Text</span>
                <button onClick={toggleOpen} className="hover:text-malachite-500">
                    <FontAwesomeIcon icon={isOpen ? faChevronUp : faChevronDown} />
                </button>
            </div>
            {isOpen && (
                <div className="p-2">
                    <div className="mb-4">
                        <label className="flex items-center">
                            <input
                                type="checkbox"
                                className="mr-2"
                                checked={dynamic}
                                onChange={handleDynamicChange}
                            />
                            <span className="text-sm text-gray-400">
                                AI will rewrite this text.
                            </span>
                        </label>
                    </div>
                    <div className="mb-4">
                        <label className="block mb-2 text-xs text-primary-400">
                            {dynamic ? 'Dynamic Text' : 'Stock Text'}
                        </label>
                        <ReactQuill
                            theme="snow"
                            value={text}
                            onChange={handleTextChange}
                            placeholder="Type your text here"
                            modules={CustomTextForm.modules}
                            formats={CustomTextForm.formats}
                            style={{ height: '200px' }}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
  
  
  CustomTextForm.modules = {
    toolbar: {
      container: [
        [{'header': [2, 3, false]}],
        [{'list': 'bullet'},],
      ],
    },
    
  };
  
  CustomTextForm.formats = [
    'header', 'list'
  ];

export default CustomTextForm;
