import React, { useState, useEffect } from 'react';
import { collection, getDocs, query, doc, addDoc, onSnapshot, deleteDoc, where } from 'firebase/firestore';
import { firestore, auth } from '../../firebase';
import { loadStripe } from '@stripe/stripe-js';
import './ProtrusivePayment.css';

const ProtrusivePaymentPage = () => {
  const [products, setProducts] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const specificProductId = 'prod_PjjN7P8dG7mvw9';
  const gbpPriceId = 'price_1OuFujDGxaME3o6cF6FNVVUJ';

  useEffect(() => {
    const fetchProductsAndPrices = async () => {
      const productsCollection = collection(firestore, 'products');
      const q = query(productsCollection, where('active', '==', true));
      const snapshot = await getDocs(q);
  
      let fetchedProducts = {};
  
      for (const productDoc of snapshot.docs) {
        const productId = productDoc.id;
        fetchedProducts[productId] = productDoc.data();
  
        const pricesCollection = collection(doc(firestore, 'products', productId), 'prices');
        const priceSnapshot = await getDocs(pricesCollection);
  
        fetchedProducts[productId].prices = []; // Initialize as an array
  
        priceSnapshot.docs.forEach(priceDoc => {
          fetchedProducts[productId].prices.push({
            priceId: priceDoc.id,
            priceData: priceDoc.data(),
            currency: priceDoc.data().currency,
          });
        });
      }
  
      setProducts(fetchedProducts);
      console.log('Fetched Products:', fetchedProducts);
    };
  
    fetchProductsAndPrices().catch(err => {
      console.error("Error fetching products and prices:", err);
    });
  }, []);
  
  const checkOut = async () => {
    setIsLoading(true);
    const user = auth.currentUser;
    console.log('User:', user);
    console.log('Products:', products);

    if (user && products[specificProductId]) {
      const matchingPriceId = gbpPriceId;  // Always use the GBP price ID
  
      const checkoutSessionCollection = collection(doc(firestore, 'customers', user.uid), 'checkout_sessions');
      
      // Delete existing checkout sessions
      const sessionsSnapshot = await getDocs(checkoutSessionCollection);
      for (const doc of sessionsSnapshot.docs) {
        await deleteDoc(doc.ref);
      }
  
      // Handle canceled subscriptions if necessary
      const subscriptionsCollection = collection(firestore, 'customers', user.uid, 'subscriptions');
      const subscriptionsSnapshot = await getDocs(subscriptionsCollection);
      for (const subDoc of subscriptionsSnapshot.docs) {
        const subscriptionData = subDoc.data();
        if (subscriptionData.status === 'canceled') {
          await deleteDoc(subDoc.ref); // Consider only deleting if the cancelation is final
        }
      }
  
      const successUrl = `${window.location.origin}/payment-success`;
  
      const payload = {
        mode: 'subscription',
        payment_method_types: ['card'],
        line_items: [
          {
            price: matchingPriceId,
            quantity: 1,
          },
        ],
        success_url: successUrl,
        cancel_url: window.location.origin,
      };
  
      try {
        const docRef = await addDoc(checkoutSessionCollection, payload);
        const checkoutSessionDocRef = doc(firestore, 'customers', user.uid, 'checkout_sessions', docRef.id);
        onSnapshot(checkoutSessionDocRef, async (snap) => {
          const { error, sessionId } = snap.data();
          if (error) {
            alert(error.message);
            setIsLoading(false);
          }
          if (sessionId) {
            const stripe = await loadStripe("pk_live_51NwhHfDGxaME3o6c1NlU5EkPLihJW5vzOw7ogz1Ayx9gdKKadXopC9LAfMBERgCO6F9m3PZ2NeW2uoQ0TLfPX6FB00bqeviOsh");
            stripe.redirectToCheckout({ sessionId });
            setIsLoading(false);
          }
        });
      } catch (error) {
        console.error("Error creating checkout session:", error);
        setIsLoading(false);
      }
    } else {
      console.error("Product or price information is missing.");
      setIsLoading(false);
    }
  };
  
  return (
    <div className="payment-body">
      <div className="payment-container">
        <h1 className="payment-title">DigitalTCO</h1>
        <h3 className="payment-subheader">Unlock a New Era of Dental Efficiency!</h3>
        <p className="payment-p">Embark on a transformative journey with a Protruserati Special Offer:</p>
        <h1 className="payment-header">28-Day FREE Trial</h1>
        <p>
          then only <s>£79.90</s> £39.95/month!
        </p>

        <h3 className="payment-subheader">Why Choose DigitalTCO?</h3>
        <div className="benefit-container-payment">
          <p className="payment-benefit">
            <span className="checkmark">✓</span> Super Dentist Cheat Mode
          </p>
          <p className="payment-benefit">
            <span className="checkmark">✓</span> Exams & Consults Note Generation
          </p>
          <p className="payment-benefit">
            <span className="checkmark">✓</span> Easy Treatment Notes
          </p>            
          <p className="payment-benefit">
            <span className="checkmark">✓</span> Automated Consent Forms
          </p>
          <p className="payment-benefit">
            <span className="checkmark">✓</span> Automated Letters to Patients
          </p>
          <p className="payment-benefit">
            <span className="checkmark">✓</span> Automated Referral Letters
          </p>
          <p className="payment-benefit">
            <span className="checkmark">✓</span> Designed by Dentists
          </p>
          <p className="payment-benefit">
            <span className="checkmark">✓</span> Community-Driven
          </p>
        </div>
        
        {products[specificProductId] && (
          <div className="plans">
            {isLoading ? (
              <div className="payment-loading">
                <div className="payment-loading-bar">
                  <p>Loading...</p>
                </div>
              </div>
            ) : (
              <button
                className="payment-button"
                onClick={checkOut}
              >
                Click Here To Claim Your 28 Day FREE Trial Now!
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ProtrusivePaymentPage;
