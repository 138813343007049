export const defaultTeethDataPalmer = [
    { number: 'UR8', description: '', segments: { occlusal: '', mesial: '', distal: '', buccal: '', lingual: '' }},
    { number: 'UR7', description: '', segments: { occlusal: '', mesial: '', distal: '', buccal: '', lingual: '' }},
    { number: 'UR6', description: '', segments: { occlusal: '', mesial: '', distal: '', buccal: '', lingual: '' }},
    { number: 'UR5', description: '', segments: { occlusal: '', mesial: '', distal: '', buccal: '', lingual: '' }},
    { number: 'UR4', description: '', segments: { occlusal: '', mesial: '', distal: '', buccal: '', lingual: '' }},
    { number: 'UR3', description: '', segments: { occlusal: '', mesial: '', distal: '', buccal: '', lingual: '' }},
    { number: 'UR2', description: '', segments: { occlusal: '', mesial: '', distal: '', buccal: '', lingual: '' }},
    { number: 'UR1', description: '', segments: { occlusal: '', mesial: '', distal: '', buccal: '', lingual: '' }},
    { number: 'UL1', description: '', segments: { occlusal: '', mesial: '', distal: '', buccal: '', lingual: '' }},
    { number: 'UL2', description: '', segments: { occlusal: '', mesial: '', distal: '', buccal: '', lingual: '' }},
    { number: 'UL3', description: '', segments: { occlusal: '', mesial: '', distal: '', buccal: '', lingual: '' }},
    { number: 'UL4', description: '', segments: { occlusal: '', mesial: '', distal: '', buccal: '', lingual: '' }},
    { number: 'UL5', description: '', segments: { occlusal: '', mesial: '', distal: '', buccal: '', lingual: '' }},
    { number: 'UL6', description: '', segments: { occlusal: '', mesial: '', distal: '', buccal: '', lingual: '' }},
    { number: 'UL7', description: '', segments: { occlusal: '', mesial: '', distal: '', buccal: '', lingual: '' }},
    { number: 'UL8', description: '', segments: { occlusal: '', mesial: '', distal: '', buccal: '', lingual: '' }},
    { number: 'LL8', description: '', segments: { occlusal: '', mesial: '', distal: '', buccal: '', lingual: '' }},
    { number: 'LL7', description: '', segments: { occlusal: '', mesial: '', distal: '', buccal: '', lingual: '' }},
    { number: 'LL6', description: '', segments: { occlusal: '', mesial: '', distal: '', buccal: '', lingual: '' }},
    { number: 'LL5', description: '', segments: { occlusal: '', mesial: '', distal: '', buccal: '', lingual: '' }},
    { number: 'LL4', description: '', segments: { occlusal: '', mesial: '', distal: '', buccal: '', lingual: '' }},
    { number: 'LL3', description: '', segments: { occlusal: '', mesial: '', distal: '', buccal: '', lingual: '' }},
    { number: 'LL2', description: '', segments: { occlusal: '', mesial: '', distal: '', buccal: '', lingual: '' }},
    { number: 'LL1', description: '', segments: { occlusal: '', mesial: '', distal: '', buccal: '', lingual: '' }},
    { number: 'LR1', description: '', segments: { occlusal: '', mesial: '', distal: '', buccal: '', lingual: '' }},
    { number: 'LR2', description: '', segments: { occlusal: '', mesial: '', distal: '', buccal: '', lingual: '' }},
    { number: 'LR3', description: '', segments: { occlusal: '', mesial: '', distal: '', buccal: '', lingual: '' }},
    { number: 'LR4', description: '', segments: { occlusal: '', mesial: '', distal: '', buccal: '', lingual: '' }},
    { number: 'LR5', description: '', segments: { occlusal: '', mesial: '', distal: '', buccal: '', lingual: '' }},
    { number: 'LR6', description: '', segments: { occlusal: '', mesial: '', distal: '', buccal: '', lingual: '' }},
    { number: 'LR7', description: '', segments: { occlusal: '', mesial: '', distal: '', buccal: '', lingual: '' }},
    { number: 'LR8', description: '', segments: { occlusal: '', mesial: '', distal: '', buccal: '', lingual: '' }}
];
