// src/components/AdminDashboard/AnalyticsWidget.jsx

import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

const AnalyticsWidget = ({ data }) => {
  const { pageViews, sessions, users } = data;

  return (
    <Card>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Google Analytics
        </Typography>
        <Typography variant="body1">
          Total Users: {users}
        </Typography>
        <Typography variant="body1">
          Total Sessions: {sessions}
        </Typography>
        <ResponsiveContainer width="100%" height={200}>
          <LineChart data={pageViews} margin={{ top: 5, right: 20, left: 10, bottom: 5 }}>
            <XAxis dataKey="date" />
            <YAxis />
            <CartesianGrid stroke="#f5f5f5" />
            <Tooltip />
            <Line type="monotone" dataKey="views" stroke="#ff7300" yAxisId={0} />
          </LineChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  );
};

export default AnalyticsWidget;
