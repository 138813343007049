import React from 'react';

const ChartSummary = () => {
    return (
        <div className="bg-gray-100 p-4 rounded-lg">
            <p className="text-sm text-gray-900">
                The AI will take your charting and treatment planning information and write it in patient friendly language.
            </p>
        </div>
    );
};

export default ChartSummary;
