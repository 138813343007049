export const segmentColors = {
    present: '#FFFFFA',
    missing: '#000000',
    caries: '#FF0000',
    fractured: '#FF4500',
    amalgam: '#808080',
    composite: '#0000FF',
    bonding: '#F08080',
    'glass Ionomer': '#ADD8E6',
    temporary: '#FFFF00',
    ceramic: '#FFA500',
    veneer: '#90EE90',
    porcelain: '#FFC0CB',
    zirconia: '#BA55D3',
    emax: '#D3D3D3',
    'PFM': '#800080',
    gold: '#FFD700',
    'post Core': '#A9A9A9',
    implant: '#B8860B',
    'bridge Abutment': '#00FFFF',
    'bridge Pontic': '#008B8B',
    'bridge Wing': '#48D1CC',
    'RCT': '#8B0000',
    'periapical Abscess': '#DC143C',
    apicectomy: '#FF8C00',
    wear: '#D2B48C',
    erosion: '#FAFAD2',
    attrition: '#CD853F',
    abfraction: '#8B4513',
    abrasion: '#A0522D',
    'ortho Bracket': '#6A5ACD',
    sealant: '#20B2AA',
    'stainless Steel': '#C0C0C0',
    'space Maintainer': '#F0E68C',
    extraction: '#24ff45',
    denture: '#24d3ff',
    default: '#FFFFFF',
};
