import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { doc, updateDoc, getDoc } from 'firebase/firestore';
import { firestore, auth } from '../../firebase';
import PatientProfileNav from './PatientProfileNav';
import './PatientNotesInput.css'; 

const SpecialTests = () => {
  const [specialTests, setSpecialTests] = useState('');
  const [testOptions, setTestOptions] = useState({
    TTP: false, '3 in 1': false, 'Ethyl Chloride': false, 
    'Hot GP': false, 'Electric PT': false, Mobility: false, 
    'Dual arch 3D scans': false, 'Full clinical photos': false, 
    'Aspirational imaging': false, CBCT: false
  });
  const navigate = useNavigate();
  const { patientId } = useParams()

  useEffect(() => {
    const patientRef = doc(firestore, 'customers', auth.currentUser.uid, 'patients', patientId);

    const fetchSpecialTests = async () => {
      const patientDoc = await getDoc(patientRef);
      if (patientDoc.exists() && patientDoc.data().notes.specialTests) {
        setSpecialTests(patientDoc.data().notes.specialTests); // Set the special tests data to the local state
      }
    };

    fetchSpecialTests();
  }, [patientId]);

  const toggleTestOption = (option) => {
    setTestOptions(prev => ({ ...prev, [option]: !prev[option] }));
  };

  const handleNext = async () => {
    const patientRef = doc(firestore, 'customers', auth.currentUser.uid, 'patients', patientId);

    const selectedTestOptionsString = Object.entries(testOptions)
      .filter(([key, value]) => value)
      .map(([key]) => key)
      .join(', ');

    const updateString = `${selectedTestOptionsString} - ${specialTests}`;

    await updateDoc(patientRef, {
      'notes.specialTests': updateString.trim()
    });

    navigate(`/mvp/patient-profile/${patientId}/radiograph-assessment`);
  };


  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleNext();
    }
  };

  return (
    <div className="patient-complaints-container">
      <PatientProfileNav patientId={patientId} />
      <h2 className="patient-complaints-h2">Special Tests</h2>

      <>
      <div className="sub-options">
        {['3 in 1', 'Ethyl Chloride', 'Electric PT','Hot GP'].map(option => (
          <button
            key={option}
            onClick={() => toggleTestOption(option)}
            className={`treatment-button ${testOptions[option] ? 'selected' : ''}`}
          >
            {option.charAt(0).toUpperCase() + option.slice(1)}
          </button>
        ))}
      </div>
      <div className="sub-options">
        {['TTP'].map(option => (
          <button
            key={option}
            onClick={() => toggleTestOption(option)}
            className={`treatment-button ${testOptions[option] ? 'selected' : ''}`}
          >
            {option.charAt(0).toUpperCase() + option.slice(1)}
          </button>
        ))}
      </div>
      <div className="sub-options">
        {['Dual arch 3D scans', 'Full clinical photos', 'Aspirational imaging'].map(option => (
          <button
            key={option}
            onClick={() => toggleTestOption(option)}
            className={`treatment-button ${testOptions[option] ? 'selected' : ''}`}
          >
            {option.charAt(0).toUpperCase() + option.slice(1)}
          </button>
        ))}
      </div>
      <div className="sub-options">
        {['CBCT'].map(option => (
          <button
            key={option}
            onClick={() => toggleTestOption(option)}
            className={`treatment-button ${testOptions[option] ? 'selected' : ''}`}
          >
            {option.charAt(0).toUpperCase() + option.slice(1)}
          </button>
        ))}
      </div>
      </>

      <textarea
        className="patient-complaints-textarea"
        placeholder="Enter additional special tests information here"
        value={specialTests}
        onChange={(e) => setSpecialTests(e.target.value)}
        onKeyPress={handleKeyPress}
        autoFocus
      />

      <button className="patient-complaints-button" onClick={handleNext}>Next</button>
    </div>
  );
};

export default SpecialTests;
