import React, { useState, useEffect } from 'react';
import { collection, getDocs, doc, updateDoc, deleteDoc } from 'firebase/firestore';
import { firestore } from '../../firebase';

const AdminTemplateManager = () => {
  const [templates, setTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [templateContent, setTemplateContent] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        const sharedTemplatesRef = collection(firestore, 'sharedtemplates');
        const templateSnapshot = await getDocs(sharedTemplatesRef);
        const loadedTemplates = templateSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        setTemplates(loadedTemplates);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching templates:', error);
      }
    };

    fetchTemplates();
  }, []);

  const handleTemplateClick = (template) => {
    setSelectedTemplate(template);
    setTemplateContent(template.content);
  };

  const handleContentChange = (e) => {
    setTemplateContent(e.target.value);
  };

  const handleSave = async () => {
    if (!selectedTemplate) return;

    try {
      await updateDoc(doc(firestore, 'sharedtemplates', selectedTemplate.id), {
        content: templateContent,
      });
      alert('Template updated successfully!');
      setSelectedTemplate(null);
      setTemplateContent('');
    } catch (error) {
      console.error('Error saving template:', error);
      alert('Failed to save the template.');
    }
  };

  const handleDelete = async () => {
    if (!selectedTemplate) return;

    try {
      await deleteDoc(doc(firestore, 'sharedtemplates', selectedTemplate.id));
      alert('Template deleted successfully!');
      setTemplates(templates.filter((template) => template.id !== selectedTemplate.id));
      setSelectedTemplate(null);
      setTemplateContent('');
    } catch (error) {
      console.error('Error deleting template:', error);
      alert('Failed to delete the template.');
    }
  };

  return (
    <div className="admin-template-manager">
      <h2 className="text-2xl font-bold mb-4">Admin Template Manager</h2>
      {isLoading ? (
        <p>Loading templates...</p>
      ) : (
        <div className="template-list mb-4">
          <h3 className="text-lg font-semibold mb-2">Select a Template:</h3>
          <ul>
            {templates.map((template) => (
              <li
                key={template.id}
                className="cursor-pointer hover:bg-gray-200 p-2 mb-1 border rounded"
                onClick={() => handleTemplateClick(template)}
              >
                {template.name}
              </li>
            ))}
          </ul>
        </div>
      )}

      {selectedTemplate && (
        <div className="editor-section">
          <h3 className="text-lg font-semibold mb-2">Editing: {selectedTemplate.name}</h3>
          <textarea
            className="w-full p-2 border rounded mb-2"
            rows="10"
            value={templateContent}
            onChange={handleContentChange}
          ></textarea>
          <div className="flex space-x-2">
            <button
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
              onClick={handleSave}
            >
              Save
            </button>
            <button
              className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
              onClick={handleDelete}
            >
              Delete
            </button>
            <button
              className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600"
              onClick={() => setSelectedTemplate(null)}
            >
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminTemplateManager;
