// ResetButton.jsx

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRedo } from '@fortawesome/free-solid-svg-icons';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

const ResetButton = ({ onReset }) => {
  return (
      <>
    <Tooltip 
    id="reset-tooltip" 
    place="bottom" 
    effect="solid" 
    content="Resets the page to create a new note." 
    style={{
        backgroundColor: '#cd66ef', // Use your primary color with opacity
        color: '#fff',
    }} 
/>

    <button
      onClick={onReset}
      className="mr-2 px-2 py-1 text-md font-medium text-primary-400 bg-white border border-gray-200 rounded-lg hover:bg-primary-400 hover:text-white"
      data-tooltip-id="reset-tooltip" 
    >
      <FontAwesomeIcon icon={faRedo} />
    </button>
    </>
  );
};

export default ResetButton;
