// App.js

import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import SignUp from './components/SignUp';
import SignUpPrav from './components/SignUpPrav';
import PaymentPage from './components/PaymentPage';
import PaymentPageCancelled from './components/PaymentPageCancelled';
import PaymentSuccess from './components/PaymentSuccess';
import MVP from './MVP';
import Login from './components/Login';
import LoginForMultiples from './components/LoginForMultiples';
import ForgottenPassword from './components/ForgottenPassword';
import LoggedOut from './components/LoggedOut';
import Profile from './components/Profile';

import TermsAndConditions from './components/TermsAndConditions';
import { SessionProvider } from './components/SessionContext';
import { UserProvider } from './components/UserContext';

import ProtrusiveSignUp from './components/Protrusive/ProtrusiveSignUp'
import ProtrusivePayment from './components/Protrusive/ProtrusivePayment'

function App() {
  return (
    <UserProvider>
    <SessionProvider>
    <Router>
      <div className="App">
        <Routes>
          <Route path="/signup" element={<SignUp />} />
          <Route path="/payment" element={<PaymentPage />} />
          <Route path="/payment-cancelled" element={<PaymentPageCancelled />} />
          <Route path="/payment-success" element={<PaymentSuccess />} />
          
          <Route path="/mvp/*" element={<MVP />} />
      
          <Route path="/login" element={<Login />} />
          <Route path="/log-in" element={<LoginForMultiples />} />
          <Route path="/logged-out" element={<LoggedOut />} />
          <Route path="/forgot-password" element={<ForgottenPassword />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/" element={<SignUp />} />

          <Route path="/prav" element={<SignUpPrav />} />
          <Route path="/protrusive" element={<ProtrusiveSignUp />} />
          <Route path="/protrusive-payment" element={<ProtrusivePayment />} />

          <Route path="/TermsAndConditions" element={<TermsAndConditions />} />


        </Routes>
      </div>
    </Router>
    </SessionProvider>
    </UserProvider>
  );
}

export default App;