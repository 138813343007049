import axios from 'axios';
import { getFirestore, collection, getDocs, query, where } from 'firebase/firestore';

export const fetchMetricsFromFirestore = async () => {
  const firestore = getFirestore();
  const stripeData = [];

  try {
    console.log("Fetching subscription batches...");

    // Fetch all active and canceled subscription batches
    const dashboardCollection = collection(firestore, 'dashboard');
    const allDocsSnapshot = await getDocs(dashboardCollection);

    // Filter and aggregate data from all documents with prefix 'stripeData_active_batch_' and 'stripeData_canceled_batch_'
    allDocsSnapshot.forEach(doc => {
      const docId = doc.id;
      if (docId.startsWith('stripeData_active_batch_') || docId.startsWith('stripeData_canceled_batch_')) {
        const data = doc.data();
        console.log(`Data from ${docId}:`, data);
        stripeData.push(...data.subscriptions);
      }
    });

    console.log("Total subscriptions aggregated:", stripeData.length);
    return { subscriptions: stripeData };
  } catch (error) {
    console.error('Error fetching data from Firestore:', error);
    throw error;
  }
};

export const fetchStripeData = async () => {
  try {
    const response = await axios.get('https://us-central1-digitaltco-c40e9.cloudfunctions.net/fetchAndStoreStripeNew');
    return response.data;
  } catch (error) {
    console.error('Error fetching Stripe data:', error);
    throw error;
  }
};
