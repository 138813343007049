import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { doc, updateDoc, getDoc } from 'firebase/firestore';
import { firestore, auth } from '../../firebase';
import PatientProfileNav from './PatientProfileNav';
import './PatientNotesInput.css'; 

const RadiographAssessment = () => {
  const navigate = useNavigate();
  const { patientId } = useParams();
  const [selectedCaries, setSelectedCaries] = useState([]);
  const [selectedPathology, setSelectedPathology] = useState([]);
  const [maxBoneLossPercentage, setMaxBoneLossPercentage] = useState('');
  const [radiographOptions, setRadiographOptions] = useState({
    '2x BW': false, 'LHS BW': false, 'RHS BW': false, 'Periapical': false,
    'OPG': false, 'Acceptable': false, 'Unacceptable': false
  });
  const [radiographDetails, setRadiographDetails] = useState('');

  // Mapping of tooth numbers to text
  const toothTextMap = {
    1: 'UR8', 2: 'UR7', 3: 'UR6', 4: 'UR5', 5: 'UR4', 6: 'UR3', 7: 'UR2', 8: 'UR1',
    9: 'UL1', 10: 'UL2', 11: 'UL3', 12: 'UL4', 13: 'UL5', 14: 'UL6', 15: 'UL7', 16: 'UL8',
    17: 'LR8', 18: 'LR7', 19: 'LR6', 20: 'LR5', 21: 'LR4', 22: 'LR3', 23: 'LR2', 24: 'LR1',
    25: 'LL1', 26: 'LL2', 27: 'LL3', 28: 'LL4', 29: 'LL5', 30: 'LL6', 31: 'LL7', 32: 'LL8',
  };

  const toggleRadiographOption = (option) => {
    setRadiographOptions(prev => ({ ...prev, [option]: !prev[option] }));
  };

  // Function to handle tooth click for caries
  const handleCariesClick = (tooth) => {
    // Check if the tooth is already selected
    if (selectedCaries.includes(tooth)) {
      // Deselect the tooth by removing it from the selectedCaries array
      setSelectedCaries((prevSelected) => prevSelected.filter((t) => t !== tooth));
    } else {
      // Select the tooth by adding it to the selectedCaries array
      setSelectedCaries((prevSelected) => [...prevSelected, tooth]);
    }
  };

  // Function to handle tooth click for pathology
  const handlePathologyClick = (tooth) => {
    // Check if the tooth is already selected
    if (selectedPathology.includes(tooth)) {
      // Deselect the tooth by removing it from the selectedPathology array
      setSelectedPathology((prevSelected) => prevSelected.filter((t) => t !== tooth));
    } else {
      // Select the tooth by adding it to the selectedPathology array
      setSelectedPathology((prevSelected) => [...prevSelected, tooth]);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleNext();
    }
  };

  useEffect(() => {
    const patientRef = doc(firestore, 'customers', auth.currentUser.uid, 'patients', patientId);

    const fetchRadiographAssessment = async () => {
      const patientDoc = await getDoc(patientRef);
      if (patientDoc.exists() && patientDoc.data().notes.radiographAssessment) {
        const radiographData = patientDoc.data().notes.radiographAssessment;
        // Convert the teeth text back to their respective numbers for caries
        const cariesNumbers = radiographData.cariesAssessment ? radiographData.cariesAssessment.split(', ').map(teeth => Object.keys(toothTextMap).find(key => toothTextMap[key] === teeth)) : [];
        // Convert the teeth text back to their respective numbers for pathology
        const pathologyNumbers = radiographData.pathologyAssessment ? radiographData.pathologyAssessment.split(', ').map(teeth => Object.keys(toothTextMap).find(key => toothTextMap[key] === teeth)) : [];
        setSelectedCaries(cariesNumbers);
        setSelectedPathology(pathologyNumbers);
        setMaxBoneLossPercentage(radiographData.maxBoneLossPercentage || '');
      }
    };

    fetchRadiographAssessment();
  }, [patientId]);

  const handleNext = async () => {
    const patientRef = doc(firestore, 'customers', auth.currentUser.uid, 'patients', patientId);

    const radiographAssessment = {
      cariesAssessment: selectedCaries.map((tooth) => toothTextMap[tooth]).join(', '),
      pathologyAssessment: selectedPathology.map((tooth) => toothTextMap[tooth]).join(', '),
      maxBoneLossPercentage: maxBoneLossPercentage,
      details: radiographDetails,
    };

    const selectedRadiographOptionsString = Object.entries(radiographOptions)
      .filter(([key, value]) => value)
      .map(([key]) => key.charAt(0).toUpperCase() + key.slice(1))
      .join(', ');

    await updateDoc(patientRef, {
      'notes.radiographAssessment': radiographAssessment,
      'notes.radiographAssessment.type': selectedRadiographOptionsString,
    });

    console.log("Updated Patient Data:", { radiographAssessment: radiographAssessment });

    navigate(`/mvp/patient-profile/${patientId}/discussion`);
  };


  return (
    <div className="patient-complaints-container">
      <PatientProfileNav patientId={patientId} />
      <h2 className="patient-complaints-h2">Radiograph Assessment</h2>

      <div className="sub-options">
        {Object.keys(radiographOptions).map(option => (
          <button
            key={option}
            onClick={() => toggleRadiographOption(option)}
            className={`treatment-button ${radiographOptions[option] ? 'selected' : ''}`}
          >
            {option}
          </button>
        ))}
      </div>
      
      <p>Select teeth with caries:</p>
      <div className="tooth-chart-grid">
        {Array.from({ length: 32 }, (_, index) => index + 1).map((tooth) => (
          <div
            key={tooth}
            className={`tooth-chart-cell ${selectedCaries.includes(String(tooth)) ? 'selected' : ''}`}
            onClick={() => handleCariesClick(String(tooth))}
          >
            {toothTextMap[tooth]}
          </div>
        ))}
      </div>
      <p>Select teeth with pathology:</p>
      <div className="tooth-chart-grid">
        {Array.from({ length: 32 }, (_, index) => index + 1).map((tooth) => (
          <div
            key={tooth}
            className={`tooth-chart-cell ${selectedPathology.includes(String(tooth)) ? 'selected' : ''}`}
            onClick={() => handlePathologyClick(String(tooth))}
          >
            {toothTextMap[tooth]}
          </div>
        ))}
      </div>
      <div>
        <div className="max-bone-loss-container">
          <label htmlFor="maxBoneLossPercentage" className="max-bone-loss-label">Max Bone Loss (%):</label>
          <input
            type="text"
            id="maxBoneLossPercentage"
            value={maxBoneLossPercentage}
            onChange={(e) => setMaxBoneLossPercentage(e.target.value)}
            placeholder="Enter max bone loss percentage"
            className="patient-bpe-input"
          />
        </div>
      </div>
      <textarea
            id="radiographDetails"
            value={radiographDetails}
            onChange={(e) => setRadiographDetails(e.target.value)}
            placeholder="Enter radiograph details"
            className="patient-complaints-textarea"
            onKeyPress={handleKeyPress}
          />
      <button className="patient-complaints-button" onClick={handleNext}>Next</button>
    </div>
  );  
};

export default RadiographAssessment;
