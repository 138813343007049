import React, { useEffect, useState, useRef } from 'react';
import { getStorage, ref, uploadBytes } from "firebase/storage";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMicrophone, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { firestore, auth } from '../../firebase';
import { collection, getDocs, query, orderBy, doc, setDoc, onSnapshot, getDoc } from 'firebase/firestore';
import './CheetChat.css';
import AIIcon from './AIIcon';
import UserIcon from './UserIcon';
import { useSelectedNote } from '../noteslist/SelectedNoteContext';
import ThreeDotsWave from '../patientnotes/ThreeDotsWave';

const CheetChat = () => {
    const [showChat, setShowChat] = useState(false);
    const [isRecording, setIsRecording] = useState(false);
    const [mediaRecorder, setMediaRecorder] = useState(null);
    const [audioData, setAudioData] = useState(null);
    const [messages, setMessages] = useState([]);
    const [transcription, setTranscription] = useState();
    const [isSending, setIsSending] = useState(false);
    const [assistantResponse, setAssistantResponse] = useState('');
    const [isFetching, setIsFetching] = useState(false);
    const [notes, setNotes] = useState([]); // State to store notes
    const [groupedNotes, setGroupedNotes] = useState({});
    const [showNotes, setShowNotes] = useState(false);
    const [selectedNote, setSelectedNote] = useState(null);
    const { selectedNote: notesListNote } = useSelectedNote();
    const [isEditMode, setIsEditMode] = useState(false);
    const messageEndRef = useRef(null);
    const [riskBankItems, setRiskBankItems] = useState([]);
    const [showRiskBank, setShowRiskBank] = useState(false);
    const [textInput, setTextInput] = useState('');
    const [languagePreference, setLanguagePreference] = useState('EN');
    const [dentistName, setDentistName] = useState('Dentist');

    useEffect(() => {
        const fetchPreferences = async () => {
            const user = auth.currentUser;
            if (user) {
                const userRef = doc(firestore, 'customers', user.uid);
                const userDoc = await getDoc(userRef);
                if (userDoc.exists()) {
                    setLanguagePreference(userDoc.data().languagePreference || 'EN');
                    setDentistName(userDoc.data().name || 'Dentist');
                } else {
                    // Fallback if no data in Firestore
                    setLanguagePreference('EN');
                }
            }
        };
        fetchPreferences();
    }, []);

    const handleNoteSelection = (note) => {
        setSelectedNote(selectedNote => {
            if (selectedNote && selectedNote.id === note.id) {
                // Toggle off if the same note is clicked again
                return null;
            } else {
                // Use content2 if available, otherwise use content
                return {
                    ...note,
                    selectedContent: note.content2 || note.content
                };
            }
        });
    };

    useEffect(() => {
        // Scroll to the bottom of the message container
        messageEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }, [messages]);

    useEffect(() => {
        const interval = setInterval(() => {
            const button = document.querySelector('.cheetChatButton');
            if (button) {
                button.style.animationPlayState = 'running';

                // Reset the animation state after it completes
                setTimeout(() => {
                    button.style.animationPlayState = 'paused';
                }, 500); // Match the duration of the animation
            }
        }, 300000); // 5 minutes in milliseconds

        return () => clearInterval(interval);
    }, []);

    const toggleChat = () => {
        setShowChat(!showChat);
        const button = document.querySelector('.cheetChatButton');
        button.classList.toggle('spinOnClick');
    };

    const addAIMessage = (userInput) => {
        const aiResponse = `${userInput}`;
        setMessages(previousMessages => [{ from: 'ai', text: aiResponse }, ...previousMessages]);
    };

    const startRecording = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            const newMediaRecorder = new MediaRecorder(stream);
            // console.log('MediaRecorder started');

            let audioChunks = [];
            newMediaRecorder.ondataavailable = (event) => {
                audioChunks.push(event.data);
            };

            newMediaRecorder.onstop = async () => {
                // console.log('Recording stopped, creating audio blob');
                const audioBlob = new Blob(audioChunks, { type: 'audio/webm' });
                // console.log('Audio blob created:', audioBlob.size);

                if (audioBlob.size > 0) {
                    const uid = auth.currentUser.uid;
                    const filePath = `cheetchat/${uid}/${Date.now()}.webm`;
                    const storageRef = ref(getStorage(), filePath);
                    await uploadBytes(storageRef, audioBlob);

                    setAudioData(filePath);
                }
            };

            setMediaRecorder(newMediaRecorder);
            newMediaRecorder.start();
            setIsRecording(true);
        } catch (error) {
            console.error('Error in starting recording:', error);
        }
    };

    const stopRecording = () => {
        if (mediaRecorder) {
            // console.log('Stopping recording');
            mediaRecorder.stop();
            setIsRecording(false);
            mediaRecorder.stream.getTracks().forEach(track => track.stop());
        }
    };

    const sendAudioToCloudFunction = async (filePath) => {
        try {
            // Get the current authenticated user
            const user = auth.currentUser;
            if (!user) {
                throw new Error("User not authenticated");
            }
    
            // Get the Firebase ID token of the current user
            const idToken = await user.getIdToken();
    
            // Prepare the payload
            const payload = {
                filePath: filePath,
                language: languagePreference // Send the user's language preference
            };
    
            // Make the POST request with Authorization header
            const response = await fetch('https://us-central1-digitaltco-c40e9.cloudfunctions.net/multilanguage', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${idToken}`, // Include the ID token in the Authorization header
                },
                body: JSON.stringify(payload),
            });
    
            if (!response.ok) {
                // Handle HTTP errors
                const errorText = await response.text();
                throw new Error(`Server Error: ${errorText}`);
            }

            const result = await response.json();
            // console.log('Received response:', result);
            setTranscription(result.text);
            setIsRecording(false);
            setMessages(prevMessages => {
                const updatedMessages = [...prevMessages];
                updatedMessages[0] = { ...updatedMessages[0], text: result.text };
                return updatedMessages;
            });
        } catch (error) {
            console.error('Error sending audio URL:', error);
        }
    };

    const handleRecordButtonClick = () => {
        if (!isRecording) {
            startRecording();
        }
    };

    const handleSendButtonClick = () => {
        if (isRecording && mediaRecorder) {
            // console.log('Stopping recording');
            stopRecording();
            setIsSending(true);
            setTimeout(() => setIsSending(false), 500);
            setMessages(prevMessages => [
                {
                    from: 'user',
                    text: (
                        <p className="messageText" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <ThreeDotsWave />
                        </p>
                    )
                },
                ...prevMessages
            ]);
        } else if (textInput.trim()) {
            // Handle text input
            setMessages(prevMessages => [
                { from: 'user', text: textInput },
                ...prevMessages
            ]);
            if (selectedNote) {
                fetchUpdatedNote(textInput, selectedNote.content);
            } else {
                fetchAssistantResponse(textInput);
            }
            setTextInput('');
        }
    };

    useEffect(() => {
        if (audioData) {
            const sendRecording = async () => {
                await sendAudioToCloudFunction(audioData);
            };
            sendRecording();
        }
    }, [audioData]);

    useEffect(() => {
        if (!isRecording && transcription) {
            if (selectedNote) {
                // console.log('Sending to fetchUpdatedNote');
                fetchUpdatedNote(transcription, selectedNote.content);
            } else {
                // console.log('Sending to fetchAssistantResponse');
                fetchAssistantResponse(transcription);
            }
            setTranscription(''); // Reset transcription after handling
        }
    }, [transcription, selectedNote, isRecording]); // Add isRecording as a dependency

    const fetchAssistantResponse = async (userInput) => {
        setIsFetching(true);
        try {
            const idToken = await auth.currentUser.getIdToken();

            const response = await fetch(`https://us-central1-digitaltco-c40e9.cloudfunctions.net/SDCMsimple`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${idToken}`, // Include the ID token in the Authorization header
                },
                body: JSON.stringify({ prompt: userInput }),
            });
            const result = await response.json();
            setAssistantResponse(result.text);
            addAIMessage(result.text);

            // Save to Firestore
            const uid = auth.currentUser.uid;
            const noteRef = doc(collection(firestore, 'customers', uid, 'notes'));
            await setDoc(noteRef, {
                title: "Cheet Note",
                content: result.text,
                timestamp: new Date()
            });

        } catch (error) {
            console.error('Error fetching response:', error);
            setAssistantResponse('Error fetching response.');
        } finally {
            setIsFetching(false);
        }
    };

    useEffect(() => {
        const uid = auth.currentUser.uid;

        // Query for the notes
        const notesQuery = query(collection(firestore, 'customers', uid, 'notes'), orderBy('timestamp', 'desc'));

        // Listening for real-time updates on notes
        const unsubscribeNotes = onSnapshot(notesQuery, (notesSnapshot) => {
            let combinedNotes = notesSnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
                timestamp: doc.data().timestamp.toDate(),
            }));

            // Fetch full notes from each patient
            const patientsQuery = collection(firestore, 'customers', uid, 'patients');
            getDocs(patientsQuery).then(patientsSnapshot => {
                patientsSnapshot.forEach(doc => {
                    const patientData = doc.data();
                    if (patientData.notes && patientData.notes.fullNote) {
                        const processedNote = patientData.notes.fullNote.replace(/\*\*(.*?)\*\*/g, '$1');
                        combinedNotes.push({
                            id: doc.id,
                            timestamp: new Date(patientData.timestamp.seconds * 1000),
                            title: `${patientData.name} - Full Note`,
                            content: processedNote
                        });
                    }
                });

                // Sort and filter notes
                combinedNotes.sort((a, b) => b.timestamp - a.timestamp);
                combinedNotes = combinedNotes.filter(note => note.title !== "Patient Letter" && note.title !== "Referral Letter");

                // Set the latest five notes
                const latestFiveNotes = combinedNotes.slice(0, 5);
                setNotes(latestFiveNotes);

                // Group notes if required
                const grouped = {};
                combinedNotes.forEach(note => {
                    const dateKey = note.timestamp.toLocaleDateString('en-GB');
                    if (!grouped[dateKey]) {
                        grouped[dateKey] = [];
                    }
                    grouped[dateKey].push(note);
                });
                setGroupedNotes(grouped);
            });
        });

        // Return the unsubscribe function to stop listening for updates
        return () => {
            unsubscribeNotes();
        };
    }, []);

    const formatTimestamp = (date) => {
        // Format the month and day
        const dayMonth = date.toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit' });

        // Format the time
        let hours = date.getHours();
        const minutes = date.getMinutes();
        const ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours || 12; // the hour '0' should be '12'
        const minutesStr = minutes < 10 ? '0' + minutes : minutes;

        return `${dayMonth}, ${hours}:${minutesStr}${ampm}`;
    };

    const handleShowNotesToggle = () => {
        setShowNotes(!showNotes);
        if (isEditMode) {
            exitEditMode(); // Exit edit mode when hiding notes
        }
    };

    const copyToClipboard = (text, iconId) => {
        navigator.clipboard.writeText(text).then(() => {
            // Trigger animation on the icon
            const iconElement = document.getElementById(iconId);
            iconElement.classList.add('copy-flash');
            setTimeout(() => {
                iconElement.classList.remove('copy-flash');
            }, 500); // Duration of the flash effect
        });
    };

    const fetchUpdatedNote = async (userInput, oldNoteContent) => {
        setIsFetching(true);
        const prompt = userInput + "\n" + oldNoteContent;
        // console.log(prompt);
        // console.log("Old Note =", oldNoteContent);
        const idToken = await auth.currentUser.getIdToken();
        try {
            const response = await fetch('https://us-central1-digitaltco-c40e9.cloudfunctions.net/noteupdater', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${idToken}`,
                },
                body: JSON.stringify({ prompt: prompt }),
            });
            const result = await response.json();
            setAssistantResponse(result.text);
            addAIMessage(result.text);

            // Save to Firestore
            const uid = auth.currentUser.uid;
            const noteRef = doc(collection(firestore, 'customers', uid, 'notes'));
            await setDoc(noteRef, {
                title: `Cheet Edit - ${selectedNote.title}`,
                content: result.text,
                timestamp: new Date()
            });

            // Deselect the note and hide the notes list
            setSelectedNote(null);
            setShowNotes(false);

        } catch (error) {
            console.error('Error fetching updated note:', error);
            setAssistantResponse('Error fetching updated note.');
        } finally {
            setIsFetching(false);
            exitEditMode();
        }
    };

    useEffect(() => {
        if (notesListNote) {
            setSelectedNote(notesListNote); // Set the note to be edited
            setShowNotes(true); // Show the notes list
            setShowChat(true); // Open the CheetChat
            setIsEditMode(true); // Enter edit mode
        }
    }, [notesListNote]);

    const exitEditMode = () => {
        setIsEditMode(false);
        setSelectedNote(null); // Clear the selected note
        // Additional logic to exit edit mode if needed
    };

    const removeDoubleAsterisks = (text) => {
        if (typeof text !== 'string') {
            // Handle the case when text is not a string
            // You might want to return text as is, or handle it differently
            return text;
        }

        let formattedText = text.replace(/\*\*(.*?)\*\*/g, '$1'); // Remove **text**
        formattedText = formattedText.replace(/##/g, ''); // Remove ##
        formattedText = formattedText.replace(/###/g, '');
        formattedText = formattedText.replace(/\*\*/g, '');
        formattedText = formattedText.replace(/【\d+†.*?】/g, '');
        return formattedText;
    };

    // risk bank

    useEffect(() => {
        if (auth.currentUser) {
            const riskBankRef = collection(firestore, 'customers', auth.currentUser.uid, 'riskBank');

            // Set up the listener
            const unsubscribe = onSnapshot(riskBankRef, (querySnapshot) => {
                const items = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setRiskBankItems(items);
            },
                (error) => {
                    console.error("Error fetching risk bank data:", error);
                });

            // Return the unsubscribe function to stop listening for updates
            return () => unsubscribe();
        }
    }, []);

    const handleShowRiskBank = () => {
        setShowRiskBank(!showRiskBank);
    };

    const handleRiskItemClick = async (itemId) => {
        try {
            const riskRef = doc(firestore, 'customers', auth.currentUser.uid, 'riskBank', itemId);
            const docSnap = await getDoc(riskRef);

            if (docSnap.exists()) {
                const riskData = docSnap.data().risk;
                navigator.clipboard.writeText(riskData);

                // Trigger pulse effect
                const button = document.getElementById(`risk-button-${itemId}`);
                if (button) {
                    button.classList.add('pulse-green');
                    setTimeout(() => button.classList.remove('pulse-green'), 1000); // Remove class after animation
                }

                // console.log("Risk content copied to clipboard");
            }
        } catch (error) {
            console.error('Error fetching risk item:', error);
        }
    };

    return (
        <>
            <button className="cheetChatButton" onClick={toggleChat} type="button">
            </button>

            {showChat && (
                <div className="cheetChatContainer">
                    <div className="flex gap-4 mx-auto">
                        <button
                            className={`inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-xs font-medium rounded-lg group focus:outline-none focus:ring-4 focus:ring-malachite-100
                        ${showRiskBank
                                    ? "bg-gradient-to-br from-malachite-400 to-malachite-500 hover:text-white"
                                    : "bg-gradient-to-br from-primary-500 to-pink-500 group-hover:from-purple-500 group-hover:to-pink-500 hover:text-white"
                                }`
                            }
                            onClick={handleShowRiskBank}
                        >
                            <span className="relative px-5 py-1.5 transition-all ease-in duration-75 bg-white rounded-md group-hover:bg-opacity-0">
                                Spiel Store
                            </span>
                        </button>
                        <button
                            className={`inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-xs font-medium rounded-lg group focus:outline-none focus:ring-4 focus:ring-malachite-100
                        ${showNotes
                                    ? "bg-gradient-to-br from-malachite-400 to-malachite-500 hover:text-white"
                                    : "bg-gradient-to-br from-primary-500 to-pink-500 group-hover:from-purple-500 group-hover:to-pink-500 hover:text-white"
                                }`
                            }
                            onClick={handleShowNotesToggle}
                        >
                            <span className="relative px-5 py-1.5 transition-all ease-in duration-75 bg-white rounded-md group-hover:bg-opacity-0">
                                {showNotes ? "Hide Notes" : "Edit Notes"}
                            </span>
                        </button>
                    </div>

                    {showNotes && (
                        <div>
                            {isEditMode ? (
                                // Render only the selected note if in edit mode
                                <button
                                    className={`inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-xs font-medium rounded-lg group focus:outline-none focus:ring-4 focus:ring-malachite-200 bg-gradient-to-br from-malachite-500 to-malachite-400 group-hover:from-purple-500 group-hover:to-pink-500 hover:text-white`}
                                    onClick={exitEditMode} // Click to exit edit mode
                                >
                                    <span className="relative px-5 py-1.5 transition-all ease-in duration-75 bg-white rounded-md group-hover:bg-opacity-0">

                                        {formatTimestamp(selectedNote.timestamp)} - {selectedNote.patientId} - {selectedNote.title}
                                    </span>
                                </button>
                            ) : (
                                // Render all notes if not in edit mode
                                notes.map((note) => (
                                    <button
                                        key={note.id}
                                        className={`inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-xs font-medium rounded-lg group focus:outline-none focus:ring-4 focus:ring-malachite-200 ${selectedNote && selectedNote.id === note.id ? 'bg-gradient-to-br from-malachite-400 to-malachite-500 hover:text-white' : 'bg-gradient-to-br from-primary-500 to-pink-500 group-hover:from-purple-500 group-hover:to-pink-500 hover:text-white'}`}
                                        onClick={() => handleNoteSelection(note)}
                                    >
                                        <span className="relative px-5 py-1.5 transition-all ease-in duration-75 bg-white rounded-md group-hover:bg-opacity-0">
                                            {formatTimestamp(note.timestamp)} - {note.patientId ? `${note.patientId} - ` : ''}{note.title}
                                        </span>
                                    </button>
                                ))
                            )}
                        </div>
                    )}
                    {showRiskBank && (
                        <div className="flex flex-wrap justify-center gap-4 mb-4 mt-2 bg-gradient-to-br from-primary-50 to-primary-100 p-2 rounded-lg shadow-sm">
                            {riskBankItems.map((item) => (
                                <button
                                    key={item.id}
                                    id={`risk-button-${item.id}`}
                                    className="p-2 bg-white text-gray-700 text-sm rounded-lg shadow-sm border hover:text-white hover:bg-primary-400"
                                    onClick={() => handleRiskItemClick(item.id)}
                                >
                                    {item.name}
                                </button>
                            ))}
                        </div>
                    )}

                    <div className="messageContainer" ref={messageEndRef}>
                        <div className="cheetChatMessages">
                            {isFetching && (
                                <div className="message ai">
                                    <span className="messageIcon"><AIIcon /></span>
                                    <p className="messageText"><ThreeDotsWave /></p>
                                </div>
                            )}
                            {messages.map((message, index) => (
                                <div key={index} className={`message ${message.from}`}>
                                    <span
                                        id={`ai-icon-${index}`}
                                        className={`messageIcon ${message.from === 'ai' ? 'ai' : ''}`}
                                        onClick={() => message.from === 'ai' && copyToClipboard(message.text, `ai-icon-${index}`)}
                                    >
                                        {message.from === 'ai' ? <AIIcon /> : <UserIcon />}
                                    </span>
                                    <p className="messageText">{removeDoubleAsterisks(message.text)}</p>
                                </div>
                            ))}

                        </div>
                    </div>

                    <div className="mx-auto mt-2 inline-flex rounded-md shadow-sm" role="group">
                        <input
                            type="text"
                            className="px-4 py-2 text-sm tracking-tighter text-gray-700 bg-white border border-gray-200 rounded-s-lg focus:outline-none focus:ring-2 focus:ring-primary-400 focus:border-transparent"
                            placeholder="Make some changes..."
                            value={textInput}
                            onChange={(e) => setTextInput(e.target.value)}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    handleSendButtonClick();
                                }
                            }}
                        />
                        <button
                            className={`${isRecording ? 'px-4 py-2 text-xl font-medium text-white bg-malachite-500 border border-gray-200 cursor-not-allowed' : 'px-4 py-2 text-xl font-medium text-primary-400 bg-white border border-gray-200 hover:bg-primary-400 hover:text-white'}`}
                            onClick={handleRecordButtonClick}
                        >
                            <FontAwesomeIcon className="animate-pulse" icon={faMicrophone} />
                        </button>
                        <button
                            className={`px-4 py-2 text-xl font-medium text-primary-400 bg-white rounded-e-lg border border-gray-200 hover:bg-primary-400 hover:text-white`}
                            onClick={handleSendButtonClick}
                        >
                            <FontAwesomeIcon icon={faPaperPlane} />
                        </button>
                    </div>
                </div>
            )}
        </>
    );
};

export default CheetChat;
