// SelectedNoteContext.js
import React, { createContext, useState, useContext } from 'react';

const SelectedNoteContext = createContext();

export const useSelectedNote = () => useContext(SelectedNoteContext);

export const SelectedNoteProvider = ({ children }) => {
    const [selectedNote, setSelectedNote] = useState(null);

    const selectNote = (note) => {
        setSelectedNote(note);
        console.log("selected note in noteslist:", note);
    };

    return (
        <SelectedNoteContext.Provider value={{ selectedNote, selectNote }}>
            {children}
        </SelectedNoteContext.Provider>
    );
};
