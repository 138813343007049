import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';

const CACWidget = ({ data }) => {
  const cac = data || 0; // Fallback to 0 if data is null or undefined

  return (
    <Card>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Customer Acquisition Cost (CAC)
        </Typography>
        <Typography variant="h4" color="primary">
          £{cac.toFixed(2)}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          Calculated based on ad spend and new customers.
        </Typography>
      </CardContent>
    </Card>
  );
};

export default CACWidget;
