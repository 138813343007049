import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { firestore, auth } from '../../firebase';
import PatientProfileNav from './PatientProfileNav';
import './PatientNotesInput.css'; 

const Motivators = () => {
  const [motivators, setMotivators] = useState('');
  const [motivatorOptions, setMotivatorOptions] = useState({
    'pain removal': false, 'get sleep': false, 'smile in photos': false,
    confidence: false, 'keep tooth': false, 'restore function': false, 
    'overall health': false
  });
  const navigate = useNavigate();
  const { patientId } = useParams();

  useEffect(() => {
    // Define the Firestore document reference
    const patientRef = doc(firestore, 'customers', auth.currentUser.uid, 'patients', patientId);

    // Fetch the current motivators data from Firestore
    const fetchMotivators = async () => {
      const patientDoc = await getDoc(patientRef);
      if (patientDoc.exists() && patientDoc.data().notes && patientDoc.data().notes.motivators) {
        setMotivators(patientDoc.data().notes.motivators); // Set the motivators data to the local state
      }
    };

    fetchMotivators();
  }, [patientId]); // Dependency array ensures this effect runs when the component mounts or patientId changes

  const toggleMotivatorOption = (option) => {
    setMotivatorOptions(prev => ({ ...prev, [option]: !prev[option] }));
  };

  const handleNext = async () => {
    const patientRef = doc(firestore, 'customers', auth.currentUser.uid, 'patients', patientId);

    const selectedMotivatorOptionsString = Object.entries(motivatorOptions)
      .filter(([key, value]) => value)
      .map(([key]) => key.charAt(0).toUpperCase() + key.slice(1))
      .join(', ');

    const updateString = `${selectedMotivatorOptionsString} - ${motivators}`;

    await updateDoc(patientRef, {
      'notes.motivators': updateString.trim()
    });

    // Navigate to the next step (Roadblocks)
    navigate(`/mvp/patient-profile/${patientId}/roadblocks`);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleNext();
    }
  };

  return (
    <div className="patient-complaints-container">
      <PatientProfileNav patientId={patientId} />
      <h2 className="patient-complaints-h2">Motivators</h2>

      <>
      <div className="sub-options">
        {['pain removal', 'get sleep'].map(option => (
          <button
            key={option}
            onClick={() => toggleMotivatorOption(option)}
            className={`treatment-button ${motivatorOptions[option] ? 'selected' : ''}`}
          >
            {option.charAt(0).toUpperCase() + option.slice(1)}
          </button>
        ))}
      </div>
      <div className="sub-options">
        {['keep tooth'].map(option => (
          <button
            key={option}
            onClick={() => toggleMotivatorOption(option)}
            className={`treatment-button ${motivatorOptions[option] ? 'selected' : ''}`}
          >
            {option.charAt(0).toUpperCase() + option.slice(1)}
          </button>
        ))}
      </div>
      <div className="sub-options">
        {['smile in photos', 'confidence'].map(option => (
          <button
            key={option}
            onClick={() => toggleMotivatorOption(option)}
            className={`treatment-button ${motivatorOptions[option] ? 'selected' : ''}`}
          >
            {option.charAt(0).toUpperCase() + option.slice(1)}
          </button>
        ))}
      </div>
      <div className="sub-options">
        {['restore function', 'overall health'].map(option => (
          <button
            key={option}
            onClick={() => toggleMotivatorOption(option)}
            className={`treatment-button ${motivatorOptions[option] ? 'selected' : ''}`}
          >
            {option.charAt(0).toUpperCase() + option.slice(1)}
          </button>
        ))}
      </div>
      </>

      <textarea
        className="patient-complaints-textarea"
        placeholder="Enter additional motivators here"
        value={motivators}
        onChange={(e) => setMotivators(e.target.value)}
        onKeyPress={handleKeyPress}
        autoFocus
      />

      <button className="patient-complaints-button" onClick={handleNext}>Next</button>
    </div>
  );
};

export default Motivators;
