const aestheticsTemplates = {
    "NP Exam" : `New Patient Exam Appointment

    Dentist: LD
    Dental Nurse: XXX
    Patient: Attends alone
    
    Medical History: Discussed and updated today. No changes.
    
    Reason for Appointment: Routine dental exam
    
    Chief Complaint: None
    
    History of Present Complaint: N/A
    
    Dental History -
    
    Last Dental Visit: 6 months.
    Anxiety Level: None, regular attender
    Smoking: Non-smoker
    Alcohol Consumption: Within normal limits
    Stress Level: 0/10
    Oral Hygiene  Habits: Electric Toothbrush  used 2x daily
    Toothpaste: Colgate, 1450ppm fluoride
    Interdental Aids: Floss, Tepes, Waterflosser
    Mouthwash: Nil
    Diet: Low risk for dental diseases

    Extra-Oral Examination  -
    
    Facial Symmetry: Normal, No abnormalities
    Lymph Nodes: No enlargement or tenderness
    TMJ Analysis: No clicking, pain, or deviation

    Soft Tissue Examination -
    
    Tongue 
    Floor of Mouth : No abnormalities detected 
    Buccal Mucosa : No abnormalities detected 
    Labial Mucosa : No abnormalities detected 
    Hard Palate : No abnormalities detected 
    Soft Palate : No abnormalities detected 

    Intra-Oral Examination (IO):
    
    Oral Cancer Screening: NAD
    Occlusion -
    Skeletal Relationship: Class I
    Posterior Relationship: Normal
    Incisor Relationship: Class I
    Basic Erosive Wear Examination (BEWE): XXX/XXX
    BPE: XXX/XXX
    Charting: Completed, no concerns
    Oral Hygiene: Good
    Gingival Health: Healthy, no signs of inflammation
    Recession: None
    Mobility: None
    Visible Caries: None
    Watches: None
    Endodontically Treated Teeth: None
    Prostheses: None
    X-Ray Analysis:
    
    Verbal Consent Given for Radiographs.
    Radiographs Taken:
    Radiograph Report:
    Radiograph discussion:

    Diagnosis (Dx)-
    
    Dental: Nil.
    Periodontal: Healthy.
    Risk Factors: None significant

    Risk Assessment -
    
    Caries: Low
    Periodontal Disease: Low
    Oral Cancer: Low
    Tooth Surface Loss (TSL): Low

    Discussion with Patient -
    
    Reinforced the importance of maintaining high oral hygiene standards to prevent periodontal disease progression.
    Explained the non-reversible but preventable nature of periodontal support loss.
    Discussed the role of professional cleaning in managing periodontal health.
    Patient opts for private scale with hygienist following a discussion of treatment options.

    Treatment Plan:
    
    Oral Hygiene Instruction (OHI): Completed. Daily flossing, dietary advice, fluoride toothpaste, and soft cleaning discussed.
    Prevention Discussion: Detailed conversation on caries, periodontal risk, and oral hygiene adjuncts, including 5000ppm fluoride toothpaste recommendation.
    Non-Surgical Periodontal Maintenance and Review (PMPR)
    Recommended biannual visits to hygienist

    Patient Understanding and Consent -
    
    Patient fully understood the discussion and agreed with the treatment plan. Verbal consent obtained for the treatment planned.
    Cost estimate provided and accepted by the patient.
    The patient left happy and informed, with no further questions.

    Next Visit (NV): Next routine check-up scheduled due 6 months.`,

  };
  
  export default aestheticsTemplates;
  