// GenerateFullNoteButton.js
import React from 'react';
import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { firestore } from '../../firebase';
import './PatientProfile.css';
import PatientProfile from './PatientProfile';
import { useNavigate } from 'react-router-dom';

const GenerateFullNoteButton = ({ patientId, userId, onGenerateStart, onGenerateEnd }) => {
    const navigate = useNavigate();

    const renderObjectAsList = (obj) => {
      if (Object.keys(obj).length === 0) {
        return 'No abnormality detected';
      }
      return Object.entries(obj).map(([key, value]) => {
        const formattedKey = key.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase()).trim();
        return `${formattedKey}: ${value}`;
      }).join(', ');
    }; 
    
    const containsMeaningfulData = (dataString) => {
      return /[0-9*]/.test(dataString);
    };    

  // Function to format patient data into a string
  const formatPatientDataToString = (patient, radiographSettings) => {
    const lines = [];
  
    const mapXrayTypeToSettings = (xrayTypeString, settings) => {
      // Split the string and take the first part (the X-ray type)
      const xrayType = xrayTypeString.split(',')[0].trim();
    
      if (xrayType.includes('BW')) {
        return settings.BW;
      } else if (xrayType.includes('Periapical')) {
        return settings.Periapical; // Make sure this matches the key in your settings object
      } else if (xrayType.includes('OPG')) {
        return settings.OPG;
      }
      return '';
    };    

  // Add patient name
  lines.push(`Name: ${patient.name}`);

  // Add complaints
  if (patient.notes.complaints) {
    lines.push(`C/O: ${patient.notes.complaints}`);
  }

  // Add past dental history
  if (patient.notes.pastDentalHistory) {
    lines.push(`PDH: ${patient.notes.pastDentalHistory}`);
  }

  // Add social history
  if (patient.notes.socialHistory) {
    lines.push(`SH: ${patient.notes.socialHistory}`);
  }

  // Add motivators
  if (patient.notes.motivators) {
    lines.push(`Motivators: ${patient.notes.motivators}`);
  }

  // Add roadblocks
  if (patient.notes.roadblocks) {
    lines.push(`Roadblocks: ${patient.notes.roadblocks}`);
  }

  // Add extraoral exam
  if (patient.notes.extraoralExam) {
    lines.push(`E/O: ${renderObjectAsList(patient.notes.extraoralExam)}`);
  }

  if (patient.notes.intraoralExam) {
    lines.push(`I/O: ${renderObjectAsList(patient.notes.intraoralExam)}`);
  }

  if (patient.notes && patient.notes.clinicalCaries) {
    lines.push(`Caries: ${patient.notes.clinicalCaries}`);
  }
  if (patient.notes && patient.notes.toothWear) {
    lines.push(`Tooth Wear: ${patient.notes.toothWear}`);
  }
  
  if (patient.notes && patient.notes.bpe) {
    const bpeData = renderObjectAsList(patient.notes.bpe);
    if (containsMeaningfulData(bpeData)) {
      lines.push(`BPE: ${bpeData}`);
    }
  }

  if (patient.notes && patient.notes.bpeType) {
    lines.push(`BOP: ${patient.notes.bpeType}`);
  }
  if (patient.notes && patient.notes.oralHygiene) {
    lines.push(`OH: ${patient.notes.oralHygiene}`);
  }
  if (patient.notes && patient.notes.cariesRisk) {
    lines.push(`Caries Risk: ${patient.notes.cariesRisk}`);
  }
  if (patient.notes && patient.notes.specialTests) {
    lines.push(`Special Tests: ${patient.notes.specialTests}`);
  }

  if (patient.notes && patient.notes.radiographAssessment && patient.notes.radiographAssessment.type) {
    const xraySettings = mapXrayTypeToSettings(patient.notes.radiographAssessment.type, radiographSettings);
    lines.push(`X-rays taken: ${patient.notes.radiographAssessment.type}, Settings: ${xraySettings}, Report: ${renderObjectAsList(patient.notes.radiographAssessment)}`);
  }

  if (patient.notes && patient.notes.diagnosis.dental) {
    lines.push(`Dental Diagnosis: ${patient.notes.diagnosis.dental}`);
  }
  if (patient.notes && patient.notes.diagnosis.pathology) {
    lines.push(`Pathology Diagnosis: ${patient.notes.diagnosis.pathology}`);
  }
  if (patient.notes && patient.notes.diagnosis.periodontal) {
    lines.push(`Periodontal Diagnosis: ${patient.notes.diagnosis.periodontal}`);
  }

  if (patient.notes && patient.notes.discussion) {
    lines.push(`Discussion: ${patient.notes.discussion}`);
  }
  
  if (patient.notes && patient.notes.treatmentPlan) {
    let treatmentPlanText = patient.notes.treatmentPlan;
    if (patient.notes.nonToothSpecificTreatments) {
      treatmentPlanText += ', ' + patient.notes.nonToothSpecificTreatments;
    }
    lines.push(`Tx Plan: ${treatmentPlanText}.`);
  }

  if (patient.notes && patient.notes.recording) {
    lines.push(`Incorporate this: ${patient.notes.recording}`);
  }

  // Combine all lines into a single string
  return lines.join('\n');
  };

  const generateAndSendNote = async () => {
    onGenerateStart();
    try {
      const patientRef = doc(firestore, 'customers', userId, 'patients', patientId);
      const radiographSettingsRef = doc(firestore, 'customers', userId, 'templates', 'xraysettings');
  
      const [patientDoc, radiographSettingsDoc] = await Promise.all([
        getDoc(patientRef),
        getDoc(radiographSettingsRef)
      ]);
  
      if (patientDoc.exists() && radiographSettingsDoc.exists()) {
        const patientData = patientDoc.data();
        const radiographSettings = radiographSettingsDoc.data();
        const fullNote = formatPatientDataToString(patientData, radiographSettings);
  
        // Call the Firebase Function
        const response = await fetch('https://us-central1-digitaltco-c40e9.cloudfunctions.net/chatGPTPrompt', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ prompt: fullNote }),
        });
  
        if (!response.ok) {
          console.error('Response not OK. Status:', response.status);
          const errorText = await response.text();
          console.error('Error response text:', errorText);
          throw new Error('Network response was not ok');
        }
  
        const responseData = await response.json();
        console.log('Response from OpenAI:', responseData);
  
        // Update the patient document with the full note
        await updateDoc(patientRef, {
          'notes.fullNote': responseData.text,
          timestamp: new Date()
        });
  
        navigate(`/mvp/patient-profile/${patientId}/full-note`);

      } else {
        alert('You need to add your radiograph settings! Please go to Settings > Radiograph Settings and fill it in, then come back and try again :)');
      }
    } catch (error) {
      console.error('Error generating full note:', error);
      alert('An error occurred while generating the full note.');
    }
    onGenerateEnd();
  }; 
   

  return (
    <button onClick={generateAndSendNote} className="profile-back-button">Generate Full Note</button>
  );
};

export default GenerateFullNoteButton;
