import React, { useEffect, useState } from 'react';
import { Grid, Box } from '@mui/material';
import { fetchMetaAdsData } from '../../api/AdminDashboard/metaAdsApi';
import { fetchFirestoreData } from '../../api/AdminDashboard/firestoreApi';
import { fetchAnalyticsData } from '../../api/AdminDashboard/analyticsApi';
import { fetchMailchimpData } from '../../api/AdminDashboard/mailchimpApi';
import MetaAdsWidget from './MetaAdsWidget';
import FirestoreWidget from './FirestoreWidget';
import StripeWidget from './StripeWidget';
import AnalyticsWidget from './AnalyticsWidget';
import MailchimpWidget from './MailchimpWidget';
import CACWidget from './CACWidget';
import Loader from '../common/Loader';
import Error from '../common/Error';

const AdminDashboard = () => {
  const [metaAdsData, setMetaAdsData] = useState(null);
  const [firestoreData, setFirestoreData] = useState(null);
  const [analyticsData, setAnalyticsData] = useState(null);
  const [mailchimpData, setMailchimpData] = useState(null);
  const [cacData, setCacData] = useState(0); // Default to 0
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAllData = async () => {
      try {
        const metaAds = await fetchMetaAdsData();
        const firestore = await fetchFirestoreData();
        const analytics = await fetchAnalyticsData();
        const mailchimp = await fetchMailchimpData();

        setMetaAdsData(metaAds);
        setFirestoreData(firestore);
        setAnalyticsData(analytics);
        setMailchimpData(mailchimp);

        // Calculate CAC based on metaAds and firestore data
        const cac = calculateCAC(metaAds?.spend || 0, firestore?.newCustomers || 0);
        setCacData(cac);

        setLoading(false);
      } catch (err) {
        console.error('Error fetching dashboard data:', err);
        setError(err);
        setLoading(false);
      }
    };

    fetchAllData();
  }, []);

  const calculateCAC = (adSpend, newCustomers) => {
    if (newCustomers === 0) return 0;
    return adSpend / newCustomers;
  };

  if (loading) return <Loader />;
  if (error) return <Error message="Failed to load dashboard data." />;

  return (
    <Box sx={{ padding: 4 }}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <CACWidget data={cacData} />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <StripeWidget /> {/* No data passed in initially */}
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <MetaAdsWidget data={metaAdsData} />
        </Grid>
        <Grid item xs={12} md={6} lg={4}>
          <FirestoreWidget data={firestoreData} />
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <AnalyticsWidget data={analyticsData} />
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <MailchimpWidget data={mailchimpData} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default AdminDashboard;
