// src/components/AdminDashboard/MetaAdsWidget.jsx

import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from 'recharts';

const MetaAdsWidget = ({ data }) => {
  const pieData = [
    { name: 'Clicks', value: data.clicks },
    { name: 'Impressions', value: data.impressions },
  ];

  const COLORS = ['#c150e5', '#1DC517']

  return (
    <Card>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Meta Ads Overview
        </Typography>
        <Typography variant="subtitle1">
          Campaign: {data.campaignName}
        </Typography>
        <Typography variant="body1">
          Spend: £{data.spend}
        </Typography>
        <Typography variant="body1">
          Conversions: {data.conversions}
        </Typography>
        <Typography variant="body1">
          Conversion Rate: {data.conversionRate}%
        </Typography>
        <Typography variant="body1">
          CPC: £{data.cpc.toFixed(2)}
        </Typography>
        <ResponsiveContainer width="100%" height={200}>
          <PieChart>
            <Pie
              data={pieData}
              innerRadius={40}
              outerRadius={80}
              dataKey="value"
              label
            >
              {pieData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
            <Tooltip />
          </PieChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  );
};

export default MetaAdsWidget;
