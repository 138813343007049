// Topbar.js
import React from 'react';
import Avatar from '../avatar/Avatar'; // Adjust the import path if necessary

function Topbar() {
  return (
    <div>
      <nav className="bg-white border-b border-gray-200 px-4 py-2.5 fixed left-0 right-0 top-0 z-50">
        <div className="flex justify-between items-center">
          <div className="flex items-center">
            <button
              data-drawer-target="drawer-navigation"
              data-drawer-toggle="drawer-navigation"
              aria-controls="drawer-navigation"
              className="p-2 mr-2 text-gray-600 rounded-lg cursor-pointer md:hidden hover:text-gray-900 hover:bg-gray-100 focus:bg-gray-100 focus:ring-2 focus:ring-gray-100"
            >
              <svg
                className="w-6 h-6"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h6a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <span className="sr-only">Toggle sidebar</span>
            </button>
            <a
              href="https://app.digitaltco.co.uk/mvp/profile"
              className="flex items-center justify-between mr-4"
            >
              <span className="ml-4 text-2xl font-bold tracking-tight text-primary-400 whitespace-nowrap">
                DigitalTCO
              </span>
              <span className="text-2xl font-bold tracking-tight text-primary-100 whitespace-nowrap">
                /Notes
              </span>
            </a>
          </div>
          {/* <div className="flex items-center ml-auto">
            <Avatar />
          </div> */}
        </div>
      </nav>
    </div>
  );
}

export default Topbar;
