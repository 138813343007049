import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { firestore, auth } from '../../firebase';
import PatientProfileNav from './PatientProfileNav';
import './PatientNotesInput.css'; 

const ExtraoralExam = () => {
  const [extraoralData, setExtraoralData] = useState({
    tmj: '',
    lymphNodes: '',
    lips: '',
    asymmetry: '',
    swelling: '',
  });
  const navigate = useNavigate();
  const { patientId } = useParams(); // Get the patientId from the URL parameters

  useEffect(() => {
    const patientRef = doc(firestore, 'customers', auth.currentUser.uid, 'patients', patientId);

    // Fetch the current extraoral exam data from Firestore
    const fetchExtraoralData = async () => {
      const patientDoc = await getDoc(patientRef);
      if (patientDoc.exists() && patientDoc.data().notes && patientDoc.data().notes.extraoralExam) {
        setExtraoralData(patientDoc.data().notes.extraoralExam); // Set the extraoral exam data to the local state
      }
    };

    fetchExtraoralData();
  }, [patientId]);

  const handleNext = async () => {
    const patientRef = doc(firestore, 'customers', auth.currentUser.uid, 'patients', patientId);

    // Update the patient document with the extraoral exam data
    await updateDoc(patientRef, {
      'notes.extraoralExam': extraoralData,
    });

    // Navigate to the next step
    navigate(`/mvp/patient-profile/${patientId}/intraoral-exam`);
  };

  const handleInputChange = (field, value) => {
    setExtraoralData({ ...extraoralData, [field]: value });
  };

  return (
    <div className="patient-complaints-container">
      <PatientProfileNav patientId={patientId} />
      <h2 className="patient-complaints-h2">Extraoral Exam</h2>
      <div className="patient-complaints-input-group">
        <label className="patient-complaints-label">TMJ:</label>
        <input
          className="patient-complaints-input"
          type="text"
          value={extraoralData.tmj}
          onChange={(e) => handleInputChange('tmj', e.target.value)}
          onKeyPress={(e) => e.key === 'Enter' && handleNext()}
          autoFocus
        />
      </div>
      <div className="patient-complaints-input-group">
        <label className="patient-complaints-label">Lymph Nodes:</label>
        <input
          className="patient-complaints-input"
          type="text"
          value={extraoralData.lymphNodes}
          onChange={(e) => handleInputChange('lymphNodes', e.target.value)}
          onKeyPress={(e) => e.key === 'Enter' && handleNext()}
        />
      </div>
      <div className="patient-complaints-input-group">
        <label className="patient-complaints-label">Lips:</label>
        <input
          className="patient-complaints-input"
          type="text"
          value={extraoralData.lips}
          onChange={(e) => handleInputChange('lips', e.target.value)}
          onKeyPress={(e) => e.key === 'Enter' && handleNext()}
        />
      </div>
      <div className="patient-complaints-input-group">
        <label className="patient-complaints-label">Asymmetry:</label>
        <input
          className="patient-complaints-input"
          type="text"
          value={extraoralData.asymmetry}
          onChange={(e) => handleInputChange('asymmetry', e.target.value)}
          onKeyPress={(e) => e.key === 'Enter' && handleNext()}
        />
      </div>
      <div className="patient-complaints-input-group">
        <label className="patient-complaints-label">Swelling:</label>
        <input
          className="patient-complaints-input"
          type="text"
          value={extraoralData.swelling}
          onChange={(e) => handleInputChange('swelling', e.target.value)}
          onKeyPress={(e) => e.key === 'Enter' && handleNext()}
        />
      </div>
      <button className="patient-complaints-button" onClick={handleNext}>Next</button>
    </div>
  );
};

export default ExtraoralExam;
