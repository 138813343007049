import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { firestore, auth } from '../../firebase';
import PatientProfileNav from './PatientProfileNav';
import './PatientNotesInput.css'; 

const ClinicalCaries = () => {
  const navigate = useNavigate();
  const { patientId } = useParams();
  const [selectedTeeth, setSelectedTeeth] = useState([]);

  // Mapping of tooth numbers to text
  const toothTextMap = {
    1: 'UR8', 2: 'UR7', 3: 'UR6', 4: 'UR5', 5: 'UR4', 6: 'UR3', 7: 'UR2', 8: 'UR1',
    9: 'UL1', 10: 'UL2', 11: 'UL3', 12: 'UL4', 13: 'UL5', 14: 'UL6', 15: 'UL7', 16: 'UL8',
    17: 'LR8', 18: 'LR7', 19: 'LR6', 20: 'LR5', 21: 'LR4', 22: 'LR3', 23: 'LR2', 24: 'LR1',
    25: 'LL1', 26: 'LL2', 27: 'LL3', 28: 'LL4', 29: 'LL5', 30: 'LL6', 31: 'LL7', 32: 'LL8',
  };

  useEffect(() => {
    const patientRef = doc(firestore, 'customers', auth.currentUser.uid, 'patients', patientId);

    const fetchClinicalCaries = async () => {
      const patientDoc = await getDoc(patientRef);
      if (patientDoc.exists() && patientDoc.data().notes && patientDoc.data().notes.clinicalCaries) {
        const existingTeeth = patientDoc.data().notes.clinicalCaries.split(', ');
        // Convert the teeth text back to their respective numbers
        const teethNumbers = existingTeeth.map(teeth => Object.keys(toothTextMap).find(key => toothTextMap[key] === teeth));
        setSelectedTeeth(teethNumbers);
      }
    };

    fetchClinicalCaries();
  }, [patientId]);

  const handleToothClick = (tooth) => {
    setSelectedTeeth(prevSelected => {
      const isToothSelected = prevSelected.includes(tooth);
      if (isToothSelected) {
        // Remove tooth from selection
        return prevSelected.filter(t => t !== tooth);
      } else {
        // Add tooth to selection
        return [...prevSelected, tooth];
      }
    });
  };

  const handleNext = async () => {
    const selectedTeethString = selectedTeeth.map(tooth => toothTextMap[tooth]).join(', ');
    const patientRef = doc(firestore, 'customers', auth.currentUser.uid, 'patients', patientId);

    await updateDoc(patientRef, {
      'notes.clinicalCaries': selectedTeethString,
    });

    navigate(`/mvp/patient-profile/${patientId}/tooth-wear`);
  };

  return (
    <div className="patient-complaints-container">
      <PatientProfileNav patientId={patientId} />
      <h2 className="patient-complaints-h2">Clinical Caries</h2>
      <p>Select teeth with dental caries:</p>
      <div className="tooth-chart-grid">
        {Array.from({ length: 32 }, (_, index) => index + 1).map(tooth => (
          <div
            key={tooth}
            className={`tooth-chart-cell ${selectedTeeth.includes(String(tooth)) ? 'selected' : ''}`}
            onClick={() => handleToothClick(String(tooth))}
          >
            {toothTextMap[tooth]}
          </div>
        ))}
      </div>
      <button className="patient-complaints-button" onClick={handleNext}>Next</button>
    </div>
  );
};

export default ClinicalCaries;
