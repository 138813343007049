import React, { useState, useEffect } from 'react';
import { collection, addDoc, getDocs, query, orderBy, Timestamp, deleteDoc, doc } from 'firebase/firestore';
import { firestore, auth } from '../../firebase';
import './PatientList.css';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

const PatientList = () => {
    const [patients, setPatients] = useState([]);
    const [newPatientName, setNewPatientName] = useState('');
    const [newPatientAge, setNewPatientAge] = useState('');

    const handleFormSubmit = async (event) => {
        event.preventDefault(); 
        await addPatient(); 
    };

    useEffect(() => {
        const fetchPatients = async () => {
            const user = auth.currentUser;
            if (user) {
                const patientsCollection = collection(firestore, 'customers', user.uid, 'patients');
                const q = query(patientsCollection, orderBy('timestamp', 'desc')); // Order by date in descending order
                const patientSnapshot = await getDocs(q);
                const patientData = patientSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setPatients(patientData);
            }
        };
      

        fetchPatients();
    }, []);

    const addPatient = async () => {
        const user = auth.currentUser;
        if (user && newPatientName) {
            const patientsCollection = collection(firestore, 'customers', user.uid, 'patients');
            const newPatient = {
                name: newPatientName,
                date: new Date().toISOString().split('T')[0],  // gets the date in YYYY-MM-DD format
                timestamp: Timestamp.now(),  // Add this line
                'notes.age': newPatientAge
            };        
            await addDoc(patientsCollection, newPatient);
            setNewPatientName('');
            setNewPatientAge('');
            // Refetch patients to update the list
            const q = query(patientsCollection, orderBy('timestamp', 'desc')); // Order by timestamp in descending order
            const patientSnapshot = await getDocs(q);
            const patientData = patientSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setPatients(patientData);
        }
    };

    const deletePatient = async (patientId) => {
        const user = auth.currentUser;
        if (user && window.confirm("Are you sure you want to delete?")) {
            const patientRef = doc(firestore, 'customers', user.uid, 'patients', patientId);
            await deleteDoc(patientRef);
            // Refetch patients to update the list
            const q = query(collection(firestore, 'customers', user.uid, 'patients'), orderBy('timestamp', 'desc'));
            const patientSnapshot = await getDocs(q);
            const patientData = patientSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setPatients(patientData);
        }
    };

    const formatDate = (dateString) => {
        if (!dateString) return 'Invalid date'; // Check for invalid date strings
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };
  
    return (
        <div className="patient-list-container">
            <h1>Patients</h1>
            <form className="add-patient" onSubmit={handleFormSubmit}> {/* Add form element with onSubmit */}
                <input
                    type="text"
                    placeholder="Patient Name"
                    value={newPatientName}
                    onChange={(e) => setNewPatientName(e.target.value)}
                    autoFocus
                />
                <input
                    type="number"
                    placeholder="Age"
                    value={newPatientAge}
                    onChange={(e) => setNewPatientAge(e.target.value)}
                />
                <button type="submit">Add Patient</button> {/* Set button type to submit */}
            </form>
            <div className="patient-list">
                <div className="patient-list-header">
                    <div>Date Added</div>
                    <div>Patient Name</div>
                </div>
                {patients.map(patient => (
                    <div key={patient.id} className="patient-list-row">
                        <Link to={`/mvp/patient-profile/${patient.id}/patient-complaints`} className="patient-list-item">
                            <div>{formatDate(patient.date)}</div>
                            <div>{patient.name}</div>
                        </Link>
                        <div onClick={() => deletePatient(patient.id)}>
                            <FontAwesomeIcon icon={faTrash} />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default PatientList;
