// EditPatientIdModal.js
import React, { useState, useEffect, useRef } from 'react';

const EditPatientIdModal = ({ isVisible, onClose, onSave, currentPatientId }) => {
    const [patientId, setPatientId] = useState(currentPatientId || '');
    const inputRef = useRef(null);

    // Update local state when currentPatientId changes
    useEffect(() => {
        setPatientId(currentPatientId || '');
    }, [currentPatientId]);

    // Focus on the input field when modal opens
    useEffect(() => {
        if (isVisible && inputRef.current) {
            inputRef.current.focus();
        }
    }, [isVisible]);

    // Handle Esc key to close the modal
    useEffect(() => {
        const handleEsc = (event) => {
            if (event.key === 'Escape') {
                onClose();
            }
        };
        window.addEventListener('keydown', handleEsc);
        return () => {
            window.removeEventListener('keydown', handleEsc);
        };
    }, [onClose]);

    if (!isVisible) return null;

    // Handle form submission
    const handleSubmit = (e) => {
        e.preventDefault(); // Prevent default form submission behavior
        onSave(patientId);
    };

    return (
        <div 
            className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50" 
            role="dialog" 
            aria-modal="true" 
            aria-labelledby="edit-patient-id-title"
            onClick={onClose} // Allow closing by clicking outside the modal content
        >
            <div 
                className="bg-white rounded-lg shadow-lg w-80 p-6" 
                onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the modal
            >
                <form onSubmit={handleSubmit}>
                    <input
                        type="text"
                        ref={inputRef}
                        value={patientId}
                        onChange={(e) => setPatientId(e.target.value)}
                        placeholder="Enter Patient ID"
                        className="w-full p-2 border border-gray-300 rounded mb-4 focus:outline-none focus:ring-2 focus:ring-primary-500"
                        aria-label="Patient ID"
                    />
                    <div className="flex justify-end gap-2">
                        <button
                            type="button"
                            onClick={onClose}
                            className="px-4 py-2 bg-gray-200 text-gray-700 rounded hover:bg-gray-300"
                            aria-label="Cancel editing Patient ID"
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className="px-4 py-2 bg-primary-500 text-white rounded hover:bg-primary-600"
                            aria-label="Save updated Patient ID"
                        >
                            Save
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default EditPatientIdModal;
