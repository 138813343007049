import React, { useState, useEffect } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { auth, firestore } from './firebase';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import './MVP.css';
import UseDesktopAdvice from './components/UseDesktopAdvice';
import TreatmentSelection from './components/TreatmentSelection';
import Topbar from './components/Nav/Topbar';
import Sidebar from './components/Nav/Sidebar';
import withPaidAccess from './components/withPaidAccess';
import withAdminAccess from './components/withAdminAccess';
import Profile from './components/Profile';
import KnowledgeVault from './components/knowledgevault/KnowledgeVault';
import VideoPage from './components/knowledgevault/VideoPage';
import LoadingPopup from  './components/patientnotes/LoadingPopup';
import Joyride, { STATUS } from 'react-joyride';
import JoyrideGuide from './components/joyride/JoyrideGuide';

import PatientList from './components/patientnotes/patientlist';
import PatientProfile from './components/patientnotes/PatientProfile';
import PatientComplaints from './components/patientnotes/PatientComplaints';
import PastDentalHistory from './components/patientnotes/PastDentalHistory';
import SocialHistory from './components/patientnotes/SocialHistory';
import Motivators from './components/patientnotes/Motivators';
import Roadblocks from './components/patientnotes/Roadblocks';
import ExtraoralExam from './components/patientnotes/ExtraoralExam';
import IntraoralExam from './components/patientnotes/IntraoralExam';
import ClinicalCaries from './components/patientnotes/ClinicalCaries';
import ToothWear from './components/patientnotes/ToothWear';
import BPE from './components/patientnotes/BPE';
import OralHygiene from './components/patientnotes/OralHygiene';
import CariesRisk from './components/patientnotes/CariesRisk';
import SpecialTests from './components/patientnotes/SpecialTests';
import RadiographAssessment from './components/patientnotes/RadiographAssessment';
import Discussion from './components/patientnotes/Discussion';
import TreatmentPlan from './components/patientnotes/TreatmentPlan';
import FullNote from './components/patientnotes/FullNote';

import Settings from './components/settings/Settings';
import TxNotes from './components/txnotes/TxNotes';
import TxNotesPage from './components/txnotes/TxNotesPage';
import SuperDentistCheatMode from './components/superdentistcheatmode/SuperDentistCheatMode';
import NotesList from './components/noteslist/NotesList';
import JazModev3 from './components/jazmodev3/JazModev3';
import BasicExam from './components/basicexam/BasicExam';
import ExamsAndConsultsList from './components/examsandconsults/ExamsAndConsultsList';
import ExamsAndConsultsPage from './components/examsandconsults/ExamsAndConsultsPage';
import Admin from './components/admin/Admin';

import TreatmentPlanner from './components/treatmentplanner/TreatmentPlanner';
import ModularLetters from './components/modularletters/ModularLetters';
import LettersTable from './components/modularletters/LettersTable';
import RiskBank from './components/riskbank/RiskBank';
import CheetChat from './components/cheet/CheetChat';
import Cariespondence from './components/cariespondence/Cariespondence';
import { SelectedNoteProvider } from './components/noteslist/SelectedNoteContext';
import AIIcon from './components/cheet/AIIcon';
import UpdatePopup from './components/updatepopup/UpdatePopup';
import Unauthorized from './components/Unauthorized';
import TemplateFixer from './components/templatefixer/TemplateFixer';
import SharedTemplates from './components/sharedtemplates/SharedTemplates';
import KanbanTodo from './components/kanban/KanbanTodo';

import ImageUpload from './components/ImageUpload';
import AdminDashboard from './components/AdminDashboard/AdminDashboard';

import PowerHourToast from './components/updatepopup/PowerHourToast';
import PowerHour from './components/powerhour/PowerHour';

import SuperDentistPenTest from './components/superdentistcheatmode/SuperDentistPenTest';
import AudioRecordingsList from './components/audiorecordingslist/AudioRecordingsList';
import OnboardingSidebar, { toggleSidebar } from './components/onboardingsidebar/OnboardingSidebar';
import { OnboardingProvider } from './components/onboardingsidebar/OnboardingContext';
import HelpScout from './components/helpscout/HelpScout';


const PaidTreatmentSelection = withPaidAccess(TreatmentSelection, ['Essentials', 'Performance']);
const PaidProfile = withPaidAccess(Profile, ['Essentials', 'Performance']);
// FULL NOTE
const PaidPatientList = withPaidAccess(PatientList);
const PaidPatientProfile = withPaidAccess(PatientProfile);
const PaidPatientComplaints = withPaidAccess(PatientComplaints);
const PaidPastDentalHistory = withPaidAccess(PastDentalHistory);
const PaidSocialHistory = withPaidAccess(SocialHistory);
const PaidMotivators = withPaidAccess(Motivators);
const PaidRoadblocks = withPaidAccess(Roadblocks);
const PaidExtraoralExam = withPaidAccess(ExtraoralExam);
const PaidIntraoralExam = withPaidAccess(IntraoralExam);
const PaidClinicalCaries = withPaidAccess(ClinicalCaries);
const PaidToothWear = withPaidAccess(ToothWear);
const PaidBPE = withPaidAccess(BPE);
const PaidOralHygiene = withPaidAccess(OralHygiene);
const PaidCariesRisk = withPaidAccess(CariesRisk);
const PaidSpecialTests = withPaidAccess(SpecialTests);
const PaidRadiographAssessment = withPaidAccess(RadiographAssessment);
const PaidDiscussion = withPaidAccess(Discussion);
const PaidTreatmentPlan = withPaidAccess(TreatmentPlan);
const PaidFullNote = withPaidAccess(FullNote);
// NEW STUFF
const PaidSettings = withPaidAccess(Settings, ['Essentials', 'Performance']);
const PaidTxNotes = withPaidAccess(TxNotes, ['Essentials', 'Performance']);
const PaidTxNotesPage = withPaidAccess(TxNotesPage, ['Essentials', 'Performance']);
const PaidSuperDentistCheatMode = withPaidAccess(SuperDentistCheatMode, ['Essentials', 'Performance']);
const PaidNotesList = withPaidAccess(NotesList, ['Essentials', 'Performance']);
const PaidKnowledgeVault = withPaidAccess(KnowledgeVault, ['Essentials', 'Performance']);
const PaidVideoPage = withPaidAccess(VideoPage, ['Essentials', 'Performance']);
const PaidBasicExam = withPaidAccess(BasicExam, ['Essentials', 'Performance']);
const PaidExamsAndConsultsList = withPaidAccess(ExamsAndConsultsList, ['Essentials', 'Performance']);
const PaidExamsAndConsultsPage = withPaidAccess(ExamsAndConsultsPage, ['Essentials', 'Performance']);
const PaidTreatmentPlanner = withPaidAccess(TreatmentPlanner, ['Performance']);
const PaidCheet = withPaidAccess(CheetChat, ['Essentials', 'Performance']);
const PaidJazModev3 = withPaidAccess(JazModev3, ['Performance']);
const PaidRiskBank = withPaidAccess(RiskBank, ['Performance']);
const PaidCariespondence = withPaidAccess(Cariespondence, ['Performance']);
const PaidModularLetters = withPaidAccess(ModularLetters, ['Performance']);
const PaidLettersTable = withPaidAccess(LettersTable, ['Performance']);
const PaidTemplateFixer = withPaidAccess(TemplateFixer, ['Essentials', 'Performance']);
const PaidSharedTemplates = withPaidAccess(SharedTemplates, ['Essentials', 'Performance']);
const PaidAudioRecordingsList = withPaidAccess(AudioRecordingsList, ['Essentials', 'Performance']);
const PaidKanbanTodo = withPaidAccess(KanbanTodo, ['Performance']);



const PaidAdmin = withPaidAccess(withAdminAccess(Admin), ['Essentials', 'Performance']);
const PaidAdminDashboard = withPaidAccess(withAdminAccess(AdminDashboard), ['Performance']);
const PaidPowerHour = withPaidAccess(PowerHour, ['Essentials', 'Performance']);
const PaidOnboardingSidebar = withPaidAccess(OnboardingSidebar, ['Essentials', 'Performance']);



function MVP() {
  const [showPopup, setShowPopup] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [joyrideSteps, setJoyrideSteps] = useState([]);
  const [showJoyride, setShowJoyride] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false); // Default to open
  const [allStepsCompleted, setAllStepsCompleted] = useState(false); // New state

  const handleToggleSidebar = (open) => { // Modified to accept explicit state
    setIsSidebarOpen(open !== undefined ? open : !isSidebarOpen);
  };

  const openSidebar = () => {
    setIsSidebarOpen(true);
  };

useEffect(() => {
    const checkPopupPreference = async () => {
      const user = auth.currentUser;
      if (user) {
        const userRef = doc(firestore, 'customers', user.uid);
        const userDoc = await getDoc(userRef);
        if (userDoc.exists()) {
          const userData = userDoc.data();

          // Check if the popup should be shown
          if (userData.popup !== false) {
            setShowPopup(true);
          }

          // Check if Joyride should be shown
          if (userData.showJoyride !== true) {
            setShowJoyride(false);
          }
        } else {
          // Default behavior when no data is found
          setShowJoyride(true);
        }
      }
    };

    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    checkPopupPreference();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleOk = async () => {
    setShowPopup(false);
    // If Joyride preference is not explicitly false, show it
    if (showJoyride !== false) {
      setShowJoyride(true);
    }

    const user = auth.currentUser;
    if (user) {
      const userRef = doc(firestore, 'customers', user.uid);
      await setDoc(userRef, { showJoyride: true }, { merge: true }); // Save Joyride preference as true
    }
  };

  const handleDoNotShowAgain = async () => {
    setShowPopup(false);
    setShowJoyride(true); // Set Joyride to true when dismissing popup
    const user = auth.currentUser;
    if (user) {
      const userRef = doc(firestore, 'customers', user.uid);
      await setDoc(userRef, { popup: false, showJoyride: true }, { merge: true });
    }
  };

  if (isMobile) {
    return <UseDesktopAdvice />;
  }

  return (
    <>
    <HelpScout />
    {showJoyride && !showPopup && <JoyrideGuide showJoyride={showJoyride} setShowJoyride={setShowJoyride} />}
    {showPopup && <UpdatePopup onOk={handleOk} onDoNotShowAgain={handleDoNotShowAgain} />}
    {/* <UpdatePopup onOk={handleOk} onDoNotShowAgain={handleDoNotShowAgain} /> */}
    {/* <PowerHourToast /> */}
    {/* <LoadingPopup /> */}
    <SelectedNoteProvider>
    <OnboardingProvider>
    <div className="fixed bottom-16 right-5 z-50 w-20 p-4">
      <PaidCheet />
    </div>
      <Topbar />
      <Sidebar />
      <PaidOnboardingSidebar
        isSidebarOpen={isSidebarOpen}
        handleToggleSidebar={handleToggleSidebar}
      />
        <main className="p-4 md:ml-56 h-auto pt-20">
          <Routes>
            <Route path="/" element={<Navigate to="tx-notes" replace />} />
            <Route path="consent-forms" element={<PaidTreatmentSelection />} />
            <Route path="profile" element={<PaidProfile openSidebar={openSidebar} />} />

              
              <Route path="full-note" element={<PaidPatientList />} />
              <Route path="patient-profile/:patientId" element={<PaidPatientProfile />} />
              <Route path="patient-profile/:patientId/patient-complaints" element={<PaidPatientComplaints />} />
              <Route path="patient-profile/:patientId/past-dental-history" element={<PaidPastDentalHistory />} />
              <Route path="patient-profile/:patientId/social-history" element={<PaidSocialHistory />} />
              <Route path="patient-profile/:patientId/motivators" element={<PaidMotivators />} />
              <Route path="patient-profile/:patientId/roadblocks" element={<PaidRoadblocks />} />
              <Route path="patient-profile/:patientId/extraoral-exam" element={<PaidExtraoralExam />} />
              <Route path="patient-profile/:patientId/intraoral-exam" element={<PaidIntraoralExam />} />
              <Route path="patient-profile/:patientId/clinical-caries" element={<PaidClinicalCaries />} />
              <Route path="patient-profile/:patientId/tooth-wear" element={<PaidToothWear />} />
              <Route path="patient-profile/:patientId/BPE" element={<PaidBPE />} />
              <Route path="patient-profile/:patientId/oral-hygiene" element={<PaidOralHygiene />} />
              <Route path="patient-profile/:patientId/caries-risk" element={<PaidCariesRisk />} />
              <Route path="patient-profile/:patientId/special-tests" element={<PaidSpecialTests />} />
              <Route path="patient-profile/:patientId/radiograph-assessment" element={<PaidRadiographAssessment />} />
              <Route path="patient-profile/:patientId/discussion" element={<PaidDiscussion />} />
              <Route path="patient-profile/:patientId/treatment-plan" element={<PaidTreatmentPlan />} />
              <Route path="patient-profile/:patientId/full-note" element={<PaidFullNote />} />
            

            <Route path="settings" element={<PaidSettings openSidebar={openSidebar} />} />
            <Route path="tx-notes" element={<PaidTxNotes openSidebar={openSidebar} />} />
            <Route path="tx-notes/:treatmentId" element={<PaidTxNotesPage openSidebar={openSidebar} />} />
            <Route path="super-dentist-cheat-mode" element={<PaidSuperDentistCheatMode openSidebar={openSidebar} />} />
            <Route path="notes-list" element={<PaidNotesList openSidebar={openSidebar} />} />
            <Route path="knowledgevault" element={<PaidKnowledgeVault />} />
            <Route path="knowledgevault/video/:videoId" element={<PaidVideoPage openSidebar={openSidebar} />} />
            <Route path="jazmode" element={<PaidJazModev3 />} />
            <Route path="basicexam" element={<PaidBasicExam />} />
            <Route path="examsandconsults" element={<PaidExamsAndConsultsList />} />
            <Route path="examsandconsults/:treatmentId" element={<PaidExamsAndConsultsPage />} />
            <Route path="treatmentplanner" element={<PaidTreatmentPlanner openSidebar={openSidebar} />} />
            <Route path="treatmentplanner/:noteId" element={<PaidTreatmentPlanner openSidebar={openSidebar} />} />
            <Route path="risk-bank" element={<PaidRiskBank openSidebar={openSidebar} />} />
            <Route path="cariespondence" element={<PaidCariespondence />} />
            <Route path="molarmail" element={<PaidLettersTable />} />
            <Route path="molarmail/:id" element={<PaidModularLetters />} />
            <Route path="templatefixer" element={<PaidTemplateFixer />} />
            <Route path="templateexchange" element={<PaidSharedTemplates />} />
            <Route path="audiolist" element={<PaidAudioRecordingsList />} />
            <Route path="todo" element={<PaidKanbanTodo />} />

            
            
            <Route path="unauthorized" element={<Unauthorized />} />

            <Route path="powerhour" element={<PaidPowerHour />} />

            <Route path="admin" element={<PaidAdmin />} />
            <Route path="admin/dash" element={<PaidAdminDashboard />} />
            <Route path="superdentistpentest" element={<SuperDentistPenTest />} />

            {/* <Route path="imageuploadtest" element={<ImageUpload />} /> */}
          </Routes>
        </main>
        </OnboardingProvider>
    </SelectedNoteProvider>
    
    </>
    
  );
}

export default MVP;
