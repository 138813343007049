import React from 'react';

const CustomForms = () => {
    return (
        <div className="bg-gray-100 p-4 rounded-lg space-y-2">
            <p className="text-sm text-gray-900">
                The patient's note will be reviewed to identify the treatment plan, and relevant treatment sections will be incorporated from the custom forms.
            </p>
            <p className="text-sm text-gray-900 ">
                You can add, edit, or change these in Customise &gt; Custom Forms.
            </p>
        </div>
    );
};

export default CustomForms;
