import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { doc, updateDoc, getDoc } from 'firebase/firestore';
import { firestore, auth } from '../../firebase';
import PatientProfileNav from './PatientProfileNav';
import './PatientNotesInput.css'; 

const Discussion = () => {
  const [discussion, setDiscussion] = useState('');
  const [discussionOptions, setDiscussionOptions] = useState({
    OHI: false, diet: false, unrestorable: false, 
    'tooth can be saved': false, 'what is periodontitis': false, 
    crown: false, bridge: false, denture: false, filling: false, 
    hygienist: false, RCT: false, extraction: false, 
    'missing teeth': false, implants: false, veneers: false, 
    'direct vs indirect': false
  });
  const navigate = useNavigate();
  const { patientId } = useParams();

  useEffect(() => {
    const patientRef = doc(firestore, 'customers', auth.currentUser.uid, 'patients', patientId);

    const fetchDiscussion = async () => {
      const patientDoc = await getDoc(patientRef);
      if (patientDoc.exists() && patientDoc.data().notes.discussion) {
        setDiscussion(patientDoc.data().notes.discussion); // Set the discussion data to the local state
      }
    };

    fetchDiscussion();
  }, [patientId]);

  const toggleDiscussionOption = (option) => {
    setDiscussionOptions(prev => ({ ...prev, [option]: !prev[option] }));
  };

  const handleNext = async () => {
    const patientRef = doc(firestore, 'customers', auth.currentUser.uid, 'patients', patientId);

    const selectedDiscussionOptionsString = Object.entries(discussionOptions)
      .filter(([key, value]) => value)
      .map(([key]) => key.charAt(0).toUpperCase() + key.slice(1))
      .join(', ');

    const updateString = `${selectedDiscussionOptionsString} - ${discussion}`;

    await updateDoc(patientRef, {
      'notes.discussion': updateString.trim()
    });

    navigate(`/mvp/patient-profile/${patientId}/treatment-plan`); // Update the navigation path as needed
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleNext();
    }
  };

  return (
    <div className="patient-complaints-container"> 
      <PatientProfileNav patientId={patientId} />
      <h2 className="patient-complaints-h2">Discussion</h2>

      <>
      <div className="sub-options">
        {['OHI', 'diet'].map(option => (
          <button
            key={option}
            onClick={() => toggleDiscussionOption(option)}
            className={`treatment-button ${discussionOptions[option] ? 'selected' : ''}`}
          >
            {option.charAt(0).toUpperCase() + option.slice(1)}
          </button>
        ))}
      </div>
      <div className="sub-options">
        {['tooth can be saved', 'unrestorable'].map(option => (
          <button
            key={option}
            onClick={() => toggleDiscussionOption(option)}
            className={`treatment-button ${discussionOptions[option] ? 'selected' : ''}`}
          >
            {option.charAt(0).toUpperCase() + option.slice(1)}
          </button>
        ))}
      </div>
      <div className="sub-options">
        {['what is periodontitis', 'hygienist'].map(option => (
          <button
            key={option}
            onClick={() => toggleDiscussionOption(option)}
            className={`treatment-button ${discussionOptions[option] ? 'selected' : ''}`}
          >
            {option.charAt(0).toUpperCase() + option.slice(1)}
          </button>
        ))}
      </div>
      <div className="sub-options">
        {['direct vs indirect'].map(option => (
          <button
            key={option}
            onClick={() => toggleDiscussionOption(option)}
            className={`treatment-button ${discussionOptions[option] ? 'selected' : ''}`}
          >
            {option.charAt(0).toUpperCase() + option.slice(1)}
          </button>
        ))}
      </div>
      <div className="sub-options">
        {['filling', 'crown', 'veneer', 'extraction', 'RCT', 'missing teeth', 'implant', 'bridge', 'denture'].map(option => (
          <button
            key={option}
            onClick={() => toggleDiscussionOption(option)}
            className={`treatment-button ${discussionOptions[option] ? 'selected' : ''}`}
          >
            {option.charAt(0).toUpperCase() + option.slice(1)}
          </button>
        ))}
      </div>
      </>

      <textarea
        className="patient-complaints-textarea" 
        placeholder="Enter discussion notes here"
        value={discussion}
        onChange={(e) => setDiscussion(e.target.value)}
        onKeyPress={handleKeyPress}
        autoFocus
      />

      <button className="patient-complaints-button" onClick={handleNext}>Next</button> 
    </div>
  );
};

export default Discussion;
