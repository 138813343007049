export const defaultTeethData = [
    { number: '18', description: '', segments: { occlusal: '', mesial: '', distal: '', buccal: '', lingual: '' }},
    { number: '17', description: '', segments: { occlusal: '', mesial: '', distal: '', buccal: '', lingual: '' }},
    { number: '16', description: '', segments: { occlusal: '', mesial: '', distal: '', buccal: '', lingual: '' }},
    { number: '15', description: '', segments: { occlusal: '', mesial: '', distal: '', buccal: '', lingual: '' }},
    { number: '14', description: '', segments: { occlusal: '', mesial: '', distal: '', buccal: '', lingual: '' }},
    { number: '13', description: '', segments: { occlusal: '', mesial: '', distal: '', buccal: '', lingual: '' }},
    { number: '12', description: '', segments: { occlusal: '', mesial: '', distal: '', buccal: '', lingual: '' }},
    { number: '11', description: '', segments: { occlusal: '', mesial: '', distal: '', buccal: '', lingual: '' }},
    { number: '21', description: '', segments: { occlusal: '', mesial: '', distal: '', buccal: '', lingual: '' }},
    { number: '22', description: '', segments: { occlusal: '', mesial: '', distal: '', buccal: '', lingual: '' }},
    { number: '23', description: '', segments: { occlusal: '', mesial: '', distal: '', buccal: '', lingual: '' }},
    { number: '24', description: '', segments: { occlusal: '', mesial: '', distal: '', buccal: '', lingual: '' }},
    { number: '25', description: '', segments: { occlusal: '', mesial: '', distal: '', buccal: '', lingual: '' }},
    { number: '26', description: '', segments: { occlusal: '', mesial: '', distal: '', buccal: '', lingual: '' }},
    { number: '27', description: '', segments: { occlusal: '', mesial: '', distal: '', buccal: '', lingual: '' }},
    { number: '28', description: '', segments: { occlusal: '', mesial: '', distal: '', buccal: '', lingual: '' }},
    { number: '38', description: '', segments: { occlusal: '', mesial: '', distal: '', buccal: '', lingual: '' }},
    { number: '37', description: '', segments: { occlusal: '', mesial: '', distal: '', buccal: '', lingual: '' }},
    { number: '36', description: '', segments: { occlusal: '', mesial: '', distal: '', buccal: '', lingual: '' }},
    { number: '35', description: '', segments: { occlusal: '', mesial: '', distal: '', buccal: '', lingual: '' }},
    { number: '34', description: '', segments: { occlusal: '', mesial: '', distal: '', buccal: '', lingual: '' }},
    { number: '33', description: '', segments: { occlusal: '', mesial: '', distal: '', buccal: '', lingual: '' }},
    { number: '32', description: '', segments: { occlusal: '', mesial: '', distal: '', buccal: '', lingual: '' }},
    { number: '31', description: '', segments: { occlusal: '', mesial: '', distal: '', buccal: '', lingual: '' }},
    { number: '41', description: '', segments: { occlusal: '', mesial: '', distal: '', buccal: '', lingual: '' }},
    { number: '42', description: '', segments: { occlusal: '', mesial: '', distal: '', buccal: '', lingual: '' }},
    { number: '43', description: '', segments: { occlusal: '', mesial: '', distal: '', buccal: '', lingual: '' }},
    { number: '44', description: '', segments: { occlusal: '', mesial: '', distal: '', buccal: '', lingual: '' }},
    { number: '45', description: '', segments: { occlusal: '', mesial: '', distal: '', buccal: '', lingual: '' }},
    { number: '46', description: '', segments: { occlusal: '', mesial: '', distal: '', buccal: '', lingual: '' }},
    { number: '47', description: '', segments: { occlusal: '', mesial: '', distal: '', buccal: '', lingual: '' }},
    { number: '48', description: '', segments: { occlusal: '', mesial: '', distal: '', buccal: '', lingual: '' }}
  ];
