import React, { useState, useEffect, useRef, useContext } from 'react';
import { doc, setDoc, writeBatch, collection, getDocs, deleteDoc } from 'firebase/firestore';
import { firestore, auth } from '../../firebase';
import './settings.css';
import ShareTemplateButton from '../sharedtemplates/ShareTemplateButton';
import OnboardingJoyrideWrapper from '../onboardingsidebar/OnboardingJoyrideWrapper';
import { OnboardingContext } from '../onboardingsidebar/OnboardingContext';

const Templates = ({ openSidebar }) => {
  const [selectedTemplate, setSelectedTemplate] = useState('');
  const [templates, setTemplates] = useState({});
  const [searchInput, setSearchInput] = useState('');
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [isCreating, setIsCreating] = useState(false); // State to differentiate creation mode
  const [newTemplateName, setNewTemplateName] = useState('');
  const [newTemplateContent, setNewTemplateContent] = useState('');
  const textareaRef = useRef(null);

  const { onboardingStepKey, setOnboardingStepKey } = useContext(OnboardingContext);
  const [userData, setUserData] = useState(null);

  // Onboarding Refs
  const onboarding1Ref = useRef(null);
  const onboarding2Ref = useRef(null);
  const onboardingRefs = { onboarding1Ref, onboarding2Ref };

  // Steps for onboarding
  const stepsDefinition = (stepKey, refs) => {
    if (stepKey === 'step3') {
      return [
        {
          target: refs.onboarding1Ref.current,
          content: "📝 Step 1: Select or create a template to start editing. Select Composite.",
          disableBeacon: true,
        },
        {
          target: refs.onboarding2Ref.current,
          content: "💾 Step 2: Copy and paste an already completed Composite note. Delete the LA. The template should reflect your ideal protocol - no 'fill in the blanks' or multiple choice. Your gold standard protocol only. Then save it."
        },
      ];
    }
    return [];
  };

  useEffect(() => {
    const loadTemplates = async () => {
      const uid = auth.currentUser.uid;
      const loadedTemplates = {};

      // Fetch all template documents from Firestore
      const templatesRef = collection(firestore, 'customers', uid, 'templates');
      const querySnapshot = await getDocs(templatesRef);

      querySnapshot.forEach((doc) => {
        loadedTemplates[doc.id] = doc.data().text;
      });

      setTemplates(loadedTemplates);
    };

    loadTemplates();
  }, []);

  const handleTemplateSelection = (templateName) => {
    setSelectedTemplate(templateName);
    setIsCreating(false); // Ensure we're in edit mode
    setNewTemplateName(templateName.trim());
    setNewTemplateContent(templates[templateName]);
  };

  const handleTextChange = (e) => {
    setNewTemplateContent(e.target.value);
  };

  const togglePopup = () => {
    setIsPopupVisible(!isPopupVisible);
  };

  const openCreatePopup = () => {
    setIsCreating(true);
    setSelectedTemplate('');
    setNewTemplateName('');
    setNewTemplateContent('');
    setIsPopupVisible(true);
  };

  /**
   * Save Template Function
   * @param {boolean} shouldTogglePopup - Determines whether to toggle the popup after saving
   */
  const saveNewTemplate = async (shouldTogglePopup = true) => {
    const trimmedTemplateName = newTemplateName.trim();

    if (!trimmedTemplateName || !newTemplateContent.trim()) {
      alert('Please enter a valid template name and content.');
      return;
    }

    try {
      const uid = auth.currentUser.uid;
      const newTemplateRef = doc(firestore, 'customers', uid, 'templates', trimmedTemplateName);
      await setDoc(newTemplateRef, { text: newTemplateContent });

      setTemplates((prevTemplates) => ({
        ...prevTemplates,
        [trimmedTemplateName]: newTemplateContent,
      }));

      if (isCreating) {
        setSelectedTemplate(trimmedTemplateName); // Optionally select the new template
        setIsCreating(false);
      }

      alert('Template saved successfully');
      
      if (shouldTogglePopup) {
        togglePopup();
      }
    } catch (error) {
      console.error('Error saving template:', error);
    }
  };

  const handleDeleteTemplate = async () => {
    if (!selectedTemplate) return;

    const confirmDelete = window.confirm(`Are you sure you want to delete the template "${selectedTemplate}"?`);

    if (confirmDelete) {
      try {
        const uid = auth.currentUser.uid;
        const templateRef = doc(firestore, 'customers', uid, 'templates', selectedTemplate);
        await deleteDoc(templateRef);

        setTemplates((prevTemplates) => {
          const updatedTemplates = { ...prevTemplates };
          delete updatedTemplates[selectedTemplate];
          return updatedTemplates;
        });

        setSelectedTemplate('');
        alert('Template deleted successfully');
      } catch (error) {
        console.error('Error deleting template:', error);
      }
    }
  };

  const filteredTemplates = Object.keys(templates)
  .filter(templateName => 
    templateName.toLowerCase().includes(searchInput.toLowerCase()) &&
    templateName.toLowerCase() !== 'basic exam' // Exclude 'Basic Exam' from the list
  );


  return (
    <main className="w-full p-6">
      {/* Search Bar and New Template Button */}
      <div className="flex items-center justify-between mb-6">
        <input
          type="text"
          autoFocus
          placeholder="Search templates..."
          value={searchInput}
          onChange={(e) => setSearchInput(e.target.value)}
          className="w-2/5 p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary-600"
        />
        <button
          onClick={openCreatePopup} // Use the new function
          className="px-4 py-2 bg-gradient-to-br from-primary-400 to-teal-200 text-white rounded-lg hover:bg-primary-700"
        >
          Create New Template
        </button>
      </div>

      {/* Template List and Editor */}
      <div className="flex flex-col md:flex-row">
        {/* Template List */}
        <div className="md:w-1/3 md:pr-4 mb-6 md:mb-0">
          <div className="bg-white rounded-lg shadow p-4">
            <h3 className="text-lg font-semibold mb-4">Templates</h3>
            <ul className="space-y-2 max-h-[448px] overflow-y-auto" ref={onboarding1Ref}>
              {filteredTemplates.length > 0 ? (
                filteredTemplates.map((templateName) => (
                  <li key={templateName}>
                    <button
                      onClick={() => handleTemplateSelection(templateName)}
                      className={`w-full text-left px-3 py-2 rounded-md ${
                        selectedTemplate === templateName
                          ? 'bg-primary-100 text-primary-700'
                          : 'hover:bg-gray-100'
                      }`}
                    >
                      {templateName}
                    </button>
                  </li>
                ))
              ) : (
                <p className="text-sm text-gray-500">No templates found.</p>
              )}
            </ul>
          </div>
        </div>

        {/* Template Editor */}
        <div className="md:w-2/3" ref={onboarding2Ref}>
          {selectedTemplate ? (
            <div className="bg-white rounded-lg shadow p-4">
              <h3 className="text-lg font-semibold mb-4">Edit Template</h3>
              <textarea
                className="w-full h-96 p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary-600"
                value={newTemplateContent}
                onChange={handleTextChange}
                ref={textareaRef}
              ></textarea>
              <div className="flex justify-end space-x-3 mt-4">
                <button
                  onClick={() => saveNewTemplate(false)} // Pass false to prevent toggling popup
                  className="px-4 py-2 bg-gradient-to-br from-primary-400 to-teal-100 text-white rounded-md hover:bg-primary-700"
                >
                  Save
                </button>
                <button
                  onClick={handleDeleteTemplate}
                  className="px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700"
                >
                  Delete
                </button>
              </div>
            </div>
          ) : (
            <div className="bg-white rounded-lg shadow p-4">
              <p className="text-gray-500">Select a template to edit or create a new one.</p>
            </div>
          )}
        </div>
      </div>

      {/* Popup for New Template */}
      {isPopupVisible && (
        <div className="fixed inset-0 z-50 bg-black bg-opacity-50 flex items-center justify-center p-4">
          <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-2xl max-h-[90vh] overflow-y-auto">
            <h3 className="text-lg font-semibold mb-4">
              {isCreating ? 'New Template' : 'Edit Template'}
            </h3>
            {isCreating && (
              <input
                type="text"
                value={newTemplateName}
                onChange={(e) => setNewTemplateName(e.target.value)}
                placeholder="Template Name"
                className="w-full p-2 mb-4 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary-600"
              />
            )}
            <textarea
              value={newTemplateContent}
              onChange={(e) => setNewTemplateContent(e.target.value)}
              placeholder="Template Content"
              className="w-full h-96 p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary-600"
            ></textarea>
            <div className="flex justify-end space-x-3 mt-4">
              <button
                onClick={() => saveNewTemplate(true)} // Pass true to toggle popup
                className="px-4 py-2 bg-gradient-to-br from-primary-400 to-teal-100 text-white rounded-md hover:bg-primary-700"
                disabled={
                  isCreating
                    ? !newTemplateName.trim() || !newTemplateContent.trim()
                    : !newTemplateContent.trim()
                }
              >
                Save
              </button>
              <button
                onClick={togglePopup}
                className="px-4 py-2 bg-gray-200 text-gray-700 rounded-md hover:bg-gray-300"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

<OnboardingJoyrideWrapper
            stepKey="step3"
            stepsDefinition={stepsDefinition}
            refs={onboardingRefs}
            onboardingStepKey={onboardingStepKey}
            setOnboardingStepKey={setOnboardingStepKey}
            userData={userData}
            openSidebar={openSidebar}
            />    

    </main>
  );
};

export default Templates;
