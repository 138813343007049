import React from 'react';

const SixteenPointAssessment = () => {
    return (
        <div className="bg-gray-100 p-4 rounded-lg">
            <p className="text-sm text-gray-900">
                Create a 16-point checklist based on the patient's note, overall health, and treatment plan.
            </p>
        </div>
    );
};

export default SixteenPointAssessment;
