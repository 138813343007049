import React, { useState, useEffect } from 'react';

const PastExamModal = ({ isOpen, onClose, onUsePastExam, initialNote }) => {
    const [pastExam, setPastExam] = useState(initialNote || '');

    useEffect(() => {
        if (isOpen) {
            setPastExam(initialNote);
        }
    }, [isOpen, initialNote]);

    const handleUsePastExam = () => {
        onUsePastExam(pastExam);
        onClose();
    };

    const handleBackgroundClick = () => {
        onClose();
    };

    const handleContentClick = (event) => {
        event.stopPropagation();
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50" onClick={handleBackgroundClick}>
            <div className="relative p-4 w-full max-w-2xl bg-white rounded-lg shadow max-h-[90vh] overflow-auto" onClick={handleContentClick}>
                <textarea
                    value={pastExam}
                    onChange={(e) => setPastExam(e.target.value)}
                    placeholder="Paste the example note here..."
                    className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300"
                    rows="10"
                ></textarea>
                <div className="flex justify-center mt-4">
                    <button onClick={handleUsePastExam} className="px-4 py-2 text-xs text-primary-400 bg-white border border-gray-200 rounded-s-lg hover:bg-primary-400 hover:text-white">
                        Use
                    </button>
                    <button onClick={onClose} className="px-4 py-2 text-xs text-gray-500 bg-gray-300 rounded-e-lg border border-gray-200">
                        Close
                    </button>
                </div>
            </div>
        </div>
    );
};

export default PastExamModal;
