import React, { useEffect, useRef, useState } from 'react';
import WaveSurfer from 'wavesurfer.js';
import RegionsPlugin from 'wavesurfer.js/dist/plugins/regions.esm.js';

const AudioEditor = ({ audioUrl, onSave, onCancel }) => {
    const waveformRef = useRef(null);
    const wavesurfer = useRef(null);
    const [loop, setLoop] = useState(true);
    const [activeRegion, setActiveRegion] = useState(null); // Correctly track the active region
    const regionsPluginRef = useRef(null); // Reference to the Regions Plugin
    const startPosition = useRef(null); // To store the initial click position
    const regionCounter = useRef(0); // Counter to assign unique numbers to regions

    const random = (min, max) => Math.random() * (max - min) + min;
    const randomColor = () => `rgba(${random(0, 255)}, ${random(0, 255)}, ${random(0, 255)}, 0.5)`;

    useEffect(() => {
        if (!waveformRef.current) return; // Ensure the ref exists

        // Initialize the Regions Plugin
        regionsPluginRef.current = RegionsPlugin.create({
            dragSelection: {
                color: 'rgba(255, 0, 0, 0.1)', // Region color
            },
        });

        // Initialize WaveSurfer with the Regions Plugin
        wavesurfer.current = WaveSurfer.create({
            container: waveformRef.current,
            waveColor: 'rgb(200, 0, 200)',
            progressColor: 'rgb(100, 0, 100)',
            backend: 'MediaElement',
            plugins: [regionsPluginRef.current],
        });

        // Load audio file
        wavesurfer.current.load(audioUrl);

        // Handle mouse events for region creation
        const handleMouseDown = (e) => {
            if (!waveformRef.current) return; // Ensure the ref exists
            const rect = waveformRef.current.getBoundingClientRect();
            const clickX = e.clientX - rect.left;
            const duration = wavesurfer.current.getDuration();
            const totalWidth = rect.width;
            startPosition.current = (clickX / totalWidth) * duration; // Calculate the start time
        };

        const handleMouseUp = (e) => {
            if (!waveformRef.current) return; // Ensure the ref exists
            const rect = waveformRef.current.getBoundingClientRect();
            const clickX = e.clientX - rect.left;
            const duration = wavesurfer.current.getDuration();
            const totalWidth = rect.width;
            const endPosition = (clickX / totalWidth) * duration; // Calculate the end time

            if (startPosition.current !== null && endPosition !== startPosition.current) {
                regionCounter.current += 1; // Increment the region counter
                const newRegion = regionsPluginRef.current.addRegion({
                    id: regionCounter.current, // Assign a unique number ID
                    start: Math.min(startPosition.current, endPosition),
                    end: Math.max(startPosition.current, endPosition),
                    color: randomColor(),
                });

                setActiveRegion(newRegion);
                console.log(`Region #${newRegion.id} added:`, newRegion); // Log the region number
            }
        };

        waveformRef.current.addEventListener('mousedown', handleMouseDown);
        waveformRef.current.addEventListener('mouseup', handleMouseUp);

        // Handle spacebar to play/pause
        const handleKeyPress = (e) => {
            if (e.code === 'Space') {
                e.preventDefault();
                wavesurfer.current.isPlaying() ? wavesurfer.current.pause() : wavesurfer.current.play();
            }
        };

        document.addEventListener('keydown', handleKeyPress);

        // Cleanup
        return () => {
            if (waveformRef.current) {
                waveformRef.current.removeEventListener('mousedown', handleMouseDown);
                waveformRef.current.removeEventListener('mouseup', handleMouseUp);
            }

            document.removeEventListener('keydown', handleKeyPress);
            if (wavesurfer.current) {
                wavesurfer.current.destroy();
            }
        };
    }, [audioUrl]);

    // Add region click and looping behavior
    useEffect(() => {
        if (!regionsPluginRef.current) return;

        // Handle region click event to select and play the region
        const handleRegionClick = (region, e) => {
            e.stopPropagation(); // Prevent the waveform click from interfering
            setActiveRegion(region); // Set this region as the active region
            region.play(); // Play the clicked region
            console.log(`Region #${region.id} clicked and playing`);
        };

        // Handle region 'in' event to activate the region
        const handleRegionIn = (region) => {
            setActiveRegion(region);
            console.log('Region In:', region);
        };

        // Handle region 'out' event for looping behavior
        const handleRegionOut = (region) => {
            console.log('Region Out:', region);
            if (activeRegion === region) {
                if (loop) {
                    console.log('Looping Region:', region);
                    region.play(); // Replay the region if looping is enabled
                } else {
                    setActiveRegion(null);
                }
            }
        };

        // Add event listeners to the regions
        regionsPluginRef.current.on('region-click', handleRegionClick);
        regionsPluginRef.current.on('region-in', handleRegionIn);
        regionsPluginRef.current.on('region-out', handleRegionOut);

        // Cleanup the event listeners
        return () => {
            regionsPluginRef.current.un('region-click', handleRegionClick);
            regionsPluginRef.current.un('region-in', handleRegionIn);
            regionsPluginRef.current.un('region-out', handleRegionOut);
        };
    }, [loop, activeRegion]);

    // Add functionality to remove the selected region
    const handleCutSelectedRegion = () => {
        if (activeRegion) {
            console.log(`Removing Region #${activeRegion.id}`);
            activeRegion.remove(); // Remove the selected region
            setActiveRegion(null); // Clear the active region after removal
        } else {
            console.log('No active region selected to remove');
        }
    };

    const handleZoom = (e) => {
        const zoomLevel = Number(e.target.value);
        wavesurfer.current.zoom(zoomLevel);
    };

    const handleCutSection = async () => {
        if (!activeRegion) {
            console.log('No active region selected to cut');
            return;
        }
    
        const { start, end } = activeRegion;
    
        // Validate start and end points
        const audioDuration = wavesurfer.current.getDuration();
        if (
            isNaN(start) || 
            isNaN(end) || 
            start < 0 || 
            end > audioDuration || 
            start >= end
        ) {
            console.error('Invalid start or end values. Please select a valid region within the audio bounds.');
            alert('Invalid selection. Please select a valid region within the audio bounds.');
            return;
        }
    
        try {
            // Get the audio file as a Blob or File object
            const response = await fetch(audioUrl);
            if (!response.ok) {
                console.error('Failed to fetch audio file:', response.statusText);
                alert('Failed to retrieve the audio file. Please try again.');
                return;
            }
            const blob = await response.blob();
    
            // Create form data to send the audio file and cutting parameters
            const formData = new FormData();
            formData.append('audio', blob, 'audio-file.webm'); // Uploading as webm
            formData.append('start', start);
            formData.append('end', end);
    
            console.log('Form data created, starting upload to backend.');
    
            // Send to backend API for processing
            const apiResponse = await fetch('https://us-central1-digitaltco-c40e9.cloudfunctions.net/cutAudioSection', {
                method: 'POST',
                body: formData,
            });
    
            if (!apiResponse.ok) {
                console.error('Failed to cut audio:', apiResponse.statusText);
                alert('Failed to cut the audio section. Please try again.');
                return;
            }
    
            console.log('Audio cut successfully by backend, preparing to load new audio.');
    
            // Get the new audio URL from the backend
            const newAudioBlob = await apiResponse.blob();
            const newAudioUrl = URL.createObjectURL(newAudioBlob);
    
            // Load the new audio file into WaveSurfer
            wavesurfer.current.load(newAudioUrl);
            setActiveRegion(null);
            console.log('Audio section cut successfully and loaded into WaveSurfer');
        } catch (error) {
            console.error('Error cutting section:', error);
            alert('An error occurred while cutting the audio section. Please try again.');
        }
    };
            

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 p-4">
            <div className="bg-white border-gray-300 border rounded-lg shadow-lg p-8 w-3/5 text-center">
                <h2 className="text-xl font-bold mb-4">Edit Audio</h2>

                {/* Waveform Container */}
                <div ref={waveformRef} className="w-full h-32 bg-gray-100 border rounded mb-4"></div>

                {/* Loop Checkbox */}
                <label className="block text-left mb-4">
                    <input
                        type="checkbox"
                        checked={loop}
                        onChange={() => setLoop(!loop)}
                    />{' '}
                    Loop regions
                </label>

                {/* Zoom Control */}
                <label className="block text-left mb-4">
                    Zoom:
                    <input
                        type="range"
                        min="10"
                        max="1000"
                        defaultValue="10"
                        onChange={handleZoom}
                        className="ml-2"
                    />
                </label>

                {/* Control Buttons */}
                <div className="mt-6 flex space-x-4 justify-center">
                    <button
                        className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
                        onClick={handleCutSelectedRegion} // Remove the active region
                    >
                        Cut Selected Region
                    </button>
                    <button
                        className="px-4 py-2 bg-yellow-500 text-white rounded hover:bg-yellow-600"
                        onClick={handleCutSection} // Cut section from waveform
                    >
                        Cut Section
                    </button>
                    <button
                        className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
                        onClick={() => onSave()} // Adjust this as per your implementation
                    >
                        Save Changes
                    </button>
                    <button
                        className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600"
                        onClick={onCancel}
                    >
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    );
};

export default AudioEditor;
