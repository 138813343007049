import React from 'react';
import './UseDesktopAdvice.css'; // Create this CSS file for styling if needed

const UseDesktopAdvice = () => {
  return (
    <div className="advice-container">
      <div className="advice-content">
        <h1 className="advice-header">Mobile Not Supported (Yet!)</h1>
        <p className="advice-message">
          Hey there, dental superstar! We know you’re excited to let DigitalTCO take the wheel and write those life-changing notes for you. But here’s the deal – our magic works best on a desktop or laptop. 
        </p>
        <p className="advice-message">
          Why, you ask? Well, you need to copy and paste the notes into your patient management software, and those softwares are useless on mobile.
        </p>
        <p className="advice-message">
          So, do us a favor and switch to a bigger screen please.
        </p>
        <p className="advice-message">
          PS: Mobile support is coming soon. Stay tuned, and keep being awesome!
        </p>
      </div>
    </div>
  );
};

export default UseDesktopAdvice;
