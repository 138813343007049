// UserContext.js

import React, { createContext, useContext, useState, useEffect } from 'react';
import { auth, firestore } from '../firebase';
import { doc, getDoc, collection, getDocs } from 'firebase/firestore'; // Added collection and getDocs


// 1. Provide a default value
const UserContext = createContext({
  isLoading: true,
  userStatus: null,
});

export const UserProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [userStatus, setUserStatus] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        const userRef = doc(firestore, 'customers', user.uid);
        const userDoc = await getDoc(userRef);

        if (userDoc.exists()) {
          setUserStatus(userDoc.data().status);
        } else {
          setUserStatus('nonexistent');
        }
      } else {
        setUserStatus('unauthenticated');
      }
      setIsLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const contextValue = {
    isLoading,
    userStatus,
  };

  return (
    <UserContext.Provider value={contextValue}>
      {children}
    </UserContext.Provider>
  );
};

// 2. Add an additional check in useUser hook
export const useUser = () => {
    const context = useContext(UserContext);
    const { isLoading, userStatus } = context;
  
    const isSubscriptionActive = async (uid) => {
      const subscriptionsCollection = collection(firestore, 'customers', uid, 'subscriptions');
      const subscriptionDocs = await getDocs(subscriptionsCollection);
      let status = 'none'; // Default status when no subscriptions are found
    
      for (const docSnapshot of subscriptionDocs.docs) {
        const subscriptionData = docSnapshot.data();
        if (subscriptionData.status === 'active' || subscriptionData.status === 'trialing') {
          status = subscriptionData.status;
          break;
        } else if (subscriptionData.status === 'canceled') {
          status = 'canceled'; // Continue checking other docs, but remember the cancelled status
        }
      }
      return status;
    };    
  
    if (context === undefined) {
      throw new Error('useUser must be used within a UserProvider');
    }
  
    return { isLoading, userStatus, isSubscriptionActive };
  };
  