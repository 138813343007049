import React from 'react';
import ThreeDotsWave from '../patientnotes/ThreeDotsWave';
import './LoadingPopup2.css';

const LoadingPopup2 = () => {
  const loadingMessages = [
    "Wow. You've been a busy bee explaining lots of stuff. This will take a few minutes, you rascal.",
  ];

  const randomMessage = loadingMessages[Math.floor(Math.random() * loadingMessages.length)];

  return (
    <div className="loading-popup-overlay">
      <div className="loading-popup-content">
        <ThreeDotsWave />
        <p>{randomMessage}</p>
        <div className="loading-bar2"></div>
        <p className="small-popup-text">Full appointments take time. Like patients with their jackets in winter.</p>
      </div>
    </div>
  );
};

export default LoadingPopup2;
