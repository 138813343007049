import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebase';
import { useSession } from './SessionContext';
import { useUser } from './UserContext';

function withPaidAccess(WrappedComponent, allowedRoles = ['Essentials', 'Performance']) {
  return function PaidAccessWrapper(props) {
    const navigate = useNavigate();
    const { isSessionValid } = useSession();
    const { isSubscriptionActive } = useUser();
    const [isLoading, setIsLoading] = useState(true);
    const [user, setUser] = useState(null);
    const [roles, setRoles] = useState([]);

    useEffect(() => {
      const unsubscribe = auth.onAuthStateChanged(async currentUser => {
        if (currentUser) {
          setUser(currentUser);
          checkSubscriptionAndRoles(currentUser);
        } else {
          // console.log("No user logged in, redirecting to login");
          navigate('/login', { replace: true });
        }
      });

      return () => {
        // console.log("Cleaning up onAuthStateChanged listener");
        unsubscribe();
      };
    }, [navigate]);

    const checkSubscriptionAndRoles = async (currentUser) => {
      const status = await isSubscriptionActive(currentUser.uid);
      if (status !== 'active' && status !== 'trialing') {
        handleInactiveSubscription(status);
        setIsLoading(false);
        return;
      }

      const idTokenResult = await currentUser.getIdTokenResult(true);
      const currentRoles = idTokenResult.claims.roles || [idTokenResult.claims.role].filter(Boolean);

      if (currentRoles.some(role => allowedRoles.includes(role))) {
        setRoles(currentRoles);
        setIsLoading(false);
      } else {
        navigate('/mvp/unauthorized', { replace: true });
        setIsLoading(false);
      }
    };

    const handleInactiveSubscription = (status) => {
      if (status === 'canceled') {
        // console.log('Redirecting to payment-cancelled, subscription is cancelled');
        navigate('/payment-cancelled', { replace: true });
      } else {
        // console.log('Redirecting to payment, subscription is inactive');
        navigate('/payment', { replace: true });
      }
    };

    if (isLoading) {
      return <div>Loading...</div>;
    }

    if (user && roles.length > 0 && isSessionValid) {
      // console.log('Rendering WrappedComponent, subscription, session, and roles are valid');
      return <WrappedComponent {...props} />;
    }

    // Additional safety net if checks fall through without redirecting or loading
    // console.log('Final check fails, likely due to missing session validation');
    return null;
  };
}

export default withPaidAccess;
