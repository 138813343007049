import React, { useEffect, useRef } from 'react';

const ActiveAudioAnalyser = ({ audioStream, isPaused, onAudioDetected }) => {
    const canvasRef = useRef(null);
    const audioDetectedRef = useRef(false); // Keeps track if audio has ever been detected

    useEffect(() => {
        const audioContext = new (window.AudioContext || window.webkitAudioContext)();
        const analyser = audioContext.createAnalyser();
        const source = audioContext.createMediaStreamSource(audioStream);
        source.connect(analyser);
        analyser.fftSize = 2048;

        const canvas = canvasRef.current;
        const canvasContext = canvas.getContext('2d');
        const bufferLength = analyser.frequencyBinCount;
        const dataArray = new Uint8Array(bufferLength);

        let animationFrameId;

        const draw = () => {
            animationFrameId = requestAnimationFrame(draw);
            analyser.getByteFrequencyData(dataArray);

            // Detect if there is audio playing
            const isAudioPlaying = dataArray.some(value => value > 5); // Adjusted threshold

            // If audio is detected for the first time, notify the parent component
            if (isAudioPlaying && !audioDetectedRef.current) {
                audioDetectedRef.current = true;
                // console.log('Audio detected for the first time'); // Debugging
                if (onAudioDetected) {
                    onAudioDetected(true); // Notify parent component
                }
            }

            // Set canvas color based on audio activity
            if (isPaused) {
                canvasContext.fillStyle = 'orange';
            } else if (isAudioPlaying) {
                canvasContext.fillStyle = '#1dc517';
            } else {
                canvasContext.fillStyle = 'red';
            }
            canvasContext.fillRect(0, 0, canvas.width, canvas.height);

            // Draw waveform bars
            const barWidth = (canvas.width / bufferLength) * 20;
            let barHeight;
            let x = 0;

            for (let i = 0; i < bufferLength; i++) {
                barHeight = dataArray[i];
                canvasContext.fillStyle = 'white'; // Waveform color
                canvasContext.fillRect(x, canvas.height - barHeight / 2, barWidth, barHeight / 2);
                x += barWidth + 1;
            }
        };

        draw();

        return () => {
            if (animationFrameId) {
                cancelAnimationFrame(animationFrameId);
            }
            audioContext.close();
        };
    }, [audioStream, isPaused, onAudioDetected]);

    return (
        <div>
            <canvas
                ref={canvasRef}
                width="100%"
                height="145px"
                style={{ borderRadius: '10px' }}
            />
        </div>
    );
};

export default ActiveAudioAnalyser;
