import React from "react";
import { motion } from "framer-motion";

const loadingContainer = {
  width: "2rem",
  height: "2rem",
  display: "flex",
  justifyContent: "space-around"
};

const loadingCircle = {
  display: "block",
  width: "0.5rem",
  height: "0.5rem",
  backgroundColor: "#cd66ef",
  borderRadius: "0.25rem"
};

const loadingCircleTransition = {
  duration: 0.5,
  repeat: Infinity, // Continuous looping
  repeatType: "mirror", // Reverse the animation
  ease: "easeInOut"
};

export default function ThreeDotsWave() {
  return (
    <motion.div
      style={loadingContainer}
      initial="start"
      animate="end"
      variants={{
        start: {
          transition: {
            staggerChildren: 0.2
          }
        },
        end: {
          transition: {
            staggerChildren: 0.2
          }
        }
      }}
    >
      {[...Array(3)].map((_, index) => (
        <motion.span
          key={index}
          style={loadingCircle}
          variants={{
            start: { y: "0%" },
            end: { y: "100%" }
          }}
          transition={loadingCircleTransition}
        />
      ))}
    </motion.div>
  );
}
