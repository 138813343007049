import React, { useState, useEffect } from 'react';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { doc, setDoc, getFirestore, collection, getDoc } from 'firebase/firestore';
import { auth, firestore } from '../../firebase'; // Adjust the import path as needed
import LoadingPopup from '../patientnotes/LoadingPopup';
import { dentistDetails, introduction, generalDentalRisks, dentalcareDetails, compositeDetails, rootCanalDetails, crownOrOnlayDetails, dentureDetails, hygieneDetails, bridgeDetails, extractionDetails, treatmentAgreement, whiteningDetails, dentalimplantDetails, patientBehaviour, amalgamDetails, fissuresealantDetails, stainlesssteelcrownDetails, compositebondingDetails, invisalignDetails, fixedorthoDetails, veneerDetails, sedationDetails, fullarchimplantsDetails, immediatedentureDetails, enlightenDetails } from '../TreatmentDetails';
import axios from 'axios';

const MolarMailerConsent = ({ noteContent, patientId, className }) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [showLoadingPopup, setShowLoadingPopup] = useState(false);
  const [userProfile, setUserProfile] = useState(null);
  const [consentForms, setConsentForms] = useState({});
  const [letterheadURL, setLetterheadURL] = useState('');


  useEffect(() => {
    const fetchUserProfile = async () => {
      const user = auth.currentUser;
      if (user) {
          const userRef = doc(firestore, 'customers', user.uid);
          const userDoc = await getDoc(userRef);
          if (userDoc.exists()) {
              setUserProfile(userDoc.data());
              if (userDoc.data().letterheadURL) {
                  setLetterheadURL(userDoc.data().letterheadURL);
              }
              // console.log("User Profile:", userDoc.data());
              // console.log("letterhead:", letterheadURL);
          }
      }
  };

    const fetchConsentForms = async () => {
      const uid = auth.currentUser.uid;
      const consentFormsRef = doc(firestore, 'customers', uid, 'templates', 'consentforms');

      try {
        const consentFormsSnap = await getDoc(consentFormsRef);
        let customConsentForms = {};

        if (consentFormsSnap.exists()) {
          customConsentForms = consentFormsSnap.data();
        } else {
          // console.log('No custom consent form data found. Using default forms.');
        }

        // Merge custom consent forms with default consent forms
        setConsentForms({
          introduction,
          generalDentalRisks,
          dentalcareDetails,
          compositeDetails,
          rootCanalDetails,
          crownOrOnlayDetails,
          dentureDetails,
          hygieneDetails,
          bridgeDetails,
          extractionDetails,
          treatmentAgreement,
          whiteningDetails,
          dentalimplantDetails,
          patientBehaviour,
          amalgamDetails,
          fissuresealantDetails,
          stainlesssteelcrownDetails,
          compositebondingDetails,
          invisalignDetails,
          fixedorthoDetails,
          veneerDetails,
          sedationDetails,
          fullarchimplantsDetails,
          immediatedentureDetails,
          enlightenDetails,
          ...customConsentForms // Merge custom consent forms here
        });
      } catch (error) {
        console.error('Error fetching custom consent form data:', error);
      }
    };

    fetchUserProfile();
    fetchConsentForms();
  }, []);

  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed in JavaScript
    const year = String(date.getFullYear()).substr(-2); // Get last two digits of the year

    return `${day}/${month}/${year}`;
  }

  const todayDate = formatDate(new Date());

  const stripHtml = (htmlString) => {
    return htmlString.replace(/<[^>]*>?/gm, '');
  };

  const generateDOCX = async (docxContent) => {
    try {
      // console.log("Starting DOCX generation process");
  
      const user = auth.currentUser;
      if (!user) {
        throw new Error("User not authenticated");
      }
  
      const idToken = await user.getIdToken();
  
      // Fetching the DOCX from DocxCreator
      const response = await fetch("https://us-central1-digitaltco-c40e9.cloudfunctions.net/DocxCreatorMM", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${idToken}`, // Attach Bearer token for authentication
        },
        body: JSON.stringify({
          textContent: docxContent,
          letterheadURL: userProfile?.letterheadURL || "", // Include letterhead URL if available
        }),
      });
  
      // Parse the response JSON
      const responseJson = await response.json();
      // console.log("Server Response:", responseJson);
  
      if (!responseJson.base64) {
        throw new Error("Missing base64 field in the server response");
      }
  
      // Convert base64 string to a Blob
      const docxBase64 = responseJson.base64;
      const blob = base64ToBlob(docxBase64, "application/vnd.openxmlformats-officedocument.wordprocessingml.document");
  
      // Create download link
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `Consent - Dr. ${userProfile.name}.docx`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
  
      // console.log("DOCX generation and download process completed");
    } catch (error) {
      console.error("Error:", error);
      // Handle errors
    }
  };
  
  // Helper function to convert base64 to Blob
  function base64ToBlob(base64, mimeType) {
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: mimeType });
  }


  const handleButtonClick = async () => {
    setIsProcessing(true);
    setShowLoadingPopup(true);
    // console.log("Sending content to cloud function:", noteContent);
    try {
      const user = auth.currentUser;
      if (!user) {
        throw new Error('User not authenticated');
      }
      const idToken = await user.getIdToken();
      
      // Step A: Parse text for treatment plan array
      const consentFormKeys = Object.keys(consentForms);

      const treatArrayResponse = await fetch('https://us-central1-digitaltco-c40e9.cloudfunctions.net/molarmailIdArray', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`, // Attach Bearer token for authentication
        },
        body: JSON.stringify({ prompt: noteContent, keys: consentFormKeys }),
      });

      // console.log("ID array:", treatArrayResponse)

      if (!treatArrayResponse.ok) {
        throw new Error('Network response was not ok in PatientLetters');
      }

      const treatmentArrayResponse = await treatArrayResponse.json();
      let treatmentArrayText = treatmentArrayResponse.text;

      // Remove the unwanted characters and phrases
      const treatmentArrayCode = treatmentArrayText.replace(/```/g, '').replace(/jsx/g, '').trim();

      // console.log("Parsed Tx Plan Array:", treatmentArrayCode);

      // console.log("Creating patient letter...");

      // Step 1: Send content to PatientLetters to format it
      const letterResponse = await fetch('https://us-central1-digitaltco-c40e9.cloudfunctions.net/molarmailerIntroDOCX', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`,
        },
        body: JSON.stringify({ prompt: noteContent }),
      });

      if (!letterResponse.ok) {
        throw new Error('Network response was not ok in PatientLetters');
      }

      const letterData = await letterResponse.json();
      // console.log("Formatted letter content:", letterData.text);

      // console.log("DocX gen now...");

      const removeLeadingWhitespace = (htmlString) => {
        return htmlString
          .replace(/^\s+</gm, '<')
          .replace(/<\/?(ol|ul|strong|i|u|b)>/gm, '');
      };

      const formatHTMLText = (htmlText) => {
        let formattedText = htmlText
          .replace(/(<(p|h1|h2|h3|li|div)[^>]*>)/g, "\n$1")
          .replace(/<\/?(ul|ol|h4|h5|h6|strong|br)>/g, "");

        return formattedText;
      };

      let introContent = removeLeadingWhitespace(`Dr. ${userProfile.name}\n${userProfile.practiceName}\n${userProfile.practiceAddress}\n${userProfile.practicePostcode}\n${userProfile.practiceEmail}\n${userProfile.practicePhoneNumber}\n<br>\n${todayDate}\n<br>\n`);
      let generalDentalRisks = formatHTMLText(consentForms.generalDentalRisks);
      let dentalCareDetails = formatHTMLText(consentForms.dentalcareDetails);
      let patientBehaviour = formatHTMLText(consentForms.patientBehaviour);
      let treatmentAgreement = formatHTMLText(consentForms.treatmentAgreement);

      let docXContent = removeLeadingWhitespace(`${introContent.trim()}\n` +
        `<br>\n` +
        `<p>Dear ${patientId},</p>\n` +
        `${letterData.text.trim()}\n<br>\n` +
        `${generalDentalRisks.trim()}\n<br>\n`);

      // Include the selected treatments in the document
      for (let key in consentForms) {
        if (treatmentArrayCode.includes(key) && !['introduction', 'generalDentalRisks', 'dentalcareDetails', 'patientBehaviour', 'treatmentAgreement'].includes(key)) {
          docXContent += removeLeadingWhitespace(`${formatHTMLText(consentForms[key]).trim()}\n<br>\n`);
        }
      }

      docXContent += removeLeadingWhitespace(
        `${dentalCareDetails.trim()}\n<br>\n` +
        `${patientBehaviour.trim()}\n<br>\n` +
        `${treatmentAgreement.trim()}`
      );

      // console.log("DocX Content:", docXContent);

      await generateDOCX(docXContent);

      setIsProcessing(false);
      setShowLoadingPopup(false);

    } catch (error) {
      console.error('Error:', error);
      setIsProcessing(false);
      setShowLoadingPopup(false);
      alert('Please go to "Profile" and add your letterhead/logo and profile details, then try again.');
    }
  };

  return (
    <>
      <button onClick={handleButtonClick} disabled={isProcessing} className={className}>
        {isProcessing ? 'Writing Letter...' : 'Consent'}
      </button>

      {showLoadingPopup && <LoadingPopup />}
    </>
  );
};

export default MolarMailerConsent;
