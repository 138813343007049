import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { firestore, auth } from '../../firebase';
import PatientProfileNav from './PatientProfileNav';
import './PatientNotesInput.css'; 

const IntraoralExam = () => {
  const [intraoralData, setIntraoralData] = useState({
    mucosa: '',
    floorOfMouth: '',
    hardPalate: '',
    softPalate: '',
    tongue: '',
  });
  const navigate = useNavigate();
  const { patientId } = useParams(); // Get the patientId from the URL parameters

  useEffect(() => {
    const patientRef = doc(firestore, 'customers', auth.currentUser.uid, 'patients', patientId);

    // Fetch the current intraoral exam data from Firestore
    const fetchIntraoralData = async () => {
      const patientDoc = await getDoc(patientRef);
      if (patientDoc.exists() && patientDoc.data().notes && patientDoc.data().notes.intraoralExam) {
        setIntraoralData(patientDoc.data().notes.intraoralExam); // Set the intraoral exam data to the local state
      }
    };

    fetchIntraoralData();
  }, [patientId]);

  const handleNext = async () => {
    const patientRef = doc(firestore, 'customers', auth.currentUser.uid, 'patients', patientId);

    // Update the patient document with the intraoral exam data
    await updateDoc(patientRef, {
      'notes.intraoralExam': intraoralData,
    });

    // Navigate to the next step
    navigate(`/mvp/patient-profile/${patientId}/clinical-caries`);
  };

  const handleInputChange = (field, value) => {
    setIntraoralData({ ...intraoralData, [field]: value });
  };

  return (
    <div className="patient-complaints-container">
      <PatientProfileNav patientId={patientId} />
      <h2 className="patient-complaints-h2">Intraoral Exam</h2>
      <div className="patient-complaints-input-group">
        <label className="patient-complaints-label">Mucosa:</label>
        <input
          className="patient-complaints-input"
          type="text"
          value={intraoralData.mucosa}
          onChange={(e) => handleInputChange('mucosa', e.target.value)}
          onKeyPress={(e) => e.key === 'Enter' && handleNext()}
          autoFocus
        />
      </div>
      <div className="patient-complaints-input-group">
        <label className="patient-complaints-label">Floor of Mouth:</label>
        <input
          className="patient-complaints-input"
          type="text"
          value={intraoralData.floorOfMouth}
          onChange={(e) => handleInputChange('floorOfMouth', e.target.value)}
          onKeyPress={(e) => e.key === 'Enter' && handleNext()}
        />
      </div>
      <div className="patient-complaints-input-group">
        <label className="patient-complaints-label">Hard Palate:</label>
        <input
          className="patient-complaints-input"
          type="text"
          value={intraoralData.hardPalate}
          onChange={(e) => handleInputChange('hardPalate', e.target.value)}
          onKeyPress={(e) => e.key === 'Enter' && handleNext()}
        />
      </div>
      <div className="patient-complaints-input-group">
        <label className="patient-complaints-label">Soft Palate:</label>
        <input
          className="patient-complaints-input"
          type="text"
          value={intraoralData.softPalate}
          onChange={(e) => handleInputChange('softPalate', e.target.value)}
          onKeyPress={(e) => e.key === 'Enter' && handleNext()}
        />
      </div>
      <div className="patient-complaints-input-group">
        <label className="patient-complaints-label">Tongue:</label>
        <input
          className="patient-complaints-input"
          type="text"
          value={intraoralData.tongue}
          onChange={(e) => handleInputChange('tongue', e.target.value)}
          onKeyPress={(e) => e.key === 'Enter' && handleNext()}
        />
      </div>
      <button className="patient-complaints-button" onClick={handleNext}>Next</button>
    </div>
  );
};

export default IntraoralExam;
