import React, { useEffect, useState, useRef, forwardRef } from 'react';
import Joyride, { STATUS, TooltipRenderProps, BeaconRenderProps } from 'react-joyride';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandPointer } from '@fortawesome/free-solid-svg-icons'; // Import the icon you want to use
import { doc, setDoc } from 'firebase/firestore';
import { auth, firestore } from '../../firebase'; // Make sure the import path is correct

// Animation for the beacon button
const pulse = keyframes`
  0% {
    transform: scale(1);
  }

  55% {
    background-color: #cd66ef;
    transform: scale(1.6);
  }
`;

// Styled Beacon Button with animation
const BeaconButton = styled.button`
  animation: ${pulse} 1s ease-in-out infinite;
  background-color: #f7e9fe;
  border: 0;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.5rem;
  width: 2.5rem;
  position: absolute;
  top: -10px; /* Custom positioning */
  left: -10px; /* Custom positioning */
  cursor: pointer;
`;

// Custom Beacon component with FontAwesome icon
const BeaconComponent = forwardRef((props, ref) => {
  return (
    <BeaconButton ref={ref} {...props}>
      <FontAwesomeIcon icon={faHandPointer} color="white" size="lg" /> {/* Add the icon */}
    </BeaconButton>
  );
});

// Styled components for customizing Joyride tooltip
const TooltipContainer = styled.div`
  background-color: white;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  max-width: 350px;
  min-width: 250px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
`;

const TooltipHeader = styled.h3`
  color: #2d3748;
  margin: 0;
  padding: 16px;
  font-size: 16px;
  border-bottom: 1px solid #e2e8f0;
`;

const TooltipContent = styled.div`
  padding: 16px;
  font-size: 14px;
  color: #4a5568;
`;

const TooltipFooter = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px; /* Small gap between the buttons */
  padding: 8px 16px;
  background-color: #f7fafc;
  border-top: 1px solid #e2e8f0;
`;

const CustomButton = styled.button`
  background-color: #cd66ef;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  font-size: 12px;
  cursor: pointer;
  margin-left: 4px;
  &:hover {
    background-color: #f7e9fe; /* Hover color */
  }
`;

const LightGrayButton = styled.button`
  background-color: #e2e8f0; /* Light gray color */
  color: #4a5568;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  font-size: 12px;
  cursor: pointer;
  &:hover {
    background-color: #cbd5e0; /* Hover color */
  }
`;

function Tooltip({
  backProps,
  continuous,
  index,
  isLastStep,
  primaryProps,
  skipProps,
  tooltipProps,
  step,
  handleDoNotShowAgain, // Passing the "Do not show again" handler
}: TooltipRenderProps & { handleDoNotShowAgain: () => void }) {
  return (
    <TooltipContainer {...tooltipProps}>
      {step.title && <TooltipHeader>{step.title}</TooltipHeader>}
      {step.content && <TooltipContent>{step.content}</TooltipContent>}
      <TooltipFooter>
        <LightGrayButton onClick={handleDoNotShowAgain}>Do not show again</LightGrayButton>
        <div>
            <LightGrayButton {...skipProps}>Skip</LightGrayButton>
          {index > 0 && <CustomButton {...backProps}>Back</CustomButton>}
          <CustomButton {...primaryProps}>{isLastStep ? 'Finish' : 'Next'}</CustomButton>
        </div>
      </TooltipFooter>
    </TooltipContainer>
  );
}

const JoyrideGuide = ({ showJoyride, setShowJoyride }) => {
  const [joyrideSteps, setJoyrideSteps] = useState([]);
  const helpers = useRef(null);

  useEffect(() => {
    setJoyrideSteps([
      {
        target: '.sidebar-link-main',
        content: 'Our recommended features. Start with Super Dentist Cheat Mode - just present the case.',
      },
      {
        target: '.sidebar-link-aux',
        content: "These tools are still available for those who prefer them, but for the best results, new dentists should start with the recommended top 3 above. Existing users, try the latest features—there's nothing new to learn, just better, faster note-taking!",
      },
      {
        target: '.sidebar-link-settings',
        content: 'Customize your experience here: manage templates, update your profile, and fine-tune settings, including your preferred tooth charting.',
      },
      {
        target: '.sidebar-link-learn',
        content: 'Unlock your full potential with these quick videos. Start with the first four—they’ll take just 10 minutes but could save you days of work. Your future self will thank you.',
      },
    ]);
  }, []);

  const handleJoyrideCallback = (data) => {
    const { status } = data;
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      setShowJoyride(false);
    }
  };

  const handleDoNotShowAgain = async () => {
    const user = auth.currentUser;
    if (user) {
      const userRef = doc(firestore, 'customers', user.uid);
      await setDoc(userRef, { showJoyride: false }, { merge: true }); // Save the preference to Firestore
    }
    setShowJoyride(false); // Hide the Joyride
  };

  return (
    <Joyride
      steps={joyrideSteps}
      continuous
      showSkipButton
      showProgress
      callback={handleJoyrideCallback}
      run={showJoyride}
      beaconComponent={BeaconComponent} // Apply the custom Beacon component
      tooltipComponent={(props) => <Tooltip {...props} handleDoNotShowAgain={handleDoNotShowAgain} />}
      styles={{
        options: {
          zIndex: 10000,
        },
      }}
    />
  );
};

export default JoyrideGuide;
