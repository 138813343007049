import React from 'react';

const PowerHour = () => {
  return (
      <div className="">
    <section className="px-4 py-8 sm:px-6 sm:py-12 lg:px-8 lg:py-16">
      <div className="flex gap-x-4">
        
        {/* Information on the left */}
        <div className="flex flex-col items-center mx-auto text-left w-3/4 md:w-2/3 lg:w-1/2 bg-white p-6">
            <p className="mt-4 text-gray-700 tracking-tighter">
                We know there’s a learning curve with any new system, but don’t let that stop you. Join us for the Power Hour on 
                <span className="font-semibold text-primary-500"> Thursday, 29th at 1 PM</span>! 
            </p>

            <p className="mt-4 text-gray-700 tracking-tighter">
                For the best experience—including live chat—click the button below to watch the session on YouTube. This will allow you to ask questions in real-time and engage directly with the content.
            </p>
            
            <button
                onClick={() => window.open("https://youtube.com/live/1cSLTkU0cAs?feature=share", "_blank")}
                className="animate-bounce mt-8 ml-8 inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-xs font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-primary-500 to-pink-500 group-hover:from-purple-500 group-hover:to-pink-500 hover:text-white focus:ring-4 focus:outline-none focus:ring-purple-200"
            >
                <span className="relative px-5 py-1.5 transition-all ease-in duration-75 bg-white rounded-md group-hover:bg-opacity-0">
                Click Here Now To Watch on YouTube
                </span>
            </button>
            
            <hr className="border border-primary-200 border-dashed border-1 w-full mt-8" />

            <p className="mt-8 text-gray-400 tracking-tighter">
                This is your golden opportunity to unlock the full potential of DigitalTCO. We’ll be live for a full hour, answering all your questions and showing you how to get the absolute most out of the platform.
            </p>
            
            
            
            <p className="mt-8 text-gray-400 tracking-tighter">
                The Power Hour is designed to help you conquer any challenges and make the most of DigitalTCO. Whether you’re curious about specific features, workflows, or just need tips to streamline your practice, we’re here to guide you.
            </p>
            
            <p className="mt-4 text-gray-400 tracking-tighter">
                Ask anything—no question is too big or small. This is your chance to get personalized advice and learn tips and tricks that can make your life easier. Don’t miss out!
            </p>
        </div>



        {/* YouTube video on the right */}
        <div className="w-full">
          <iframe
            width="100%"
            height="600"
            src="https://www.youtube.com/embed/1cSLTkU0cAs"
            title="YouTube Live Stream"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </section>
    </div>
  );
};

export default PowerHour;
