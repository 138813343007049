// ConfettiExplosion.jsx
import React, { useEffect } from 'react';
import './ConfettiExplosion.css';
import { gsap } from 'gsap';
import { RoughEase } from 'gsap/all'; // Import RoughEase from GSAP's bundled plugins

// Register the necessary plugins with GSAP
gsap.registerPlugin(RoughEase);

const ConfettiExplosion = () => {
  useEffect(() => {
    const emitterSize = 80,
      dotQuantity = 60,
      dotSizeMin = 8,
      dotSizeMax = 12,
      speed = 3.0,
      gravity = 0.3,
      explosionQuantity = 3,
      emitter = document.querySelector('#emitter'),
      explosions = [];
    let currentExplosion = 0;

    function createExplosion(container) {
      const tl = gsap.timeline({
        paused: true,
        onComplete: () => {
          // Remove the container after the animation is complete
          container.remove();
        },
      });
      const dots = [];
      for (let i = 0; i < dotQuantity; i++) {
        const dot = document.createElement('div');
        dots.push(dot);
        dot.className = 'dot';
        const r = getRandom(30, 255);
        const g = getRandom(30, 230);
        const b = getRandom(30, 230);
        gsap.set(dot, {
          backgroundColor: `rgb(${r},${g},${b})`,
          visibility: 'hidden',
        });
        const size = getRandom(dotSizeMin, dotSizeMax);
        container.appendChild(dot);
        const angle = getRandom(0, Math.PI * 2);
        const length = Math.random() * (emitterSize - size) * 2;
        const duration = 4 + Math.random();
        gsap.set(dot, {
          x: Math.cos(angle) * length,
          y: Math.sin(angle) * length,
          width: size,
          height: size,
          xPercent: -50,
          yPercent: -50,
          visibility: 'hidden',
          force3D: true,
        });
        tl.to(
          dot,
          {
            duration: duration / 2,
            opacity: 0,
            ease: RoughEase.config({
              points: 20,
              strength: 1.75,
              clamp: true,
            }),
          },
          0
        ).to(
          dot,
          {
            duration: duration,
            visibility: 'visible',
            rotationX: `-=${getRandom(720, 1440)}`,
            rotationZ: `+=${getRandom(720, 1440)}`,
            physics2D: {
              angle: (angle * 180) / Math.PI,
              velocity: (200 + Math.random() * 400) * speed,
              gravity: 400 * gravity,
              friction: getRandom(0.05, 0.1),
            },
          },
          0
        ).to(
          dot,
          {
            duration: 1.5 + Math.random(),
            opacity: 0,
          },
          duration / 2
        );
      }
      return tl;
    }

    function explode(element) {
      const bounds = element.getBoundingClientRect();
      if (++currentExplosion === explosions.length) {
        currentExplosion = 0;
      }
      const explosion = explosions[currentExplosion];
      gsap.set(explosion.container, {
        x: getRandom(100, window.innerWidth - 100),
        y: getRandom(window.innerHeight * 0.2, window.innerHeight * 0.8),
        scale: 1.5,
      });
      explosion.animation.restart();
    }

    function getRandom(min, max) {
      return min + Math.random() * (max - min);
    }

    function play() {
      let intervalCount = 0;
      const interval = setInterval(() => {
        if (intervalCount < 3) {
          explode(emitter);
          intervalCount++;
        } else {
          clearInterval(interval);
        }
      }, 400);
    }

    function setup() {
      for (let i = 0; i < explosionQuantity; i++) {
        const container = document.createElement('div');
        container.className = 'dot-container';
        container.style.position = 'fixed';
        container.style.pointerEvents = 'none'; // Prevent container from blocking any user interaction
        document.body.appendChild(container);
        explosions.push({
          container: container,
          animation: createExplosion(container),
        });
      }

      play();
    }

    setup();
  }, []);

  return <div id="emitter" style={{ position: 'fixed', top: 0, left: 0, zIndex: -1, pointerEvents: 'none' }}></div>;
};

export default ConfettiExplosion;
