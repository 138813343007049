import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { firestore, auth } from '../../firebase';
import PatientProfileNav from './PatientProfileNav';
import './PatientNotesInput.css'; 

const Roadblocks = () => {
    const [roadblocks, setRoadblocks] = useState('');
    const [roadblockOptions, setRoadblockOptions] = useState({
      'fear of pain': false, 'dental anxiety': false,
      'bad past experience': false, cost: false, time: false
    });
    const navigate = useNavigate();
    const { patientId } = useParams();

  useEffect(() => {
    // Define the Firestore document reference
    const patientRef = doc(firestore, 'customers', auth.currentUser.uid, 'patients', patientId);

    // Fetch the current roadblocks data from Firestore
    const fetchRoadblocks = async () => {
      const patientDoc = await getDoc(patientRef);
      if (patientDoc.exists() && patientDoc.data().notes && patientDoc.data().notes.roadblocks) {
        setRoadblocks(patientDoc.data().notes.roadblocks); // Set the roadblocks data to the local state
      }
    };

    fetchRoadblocks();
  }, [patientId]); // Dependency array ensures this effect runs when the component mounts or patientId changes

    const toggleRoadblockOption = (option) => {
      setRoadblockOptions(prev => ({ ...prev, [option]: !prev[option] }));
    };

    const handleNext = async () => {
      const patientRef = doc(firestore, 'customers', auth.currentUser.uid, 'patients', patientId);

      const selectedRoadblockOptionsString = Object.entries(roadblockOptions)
        .filter(([key, value]) => value)
        .map(([key]) => key.charAt(0).toUpperCase() + key.slice(1))
        .join(', ');

      const updateString = `${selectedRoadblockOptionsString} - ${roadblocks}`;

      await updateDoc(patientRef, {
        'notes.roadblocks': updateString.trim()
      });

    // Navigate to the next step (You can define the final step or route here)
    navigate(`/mvp/patient-profile/${patientId}/extraoral-exam`);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleNext();
    }
  };

  return (
    <div className="patient-complaints-container">
      <PatientProfileNav patientId={patientId} />
      <h2 className="patient-complaints-h2">Roadblocks</h2>

      <div className="sub-options">
        {Object.keys(roadblockOptions).map(option => (
          <button
            key={option}
            onClick={() => toggleRoadblockOption(option)}
            className={`treatment-button ${roadblockOptions[option] ? 'selected' : ''}`}
          >
            {option.charAt(0).toUpperCase() + option.slice(1)}
          </button>
        ))}
      </div>

      <textarea
        className="patient-complaints-textarea"
        placeholder="Enter additional roadblocks here"
        value={roadblocks}
        onChange={(e) => setRoadblocks(e.target.value)}
        onKeyPress={handleKeyPress}
        autoFocus
      />

      <button className="patient-complaints-button" onClick={handleNext}>Next</button>
    </div>
  );
};

export default Roadblocks;