// src/components/common/Loader.jsx

import React from 'react';
import { CircularProgress, Box } from '@mui/material';

const Loader = () => (
  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '50vh' }}>
    <CircularProgress />
  </Box>
);

export default Loader;
