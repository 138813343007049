import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

const ImageForm = ({ id, content, imageName, isDynamic, onChange }) => {
    const [image, setImage] = useState(content || '');
    const [name, setName] = useState(imageName || '');
    const [dynamic, setDynamic] = useState(isDynamic || false);
    const [isOpen, setIsOpen] = useState(true);

    useEffect(() => {
        setImage(content || '');
    }, [content]);

    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            const localImageUrl = URL.createObjectURL(file);
            setImage(localImageUrl);
            console.log(`Image uploaded: ${localImageUrl}`);
            onChange(id, file, name, dynamic, 'upload');
        }
    };

    const toggleOpen = () => {
        setIsOpen(!isOpen);
    };

    const handleNameChange = (e) => {
        const newName = e.target.value;
        setName(newName);
        console.log(`Name changed: ${newName}`);
        console.log(`Current image URL: ${image}`);
        onChange(id, image, newName, dynamic, 'nameChange');
    };

    const handleDynamicChange = () => {
        const newDynamic = !dynamic;
        setDynamic(newDynamic);
        console.log(`Dynamic changed: ${newDynamic}`);
        onChange(id, image, name, newDynamic, 'dynamicChange');
    };

    return (
        <div className="p-4 w-full">
            <div className="flex items-center justify-between w-full p-2 font-medium text-gray-500">
                <span>{isOpen ? 'Image' : `${name ? name + ' Image' : 'Image'}`}</span>
                <button onClick={toggleOpen} className="hover:text-malachite-500">
                    <FontAwesomeIcon icon={isOpen ? faChevronUp : faChevronDown} />
                </button>
            </div>
            {isOpen && (
                <div className="p-2">
                    {!image && (
                        <div className="mb-4">
                            <label className="flex items-center">
                                <input
                                    type="checkbox"
                                    className="mr-2"
                                    checked={dynamic}
                                    onChange={handleDynamicChange}
                                />
                                <span className="text-sm text-gray-400">
                                    {dynamic ? "Upload the image at letter creation" : "Use the patient's own image"}
                                </span>
                            </label>
                        </div>
                    )}
                    <div className="mb-4 w-full">
                        <label className="block mb-2 text-xs text-primary-400">Image Type</label>
                        <input
                            type="text"
                            className="block w-full p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500"
                            value={name}
                            onChange={handleNameChange}
                            placeholder="Smile view, radiograph etc."
                        />
                    </div>
                    {!dynamic && !image && (
                        <div className="mb-4 w-full">
                            <div className="flex items-center justify-center w-full">
                                <label
                                    htmlFor="dropzone-file"
                                    className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50"
                                >
                                    <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                        <svg
                                            className="w-8 h-8 mb-4 text-gray-500"
                                            aria-hidden="true"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 20 16"
                                        >
                                            <path
                                                stroke="currentColor"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                                            />
                                        </svg>
                                        <p className="mb-2 text-sm text-gray-500">
                                            <span className="font-semibold">Click to upload</span> or drag and drop
                                        </p>
                                        <p className="text-xs text-gray-500">SVG, PNG, JPG or GIF (MAX. 800x400px)</p>
                                    </div>
                                    <input
                                        id="dropzone-file"
                                        type="file"
                                        accept="image/*"
                                        className="hidden"
                                        onChange={handleImageUpload}
                                    />
                                </label>
                            </div>
                        </div>
                    )}
                    {image && <img src={image} alt="Uploaded" className="mt-4 rounded-lg w-full" />}
                </div>
            )}
        </div>
    );
};

export default ImageForm;
