import React, { useEffect, useState } from 'react';
import { collection, query, where, getDocs, writeBatch, doc, setDoc, deleteDoc, getDoc, updateDoc } from 'firebase/firestore';
import { firestore, functions, auth } from '../../firebase';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { useNavigate } from 'react-router-dom';
import stockTxNoteTemplates from '../txnotes/stocktxnotetemplates';
import aestheticsTemplates from '../aestheticsTemplates';
import jazTemplates from '../jazTemplates';
import NpsOverview from './NpsOverview';
import FeedbackOverview from './FeedbackOverview';
import UserProgressAdmin from '../knowledgevault/UserProgressAdmin';
import AdminTemplateManager from './AdminTemplateManager';

const Admin = () => {
  const [customersList, setCustomersList] = useState([]);
  const [uidInput, setUidInput] = useState('');
  const [filePath, setFilePath] = useState('');
  const [emailInput, setEmailInput] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [timestampInput, setTimestampInput] = useState('');
  const [noteSearchResults, setNoteSearchResults] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);
  const navigate = useNavigate();
  const [dentalPractices, setDentalPractices] = useState([]);
  const [searchQuery, setSearchQuery] = useState('dental practices in UK');
  const [totalNotesCount, setTotalNotesCount] = useState(0);
  const [claimsUid, setClaimsUid] = useState('');
  const [claimType, setClaimType] = useState('');
  const [uidInput2, setUidInput2] = useState('');
  const [clearClaimsUid, setClearClaimsUid] = useState('');
  const [notesList, setNotesList] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState('');
  const [templateUid, setTemplateUid] = useState('');
  const [templateName, setTemplateName] = useState('');
  const [templateContent, setTemplateContent] = useState('');
  const [examTemplateUid, setExamTemplateUid] = useState('');
  const [examTemplateName, setExamTemplateName] = useState('');
  const [examTemplateContent, setExamTemplateContent] = useState('');
  const [templateType, setTemplateType] = useState(''); // 'treatment' or 'exam'
  const [retrievedTemplates, setRetrievedTemplates] = useState([]);
  const [selectedTemplateId, setSelectedTemplateId] = useState(null);
  const [selectedTemplateContent, setSelectedTemplateContent] = useState('');
  const [selectedTemplates, setSelectedTemplates] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedNoteDetails, setSelectedNoteDetails] = useState(null);
  const [selectedNoteId, setSelectedNoteId] = useState(null);
  const [checkClaimsUid, setCheckClaimsUid] = useState('');  // State for UID input
  const [userClaims, setUserClaims] = useState(null);  // State to hold the user claims
  const [roleType, setRoleType] = useState(''); // New state for setting a specific role
  const [roleUid, setRoleUid] = useState(''); // UID for setting the role
  const [riskBank, setRiskBank] = useState([]); // State to store the risk bank data
  const [selectedRisk, setSelectedRisk] = useState(null);
const [editingRiskContent, setEditingRiskContent] = useState('');
  const [newRiskName, setNewRiskName] = useState(''); // State to store new risk name
  const [newRiskTrigger, setNewRiskTrigger] = useState(''); // State to store new risk trigger
  const [newRiskContent, setNewRiskContent] = useState('');
  const [isPushingToAll, setIsPushingToAll] = useState(false);
  const [pushProgress, setPushProgress] = useState(0); // New state for progress
  const [oldUid, setOldUid] = useState('');
  const [newUid, setNewUid] = useState('');
  const [userData, setUserData] = useState(null); // State for storing user data
const [editData, setEditData] = useState({}); // State for editable data fields
const [newFieldName, setNewFieldName] = useState(''); // Field name for new data
const [newFieldValue, setNewFieldValue] = useState(''); // Field value for new data

const addFieldToUser = async (uid) => {
  if (!newFieldName || !newFieldValue) {
    alert('Please provide both field name and value.');
    return;
  }

  const updatedData = { ...editData, [newFieldName]: newFieldValue }; // Add new field to editData state
  setEditData(updatedData); // Update local state

  try {
    const userRef = doc(firestore, 'customers', uid);
    await updateDoc(userRef, { [newFieldName]: newFieldValue }); // Update Firestore
    alert(`Field "${newFieldName}" added successfully.`);
    setNewFieldName(''); // Clear input fields
    setNewFieldValue('');
  } catch (error) {
    console.error('Error adding new field:', error);
    alert('Error adding new field.');
  }
};


const fetchUserData = async (uid) => {
  if (!uid) {
    alert('Please enter a UID.');
    return;
  }

  try {
    const userRef = doc(firestore, 'customers', uid);
    const userSnapshot = await getDoc(userRef);
    if (userSnapshot.exists()) {
      const data = userSnapshot.data();
      setUserData(data);
      setEditData(data); // Initialize edit fields with existing data
    } else {
      alert('No such document!');
    }
  } catch (error) {
    console.error('Error fetching user data:', error);
    alert('Error fetching user data.');
  }
};



  const pushMolarMailTemplateToAllCustomers = async () => {
    // Validation: Ensure a template is selected
    if (!selectedTemplate) {
      alert("Please select a template to push to all customers.");
      return;
    }
  
    // Validation: Ensure there are customers to push the template to
    if (customersList.length === 0) {
      alert("No customers found with marketing consent.");
      return;
    }
  
    // Retrieve the selected template object
    const template = templates.find(t => t.id === selectedTemplate);
  
    if (!template) {
      alert("Selected template not found.");
      return;
    }
  
    // Confirmation Dialog
    if (!window.confirm(`Are you sure you want to push the template "${template.name}" to all ${customersList.length} customers? This may take a while.`)) {
      return; // User canceled the action
    }
  
    // Set loading state to true
    setIsPushingToAll(true);
    setPushProgress(0);
  
    try {
      const BATCH_SIZE = 500;
      const totalBatches = Math.ceil(customersList.length / BATCH_SIZE);
      const batches = [];
  
      for (let i = 0; i < customersList.length; i += BATCH_SIZE) {
        const batch = writeBatch(firestore);
        const batchCustomers = customersList.slice(i, i + BATCH_SIZE);
  
        batchCustomers.forEach(customer => {
          const templateRef = doc(firestore, 'customers', customer.id, 'molarmailer', template.id);
          batch.set(templateRef, template);
        });
  
        batches.push(batch.commit());
  
        // Update progress after each batch
        setPushProgress(((i + BATCH_SIZE) / customersList.length) * 100);
      }
  
      await Promise.all(batches);
      alert(`Template "${template.name}" successfully pushed to all customers.`);
    } catch (error) {
      console.error('Error pushing template to all customers:', error);
      alert('An error occurred while pushing the template to all customers.');
    } finally {
      setIsPushingToAll(false);
      setPushProgress(0); // Reset progress
    }
  };
  


  // Function to set a single role
  const setSingleRole = async () => {
    if (!roleUid.trim() || !roleType.trim()) {
      alert("Please enter both a UID and a role type.");
      return;
    }

    const setRoleFunc = httpsCallable(functions, 'setCustomRole'); // You'll need to create this function in your Firebase functions
    try {
      const result = await setRoleFunc({ uid: roleUid, role: roleType });
      console.log("Role set successfully: ", result.data.message);
      alert(`Role "${roleType}" set successfully for UID ${roleUid}: ${result.data.message}`);
    } catch (error) {
      console.error('Error setting role:', error);
      alert('Error setting role: ' + error.message);
    }
  };

  // Function to check current custom claims
  const checkCurrentCustomClaims = async () => {
    if (!checkClaimsUid.trim()) {
      alert("Please enter a UID.");
      return;
    }

    try {
      const functions = getFunctions();
      const checkClaims = httpsCallable(functions, 'getCustomClaims');  // Calls the backend function
      const result = await checkClaims({ uid: checkClaimsUid });
      console.log("Custom claims retrieved successfully:", result.data);
      setUserClaims(result.data);
    } catch (error) {
      console.error('Error fetching custom claims:', error);
      alert('Failed to fetch custom claims.');
    }
  };





  useEffect(() => {
    auth.onAuthStateChanged(user => {
      setCurrentUser(user);
    });
  }, []);

  useEffect(() => {
    const adminUID = process.env.REACT_APP_ADMIN_UID;
    if (currentUser && currentUser.uid !== adminUID) {
      navigate('/mvp/super-dentist-cheat-mode');
    }
  }, [currentUser, navigate]);

  const handleFileProcessing = async () => {
    if (!filePath) {
      alert("Please enter the file path.");
      return;
    }

    try {
      const storage = getStorage();
      const storageRef = ref(storage, filePath);
      const url = await getDownloadURL(storageRef);
      const a = document.createElement("a");
      a.href = url;
      a.download = filePath.split('/').pop();
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) {
      console.error('Error downloading file:', error);
      alert('An error occurred while downloading the file.');
    }
  };

  useEffect(() => {
    const getCustomersWithMarketingConsent = async () => {
      try {
        const customersRef = collection(firestore, 'customers');
        const customersQuery = query(customersRef, where('marketingConsent', '==', true));
        const querySnapshot = await getDocs(customersQuery);

        const customersData = [];

        querySnapshot.forEach((doc) => {
          customersData.push({ id: doc.id, ...doc.data() });
        });

        setCustomersList(customersData);
      } catch (error) {
        console.error('Error fetching customers:', error);
      }
    };

    getCustomersWithMarketingConsent();
  }, []);

  useEffect(() => {
    const fetchTemplates = async () => {
      const uid = auth.currentUser.uid;
      const templatesRef = collection(firestore, 'customers', uid, 'molarmailer');
      const templatesSnapshot = await getDocs(templatesRef);
      const templatesList = templatesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setTemplates(templatesList);
    };

    fetchTemplates();
  }, []);

  const pushMolarMailTemplate = async (uid, template) => {
    if (!uid) {
      alert("Please enter a UID.");
      return;
    }

    const templateRef = doc(firestore, 'customers', uid, 'molarmailer', template.id);
    try {
      await setDoc(templateRef, template);
      alert(`Template "${template.name}" pushed to user's Firestore record successfully.`);
    } catch (error) {
      console.error('Error pushing template:', error);
      alert('An error occurred while pushing the template.');
    }
  };

  const pushStockTemplates = async (uid) => {
    if (!uid) {
      alert("Please enter a UID.");
      return;
    }

    const templatesRef = collection(firestore, 'customers', uid, 'templates');
    const templateDocs = await getDocs(templatesRef);

    const batch = writeBatch(firestore);

    templateDocs.forEach((docSnapshot) => {
      const templateId = docSnapshot.id;
      const templateContent = docSnapshot.data().text;

      if (!['consentforms', 'anaesthetics', 'xraysettings'].includes(templateId)) {
        if (!templateContent || templateContent.trim() === "") {
          const templateRef = doc(templatesRef, templateId);
          batch.set(templateRef, { text: stockTxNoteTemplates[templateId] });
        }
      }
    });

    for (const [key, value] of Object.entries(stockTxNoteTemplates)) {
      if (!templateDocs.docs.map(doc => doc.id).includes(key)) {
        const templateRef = doc(templatesRef, key);
        batch.set(templateRef, { text: value });
      }
    }

    await batch.commit();
    alert("Stock templates updated in user's Firestore record successfully.");
  };

  const pushAestheticsTemplates = async (uid) => {
    if (!uid) {
      alert("Please enter a UID.");
      return;
    }

    const aestheticsRef = collection(firestore, 'customers', uid, 'exam_templates');
    const batch = writeBatch(firestore);

    Object.entries(aestheticsTemplates).forEach(([key, value]) => {
      const templateRef = doc(aestheticsRef, key);
      batch.set(templateRef, { text: value });
    });

    await batch.commit();
    alert("Aesthetics templates pushed to user's Firestore record successfully.");
  };

  const pushJazTemplates = async (uid) => {
    if (!uid) {
      alert("Please enter a UID.");
      return;
    }

    const jazRef = collection(firestore, 'customers', uid, 'exam_templates');
    const batch = writeBatch(firestore);

    Object.entries(jazTemplates).forEach(([key, value]) => {
      const templateRef = doc(jazRef, key);
      batch.set(templateRef, { text: value });
    });

    await batch.commit();
    alert("Jaz templates pushed to user's Firestore record successfully.");
  };

  const forceUsersReAuth = async () => {
    try {
      const functions = getFunctions();
      const forceReAuth = httpsCallable(functions, 'forceUsersReAuth');
      const result = await forceReAuth();
      console.log(result);
      alert('All users will be required to re-authenticate.');
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred.');
    }
  };

  const [testUid, setTestUid] = useState('');

  const forceSingleUserReAuth = async () => {
    if (!testUid) {
      alert("Please enter a UID.");
      return;
    }

    try {
      const functions = getFunctions();
      const forceUsersReAuth = httpsCallable(functions, 'forceUsersReAuth');
      const result = await forceUsersReAuth({ userId: testUid });
      console.log(result);
      alert(`User with UID ${testUid} will be required to re-authenticate.`);
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred.');
    }
  };

  const pushBasicExamTemplate = async (uid) => {
    if (!uid) {
      alert("Please enter a UID.");
      return;
    }

    const examTemplateRef = collection(firestore, 'customers', uid, 'templates');
    const batch = writeBatch(firestore);

    const basicExamTemplate = `
    EXAMINATION

Visit Completed: Yes  
Medical History Updated: Yes

COMPLAINTS
Complaints: None  

Medical History: Checked and updated, nil changes noted.  
Dental History: Regular attender, no issues reported.  
Social History: [Job, pets, holidays, stress in here]  
Smoking: None  
Alcohol: None  

CLINICAL EXAMINATION  
Extraoral Examination -
TMJ: NAD (No abnormality detected). No clicking, popping, or locking history.  
Face: NAD  
Neck: NAD  
Lips: NAD  
Skin: NAD  
Lymph Nodes: NAD  
Salivary Glands: NAD  
Swelling: NAD  
Deviation: NAD  

Intraoral Examination -
Soft Tissues: NAD  
Buccal Mucosa: NAD  
Floor of Mouth: NAD  
Tongue: NAD  
Hard Palate: NAD  
Soft Palate: NAD  
Fauces/Pharynx: NAD  

PERIODONTAL EXAMINATION
BPE: 000/000  
Periodontal Condition: Healthy  
Periodontal Diagnosis: None  
Comment on Periodontal Condition: Stable  
Oral Hygiene: Good  

OCCLUSION AND WEAR 
Wear Facets: None  
Basic Erosive Wear Examination (BEWE): 000/000  
Occlusion: No issues in static and dynamic  
Crossbites: None  
Anterior Open Bite: None  
Overbite: Normal  
Overjet: Normal  

Canine relationship right side: Class I
Molar Relationship right side: Class I
Canine relationship left side: Class I
Molar Relationship left side: Class I
Incisor Relationship: Class I

RADIOGRAPHIC EXAMINATION
Radiographs: None indicated today  
Radiograph Report: N/A  
Radiograph Exposure Settings: N/A  
Maximum Bone Loss: 0%  

RISK ASSESSMENT
Caries Risk: Low  
Periodontal Risk: Low  
Oral Cancer Risk: Low  
Tooth Fracture Risk: Low  

CLINICAL OBSERVATIONS 
Observations: None  

DIAGNOSIS AND TREATMENT PLAN
Diagnoses: None  
Recommended Treatment: None  
Alternatives: None  

DISCUSSION AND RISKS 
Discussion: Nil  
Risks: None  
Benefits: None  
Discussion of Fees: N/A  
Further Discussion: Nil

TREATMENT AND PREVENTATIVE PLAN
Treatment Today: None  
Preventative Plan: Regular hygiene visits, fluoride application 1x/year  

Next Visit: In 6 months for a routine examination
    `;

    const templateRef = doc(examTemplateRef, 'Basic Exam');
    batch.set(templateRef, { text: basicExamTemplate });

    await batch.commit();
    alert("Basic exam template pushed to user's Firestore record successfully.");
  };

  const pushBasicExamIfNotExists = async () => {
    try {
      const customersRef = collection(firestore, 'customers');
      const querySnapshot = await getDocs(customersRef);

      for (const customerDoc of querySnapshot.docs) {
        const uid = customerDoc.id;
        const templatesRef = collection(firestore, 'customers', uid, 'templates');
        const templateSnapshot = await getDocs(templatesRef);

        const hasBasicExam = templateSnapshot.docs.some(doc => doc.id === 'Basic Exam');

        if (!hasBasicExam) {
          await pushBasicExamTemplate(uid);
        }
      }

      alert("Basic exam templates pushed to customers without it.");
    } catch (error) {
      console.error('Error checking or pushing basic exam templates:', error);
      alert('An error occurred while processing basic exam templates.');
    }
  };

  const searchUsersByEmail = async () => {
    if (!emailInput.trim()) {
      alert("Please enter an email.");
      return;
    }

    try {
      const usersRef = collection(firestore, 'customers');
      const querySnapshot = await getDocs(query(usersRef, where('email', '==', emailInput.trim())));

      const results = [];
      querySnapshot.forEach((doc) => {
        results.push({ uid: doc.id, ...doc.data() });
      });

      setSearchResults(results);
    } catch (error) {
      console.error('Error searching users:', error);
      alert('An error occurred during the search.');
    }
  };

  const searchNotesByTimestamp = async () => {
    try {
      const customersRef = collection(firestore, 'customers');
      const customerSnapshots = await getDocs(customersRef);

      const results = [];

      for (const customerDoc of customerSnapshots.docs) {
        const notesRef = collection(firestore, 'customers', customerDoc.id, 'notes');
        const notesQuery = query(notesRef, where('timestamp', '==', timestampInput.trim()));
        const notesSnapshots = await getDocs(notesQuery);

        for (const noteDoc of notesSnapshots.docs) {
          results.push({ customerId: customerDoc.id, noteId: noteDoc.id, ...noteDoc.data() });
        }
      }

      setNoteSearchResults(results);
    } catch (error) {
      console.error('Error searching notes:', error);
    }
  };

  const functions = getFunctions();

  const showUpdatePopupForAllUsers = async () => {
    const setPopupForAllUsers = httpsCallable(functions, 'setPopupForAllUsers');
    try {
      console.log("updating....");
      const result = await setPopupForAllUsers();
      console.log(result.data.message);
      alert(result.data.message);
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred.');
    }
  };

  const processDentalPractices = (practices) => {
    return practices.map(practice => ({
      name: practice.displayName.text,
      address: practice.formattedAddress,
      phoneNumber: practice.nationalPhoneNumber,
      website: practice.websiteUri,
      rating: practice.rating,
    }));
  };

  const fetchDentalPractices = async () => {
    try {
      console.log("searching...");
      const getDentalPractices = httpsCallable(functions, 'getDentalPractices');

      let allPractices = [];
      let pageCount = 0;
      let nextPageToken = null;

      do {
        const response = await getDentalPractices({ query: searchQuery, pageToken: nextPageToken });
        console.log("Raw response for page " + (pageCount + 1) + ":", response);

        const rawResponseWindow = window.open();
        rawResponseWindow.document.write("<pre>" + JSON.stringify(response, null, 2) + "</pre>");

        const processedPractices = processDentalPractices(response.data.practices);
        allPractices = [...allPractices, ...processedPractices];

        nextPageToken = response.data.nextPageToken;
        pageCount++;

        if (nextPageToken && pageCount < 20) {
          await new Promise(resolve => setTimeout(resolve, 2000));
        } else {
          break;
        }
      } while (nextPageToken);

      setDentalPractices(allPractices);
      console.log("All processed results:", allPractices);
    } catch (error) {
      console.error('Error fetching dental practices:', error);
      alert('Failed to fetch dental practices');
    }
  };

  const countAllNotes = async () => {
    try {
      let totalNotes = 0;
      const customersRef = collection(firestore, 'customers');
      const customersSnapshot = await getDocs(customersRef);
      const totalCustomers = customersSnapshot.size;
  
      console.log(`Starting to count notes for ${totalCustomers} customers.`);
  
      for (let i = 0; i < customersSnapshot.docs.length; i++) {
        const customerDoc = customersSnapshot.docs[i];
        const customerId = customerDoc.id;
        const notesRef = collection(firestore, `customers/${customerId}/notes`);
        const notesSnapshot = await getDocs(notesRef);
        const notesCount = notesSnapshot.size;
  
        totalNotes += notesCount;
  
        // Log progress to the console
        console.log(
          `Processed customer ${i + 1}/${totalCustomers} (UID: ${customerId}). Notes counted: ${notesCount}. Total notes so far: ${totalNotes}`
        );
  
        // Optionally, update the state incrementally (uncomment if needed)
        // setTotalNotesCount(prevCount => prevCount + notesCount);
      }
  
      setTotalNotesCount(totalNotes);
      console.log(`Completed counting. Total notes across all customers: ${totalNotes}`);
      alert(`Total notes across all customers: ${totalNotes}`);
    } catch (error) {
      console.error('Error counting all notes:', error);
      alert('An error occurred while counting all notes.');
    }
  };
  

  const setCustomClaims = async () => {
    if (!claimsUid.trim() || !claimType.trim()) {
      alert("Please enter both a UID and a claim type.");
      return;
    }

    const setClaimsFunc = httpsCallable(functions, 'setCustomClaims');
    try {
      const result = await setClaimsFunc({ uid: claimsUid, productType: claimType });
      console.log("Custom claims set successfully: ", result.data.message);
      alert(`Custom claims set successfully for UID ${claimsUid}: ${result.data.message}`);
    } catch (error) {
      console.error('Error setting custom claims:', error);
      alert('Error setting custom claims: ' + error.message);
    }
  };

  const fetchNotes = async () => {
    if (!uidInput2.trim()) {
      alert("Please enter a UID.");
      return;
    }

    console.log("Fetching notes for UID:", uidInput2);

    try {
      const notesRef = collection(firestore, `customers/${uidInput2}/notes`);
      const notesSnapshot = await getDocs(notesRef);
      console.log("Notes fetched:", notesSnapshot.size);

      if (notesSnapshot.empty) {
        console.log("No notes found for this user.");
        setNotesList([]);
        alert("No notes found for this user.");
        return;
      }

      const notesData = notesSnapshot.docs.map(doc => ({
        id: doc.id,
        title: doc.data().title,
        timestamp: doc.data().timestamp ? doc.data().timestamp.toDate().toISOString() : 'No timestamp',
      }));

      console.log("Processed notes data:", notesData);
      setNotesList(notesData);
    } catch (error) {
      console.error('Error fetching notes:', error);
      alert('Failed to fetch notes for the user.');
    }
  };

  const clearCustomClaims = async () => {
    if (!clearClaimsUid.trim()) {
      alert("Please enter a UID.");
      return;
    }
  
    const clearClaimsFunc = httpsCallable(functions, 'clearCustomClaims');
    try {
      const result = await clearClaimsFunc({ uid: clearClaimsUid });
      console.log("Custom claims cleared successfully: ", result.data.message);
      alert(`Custom claims cleared successfully for UID ${clearClaimsUid}: ${result.data.message}`);
    } catch (error) {
      console.error('Error clearing custom claims:', error);
      alert('Error clearing custom claims: ' + error.message);
    }
  };

  const handlePushCustomTemplate = async () => {
      if (!templateUid || !templateName || !templateContent) {
          alert('Please fill in all fields.');
          return;
      }

      const sanitizedContent = sanitizeTemplateContent(templateContent);

      try {
          const templateRef = doc(firestore, 'customers', templateUid, 'templates', templateName);
          await setDoc(templateRef, { text: sanitizedContent });
          alert(`Template "${templateName}" pushed successfully to UID: ${templateUid}.`);
      } catch (error) {
          console.error('Error pushing template:', error);
          alert('An error occurred while pushing the template.');
      }
  };


  const sanitizeTemplateContent = (text) => {
    let formattedText = text.replace(/\*\*(.*?)\*\*/g, '$1'); // Remove **text**
    formattedText = formattedText.replace(/###/g, ''); // Remove ###
    formattedText = formattedText.replace(/##/g, ''); // Remove ##
    formattedText = formattedText.replace(/\*\*/g, ''); // Remove any remaining **
    formattedText = formattedText.replace(/【\d+†.*?】/g, ''); // Remove any special references
    return formattedText;
  };

  const handlePushExamTemplate = async () => {
    if (!examTemplateUid || !examTemplateName || !examTemplateContent) {
        alert('Please fill in all fields.');
        return;
    }

    const sanitizedContent = sanitizeTemplateContent(examTemplateContent);

    try {
        const examTemplateRef = doc(firestore, 'customers', examTemplateUid, 'exam_templates', examTemplateName);
        await setDoc(examTemplateRef, { text: sanitizedContent });
        alert(`Exam Template "${examTemplateName}" pushed successfully to UID: ${examTemplateUid}.`);
    } catch (error) {
        console.error('Error pushing exam template:', error);
        alert('An error occurred while pushing the exam template.');
    }
};

const fetchTemplatesByType = async () => {
  if (!uidInput || !templateType) {
    alert("Please enter a UID and select a template type.");
    return;
  }

  const collectionPath = templateType === 'treatment' ? 'templates' : 'exam_templates';
  const templatesRef = collection(firestore, 'customers', uidInput, collectionPath);

  try {
    const templatesSnapshot = await getDocs(templatesRef);
    const templatesList = templatesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setRetrievedTemplates(templatesList);
  } catch (error) {
    console.error('Error fetching templates:', error);
    alert('An error occurred while fetching the templates.');
  }
};

const handleSaveTemplate = async () => {
  if (!selectedTemplateId || !selectedTemplateContent) {
    alert('Template ID or content is missing.');
    return;
  }

  // Sanitize the template content before saving
  const sanitizedContent = sanitizeTemplateContent(selectedTemplateContent);

  const collectionPath = templateType === 'treatment' ? 'templates' : 'exam_templates';
  const templateRef = doc(firestore, 'customers', uidInput, collectionPath, selectedTemplateId);

  try {
    await setDoc(templateRef, { text: sanitizedContent });
    alert('Template saved successfully.');
  } catch (error) {
    console.error('Error saving template:', error);
    alert('An error occurred while saving the template.');
  }
};


const handleDeleteTemplate = async () => {
  if (!selectedTemplateId) {
    alert('Template ID is missing.');
    return;
  }

  const collectionPath = templateType === 'treatment' ? 'templates' : 'exam_templates';
  const templateRef = doc(firestore, 'customers', uidInput, collectionPath, selectedTemplateId);

  try {
    await deleteDoc(templateRef);
    alert('Template deleted successfully.');
    // Remove deleted template from the list
    setRetrievedTemplates(retrievedTemplates.filter(template => template.id !== selectedTemplateId));
    setSelectedTemplateId(null);
    setSelectedTemplateContent('');
  } catch (error) {
    console.error('Error deleting template:', error);
    alert('An error occurred while deleting the template.');
  }
};

const handleBatchDeleteTemplates = async () => {
  if (selectedTemplates.length === 0) {
    alert('No templates selected for deletion.');
    return;
  }

  const collectionPath = templateType === 'treatment' ? 'templates' : 'exam_templates';
  const batch = writeBatch(firestore);

  try {
    selectedTemplates.forEach((templateId) => {
      const templateRef = doc(firestore, 'customers', uidInput, collectionPath, templateId);
      batch.delete(templateRef);
    });

    await batch.commit();
    alert('Selected templates deleted successfully.');

    // Remove deleted templates from the retrieved list
    setRetrievedTemplates((prevTemplates) =>
      prevTemplates.filter((template) => !selectedTemplates.includes(template.id))
    );
    setSelectedTemplates([]);
    setSelectAll(false);
  } catch (error) {
    console.error('Error deleting templates:', error);
    alert('An error occurred while deleting the templates.');
  }
};

const fetchNoteContent = async (noteId) => {
  if (!uidInput2.trim()) {
    alert("Please enter a UID.");
    return;
  }

  try {
    const noteRef = doc(firestore, `customers/${uidInput2}/notes`, noteId);
    const noteSnapshot = await getDoc(noteRef);

    if (noteSnapshot.exists()) {
      const noteData = noteSnapshot.data();
      setSelectedNoteDetails(noteData);
      setSelectedNoteId(noteId);
    } else {
      alert('Note not found.');
    }
  } catch (error) {
    console.error('Error fetching note content:', error);
    alert('Failed to fetch note content.');
  }
};


// Function to fetch risk bank data
const fetchRiskBank = async () => {
  if (!uidInput.trim()) {
    alert('Please enter a UID.');
    return;
  }

  try {
    const riskBankRef = collection(firestore, 'customers', uidInput, 'riskBank');
    const riskSnapshot = await getDocs(riskBankRef);

    const risks = riskSnapshot.docs.map((doc) => ({
      id: doc.id,
      name: doc.data().name,
      trigger: doc.data().trigger,
      risk: doc.data().risk || 'No risk details available',
    }));

    setRiskBank(risks);
  } catch (error) {
    console.error('Error fetching risk bank:', error);
    alert('An error occurred while fetching the risk bank.');
  }
};

// Function to display risk content when a risk is clicked
const displayRiskContent = (riskId) => {
  const risk = riskBank.find((risk) => risk.id === riskId);
  if (risk) {
    setSelectedRisk(risk);
    setEditingRiskContent(risk.risk);
  }
};

// Function to add a new risk record and push it to Firestore
const addNewRiskToBank = async () => {
  if (!uidInput.trim()) {
    alert('Please enter a UID.');
    return;
  }

  if (!newRiskName.trim() || !newRiskTrigger.trim() || !newRiskContent.trim()) {
    alert('Please fill in all fields for the new risk.');
    return;
  }

  try {
    const newRisk = {
      name: newRiskName,
      trigger: newRiskTrigger,
      risk: newRiskContent,
      status: 'active',
    };

    const riskBankRef = doc(collection(firestore, 'customers', uidInput, 'riskBank'));
    await setDoc(riskBankRef, newRisk);

    setRiskBank((prevRiskBank) => [...prevRiskBank, { id: riskBankRef.id, ...newRisk }]); // Update local state
    alert('New risk added successfully.');
  } catch (error) {
    console.error('Error adding new risk:', error);
    alert('An error occurred while adding the new risk.');
  }
};

// Handle saving the edited risk content back to Firestore
const saveRiskBankContent = async () => {
  if (!selectedRisk) {
    alert('No risk selected.');
    return;
  }

  try {
    const riskRef = doc(firestore, 'customers', uidInput, 'riskBank', selectedRisk.id);
    await updateDoc(riskRef, { risk: editingRiskContent });

    // Update the local state after saving
    setRiskBank((prevRisks) =>
      prevRisks.map((risk) =>
        risk.id === selectedRisk.id ? { ...risk, risk: editingRiskContent } : risk
      )
    );

    alert('Risk content saved successfully.');
    setSelectedRisk(null); // Clear the selected risk after saving
  } catch (error) {
    console.error('Error saving risk content:', error);
    alert('An error occurred while saving the risk content.');
  }
};

// Function to delete a risk from Firestore
const deleteRisk = async (riskId) => {
  if (!uidInput.trim()) {
    alert('Please enter a UID.');
    return;
  }

  try {
    const riskRef = doc(firestore, 'customers', uidInput, 'riskBank', riskId);
    await deleteDoc(riskRef);

    // Remove the deleted risk from the local state
    setRiskBank(prevRisks => prevRisks.filter(risk => risk.id !== riskId));

    alert('Risk deleted successfully.');
  } catch (error) {
    console.error('Error deleting risk:', error);
    alert('An error occurred while deleting the risk.');
  }
};

const transferDataBetweenUsers = async (oldUid, newUid) => {
  // Log values to ensure they are being passed correctly
  console.log('Old UID:', oldUid);
  console.log('New UID:', newUid);

  if (typeof oldUid !== 'string' || typeof newUid !== 'string') {
    alert('UIDs must be strings.');
    return;
  }

  if (!oldUid.trim() || !newUid.trim()) {
    alert('Please enter both old and new UIDs.');
    return;
  }

  const functions = getFunctions();
  const copyUserDataWithSubcollections = httpsCallable(functions, 'copyUserDataWithSubcollections');

  try {
    const result = await copyUserDataWithSubcollections({ oldUid, newUid });
    alert(result.data.message);
  } catch (error) {
    console.error('Error transferring data:', error);
    alert('An error occurred while transferring data: ' + error.message);
  }
};

const saveUserData = async (uid) => {
  if (!uid || !editData) {
    alert('Please enter a UID and ensure data is loaded.');
    return;
  }

  try {
    const userRef = doc(firestore, 'customers', uid);
    await updateDoc(userRef, editData);
    alert('User data updated successfully.');
    fetchUserData(uid); // Refresh data after saving
  } catch (error) {
    console.error('Error updating user data:', error);
    alert('Error updating user data.');
  }
};


        



  return (
    <main className="flex flex-col tracking-tight w-4/5 mx-auto">
      <h2 className="text-2xl font-bold mb-4">Admin Page</h2>

       {/* <div className="mb-4">
        <h3 className="text-lg font-bold mb-2">Set Single Role</h3>
        <input
          type="text"
          placeholder="Enter UID for Role"
          value={roleUid}
          onChange={(e) => setRoleUid(e.target.value)}
          className="border p-2 mr-2"
        />
        <input
          type="text"
          placeholder="Enter Role Type (e.g., Performance)"
          value={roleType}
          onChange={(e) => setRoleType(e.target.value)}
          className="border p-2 mr-2"
        />
        <button onClick={setSingleRole} className="bg-green-500 text-white p-2 rounded">
          Set Single Role
        </button>
      </div> */}

      <div className="mb-4">
        <input 
          type="text" 
          placeholder="Enter User Email" 
          value={emailInput} 
          onChange={(e) => setEmailInput(e.target.value)}
          className="border p-2 mr-2"
        />
        <button onClick={searchUsersByEmail} className="bg-yellow-500 text-white p-2 rounded">Search User by Email</button>
      </div>
      <div className="mb-4">
        <ul className="list-disc list-inside">
          {searchResults.map((user) => (
            <li key={user.uid}>Email: {user.email}, UID: {user.uid}</li>
          ))}
        </ul>
      </div>

      <div className="mb-4">
        <input
          type="text"
          placeholder="Enter UID"
          value={uidInput}
          onChange={(e) => setUidInput(e.target.value)}
          className="border p-2 mr-2"
        />
        <button onClick={() => fetchUserData(uidInput)} className="bg-blue-500 text-white p-2 rounded">
          Fetch User Data
        </button>
      </div>


      {userData && (
        <div className="mb-4">
          <h3 className="text-lg font-bold mb-2">Edit User Data:</h3>
          {Object.keys(editData).map((key) => (
            <div key={key} className="mb-2">
              <label className="block font-bold mb-1">{key}</label>
              <input
                type="text"
                value={editData[key]}
                onChange={(e) => setEditData((prev) => ({ ...prev, [key]: e.target.value }))}
                className="border p-2 w-full"
              />
            </div>
          ))}
          <button onClick={() => saveUserData(uidInput)} className="bg-blue-500 text-white p-2 rounded">
            Save Changes
          </button>
        </div>
      )}

      <div className="mb-4">
        <h3 className="text-lg font-bold mb-2">Add New Field:</h3>
        <input
          type="text"
          placeholder="Field Name"
          value={newFieldName}
          onChange={(e) => setNewFieldName(e.target.value)}
          className="border p-2 mr-2"
        />
        <input
          type="text"
          placeholder="Field Value"
          value={newFieldValue}
          onChange={(e) => setNewFieldValue(e.target.value)}
          className="border p-2 mr-2"
        />
        <button onClick={() => addFieldToUser(uidInput)} className="bg-green-500 text-white p-2 rounded">
          Add Field
        </button>
      </div>



      <div className="mb-4">
        <input 
          type="text" 
          placeholder="Enter Firebase Storage File Path" 
          value={filePath} 
          onChange={(e) => setFilePath(e.target.value)}
          className="border p-2 mr-2"
        />
        <button onClick={handleFileProcessing} className="bg-blue-500 text-white p-2 rounded">Process Audio File</button>
      </div>

      {/* <p className="mb-2">List of Customers with Marketing Consent:</p>
      <ul className="list-disc list-inside mb-4">
        {customersList.map((customer) => (
          <li key={customer.id}>{customer.email}</li>
        ))}
      </ul> */}

      <div className="mb-4">
        <input 
          type="text" 
          placeholder="Enter UID" 
          value={uidInput} 
          onChange={(e) => setUidInput(e.target.value)}
          className="border p-2 mr-2"
        />
        <button onClick={() => pushStockTemplates(uidInput)} className="bg-green-500 text-white p-2 rounded mr-2">Push Stock Templates</button>
        <button onClick={() => pushAestheticsTemplates(uidInput)} className="bg-green-500 text-white p-2 rounded mr-2">Push Aesthetics Templates</button>
        <button onClick={() => pushJazTemplates(uidInput)} className="bg-green-500 text-white p-2 rounded mr-2">Push Jaz Templates</button>
        <button onClick={() => pushBasicExamTemplate(uidInput)} className="bg-green-500 text-white p-2 rounded mr-2">Push Basic Exam Template</button>
        <button onClick={pushBasicExamIfNotExists} className="bg-green-500 text-white p-2 rounded">Push Basic Exam Where Missing</button>
      </div>

      {/* <div className="mb-4">
        <button onClick={forceUsersReAuth} className="bg-red-500 text-white p-2 rounded">Force Re-Authentication</button>
      </div>

      <div className="mb-4">
        <input 
          type="text" 
          placeholder="Enter Test UID" 
          value={testUid} 
          onChange={(e) => setTestUid(e.target.value)}
          className="border p-2 mr-2"
        />
        <button onClick={forceSingleUserReAuth} className="bg-red-500 text-white p-2 rounded">Force Re-Authentication for Test User</button>
      </div> */}

      

      

      <div className="mb-4">
        <input 
          type="text" 
          placeholder="Enter Timestamp (e.g., 2 April 2024 at 19:33:11 UTC+1)" 
          value={timestampInput} 
          onChange={(e) => setTimestampInput(e.target.value)}
          className="border p-2 mr-2"
        />
        <button onClick={searchNotesByTimestamp} className="bg-purple-500 text-white p-2 rounded">Search Notes by Timestamp</button>
      </div>

      <div className="mb-4">
        <ul className="list-disc list-inside">
          {noteSearchResults.map((note) => (
            <li key={note.noteId}>Customer ID: {note.customerId}, Note ID: {note.noteId}, Timestamp: {note.timestamp.toString()}</li>
          ))}
        </ul>
      </div>

      <div className="mb-4">
        <input
          type="text"
          placeholder="Enter UID to fetch notes"
          value={uidInput2}
          onChange={(e) => setUidInput2(e.target.value)}
          className="border p-2 mr-2"
        />
        <button onClick={fetchNotes} className="bg-blue-500 text-white p-2 rounded">Fetch Notes</button>
      </div>

      {notesList.length > 0 ? (
  <div className="mb-4">
    <h3 className="text-lg font-bold mb-2">User's Notes:</h3>
    <ul className="list-disc list-inside">
      {notesList.map(note => (
        <li 
          key={note.id} 
          className="cursor-pointer text-blue-500 underline"
          onClick={() => fetchNoteContent(note.id)}
        >
          Note ID: {note.id}, Title: {note.title}, Timestamp: {note.timestamp}
        </li>
      ))}
    </ul>
  </div>
) : (
  <p className="mb-4">No notes found for this user.</p>
)}

{selectedNoteDetails && (
  <div className="mb-4">
    <h3 className="text-lg font-bold mb-2">Note Details: {selectedNoteId}</h3>
    
    <p><strong>Title:</strong> {selectedNoteDetails.title}</p>
    <p><strong>Timestamp:</strong> {selectedNoteDetails.timestamp.toString()}</p>
    
    {selectedNoteDetails.audio_recording && (
      <div className="mb-2">
        <strong>Audio Recording:</strong>
        <audio controls src={selectedNoteDetails.audio_recording} />
        <p>
          <a href={selectedNoteDetails.audio_recording} target="_blank" rel="noopener noreferrer" className="text-blue-500 underline">
            Link to Recording
          </a>
        </p>
      </div>
    )}

    <div className="mb-4">
      <label className="block font-bold mb-2" htmlFor="content">Content:</label>
      <textarea
        id="content"
        value={selectedNoteDetails.content}
        readOnly
        rows={10}
        className="w-full p-2 border rounded"
      />
    </div>

    <div className="mb-4">
      <label className="block font-bold mb-2" htmlFor="transcript">Transcript:</label>
      <textarea
        id="transcript"
        value={selectedNoteDetails.transcript}
        readOnly
        rows={10}
        className="w-full p-2 border rounded"
      />
    </div>
    
    {selectedNoteDetails.patientId && (
      <p><strong>Patient ID:</strong> {selectedNoteDetails.patientId}</p>
    )}

    <button
      onClick={() => {
        setSelectedNoteDetails(null);
        setSelectedNoteId(null);
      }}
      className="bg-gray-500 text-white p-2 rounded"
    >
      Close Note
    </button>
  </div>
)}





      <div className="flex gap-2">
        <button onClick={showUpdatePopupForAllUsers} className="bg-indigo-500 text-white p-2 rounded mb-4">Show Update Popup for All Users</button>
        <button onClick={countAllNotes} className="bg-orange-500 text-white p-2 rounded mb-4">Count All Notes</button>
        <p>Total Notes: {totalNotesCount}</p>
      </div>

      {/* <div className="mb-4">
        <input 
          type="text" 
          placeholder="Enter search query" 
          value={searchQuery} 
          onChange={(e) => setSearchQuery(e.target.value)}
          className="border p-2 mr-2"
        />
        <button onClick={fetchDentalPractices} className="bg-blue-500 text-white p-2 rounded">Show Dental Practices</button>
        <table className="min-w-full bg-white border">
          <thead>
            <tr>
              <th className="py-2">Name</th>
              <th className="py-2">Address</th>
              <th className="py-2">Phone Number</th>
              <th className="py-2">Website</th>
              <th className="py-2">Rating</th>
            </tr>
          </thead>
          <tbody>
            {dentalPractices.map((practice, index) => (
              <tr key={index}>
                <td className="border px-4 py-2">{practice.name}</td>
                <td className="border px-4 py-2">{practice.address}</td>
                <td className="border px-4 py-2">{practice.phoneNumber}</td>
                <td className="border px-4 py-2"><a href={practice.website} target="_blank" rel="noopener noreferrer">{practice.website}</a></td>
                <td className="border px-4 py-2">{practice.rating}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div> */}

       <div className="mb-4">
        <h3 className="text-lg font-bold mb-2">Check Current Custom Claims</h3>
        <input
          type="text"
          placeholder="Enter UID to Check Claims"
          value={checkClaimsUid}
          onChange={(e) => setCheckClaimsUid(e.target.value)}
          className="border p-2 mr-2"
        />
        <button onClick={checkCurrentCustomClaims} className="bg-orange-500 text-white p-2 rounded">
          Check Current Custom Claims
        </button>
      </div>

      {userClaims && (
        <div className="mb-4">
          <h4 className="text-lg font-bold">User Claims for UID: {checkClaimsUid}</h4>
          <pre className="bg-gray-100 p-2 rounded">
            {JSON.stringify(userClaims, null, 2)}
          </pre>
        </div>
      )}

      <div className="mb-4">
        <h3 className="text-lg font-bold mb-2">Set Custom Claims</h3>
        <input
          type="text"
          placeholder="Enter UID for Claims"
          value={claimsUid}
          onChange={(e) => setClaimsUid(e.target.value)}
          className="border p-2 mr-2"
        />
        <input
          type="text"
          placeholder="Enter Claim Type (Essentials or Performance)"
          value={claimType}
          onChange={(e) => setClaimType(e.target.value)}
          className="border p-2 mr-2"
        />
        <button onClick={setCustomClaims} className="bg-green-500 text-white p-2 rounded">Set Custom Claims</button>
      </div>
      <div className="mb-4">
        <h3 className="text-lg font-bold mb-2">Clear Custom Claims</h3>
        <input
          type="text"
          placeholder="Enter UID to Clear Claims"
          value={clearClaimsUid}
          onChange={(e) => setClearClaimsUid(e.target.value)}
          className="border p-2 mr-2"
        />
        <button onClick={clearCustomClaims} className="bg-red-500 text-white p-2 rounded">
          Clear Custom Claims
        </button>
      </div>

      {/* New section for pushing Molar Mail templates */}
      <div className="mb-4">
        <h3 className="text-lg font-bold mb-2">Push Molar Mail Template</h3>
        <input
          type="text"
          placeholder="Enter UID"
          value={uidInput}
          onChange={(e) => setUidInput(e.target.value)}
          className="border p-2 mr-2"
        />
        <select
          value={selectedTemplate}
          onChange={(e) => setSelectedTemplate(e.target.value)}
          className="border p-2 mr-2"
        >
          <option value="">Select Template</option>
          {templates.map((template) => (
            <option key={template.id} value={template.id}>
              {template.name}
            </option>
          ))}
        </select>
        <button
          onClick={() => {
            const template = templates.find((t) => t.id === selectedTemplate);
            if (template) {
              pushMolarMailTemplate(uidInput, template);
            } else {
              alert('Please select a valid template.');
            }
          }}
          className="bg-blue-500 text-white p-2 rounded"
        >
          Push Template
        </button>
       {/* New Button to Push Template to All Customers */}
        <button
          onClick={pushMolarMailTemplateToAllCustomers}
          className={`bg-purple-500 text-white p-2 rounded ${isPushingToAll ? 'opacity-50 cursor-not-allowed' : ''}`}
          disabled={isPushingToAll}
        >
          {isPushingToAll ? `Pushing... (${pushProgress.toFixed(0)}%)` : 'Push Template to All Customers'}
        </button>

        {/* Optional: Display a Progress Bar */}
        {isPushingToAll && (
          <div className="w-full bg-gray-200 rounded-full h-4 mt-2">
            <div
              className="bg-purple-600 h-4 rounded-full"
              style={{ width: `${pushProgress}%` }}
            ></div>
          </div>
        )}
      </div>

      <div className="mb-4 flex flex-col">
        <h3 className="text-lg font-bold mb-2">Push Custom Treatment Template</h3>
        <div className="flex">
        <input
            type="text"
            placeholder="Enter UID"
            value={templateUid}
            onChange={(e) => setTemplateUid(e.target.value)}
            className="border p-2 mr-2"
        />
        <input
            type="text"
            placeholder="Enter Template Name"
            value={templateName}
            onChange={(e) => setTemplateName(e.target.value)}
            className="border p-2 mr-2"
        />
        </div>
        <textarea
            placeholder="Enter Template Content"
            value={templateContent}
            onChange={(e) => setTemplateContent(e.target.value)}
            className="border p-2 mr-2 w-1/2"
            rows="4"
            cols="50"
        />
        <button
            onClick={handlePushCustomTemplate}
            className="bg-blue-500 text-white p-2 rounded w-1/2"
        >
            Push Template
        </button>
    </div>

    <div className="mb-4 flex flex-col">
        <h3 className="text-lg font-bold mb-2">Push Custom Exam Template</h3>
        <div className="flex">
            <input
                type="text"
                placeholder="Enter UID"
                value={examTemplateUid}
                onChange={(e) => setExamTemplateUid(e.target.value)}
                className="border p-2 mr-2"
            />
            <input
                type="text"
                placeholder="Enter Template Name"
                value={examTemplateName}
                onChange={(e) => setExamTemplateName(e.target.value)}
                className="border p-2 mr-2"
            />
        </div>
        <textarea
            placeholder="Enter Template Content"
            value={examTemplateContent}
            onChange={(e) => setExamTemplateContent(e.target.value)}
            className="border p-2 mr-2 w-1/2"
            rows="4"
            cols="50"
        />
        <button
            onClick={handlePushExamTemplate}
            className="bg-blue-500 text-white p-2 rounded w-1/2"
        >
            Push Exam Template
        </button>
    </div>

<div className="mb-4">
  <h3 className="text-lg font-bold mb-2">Fetch User's Templates</h3>
  <div className="flex">
    <input
      type="text"
      placeholder="Enter UID"
      value={uidInput}
      onChange={(e) => setUidInput(e.target.value)}
      className="border p-2 mr-2"
    />
    <select
      value={templateType}
      onChange={(e) => setTemplateType(e.target.value)}
      className="border p-2 mr-2"
    >
      <option value="">Select Template Type</option>
      <option value="treatment">Treatment Templates</option>
      <option value="exam">Exam Templates</option>
    </select>
    <button
      onClick={fetchTemplatesByType}
      className="bg-green-500 text-white p-2 rounded"
    >
      Fetch Templates
    </button>
  </div>
</div>

{retrievedTemplates.length > 0 && (
  <div className="flex flex-col mb-4">
    <h3 className="text-lg font-bold mb-2">Retrieved Templates:</h3>
    <div className="flex items-center mb-2">
      <input
        type="checkbox"
        checked={selectAll}
        onChange={(e) => {
          setSelectAll(e.target.checked);
          setSelectedTemplates(
            e.target.checked ? retrievedTemplates.map((template) => template.id) : []
          );
        }}
        className="mr-2"
      />
      <label>Select All</label>
    </div>
    <ul className="list-disc list-inside">
      {retrievedTemplates.map((template) => (
        <li key={template.id} className="flex items-center">
          <input
            type="checkbox"
            checked={selectedTemplates.includes(template.id)}
            onChange={(e) => {
              const isChecked = e.target.checked;
              setSelectedTemplates((prevSelected) =>
                isChecked
                  ? [...prevSelected, template.id]
                  : prevSelected.filter((id) => id !== template.id)
              );
            }}
            className="mr-2"
          />
          <span
            className="cursor-pointer text-blue-500 underline"
            onClick={() => {
              setSelectedTemplateId(template.id);
              setSelectedTemplateContent(template.text); // Assuming 'text' contains the template content
            }}
          >
            {template.id}
          </span>
        </li>
      ))}
    </ul>
    {selectedTemplates.length > 0 && (
      <button
        onClick={handleBatchDeleteTemplates}
        className="bg-red-500 text-white p-2 rounded mt-4"
      >
        Delete Selected Templates
      </button>
    )}
  </div>
)}

{selectedTemplateId && (
  <div className="mb-4">
    <h3 className="text-lg font-bold mb-2">Edit Template: {selectedTemplateId}</h3>
    <textarea
      value={selectedTemplateContent}
      onChange={(e) => setSelectedTemplateContent(e.target.value)}
      className="border p-2 w-full mb-2"
      rows="10"
    />
    <div className="flex">
      <button 
        onClick={handleSaveTemplate} 
        className="bg-blue-500 text-white p-2 rounded mr-2"
      >
        Save Changes
      </button>
      <button 
        onClick={handleDeleteTemplate} 
        className="bg-red-500 text-white p-2 rounded"
      >
        Delete Template
      </button>
    </div>
  </div>
)}


{/* UID Input and Fetch Risk Bank */}
      <div className="mb-4">
        <input
          type="text"
          placeholder="Enter UID"
          value={uidInput}
          onChange={(e) => setUidInput(e.target.value)}
          className="border p-2 mr-2"
        />
        <button onClick={fetchRiskBank} className="bg-blue-500 text-white p-2 rounded">
          Fetch Risk Bank
        </button>
      </div>

      {riskBank.length > 0 && (
        <div className="mb-4">
          <h3 className="text-lg font-bold mb-2">Risk Bank Items:</h3>
          <ul className="list-disc list-inside">
            {riskBank.map((risk) => (
              <li key={risk.id} className="cursor-pointer">
                <span
                  className="text-blue-500 underline"
                  onClick={() => displayRiskContent(risk.id)}
                >
                  {risk.name} (Trigger: {risk.trigger})
                </span>
                <button
                  onClick={() => deleteRisk(risk.id)}
                  className="bg-red-500 text-white ml-4 p-1 rounded"
                >
                  Delete Risk
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}

      {selectedRisk && (
        <div className="mb-4">
          <h3 className="text-lg font-bold mb-2">Edit Risk: {selectedRisk.name}</h3>
          <p><strong>Trigger:</strong> {selectedRisk.trigger}</p>
          <textarea
            value={editingRiskContent}
            onChange={(e) => setEditingRiskContent(e.target.value)}
            className="border p-2 w-full mb-2"
            rows="10"
          />
          <button
            onClick={saveRiskBankContent}
            className="bg-blue-500 text-white p-2 rounded mr-2"
          >
            Save Changes
          </button>
          <button
            onClick={() => setSelectedRisk(null)}
            className="bg-gray-500 text-white p-2 rounded"
          >
            Cancel
          </button>
        </div>
      )}



      {/* Form to Add New Risk */}
      <div className="mb-4">
        <h3 className="text-lg font-bold mb-2">Add New Risk</h3>
        <input
          type="text"
          placeholder="Risk Name"
          value={newRiskName}
          onChange={(e) => setNewRiskName(e.target.value)}
          className="border p-2 mr-2"
        />
        <input
          type="text"
          placeholder="Risk Trigger"
          value={newRiskTrigger}
          onChange={(e) => setNewRiskTrigger(e.target.value)}
          className="border p-2 mr-2"
        />
        <textarea
          placeholder="Risk Content"
          value={newRiskContent}
          onChange={(e) => setNewRiskContent(e.target.value)}
          className="border p-2 mr-2"
          rows="4"
        />
        <button onClick={addNewRiskToBank} className="bg-green-500 text-white p-2 rounded mt-2">
          Add New Risk
        </button>
      </div>

      <NpsOverview />

      <div className="mb-4">
        <h3 className="text-lg font-bold mb-2">Transfer Data Between Users</h3>
        <input
          type="text"
          placeholder="Enter Old UID"
          value={oldUid}
          onChange={(e) => setOldUid(e.target.value)}
          className="border p-2 mr-2"
        />
        <input
          type="text"
          placeholder="Enter New UID"
          value={newUid}
          onChange={(e) => setNewUid(e.target.value)}
          className="border p-2 mr-2"
        />
        <button
          onClick={() => transferDataBetweenUsers(oldUid, newUid)}
          className="bg-blue-500 text-white p-2 rounded"
        >
          Transfer Data
        </button>
      </div>

      <FeedbackOverview />
      <UserProgressAdmin />
      <AdminTemplateManager />





    </main>
  );
};

export default Admin;
