// NotesList.jsx
import React, { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { firestore, auth } from '../../firebase';
import { collection, getDocs, query, orderBy, limit, startAfter, startAt, getCountFromServer, doc, deleteDoc, updateDoc } from 'firebase/firestore';
import './NotesList.css';
import CopyButton from '../CopyButton';
import PatientLetters from '../letters/PatientLetters';
import ReferralLetters from '../letters/ReferralLetters';
import ReferralResponseLetter from '../letters/ReferralResponseLetter';
import MolarMailerConsent from '../molarmailer/MolarMailerConsent';
import CreateMolarMailButton from '../modularletters/CreateMolarMailButton';
import CircleLoader from '../circleloader/CircleLoader';
import { useSelectedNote } from './SelectedNoteContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faUserSlash } from '@fortawesome/free-solid-svg-icons';
import EditPatientIdModal from './EditPatientIdModal';
import OnboardingJoyrideWrapper from '../onboardingsidebar/OnboardingJoyrideWrapper';
import { OnboardingContext } from '../onboardingsidebar/OnboardingContext';

const NotesList = ({ openSidebar }) => {
    const [notes, setNotes] = useState([]);
    const [selectedNote, setSelectedNote] = useState(null);
    const [groupedNotes, setGroupedNotes] = useState({});
    const { selectNote } = useSelectedNote();
    const [searchTerm, setSearchTerm] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [lastDoc, setLastDoc] = useState(null);
    const [firstDoc, setFirstDoc] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const navigate = useNavigate();
    const notesPerPage = 20;
    const [selectedNotes, setSelectedNotes] = useState([]);
    const [hasWarnedDeleteAudio, setHasWarnedDeleteAudio] = useState(false);

    // New state variables for editing Patient ID
    const [isPatientIdModalVisible, setIsPatientIdModalVisible] = useState(false);
    const [currentNoteId, setCurrentNoteId] = useState(null);
    const [newPatientId, setNewPatientId] = useState('');

    const { onboardingStepKey, setOnboardingStepKey } = useContext(OnboardingContext);
    const [userData, setUserData] = useState(null);
  
    // Define refs
    const onboarding1Ref = useRef(null);
  
    const onboardingRefs = {
      onboarding1Ref,
    };

    // Define stepsDefinition function
    const stepsDefinition = (stepKey, refs) => {
        let steps = [];
        if (stepKey === 'step10') {
          steps = [
            {
              target: refs.onboarding1Ref.current,
              content: "📝 Pick the exam note you recently made, then press 'Patient Letter' to generate and download a Word Document automatically.",
              disableBeacon: true,
            },
          ];
        }
        return steps;
      };

    const fetchNotes = async (page = 1) => {
        setIsLoading(true);
        const uid = auth.currentUser.uid;
        let notesQuery = query(
            collection(firestore, 'customers', uid, 'notes'),
            orderBy('timestamp', 'desc'),
            limit(notesPerPage)
        );

        if (page > 1) {
            const startDoc = page > currentPage ? lastDoc : firstDoc;
            notesQuery = page > currentPage
                ? query(notesQuery, startAfter(startDoc))
                : query(notesQuery, startAt(startDoc));
        }

        const notesSnapshot = await getDocs(notesQuery);
        const notesData = notesSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
            timestamp: doc.data().timestamp.toDate(),
            audioLink: doc.data().audio
        })).filter(note => !note.delete);

        setLastDoc(notesSnapshot.docs[notesSnapshot.docs.length - 1]);
        setFirstDoc(notesSnapshot.docs[0]);
        setNotes(notesData);

        const grouped = {};
        notesData.forEach(note => {
            const dateKey = note.timestamp.toLocaleDateString('en-GB');
            if (!grouped[dateKey]) {
                grouped[dateKey] = [];
            }
            grouped[dateKey].push(note);
        });
        setGroupedNotes(grouped);
        setCurrentPage(page);
        setIsLoading(false);
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value.toLowerCase());
    };

    useEffect(() => {
        const fetchTotalPages = async () => {
            const uid = auth.currentUser.uid;
            const notesCollectionRef = collection(firestore, 'customers', uid, 'notes');
            const snapshot = await getCountFromServer(notesCollectionRef);
            setTotalPages(Math.ceil(snapshot.data().count / notesPerPage));
        };

        fetchTotalPages();
        fetchNotes();
    }, []);

    const handleCheckboxChange = (note, event) => {
        event.stopPropagation(); // Prevent row click
        if (selectedNotes.includes(note.id)) {
            setSelectedNotes(selectedNotes.filter((id) => id !== note.id));
        } else {
            setSelectedNotes([...selectedNotes, note.id]);
        }
    };

    const handleSelectAllNotesForDay = (date) => {
        const eligibleNoteIdsForDay = groupedNotes[date]
            .filter((note) => !note.title.toLowerCase().includes("audio recording"))
            .map((note) => note.id);
    
        const allSelected = eligibleNoteIdsForDay.length > 0 && eligibleNoteIdsForDay.every((id) => selectedNotes.includes(id));
    
        if (allSelected) {
            // Deselect all eligible notes for the day
            setSelectedNotes((prevSelected) => 
                prevSelected.filter((id) => !eligibleNoteIdsForDay.includes(id))
            );
        } else {
            // Select all eligible notes for the day
            setSelectedNotes((prevSelected) => 
                [...new Set([...prevSelected, ...eligibleNoteIdsForDay])]
            );
        }
    };
    
    const handleDeleteSelectedNotes = async () => {
        if (selectedNotes.length === 0) {
            alert("No notes selected for deletion.");
            return;
        }

        const uid = auth.currentUser.uid;

        // Get selected notes data
        const selectedNotesData = notes.filter(note => selectedNotes.includes(note.id));

        // Separate audio recording and other notes
        const audioRecordingNotes = selectedNotesData.filter(note => note.title.toLowerCase().includes("audio recording"));
        const otherNotes = selectedNotesData.filter(note => !note.title.toLowerCase().includes("audio recording"));

        if (audioRecordingNotes.length > 0 && !hasWarnedDeleteAudio) {
            // First warning
            alert("Notes containing 'Audio Recording' are part of the patient's record and should not be deleted.");
            // Deselect audio recording notes
            const audioNoteIds = audioRecordingNotes.map(note => note.id);
            setSelectedNotes(prevSelected => prevSelected.filter(id => !audioNoteIds.includes(id)));
            // Set warning flag
            setHasWarnedDeleteAudio(true);
            return;
        }

        if (audioRecordingNotes.length > 0 && hasWarnedDeleteAudio) {
            // Second confirmation
            const confirmDelete = window.confirm("Deleting these 'Audio Recording' notes cannot be undone. Are you sure you want to proceed?");
            if (!confirmDelete) {
                return;
            }
        }

        // Determine all notes to delete
        const notesToDelete = otherNotes.map(note => note.id);
        if (audioRecordingNotes.length > 0 && hasWarnedDeleteAudio) {
            notesToDelete.push(...audioRecordingNotes.map(note => note.id));
        }

        if (notesToDelete.length === 0) {
            alert("No eligible notes selected for deletion.");
            return;
        }

        try {
            // Use Promise.all for parallel deletion
            await Promise.all(notesToDelete.map(noteId => {
                const noteRef = doc(firestore, 'customers', uid, 'notes', noteId);
                return deleteDoc(noteRef);
            }));
            alert('Selected notes deleted successfully.');
            // Refresh notes after deletion
            fetchNotes(currentPage);
            setSelectedNotes([]);
            // Reset warning flag
            setHasWarnedDeleteAudio(false);
        } catch (error) {
            console.error('Error deleting notes:', error);
            alert('An error occurred while deleting the notes.');
        }
    };

    const handleCopyToClipboard = (text) => {
        navigator.clipboard.writeText(text);
    };

    const handleClosePopup = (event) => {
        event.stopPropagation();
        setSelectedNote(null);
    };

    const handleOverlayClick = () => {
        setSelectedNote(null);
    };

    const formatDate = (dateString) => {
        const [day, month, year] = dateString.split('/');
        const date = new Date(`${year}-${month}-${day}`);
        const options = { day: 'numeric', month: 'long', year: 'numeric' };
        return date.toLocaleDateString('en-GB', options);
    };

    const formatTime = (date) => {
        return date.toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit', hour12: true });
    };

    const removeDoubleAsterisks = (text) => {
        if (!text) return '';
        let formattedText = text.replace(/\*\*(.*?)\*\*/g, '$1');
        formattedText = formattedText.replace(/##/g, '');
        formattedText = formattedText.replace(/###/g, '');
        formattedText = formattedText.replace(/【\d+†.*?】/g, '');
        return formattedText;
    };

    const containsReferral = selectedNote && selectedNote.content ? /referr(al|er)/i.test(selectedNote.content) : false;

    const filteredNotes = Object.entries(groupedNotes).reduce((acc, [date, notes]) => {
        const filtered = notes.filter(note => {
            const formattedDate = formatDate(date).toLowerCase();
            const formattedTime = formatTime(note.timestamp).toLowerCase();
            return (
                note.title.toLowerCase().includes(searchTerm) ||
                (note.content && note.content.toLowerCase().includes(searchTerm)) ||
                (note.patientId && note.patientId.toLowerCase().includes(searchTerm)) ||
                formattedDate.includes(searchTerm) ||
                formattedTime.includes(searchTerm)
            );
        });
        if (filtered.length > 0) {
            acc[date] = filtered;
        }
        return acc;
    }, {});

    const handleDeleteNote = async (event, noteId) => {
        event.stopPropagation();
        
        // Find the note to delete
        const noteToDelete = notes.find(note => note.id === noteId);
        
        if (!noteToDelete) {
            alert("Note not found.");
            return;
        }
    
        // Check if the note is an audio recording
        if (noteToDelete.title.toLowerCase().includes("audio recording")) {
            if (!hasWarnedDeleteAudio) {
                // First warning
                alert("This audio forms part of the patient's record. It is not advisable to delete it.");
                // Set warning flag
                setHasWarnedDeleteAudio(true);
                return;
            } else {
                // Second confirmation
                const confirmDelete = window.confirm("Deleting this 'Audio Recording' note cannot be undone. Are you sure you want to proceed?");
                if (!confirmDelete) {
                    return;
                }
            }
        }
    
        // Proceed to delete the note
        const uid = auth.currentUser.uid;
        const noteRef = doc(firestore, 'customers', uid, 'notes', noteId);
    
        try {
            await deleteDoc(noteRef); // Directly delete the note from the user's collection
            fetchNotes(currentPage);  // Refresh the notes list after deletion
            alert('Note deleted successfully.');
            setHasWarnedDeleteAudio(false); // Reset the warning flag after successful deletion
        } catch (error) {
            console.error('Error deleting note:', error);
            alert('An error occurred while deleting the note.');
        }
    };

    const handleLoadTreatmentPlanner = (noteId) => {
        navigate(`/mvp/treatmentplanner/${noteId}`);
    };

    const handleDownloadAudio = async (downloadURL) => {
        if (downloadURL) {
            try {
                const response = await fetch(downloadURL);
                if (!response.ok) throw new Error('Network response was not ok.');
                const blob = await response.blob();
                const blobUrl = window.URL.createObjectURL(blob);

                const a = document.createElement('a');
                a.href = blobUrl;
                a.download = `audio_recording_${new Date().toISOString()}.webm`;
                document.body.appendChild(a);
                a.click();

                window.URL.revokeObjectURL(blobUrl);
                document.body.removeChild(a);
            } catch (error) {
                console.error('Error downloading audio:', error);
            }
        }
    };

    const handlePageChange = (page) => {
        if (page > 0 && page <= totalPages) {
            fetchNotes(page);
        }
    };

    const renderPageNumbers = () => {
        const pageNumbers = [];
        let startPage = Math.max(1, currentPage - 2);
        let endPage = Math.min(totalPages, currentPage + 2);

        if (currentPage === 1) {
            endPage = Math.min(5, totalPages);
        } else if (currentPage === totalPages) {
            startPage = Math.max(1, totalPages - 4);
        }

        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(
                <li key={i}>
                    <button
                        onClick={() => handlePageChange(i)}
                        className={`flex items-center justify-center px-3 h-8 leading-tight ${
                            currentPage === i
                                ? 'text-primary-600 border border-primary-300 bg-primary-50 hover:bg-primary-100 hover:text-primary-700'
                                : 'text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white'
                        }`}
                    >
                        {i}
                    </button>
                </li>
            );
        }
        return pageNumbers;
    };

    // Handler to open the edit Patient ID modal
    const handlePatientIdClick = (noteId, currentPatientId) => {
        setCurrentNoteId(noteId);
        setNewPatientId(currentPatientId || '');
        setIsPatientIdModalVisible(true);
    };

    // Handler to save the updated Patient ID
    const handleSavePatientId = async (updatedPatientId) => {
        if (!updatedPatientId.trim()) {
            alert("Patient ID cannot be empty.");
            return;
        }

        const uid = auth.currentUser.uid;
        const noteRef = doc(firestore, 'customers', uid, 'notes', currentNoteId);

        try {
            await updateDoc(noteRef, { patientId: updatedPatientId });
            // Refresh the notes list to reflect changes
            fetchNotes(currentPage);
            // Close the modal
            setIsPatientIdModalVisible(false);
            setCurrentNoteId(null);
            setNewPatientId('');
        } catch (error) {
            console.error("Error updating Patient ID:", error);
            alert("Failed to update Patient ID. Please try again.");
        }
    };

    return (
        <main className="flex flex-col items-center tracking-tight w-4/5 mx-auto">
            {/* Search Bar and Delete Selected Notes Button */}
            <div className="flex items-center w-full mx-auto justify-center mb-4">
                <div className="flex items-center w-1/5">
                    <input
                        type="text"
                        placeholder="Search..."
                        value={searchTerm}
                        onChange={handleSearchChange}
                        className="block w-full p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 text-xs focus:ring-primary-500 focus:border-primary-500"
                    />
                </div>
                {selectedNotes.length > 0 && (
                    <button
                        onClick={handleDeleteSelectedNotes}
                        className="px-4 py-1 ml-2 bg-primary-400 text-white rounded-lg hover:bg-red-700"
                    >
                        <FontAwesomeIcon icon={faTrash} />
                    </button>
                )}
            </div>

            {/* Notes Table */}
            {isLoading ? <CircleLoader /> : (
                <div ref={onboarding1Ref} className="overflow-x-auto w-3/5 text-sm text-left text-gray-500">
                    {Object.entries(filteredNotes).map(([date, notes]) => {
                        // Calculate eligible notes for the date that do not have "audio recording" in their title
                        const eligibleNoteIdsForDay = notes
                            .filter(note => !note.title.toLowerCase().includes("audio recording"))
                            .map(note => note.id);
                    
                        const allSelected = eligibleNoteIdsForDay.length > 0 && eligibleNoteIdsForDay.every(id => selectedNotes.includes(id));

                        return (
                            <React.Fragment key={date}>
                                {/* Date Header with Select/Deselect All Button */}
                                <div className="text-xs text-gray-600 rounded-lg uppercase bg-gradient-to-r from-primary-50 to-primary-100 p-4 flex justify-between items-center">
                                    <span>{formatDate(date)}</span>
                                    <button
                                        onClick={() => handleSelectAllNotesForDay(date)}
                                        className="text-primary-400 text-xs hover:underline"
                                    >
                                        {allSelected ? 'Deselect All' : 'Select All'}
                                    </button>
                                </div>

                                {/* Notes Rows */}
                                {notes.map(note => (
                                    <div 
                                        key={note.id} 
                                        className={`grid grid-cols-[1fr_1fr_3fr_auto_auto] border-b p-2 cursor-pointer ${selectedNotes.includes(note.id) ? 'bg-primary-100' : 'hover:text-white hover:bg-primary-200'}`}
                                        onClick={() => setSelectedNote(note)}
                                    >
                                        {/* Time Column */}
                                        <div className="truncate">{formatTime(note.timestamp)}</div>

                                        {/* Patient ID Column */}
                                        <div className="patient-id-cell flex items-center space-x-2 cursor-pointer">
                                            {note.patientId ? (
                                                <span onClick={(e) => {
                                                    e.stopPropagation(); // Prevent triggering the row click
                                                    handlePatientIdClick(note.id, note.patientId);
                                                }}
                                                className="hover:text-primary-400 hover:animate-bounce truncate"
                                                >
                                                    {note.patientId}
                                                </span>
                                            ) : (
                                                <FontAwesomeIcon 
                                                    icon={faUserSlash} 
                                                    title="No Patient ID"
                                                    onClick={(e) => {
                                                        e.stopPropagation(); // Prevent triggering the row click
                                                        handlePatientIdClick(note.id, note.patientId);
                                                    }}
                                                    className="text-gray-200 hover:text-primary-400 hover:animate-bounce"
                                                />
                                            )}
                                        </div>

                                        {/* Title Column */}
                                        <div className="truncate">{note.title}</div>

                                        <div className="space-x-4">  
                                        {/* Delete Button */}
                                        <button 
                                            className="text-primary-400 hover:animate-pulse delete-note-button"
                                            onClick={(e) => handleDeleteNote(e, note.id)}
                                        >
                                            <FontAwesomeIcon icon={faTrash} />
                                        </button>
                                        {/* Checkbox */}
                                        <input
                                            type="checkbox"
                                            checked={selectedNotes.includes(note.id)}
                                            onChange={(e) => handleCheckboxChange(note, e)}
                                            className="mr-2 cursor-pointer accent-primary-400"
                                            onClick={(e) => e.stopPropagation()} // Prevent row click
                                        />
                                        </div>
                                    </div>
                                ))}
                            </React.Fragment>
                        );
                    })}
                </div>
            )}

            {/* Pagination */}
            <nav aria-label="Page navigation example" className="mt-4">
                <ul className="flex items-center -space-x-px h-8 text-sm">
                    <li>
                        <button
                            onClick={() => handlePageChange(currentPage - 1)}
                            disabled={currentPage === 1}
                            className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-e-0 border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700"
                        >
                            <span className="sr-only">Previous</span>
                            <svg className="w-2.5 h-2.5 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 1 1 5l4 4" />
                            </svg>
                        </button>
                    </li>
                    {renderPageNumbers()}
                    <li>
                        <button
                            onClick={() => handlePageChange(currentPage + 1)}
                            disabled={currentPage === totalPages}
                            className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700"
                        >
                            <span className="sr-only">Next</span>
                            <svg className="w-2.5 h-2.5 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 9 4-4-4-4" />
                            </svg>
                        </button>
                    </li>
                </ul>
            </nav>

            {/* Notes Popup */}
            {selectedNote && (
                <div className="fixed inset-0 z-50 bg-black bg-opacity-50 flex items-center justify-center p-4 text-sm tracking-tighter" onClick={handleOverlayClick}>
                    <div className="relative bg-white shadow-lg mx-auto w-3/5 p-4 flex flex-col items-center rounded-xl" onClick={(e) => e.stopPropagation()}>
                        {(selectedNote.title === "Audio Recording - Jazmode" ||
                            selectedNote.title === "Audio Recording - Platypus" ||
                            selectedNote.title.includes("Audio Recording")) ? (
                            <div className="noteslistta">
                                <a
                                    href={selectedNote.content}
                                    download={`audio_recording_${new Date().toISOString()}.webm`}
                                    className="download-audio-button"
                                >
                                    Right-click here (or control-click on Mac) and choose 'Save link as...' to download the audio transcript
                                </a>
                            </div>
                        ) : (
                            <div className="flex flex-col w-full">
                                {/* Adjusted to handle cases where transcript or nursesInput might be missing */}
                                {selectedNote.transcript ? (
                                    <textarea 
                                        className="bg-gray-50 p-2 rounded-lg mb-4 border" 
                                        value={selectedNote.transcript} 
                                        rows="5" 
                                        readOnly
                                    ></textarea>
                                ) : selectedNote.nursesInput ? (
                                    <textarea 
                                        className="bg-gray-50 p-2 rounded-lg mb-4 border" 
                                        value={selectedNote.nursesInput} 
                                        rows="5" 
                                        readOnly
                                    ></textarea>
                                ) : null}

                                {/* Adjusted to handle cases where content might be missing */}
                                {selectedNote.content ? (
                                    <textarea 
                                        className="bg-gray-50 p-2 rounded-lg mb-4 border" 
                                        value={removeDoubleAsterisks(selectedNote.content)} 
                                        rows="25" 
                                        readOnly
                                    ></textarea>
                                ) : null}

                                {selectedNote.content2 && (
                                    <textarea 
                                        value={removeDoubleAsterisks(selectedNote.content2)} 
                                        readOnly
                                    ></textarea>
                                )}
                            </div>
                        )}
                        <div className="flex gap-2">
                            {selectedNote.title === "Exam Notes" && (
                                <button
                                    className="ml-2 px-2 py-1 text-xs text-primary-400 bg-white border border-gray-200 rounded-lg hover:bg-primary-400 hover:text-white"
                                    onClick={() => handleLoadTreatmentPlanner(selectedNote.id)}
                                >
                                    Re-Load Exam
                                </button>
                            )}

                            {/* Conditionally render buttons and components based on the existence of content */}
                            {selectedNote.content ? (
                                <>
                                    <CopyButton textToCopy={removeDoubleAsterisks(selectedNote.content)} />
                                    {selectedNote.content2 && (
                                        <CopyButton textToCopy={removeDoubleAsterisks(selectedNote.content2)} buttonText="Copy #2" />
                                    )}
                                    <div>
                                        <PatientLetters noteContent={removeDoubleAsterisks(selectedNote.content)} />
                                        {containsReferral && (
                                            <ReferralLetters noteContent={removeDoubleAsterisks(selectedNote.content)} />
                                        )}
                                        <ReferralResponseLetter noteContent={removeDoubleAsterisks(selectedNote.content)} />
                                    </div>
                                    <MolarMailerConsent 
                                        className="px-4 py-2 text-xs text-primary-400 bg-white border border-gray-200 rounded-lg hover:bg-primary-400 hover:text-white" 
                                        noteContent={removeDoubleAsterisks(selectedNote.content)} 
                                        patientId={selectedNote.patientId} 
                                    />
                                    <CreateMolarMailButton
                                        className="px-4 py-2 text-xs text-primary-400 bg-white border border-gray-200 rounded-lg hover:bg-primary-400 hover:text-white"
                                        clinicalRecordText={removeDoubleAsterisks(selectedNote.content)}
                                        teethData={selectedNote.teethData}
                                        treatmentPlanData={selectedNote.treatmentPlanData}
                                    />
                                </>
                            ) : selectedNote.nursesInput ? (
                                /* If content doesn't exist but nursesInput does, provide a Copy button for nursesInput */
                                <>
                                    <CopyButton textToCopy={selectedNote.nursesInput} buttonText="Copy Nurse's Input" />
                                </>
                            ) : null}

                            {/* Always provide the Edit Note button */}
                            <button 
                                className="px-4 py-2 text-xs text-primary-400 bg-white border border-gray-200 rounded-lg hover:bg-primary-400 hover:text-white" 
                                onClick={() => selectNote(selectedNote)}
                            >
                                Edit Note
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {/* Edit Patient ID Modal */}
            <EditPatientIdModal 
                isVisible={isPatientIdModalVisible} 
                onClose={() => {
                    setIsPatientIdModalVisible(false);
                    setCurrentNoteId(null);
                    setNewPatientId('');
                }} 
                onSave={handleSavePatientId}
                currentPatientId={newPatientId}
            />
            <OnboardingJoyrideWrapper
                stepKey="step10"
                stepsDefinition={stepsDefinition}
                refs={onboardingRefs}
                onboardingStepKey={onboardingStepKey}
                setOnboardingStepKey={setOnboardingStepKey}
                userData={userData}
                openSidebar={openSidebar}
            />
        </main>
    );

};

export default NotesList;
