import React, { useEffect, useState } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { firestore, auth } from '../../firebase';
import { Link } from 'react-router-dom';
import { videos } from './videos';

const KnowledgeVault = () => {
  const [watchedStatus, setWatchedStatus] = useState({});

  useEffect(() => {
    const fetchWatchedStatus = async () => {
      const user = auth.currentUser;
      if (user) {
        const userVideosCollection = collection(firestore, 'customers', user.uid, 'knowledgevault');
        const userSnapshot = await getDocs(userVideosCollection);
        const status = {};
        userSnapshot.forEach(doc => {
          status[doc.id] = doc.data().watched;
        });
        setWatchedStatus(status);
      }
    };

    fetchWatchedStatus();
  }, []);

  return (
    <main className="flex flex-col items-center tracking-tighter w-4/5 mx-auto">
 <div className="bg-primary-50 border border-primary-500 text-gray-700 px-4 py-3 rounded-lg relative tracking-tighter text-sm w-3/5">
  <strong className="font-bold">Introducing the Knowledge Vault!</strong>
  <p className="mt-2">
    Hate reading or watching instructions? I feel you. It's boring! Just watch video #4. Seriously, if you watch video #4, it will work wonders for you.
  </p>
  <p className="mt-2">
    But I do recommend watching all of the videos to get the most from the platform. The best part? All the videos combined are just 20 minutes. 20 minutes now to save you a ton of time later.
  </p>
  <p className="mt-2">
    No fancy equipment needed. A simple microphone from Amazon will do the trick. For Jazmode, though, splurge a little on a good quality mic. Your ears will thank you. You have links in your emails.
  </p>
  <p className="mt-2">
    Prefer reading? We've got you covered. Check out the <a href="https://digitaltco.co.uk/docs" target="_blank" className="text-primary-500 underline">written explanations</a>.
  </p>
</div>


      
      <div className="relative overflow-x-auto w-3/5 mt-8">
        <table className="w-full text-sm text-left rtl:text-right text-gray-500">
          <thead className="text-xs text-gray-500 uppercase bg-primary-50">
            <tr>
              <th scope="col" className="px-6 py-3">
                Video Title
              </th>
              <th scope="col" className="px-6 py-3">
                Watched Status
              </th>
            </tr>
          </thead>
          <tbody>
            {videos.map(video => (
              <tr key={video.id} className="bg-white border-b cursor-pointer hover:bg-primary-200 hover:text-white">
                <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap hover:text-white">
                  <Link to={`/mvp/knowledgevault/video/${video.id}`} className="text-primary-400">
                    {video.title}
                  </Link>
                </th>
                <td className="px-6 py-4">
                  {watchedStatus[video.id] ? '✔️' : '❌'}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </main>
  );
};

export default KnowledgeVault;
