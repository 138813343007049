// src/components/HelpScout.js
import { useEffect } from 'react';
import { auth, firestore } from '../../firebase'; // Ensure correct path to Firebase imports
import { doc, getDoc, setDoc } from 'firebase/firestore';

const HelpScout = () => {
  useEffect(() => {
    // Insert HelpScout's embed script
    (function(e, t, n) {
      function a() {
        const script = t.createElement("script");
        const firstScript = t.getElementsByTagName("script")[0];
        script.type = "text/javascript";
        script.async = true;
        script.src = "https://beacon-v2.helpscout.net";
        firstScript.parentNode.insertBefore(script, firstScript);
        
        script.onload = async () => {
          console.log('HelpScout Beacon script loaded.');
          if (window.Beacon) {
            window.Beacon('init', '107b8f9f-3b01-4940-9331-667976d744ec');
            console.log('HelpScout Beacon initialized.');

            const user = auth.currentUser;
            if (user) {
              try {
                const userDocRef = doc(firestore, 'customers', user.uid);
                const userDoc = await getDoc(userDocRef);

                let userEmail;

                // Check if `dtcocustomeremail` exists in Firestore
                if (userDoc.exists() && userDoc.data().dtcocustomeremail) {
                  userEmail = userDoc.data().dtcocustomeremail;
                  console.log('Using cached email from Firestore:', userEmail);
                } else {
                  // Get ID token for authorization
                  const idToken = await user.getIdToken();

                  // Fetch email from backend
                  const response = await fetch('https://us-central1-digitaltco-c40e9.cloudfunctions.net/getUserEmails', {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                      'Authorization': `Bearer ${idToken}`,
                    },
                    body: JSON.stringify({ uids: [user.uid] }),
                  });

                  const userData = await response.json();
                  userEmail = userData[0]?.email;

                  // Store fetched email in Firestore for future use
                  if (userEmail) {
                    await setDoc(userDocRef, { dtcocustomeremail: userEmail }, { merge: true });
                    console.log('Email fetched and stored in Firestore:', userEmail);
                  } else {
                    console.warn('User email not found from backend.');
                  }
                }

                const userName = userDoc.exists() ? userDoc.data().name : 'Customer';
                const dtcouid = user.uid;
                // Identify user in Help Scout Beacon
                if (userEmail) {
                  window.Beacon('identify', {
                    name: userName,
                    email: userEmail,
                    'uid': dtcouid,
                  });
                  console.log('User identified in Help Scout Beacon.');
                }
              } catch (error) {
                console.error('Error identifying user in Help Scout:', error);
              }
            }
          } else {
            console.error('HelpScout Beacon failed to initialize.');
          }
        };
      }

      if (e.Beacon = n = function(t, n, a) {
        e.Beacon.readyQueue.push({ method: t, options: n, data: a });
      }, n.readyQueue = [], "complete" === t.readyState) {
        a();
      } else {
        e.addEventListener ? e.addEventListener("load", a, false) : e.attachEvent("onload", a);
      }
    })(window, document, window.Beacon || function() {});

    // Cleanup function to remove Beacon instance if needed
    return () => {
      if (window.Beacon) {
        window.Beacon('destroy');
        console.log('HelpScout Beacon destroyed.');
      }
    };
  }, []);

  return null; // No visible rendering
};

export default HelpScout;
