// IdealVsActualTeeth.js
import React from 'react';

const IdealVsActualTeeth = ({ id }) => {
    return (
        <div key={id} className="bg-gray-100 p-4 rounded-lg">
            <p className="text-sm text-gray-900">This section will show a comparison between ideal teeth and the patient's teeth with annotations.</p>
        </div>
    );
};

export default IdealVsActualTeeth;
