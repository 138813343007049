import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { firestore, auth } from '../../firebase';
import PatientProfileNav from './PatientProfileNav';
import './PatientNotesInput.css'; // Ensure this CSS file contains styles for your new page

const TreatmentPlan = () => {
  const navigate = useNavigate();
  const { patientId } = useParams();
  const [diagnosedCaries, setDiagnosedCaries] = useState([]);
  const [diagnosedPathology, setDiagnosedPathology] = useState([]);
  const [treatmentPlan, setTreatmentPlan] = useState({});
  const [selectedTreatment, setSelectedTreatment] = useState('');
  const [nonToothSpecificTreatments, setNonToothSpecificTreatments] = useState({
    Invisalign: false,
    'Fixed Ortho': false,
    Hygiene: false,
    Sedation: false,
  });  

  const toothTextMap = {
    1: 'UR8', 2: 'UR7', 3: 'UR6', 4: 'UR5', 5: 'UR4', 6: 'UR3', 7: 'UR2', 8: 'UR1',
    9: 'UL1', 10: 'UL2', 11: 'UL3', 12: 'UL4', 13: 'UL5', 14: 'UL6', 15: 'UL7', 16: 'UL8',
    17: 'LR8', 18: 'LR7', 19: 'LR6', 20: 'LR5', 21: 'LR4', 22: 'LR3', 23: 'LR2', 24: 'LR1',
    25: 'LL1', 26: 'LL2', 27: 'LL3', 28: 'LL4', 29: 'LL5', 30: 'LL6', 31: 'LL7', 32: 'LL8',
  };

  useEffect(() => {
    const patientRef = doc(firestore, 'customers', auth.currentUser.uid, 'patients', patientId);
  
    const fetchDiagnosedConditions = async () => {
      const patientDoc = await getDoc(patientRef);
      if (patientDoc.exists()) {
        const notes = patientDoc.data().notes;
        let cariesConditions = [];
        let pathologyConditions = [];
  
        // Fetch clinical caries and add to cariesConditions
        if (notes && notes.clinicalCaries) {
          cariesConditions = cariesConditions.concat(notes.clinicalCaries.split(', '));
        }
  
        // Fetch radiographic caries and add to cariesConditions
        if (notes && notes.radiographAssessment && notes.radiographAssessment.cariesAssessment) {
          cariesConditions = cariesConditions.concat(notes.radiographAssessment.cariesAssessment.split(', '));
        }
  
        // Fetch pathology and add to pathologyConditions
        if (notes && notes.radiographAssessment && notes.radiographAssessment.pathologyAssessment) {
          pathologyConditions = pathologyConditions.concat(notes.radiographAssessment.pathologyAssessment.split(', '));
        }
  
        // Remove duplicates within cariesConditions and pathologyConditions
        cariesConditions = [...new Set(cariesConditions)];
        pathologyConditions = [...new Set(pathologyConditions)];
  
        // Convert to tooth numbers
        const cariesTeethNumbers = cariesConditions.map(condition => 
          Object.keys(toothTextMap).find(key => toothTextMap[key] === condition)
        );
        const pathologyTeethNumbers = pathologyConditions.map(condition => 
          Object.keys(toothTextMap).find(key => toothTextMap[key] === condition)
        );
  
        // Update state with the tooth numbers for caries and pathology
        setDiagnosedCaries(cariesTeethNumbers);
        setDiagnosedPathology(pathologyTeethNumbers);
      }
    };

    const fetchTreatmentPlan = async () => {
      const patientDoc = await getDoc(patientRef);
      if (patientDoc.exists()) {
        const data = patientDoc.data();
        const notes = data.notes;
        let fetchedTreatmentPlan = {};
      
        // Fetch and process tooth-specific treatments
        if (notes && notes.treatmentPlan) {
          const treatmentEntries = notes.treatmentPlan.split('; ').flatMap(entry => {
            const [tooth, treatments] = entry.split(' - ');
            if (treatments) {
              return treatments.split(', ').map(treatment => {
                return [tooth, treatment.trim()];
              });
            }
            return [];
          });
      
          fetchedTreatmentPlan = treatmentEntries.reduce((acc, [tooth, treatment]) => {
            const toothNumber = Object.keys(toothTextMap).find(key => toothTextMap[key] === tooth);
            if (toothNumber) {
              if (!acc[toothNumber]) {
                acc[toothNumber] = [];
              }
              acc[toothNumber].push(treatment);
            }
            return acc;
          }, {});
      
          Object.keys(fetchedTreatmentPlan).forEach(tooth => {
            fetchedTreatmentPlan[tooth] = fetchedTreatmentPlan[tooth].join(', ');
          });
        }
      
        setTreatmentPlan(fetchedTreatmentPlan);
    
        // Fetch and process non-tooth-specific treatments
        if (notes && notes.nonToothSpecificTreatments) {
          const nonToothSpecificTreatmentsArray = notes.nonToothSpecificTreatments.split('; ');
          const updatedNonToothSpecificTreatments = { ...nonToothSpecificTreatments };
    
          nonToothSpecificTreatmentsArray.forEach(treatment => {
            if (treatment in updatedNonToothSpecificTreatments) {
              updatedNonToothSpecificTreatments[treatment] = true;
            }
          });
    
          setNonToothSpecificTreatments(updatedNonToothSpecificTreatments);
        }
      }
    };   

    fetchDiagnosedConditions();
    fetchTreatmentPlan();
  }, [patientId]);  

  const handleTreatmentSelection = (treatment) => {
    if (treatment in nonToothSpecificTreatments) {
      setNonToothSpecificTreatments(prev => ({
        ...prev,
        [treatment]: !prev[treatment],
      }));
    } else {
      setSelectedTreatment(treatment);
    }
  };  

  const renderNonToothSpecificTreatmentButtons = () => {
    return (
      <div className="treatment-buttons">
        {Object.keys(nonToothSpecificTreatments).map(treatment => (
          <button
            key={treatment}
            className={`treatment-button ${nonToothSpecificTreatments[treatment] ? 'selected' : ''}`}
            onClick={() => handleTreatmentSelection(treatment)}
          >
            {treatment}
          </button>
        ))}
      </div>
    );
  };  

  const handleToothClick = (tooth) => {
    setTreatmentPlan(prev => {
      // Get the current treatments for the tooth, if any
      const currentTreatments = prev[tooth] ? prev[tooth].split(', ') : [];
      let updatedTreatments;
  
      // Check if the selected treatment is already added
      if (currentTreatments.includes(selectedTreatment)) {
        // Remove the treatment if it's already there
        updatedTreatments = currentTreatments.filter(treatment => treatment !== selectedTreatment);
      } else {
        // Append the new treatment to the list if it's not already there
        updatedTreatments = [...currentTreatments, selectedTreatment];
      }
  
      // If there are no treatments left after removal, delete the tooth key from the plan
      if (updatedTreatments.length === 0) {
        const {[tooth]: _, ...rest} = prev;
        return rest;
      }
  
      // Return the updated treatment plan
      return {
        ...prev,
        [tooth]: updatedTreatments.join(', ')
      };
    });
  };
  

  const renderDiagnosisChart = () => {
    return (
      <div className="tooth-chart-grid">
        {Array.from({ length: 32 }, (_, index) => {
          const toothNumber = String(index + 1);
          const isCaries = diagnosedCaries.includes(toothNumber);
          const isPathology = diagnosedPathology.includes(toothNumber);
          let toothClass = '';
          if (isCaries && isPathology) toothClass = 'caries-pathology';
          else if (isCaries) toothClass = 'caries';
          else if (isPathology) toothClass = 'pathology';
  
          return (
            <div
              key={toothNumber}
              className={`tooth-chart-cell ${toothClass} tooth-chart-cell-no-hover`}
            >
              {toothTextMap[toothNumber]}
            </div>
          );
        })}
      </div>
    );
  }; 
  
  const renderTreatmentChart = () => {
    return (
      <div className="tooth-chart-grid">
        {Array.from({ length: 32 }, (_, index) => {
          const toothNumber = index + 1;
          const treatments = treatmentPlan[toothNumber];
          let toothClass = treatments ? ' treatment' : '';
  
          return (
            <div
              key={toothNumber}
              className={`tooth-chart-cell${toothClass} clickable`}
              onClick={() => handleToothClick(toothNumber)}
            >
              {toothTextMap[toothNumber]}
              {treatments ? ` ${treatments}` : ''}
            </div>
          );
        })}
      </div>
    );
  };   
 
  const handleNext = async () => {
    // Convert the treatmentPlan object into a string format for Firestore
    const treatmentPlanEntries = Object.entries(treatmentPlan);
    const treatmentPlanForFirestore = treatmentPlanEntries
      .map(([tooth, treatments]) => `${toothTextMap[tooth]} - ${treatments}`)
      .join('; ');

    const patientRef = doc(firestore, 'customers', auth.currentUser.uid, 'patients', patientId);
  
    const nonToothSpecificTreatmentsForFirestore = Object.entries(nonToothSpecificTreatments)
    .filter(([_, value]) => value)
    .map(([key]) => key)
    .join('; ');

    try {
      // Update the Firestore document with both tooth-specific and non-tooth-specific treatments
      await updateDoc(patientRef, {
        'notes.treatmentPlan': treatmentPlanForFirestore,
        'notes.nonToothSpecificTreatments': nonToothSpecificTreatmentsForFirestore,
      });
  
      console.log("Updated Patient Data with Treatment Plan:", treatmentPlanForFirestore);
  
      // Navigate to the next page or component after successful update
      navigate(`/mvp/patient-profile/${patientId}`);
    } catch (error) {
      // Handle any errors that occur during the update
      console.error("Error updating document with Treatment Plan:", error);
    }
  };


  return (
    <div className="patient-complaints-container">
      <PatientProfileNav patientId={patientId} />
      <h2 className="patient-complaints-h2">Treatment Plan</h2>
      <p>Diagnosed Conditions:</p>
      {renderDiagnosisChart()}
  
      <p>Treatment Plan:</p>
  
      <div className="treatment-buttons-txplan">
        {['Composite', 'Amalgam', 'Fissure Sealant', 'Crown', 'RCT', 'Extraction', 'Denture', 'Implant', 'Bridge', 'Veneer', 'Composite Bonding', 'SSC', 'Perio'].map(treatment => (
          <button
            key={treatment}
            className={`treatment-button ${selectedTreatment === treatment ? 'selected' : ''}`}
            onClick={() => handleTreatmentSelection(treatment)}
          >
            {treatment}
          </button>
        ))}
      </div>

      {renderNonToothSpecificTreatmentButtons()}
  
      {renderTreatmentChart()}
  
      <button className="treatment-plan-button" onClick={handleNext}>Next</button>
    </div>
  );
};  

export default TreatmentPlan;
