import React, { useState, useEffect, useRef } from 'react';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { auth } from '../../firebase';

const ImageUploadModal = ({ images, onUpload, onClose }) => {
  const [uploading, setUploading] = useState(false);
  const [imagePreviews, setImagePreviews] = useState(images.map(() => null));
  const [imageFiles, setImageFiles] = useState(images.map(() => null));
  const [rotations, setRotations] = useState(images.map(() => 0));
  const [flipsH, setFlipsH] = useState(images.map(() => false));
  const [flipsV, setFlipsV] = useState(images.map(() => false));
  const [dragActiveIndex, setDragActiveIndex] = useState(null);
  const imageCache = useRef(images.map(() => null));

  useEffect(() => {
    // Reset state variables when images prop changes
    setImagePreviews(images.map(() => null));
    setImageFiles(images.map(() => null));
    setRotations(images.map(() => 0));
    setFlipsH(images.map(() => false));
    setFlipsV(images.map(() => false));
    imageCache.current = images.map(() => null);
  }, [images]);

  useEffect(() => {
    console.log('Images to be uploaded:', images);
  }, [images]);

  const handleImageChange = (index, file) => {
    if (file) {
      const newImageFiles = [...imageFiles];
      newImageFiles[index] = file;
      setImageFiles(newImageFiles);

      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.src = event.target.result;
        img.onload = () => {
          const newImageCache = [...imageCache.current];
          newImageCache[index] = img;
          imageCache.current = newImageCache;

          const newImagePreviews = [...imagePreviews];
          newImagePreviews[index] = img.src;
          setImagePreviews(newImagePreviews);
        };
      };
      reader.readAsDataURL(file);
    }
  };

  const applyTransformations = (index, rotation, flipH, flipV) => {
    const img = imageCache.current[index];
    if (!img) return;

    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    // Handle canvas size based on rotation
    if (rotation % 180 === 90) {
      canvas.width = img.height;
      canvas.height = img.width;
    } else {
      canvas.width = img.width;
      canvas.height = img.height;
    }

    context.clearRect(0, 0, canvas.width, canvas.height);
    context.save();
    context.translate(canvas.width / 2, canvas.height / 2);
    context.rotate((rotation * Math.PI) / 180);
    context.scale(flipH ? -1 : 1, flipV ? -1 : 1);
    context.drawImage(img, -img.width / 2, -img.height / 2);
    context.restore();

    const newImagePreviews = [...imagePreviews];
    newImagePreviews[index] = canvas.toDataURL('image/jpeg', 0.8); // Adjust quality for compression
    setImagePreviews(newImagePreviews);
  };

  const handleUpload = async () => {
    setUploading(true);
    const uid = auth.currentUser.uid;
    const storage = getStorage();

    const uploadPromises = imageFiles.map(async (file, index) => {
      if (file) {
        const img = imageCache.current[index];
        if (!img) return null;

        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');

        // Apply transformations before creating the dataUrl
        const rotation = rotations[index];
        const flipH = flipsH[index];
        const flipV = flipsV[index];

        if (rotation % 180 === 90) {
          canvas.width = img.height;
          canvas.height = img.width;
        } else {
          canvas.width = img.width;
          canvas.height = img.height;
        }

        context.clearRect(0, 0, canvas.width, canvas.height);
        context.save();
        context.translate(canvas.width / 2, canvas.height / 2);
        context.rotate((rotation * Math.PI) / 180);
        context.scale(flipH ? -1 : 1, flipV ? -1 : 1);
        context.drawImage(img, -img.width / 2, -img.height / 2);
        context.restore();

        const dataUrl = canvas.toDataURL('image/jpeg', 0.8); // Adjust quality for compression
        const blob = await (await fetch(dataUrl)).blob();
        const imageRef = ref(storage, `molarmailer/${uid}/${file.name}-${Date.now()}`);
        await uploadBytes(imageRef, blob);
        const imageUrl = await getDownloadURL(imageRef);
        return { url: imageUrl, label: images[index]?.label || `Image ${index + 1}` };
      }
      return null;
    });

    const uploadedImages = await Promise.all(uploadPromises);
    onUpload(uploadedImages.filter((image) => image !== null && image.label !== undefined));
    setUploading(false);
    onClose();
  };

  const handleRotate = (index) => {
    console.log(`Initial rotation for image at index ${index}: ${rotations[index]} degrees`);
    const newRotations = [...rotations];
    newRotations[index] = (newRotations[index] + 90) % 360;
    setRotations(newRotations);
    applyTransformations(index, newRotations[index], flipsH[index], flipsV[index]);
    console.log(`New rotation for image at index ${index}: ${newRotations[index]} degrees`);
  };

  const handleFlipH = (index) => {
    const newFlipsH = [...flipsH];
    newFlipsH[index] = !newFlipsH[index];
    setFlipsH(newFlipsH);
    applyTransformations(index, rotations[index], newFlipsH[index], flipsV[index]);
  };

  const handleFlipV = (index) => {
    const newFlipsV = [...flipsV];
    newFlipsV[index] = !newFlipsV[index];
    setFlipsV(newFlipsV);
    applyTransformations(index, rotations[index], flipsH[index], newFlipsV[index]);
  };

  const onDragEnter = (index) => (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActiveIndex(index);
  };

  const onDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const onDragLeave = (index) => (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (index === dragActiveIndex) {
      setDragActiveIndex(null);
    }
  };

  const onDrop = (index) => (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActiveIndex(null);
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      handleImageChange(index, e.dataTransfer.files[0]);
      e.dataTransfer.clearData();
    }
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-6 rounded-lg shadow-lg max-w-2xl w-full max-h-[50rem] overflow-y-auto">
        <h2 className="text-lg mb-4 text-gray-700">Upload Images</h2>
        {images.map((image, index) => (
          <div key={index} className="mb-4">
            <label className="block mb-2 text-xs text-primary-400">{image.label}</label>
            {!imagePreviews[index] ? (
              <div
                className="flex items-center justify-center w-full"
                onDragEnter={onDragEnter(index)}
                onDragOver={onDragOver}
                onDragLeave={onDragLeave(index)}
                onDrop={onDrop(index)}
              >
                <label
                  htmlFor={`dropzone-file-${index}`}
                  className={`flex flex-col items-center justify-center w-full h-36 border-2 ${
                    dragActiveIndex === index
                      ? 'border-primary-400 bg-primary-100'
                      : 'border-gray-300'
                  } border-dashed rounded-lg cursor-pointer bg-gray-50`}
                >
                  <div className="flex flex-col items-center justify-center pt-5 pb-6">
                    <svg
                      className="w-8 h-8 mb-4 text-gray-500"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 20 16"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5A5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                      />
                    </svg>
                    <p className="mb-2 text-sm text-gray-500">
                      <span className="font-semibold">Click to upload</span> or drag and drop
                    </p>
                    <p className="text-xs text-gray-500">SVG, PNG, JPG or GIF (MAX. 800x400px)</p>
                  </div>
                  <input
                    id={`dropzone-file-${index}`}
                    type="file"
                    accept="image/*"
                    className="hidden"
                    onChange={(e) => handleImageChange(index, e.target.files[0])}
                  />
                </label>
              </div>
            ) : (
              <div className="mt-4 relative">
                <img src={imagePreviews[index]} alt="Preview" className="rounded-lg w-full" />
                <div className="absolute top-2 right-2 flex flex-col">
                  <button
                    onClick={() => handleRotate(index)}
                    className="mb-1 px-2 py-1 bg-primary-400 text-white rounded hover:bg-primary-600"
                  >
                    Rotate 90°
                  </button>
                  <button
                    onClick={() => handleFlipH(index)}
                    className="mb-1 px-2 py-1 bg-primary-400 text-white rounded hover:bg-primary-600"
                  >
                    Flip Horizontal
                  </button>
                  <button
                    onClick={() => handleFlipV(index)}
                    className="px-2 py-1 bg-primary-400 text-white rounded hover:bg-primary-600"
                  >
                    Flip Vertical
                  </button>
                </div>
              </div>
            )}
          </div>
        ))}
        <div className="mt-4 flex justify-end">
          <button
            onClick={onClose}
            className="mr-2 px-4 py-2 bg-gray-300 rounded hover:bg-gray-400"
          >
            Cancel
          </button>
          <button
            onClick={handleUpload}
            className="px-4 py-2 bg-primary-400 text-white rounded hover:bg-primary-600 cursor-pointer"
            disabled={uploading}
          >
            {uploading ? 'Uploading...' : 'Upload'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ImageUploadModal;
