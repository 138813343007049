// src/components/KanbanTodo.jsx

import React, { useEffect, useState } from 'react';
import { getFirestore, doc, getDoc, setDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import initialKanban from './initialKanban';
import Column from './Column';
import CircleLoader from '../circleloader/CircleLoader';

const KanbanTodo = () => {
  const [kanbanData, setKanbanData] = useState(initialKanban);
  const [loading, setLoading] = useState(true);
  const auth = getAuth();
  const db = getFirestore();

  useEffect(() => {
    const fetchKanbanData = async () => {
      const user = auth.currentUser;
      if (user) {
        const kanbanRef = doc(db, 'customers', user.uid, 'kanban', 'board');
        const kanbanSnap = await getDoc(kanbanRef);
        if (kanbanSnap.exists()) {
          setKanbanData(kanbanSnap.data());
        } else {
          // Initialize with default data
          await setDoc(kanbanRef, initialKanban);
          setKanbanData(initialKanban);
        }
      }
      setLoading(false);
    };

    fetchKanbanData();
  }, [auth, db]);

  const handleDragEnd = async (result) => {
    const { destination, source, draggableId, type } = result;

    if (!destination) return;

    // If the position hasn't changed
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    if (type === 'column') {
      const newColumnOrder = Array.from(kanbanData.columnOrder);
      newColumnOrder.splice(source.index, 1);
      newColumnOrder.splice(destination.index, 0, draggableId);

      const newKanbanData = {
        ...kanbanData,
        columnOrder: newColumnOrder,
      };

      setKanbanData(newKanbanData);
      await saveKanbanData(newKanbanData);
      return;
    }

    const startColumn = kanbanData.columns[source.droppableId];
    const finishColumn = kanbanData.columns[destination.droppableId];

    if (startColumn === finishColumn) {
      const newCards = Array.from(startColumn.cards);
      const [movedCard] = newCards.splice(source.index, 1);
      newCards.splice(destination.index, 0, movedCard);

      const newColumn = {
        ...startColumn,
        cards: newCards,
      };

      const newKanbanData = {
        ...kanbanData,
        columns: {
          ...kanbanData.columns,
          [newColumn.id]: newColumn,
        },
      };

      setKanbanData(newKanbanData);
      await saveKanbanData(newKanbanData);
    } else {
      const startCards = Array.from(startColumn.cards);
      const [movedCard] = startCards.splice(source.index, 1);
      const finishCards = Array.from(finishColumn.cards);
      finishCards.splice(destination.index, 0, movedCard);

      const newStartColumn = {
        ...startColumn,
        cards: startCards,
      };

      const newFinishColumn = {
        ...finishColumn,
        cards: finishCards,
      };

      const newKanbanData = {
        ...kanbanData,
        columns: {
          ...kanbanData.columns,
          [newStartColumn.id]: newStartColumn,
          [newFinishColumn.id]: newFinishColumn,
        },
      };

      setKanbanData(newKanbanData);
      await saveKanbanData(newKanbanData);
    }
  };

  const saveKanbanData = async (data) => {
    const user = auth.currentUser;
    if (user) {
      const kanbanRef = doc(db, 'customers', user.uid, 'kanban', 'board');
      await setDoc(kanbanRef, data);
    }
  };

  const addColumn = async () => {
    const newColumnId = `column-${Date.now()}`;
    const newColumn = {
      id: newColumnId,
      name: 'New Column',
      cards: [],
    };

    const newKanbanData = {
      ...kanbanData,
      columns: {
        ...kanbanData.columns,
        [newColumnId]: newColumn,
      },
      columnOrder: [...kanbanData.columnOrder, newColumnId],
    };

    setKanbanData(newKanbanData);
    await saveKanbanData(newKanbanData);
  };

  const renameColumn = async (columnId, newName) => {
    const updatedColumn = {
      ...kanbanData.columns[columnId],
      name: newName,
    };

    const newKanbanData = {
      ...kanbanData,
      columns: {
        ...kanbanData.columns,
        [columnId]: updatedColumn,
      },
    };

    setKanbanData(newKanbanData);
    await saveKanbanData(newKanbanData);
  };

  const deleteColumn = async (columnId) => {
    const newColumns = { ...kanbanData.columns };
    delete newColumns[columnId];

    const newColumnOrder = kanbanData.columnOrder.filter((id) => id !== columnId);

    const newKanbanData = {
      ...kanbanData,
      columns: newColumns,
      columnOrder: newColumnOrder,
    };

    setKanbanData(newKanbanData);
    await saveKanbanData(newKanbanData);
  };

  const addCard = async (columnId, content) => {
    const newCard = {
      id: `card-${Date.now()}`,
      content,
    };

    const newCards = [...kanbanData.columns[columnId].cards, newCard];
    const updatedColumn = {
      ...kanbanData.columns[columnId],
      cards: newCards,
    };

    const newKanbanData = {
      ...kanbanData,
      columns: {
        ...kanbanData.columns,
        [columnId]: updatedColumn,
      },
    };

    setKanbanData(newKanbanData);
    await saveKanbanData(newKanbanData);
  };

  const deleteCard = async (columnId, cardId) => {
    const newCards = kanbanData.columns[columnId].cards.filter(
      (card) => card.id !== cardId
    );
    const updatedColumn = {
      ...kanbanData.columns[columnId],
      cards: newCards,
    };

    const newKanbanData = {
      ...kanbanData,
      columns: {
        ...kanbanData.columns,
        [columnId]: updatedColumn,
      },
    };

    setKanbanData(newKanbanData);
    await saveKanbanData(newKanbanData);
  };

  if (loading) {
    return <div className="text-center"><CircleLoader /></div>;
  }

  return (
    <div className="px-8 min-h-screen">
      <div className="flex justify-end items-center mb-6">
        <button
          onClick={addColumn}
          className="px-4 py-2 bg-primary-100 text-gray-600 rounded-lg hover:bg-primary-400 hover:text-white"
        >
          + Add Column
        </button>
      </div>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable
          droppableId="all-columns"
          direction="horizontal"
          type="column"
        >
          {(provided) => (
            <div
              className="flex space-x-4 overflow-x-auto"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {kanbanData.columnOrder.map((columnId, index) => {
                const column = kanbanData.columns[columnId];
                return (
                  <Draggable
                    draggableId={columnId}
                    index={index}
                    key={columnId}
                  >
                    {(provided) => (
                      <div
                        className="flex-shrink-0 w-80"
                        {...provided.draggableProps}
                        ref={provided.innerRef}
                      >
                        <Column
                          column={column}
                          dragHandleProps={provided.dragHandleProps}
                          renameColumn={renameColumn}
                          deleteColumn={deleteColumn}
                          addCard={addCard}
                          deleteCard={deleteCard}
                        />
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default KanbanTodo;
