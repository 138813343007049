// src/components/OnboardingJoyride.jsx
import React, { useEffect } from 'react';
import Joyride, { STATUS, LIFECYCLE } from 'react-joyride';
import styled from '@emotion/styled';

// Styled Components for Tooltip (Optional Customization)
const TooltipContainer = styled.div`
  background-color: white;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  max-width: 350px;
  min-width: 250px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
`;

const TooltipHeader = styled.h3`
  color: #2d3748;
  margin: 0;
  padding: 16px;
  font-size: 16px;
  border-bottom: 1px solid #e2e8f0;
`;

const TooltipContent = styled.div`
  padding: 16px;
  font-size: 14px;
  color: #4a5568;
`;

const TooltipFooter = styled.div`
  display: flex;
  justify-content: flex-end; /* Align buttons to the right */
  padding: 8px 16px;
  background-color: #f7fafc;
  border-top: 1px solid #e2e8f0;
`;

const CustomButton = styled.button`
  background-color: #cd66ef;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  font-size: 12px;
  cursor: pointer;
  &:hover {
    background-color: #f7e9fe;
  }
`;

const LightGrayButton = styled.button`
  background-color: #e2e8f0;
  margin-right: 8px;
  color: #4a5568;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  font-size: 12px;
  cursor: pointer;
  &:hover {
    background-color: #cbd5e0;
  }
`;

// Custom Tooltip Component
const CustomTooltip = ({
  backProps,
  continuous,
  index,
  isLastStep,
  primaryProps,
  skipProps,
  tooltipProps,
  step,
  handleDoNotShowAgain,
}) => (
  <TooltipContainer {...tooltipProps}>
    {step.title && <TooltipHeader>{step.title}</TooltipHeader>}
    {step.content && <TooltipContent>{step.content}</TooltipContent>}
    <TooltipFooter>
      <div>
        <LightGrayButton
          onClick={skipProps.onClick}
          aria-label={skipProps['aria-label']}
        >
          Skip Tour
        </LightGrayButton>
        <CustomButton
          onClick={primaryProps.onClick}
          aria-label={primaryProps['aria-label']}
        >
          {isLastStep ? 'Finish' : 'Next'}
        </CustomButton>
      </div>
    </TooltipFooter>
  </TooltipContainer>
);


const OnboardingJoyride = ({
    run,
    steps,
    onComplete,
    onDoNotShowAgain,
  }) => {

    // Logging props for debugging
    useEffect(() => {
      console.log('OnboardingJoyride run:', run);
      console.log('OnboardingJoyride steps:', steps);
    }, [run, steps]);

    const handleJoyrideCallback = (data) => {
        const { status, step, index, lifecycle } = data;
        console.log('Joyride callback:', data);
    
        if (status === STATUS.RUNNING) {
          console.log(`Joyride is running. Step index: ${index}, Step content: ${step?.content}`);
        }
    
        // Check if it's the last step and finished
        const isLastStep = index === steps.length - 1 && status === STATUS.FINISHED;
        if (isLastStep) {
        // Invoke onComplete callback, which will open the sidebar
        onComplete();
        }

        // Check if a beacon lifecycle event is being triggered
        if (lifecycle === LIFECYCLE.BEACON) {
          console.warn('Beacon was rendered even though disableBeacon is true!');
        }
    
        // Log when the tour is completed or skipped
        const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];
        if (finishedStatuses.includes(status)) {
          console.log('Joyride complete or skipped.');
          onComplete();
        }
      };

    const handleDoNotShowAgainLocal = async () => {
      console.log('Do not show again clicked');
      if (onDoNotShowAgain) {
        await onDoNotShowAgain();
      }
    };

    return (
      <Joyride
        steps={steps}
        continuous
        showSkipButton
        showProgress
        run={run}
        disableBeacon={true}
        callback={handleJoyrideCallback}
        spotlightClicks={true}
        disableOverlayClose={true}
        styles={{
          options: {
            zIndex: 10000,
            // overlayColor: 'rgba(0, 0, 0, 0.5)',
            // primaryColor: '#cd66ef',
            // textColor: '#4a5568',
            // width: 350,
            // arrowColor: '#cd66ef',
          },
        }}
        tooltipComponent={(props) => (
          <CustomTooltip
            {...props}
            handleDoNotShowAgain={handleDoNotShowAgainLocal}
          />
        )}
      />
    );
  };

export default OnboardingJoyride;