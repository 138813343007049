// src/components/Column.jsx

import React, { useState } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import CardComponent from './Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX } from '@fortawesome/free-solid-svg-icons';

const Column = ({
  column,
  dragHandleProps,
  renameColumn,
  deleteColumn,
  addCard,
  deleteCard,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [newName, setNewName] = useState(column.name);
  const [newCardContent, setNewCardContent] = useState('');

  const handleRename = () => {
    if (newName.trim() === '') {
      alert('Column name cannot be empty.');
      return;
    }
    renameColumn(column.id, newName);
    setIsEditing(false);
  };

  const handleDelete = () => {
    const confirmDelete = window.confirm(
      `Are you sure you want to delete the "${column.name}" column? This action cannot be undone.`
    );
    if (confirmDelete) {
      deleteColumn(column.id);
    }
  };

  const handleAddCard = () => {
    if (newCardContent.trim() !== '') {
      addCard(column.id, newCardContent);
      setNewCardContent('');
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleAddCard();
    }
  };

  return (
    <div className="bg-primary-50 rounded-lg shadow-md p-4 flex flex-col h-full min-w-[300px]">
      {/* Column Header */}
      <div className="flex justify-between items-center mb-4" {...dragHandleProps}>
        {isEditing ? (
          // Edit Mode
          <div className="flex flex-col sm:flex-row items-start sm:items-center w-full space-y-2 sm:space-y-0 sm:space-x-2">
            <input
              type="text"
              value={newName}
              onChange={(e) => setNewName(e.target.value)}
              className="border border-gray-300 rounded px-2 py-1 flex-1 w-1/5"
              placeholder="Enter column name"
            />
            <div className="flex">
              <button
                onClick={handleRename}
                className="px-2 py-2 text-xs text-primary-400 bg-white border-s border-t border-b border-gray-200 rounded-s-lg hover:bg-primary-400 hover:text-white"
              >
                Save
              </button>
              <button
                onClick={() => {
                  setIsEditing(false);
                  setNewName(column.name); // Reset to original name on cancel
                }}
                className="px-2 py-2 text-xs text-primary-400 bg-white border border-gray-200 rounded-e-lg hover:bg-primary-400 hover:text-white"
              >
                Cancel
              </button>
            </div>
          </div>
        ) : (
          // Display Mode
          <div className="flex justify-between items-center w-full">
            <h3
              className="text-sm text-primary-400 cursor-pointer"
              onClick={() => setIsEditing(true)}
            >
              {column.name}
            </h3>
            <div className="flex space-x-2">
              <button
                onClick={handleDelete}
                className="px-2 py-1 text-gray-600 rounded-lg hover:bg-red-500 hover:text-white text-sm"
              >
                <FontAwesomeIcon icon={faX} />
              </button>
            </div>
          </div>
        )}
      </div>

      {/* Cards List */}
      <Droppable droppableId={column.id} type="card">
        {(provided, snapshot) => (
          <div
            className={`flex-1 p-2 rounded ${
              snapshot.isDraggingOver ? 'bg-primary-200' : 'bg-primary-50'
            } overflow-y-auto`}
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {column.cards.map((card, index) => (
              <CardComponent
                key={card.id}
                card={card}
                index={index}
                columnId={column.id}
                deleteCard={deleteCard}
              />
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>

      {/* Add Card Section */}
      <div className="mt-4 flex flex-col space-y-2">
        <input
          type="text"
          value={newCardContent}
          onChange={(e) => setNewCardContent(e.target.value)}
          onKeyDown={handleKeyDown}
          placeholder="Add a card..."
          className="w-full border border-gray-300 rounded px-2 py-1"
        />
        <button
          onClick={handleAddCard}
          className="w-full bg-primary-400 text-white px-4 py-2 rounded-lg hover:bg-primary-600"
        >
          Add Card
        </button>
      </div>
    </div>
  );
};

export default Column;
