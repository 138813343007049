import React, { useState, useEffect } from 'react';
import { ReactComponent as UrqToothSvg } from './svgs/urqtooth.svg';
import { ReactComponent as UlqToothSvg } from './svgs/ulqtooth.svg';
import { ReactComponent as LlqToothSvg } from './svgs/llqtooth.svg';
import { ReactComponent as LrqToothSvg } from './svgs/lrqtooth.svg';
import { defaultTeethData } from './defaultTeethData';
import { segmentColors } from './conditionColors';
import { firestore, auth } from '../../firebase';
import { doc, updateDoc } from 'firebase/firestore';

const DentalChart = ({ data = [], noteId, isTreatmentPlan, setTeethData, setTreatmentplanData }) => {
  const [colors, setColors] = useState({});
  const [dataToRender, setDataToRender] = useState(defaultTeethData);
  const [selectedColor, setSelectedColor] = useState(segmentColors.default);

  const getColorName = (colorValue) => {
    return Object.keys(segmentColors).find(key => segmentColors[key].toLowerCase() === colorValue.toLowerCase()) || 'default';
  };

  useEffect(() => {
    const mergeTeethDataWithDefaults = (teethData) => {
      return defaultTeethData.map(tooth => ({
        ...tooth,
        segments: { ...tooth.segments, ...teethData.find(tdTooth => tdTooth.number === tooth.number)?.segments }
      }));
    };

    const initialData = mergeTeethDataWithDefaults(data);
    setDataToRender(initialData);

    const initialColors = {};
    initialData.forEach(tooth => {
      initialColors[tooth.number] = {
        mesial: tooth.segments.mesial ? (segmentColors[tooth.segments.mesial] || segmentColors.default) : segmentColors.default,
        occlusal: tooth.segments.occlusal ? (segmentColors[tooth.segments.occlusal] || segmentColors.default) : segmentColors.default,
        distal: tooth.segments.distal ? (segmentColors[tooth.segments.distal] || segmentColors.default) : segmentColors.default,
        buccal: tooth.segments.buccal ? (segmentColors[tooth.segments.buccal] || segmentColors.default) : segmentColors.default,
        lingual: tooth.segments.lingual ? (segmentColors[tooth.segments.lingual] || segmentColors.default) : segmentColors.default,
      };
    });
    setColors(initialColors);
  }, [data]);

  const handleSegmentClick = async (toothNumber, segment) => {
    const currentColor = colors[toothNumber][segment];
    const selectedColorName = getColorName(selectedColor);
    let newColor;

    if (currentColor === selectedColor) {
      newColor = segmentColors.default;
    } else if (
      currentColor !== segmentColors.default &&
      currentColor !== segmentColors.present &&
      currentColor !== segmentColors.missing
    ) {
      newColor = `linear-gradient(45deg, ${currentColor} 50%, ${selectedColor} 50%)`;
    } else {
      newColor = selectedColor;
    }

    const updatedColors = {
      ...colors,
      [toothNumber]: {
        ...colors[toothNumber],
        [segment]: newColor,
      },
    };
    setColors(updatedColors);

    const updatedData = dataToRender.map((tooth) =>
      tooth.number === toothNumber
        ? {
            ...tooth,
            segments: {
              ...tooth.segments,
              [segment]: newColor === segmentColors.default ? '' : selectedColorName,
            },
          }
        : tooth
    );

    setDataToRender(updatedData);

    if (noteId) {
      try {
        const noteRef = doc(firestore, 'customers', auth.currentUser.uid, 'notes', noteId);
        if (isTreatmentPlan) {
          await updateDoc(noteRef, { treatmentPlanData: updatedData });
          setTreatmentplanData(updatedData);
        } else {
          await updateDoc(noteRef, { teethData: updatedData });
          setTeethData(updatedData);
        }
      } catch (error) {
        console.error("Error updating Firestore document:", error);
      }
    }
  };

  const handleColorKeyClick = (color) => {
    setSelectedColor(color);
  };

  const renderTopRowTeeth = (teeth) => {
    return teeth.map((tooth, index) => (
      <div key={index} className="relative text-center bg-white p-0.5 cursor-pointer">
        {index < 8 ? (
          <UrqToothSvg
            className="w-full h-full segment-hover"
            style={{
              '--occlusal-color': colors[tooth.number]?.occlusal,
              '--mesial-color': colors[tooth.number]?.mesial,
              '--distal-color': colors[tooth.number]?.distal,
              '--buccal-color': colors[tooth.number]?.buccal,
              '--lingual-color': colors[tooth.number]?.lingual,
            }}
            onClick={(e) => {
              const segment = e.target.classList[0];
              handleSegmentClick(tooth.number, segment);
            }}
          />
        ) : (
          <UlqToothSvg
            className="w-full h-full segment-hover"
            style={{
              '--occlusal-color': colors[tooth.number]?.occlusal,
              '--mesial-color': colors[tooth.number]?.mesial,
              '--distal-color': colors[tooth.number]?.distal,
              '--buccal-color': colors[tooth.number]?.buccal,
              '--lingual-color': colors[tooth.number]?.lingual,
            }}
            onClick={(e) => {
              const segment = e.target.classList[0];
              handleSegmentClick(tooth.number, segment);
            }}
          />
        )}
      </div>
    ));
  };

  const renderBottomRowTeeth = (teeth) => {
    return teeth.map((tooth, index) => (
      <div key={index} className="relative text-center bg-white p-0.5 cursor-pointer">
        {index < 8 ? (
          <LlqToothSvg
            className="w-full h-full segment-hover"
            style={{
              '--occlusal-color': colors[tooth.number]?.occlusal,
              '--mesial-color': colors[tooth.number]?.mesial,
              '--distal-color': colors[tooth.number]?.distal,
              '--buccal-color': colors[tooth.number]?.buccal,
              '--lingual-color': colors[tooth.number]?.lingual,
            }}
            onClick={(e) => {
              const segment = e.target.classList[0];
              handleSegmentClick(tooth.number, segment);
            }}
          />
        ) : (
          <LrqToothSvg
            className="w-full h-full segment-hover"
            style={{
              '--occlusal-color': colors[tooth.number]?.occlusal,
              '--mesial-color': colors[tooth.number]?.mesial,
              '--distal-color': colors[tooth.number]?.distal,
              '--buccal-color': colors[tooth.number]?.buccal,
              '--lingual-color': colors[tooth.number]?.lingual,
            }}
            onClick={(e) => {
              const segment = e.target.classList[0];
              handleSegmentClick(tooth.number, segment);
            }}
          />
        )}
      </div>
    ));
  };

  const renderColorKey = () => {
    const formatKey = (key) => {
      return key.replace(/([a-z0-9])([A-Z])/g, '$1 $2').replace(/^./, str => str.toUpperCase());
    };

    return (
      <div className="mb-4">
        <div className="grid grid-rows-6 grid-flow-col gap-0">
          {Object.entries(segmentColors).map(([key, color]) => (
            <div
              key={key}
              className={`flex items-center cursor-pointer p-0.5 w-4/5 ${selectedColor === color ? 'font-bold bg-primary-100 rounded-lg' : 'hover:bg-primary-50 rounded-lg'}`}
              onClick={() => handleColorKeyClick(color)}
            >
              <div className="w-6 h-6 mr-2 rounded-full" style={{ backgroundColor: color }}></div>
              <span className="text-xs font-medium">
                {formatKey(key)}
              </span>
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className="w-full mt-8">
      {renderColorKey()}
      <div className="grid w-full gap-0 grid-cols-16 justify-center mb-2 min-h-20">
        {renderTopRowTeeth(dataToRender.slice(0, 16))}
      </div>
      <div className="grid w-full gap-0 grid-cols-16 justify-center min-h-20">
        {renderBottomRowTeeth(dataToRender.slice(16).reverse())}
      </div>
    </div>
  );
};

export default DentalChart;