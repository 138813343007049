import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { doc, updateDoc, getDoc } from 'firebase/firestore';
import { firestore, auth } from '../../firebase';
import PatientProfileNav from './PatientProfileNav';
import './PatientNotesInput.css'; 

const CariesRisk = () => {
  const navigate = useNavigate();
  const { patientId } = useParams();
  const [cariesRisk, setCariesRisk] = useState('');

  useEffect(() => {
    const patientRef = doc(firestore, 'customers', auth.currentUser.uid, 'patients', patientId);

    const fetchCariesRisk = async () => {
      const patientDoc = await getDoc(patientRef);
      if (patientDoc.exists() && patientDoc.data().notes.cariesRisk) {
        setCariesRisk(patientDoc.data().notes.cariesRisk); // Set the caries risk data to the local state
      }
    };

    fetchCariesRisk();
  }, [patientId]);

  const handleCariesRiskClick = async (value) => {
    const patientRef = doc(firestore, 'customers', auth.currentUser.uid, 'patients', patientId);

    await updateDoc(patientRef, {
      'notes.cariesRisk': value,
    });

    console.log("Updated Patient Data:", { cariesRisk: value });

    navigate(`/mvp/patient-profile/${patientId}/special-tests`);
  };

  return (
    <div className="patient-complaints-container">
      <PatientProfileNav patientId={patientId} />
      <h2 className="patient-complaints-h2">Caries Risk</h2>
      <div className="patient-complaints-button-group">
        <button onClick={() => handleCariesRiskClick('High')} className={`patient-complaints-button ${cariesRisk === 'High' ? 'selected' : ''}`}>High</button>
        <button onClick={() => handleCariesRiskClick('Moderate')} className={`patient-complaints-button ${cariesRisk === 'Moderate' ? 'selected' : ''}`}>Moderate</button>
        <button onClick={() => handleCariesRiskClick('Low')} className={`patient-complaints-button ${cariesRisk === 'Low' ? 'selected' : ''}`}>Low</button>
      </div>
    </div>
  );
};

export default CariesRisk;
