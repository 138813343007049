import React, { useState, useEffect } from 'react';
import { signInWithEmailAndPassword, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { useNavigate, Link } from 'react-router-dom';
import { auth, firestore } from '../firebase';  // adjust path if needed
import { doc, setDoc, getDoc, collection, getDocs, query, where, writeBatch, addDoc } from 'firebase/firestore';
import './SignUp.css';
import { useSession } from './SessionContext';
import { FcGoogle } from 'react-icons/fc';


const LoginForMultiples = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const { setSessionId } = useSession();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setShowModal(true);
  }, []);
  
  const handleCloseModal = () => {
    setShowModal(false);
  };
  


  const handleGoogleLogin = async () => {
    const googleProvider = new GoogleAuthProvider();
    try {
      const userCredential = await signInWithPopup(auth, googleProvider);
      const user = userCredential.user;

      const userRef = doc(firestore, 'customers', user.uid);
      const userSnapshot = await getDoc(userRef);

      // If the user doesn't exist in Firestore, then create the user document
      if (!userSnapshot.exists()) {
        await setDoc(userRef, {
          email: user.email,
        });
        navigate('/payment');  // If they're a new user, navigate them to the payment page.
        return;
      }
 
      await invalidatePreviousSessions(user.uid);
      await createNewSession(user.uid);
      navigate('/mvp');
    } catch (err) {
      setError('Failed to log in with Google. Please try again.');
      console.error("Google login error:", err);
    }
  };

  const invalidatePreviousSessions = async (userId) => {
    try {
      const sessionsRef = collection(firestore, 'sessions');
      const q = query(sessionsRef, where('userId', '==', userId));
      const activeSessions = await getDocs(q);
  
      const batch = writeBatch(firestore);
      activeSessions.forEach((docSnapshot) => {
        console.log(`Deleting session: ${docSnapshot.id}`);
        batch.delete(docSnapshot.ref);
      });
      await batch.commit();
      console.log("All previous sessions invalidated successfully.");
    } catch (err) {
      console.error("Error invalidating sessions:", err);
      throw err; // Rethrow the error for higher-level handling
    }
  };
  
  

  const generateSessionId = () => {
    // Generate a random number and convert it to a base-36 string (numbers + letters), then slice off the first 2 chars (which are '0.')
    const randomPart = Math.random().toString(36).substring(2, 15);
  
    // Use the current timestamp
    const timePart = new Date().getTime().toString(36);
  
    // Combine both parts to get a unique session ID
    return `${randomPart}${timePart}`;
  };
  

  const createNewSession = async (userId) => {
    try {
      const sessionId = generateSessionId();
      const userAgent = navigator.userAgent;
      const browser = navigator.appName;
      const os = navigator.platform;
      const sessionsRef = collection(firestore, 'sessions');
  
      // Capture the DocumentReference returned by addDoc
      const sessionDocRef = await addDoc(sessionsRef, {
        userId: userId,
        sessionId: sessionId,
        valid: true,
        timestamp: new Date(),
        deviceInfo: { userAgent, browser, os }
      });
  
      // Now you can use sessionDocRef.id
      setSessionId(sessionDocRef.id);
    } catch (err) {
      console.error("Error creating new session:", err);
      throw err; // Rethrow the error for higher-level handling
    }
  };
  

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
        await signInWithEmailAndPassword(auth, email, password);
          
        // Check the user's role after successful login
        const user = auth.currentUser;
        // Invalidate previous sessions
        await invalidatePreviousSessions(user.uid);
    
        // Create a new session record
        await createNewSession(user.uid);
        
        user.getIdTokenResult().then(idTokenResult => {
            if (idTokenResult.claims.role === 'reception') {
                navigate('/reception');  // Navigate to the reception page
            } else {
                navigate('/mvp');  // Navigate to the default page for other users
            }
        });
    } catch (err) {
        setError('Failed to log in. Please check your email and password.');
        console.error("Login error:", err);
    }
};

  return (
<section className="flex items-center justify-center min-h-screen bg-gradient-to-br from-primary-50 to-primary-300">

{showModal && (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
    <div className="bg-white rounded-lg shadow-lg p-8 pb-12 w-full max-w-lg mx-auto">
      <div className="flex flex-col items-center text-center space-y-6">
        <h1 className="text-2xl font-semibold">Session Terminated</h1>
        <p className="text-gray-700">
          For security reasons, your session has been terminated. This could be due to one of the following reasons:
        </p>
        <ul className="list-disc list-inside text-gray-700 text-left font-bold">
          <li>Multiple tabs opened with the same account</li>
          <li>Another person logged into your account</li>
          <li>Account not registered</li>
        </ul>
        <p className="text-gray-700">
          To comply with GDPR and ensure a secure experience, each user must have an individual account. We are working on supporting multiple tabs for Jazmode and Platypus. Thank you for your patience.
        </p>
        <p className="text-gray-700 font-bold">
          Please sign back in or, if you don't have an account, sign up and register.
        </p>
        <p className="text-gray-700">
          If this issue caused you to lose a Jazmode recording, please get in touch with us, providing the time this occurred. We will do our best to recover it for you.
        </p>
        <div className="flex space-x-2">
          <a
            href={`mailto:kevin@digitaltco.co.uk?subject=Lost Jazmode Recording&body=I experienced a session termination at [TIME]. Please help me recover my recording.`}
            className="bg-white border-2 border-primary-200 text-primary-200 py-2 px-4 rounded-lg shadow hover:bg-primary-400 hover:text-white transition duration-300"
          >
            Email Kevin
          </a>
          <button
            onClick={handleCloseModal}
            className="bg-primary-400 text-white py-2 px-4 rounded-lg shadow hover:bg-gray-700 transition duration-300"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
)}





<main className="flex flex-col items-center justify-center w-full max-w-md p-8 bg-white rounded-xl shadow-lg">
      
          <h1 className="mt-6 mb-16 text-4xl font-bold text-primary-400 tracking-tighter sm:text-5xl md:text-6xl text-center">
            DigitalTCO
          </h1>
        <form className="mx-auto w-full" onSubmit={handleSubmit}>
                {/* Sign Up With Google Button */}
                <div class="flex items-center justify-center mx-auto pb-8 border-b border-primary-300">
                  <button type="button"
                          class="flex items-center justify-center mx-auto p-2 bg-white text-primary-400 shadow-sm border-primary-400 border rounded-xl w-full hover:bg-gradient-to-r hover:from-primary-200 hover:to-primary-300 hover:text-white"
                          onClick={handleGoogleLogin}>
                    <FcGoogle className="text-xl mr-2"/>
                    Log in with Google
                  </button>
                </div>

         
            <input
              type="email"
              id="email"
              className="mb-2 mt-8 bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
              placeholder="Enter your email"                    
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              onBlur={(e) => setEmail(e.target.value)}
            />
       
            <input
              type="password"
              id="password"
              className="mb-8 mt-2 bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
              placeholder="Enter your password"                    
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              onBlur={(e) => setPassword(e.target.value)}
            />

          <button className="bg-primary-400 text-white p-2 rounded-xl w-full mx-auto hover:bg-white hover:border hover:border-primary-400 hover:text-primary-400" type="submit">Log In</button>
        </form>
        <div className="signup-button-container">
          {error && <p>{error}</p>}
          <Link to="/signup" className="signup-link">Not registered? Sign Up!</Link>
          <Link to="/forgot-password" className="signup-link">Forgotten Password?</Link>
        </div>
      
      </main>
      </section>
  );
 
};

export default LoginForMultiples;
