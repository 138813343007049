import React, { useState, useEffect } from 'react';
import LoadingPopup from '../patientnotes/LoadingPopup';
import CopyButton from '../CopyButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { doc, setDoc } from 'firebase/firestore';
import { firestore, auth } from '../../firebase';

const TemplateFixer = () => {
  const [inputText, setInputText] = useState('');
  const [fixedTemplate, setFixedTemplate] = useState('');
  const [isFetching, setIsFetching] = useState(false);
  const [showLoadingPopup, setShowLoadingPopup] = useState(false);
  const [showModal, setShowModal] = useState(true); // State to control modal visibility

  const handleInputChange = (e) => {
    setInputText(e.target.value);
  };

  const handleSendClick = async () => {
    if (inputText.trim() === '') return;

    setIsFetching(true);
    setShowLoadingPopup(true);

    try {
      const response = await fetch('https://us-central1-digitaltco-c40e9.cloudfunctions.net/templateFixer', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ prompt: inputText }),
      });

      const result = await response.json();
      setFixedTemplate(result.text);
    } catch (error) {
      console.error('Error fetching fixed template:', error);
    } finally {
      setIsFetching(false);
      setShowLoadingPopup(false);
    }
  };

  const removeDoubleAsterisks = (text) => {
    let formattedText = text.replace(/\*\*(.*?)\*\*/g, '$1'); // Remove **text**
    formattedText = formattedText.replace(/###/g, '');
    formattedText = formattedText.replace(/##/g, ''); // Remove ##
    formattedText = formattedText.replace(/\*\*/g, '');
    formattedText = formattedText.replace(/【\d+†.*?】/g, '');
    return formattedText;
  };

  const addBasicExamTemplate = async () => {
    try {
      const user = auth.currentUser;

      if (!user) {
        alert('No user is currently logged in.');
        return;
      }

      const uid = user.uid;

      const examTemplateRef = doc(firestore, 'customers', uid, 'templates', 'Basic Exam');

      const basicExamTemplate = `
EXAMINATION

Visit Completed: Yes  
Medical History Updated: Yes

COMPLAINTS
Complaints: None  

Medical History: Checked and updated, nil changes noted.  
Dental History: Regular attender, no issues reported.  
Social History: [Job, pets, holidays, stress in here]  
Smoking: None  
Alcohol: None  

CLINICAL EXAMINATION  
Extraoral Examination -
TMJ: NAD (No abnormality detected). No clicking, popping, or locking history.  
Face: NAD  
Neck: NAD  
Lips: NAD  
Skin: NAD  
Lymph Nodes: NAD  
Salivary Glands: NAD  
Swelling: NAD  
Deviation: NAD  

Intraoral Examination -
Soft Tissues: NAD  
Buccal Mucosa: NAD  
Floor of Mouth: NAD  
Tongue: NAD  
Hard Palate: NAD  
Soft Palate: NAD  
Fauces/Pharynx: NAD  

PERIODONTAL EXAMINATION
BPE: 000/000  
Periodontal Condition: Healthy  
Periodontal Diagnosis: None  
Comment on Periodontal Condition: Stable  
Oral Hygiene: Good  

OCCLUSION AND WEAR 
Wear Facets: None  
Basic Erosive Wear Examination (BEWE): 000/000  
Occlusion: No issues in static and dynamic  
Crossbites: None  
Anterior Open Bite: None  
Overbite: Normal  
Overjet: Normal  

Canine relationship right side: Class I
Molar Relationship right side: Class I
Canine relationship left side: Class I
Molar Relationship left side: Class I
Incisor Relationship: Class I

RADIOGRAPHIC EXAMINATION
Radiographs: None indicated today  
Radiograph Report: N/A  
Radiograph Exposure Settings: N/A  
Maximum Bone Loss: 0%  

RISK ASSESSMENT
Caries Risk: Low  
Periodontal Risk: Low  
Oral Cancer Risk: Low  
Tooth Fracture Risk: Low  

CLINICAL OBSERVATIONS 
Observations: None  

DIAGNOSIS AND TREATMENT PLAN
Diagnoses: None  
Recommended Treatment: None  
Alternatives: None  

DISCUSSION AND RISKS 
Discussion: Nil  
Risks: None  
Benefits: None  
Discussion of Fees: N/A  
Further Discussion: Nil

TREATMENT AND PREVENTATIVE PLAN
Treatment Today: None  
Preventative Plan: Regular hygiene visits, fluoride application 1x/year  

Next Visit: In 6 months for a routine examination
      `;

      await setDoc(examTemplateRef, { text: basicExamTemplate });

      alert("Basic exam template added to your account successfully.");
      setShowModal(false); // Close the modal after adding the template
    } catch (error) {
      console.error('Error adding template:', error);
      alert('An error occurred while adding the template.');
    }
  };

  return (
    <main className="flex flex-col items-center tracking-tight w-4/5 mx-auto">
      {showLoadingPopup && <LoadingPopup />}
      
      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="relative p-8 w-full max-w-2xl bg-white rounded-lg shadow max-h-[90vh] overflow-auto tracking-tighter text-sm">
            <h1 className="text-3xl font-bold mb-4 text-center">🔧 Template Fixer 🔧</h1>
            <p>
              This tool helps you fine-tune your templates. But before you dive in, we highly recommend tweaking the "Basic Exam" template that comes with DigitalTCO—it's been carefully crafted and proven to deliver outstanding results.
            </p>
            <p className="mt-4">
              For a deeper understanding of how templates work in DigitalTCO, <b>check out Video #4 in the Knowledge Vault</b>. It’s packed with insights that will enhance your experience.
            </p>
            <p className="mt-4 font-bold">
              Give it a shot! Click below to add the recommended template and see the difference for yourself.
            </p>

            <div className="flex flex-col justify-center mt-4 space-y-4 w-1/2 mx-auto">
              <button
                onClick={addBasicExamTemplate}
                className="px-4 py-2 text-xs text-white bg-green-500 rounded-lg hover:bg-green-600"
              >
                Add Recommended Basic Exam Template
              </button>
              <button
                onClick={() => setShowModal(false)}
                className="px-4 py-2 text-xs text-gray-500 bg-gray-300 rounded-lg border border-gray-200 hover:bg-primary-400 hover:text-white"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}

      <div className="mt-4 grid grid-cols-1 gap-6 sm:grid-cols-2 w-full">
        {/* LEFT INPUT COLUMN */}
        <div className="flex flex-col">
          <label className="block mb-2 text-xs text-primary-400">Template Input</label>
          <textarea
            placeholder="Paste your template here..."
            value={inputText}
            onChange={handleInputChange}
            className="block overflow-y-auto p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300"
            rows="20"
          ></textarea>

          <div className="mx-auto mt-2">
            <button
              onClick={handleSendClick}
              disabled={isFetching}
              className={`${isFetching ? 'px-4 py-2 text-xl font-medium text-gray-500 bg-gray-300 border border-gray-200 rounded-lg cursor-not-allowed' : 'px-4 py-2 text-xl font-medium text-primary-400 bg-white border border-gray-200 rounded-lg hover:bg-primary-400 hover:text-white'}`}
            >
              <FontAwesomeIcon icon={faPaperPlane} />
            </button>
          </div>
        </div>

        {/* RIGHT OUTPUT COLUMN */}
        <div className="flex flex-col">
          <label className="block mb-2 text-xs text-primary-400">Fixed Template Output</label>
          <textarea
            value={removeDoubleAsterisks(fixedTemplate)}
            placeholder="The fixed template will appear here..."
            readOnly
            className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300"
            rows="20"
          />

          <div className="flex flex-col items-center justify-center mx-auto mt-2">
            {fixedTemplate && <CopyButton textToCopy={fixedTemplate} />}
          </div>
        </div>
      </div>
    </main>
  );
};

export default TemplateFixer;
