import React, { useState } from 'react';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../firebase'; // adjust path if needed
import { Link } from 'react-router-dom';
import './ForgottenPassword.css'; // Create this CSS file for styling if needed

const ForgottenPassword = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await sendPasswordResetEmail(auth, email);
      setMessage('Password reset link sent! Check your email.');
      setError('');
    } catch (err) {
      setError('Failed to send password reset email. Please try again.');
      setMessage('');
      console.error('Error sending password reset email:', err);
    }
  };

  return (
<section className="flex items-center justify-center min-h-screen bg-gradient-to-br from-primary-50 to-primary-300">
<main className="flex flex-col items-center justify-center w-full max-w-md p-8 bg-white rounded-xl shadow-lg">
          <h1 className="mt-6 mb-4 text-xl font-bold text-primary-400 tracking-tighter sm:text-2xl md:text-3xl text-center">
            Reset Password
          </h1>
        <form className="mx-auto w-full" onSubmit={handleSubmit}>
            <input
              type="email"
              id="email"
              className="mb-2 mt-8 bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
              value={email}
              placeholder='Enter your email'
              onChange={(e) => setEmail(e.target.value)}
            />
          <button className="bg-primary-400 text-white p-2 rounded-xl w-full mx-auto hover:bg-white hover:border hover:border-primary-400 hover:text-primary-400 mt-2" type="submit">Send Reset Link</button>
        </form>
            {message && (
                <>
                <p className="success-message">
                {message} 
                </p>
                <p className="success-message">
                <Link to="/login" className="back-to-login-link">Back to Login</Link>
                </p>
                </>
            )}
            {error && <p className="error-message">{error}</p>}
        </main>
    </section>
  );
};

export default ForgottenPassword;
