// src/components/onboardingsidebar/OnboardingContext.js
import React, { createContext, useState } from 'react';

export const OnboardingContext = createContext({
  onboardingStepKey: null,
  setOnboardingStepKey: () => {},
});

export const OnboardingProvider = ({ children }) => {
  const [onboardingStepKey, setOnboardingStepKey] = useState(null);

  return (
    <OnboardingContext.Provider value={{ onboardingStepKey, setOnboardingStepKey }}>
      {children}
    </OnboardingContext.Provider>
  );
};
