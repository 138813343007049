import React from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SidebarLink = ({ to, icon, label }) => (
  <li>
    <NavLink
      to={to}
      className={({ isActive }) =>
        isActive
          ? 'flex items-center p-2 text-sm font-normal text-gray-800 rounded-lg bg-primary-100 group'
          : 'flex items-center p-2 text-sm font-normal text-gray-400 rounded-lg hover:text-gray-800 hover:bg-primary-100 group'
      }
    >
      {({ isActive }) => (
        <>
          <FontAwesomeIcon
            icon={icon}
            className={`text-sm flex-shrink-0 w-6 h-6 ${
              isActive ? 'text-primary-400' : 'text-primary-300 group-hover:text-primary-400 transition duration-75'
            }`}
          />
          <span className="flex-1 ml-3">{label}</span>
        </>
      )}
    </NavLink>
  </li>
);

export default SidebarLink;
