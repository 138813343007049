// FullNote.js
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { firestore, auth } from '../../firebase';
import PatientProfileNav from './PatientProfileNav';
import { generatePatientPDF } from './PDFConsentGen';
import './PatientNotesInput.css'; 
import CopyButton from '../CopyButton';

const FullNote = () => {
  const { patientId } = useParams();
  const [fullNote, setFullNote] = useState('');
  const [isGenerating, setIsGenerating] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchFullNote = async () => {
      const patientRef = doc(firestore, 'customers', auth.currentUser.uid, 'patients', patientId);
      const docSnap = await getDoc(patientRef);

      if (docSnap.exists()) {
        const rawNote = docSnap.data().notes.fullNote || '';
        const processedNote = rawNote.replace(/\*\*(.*?)\*\*/g, '$1'); // Remove ** characters
        setFullNote(processedNote);
      }
    };

    fetchFullNote();
  }, [patientId]);

  const handleSave = async () => {
    const patientRef = doc(firestore, 'customers', auth.currentUser.uid, 'patients', patientId);
    await updateDoc(patientRef, {
      'notes.fullNote': fullNote
    });

    alert('Note saved successfully!');
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(fullNote);
    alert('Note copied to clipboard!');
  };

  const handleGeneratePDF = async () => {
    setIsGenerating(true);
    await generatePatientPDF(patientId);
    setIsGenerating(false);
  };

  return (
    <div className="patient-complaints-container">
    <PatientProfileNav patientId={patientId} />
    <h2 className="patient-complaints-h2">Full Note</h2>
      <div className="patient-complaints-button-group">
        <button 
            onClick={handleGeneratePDF} 
            disabled={isGenerating} 
            className={`patient-complaints-button ${isGenerating ? 'patient-complaints-button-generating' : ''}`}
        >
            {isGenerating ? "Downloading..." : "Download Consent"}
        </button>
      </div>
      <textarea
        className="full-note-textarea"
        placeholder="Full note will appear here..."
        value={fullNote}
        onChange={(e) => setFullNote(e.target.value)}
        autoFocus
      />
      <div className="patient-complaints-button-group">
        <button onClick={handleSave} className="patient-complaints-button">Save</button>
        <CopyButton textToCopy={fullNote} />
      </div>
    </div>
  );
};

export default FullNote;
