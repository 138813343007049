import React, { useEffect, useState, useRef } from 'react';
import './Profile.css';
import { auth, firestore } from '../firebase';
import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { reauthenticateWithCredential, EmailAuthProvider, updatePassword } from 'firebase/auth';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faApple, faWindows, faChrome } from '@fortawesome/free-brands-svg-icons';
import OnboardingJoyride from './onboardingsidebar/OnboardingJoyride';
import OnboardingSidebar from './onboardingsidebar/OnboardingSidebar';

const Profile = ({ openSidebar }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [tempUserData, setTempUserData] = useState({
    name: '',
    practiceName: '',
    practiceAddress: '',
    practicePostcode: '',
    practiceEmail: '',
    practicePhoneNumber: '',
    gdcNumber: ''
  });
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [emailPreferencesVisible, setEmailPreferencesVisible] = useState(false);
  const [marketingConsent, setMarketingConsent] = useState(false);
  const [emailPreferencesButtonLabel, setEmailPreferencesButtonLabel] = useState('Change Email Preferences');
  const [imageFile, setImageFile] = useState(null);
  const [letterheadURL, setLetterheadURL] = useState('');
  const [practices, setPractices] = useState([]);

  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [defaultMic, setDefaultMic] = useState('');
  const [chartingMethod, setChartingMethod] = useState('FDI'); // Default charting method
  const [languagePreference, setLanguagePreference] = useState('EN'); // Default language preference
  const [saveAudioRecordings, setSaveAudioRecordings] = useState(true);


  const [joyrideRun, setJoyrideRun] = useState(false);
  const [joyrideSteps, setJoyrideSteps] = useState([]);
  const [joyrideInitialized, setJoyrideInitialized] = useState(false);
  const [joyrideKey, setJoyrideKey] = useState(0); // Define the state at the top with other state variables


   // Refs for target elements
   const micRef = useRef(null);
   const chartingRef = useRef(null);
   const audioSavingRef = useRef(null);

  // === New Handler Functions for Onboarding Joyride ===

  // Handle Joyride completion or skipping
  const handleJoyrideComplete = () => {
    console.log('handleJoyrideComplete called');

    setJoyrideRun(false);
    console.log('Joyride run state set to false');

    if (openSidebar) {
      console.log('openSidebar function is defined, opening sidebar...');
      openSidebar(); // Set isSidebarOpen to true
    } else {
      console.warn('openSidebar function is not defined');
    }
  };
  

  // Handle "Do not show again" action
  const handleDoNotShowAgain = async () => {
    setJoyrideRun(false);
    const user = auth.currentUser;
    if (user) {
      const onboardingRef = doc(firestore, 'customers', user.uid, 'onboarding', 'progress');
      await updateDoc(onboardingRef, { showProfileJoyride: false });
    }
  };

  useEffect(() => {
    if (
      userData &&
      !joyrideInitialized &&
      location.state &&
      location.state.fromOnboarding &&
      location.state.stepKey
    ) {
      const stepKey = location.state.stepKey;
  
      // Wait until refs are assigned
      const interval = setInterval(() => {
        if (micRef.current && chartingRef.current && audioSavingRef.current) {
          clearInterval(interval);
  
          // Log refs
          console.log('micRef.current:', micRef.current);
          console.log('chartingRef.current:', chartingRef.current);
          console.log('audioSavingRef.current:', audioSavingRef.current);
  
          // Define steps based on stepKey
          let steps = [];
          if (stepKey === 'step1') {
            steps = [
              {
                target: micRef.current,
                content: 'Click this and follow the instructions to set up your microphone correctly.',
                disableBeacon: true,
              },
              {
                target: chartingRef.current,
                content: 'Choose your charting method and preference and set your preferred language here.',
              },
              {
                target: audioSavingRef.current,
                content:
                  "Decide if you want to save audio recordings for live note taking. If you want to save the audio for medico-legal purposes, check the box.",
              },
              {
                target: '.onboarding4', // Assuming your practice tab list has a class of 'tab-list'
                content: 'Add your practice details here to complete your profile information.',
              },
              {
                target: '.onboarding5', // Assuming the upload section has an identifiable class
                content:
                  "Finally, upload your practice logo. If you can't be bothered with that at the moment, feel free to skip it, but you'll need to add this for letters.",
              },
            ];
          }

  
          // Log steps
          console.log('Joyride steps:', steps);
  
          setJoyrideSteps(steps);
          setJoyrideRun(true);
          setJoyrideInitialized(true);
  
          // Clear the location state after using it
          navigate(location.pathname, { replace: true, state: {} });
        }
      }, 100);
    }
  }, [userData, joyrideInitialized, location, navigate]);

   // === ResizeObserver to Detect Size Changes on micRef ===
   useEffect(() => {
    if (!micRef.current) return;

    const observer = new ResizeObserver(entries => {
      for (let entry of entries) {
        if (entry.target === micRef.current) {
          console.log('micRef size changed, triggering Joyride reposition');
          setJoyrideKey(prev => prev + 1); // Increment key to force re-render
        }
      }
    });

    observer.observe(micRef.current);

    return () => {
      if (micRef.current) {
        observer.unobserve(micRef.current);
      }
    };
  }, [micRef]);
  
    

  // ================================================

  const handleTabChange = (index) => {
    setActiveTabIndex(index);
    const practiceDetails = practices[index] || {};
    setTempUserData({
      name: userData.name || '',
      gdcNumber: userData.gdcNumber || '',
      practiceName: practiceDetails.practiceName || '',
      practiceAddress: practiceDetails.practiceAddress || '',
      practicePostcode: practiceDetails.practicePostcode || '',
      practiceEmail: practiceDetails.practiceEmail || '',
      practicePhoneNumber: practiceDetails.practicePhoneNumber || '',
    });
    setIsEditing(false);
  };
  

  const handleAddPractice = () => {
    const newPractice = {
      practiceName: '',
      practiceAddress: '',
      practicePostcode: '',
      practiceEmail: '',
      practicePhoneNumber: '',
      isDefault: false,
    };
    setPractices([...practices, newPractice]);
    const newIndex = practices.length;
    setActiveTabIndex(newIndex);
    
    // Ensure userData is available before accessing its properties
    if (userData) {
      setTempUserData({
        name: userData.name || '',
        gdcNumber: userData.gdcNumber || '',
        practiceName: '', // Initialize as empty for new practice
        practiceAddress: '',
        practicePostcode: '',
        practiceEmail: '',
        practicePhoneNumber: '',
      });
    } else {
      // Fallback in case userData is not available
      setTempUserData({
        name: '',
        gdcNumber: '',
        practiceName: '',
        practiceAddress: '',
        practicePostcode: '',
        practiceEmail: '',
        practicePhoneNumber: '',
      });
    }
  
    setIsEditing(true); // Enter editing mode for the new practice
  };
  
  const handlePracticeFieldChange = (index, field, value) => {
    const updatedPractices = practices.map((practice, idx) => {
      if (idx === index) {
        return { ...practice, [field]: value };
      }
      return practice;
    });
    setPractices(updatedPractices);
  };

  useEffect(() => {
    const fetchUserData = async () => {
      const user = auth.currentUser;
      if (user) {
        const userRef = doc(firestore, 'customers', user.uid);
        const userDoc = await getDoc(userRef);
        if (userDoc.exists()) {
          const data = userDoc.data();
          setUserData(data);
          setTempUserData({
            name: data.name || '',
            gdcNumber: data.gdcNumber || '',
            // Set initial practice data based on the default practice
            practiceName: data.practiceName || '',
            practiceAddress: data.practiceAddress || '',
            practicePostcode: data.practicePostcode || '',
            practiceEmail: data.practiceEmail || '',
            practicePhoneNumber: data.practicePhoneNumber || '',
          });
          setMarketingConsent(data.marketingConsent);
          setSaveAudioRecordings(data.saveAudioRecordings !== false);
          setLetterheadURL(data.letterheadURL || '');
          setChartingMethod(data.chartingMethod || 'FDI');
          setLanguagePreference(data.languagePreference || 'EN');
          // Initialize practices array
          if (Array.isArray(data.practices) && data.practices.length > 0) {
            setPractices(data.practices);
          } else {
            // If no practices, initialize with default values
            setPractices([{
              practiceName: data.practiceName || '',
              practiceAddress: data.practiceAddress || '',
              practicePostcode: data.practicePostcode || '',
              practiceEmail: data.practiceEmail || '',
              practicePhoneNumber: data.practicePhoneNumber || '',
              isDefault: true,
            }]);
          }
        }
         else {
          console.log("No user data found");
        }
      } else {
        console.log("No user logged in");
      }
      // if (location.state && location.state.fromOnboarding && location.state.joyrideSteps) {
      //   setJoyrideSteps(location.state.joyrideSteps);
      //   setJoyrideRun(true);
      // }
    };
    fetchUserData();
  }, []);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = async () => {
    const user = auth.currentUser;
    if (user) {
      const userRef = doc(firestore, 'customers', user.uid);
      const practiceData = {
        practiceName: tempUserData.practiceName,
        practiceAddress: tempUserData.practiceAddress,
        practicePostcode: tempUserData.practicePostcode,
        practiceEmail: tempUserData.practiceEmail,
        practicePhoneNumber: tempUserData.practicePhoneNumber,
        isDefault: activeTabIndex === 0 ? true : false,
      };
      let updatedPractices = [...practices];
      updatedPractices[activeTabIndex] = practiceData;
      try {
        if (activeTabIndex === 0) {
          await updateDoc(userRef, {
            name: tempUserData.name,
            gdcNumber: tempUserData.gdcNumber,
            practices: updatedPractices,
          });
          setUserData({
            ...userData,
            name: tempUserData.name,
            gdcNumber: tempUserData.gdcNumber,
            practices: updatedPractices,
          });          
        } else {
          await updateDoc(userRef, {
            practices: updatedPractices,
          });
          setUserData({
            ...userData,
            practices: updatedPractices,
          });
        }
        setPractices(updatedPractices);
        setIsEditing(false);
      } catch (error) {
        console.error('Error updating user data:', error);
        alert('Failed to save practice information. Please try again.');
      }
    }
  };
  

  const handleChangePasswordClick = async () => {
    const user = auth.currentUser;
    if (user) {
      try {
        const credential = EmailAuthProvider.credential(user.email, currentPassword);
        await reauthenticateWithCredential(user, credential);
        await updatePassword(user, newPassword);
        setCurrentPassword('');
        setNewPassword('');
        alert('Password changed successfully!');
      } catch (error) {
        setPasswordError(error.message);
      }
    }
  };

  const handleEmailPreferencesClick = () => {
    setEmailPreferencesVisible(!emailPreferencesVisible);
    setEmailPreferencesButtonLabel(emailPreferencesVisible ? 'Change Email Preferences' : 'Save Email Preferences');
  };

  const handleMarketingConsentChange = async () => {
    const user = auth.currentUser;
    if (user) {
      const userRef = doc(firestore, 'customers', user.uid);
      await setDoc(userRef, { marketingConsent: !marketingConsent }, { merge: true });
      setMarketingConsent(!marketingConsent);
    }
  };

  const handleImageChange = (e) => {
    if (e.target.files[0]) {
      setImageFile(e.target.files[0]);
    }
  };

  const handleUploadClick = async () => {
    if (!imageFile) return;
    const user = auth.currentUser;
    if (user) {
      const storageService = getStorage();
      const storageRef = ref(storageService, `letterheads/${user.uid}/${imageFile.name}`);
      try {
        const snapshot = await uploadBytes(storageRef, imageFile);
        const downloadURL = await getDownloadURL(snapshot.ref);
        const userRef = doc(firestore, 'customers', user.uid);
        await setDoc(userRef, { letterheadURL: downloadURL }, { merge: true });
        alert('Image uploaded successfully!');
        setLetterheadURL(downloadURL);
      } catch (error) {
        console.error('Error uploading image:', error);
      }
    }
  };

  const handleDeleteLetterhead = async () => {
    const confirmDelete = window.confirm("Are you sure you want to delete the letterhead?");
    if (confirmDelete) {
      const user = auth.currentUser;
      if (user) {
        const userRef = doc(firestore, 'customers', user.uid);
        await setDoc(userRef, { letterheadURL: '' }, { merge: true });
        setLetterheadURL('');
        alert('Letterhead deleted successfully!');
      }
    }
  };

  const handleDeletePractice = async (index) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this practice? This can't be undone.");
    if (confirmDelete) {
      const updatedPractices = practices.filter((_, idx) => idx !== index);
      setPractices(updatedPractices);
      if (activeTabIndex === index || activeTabIndex >= updatedPractices.length) {
        setActiveTabIndex(0);
        setTempUserData({ ...userData });
      }
      const user = auth.currentUser;
      if (user) {
        const userRef = doc(firestore, 'customers', user.uid);
        await setDoc(userRef, { practices: updatedPractices }, { merge: true });
      }
    }
  };

  const handleSetDefault = async (index) => {
    const updatedPractices = practices.map((practice, idx) => ({
      ...practice,
      isDefault: idx === index,
    }));
    setPractices(updatedPractices);
    const newUserData = {
      ...userData,
      ...updatedPractices[index],
    };
    const user = auth.currentUser;
    if (user) {
      const userRef = doc(firestore, 'customers', user.uid);
      await setDoc(userRef, { ...newUserData, practices: updatedPractices }, { merge: true });
      setUserData(newUserData);
    }
  };

  const checkDefaultMicrophone = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const devices = await navigator.mediaDevices.enumerateDevices();
      const audioDevices = devices.filter(device => device.kind === 'audioinput');
      const track = stream.getTracks()[0];
      const defaultMicId = track.getSettings().deviceId;
      const defaultMic = audioDevices.find(device => device.deviceId === defaultMicId);
      setDefaultMic(defaultMic ? defaultMic.label : 'Unknown microphone');
      track.stop();
    } catch (error) {
      console.error('Error accessing microphone:', error);
    }
  };

  const handleChartingMethodChange = async (e) => {
    const newMethod = e.target.value;
    setChartingMethod(newMethod);
    const user = auth.currentUser;
    if (user) {
      const userRef = doc(firestore, 'customers', user.uid);
      await setDoc(userRef, { chartingMethod: newMethod }, { merge: true });
    }
  };

  const handleCheckboxChange = async (e) => {
    const newValue = e.target.checked;
    setSaveAudioRecordings(newValue);
    const user = auth.currentUser;
    if (user) {
      const userRef = doc(firestore, 'customers', user.uid);
      try {
        await setDoc(userRef, { saveAudioRecordings: newValue }, { merge: true });
        alert(`Save Audio Recordings is now ${newValue ? 'enabled' : 'disabled'}.`);
      } catch (error) {
        console.error('Error updating saveAudioRecordings:', error);
        alert('Failed to update your preference. Please try again.');
      }
    }
  };
  

  const handleLanguagePreferenceChange = async (e) => {
    const newLanguage = e.target.value;
    setLanguagePreference(newLanguage);
    const user = auth.currentUser;
    if (user) {
      const userRef = doc(firestore, 'customers', user.uid);
      await setDoc(userRef, { languagePreference: newLanguage }, { merge: true });
    }
  };

  const languages = [
    { name: 'English', code: 'en' },
    { name: 'Afrikaans', code: 'af' },
    { name: 'Arabic', code: 'ar' },
    { name: 'Armenian', code: 'hy' },
    { name: 'Azerbaijani', code: 'az' },
    { name: 'Belarusian', code: 'be' },
    { name: 'Bosnian', code: 'bs' },
    { name: 'Bulgarian', code: 'bg' },
    { name: 'Catalan', code: 'ca' },
    { name: 'Chinese', code: 'zh' },
    { name: 'Croatian', code: 'hr' },
    { name: 'Czech', code: 'cs' },
    { name: 'Danish', code: 'da' },
    { name: 'Dutch', code: 'nl' },
    { name: 'Estonian', code: 'et' },
    { name: 'Finnish', code: 'fi' },
    { name: 'French', code: 'fr' },
    { name: 'Galician', code: 'gl' },
    { name: 'German', code: 'de' },
    { name: 'Greek', code: 'el' },
    { name: 'Hebrew', code: 'he' },
    { name: 'Hindi', code: 'hi' },
    { name: 'Hungarian', code: 'hu' },
    { name: 'Icelandic', code: 'is' },
    { name: 'Indonesian', code: 'id' },
    { name: 'Italian', code: 'it' },
    { name: 'Japanese', code: 'ja' },
    { name: 'Kannada', code: 'kn' },
    { name: 'Kazakh', code: 'kk' },
    { name: 'Korean', code: 'ko' },
    { name: 'Latvian', code: 'lv' },
    { name: 'Lithuanian', code: 'lt' },
    { name: 'Macedonian', code: 'mk' },
    { name: 'Malay', code: 'ms' },
    { name: 'Marathi', code: 'mr' },
    { name: 'Maori', code: 'mi' },
    { name: 'Nepali', code: 'ne' },
    { name: 'Norwegian', code: 'no' },
    { name: 'Persian', code: 'fa' },
    { name: 'Polish', code: 'pl' },
    { name: 'Portuguese', code: 'pt' },
    { name: 'Romanian', code: 'ro' },
    { name: 'Russian', code: 'ru' },
    { name: 'Serbian', code: 'sr' },
    { name: 'Slovak', code: 'sk' },
    { name: 'Slovenian', code: 'sl' },
    { name: 'Spanish', code: 'es' },
    { name: 'Swahili', code: 'sw' },
    { name: 'Swedish', code: 'sv' },
    { name: 'Tagalog', code: 'tl' },
    { name: 'Tamil', code: 'ta' },
    { name: 'Thai', code: 'th' },
    { name: 'Turkish', code: 'tr' },
    { name: 'Ukrainian', code: 'uk' },
    { name: 'Urdu', code: 'ur' },
    { name: 'Vietnamese', code: 'vi' },
    { name: 'Welsh', code: 'cy' },
  ];

  if (!userData) return <div>Loading...</div>;

  return (
    <main className="flex flex-col items-center tracking-tight w-4/5 mx-auto">  
      
      {/* MAIN SECTION */}
      <div className="grid grid-cols-2 w-full">
        {/* LEFT INPUT COLUMN */}
        <div className="flex flex-col">
          <div className="onboarding4 bg-white border-gray-300 border rounded-lg shadow-lg p-8 w-10/12">
            {/* Tab List */}
            <div className="tab-list mb-4">
              <ul>
                {practices.map((_, index) => (
                  <li key={index} className="tab-item">
                    <a href="#" onClick={() => handleTabChange(index)} className={`tab-link ${activeTabIndex === index ? 'active' : ''}`}>
                      Practice {index + 1}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            {isEditing ? (
              <div className="grid-container">
                <div className="labels">
                  <div className="profile-data-label">Name:</div>
                  <div className="profile-data-label">Practice Name:</div>
                  <div className="profile-data-label">Practice Address:</div>
                  <div className="profile-data-label">Practice Postcode:</div>
                  <div className="profile-data-label">Practice Email:</div>
                  <div className="profile-data-label">Practice Phone Number:</div>
                  <div className="profile-data-label">GDC Number:</div>
                </div>
                <div className="inputs">
                  <input
                    className="profile-data-input"
                    value={tempUserData.name}
                    onChange={(e) =>
                      setTempUserData({ ...tempUserData, name: e.target.value })
                    }
                  />
                  <input
                    className="profile-data-input"
                    value={tempUserData.practiceName}
                    onChange={(e) =>
                      setTempUserData({
                        ...tempUserData,
                        practiceName: e.target.value,
                      })
                    }
                  />
                  <input
                    className="profile-data-input"
                    value={tempUserData.practiceAddress}
                    onChange={(e) =>
                      setTempUserData({
                        ...tempUserData,
                        practiceAddress: e.target.value,
                      })
                    }
                  />
                  <input
                    className="profile-data-input"
                    value={tempUserData.practicePostcode}
                    onChange={(e) =>
                      setTempUserData({
                        ...tempUserData,
                        practicePostcode: e.target.value,
                      })
                    }
                  />
                  <input
                    className="profile-data-input"
                    value={tempUserData.practiceEmail}
                    onChange={(e) =>
                      setTempUserData({
                        ...tempUserData,
                        practiceEmail: e.target.value,
                      })
                    }
                  />
                  <input
                    className="profile-data-input"
                    value={tempUserData.practicePhoneNumber}
                    onChange={(e) =>
                      setTempUserData({
                        ...tempUserData,
                        practicePhoneNumber: e.target.value,
                      })
                    }
                  />
                  <input
                    className="profile-data-input"
                    value={tempUserData.gdcNumber}
                    onChange={(e) =>
                      setTempUserData({
                        ...tempUserData,
                        gdcNumber: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            ) : (
              <>
                <div className="grid-container">
                  <div className="labels">
                    <div className="profile-data-label">Name:</div>
                    <div className="profile-data-label">Practice Name:</div>
                    <div className="profile-data-label">Practice Address:</div>
                    <div className="profile-data-label">Practice Postcode:</div>
                    <div className="profile-data-label">Practice Email:</div>
                    <div className="profile-data-label">Practice Phone Number:</div>
                    <div className="profile-data-label">GDC Number:</div>
                  </div>
                  <div className="inputs">
                    <span className="profile-data">{tempUserData.name}</span>
                    <span className="profile-data">{tempUserData.practiceName}</span>
                    <span className="profile-data">{tempUserData.practiceAddress}</span>
                    <span className="profile-data">{tempUserData.practicePostcode}</span>
                    <span className="profile-data">{tempUserData.practiceEmail}</span>
                    <span className="profile-data">{tempUserData.practicePhoneNumber}</span>
                    <span className="profile-data">{tempUserData.gdcNumber}</span>
                  </div>
                </div>
              </>
            )}
            <div className="flex items-center justify-center mx-auto mt-2">
              {isEditing ? (
                <button className="px-4 py-2 text-xs text-primary-400 bg-white border border-gray-200 rounded-s-lg hover:bg-primary-400 hover:text-white" onClick={handleSaveClick}>
                  Save Practice
                </button>
              ) : (
                <button className="px-4 py-2 text-xs text-primary-400 bg-white border border-gray-200 rounded-s-lg hover:bg-primary-400 hover:text-white" onClick={handleEditClick}>
                  Edit Practice
                </button>
              )}
              <button className="px-4 py-2 text-xs text-primary-400 bg-white border-t border-b border-gray-200 hover:bg-primary-400 hover:text-white" onClick={handleAddPractice}>
                Add Practice
              </button>
              {activeTabIndex !== 0 && (
                <button 
                  className="px-4 py-2 text-xs text-primary-400 bg-white border-t border-b border-gray-200 hover:bg-primary-400 hover:text-white"
                  onClick={() => handleDeletePractice(activeTabIndex)}
                >
                  Delete Practice
                </button>
              )}
              {practices[activeTabIndex].isDefault && (
                <button className="px-4 py-2 text-xs text-white bg-malachite-400 border border-gray-200 rounded-e-lg hover:bg-primary-400 hover:text-white" disabled>
                  Current Location
                </button>
              )}
              {!practices[activeTabIndex].isDefault && (
                <button className="px-4 py-2 text-xs text-primary-400 bg-white border border-gray-200 rounded-e-lg hover:bg-primary-400 hover:text-white" onClick={() => handleSetDefault(activeTabIndex)}>
                  Choose Location
                </button>
              )}
            </div>
          </div>
          <div className="mt-4 bg-white border-gray-300 border rounded-lg shadow-lg p-4 w-10/12 text-center">
            <a
              href="https://billing.stripe.com/p/login/28o9Cr1ke7QV2WsdQQ"
              target="_blank"
              rel="noopener noreferrer"
              className="inline-flex items-center justify-center p-0.5 me-2 overflow-hidden text-xs font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-primary-500 to-pink-500 group-hover:from-purple-500 group-hover:to-pink-500 hover:text-white focus:ring-4 focus:outline-none focus:ring-purple-200"
            >
              <span className="relative px-5 py-1.5 transition-all ease-in duration-75 bg-white rounded-md group-hover:bg-opacity-0">
                Manage Subscription
              </span>
            </a>
          </div>
          <div className="mt-4 bg-white border-gray-300 border rounded-lg shadow-lg p-4 w-10/12 text-center">
            <button className="inline-flex items-center justify-center p-0.5 me-2 overflow-hidden text-xs font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-primary-500 to-pink-500 group-hover:from-purple-500 group-hover:to-pink-500 hover:text-white focus:ring-4 focus:outline-none focus:ring-purple-200" onClick={handleEmailPreferencesClick}>
              <span className="relative px-5 py-1.5 transition-all ease-in duration-75 bg-white rounded-md group-hover:bg-opacity-0">
                {emailPreferencesButtonLabel}
              </span>
            </button>
            {emailPreferencesVisible && (
              <div className="mt-2">
                <label>
                  Receive emails about updates from DigitalTCO:
                  <input
                    type="checkbox"
                    checked={marketingConsent}
                    onChange={handleMarketingConsentChange}
                    className="ml-2 accent-primary-400"
                  />
                </label>
              </div>
            )}
          </div>
          {/* AUDIO PREFERENCES */}
          <div ref={audioSavingRef} className="mt-4 bg-white border-gray-300 border rounded-lg shadow-lg p-8 w-10/12 text-center">
            <label className="inline-flex items-center">
              <input
                type="checkbox"
                checked={saveAudioRecordings}
                onChange={handleCheckboxChange}
                className="form-checkbox h-4 w-4 accent-primary-400 transition duration-150 ease-in-out"
              />
              <span className="ml-2 text-xs text-gray-700">Save Audio Recordings</span>
            </label>
            <p className="mt-4 text-xs text-gray-700">
              The choice is yours. You decide whether to save the audio recordings or not. If you're doing live appointment recordings and prefer not to save them, that's absolutely fine. Just make sure you have patient consent since the AI is listening, even if the audio isn't saved.
            </p>
            <p className="mt-2 text-xs text-gray-700">
              If you choose to save the recordings, they become part of the patient’s medical record, which can be helpful for medico-legal purposes. In that case, it’s smart to get written consent from the patient. You can easily download and customize a consent form from the Knowledge Centre, and it’s a great idea to keep it next to your photographic consent form.
            </p>
            <p className="mt-2 text-xs text-gray-700">
              Saved audio recordings will appear in the notes list, and you’ll also find them in the 'Audio Recordings' section. You can share these recordings with patients if needed or convert them into verbatim transcripts to capture exactly what was said during the appointment.
            </p>
          </div>
          </div>


        {/* RIGHT OUTPUT COLUMN */}
        <div className="flex flex-col">
          {/* MICROPHONE */}
          <div ref={micRef} className="bg-white border-gray-300 border rounded-lg shadow-lg p-8 w-10/12 text-center">
            <button 
              className="ml-8 inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-xs font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-primary-500 to-pink-500 group-hover:from-purple-500 group-hover:to-pink-500 hover:text-white focus:ring-4 focus:outline-none focus:ring-purple-200"
              onClick={checkDefaultMicrophone}
              
            >
              <span className="relative px-5 py-1.5 transition-all ease-in duration-75 bg-white rounded-md group-hover:bg-opacity-0">
                Check Default Microphone
              </span>
            </button>
            {defaultMic && (
              <div>
                <p><b>Default Microphone: {defaultMic}</b></p>
                <p>If this microphone isn't what you expected, you may need to update your settings directly in your operating system or browser:</p>
                <ul className="mic-instructions">
                  <li><a href="https://support.google.com/chrome/answer/2693767?hl=en-GB&co=GENIE.Platform%3DDesktop" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faChrome} />Chrome Settings</a></li>
                  <li><a href="https://www.youtube.com/watch?v=_wYUQlC3RdE" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faWindows} />Windows Settings</a></li>
                  <li><a href="https://support.apple.com/en-gb/guide/mac-help/mchlp2567/mac" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faApple} />Mac Settings</a></li>
                </ul>
              </div>
            )}
          </div>

          <div ref={chartingRef} className="mt-4 bg-white border-gray-300 border rounded-lg shadow-lg p-8 w-10/12 text-center">
            <label className="block mb-2 text-xs text-primary-400 text-left">
              Preferred Teeth Charting Method:
            </label>
            <select
              className="block w-full p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 text-xs focus:ring-blue-500 focus:border-blue-500"
              value={chartingMethod}
              onChange={handleChartingMethodChange}
            >
              <option value="FDI">FDI</option>
              <option value="Palmer">Palmer (UR2, etc.)</option>
              <option value="Universal">Universal/USA</option>
            </select>
            <label className="mt-4 block mb-2 text-xs text-primary-400 text-left">
              Preferred Language:
            </label>

            <select
              className="block w-full p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 text-xs focus:ring-blue-500 focus:border-blue-500"
              value={languagePreference || 'en'}
              onChange={handleLanguagePreferenceChange}
            >
              {languages.map(language => (
                <option key={language.code} value={language.code}>
                  {language.name}
                </option>
              ))}
            </select>
          </div>


          {/* LETTERHEAD */}
          <div className="onboarding5 mt-4 bg-white border-gray-300 border rounded-lg shadow-lg p-8 w-10/12 text-center">
            {letterheadURL && (
              <>
                <div>
                  <img src={letterheadURL} alt="Letterhead" className="letterhead-image" />
                </div>
              </>
            )}
            <input type="file" onChange={handleImageChange} />
            <div className="flex text-center items-center justify-center mt-4 gap-2">
              <button className="px-4 py-2 text-xs text-primary-400 bg-white border border-gray-200 rounded-lg hover:bg-primary-400 hover:text-white" onClick={handleUploadClick}>
                {letterheadURL ? 'Change Letterhead' : 'Upload Letterhead'}
              </button>
              <button className="px-4 py-2 text-xs text-primary-400 bg-white border border-gray-200 rounded-lg hover:bg-primary-400 hover:text-white" onClick={handleDeleteLetterhead}>
                Delete Letterhead
              </button>
            </div>
          </div>

          {/* PASSWORD */}
          <div className="mt-4 bg-white border-gray-300 border rounded-lg shadow-lg p-8 w-10/12 text-center">
            <div className="w-3/4 mx-auto">
              <h3 className="block mb-4 text-xs text-primary-400 text-left">Change Password</h3>
              <input
                className="block w-full p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 text-xs focus:ring-blue-500 focus:border-blue-500"
                type="password"
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
                placeholder="Current Password"
              />
              <input
                className="mt-2 block w-full p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 text-xs focus:ring-blue-500 focus:border-blue-500"
                type="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                placeholder="New Password"
              />
            </div>
            <button
              className="mt-2 px-4 py-2 text-xs text-primary-400 bg-white border border-gray-200 rounded-lg hover:bg-primary-400 hover:text-white"
              onClick={handleChangePasswordClick}
            >
              Change Password
            </button>
            {passwordError && (
              <p className="password-error-message">{passwordError}</p>
            )}
          </div>
        </div>
      </div>

            {/* === New OnboardingJoyride Component === */}
{joyrideSteps.length > 0 && (
  <OnboardingJoyride
    run={joyrideRun}
    steps={joyrideSteps}
    onComplete={handleJoyrideComplete}
    onDoNotShowAgain={handleDoNotShowAgain}
  />
)}
      {/* ====================================== */}    
    </main>
  );
};

export default Profile;