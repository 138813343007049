// src/components/Card.jsx

import React from 'react';
import { Draggable } from 'react-beautiful-dnd';

const CardComponent = ({ card, index, columnId, deleteCard }) => {
  return (
    <Draggable draggableId={card.id} index={index}>
      {(provided, snapshot) => (
        <div
          className={`bg-white p-3 rounded shadow mb-2 flex justify-between items-center ${
            snapshot.isDragging ? 'bg-primary-100' : ''
          }`}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <span>{card.content}</span>
          <button
            onClick={() => deleteCard(columnId, card.id)}
            className="text-primary-500 hover:text-primary-700"
          >
            &#10005;
          </button>
        </div>
      )}
    </Draggable>
  );
};

export default CardComponent;
