import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { firestore } from '../../firebase'; // Adjust the import path as necessary

// Helper function to fetch patient notes from Firestore
async function fetchPatientNotes(patientId, userId) {
  const patientRef = doc(firestore, 'customers', userId, 'patients', patientId);
  const patientDoc = await getDoc(patientRef);

  if (!patientDoc.exists()) {
    throw new Error('Patient not found');
  }

  return patientDoc.data().notes; // Assuming the structure is { notes: { ... } }
}

// Updated generateDentalDiagnosis function
async function generateDentalDiagnosis(patientId, userId) {
  const patientNotes = await fetchPatientNotes(patientId, userId);
  let diagnosis = '';

  const clinicalCaries = patientNotes && patientNotes.clinicalCaries;
  const radiographCaries = patientNotes && patientNotes.radiographAssessment && patientNotes.radiographAssessment.cariesAssessment;

// Create an array to store unique teeth numbers
const uniqueTeeth = [];
  
// Function to add unique teeth numbers to the uniqueTeeth array
const addUniqueTeeth = (teeth) => {
  if (teeth) {
    const teethArray = teeth.split(',').map((item) => item.trim());
    teethArray.forEach((tooth) => {
      if (!uniqueTeeth.includes(tooth)) {
        uniqueTeeth.push(tooth);
      }
    });
  }
};

// Check if caries are noted in clinicalCaries or radiographAssessment.cariesAssessment
if (clinicalCaries) {
  addUniqueTeeth(clinicalCaries);
}

if (radiographCaries) {
  addUniqueTeeth(radiographCaries);
}

// Sort unique teeth numbers in the specified order
uniqueTeeth.sort((a, b) => {
  const order = ['UR', 'UL', 'LL', 'LR'];
  const getPart = (str) => str.match(/\D+/)[0];
  return order.indexOf(getPart(a)) - order.indexOf(getPart(b));
});

// Create the diagnosis string from sorted unique teeth numbers
if (uniqueTeeth.length > 0) {
  diagnosis = 'Dental caries detected in teeth ' + uniqueTeeth.join(' ');
} else {
  diagnosis = 'No significant dental abnormalities detected.';
}
  return diagnosis;
}

// Updated generateDentalPathologyDiagnosis function
async function generateDentalPathologyDiagnosis(patientId, userId) {
  const patientNotes = await fetchPatientNotes(patientId, userId);
  let diagnosis = '';

  const pathologyAssessment = patientNotes && patientNotes.radiographAssessment && patientNotes.radiographAssessment.pathologyAssessment;
// Create an array to store unique teeth numbers
const uniqueTeeth = [];
  
// Function to add unique teeth numbers to the uniqueTeeth array
const addUniqueTeeth = (teeth) => {
  if (teeth) {
    const teethArray = teeth.split(',').map((item) => item.trim());
    teethArray.forEach((tooth) => {
      if (!uniqueTeeth.includes(tooth)) {
        uniqueTeeth.push(tooth);
      }
    });
  }
};

// Check if dental pathology is noted in pathologyAssessment
if (pathologyAssessment) {
  addUniqueTeeth(pathologyAssessment);
}

// Sort unique teeth numbers in the specified order
uniqueTeeth.sort((a, b) => {
  const order = ['UR', 'UL', 'LL', 'LR'];
  const getPart = (str) => str.match(/\D+/)[0];
  return order.indexOf(getPart(a)) - order.indexOf(getPart(b));
});

// Create the diagnosis string from sorted unique teeth numbers
if (uniqueTeeth.length > 0) {
  diagnosis = 'Periradicular pathology detected on teeth ' + uniqueTeeth.join(' ');
} else {
  diagnosis = 'No significant dental pathology detected.';
}

  return diagnosis;
}

// Updated generatePeriodontalDiagnosis function
async function generatePeriodontalDiagnosis(patientId, userId) {
    const patientNotes = await fetchPatientNotes(patientId, userId);
    let diagnosis = '';
  
    // Ensure that patientNotes.bpe exists before trying to access its properties
    if (!patientNotes.bpe) {
      return 'BPE data is not available for diagnosis.';
    }
  
    // Determine the highest BPE code across all 6 BPE inputs
    const highestBPECode = Math.max(
      patientNotes.bpe.topLeft,
      patientNotes.bpe.topMiddle,
      patientNotes.bpe.topRight,
      patientNotes.bpe.bottomRight,
      patientNotes.bpe.bottomMiddle,
      patientNotes.bpe.bottomLeft
    );
  
    // Check the highest BPE code to generate the periodontal diagnosis
    switch (highestBPECode) {
      case 0:
        diagnosis = 'Clinical Gingival Health';
        break;
      case 1:
        diagnosis = 'Gingivitis';
        if (patientNotes.bpeType === 'Localised') {
          diagnosis += ' (Localised)';
        } else if (patientNotes.bpeType === 'Generalised') {
          diagnosis += ' (Generalised)';
        }
        break;
      case 2:
        diagnosis = 'Gingivitis with Associated Calculus and Plaque Retentive Factors';
        if (patientNotes.bpeType === 'Localised') {
          diagnosis += ' (Localised)';
        } else if (patientNotes.bpeType === 'Generalised') {
          diagnosis += ' (Generalised)';
        }
        break;
      case 3:
      case 4:
        // Ensure that patientNotes.radiographAssessment exists before trying to access its properties
        if (!patientNotes.radiographAssessment || !patientNotes.radiographAssessment.maxBoneLossPercentage) {
          return 'Radiograph assessment data is not available for diagnosis.';
        }
  
        // Calculate the stage and grade based on max bone loss percentage
        const maxBoneLossPercentage = parseFloat(patientNotes.radiographAssessment.maxBoneLossPercentage);
        const age = patientNotes.age || 65; // Use patient's age if available, otherwise default to 65
        const gradeValue = maxBoneLossPercentage / age;
        let stage = '';
        let grade = '';
        
        if (maxBoneLossPercentage < 15) {
          stage = 'I';
        } else if (maxBoneLossPercentage >= 15 && maxBoneLossPercentage < 33) {
          stage = 'II';
        } else if (maxBoneLossPercentage >= 33 && maxBoneLossPercentage < 66) {
          stage = 'III';
        } else if (maxBoneLossPercentage >= 66 && maxBoneLossPercentage <= 100) {
          stage = 'IV';
        }
        
        if (gradeValue < 0.5) {
          grade = 'Grade A';
        } else if (gradeValue >= 0.5 && gradeValue <= 1.0) {
          grade = 'Grade B';
        } else if (gradeValue > 1.0) {
          grade = 'Grade C';
        }
        
        diagnosis = `${patientNotes.bpeType || ''} Periodontitis (Stage ${stage}, ${grade})`;
        break;
      default:
        diagnosis = 'No periodontal diagnosis available.';
    }
  
    return diagnosis;
  }
  

// Function to update the diagnoses in Firestore
async function updatePatientDiagnoses(patientId, userId, dentalDiagnosis, pathologyDiagnosis, periodontalDiagnosis) {
    const patientRef = doc(firestore, 'customers', userId, 'patients', patientId);
  
    try {
      await updateDoc(patientRef, {
        'notes.diagnosis.dental': dentalDiagnosis,
        'notes.diagnosis.pathology': pathologyDiagnosis,
        'notes.diagnosis.periodontal': periodontalDiagnosis,
      });
      // You can return some status or the updated data here if needed
      return { status: 'success' };
    } catch (error) {
      console.error("Error updating patient's diagnoses:", error);
      return { status: 'error', error: error };
    }
  }

// Export the functions
export {
  generateDentalDiagnosis,
  generatePeriodontalDiagnosis,
  generateDentalPathologyDiagnosis,
  updatePatientDiagnoses
};
