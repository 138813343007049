import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { firestore, auth } from '../../firebase';
import { collection, getDocs, doc, getDoc, setDoc, deleteDoc } from 'firebase/firestore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

const fontOptions = [
    { value: 'Arial', label: 'Arial' },
    { value: 'Times New Roman', label: 'Times New Roman' },
    { value: 'Calibri', label: 'Calibri' },
    { value: 'Helvetica', label: 'Helvetica' },
    { value: 'Georgia', label: 'Georgia' },
    { value: 'Verdana', label: 'Verdana' },
    { value: 'Tahoma', label: 'Tahoma' },
    { value: 'Trebuchet MS', label: 'Trebuchet MS' },
    { value: 'Lucida Sans Unicode', label: 'Lucida Sans Unicode' }
];

const defaultSettings = {
    font: 'Calibri',
    fontSize: '11',
    footerContent: 'Default footer content',
};

const LettersTable = () => {
    const [letterName, setLetterName] = useState('');
    const [letters, setLetters] = useState([]);
    const [selectedFont, setSelectedFont] = useState(defaultSettings.font);
    const [selectedFontSize, setSelectedFontSize] = useState(defaultSettings.fontSize);
    const [footerContent, setFooterContent] = useState(defaultSettings.footerContent);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchLetters = async () => {
            const uid = auth.currentUser.uid;
            const lettersRef = collection(firestore, 'customers', uid, 'molarmailer');
            const lettersSnapshot = await getDocs(lettersRef);
            const lettersList = lettersSnapshot.docs
                .filter(doc => doc.id !== 'settings')
                .map(doc => ({ id: doc.id, ...doc.data() }));
            setLetters(lettersList);

            // Fetch settings
            const settingsRef = doc(firestore, 'customers', uid, 'molarmailer', 'settings');
            const settingsDoc = await getDoc(settingsRef);
            if (settingsDoc.exists()) {
                const settingsData = settingsDoc.data();
                setSelectedFont(settingsData.font || defaultSettings.font);
                setSelectedFontSize(settingsData.fontSize || defaultSettings.fontSize);
                setFooterContent(settingsData.footerContent || defaultSettings.footerContent);
            } else {
                // If settings don't exist, save the default settings
                await setDoc(settingsRef, defaultSettings, { merge: true });
            }
        };

        fetchLetters();
    }, []);

    const saveSettings = async (newSettings) => {
        const uid = auth.currentUser.uid;
        const settingsRef = doc(firestore, 'customers', uid, 'molarmailer', 'settings');
        try {
            await setDoc(settingsRef, newSettings, { merge: true });
        } catch (error) {
            console.error('Error saving settings:', error);
        }
    };

    const handleFontChange = (e) => {
        const newFont = e.target.value;
        setSelectedFont(newFont);
        saveSettings({ font: newFont });
    };

    const handleFontSizeChange = (e) => {
        const newSize = e.target.value;
        setSelectedFontSize(newSize);
        saveSettings({ fontSize: newSize });
    };

    const handleFooterContentChange = (e) => {
        const newFooterContent = e.target.value;
        setFooterContent(newFooterContent);
        saveSettings({ footerContent: newFooterContent });
    };

    const addLetter = async () => {
        if (letterName) {
            const newLetter = { name: letterName, id: Date.now().toString() };
            const uid = auth.currentUser.uid;
            const letterRef = doc(firestore, 'customers', uid, 'molarmailer', newLetter.id);

            try {
                await setDoc(letterRef, newLetter);
                setLetters([...letters, newLetter]);
                setLetterName('');
            } catch (error) {
                console.error('Error adding letter:', error);
            }
        }
    };

    const deleteLetter = async (id) => {
        const confirmDelete = window.confirm('Are you sure you want to delete this letter?');
        if (confirmDelete) {
            const uid = auth.currentUser.uid;
            const letterRef = doc(firestore, 'customers', uid, 'molarmailer', id);
            try {
                await deleteDoc(letterRef);
                setLetters(letters.filter(letter => letter.id !== id));
            } catch (error) {
                console.error('Error deleting letter:', error);
            }
        }
    };

    const goToLetter = (id) => {
        navigate(`/mvp/molarmail/${id}`);
    };

    return (
        <main className="flex flex-col items-center tracking-tight w-4/5 mx-auto">
            {/* MAIN SECTION */}
            <div className="mt-4 grid grid-cols-1 gap-6 sm:grid-cols-2 w-full">
                {/* LEFT INPUT COLUMN */}
                <div className="flex flex-col text-xs w-3/5 mx-auto">
                    <div className="mb-4">
                        <label htmlFor="font-select" className="block mb-2 text-xs text-primary-400">Font</label>
                        <select
                            id="font-select"
                            value={selectedFont}
                            onChange={handleFontChange}
                            className="block w-full p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-primary-500 focus:border-primary-500"
                        >
                            {fontOptions.map(font => (
                                <option key={font.value} value={font.value} style={{ fontFamily: font.value }}>
                                    {font.label}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="mb-4">
                        <label htmlFor="font-size-select" className="block mb-2 text-xs text-primary-400">Font Size</label>
                        <select
                            id="font-size-select"
                            value={selectedFontSize}
                            onChange={handleFontSizeChange}
                            className="block w-full p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-primary-500 focus:border-primary-500"
                        >
                            <option value="10" style={{ fontSize: '10px' }}>10</option>
                            <option value="11" style={{ fontSize: '11px' }}>11</option>
                            <option value="12" style={{ fontSize: '12px' }}>12</option>
                            <option value="14" style={{ fontSize: '14px' }}>14</option>
                            <option value="16" style={{ fontSize: '16px' }}>16</option>
                            <option value="18" style={{ fontSize: '18px' }}>18</option>
                            <option value="20" style={{ fontSize: '20px' }}>20</option>
                            <option value="24" style={{ fontSize: '24px' }}>24</option>
                        </select>
                    </div>

                    <div className="mb-4">
                        <label htmlFor="footer-content" className="block mb-2 text-xs text-primary-400">Footer Content</label>
                        <textarea
                            id="footer-content"
                            value={footerContent}
                            onChange={handleFooterContentChange}
                            className="block w-full p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-primary-500 focus:border-primary-500"
                            rows="4"
                        />
                    </div>
                </div>

                {/* RIGHT INPUT COLUMN */}
                <div className="flex flex-col">
                    <div className="mb-4 flex justify-center">
                        <input
                            type="text"
                            className="block w-2/5 p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 text-xs focus:ring-primary-500 focus:border-primary-500"
                            placeholder="Enter letter name"
                            value={letterName}
                            onChange={(e) => setLetterName(e.target.value)}
                        />
                        <button
                            onClick={addLetter}
                            className="p-2 text-xs w-1/5 text-primary-400 bg-white border border-gray-200 rounded-lg hover:bg-primary-400 hover:text-white ml-2"
                        >
                            Add Letter
                        </button>
                    </div>
                    <div className="relative overflow-x-auto w-full rounded-t-lg">
                        <table className="mx-auto w-full text-sm text-left text-gray-500">
                            <thead className="text-xs text-gray-700 bg-primary-100">
                                <tr>
                                    <th scope="col" className="px-6 py-3">Letter Name</th>
                                    <th scope="col" className="px-3 py-3 w-20"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {letters.map((letter) => (
                                    <tr
                                        key={letter.id}
                                        className="bg-white rounded-lg cursor-pointer hover:bg-gradient-to-l from-primary-50 via-primary-100 to-primary-200 hover:text-white"
                                    >
                                        <th
                                            scope="row"
                                            className="px-6 py-4 font-normal text-gray-700 whitespace-nowrap"
                                            onClick={() => goToLetter(letter.id)}
                                        >
                                            {letter.name}
                                        </th>
                                        <td className="px-3 py-4 w-20 text-center">
                                            <FontAwesomeIcon
                                                icon={faTrash}
                                                className="text-red-500 cursor-pointer hover:text-red-700"
                                                onClick={() => deleteLetter(letter.id)}
                                            />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default LettersTable;
