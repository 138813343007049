import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { firestore, auth } from '../../firebase';
import PatientProfileNav from './PatientProfileNav';
import './PatientNotesInput.css'; 

const BPE = () => {
  const navigate = useNavigate();
  const { patientId } = useParams();

  const [bpeValues, setBPEValues] = useState({
    topLeft: '',
    topMiddle: '',
    topRight: '',
    bottomRight: '',
    bottomMiddle: '',
    bottomLeft: '',
  });

  const [selectedBPEType, setSelectedBPEType] = useState('');

  useEffect(() => {
    const patientRef = doc(firestore, 'customers', auth.currentUser.uid, 'patients', patientId);

    const fetchBPE = async () => {
      const patientDoc = await getDoc(patientRef);
      if (patientDoc.exists() && patientDoc.data().notes.bpe) {
        setBPEValues(patientDoc.data().notes.bpe); // Set the BPE data to the local state
        setSelectedBPEType(patientDoc.data().notes.bpeType || '');
      }
    };

    fetchBPE();
  }, [patientId]);

  const handleInputChange = (name, value) => {
    setBPEValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleTabPress = (event, nextInputName) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      document.getElementById(nextInputName).focus();
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleNext();
    }
  };

  const handleNext = async () => {
    const patientRef = doc(firestore, 'customers', auth.currentUser.uid, 'patients', patientId);

    await updateDoc(patientRef, {
      'notes.bpe': bpeValues,
      'notes.bpeType': selectedBPEType || 'Nil',
    });

    console.log("Updated Patient Data:", { bpeValues, selectedBPEType });

    navigate(`/mvp/patient-profile/${patientId}/oral-hygiene`);
  };

  const handleLocalisedBPE = () => {
    setSelectedBPEType('Localised');
  };

  const handleGeneralisedBPE = () => {
    setSelectedBPEType('Generalised');
  };

  const handleNil = () => {
    setSelectedBPEType('Nil');
  };

  return (
    <div className="patient-complaints-container">
      <PatientProfileNav />
      <h2 className="patient-complaints-h2">BPE</h2>
      <div className="patient-bpe-input-group">
        <input
          className="patient-bpe-input"
          type="number"
          id="topRight"
          value={bpeValues.topRight}
          onChange={(e) => handleInputChange('topRight', e.target.value)}
          onKeyDown={(e) => handleTabPress(e, 'topMiddle')}
          autoFocus
        />
        <input
          className="patient-bpe-input"
          type="number"
          id="topMiddle"
          value={bpeValues.topMiddle}
          onChange={(e) => handleInputChange('topMiddle', e.target.value)}
          onKeyDown={(e) => handleTabPress(e, 'topLeft')}
        />
        <input
          className="patient-bpe-input"
          type="number"
          id="topLeft"
          value={bpeValues.topLeft}
          onChange={(e) => handleInputChange('topLeft', e.target.value)}
          onKeyDown={(e) => handleTabPress(e, 'bottomLeft')}
        />
      </div>
      <div className="patient-bpe-input-group">
        <input
          className="patient-bpe-input"
          type="number"
          id="bottomRight"
          value={bpeValues.bottomRight}
          onChange={(e) => handleInputChange('bottomRight', e.target.value)}
          onKeyDown={(e) => handleTabPress(e, 'topLeft')}
        />
        <input
          className="patient-bpe-input"
          type="number"
          id="bottomMiddle"
          value={bpeValues.bottomMiddle}
          onChange={(e) => handleInputChange('bottomMiddle', e.target.value)}
          onKeyDown={(e) => handleTabPress(e, 'bottomRight')}
        />
        <input
          className="patient-bpe-input"
          type="number"
          id="bottomLeft"
          value={bpeValues.bottomLeft}
          onChange={(e) => handleInputChange('bottomLeft', e.target.value)}
          onKeyDown={(e) => handleTabPress(e, 'bottomMiddle')}
        />
      </div>
      <div className="patient-complaints-button-group">
      <button
          className={`patient-complaints-button ${selectedBPEType === 'Nil' ? 'selected' : ''}`}
          onClick={handleNil}
        >
          No BOP
        </button>
        <button
          className={`patient-complaints-button ${selectedBPEType === 'Localised' ? 'selected' : ''}`}
          onClick={handleLocalisedBPE}
        >
          Localised BOP
        </button>
        <button
          className={`patient-complaints-button ${selectedBPEType === 'Generalised' ? 'selected' : ''}`}
          onClick={handleGeneralisedBPE}
        >
          Generalised BOP
        </button>
      </div>
      <button className="patient-complaints-button" onClick={handleNext}>Next</button>
    </div>
  );
};

export default BPE;
