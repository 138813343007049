import axios from 'axios';

export const fetchMetaAdsData = async () => {
  try {
    // Placeholder API call or mock data
    const data = {
      campaignName: 'Spring Sale',
      impressions: 5000,
      clicks: 300,
      spend: 1000,
      conversions: 50,
      conversionRate: 10,
      cpc: 3.33,
    };
    
    return data;
  } catch (error) {
    console.error('Error fetching Meta Ads data:', error);
    throw error;
  }
};
