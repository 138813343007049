// Settings.js

import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faNotesMedical,
  faSyringe,
  faMagnifyingGlass,
  faEnvelope
} from '@fortawesome/free-solid-svg-icons';

import Templates from './Templates';
import LocalAnaesthetic from './LocalAnaesthetic';
import ConsentForms from './ConsentForms';
import ExamAndConsultTemplates from './ExamsAndConsultTemplates';
import CariespondenceTemplates from './CariespondenceTemplates';

const tabConfig = [
  {
    key: 'ExamsConsults',
    label: 'Exams',
    icon: faNotesMedical,
    component: <ExamAndConsultTemplates />
  },
  {
    key: 'BasicExamTreatmentNotes',
    label: 'Treatments',
    icon: faMagnifyingGlass,
    component: (openSidebar) => <Templates openSidebar={openSidebar} />
  },
  {
    key: 'LA',
    label: 'Local Anaesthetic',
    icon: faSyringe,
    component: <LocalAnaesthetic />
  },
  {
    key: 'CustomForms',
    label: 'Custom Forms',
    icon: faEnvelope,
    component: <ConsentForms />
  },
  {
    key: 'Cariespondence',
    label: 'Cariespondence',
    icon: faEnvelope,
    component: <CariespondenceTemplates />
  }
];

const Settings = ({ openSidebar }) => {
  const [activeTab, setActiveTab] = useState('BasicExamTreatmentNotes');

  const handleTabClick = (tabKey) => {
    setActiveTab(tabKey);
  };

  return (
    <main className="flex flex-col items-center tracking-tight w-4/5 mx-auto">
      {/* Tab Navigation */}
      <div className="w-full border-b border-gray-200">
        <ul className="flex flex-wrap -mb-px text-sm font-medium text-center">
          {tabConfig.map((tab) => (
            <li key={tab.key} className="mr-2">
              <button
                onClick={() => handleTabClick(tab.key)}
                className={`inline-flex items-center p-4 rounded-t-xl ${
                  activeTab === tab.key
                    ? 'text-white bg-primary-400'
                    : 'text-gray-500 hover:text-white hover:bg-primary-200'
                }`}
              >
                <FontAwesomeIcon icon={tab.icon} className="mr-2" />
                {tab.label}
              </button>
            </li>
          ))}
        </ul>
      </div>

      {/* Tab Content */}
      <div className="w-full mt-6">
        {tabConfig.map(
          (tab) =>
            activeTab === tab.key &&
            (typeof tab.component === 'function' ? tab.component(openSidebar) : tab.component)
        )}
      </div>
    </main>
  );
};

export default Settings;
